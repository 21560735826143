import React, { useEffect, useState } from "react";
import BreadCrump from "../components/BreadCrump/bread_crump";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import { useBlogs } from "../store/blog_store";
import Loader from "../components/loader/loader";

export default function GdprCompliance() {
  const page_id = "kM5W7PrO6nk303Kpl43z";
  const getSpecificPage = useBlogs((state) => state.getSpecificPage);
  const [page, setPage] = useState(null); // Initialize page as null

  useEffect(() => {
    const fetchPage = async () => {
      const pageRef = await getSpecificPage(page_id);
      setPage(pageRef);
    };
    fetchPage();
  }, [page?.length]);

  const parseHTML = (htmlString) => ({ __html: htmlString });

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* BREADCRUMP */}
      <BreadCrump title={"GDPR Compliance"} />

      {page ? (
        <>
          <section className="section-content padding-y bg-white">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card box">
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={parseHTML(page.content)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ADDING THE FOOTER */}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
