/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Alert from "./components/alert";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import DashboardBreadcrump from "../../components/dashboard_breadcrump/dashboard_breadcrump";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import OptionsDropDown from "./components/options_dropdown";
import { isMobile } from "react-device-detect";
import { ordersStore } from "../../store/orders_store";
import { userStore } from "../../store/user_store";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import OrganizerName from "./OrganizerName";
import CustomPagination from "../../components/pagination/Pagination";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Tickets() {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const isAttendee = userStore((state) => state.isAttendee);
  const user = userStore((state) => state.user);
  const getAttendeeOrders = ordersStore((state) => state.attendeeOrders);
  const navigate = useNavigate();
  const layout_page = useSettings((state) => state.layout_page);

  // GETTING THE TICKETS FOR THIS USER
  useEffect(() => {
    const fetchOrders = async () => {
      if (isAttendee) {
        const attendeeOrders = await getAttendeeOrders(user?.user_id);
        setOrders(attendeeOrders);
      }
    };
    fetchOrders();
    //set a delay of atleast 3 seconds in order all the data to display
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [user?.user_id]);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(20);
  //get current events
  const indexOfLastorders = currentPage * ordersPerPage;
  const indexOfFastorders = indexOfLastorders - ordersPerPage;
  const current_orders = orders?.slice(indexOfFastorders, indexOfLastorders);

  //  DISPLAYING THE LOADER
  if (isLoading) {
    return <Loader />;
  }
  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  }

  return (
    <div>
      <Header currentPage={"tickets"} />
      <DashboardBreadcrump title={"My Tickets"} />

      {/* MAIN TICKETS BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* sidenav */}
            {!isMobile && <AttendeeSideNav activeTab={"tickets"} />}

            <div className="col-lg-9 mt-4 mt-lg-0">
              {/* IF NO TICKETS ARE AVAILABLE */}
              {orders?.length === 0 ? (
                <Alert message={"No Orders Found"} page={"tickets"} />
              ) : (
                // IF THERE ARE TICKETS TO DISPLAY
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-hover table-vcenter">
                            <thead>
                              <tr>
                                <th className="text-center">Reference</th>
                                <th>Organizer / Event / Date / Ticket</th>
                                <th>Order date</th>
                                <th>Status</th>
                                <th className="text-center">
                                  <i className="fas fa-cog" />
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* LOOPING THROUGH THE ORDERS */}
                              {current_orders?.map((order, index) => (
                                <tr key={order?.id}>
                                  {/* BOOKING ID */}
                                  <td className="text-center text-muted text-sm">
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={
                                        "/ticket_details/" + order?.booking_id
                                      }
                                    >
                                      {order?.booking_id}
                                    </Link>
                                  </td>
                                  <td className="text-sm">
                                    <ul className="p-0">
                                      {/* Group the tickets by event */}
                                      {Object.values(
                                        order?.order_tickets?.reduce(
                                          (acc, ticket) => {
                                            const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                            if (!acc[key]) {
                                              acc[key] = [];
                                            }
                                            acc[key].push(ticket);
                                            return acc;
                                          },
                                          {}
                                        )
                                      ).map((tickets, eventIndex) => (
                                        <li key={eventIndex}>
                                          {/* ORGANIZER NAME */}
                                          <OrganizerName
                                            event_id={tickets[0]?.event_id}
                                          />
                                          {/* CHEVRON RIGHT */}
                                          <i className="fas fa-chevron-right fa-sm mx-1" />
                                          {/* EVENT NAME */}
                                          <Link
                                            style={{
                                              color: layout_page?.primary_color,
                                            }}
                                            to={
                                              "/event_details/" +
                                              tickets[0]?.event_id
                                            }
                                          >
                                            {tickets[0]?.event_name}
                                          </Link>
                                          {/* CHEVRON RIGHT */}
                                          <i className="fas fa-chevron-right fa-sm mx-1" />

                                          {/* EVENT DATE */}
                                          {formatDate(
                                            convertTimestampToDateTime(
                                              tickets[0]?.event_start_date
                                            )
                                          )}

                                          {/* CHEVERON RIGHT */}
                                          <i className="fas fa-chevron-right fa-sm mx-1" />

                                          {/* TICKET NAME AND COUNT */}
                                          {Object.values(
                                            tickets.reduce((acc, ticket) => {
                                              const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                              if (!acc[key]) {
                                                acc[key] = 0;
                                              }
                                              acc[key]++;
                                              return acc;
                                            }, {})
                                          ).map((count, ticketIndex) => (
                                            <div key={ticketIndex}>
                                              {ticketIndex > 0 && ", "}
                                              {
                                                tickets[ticketIndex]
                                                  ?.ticket_name
                                              }{" "}
                                              ({count}{" "}
                                              {count > 1 ? "tickets" : "ticket"}
                                              )
                                            </div>
                                          ))}
                                        </li>
                                      ))}
                                    </ul>
                                  </td>

                                  {/* ORDER DATE */}
                                  <td className="text-sm">
                                    {formatDate(
                                      convertTimestampToDateTime(
                                        order?.created_at
                                      )
                                    )}
                                  </td>

                                  {/* ORDER STATUS */}
                                  <td className="text-sm">
                                    <span
                                      className={
                                        order?.status === "pending"
                                          ? "badge badge-warning"
                                          : "badge badge-success"
                                      }
                                    >
                                      {order?.status === "pending"
                                        ? "Awaiting payment"
                                        : "Successful"}
                                    </span>
                                  </td>

                                  {/* ACTIONS */}
                                  <td className="text-center">
                                    <OptionsDropDown
                                      booking_id={order?.booking_id}
                                      order_id={order?.order_id}
                                      orderStatus={order?.status}
                                      key={1}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PAGINATION */}
                  {orders?.length > 20 && (
                    <CustomPagination
                      setCurrentPage={setCurrentPage}
                      totalPages={Math.ceil(orders?.length / ordersPerPage)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
