// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-13cymwt-control {
    height: calc(2.75rem + 2px);
    color: #8898aa;
    border: 1px solid #f1f3f7;
    border-radius: 0.25rem;
    background-color: #f1f3f7 !important;
}

.css-t3ipsp-control, .css-1fdsijx-ValueContainer, .css-qbdosj-Input{
    height: calc(2.75rem + 2px) !important;

}
`, "",{"version":3,"sources":["webpack://./src/components/searchable_dropdown/searchable_dropdown.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;IACd,yBAAyB;IACzB,sBAAsB;IACtB,oCAAoC;AACxC;;AAEA;IACI,sCAAsC;;AAE1C","sourcesContent":[".css-13cymwt-control {\r\n    height: calc(2.75rem + 2px);\r\n    color: #8898aa;\r\n    border: 1px solid #f1f3f7;\r\n    border-radius: 0.25rem;\r\n    background-color: #f1f3f7 !important;\r\n}\r\n\r\n.css-t3ipsp-control, .css-1fdsijx-ValueContainer, .css-qbdosj-Input{\r\n    height: calc(2.75rem + 2px) !important;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
