import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { displaySuccessToast } from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import Loader from "../../../components/loader/loader";
import Header from "../../../components/header/header";
import AdminSideNav from "./admin_side_nav";
import TextEditor from "../../../components/text_editor/text_editor";
import Footer from "../../../components/footer/footer";
import { addPage } from "../../../services/blogs";

export default function AddHelpCenterArticle() {
  // const addPage = useBlogs((state) => state.addPage);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // HANDLING UPLOADING THE CURRENCY
  const handleAddPage = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setIsLoading(true);
    if (Object.keys(errors).length === 0) {
      const data = {
        title,
        content,
      };
      await addPage(data);
      navigate("/manage_pages");
      displaySuccessToast("Successfully added");
      setIsLoading(false);
    } else {
      console.log("SOMETHING WENT WRONG-");
      setErrors(errors);
      setIsLoading(false);
    }
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING TITLE
    if (title.trim() === "") {
      errors.title = "Title is required";
    }

    // VALIDATING content
    if (content.trim() === "") {
      errors.content = "Content is required";
    }

    return errors;
  }

  // HANDLE DESCRIPTION
  const handleContent = (e) => {
    setContent(e);
  };

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">
              Manage Pages
            </h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <Link to="/" className="dark">
                    <i className="fas fa-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin_dashboard" className="dark">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/manage_pages">Manage pages</Link>
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_pages"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="help_center_article"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleAddPage}
                    >
                      <div id="add_new_page">
                        {/* PAGE CONTENT */}

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Page content
                          </legend>
                          <div className="a2lix_translations">
                            <div className="a2lix_translationsFields tab-content">
                              <div
                                id="help_center_article_translations_en_a2lix_translations-fields"
                                className="tab-pane show active "
                                role="tabpanel"
                              >
                                <div id="add_page">
                                  {/* TITLE */}
                                  <div className="form-group">
                                    <label
                                      htmlFor="add_page"
                                      className="required"
                                    >
                                      Title
                                    </label>

                                    {errors.title && (
                                      <span
                                        style={{ marginTop: 0, color: "red" }}
                                      >
                                        {errors.title}
                                      </span>
                                    )}
                                    <input
                                      type="text"
                                      id="add_page_1"
                                      name="title"
                                      required="required"
                                      maxLength={80}
                                      pattern=".{1,}"
                                      className="form-control"
                                      onChange={(e) => setTitle(e.target.value)}
                                    />
                                  </div>

                                  {/* ARTICLE CONTENT */}
                                  <div className="form-group">
                                    <label
                                      htmlFor="add_page_1"
                                      className="required"
                                    >
                                      Content
                                    </label>

                                    {errors.content && (
                                      <span
                                        style={{ marginTop: 0, color: "red" }}
                                      >
                                        {errors.content}
                                      </span>
                                    )}
                                    <TextEditor
                                      value={content}
                                      onChange={handleContent}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            name="help_center_article[save]"
                            className="btn btn-primary btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
