import React, { useEffect, useState } from "react";
import Header from "../header/header";
import {
  FaFacebookF,
  FaMapMarkerAlt,
  FaCalendarDay,
  FaCopy,
  FaWhatsapp,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import "./EventDetail.css";
import AboutEvent from "./AboutEvent";
import { useParams } from "react-router-dom";
import {
  convertTimestampToDateTime,
  formatDateShort,
  formatTime,
  getDate,
  getDay,
  getMonth,} from "./../../services/helpers";

import Footer from "./../footer/footer";
import { useEvents } from "../../store/event_store";
import { userStore } from "../../store/user_store";

const EventDetail = () => {
  // GETTING THE EVENT ID FROM THE URL
  const { event_id } = useParams();

  // ZUSTAND VARIABLES
  const getEvent = useEvents((state) => state.getEvent);

  // GETTING THE EVENT DETAILS
  const [eventDetails, setEventDetails] = useState({});
  const [eventDate, setEventDate] = useState({});

  // GETTING THE EVENT DETAILS
  async function getEventDetails() {
    console.log("GETTING EVENT DETAILS");
    const response = await getEvent(event_id);
    setEventDetails(response);
    console.log("THE EVENT RESPONSE");
    console.log(response);
    setEventDate(response?.event_dates[0]);
  }

  useEffect(() => {
    getEventDetails();
  }, []);

  if (!eventDetails) {
    console.log("EVENT DETAILS EMPTY");
    return;
  }

  return (
    <div>
      <Header />
      {/* BREADCRUMP */}
      <div className="px-5 py-5">
        <h4 className="title-page b dark float-xl-left mb-0">
          {eventDetails?.name}
        </h4>

        <nav className="float-xl-right mt-2 mt-xl-0">
          <ol className="breadcrumb text-white">
            <li className="breadcrumb-item">
              <a href="/en" className="dark">
                <i className="fas fa-home"></i>
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              event
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {eventDetails?.name}
            </li>
          </ol>
        </nav>
      </div>
      <div className="ml-5 mr-3 mt-4">
        <div className="row">
          {/* EVENT IMAGE */}
          <div className="col-md-5 mb-4">
            <div className="image-container">
              <img
                src={eventDetails?.main_event_image}
                alt="..."
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="">
              <span
                className="d-flex fit shadow-sm flex-column bg-white"
                style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              >
                <span style={{ fontSize: "24px" }}>
                  {eventDate.start_date &&
                    getDay(convertTimestampToDateTime(eventDate?.start_date))}
                </span>
                <span style={{ fontSize: "24px" }}>
                  {eventDate.start_date &&
                    getDate(convertTimestampToDateTime(eventDate?.start_date))}
                </span>
                <span style={{ fontSize: "24px" }}>
                  {eventDate.start_date &&
                    getMonth(convertTimestampToDateTime(eventDate?.start_date))}
                </span>
              </span>

              {/* EVENT NAME */}
              <div>
                <h1 style={{ fontSize: "48px" }} className="py-3 fw-bold">
                  {eventDetails?.name}
                </h1>
              </div>
              <div
                className="shadow p-3 mb-5 bg-body d-flex flex-column"
                style={{ borderRadius: "16px" }}
              >
                <div className="pl-3">
                  {/* EVENT VENUE */}
                  <h5 className="mb-4">
                    <FaMapMarkerAlt size={20} style={{ color: "#F76300" }} />
                    <span className="ml-3" style={{ fontSize: "20px" }}>
                      {eventDate?.venue}
                    </span>
                  </h5>

                  {/* EVENT DATE */}
                  <h5 className="mb-4">
                    <FaCalendarDay size={20} style={{ color: "#F76300" }} />
                    <span className="ml-3" style={{ fontSize: "20px" }}>
                      {eventDate.start_date &&
                        formatDateShort(
                          convertTimestampToDateTime(eventDate?.start_date)
                        )}
                    </span>
                  </h5>

                  {/* EVENT TIME */}
                  <span className="mb-3 h5">
                    <MdAccessTimeFilled size={20} style={{ color: "F76300" }} />
                    <span className="ml-3" style={{ fontSize: "20px" }}>
                      {eventDate.start_date &&
                        formatTime(
                          convertTimestampToDateTime(eventDate?.start_date)
                        )}{" "}
                      -{" "}
                      {eventDate.end_date &&
                        formatTime(
                          convertTimestampToDateTime(eventDate?.end_date)
                        )}
                    </span>
                  </span>
                </div>
                <div className="ml-3 mt-5">
                  <h6 style={{ fontSize: "16px" }}>Share Event</h6>
                  <div className="row gap-2 mx-0">
                    {/* FACEBOOK */}
                    <div
                      className="button_facebook d-flex align-items-center"
                      style={{ width: "135px", height: "30px" }}
                    >
                      <FaFacebookF size={15} />
                      <span className="fw-bolder ml-2">Share</span>
                    </div>

                    {/* TWITTER */}
                    <div
                      className="button_twitter d-flex align-items-center"
                      style={{ width: "135px", height: "30px" }}
                    >
                      <FaTwitter size={20} />
                      <span className="fw-bolder ml-2">Tweet</span>
                    </div>

                    {/* SHARE */}
                    <div
                      className="button_linkedIn d-flex align-items-center"
                      style={{ width: "135px", height: "30px" }}
                    >
                      <FaLinkedin size={20} />
                      <span className="fw-bolder ml-2">Share</span>
                    </div>

                    {/* WHATSAPP */}
                    <div
                      className="button_whatsapp d-flex align-items-center"
                      style={{ width: "135px", height: "30px" }}
                    >
                      <FaWhatsapp size={20} />
                      <span className="fw-bolder ml-2">Share</span>
                    </div>

                    {/* COPY LINK */}
                    <div
                      className="button_copy d-flex align-items-center"
                      style={{ width: "135px", height: "30px" }}
                    >
                      <FaCopy size={20} />
                      <span className="fw-bolder ml-2">Copy-link</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BOTTOM COMPONENT FOR EVENT DESCRIPTION AND TICKETS */}
      <AboutEvent
        description={eventDetails?.description}
        tickets={eventDate?.tickets ?? []}
        event={eventDetails}
      />
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default EventDetail;
