import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { tableStore } from "../../store/table_store";
import { displayWarningToast } from "../../services/helpers";

export default function UserSignedTablesModal(props) {
  const {
    incrementTableQuantity,
    decrementTableQuantity,
    table_id,
    venue_id,
    onHide,
    table_name,
    table_index,
    tableQuantity,
    getSelectedTables,
  } = props;

  const rooms = tableStore((state) => state.rooms);
  const room = rooms.find((room) => room?.venue?.value === venue_id);

  // State to keep track of selected tables
  const [selectedTables, setSelectedTables] = useState([]);
  // console.log(" the selected tables are:", selectedTables);

  const getTableImage = (numberOfSeats, tableType, tableId) => {
    const isSelected = selectedTables[tableId];

    const imagePrefix = isSelected
      ? "grey"
      : tableType === "VIP"
      ? "blue"
      : "brown";

    return require(`./../../assets/table_images/${numberOfSeats}${imagePrefix}.svg`);
  };

  // taggle the table(select or unselect the table)
  const handleTableClick = (tableId, selectedTable) => {
    if (selectedTable === table_name) {
      setSelectedTables((prevSelectedTables) => {
        const isSelected = !prevSelectedTables[tableId];

        // Trigger increment or decrement based on selection state
        isSelected
          ? incrementTableQuantity(table_index, tableQuantity)
          : decrementTableQuantity(table_index, tableQuantity);

        //create a new array with the updated selected state
        const updatedSelectedTables = { ...prevSelectedTables };

        //add or remove the table from th array based on selection state
        if (isSelected) {
          updatedSelectedTables[tableId] = room?.tables.find(
            (table) => table.id === tableId
          );
        } else {
          delete updatedSelectedTables[tableId];
        }

        //pass the most up_to date selected tables to the parent
        getSelectedTables(updatedSelectedTables);
        // Return the updated state
        return updatedSelectedTables;
      });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className=" fw-bold ">
            Select <span className="text-danger"> {table_name} table </span> of
            your choice
          </p>
          <div className=" d-flex gap-2">
            {room?.tableTypes?.map((table_type) => (
              <div key={table_type?.id}>
                <div
                  className="border"
                  style={{
                    backgroundColor: table_type?.table_color,
                    height: 30,
                    width: 40,
                    borderRadius: 8,
                  }}
                ></div>
                <p className=" h6 fw-medium  ">
                  {table_type?.table_type} Table
                </p>
              </div>
            ))}
            <span>
              <div
                className="border"
                style={{
                  backgroundColor: "#C8C8C8",
                  height: 30,
                  width: 40,
                  borderRadius: 8,
                }}
              ></div>
              <p className=" h6 fw-medium  ">Booked Table</p>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 bg-gray p-3">
          <div className="bg-white">
            {/* THE STAGE */}
            <div
              className=" bg-success mt-2 mb-5 "
              style={{ marginLeft: 100, marginRight: 100 }}
            >
              <p className=" text-center px-5 py-3 cursor-pointer text-white fw-bold fs-6 ">
                Stage
              </p>
            </div>
            <div className="row mx-5 position-relative">
              {/* Filter tables based on table_name prop and map over them */}
              {room?.tables?.length > 0 &&
                room?.tables.map((table) => (
                  <div
                    className={`col py-3 d-flex flex-column align-items-center ${
                      table?.selectedTable !== table_name || table?.isBooked
                        ? " disabled-table "
                        : ""
                    } `}
                    key={table?.id}
                    onClick={(e) =>
                      handleTableClick(table?.id, table?.selectedTable)
                    }
                  >
                    <span className=" position-absolute top-50 translate-middle cursor-pointer text-white fw-bold">
                      {table?.tableLabel}
                    </span>
                    <img
                      src={
                        table?.isBooked
                          ? require(`./../../assets/table_images/${table?.numberOfSeatsPerTable}grey.svg`)
                          : getTableImage(
                              table?.numberOfSeatsPerTable,
                              table?.selectedTable,
                              table?.id
                            )
                      }
                      width={120}
                      height={80}
                      className="cursor-pointer"
                      alt="No table"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
