import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Timestamp,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import app from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { Alert, Snackbar } from "@mui/material";
import { getEventReviews } from "./reviews";
import { displayErrorToast, displaySuccessToast } from "./helpers";

// INITILIZE FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////// METHOD TO ADD A NEW EVENT CATEGORY //////////////////////////////
export async function addNewEventcategory({ name, icon, image, displayorder }) {
  // CREATING A COLLECTION

  const eventscategoryCollection = collection(db, "events_category");
  // ADDING THE DATA TO FIRESTORE
  const data = {
    name: name,
    icon: icon,
    image: image,
    displayorder: displayorder,
    isVisible: true,
    isFeatured: true,
  };

  const responseFromFirestore = await addDoc(eventscategoryCollection, data);
  const eventsCategory_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const eventsCategoryDoc = doc(db, "events_category", eventsCategory_id);

  // UPDATING THE EVENTS CATEGORY ID
  await updateDoc(eventsCategoryDoc, {
    id: eventsCategory_id,
  });
}

// METHOD TO ADD A NEW EVENT TO THE DATABASE
export async function addEvent({
  event_id,
  category,
  name,
  description,
  tags,
  main_event_image,
  show_attendees,
  enable_reviews,
  languages,
  subtitles,
  year,
  audiences,
  country,
  video_link,
  external_link,
  phone_number,
  email,
  twitter_link,
  facebook_link,
  instagram_link,
  google_plus_link,
  linkedin_link,
  artists,
  gallery,
  organizer_id,
  event_dates,
}) {
  // CREATING THE EVENTS COLLECTION
  const eventsDoc = doc(db, "events", event_id);

  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: event_id || null,
    name: name || null,
    description: description || null,
    organizer_id: organizer_id || null,
    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
    main_event_image: main_event_image || null,
    gallery: gallery || null,
    status: "",
    views: 0,
    category: category || null,
    audiences: audiences || null,
    country: country || null,
    tags: tags || null,
    video_link: video_link || null,
    show_attendees: show_attendees || null,
    external_link: external_link || null,
    email: email || null,
    phone_number: phone_number || null,
    facebook_link: facebook_link || null,
    twitter_link: twitter_link || null,
    instagram_link: instagram_link || null,
    linkedin_link: linkedin_link || null,
    google_plus_link: google_plus_link || null,
    artists: artists || null,
    year: year || null,
    enable_reviews: enable_reviews || null,
    languages: languages || null,
    subtitles: subtitles || null,
    event_dates: event_dates,
  };

  // console.log("EVENT DATA: " + Object.values(data));

  try {
    await setDoc(eventsDoc, data);
    console.log("event added");
    return "success";
  } catch (error) {
    console.log("something went wrong adding event: " + error);
    return error;
  }
}

// METHOD TO UPDATE AN EVENT
export async function updateEvent(data) {
  // CREATING THE EVENTS DOC
  const eventsDoc = doc(db, "events", data.event_id);

  try {
    await updateDoc(eventsDoc, data);
    return true;
  } catch (error) {
    console.log("something went wrong adding event: " + error);
    displayErrorToast("Something went wrong updating EVENT", error);
    return false;
  }
}

// METHOD TO UPDATE AN EVENT DATE
export async function updateEventDate(data) {
  // CREATING THE EVENTS DOC
  const eventDateDoc = doc(db, "event_dates", data.id);

  try {
    await updateDoc(eventDateDoc, data);
    return true;
  } catch (error) {
    if (error.code === "not-found") {
      try {
        setDoc(eventDateDoc, data);
        return true;
      } catch (error) {
        console.log("something went wrong adding NEW EVENT DATE: " + error);
        return false;
      }
    }
    console.log("something went wrong adding event: " + error);
    displayErrorToast("Something went wrong updating EVENT DATE", error);
    return false;
  }
}

// METHOD TO UPDATE THE TICKET
export async function updateTicket(data) {
  // CREATING THE EVENTS DOC
  const ticketDoc = doc(db, "tickets", data.ticket_id);

  try {
    await updateDoc(ticketDoc, data);
    return true;
  } catch (error) {
    if (error.code === "not-found") {
      console.log("DOCUMENT NOT FOUND ERROR");
      try {
        setDoc(ticketDoc, data);
        return true;
      } catch (error) {
        console.log("something went wrong adding NEW TICKET: " + error);
      }
    } else {
      console.log("SOME OTHER ERROR");
    }

    console.log("something went wrong adding event: " + error);
    displayErrorToast("Something went wrong updating event Ticket", error);
    return false;
  }
}

// METHOD TO GET EVENTS CATEGORIES
export async function getEventsCategories() {
  var event_categories = [];
  const userDoc = collection(db, "events_category");
  const querySnapshot = await getDocs(userDoc);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    event_categories.push(querySnapshot.docs.at(i).data());
  }

  return event_categories;
}

//GETTING SPECIFIC CATEGORY
export const getSpecificCategory = async (categoryID) => {
  const categoryDoc = doc(db, "events_category", categoryID);
  const querySnapshot = await getDoc(categoryDoc);
  const category = querySnapshot.data();
  return category;
};

// METHOD TO DELETE AN EVENT CATEGORY
export const deleteCategory = async (categoryID) => {
  console.log("GOING TO DELETE A CATEGORY WITH ID:", categoryID);
  try {
    // Get the category details
    const categoryDetails = await getSpecificCategory(categoryID);
    console.log("Specific category retrieved:", categoryDetails);

    // Delete the category image from the storage
    const categoryImage = categoryDetails?.image;
    try {
      const storageRef = ref(storage, categoryImage);
      await deleteObject(storageRef);
      console.log("Category image deleted");
    } catch (error) {
      console.log("Category image not deleted", error);
      displayErrorToast("Category image not deleted", error);
    }

    // Delete the category document
    const docRef = doc(db, "events_category", categoryID);
    await deleteDoc(docRef);
    console.log("Category deleted");
    return true;
  } catch (error) {
    console.log("Something went wrong while deleting the category", error);
    return false;
  }
};

// METHOD TO GET EVENTS CATEGORIES THAT ARE VISIBLE
export async function getVisibleEventsCategories() {
  const event_categories = [];
  const eventsCategoryRef = collection(db, "events_category");
  try {
    const eventsCategoryQuery = query(
      eventsCategoryRef,
      where("isVisible", "==", true)
    );
    const querySnapshot = await getDocs(eventsCategoryQuery);

    querySnapshot.forEach((doc) => {
      event_categories.push(doc.data());
    });
  } catch (error) {
    console.log(
      "something went wrong getting visible events categories: " + error
    );
  }

  return event_categories;
}

// METHOD TO GET AUDIENCES
export async function getAudiences() {
  var audiences = [];
  const userDoc = collection(db, "audiences");
  const querySnapshot = await getDocs(userDoc);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    audiences.push(querySnapshot.docs.at(i).data());
  }

  return audiences;
}

//a method to det specific audience
export const getSpecificAudience = async (audience_id) => {
  const audienceDoc = doc(db, "audiences", audience_id);
  const querySnapshot = await getDoc(audienceDoc);
  const audience = querySnapshot.data();
  console.log("audience:", audience);
  return audience;
};

export const deleteAudience = async (audience_id) => {
  console.log("Going to delete an audience with ID:", audience_id);

  try {
    const audienceRef = doc(db, "audiences", audience_id);

    // Get the audience details
    const audienceDetails = await getSpecificAudience(audience_id);
    console.log("Specific audience retrieved:", audienceDetails);

    // Delete the audience image from storage
    try {
      const storageRef = ref(storage, audienceDetails?.image);
      await deleteObject(storageRef);
      console.log("Audience image deleted");
    } catch (error) {
      console.log("Audience image not deleted", error);
      displayErrorToast("Audience image not deleted", error);
    }
    // Delete the audience document from Firestore
    await deleteDoc(audienceRef);
    console.log("Audience deleted successfully");

    // Display a success toast or notification to the user
    displaySuccessToast("Audience deleted successfully");

    return true; // Return true to indicate successful deletion
  } catch (error) {
    console.log("Something went wrong while deleting the audience", error);
    displayErrorToast("Failed to delete audience", error);

    return false; // Return false to indicate deletion failure
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// METHOD TO GET COUNTRIES LIST
export async function getCountriesList() {
  var countries = [];
  const userDoc = collection(db, "countries");
  const querySnapshot = await getDocs(userDoc);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    countries.push(querySnapshot.docs.at(i).data());
  }

  return countries;
}

//A METHOD TO GET SPECIFIC COUNTRY
export const getSpecificCountry = async (country_id) => {
  const countryDoc = doc(db, "countries", country_id);
  const querySnapshot = await getDoc(countryDoc);
  const country = querySnapshot.data();
  console.log("country:", country);
  return country;
};

// METHOD TO DELETE COUNTRY
export const deleteCountry = async (country_id) => {
  console.log("Going to delete a country with ID:", country_id);
  const countryRef = doc(db, "countries", country_id);
  try {
    await deleteDoc(countryRef);
    console.log("Country deleted successfully");
    // Display a success toast or notification to the user
    displaySuccessToast("Country deleted successfully");
    return true;
  } catch (error) {
    console.log("Something went wrong while deleting the country", error);
    return false;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

//get a specific event by id
export async function getEventById(event_id) {
  // console.log("GETTING order: " + order_id);
  const docRef = doc(db, "events", event_id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return { id: docSnap.id, ...data };
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }
}

// METHOD TO GET ALL ORGANIZER EVENTS
export async function getOrganizerEvents(organizer_id) {
  var events = [];
  const userDoc = collection(db, "events");

  const q = query(userDoc, where("organizer_id", "==", organizer_id));

  const querySnapshot = await getDocs(q);

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const fullEvent = await getFullEventDetails(
      querySnapshot.docs.at(i).data().id
    );
    events.push(fullEvent);
  }

  // console.log(`NUMBER OF EVENTS FOR ${organizer_id}: ${events.length}`);
  return events;
}

// METHOD TO GET ALL ORGANIZER FOLLOWERS
export async function getOrganizerFollowers(organizer_id) {
  console.log("GETTING FOLLOWERS FOR: " + organizer_id);

  var followers = [];
  const followersCollection = collection(db, "following");

  const q = query(
    followersCollection,
    where("organizer_id", "==", organizer_id)
  );

  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    followers.push(querySnapshot.docs.at(i).data());
  }

  return followers;
}

// METHOD TO GET ALL ORGANIZER REVIEWS
export async function getOrganizerReviews(organizerEvents) {
  // console.log("THE LENGTH OF ORGANIZER EVENTS: " + organizerEvents.length);

  var organizerReviews = [];

  for (let i = 0; i < organizerEvents.length; i++) {
    const eventReviews = await getEventReviews(organizerEvents[i].id);
    if (eventReviews.length > 0) {
      organizerReviews.push(eventReviews);
    }
  }

  return organizerReviews;
}

// METHOD TO GET all UPCOMING EVENTS from database
export async function getUpcomingEvents() {
  var upcomingEvents = [];
  const today = new Date();

  const userDoc = collection(db, "event_dates");

  const q = query(userDoc, where("start_date", ">=", today));

  const querySnapshot = await getDocs(q);

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const eventDate = querySnapshot.docs.at(i).data();

    const event_id = eventDate?.event_id;

    // GETTING THE EVENT DETAILS
    const event = await getSpecificEvent(event_id);

    if (!event) continue;

    const tickets = [];

    // GETTING THE TICKET DETAILS
    for (let k = 0; k < eventDate.ticket_ids.length; k++) {
      const ticket = await getTicket(eventDate.ticket_ids[k]);

      tickets.push(ticket);
    }

    var venue = "";

    if (eventDate.venue) {
      venue = eventDate?.venue?.label;
    } else {
      venue = null;
    }

    // CREATING UPCOMING EVENT OBJECT
    const upcoming_event = {
      event_id: event?.id,
      image: event?.main_event_image,
      name: event?.name,
      category: event?.category.label,
      venue: venue,
      start_date: eventDate?.start_date.toDate(),
      price: tickets[0]?.ticket_price,
      promotional_price: tickets[0]?.promotional_price,
    };

    // adding upcoming event to list
    upcomingEvents.push(upcoming_event);
  }

  return upcomingEvents;
}

// METHOD TO GET ORGANIZER UPCOMING EVENTS
export async function getOrganizerUpcomingEvents(events) {
  const today = new Date();

  // FILTERING OUT UPCOMING EVENTs
  const upcomingEvents = events.filter((event) => {
    if (
      event.event_dates &&
      Array.isArray(event.event_dates) &&
      event.event_dates.length > 0 &&
      event.event_dates[0].start_date
    ) {
      const eventStartDate = new Date(event.event_dates[0].start_date);
      return eventStartDate >= today;
    }
    return false;
  });

  return upcomingEvents;
}

// METHOD TO GET ORGANIZER PAST EVENTS
export async function getOrganizerPastEvents(events) {
  const today = new Date();

  var pastEvents = events.filter((event) => {
    if (
      event.event_dates &&
      Array.isArray(event.event_dates) &&
      event.event_dates.length > 0 &&
      event.event_dates[0].start_date
    ) {
      const eventStartDate = new Date(event.event_dates[0].start_date);
      return eventStartDate <= today;
    }
    return false;
  });

  return pastEvents;
}

// METHOD TO GET UPCOMING EVENTS BY A GIVEN ORGANIZER
export async function getUpcomingEventsbyOrganizerId(organizer_id) {
  var upcomingEvents = [];
  const today = new Date();

  const userDoc = collection(db, "event_dates");

  const q = query(
    userDoc,
    where("start_date", ">=", today),
    where("organizer_id", "==", organizer_id)
  );

  const querySnapshot = await getDocs(q);

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const eventDate = querySnapshot.docs.at(i).data();

    const event_id = eventDate.event_id;

    // GETTING THE EVENT DETAILS
    const event = await getSpecificEvent(event_id);
    const tickets = [];

    // GETTING THE TICKET DETAILS
    for (let k = 0; k < eventDate.ticket_ids.length; k++) {
      const ticket = await getTicket(eventDate.ticket_ids[k]);

      if (ticket.sales_start_date.toDate() >= today) {
        tickets.push(ticket);
      }
    }

    var venue = "";

    if (eventDate.venue) {
      venue = eventDate.venue.label;
    } else {
      venue = null;
    }

    // CREATING UPCOMING EVENT OBJECT
    const upcoming_event = {
      event_id: event.id,
      image: event.main_event_image,
      name: event.name,
      category: event.category.label,
      venue: venue,
      start_date: eventDate.start_date.toDate(),
      price: tickets[0].ticket_price,
      promotional_price: tickets[0].promotional_price,
    };

    // adding upcoming event to list
    upcomingEvents.push(upcoming_event);
  }

  return upcomingEvents;
}

// METHOD TO GET FULL DETAILS OF AN EVENT
export async function getFullEventDetails(event_id) {
  // console.log("GETTING FULL EVENT DETAILS FOR EVENT: " + event_id);
  const eventDoc = doc(db, "events", event_id);
  const eventSnapshot = await getDoc(eventDoc);

  if (eventSnapshot.exists()) {
    const event = eventSnapshot.data();
    const eventDates = [];

    if (event.event_dates && Array.isArray(event.event_dates)) {
      for (var i = 0; i < event.event_dates.length; i++) {
        const eventDate = await getEventDate(event.event_dates[i]);

        if (
          eventDate &&
          eventDate.ticket_ids &&
          Array.isArray(eventDate.ticket_ids)
        ) {
          const tickets = [];
          for (var k = 0; k < eventDate.ticket_ids.length; k++) {
            const ticket = await getTicket(eventDate.ticket_ids[k]);
            tickets.push(ticket);
          }

          eventDate.tickets = tickets;
          eventDates.push(eventDate);
        }
      }
    }

    event.event_dates = eventDates;

    return event;
  } else {
    // Handle the case where the event does not exist
    return null;
  }
}

// METHOD TO GET THE USER'S FAVORITE EVENTS
export async function getFavoriteEvents(user_id) {
  var favoriteEvents = [];
  const userDoc = collection(db, "favorites");

  try {
    var q = query(userDoc, where("user_id", "==", user_id));
    const querySnapshot = await getDocs(q);

    for (var i = 0; i < querySnapshot.docs.length; i++) {
      const favorite = querySnapshot.docs.at(i).data();
      var response = await getFullEventDetails(favorite.event_id);
      console.log(response);
      favoriteEvents.push(response);
    }
  } catch (error) {
    console.log("SOMETHING WENT WRONG GETTING FAVORITE EVENTS: " + error);
  }

  return favoriteEvents;
}

// METHOD TO DISPLAY A SNACKBAR
export function displaySnackbar({ message, color, onClose, open }) {
  return (
    <Snackbar
      color="green"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={() => onClose(false)}
    >
      <Alert
        color={color}
        sx={{ width: "100%" }}
        variant={"filled"}
        onClose={() => onClose(false)}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

// METHOD TO GET THE USER'S FAVORITE EVENTS
export async function removeFromFavorites({ eventId, userId }) {
  const favoritesCollection = collection(db, "favorites");
  const q = query(
    favoritesCollection,
    where("event_id", "==", eventId),
    where("user_id", "==", userId)
  );

  try {
    const querySnapshot = await getDocs(q);

    // Delete the document where the event_id field matches the given ID
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });

    console.log("Document(s) deleted.");
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// METHOD TO DELETE A SPECIFIC TICKET OF AN ORGANIZER
export async function deleteTicket(ticket_id) {
  const ticketDoc = doc(db, "tickets", ticket_id);

  try {
    deleteDoc(ticketDoc);
    console.log("Ticket deleted.");
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// METHOD TO DELETE A SPECIFIC OF AN ORGANISER EVENT DATE
export async function deleteEventDate(event_date_id) {
  const eventDateDoc = doc(db, "event_dates", event_date_id);

  try {
    deleteDoc(eventDateDoc);
    console.log("Event Date deleted.");
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// METHOD TO GET ALL EVENT DATES FOR AN EVENT
export async function getEventDatesForEvent(event_id) {
  // RETURNING EMPTY LIST IF EVENT ID IS NULL
  if (!event_id) {
    return [];
  }

  // console.log("GETTING EVENT DATE FOR: " + event_id);

  var event_dates = [];
  const userDoc = collection(db, "event_dates");

  const q = query(userDoc, where("event_id", "==", event_id));

  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    event_dates.push(querySnapshot.docs.at(i).data());
  }

  console.log("THE LENGTH OF EVENT DATES: " + event_dates.length);

  return event_dates;
}

// // METHOD TO GET ALL EVENT DATES FOR ALL ORGANIZERS
// export async function getEventDatesForAllOrganizers() {
//   const organizersDocRef = collection(db, "organizers");
//   const organizersSnapshot = await getDocs(organizersDocRef);

//   var event_dates = [];

//   for (let i = 0; i < organizersSnapshot.docs.length; i++) {
//     const organizerDoc = organizersSnapshot.docs[i];
//     const organizerId = organizerDoc.id;
//     const eventsDocRef = collection(db, "events");
//     const q = query(eventsDocRef, where("organizer_id", "==", organizerId));
//     const eventsSnapshot = await getDocs(q);

//     for (let j = 0; j < eventsSnapshot.docs.length; j++) {
//       const eventDoc = eventsSnapshot.docs[j];
//       const event = eventDoc.data();
//       const eventDates = await getEventDatesForEvent(event.id);
//       event_dates.push(...eventDates);
//     }
//   }
//   // console.log("EVENT DATES FOR ALL ORGANIZERS: ", event_dates);
//   // console.log("NUMBER OF EVENT DATES: ", event_dates.length);

//   return event_dates;
// }

//ANOTHER METHOD TO GET EVENT DATES FOR ALL ORGANIZERS
// export const getAllEventDates = async () => {
//   const dates = [];
//   const datesDoc = collection(db, "event_dates");
//   const querySnapshot = await getDocs(datesDoc);

//   for (let i = 0; i < querySnapshot.docs.length; i++) {
//     dates.push(querySnapshot.docs.at(i).data());
//   }
//   return dates;
// };

// METHOD TO GET ATTENDEES FOR AN EVENT
export async function getAttendees(event_id) {
  var attendees = [];
  const rsvpCollection = collection(db, "rsvp");

  const q = query(rsvpCollection, where("event_id", "==", event_id));

  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    attendees.push(querySnapshot.docs.at(i).data());
  }

  console.log("THE LENGTH OF ATTENDEES GOT: " + attendees.length);

  return attendees;
}

// METHOD TO GET ALL EVENT DATES FOR AN ORGANIZER
export async function getEventDatesNumForOrganizer(organizerId) {
  var num_of_event_dates = 0;

  const userDoc = collection(db, "events");

  const q = query(userDoc, where("organizer_id", "==", organizerId));

  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    var event = querySnapshot.docs.at(i).data();
    var response = await getEventDatesForEvent(event.id);
    var count = response.length;
    num_of_event_dates += count;
  }

  console.log("NUMBER OF EVENT DATES: " + num_of_event_dates);

  return num_of_event_dates;
}

// METHOD TO GET ALL TICKETS FOR AN EVENT DATE
export async function getTicketsForEventDate(event_date_id) {
  console.log("GETTING TICKETS FOR EVENT DATE: " + event_date_id);
  if (!event_date_id) {
    return [];
  }

  var tickets = [];
  const ticketsCollection = collection(db, "tickets");

  const q = query(
    ticketsCollection,
    where("event_date_id", "==", event_date_id)
  );

  const querySnapshot = await getDocs(q);

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    tickets.push(querySnapshot.docs.at(i).data());
  }

  return tickets;
}

// METHOD TO GET AN EVENT DATE
export async function getEventDate(eventDateId) {
  const userDoc = doc(db, "event_dates", eventDateId);
  const querySnapshot = await getDoc(userDoc);
  const eventDate = querySnapshot.data();

  return eventDate;
}

// METHOD TO GET AN EVENT DATE
export async function getSpecificEvent(event_id) {
  const eventDoc = doc(db, "events", event_id);
  const querySnapshot = await getDoc(eventDoc);
  const event = querySnapshot.data();

  return event;
}

// METHOD TO GET A TICKET
export async function getTicket(ticket_id) {
  const userDoc = doc(db, "tickets", ticket_id);
  const querySnapshot = await getDoc(userDoc);
  const ticket = querySnapshot.data();
  return ticket;
}
//////////////////////////////////////////////////////////////////////
// METHOD TO GET LANGUAGES LIST
export async function getLanguages() {
  var languages = [];
  const userDoc = collection(db, "languages");
  const querySnapshot = await getDocs(userDoc);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    languages.push(querySnapshot.docs.at(i).data());
  }

  return languages;
}
// a method to get a alanguage
export async function getLanguage(language_id) {
  const languageDoc = doc(db, "languages", language_id);
  const querySnapshot = await getDoc(languageDoc);
  const language = querySnapshot.data();
  return language;
}

//A METHOD TO DELETE A LANGUAGE
export async function deleteLanguage(language_id) {
  try {
    const languageRef = doc(db, "languages", language_id);
    await deleteDoc(languageRef);
    console.log("language deleted successfully");
    return true;
  } catch (error) {
    console.log("Something went wrong while deleting the language", error);
    return false;
  }
}
///////////////////////////////////////////////////////////////////////////

//  METHOD TO ADD EVENTY CATEGORY IMAGE
export const uploadeventsCategoryImage = async (imageFile) => {
  if (!imageFile) {
    return;
  }

  const storage = getStorage(app);
  const storageRef = ref(storage, "events_category/images/" + imageFile.name);

  try {
    await uploadBytes(storageRef, imageFile);

    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image", error);
  }
};
//handle delete Category Image Url
export const deleteCategoryImageUrl = async (imageUrl) => {
  const storage = getStorage(app);
  const imageRef = ref(storage, imageUrl);
  try {
    await deleteObject(imageRef);
    console.log("category image deleted successfully");
  } catch (error) {
    console.log("Error deleting image", error);
    throw error;
  }
};

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////// METHOD TO ADD A NEW COUNTRY///////////////////////////////////
export async function addNewCountry({ name, countryCode }) {
  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: null,
    name: name,
    countryCode: countryCode,
    isVisible: true,
  };
  try {
    //creating a document
    const countryCol = collection(db, "countries");
    const response = await addDoc(countryCol, data);
    const country_id = response.id;

    const countriesDoc = doc(db, "countries", country_id);
    await updateDoc(countriesDoc, { id: country_id });
    return true;
  } catch (error) {
    console.log("ERROR ADDING COUNTRY:", error);
    return false;
  }
}

///////////////////////////////////////////////////////////////////////////////
/////////////////// METHOD TO ADD TO FAVORITES ////////////////////////////////
export async function addToFavorites({ event_id, user_id }) {
  console.log("EVENT ID: " + event_id + " USER ID: " + user_id);
  const favoritesCollection = collection(db, "favorites");

  // ADDING THE DATA TO FIRESTORE
  const data = {
    created_at: Timestamp.fromDate(new Date()),
    event_id: event_id,
    user_id: user_id,
  };

  try {
    // CREATING A DOCUMENT
    const favoritesDoc = await addDoc(favoritesCollection, data);
    return true;
  } catch (error) {
    console.log("SOMETHING WENT WRONG ADDING TO FAVORITES: " + error);
    return false;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
///////////////////   METHOD TO ADD A NEW LANGUAGE    ///////////////////////////////////
export async function addNewLanguage({ name, languageCode }) {
  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: null,
    name: name,
    languageCode: languageCode,
    isVisible: true,
  };

  try {
    // CREATING A DOCUMENT
    const langaugesCol = collection(db, "languages");
    var response = await addDoc(langaugesCol, data);
    var languageId = response.id;

    var languagesDoc = doc(db, "languages", languageId);

    await updateDoc(languagesDoc, { id: languageId });
    return true;
  } catch (error) {
    console.log("ERROR ADDING LANGUAGE: " + error);
    return false;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
///////////////////   ADD ORDER TO CART   ///////////////////////////////////
export async function addToCart({ cart_items, user_id }) {
  console.log("ADD TO CART CALLED");

  // CREATING A COLLECTION
  const cartCollection = collection(db, "cart");

  // CHECKING THE USER HAS A CART
  try {
    const q = query(cartCollection, where("user_id", "==", user_id));
    const querySnapshot = await getDocs(q);
    console.log("QUERY SNAPSHOT LENGTH: " + querySnapshot.docs.length);

    // IF THE USER HAS A CART
    if (querySnapshot.docs.length > 0) {
      console.log("USER HAS A CART ALREADY");
      const cart_id = querySnapshot.docs.at(0)?.id;
      console.log("CART ID: " + cart_id);

      // CREATING A DOCUMENT
      const cartDoc = doc(db, "cart", cart_id);

      // UPDATING THE CART
      for (var i = 0; i < cart_items.length; i++) {
        console.log("THE CART ITEM WE ARE ADDING: " + cart_items[i]);
        await updateDoc(cartDoc, {
          cart_items: arrayUnion(cart_items[i]),
        });
      }

      return "success";
    }

    // IF THE USER DOES NOT HAVE A CART
    else {
      console.log("USER DOES NOT HAVE A CART");

      const data = {
        cart_items: cart_items,
        user_id: user_id,
        date_added: Date(),
      };

      try {
        const responseFromFirestore = await addDoc(cartCollection, data);
        const order_id = responseFromFirestore.id;

        // CREATING A DOCUMENT
        const orderDoc = doc(db, "cart", order_id);

        // UPDATING THE CART ORDER ID
        await updateDoc(orderDoc, {
          id: order_id,
        });

        return "success";
      } catch (error) {
        return error;
      }
    }
  } catch (error) {
    console.log("ERROR GETTING CART ITEMS: " + error);
  }
}

///////////////////////////////////////////////////////////////
///////////////////  UPDATE CART   ///////////////////////////
export async function updateCart({ cart_id, cart_items }) {
  // CREATING A COLLECTION
  const cartCollection = doc(db, "cart", cart_id);
  // ADDING THE DATA TO FIRESTORE
  const data = {
    cart_items: cart_items,
    updated_on: Date(),
  };

  try {
    await updateDoc(cartCollection, data);
    return "success";
  } catch (error) {
    return error;
  }
}

///////////////////////////////////////////////////////////////
///////////////////  UPDATE CART   ///////////////////////////
export async function clearCart(user_id) {
  console.log("THE RECIEVED USER ID: " + user_id);

  // CREATING A COLLECTION
  const cartCollection = collection(db, "cart");

  const q = query(cartCollection, where("user_id", "==", user_id));

  const response = await getDocs(q);
  for (var i = 0; i < response.docs.length; i++) {
    const docToDelete = doc(db, "cart", response.docs[i].id);

    try {
      deleteDoc(docToDelete);
      console.log("DELETED DOC: ");
      return "success";
    } catch (error) {
      return error;
    }
  }
}

///////////////////////////////////////////////////////////////
///////////////////  UPDATE CART   ///////////////////////////
export async function removeItemFromCart(cartId, user_id, item) {
  console.log("THE RECIEVED CART ID: " + cartId);
  console.log("THE RECIEVED USER ID: " + user_id);
  console.log("THE KEYS OF THE RECEIVED ITEM: " + Object.keys(item));

  // CREATING A COLLECTION
  const cartDocRef = doc(db, "cart", cartId);
  try {
    await updateDoc(cartDocRef, {
      cart_items: arrayRemove(item),
    });
    return true;
  } catch (error) {
    console.log("ERROR DELETING ITEM FROM CART: " + error);
    return false;
  }
}

///////////////////////////////////////////////
// METHOD TO GET ALL TICKETS FOR AN EVENT DATE
export async function getCartItems(user_id) {
  const cartDoc = doc(db, "cart", user_id);
  const document = await getDoc(cartDoc);
  if (document.exists) {
    const data = document.data();
    return data?.cart_items;
  } else {
    return [];
  }
}

///////////////////////////////////////////////////////////////////////////
///////////////////   METHOD TO ADD A NEW AUDIENCE    /////////////////////
export async function addNewAudience({ name, image }) {
  console.log("the image for the audience: " + image);

  try {
    // CREATING A COLLECTION

    const audienceCollection = collection(db, "audiences");
    // ADDING THE DATA TO FIRESTORE
    const data = {
      id: null,
      name: name,
      image: image,
      isVisible: true,
    };

    const responseFromFirestore = await addDoc(audienceCollection, data);
    const audience_id = responseFromFirestore.id;

    // CREATING A DOCUMENT
    const audienceDoc = doc(db, "audiences", audience_id);

    // UPDATING THE AUDIENCE ID
    const response = await updateDoc(audienceDoc, {
      id: audience_id,
    });

    console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
    return true;
  } catch (error) {
    console.log("THE ERROR WHEN ADDING AUDIENCE: " + error);
    return false;
  }
}

//UPLOADING AUDIENCE IMAGE
export const uploadaudienceImage = async (imageFile) => {
  if (!imageFile) {
    return;
  }

  const storage = getStorage(app);
  const storageRef = ref(storage, "audiences/images/" + imageFile.name);

  try {
    await uploadBytes(storageRef, imageFile);

    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image", error);
    return false;
  }
};

//handle delete Audience Image Url
export const deleteAudienceImage = async (imageUrl) => {
  const storage = getStorage(app);
  const imageRef = ref(storage, imageUrl);

  try {
    await deleteObject(imageRef);
    console.log("Audience Image deleted");
  } catch (error) {
    console.log("Error deleting image", error);
    throw error;
  }
};

///////////////////////////////////
// METHOD TO UPLOAD EVENT IMAGES //
/////////////////////////// //////
export const uploadEventImages = async (eventImages) => {
  if (eventImages.length === 0) {
    return;
  }

  const storageRef = ref(storage, "events/images/" + eventImages.name);

  try {
    await uploadBytes(storageRef, eventImages);

    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image", error);
  }
};

/////////////////////////////////////
// METHOD TO GET EVENT IMAGES ///////
/////////////////////////////////////
export const getEventImages = async (event_id) => {
  const storageRef = ref(storage, "events/images/" + event_id);
  const imageUrl = await getDownloadURL(storageRef);
  return imageUrl;
};

/////////////////////////////////////
// METHOD TO DELETE EVENT IMAGES ///////
/////////////////////////////////////
// export const deleteEventImages = async (event_id) => {
//   const storageRef = ref(storage, "events/images/" + event_id);
//   await storageRef.delete();
// };

// METHOD TO DELETE EVENT
export const deleteEvent = async (event_id) => {
  console.log("GOING TO DELETE EVENT WITH ID :" + event_id);

  try {
    // GETTING THE EVENT DETAILS
    var eventDetails = await getSpecificEvent(event_id);

    var eventDate = await getEventDate(eventDetails.event_dates[0]);

    for (var i = 0; i < eventDate.ticket_ids; i++) {
      await deleteTicket(eventDate.ticket_ids[i]);
    }
    console.log("ALL TICKETS DELETED");

    for (var j = 0; j < eventDetails.event_dates.length; j++) {
      await deleteEventDate(eventDetails.event_dates[j]);
    }
    console.log("ALL EVENT DATES HAVE BEEN DELETED");

    // DELETE EVENT MAIN IMAGE
    //delete image from storage
    try {
      const storageRef = ref(storage, eventDetails?.main_event_image);
      await deleteObject(storageRef);
      console.log("MAIN EVENT IMAGE DELETED");
    } catch (error) {
      console.log("MAIN EVENT IMAGE NOT DELETED: " + error);
      displayErrorToast("MAIN EVENT IMAGE NOT DELETED: " + error);
    }

    // IF THE GALLERY IS NOT EMPTY, DELETE ALL THE IMAGES IN THE GALLERY
    const galleryImages = eventDetails?.gallery;

    console.log("LENGTH OF THE GALLERY IMAGES: " + galleryImages.length);
    try {
      for (let h = 0; h < galleryImages.length; h++) {
        const storageReff = ref(storage, galleryImages[h]);
        await deleteObject(storageReff);
      }

      console.log("gallery deleted successfully");
    } catch (error) {
      console.log("GALLERY IMAGES NOT DELETED: " + error);
      displayErrorToast("GALLERY IMAGES NOT DELETED: " + error);
    }

    // DELETE THE EVENT
    const docRef = doc(db, "events", event_id);
    await deleteDoc(docRef)
      .then(() => {
        console.log("Entire Event has been deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
      });

    return true;
  } catch (error) {
    console.log("SOMETHING WENT WRONG DELETING EVENT: " + error);
    return false;
  }
};

/////////////////////////////////////
// METHOD TO GET ALL EVENT IMAGES ///////
/////////////////////////////////////
export const getAllEventImages = async (event_id) => {
  const storageRef = ref(storage, "events/images/" + event_id);
  const imageUrl = await getDownloadURL(storageRef);
  return imageUrl;
};

/////////////////////////////////////
//  METHOD TO ADD EVENT TICKET//////
/////////////////////////////////////
export async function addEventTicket({
  event_date_id,
  ticket_id,
  enable_sales_for_ticket,
  ticket_name,
  ticket_description,
  isFree,
  ticket_price,
  promotional_price,
  number_of_tickets,
  tickets_per_attendee,
  sales_start_date,
  sales_end_date,
}) {
  try {
    const ticketDoc = doc(db, "tickets", ticket_id);

    // ADDING THE DATA TO FIRESTORE
    const data = {
      id: ticket_id || "",
      event_date_id: event_date_id || "",
      enable_sales_for_ticket: enable_sales_for_ticket || "",
      ticket_name: ticket_name || "",
      ticket_description: ticket_description || "",
      isFree: isFree,
      ticket_price: ticket_price || "",
      promotional_price: promotional_price || "",
      number_of_tickets: number_of_tickets || "",
      tickets_per_attendee: tickets_per_attendee || "",
      sales_start_date: sales_start_date || "",
      sales_end_date: sales_end_date || "",
      active: false,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
    };

    console.log("TICKET DATA TO BE SENT: ");
    console.log(data);

    await setDoc(ticketDoc, data);
    return "success";
  } catch (error) {
    console.log("THE ERROR IS: " + error);
    return error;
  }
}

/////////////////////////////////////
//  METHOD TO ADD EVENT DATE //////
/////////////////////////////////////
export async function addEventDate({
  id,
  enable_sales,
  start_date,
  end_date,
  isOnline,
  venue,
  scanners,
  points_of_sale,
  ticket_ids,
  event_id,
}) {
  const event_date_doc = doc(db, "event_dates", id);
  
  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: id || null,
    enable_sales: enable_sales || null,
    start_date: start_date || null,
    end_date: end_date || null,
    isOnline: isOnline || false,
    venue: venue || null,
    scanners: scanners || null,
    points_of_sale: points_of_sale || null,
    ticket_ids: ticket_ids || null,
    active: false,
    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
    event_id: event_id,
  };

  await setDoc(event_date_doc, data);

  return "success";
}

// METHOD TO DELETE AN IMAGE FROM FIREBASE STORAGE
export async function deleteImageFromFirebaseStorage(url) {
  try {
    const storageRef = ref(storage, url);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    displayErrorToast("Error deleting image from storage: " + error);
    return false;
  }
}
