import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import PopingOutVenue from "./PopingOutVenue";
import VenueNamePopover from "./VenueName";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import VenueOrganizer from "./VenueOrganizer";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageVenues() {
  //fetching venues
  const getVenues = useVenues((state) => state.getVenues);
  const venues = useVenues((state) => state.venues);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //FETCHING VENUES
  useEffect(() => {
    async function fetchData() {
      await getVenues();
    }

    fetchData();
  }, [venues.length]);
  // console.log("venues", venues);

  // GETTING NUMBER OF VENUES
  const isAdmin = userStore((state) => state.isAdmin);

  //filtering logic
  const [venuesFilter, setVenuesFilter] = useState("");
  const filteredVenues = venues.filter((venue) => {
    return (
      !venuesFilter ||
      (venue.name &&
        venue.name.toLowerCase().includes(venuesFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("added_on");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedVenues = filteredVenues.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "added_on") {
      const dateA = a.added_on;
      const dateB = b.added_on;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage] = useState(20);
  //get current events
  const indexOfLastvenues = currentPage * venuesPerPage;
  const indexOfFastvenues = indexOfLastvenues - venuesPerPage;
  const current_venues = sortedVenues?.slice(
    indexOfFastvenues,
    indexOfLastvenues
  );

  if (isLoading) {
    <Loader />;
  }
  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Venues " />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_venues"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {venues?.length} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={venuesFilter}
                        onChange={(e) => setVenuesFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`added_on&direction=desc`}
                            selected={
                              sortCriteria === "added_on" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`added_on&direction=asc`}
                            selected={
                              sortCriteria === "added_on" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/addanewvenue"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new venue"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Image</th>
                              <th>Organizer</th>
                              <th>Events count</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_venues.map((venue, index) => (
                              <tr key={venues?.id}>
                                <td>
                                  <VenueNamePopover
                                    venue_id={venue?.id}
                                    venue_name={venue?.name}
                                  />
                                </td>
                                {/* DISPLAYING VENUE IMAGE */}
                                <td>
                                  <Link
                                    className="fancybox"
                                    href=""
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Enlarge"
                                  >
                                    <img
                                      src={venue?.venue_images[0]}
                                      className="img-thumbnail img-50-50"
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                {/* DISPLAYING VENUE ORGANIZER */}

                                <td>
                                  <VenueOrganizer
                                    organizer_ID={venue?.organizer_id}
                                  />
                                </td>
                                {/* DISPLAYING THE EVENTS COUNT */}
                                <td>10</td>
                                {/* DISPLAYING STATUS */}
                                <td>
                                  {venue?.isHidden === true ? (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />
                                      {""}
                                      Invisible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-success ">
                                      <i className="fas fa-eye fa-fw" />
                                      {""}
                                      Visible
                                    </span>
                                  )}
                                  <br />
                                  <br />
                                  {venue?.listedondirectory !== true ? (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />
                                      {""}
                                      Hidden from directory
                                    </span>
                                  ) : (
                                    <span className=" badge badge-success ">
                                      <i className="fas fa-eye fa-fw" />
                                      {""}
                                      Listed on directory
                                    </span>
                                  )}
                                </td>
                                {/* ACTIONS */}
                                <td className="text-center">
                                  <PopingOutVenue
                                    venue_id={venue?.id}
                                    setIsLoading={setIsLoading}
                                    isHidden={venue?.isHidden}
                                    listedOnDirectory={venue?.listedondirectory}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {sortedVenues?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedVenues?.length / venuesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
