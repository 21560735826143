import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FcStatistics } from "react-icons/fc";
import { MdRequestQuote } from "react-icons/md";
import { FaEdit, FaEye, FaEyeSlash, FaStar, FaUsers } from "react-icons/fa";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../services/helpers";
import { useEvents } from "./../../store/event_store";
import useDisclosure from "../../customHooks/useDisclosure";
import RequestPayout from "../../components/request_payout/RequestPayout";
import { useSettings } from "../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";
import Dropdown from "react-bootstrap/Dropdown";

const PopingOut = ({ event_id, setIsLoading, status }) => {
  const [eventData, setEventData] = useState({});

  const { isOpen, onClose, onOpen } = useDisclosure();

  // ZUSTAND VARIABLES
  const getEvent = useEvents((state) => state.getEvent);
  const updateEvent = useEvents((state) => state.updateEvent);

  useEffect(() => {
    // METHOD TO HANDLE GETTING THE EVENT DATA
    async function handleGetEventData() {
      const event_data = await getEvent(event_id);
      setEventData(event_data);
    }
    handleGetEventData();
  }, [event_id]);

  //getting delete function from the zustand store
  const deleteEvent = useEvents((state) => state.deleteEvent);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE EVENT
  async function handleDeleteEvent(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteEvent(event_id);
    if (response) {
      displaySuccessToast("The event has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the event");
    }
    setIsLoading(false);
  }

  const handledraftPublish = async () => {
    setIsLoading(true);
    await updateEvent(event_id, {
      status: status === "draft" ? "published" : "draft",
      updated_at: Timestamp.fromDate(new Date()),
    });

    if (status === "published") {
      displayWarningToast(
        "The event has been unpublished and it will not be seen by attendees"
      );
    } else {
      displaySuccessToast(
        "The event has been published and it will be seen by attendees"
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
        <Dropdown.Toggle
          variant="white"
          size="md"
          className="hide-dropdown-icon"
          style={{ padding: 0, background: "transparent"}}
        >
          <BsThreeDotsVertical
            style={{
              color: layout_page?.primary_color,
              cursor: "pointer",
            }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to={"/event_statistics/" + event_id}>
              <div className="d-flex gap-1 " style={{ color: "gray" }}>
                <FcStatistics />
                <h6>Statistics</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link onClick={() => onOpen()} className="cursor-pointer">
              <div className="d-flex gap-1" style={{ color: "gray" }}>
                <MdRequestQuote />
                <h6>Request Payout</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item href="">
            {/* EDIT EVENT */}
            <Link
              to={"/edit_event/" + event_id}
              state={{ event_data: eventData }}
            >
              <div className="d-flex gap-1" style={{ color: "gray" }}>
                <FaEdit />
                <h6>Edit</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            {/* ALL ATTENDDEES */}
            <Link to={"/manage_organizer_orders/" + event_id}>
              <div className="d-flex gap-1" style={{ color: "gray" }}>
                <FaUsers />
                <h6>Attendees</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to={"/my_reviews/" + event_id}>
              <div className="d-flex gap-1" style={{ color: "gray" }}>
                <FaStar />
                <h6>Reviews</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item href="">
            <>
              <div
                className="d-flex gap-1 cursor-pointer"
                style={{ color: "gray" }}
                onClick={(e) => handledraftPublish(e)}
              >
                {status === "draft" ? (
                  <>
                    <FaEye />
                    <h6>Publish</h6>
                  </>
                ) : (
                  <>
                    <FaEyeSlash />
                    <h6>Draft</h6>
                  </>
                )}
              </div>
            </>
          </Dropdown.Item>
          <Dropdown.Item>
            {/* DELETE EVENT */}
            <DeleteComponentModal
              itemName="Event"
              handleDelete={handleDeleteEvent}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <RequestPayout eventId={event_id} onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default PopingOut;
