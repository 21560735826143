import React, { useState } from "react";
import "./style.css";
import { ProceedButton } from "../../Components";
import { tableStore } from "../../../../../store/table_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../../services/helpers";

const BookedTables = ({ handleTabClick, venue_room_id, venue_id }) => {
  const venue_room = tableStore((state) =>
    state.venueTableTypes.find(
      (venueTableType) => venueTableType.id === venue_room_id
    )
  );

  const [room, setRoom] = useState(venue_room);

  const [isLoading, setIsLoading] = useState(false);

  const editTableLable = tableStore((state) => state.editTableLable);
  const tables = tableStore((state) =>
    state.tableLabels.filter(
      (tableLabel) => tableLabel.venue_tableType_id === venue_room_id
    )
  );
  const editVenueRoom = tableStore((state) => state.editVenueTableType);

  const handleTableSelection = (tableId) => {
    setRoom((prevRoom) => ({
      ...prevRoom,
      tables: prevRoom.tables.map((table) => {
        if (table.id === tableId) {
          return {
            ...table,
            isBooked: !table.isBooked, // Toggle the isBooked
          };
        }
        return table;
      }),
    }));

    // Use room.tables directly to find the updated table
    const updatedTable = tables.find((table) => table.id === tableId);
    if (updatedTable) {
      // Update the state of the table
      editTableLable(tableId, { isBooked: !updatedTable.isBooked });
    }
  };

  //editing the room
  const handleProceed = () => {
    setIsLoading(true);
    try {
      editVenueRoom(venue_id, venue_room_id, {
        ...room,
        tables: room.tables.map((table) => ({
          ...table,
          isBooked: table.isBooked,
        })),
      });
      displaySuccessToast("Updates are successfull");
      handleTabClick("mappings");
      setIsLoading(false);
    } catch (error) {
      displayErrorToast("Something went wrong");
    }
  };

  // if is loading show this
  if (isLoading) {
    return <div> Loading... </div>;
  }

  return (
    <div>
      <div className="row">
        <p className="fw-bold text-lg mt-5">Booked tables</p>
        {room?.tables.length > 0 &&
          room?.tables.map((table) => (
            <div key={table?.id} className="col-md-6 ">
              <div className="form-group">
                <div className=" d-flex text-lg">
                  <span className="w-25"> {table?.tableLabel}</span>
                  <label className="custom-radio-label fw-light">
                    Mark as Booked
                  </label>
                  <div className="custom-control custom-checkbox ml-3">
                    <input
                      type="checkbox"
                      checked={table?.isBooked || false}
                      onChange={() => handleTableSelection(table?.id)}
                      className="custom-control-input"
                      id={table?.id}
                    />
                    <label
                      htmlFor={table?.id}
                      className="custom-control-label cursor-pointer"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div onClick={handleProceed}>
        <ProceedButton text="Save Changes" />
      </div>
    </div>
  );
};

export default BookedTables;
