import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

var firebaseConfig;

// // IF ITS IN PRODUCTION MODE
// if (process.env.NODE_ENV === "production") {
//   firebaseConfig = {
//     apiKey: "AIzaSyCic3KMkTYQqQi6ut31C_P2r85UMXQ2V9U",
//     authDomain: "lyxa-prod.firebaseapp.com",
//     projectId: "lyxa-prod",
//     storageBucket: "lyxa-prod.appspot.com",
//     messagingSenderId: "853728249395",
//     appId: "1:853728249395:web:58c2678019ba9c26530546",
//     measurementId: "G-1JW98YZMX8",
//   };

// }
// // IF THE APP IS RUNNING IN DEVELOPMENT MODE
// else {
//   firebaseConfig = {
//     apiKey: "AIzaSyBF3g3BemYsiX1ipAjUDJLMzw71BllWDG4",
//     authDomain: "lyxa-e0c88.firebaseapp.com",
//     projectId: "lyxa-e0c88",
//     storageBucket: "lyxa-e0c88.appspot.com",
//     messagingSenderId: "583627730972",
//     appId: "1:583627730972:web:af67d674e46f92d8456fd9",
//     measurementId: "G-F59Q94BJ6N",
//   };
// }

firebaseConfig = {
  apiKey: "AIzaSyBF3g3BemYsiX1ipAjUDJLMzw71BllWDG4",
  authDomain: "lyxa-e0c88.firebaseapp.com",
  projectId: "lyxa-e0c88",
  storageBucket: "lyxa-e0c88.appspot.com",
  messagingSenderId: "583627730972",
  appId: "1:583627730972:web:af67d674e46f92d8456fd9",
  measurementId: "G-F59Q94BJ6N",
};

const app = initializeApp(firebaseConfig);
export default app;
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
