import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import app from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  convertTimestampToDateTime,
  displayInfoToast,
  displaySuccessToast,
  displayWarningToast,
} from "../services/helpers";

// INITIALIZING FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

const eventsStore = (set, get) => ({
  events: [],
  upcomingEvents: [],
  favoriteEvents: [],
  eventCategories: [],
  cartItems: [],
  countries: [],
  languages: [],
  audiences: [],
  organizerFollowers: [],
  organizerReviews: [],
  organizerPastEvents: [],
  queriedEvents: [],
  organizerEvents: [],
  eventDates: [],
  searches: [],
  event_ideas: [],

  // CHECKING IF A TICKET HAS A PROMOCODE
  checkIfTicketHasPromoCode: async (ticket_id) => {
    console.log("THE RECEIVED TICKET ID: ", ticket_id);
    try {
      // GET PROMOCODES FOR THIS TICKET
      const docRef = collection(db, "promocodes");
      const q = query(docRef, where("ticketId", "==", ticket_id));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.length > 0;
    } catch (error) {
      console.log("Error checking if ticket has promocode: ", error);
      return false;
    }
  },

  checkIfTableHasPromoCode: async (table_id) => {
    console.log("THE RECEIVED TABLE ID: ", table_id);
    try {
      // GET PROMOCODES FOR THIS TICKET
      const docRef = collection(db, "promocodes");
      const q = query(docRef, where("ticketId", "==", table_id));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.length > 0;
    } catch (error) {
      console.log("Error checking if table has promocode: ", error);
      return false;
    }
  },

  // GETTING ALL EVENTS FOR AN ORGANIZER
  getOrganizerEvents: (organizer_id) => {
    const organizerEvents = get().events.filter(
      (event) => event.organizer_id === organizer_id
    );
    set((state) => ({ organizerEvents: organizerEvents }));

    return organizerEvents;
  },

  // GETTING PUBLISHED EVENTS OF THE ORGANIZER
  getPublishedOrganizerEvents: async (organizer_id) => {
    const publishedEvents = (await get()).events.filter(
      (event) =>
        event.organizer_id === organizer_id && event.visibility !== "draft"
    );
    return publishedEvents;
  },

  // DELETE AN EVENT
  deleteEvent: async (event_id) => {
    try {
      // Get specific event you want to delete
      const eventDetails = await get().getEvent(event_id);

      // DELETE THE EVENT IMAGE FROM FIREBASE STORAGE
      try {
        const eventImage = eventDetails?.main_event_image;
        if (eventImage) {
          const storageRef = ref(storage, eventImage);
          await deleteObject(storageRef);
          console.log("Event image deleted successfully");
        }
      } catch (error) {
        console.log("Error deleting event image:", error);
      }

      // DELETING THE IMAGE GALLERY IMAGES FROM FIREBASE STORAGE
      if (eventDetails?.gallery && eventDetails?.gallery.length > 0) {
        for (let i = 0; i < eventDetails?.gallery.length; i++) {
          const image = eventDetails.gallery[i];
          if (image) {
            try {
              const imageRef = ref(storage, image);
              await deleteObject(imageRef);
              console.log(`Image ${i + 1} deleted successfully`);
            } catch (error) {
              console.log(`Error deleting image ${i + 1}:`, error);
            }
          }
        }
      }

      // DELETE THE EVENT DOCUMENT FROM FIRESTORE
      await deleteDoc(doc(db, "events_new", event_id));
      console.log("Event document deleted successfully");

      // UPDATE STATE WITH THE DELETED EVENT REMOVED
      const updatedEvents = get().events.filter(
        (event) => event.id !== event_id
      );
      set({ events: updatedEvents });

      return true;
    } catch (error) {
      console.error("Error deleting event: ", error);
      return false;
    }
  },

  // METHOD TO UPDATE EVENT
  updateEvent: async (event_id, data) => {
    try {
      await updateDoc(doc(db, "events_new", event_id), data);
      set((state) => ({
        organizerEvents: state.organizerEvents.map((event) =>
          event.id === event_id ? data : event
        ),
        upcomingEvents: state.upcomingEvents.map((event) =>
          event.id === event_id ? data : event
        ),
      }));
      get().getUpcomingEvents();
      get().getOrganizerUpcomingEvents();
      get().getOrganizerPastEvents();
      get().getOrganizerEvents();
      get().fetchEvents();
      return true;
    } catch (error) {
      console.error("Error Updating event document: ", error);
      return false;
    }
  },

  // METHOD TO GET CART ITEMS
  getCartItems: async (user_id) => {
    const cart_items = [];
    const cartItemsCollection = collection(db, "cart");
    const q = query(cartItemsCollection, where("user_id", "==", user_id));
    const querySnapshot = await getDocs(q);

    for (let i = 0; i < querySnapshot.docs.length; i++) {
      const cartItem = querySnapshot.docs.at(i).data();
      cart_items.push(cartItem);
    }

    set({ cartItems: cart_items });
    return true;
  },

  // ADD EVENT TO FAVORITES
  addToFavorites: async (event_id, user_id) => {
    try {
      // Check if the document already exists
      const querySnapshot = await getDocs(
        query(
          collection(db, "favorites"),
          where("event_id", "==", event_id),
          where("user_id", "==", user_id)
        )
      );
      // If querySnapshot.empty is false, it means that there is at least one matching document based on your query conditions, indicating that a document with the same event_id and user_id combination already exists in your Firestore collection
      if (!querySnapshot.empty) {
        console.log("Document already exists.");
        displayInfoToast("The event already exists in your favorites");
        return;
      }

      // Document doesn't exist, add it
      const docRef = await addDoc(collection(db, "favorites"), {
        event_id: event_id,
        user_id: user_id,
        created_at: Timestamp.fromDate(new Date()),
      });

      console.log("Document written with ID: ", docRef.id);
      get().getFavoriteEvents(user_id);
      return true;
    } catch (error) {
      console.error("Error adding document: ", error);
      return false;
    }
  },

  // REMOVE FROM FAVORITES
  removeFromFavorites: async ({ event_id, user_id }) => {
    try {
      const userDoc = collection(db, "favorites");
      const q = query(
        userDoc,
        where("event_id", "==", event_id),
        where("user_id", "==", user_id)
      );

      const querySnapshot = await getDocs(q);

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const favorite = querySnapshot.docs.at(i);
        await deleteDoc(doc(db, "favorites", favorite.id));
      }

      get().getFavoriteEvents(user_id);

      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG REMOVING FROM FAVORITES: " + error);
      return false;
    }
  },

  // GETTING THE FAVORITE EVENTS
  getFavoriteEvents: async (user_id) => {
    const favorite_events = [];
    const userDoc = collection(db, "favorites");

    try {
      const q = query(userDoc, where("user_id", "==", user_id));
      const querySnapshot = await getDocs(q);

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const favorite = querySnapshot.docs.at(i).data();
        const response = await get().getEvent(favorite.event_id);
        favorite_events.push(response);
      }
      set({ favoriteEvents: favorite_events });
      return favorite_events;
    } catch (error) {
      console.log("SOMETHING WENT WRONG GETTING FAVORITE EVENTS: " + error);
      // displayErrorToast("Something went wrong getting favorite events");
      return false;
    }
  },

  // GETTING A SPECIFIC EVENT
  getEvent: async (event_id) => {
    try {
      if (!event_id) {
        return null;
      }
      const cachedEvent = (await get()).events.find(
        (event) => event.id === event_id
      );
      if (cachedEvent) {
        return cachedEvent;
      }

      // If the event is not in the cached data, retrieve it from Firestore
      const eventDoc = await getDoc(doc(db, "events_new", event_id));

      if (eventDoc.exists()) {
        return eventDoc.data();
      } else {
        return null; // Event not found in Firestore
      }
    } catch (error) {
      console.log("Error getting event details:", error);
      return null;
    }
  },

  ///////////////////////////////////
  // METHOD TO UPLOAD EVENT IMAGES //
  /////////////////////////// //////
  uploadEventImages: async (eventImages) => {
    if (eventImages.length === 0) {
      return;
    }
    const storageRef = ref(storage, "events/images/" + eventImages.name);
    try {
      await uploadBytes(storageRef, eventImages);
      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
      return null;
    }
  },

  // METHOD TO DELETE AN IMAGE FROM FIREBASE STORAGE
  deleteImageFromFirebaseStorage: async (url) => {
    try {
      const storageRef = ref(storage, url);
      await deleteObject(storageRef);
      return true;
    } catch (error) {
      console.log("Error deleting image from storage: " + error);
      return false;
    }
  },

  // GETTING ALL UPCOMING EVENTS FOR AN ORGANIZER
  getOrganizerUpcomingEvents: async (organizer_id) => {
    const organizerEvents = get().upcomingEvents.filter(
      (event) => event.organizer_id === organizer_id
    );
    return organizerEvents;
  },

  // GETTING ALL PAST EVENTS FOR AN ORGANIZER
  getOrganizerPastEvents: async (organizer_id) => {
    try {
      const currentDate = new Date();
      const organizerPastEvents = get().events.filter((event) => {
        const eventStartDate = convertTimestampToDateTime(
          event.event_dates[0].start_date
        );
        return (
          eventStartDate < currentDate && event.organizer_id === organizer_id
        );
      });
      organizerPastEvents.sort((a, b) => {
        const startDateA = convertTimestampToDateTime(
          a.event_dates[0].start_date
        );
        const startDateB = convertTimestampToDateTime(
          b.event_dates[0].start_date
        );
        return startDateB - startDateA;
      });

      set({ organizerPastEvents });
      return organizerPastEvents;
    } catch (error) {
      console.log("Error getting organizer past events:", error);
      return false;
    }
  },

  //GET ORGANIZER FOLLOWERS
  getOrganizerFollowers: async (organizer_id) => {
    console.log("GETTING FOLLOWERS FOR: " + organizer_id);
    try {
      const followers = [];
      const followersCollection = collection(db, "following");

      const q = query(
        followersCollection,
        where("organizer_id", "==", organizer_id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        followers.push(doc.data());
      });
      set({ organizerFollowers: followers });
      return true;
    } catch (error) {
      console.log("Error getting organizer followers:", error);
      return false;
    }
  },

  //getting organizer reviews
  getOrganizerReviews: async (organizerEvents) => {
    try {
      const organizerReviews = [];
      for (let i = 0; i < organizerEvents.length; i++) {
        const eventReviews = await get().getEventReviews(organizerEvents[i].id);
        if (eventReviews.length > 0) {
          organizerReviews.push(eventReviews);
        }
      }
      set({ organizerReviews });
      return true;
    } catch (error) {
      console.log("Error getting organizer reviews:", error);
      return false;
    }
  },

  /////////////////////////////////EVENTS CATEGORY SECTION////////////////////////////////////////////////////////
  /////////////////////// METHOD TO ADD A NEW EVENT CATEGORY //////////////////////////////
  addNewEventcategory: async (data) => {
    try {
      const eventscategoryCollection = collection(db, "events_category");
      const responseFromFirestore = await addDoc(
        eventscategoryCollection,
        data
      );
      const eventsCategory_id = responseFromFirestore.id;

      const eventsCategoryDoc = doc(db, "events_category", eventsCategory_id);
      await updateDoc(eventsCategoryDoc, {
        id: eventsCategory_id,
      });
      const addCategory = (eventCategory) =>
        set((state) => ({
          eventCategories: [...state.eventCategories, eventCategory],
        }));
      addCategory(data);
      return true;
    } catch (error) {
      console.log("error occured when adding event category:", error);
      return null;
    }
  },

  // GETTING EVENT CATEGORIES
  getEventCategories: async () => {
    try {
      const event_categories = [];
      const userDoc = collection(db, "events_category");
      const querySnapshot = await getDocs(userDoc);

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        event_categories.push(querySnapshot.docs.at(i).data());
      }

      set({ eventCategories: event_categories });
      return true;
    } catch (error) {
      console.log("the error:", error);
      return false;
    }
  },

  //getting eventCategories where isVisible===true
  getVisibleEventCategories: async () => {
    const visibleCategories = (await get()).eventCategories.filter(
      (eventCategory) => eventCategory.isVisible === true
    );
    return visibleCategories;
  },
  //getting eventCategories where isVisible===true
  getFeaturedCategories: async () => {
    const isFeaturedCategories = (await get()).eventCategories.filter(
      (eventCategory) => eventCategory.isFeatured === true
    );
    return isFeaturedCategories;
  },

  // METHOD TO UPDATE EVENT CATEGORY.
  updateEventCategory: async (category_id, data) => {
    try {
      await updateDoc(doc(db, "events_category", category_id), data);
      get().getEventCategories();
      set((state) => ({
        eventCategories: state.eventCategories.map((eventCategory) =>
          eventCategory.id === category_id ? data : eventCategory
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating event category document: ", error);
      return false;
    }
  },

  //  METHOD TO ADD EVENTY CATEGORY IMAGE
  uploadeventsCategoryImage: async (imageFile) => {
    if (!imageFile) {
      return;
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, "events_category/images/" + imageFile.name);

    try {
      await uploadBytes(storageRef, imageFile);

      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
      return false;
    }
  },

  //handle delete Category Image Url
  deleteCategoryImageUrl: async (imageUrl) => {
    const storage = getStorage(app);
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("category image deleted successfully");
      return true;
    } catch (error) {
      console.log("Error deleting image", error);
      return false;
    }
  },

  // GET ALL EVENTS IN THE DATABASE
  fetchEvents: async () => {
    try {
      const fetchedEvents = [];
      const querySnapshot = await getDocs(collection(db, "events_new"));

      querySnapshot.forEach((doc) => {
        fetchedEvents.push(doc.data());
      });

      set({ events: fetchedEvents });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG FETCHING EVENTS: " + error);
      return false;
    }
  },

  // GET ALL EVENTS IN THE DATABASE
  getUpcomingEvents: async () => {
    try {
      // Get the current date
      const currentDate = new Date();

      // Filter out only the upcoming events
      const upcoming_events = get().events?.filter((event) => {
        const eventEndDate = convertTimestampToDateTime(
          event.event_dates[0]?.end_date
        );
        return eventEndDate >= currentDate && event.visibility !== "draft";
      });

      // SORTING THE EVENTS SO THAT THE EVENTS HAPPENING SOON APPEAR FIRST
      upcoming_events?.sort((a, b) => {
        const startDateA = convertTimestampToDateTime(
          a.event_dates[0]?.start_date
        );
        const startDateB = convertTimestampToDateTime(
          b.event_dates[0]?.start_date
        );
        return startDateA - startDateB;
      });

      set({ upcomingEvents: upcoming_events });
      return upcoming_events;
    } catch (error) {
      console.log("SOMETHING WENT WRONG FETCHING UPCOMING EVENTS: " + error);
      // displayErrorToast("Something went wrong fetching upcoming events");
      return [];
    }
  },

  // Define the handleStatusToggle function
  handleStatusToggle: async (event_id) => {
    const eventIndex = get().events.findIndex((event) => event.id === event_id);
    if (eventIndex !== -1) {
      const updatedEvents = [...get().events];
      const event = updatedEvents[eventIndex];

      // Check the current status and toggle it
      if (event.visibility === "draft") {
        event.visibility = "published";
      } else {
        event.visibility = "draft";
      }

      try {
        // Update the event status in the database
        const response = await get().updateEvent(event_id, {
          status: event?.visibility,
        });

        if (response) {
          if (event?.visibility === "draft") {
            displayWarningToast(
              "The event has been unpublished and it will not be seen by attendees"
            );
          } else {
            displaySuccessToast(
              "The event has been published and it will be seen by attendees"
            );
          }
        }
        set({ events: updatedEvents });
      } catch (error) {
        console.log("Error updating event status:", error);
        // Handle the error appropriately
        return false;
      }
    }
  },

  getAllEventDates: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "events_new"));
      const eventDates = [];

      querySnapshot.forEach((doc) => {
        const event = doc.data();
        eventDates.push(...event.event_dates);
      });
      set({ eventDates });
      return eventDates;
    } catch (error) {
      console.error("Error getting event dates:", error);
      return null;
    }
  },

  // ADDING EVENTS
  addEvent: async (event) => {
    try {
      const event_id = event.id.toString();
      const eventsDoc = doc(db, "events_new", event_id);
      await setDoc(eventsDoc, event);
      set((state) => ({ events: [...state.events, event] }));
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG ADDING EVENTS: " + error);
      return false;
    }
  },

  //GETTING SPECIFICE CATEGORY
  getSpecificCategory: async (categoryID) => {
    // Function to fetch specific category
    try {
      const categoryDoc = doc(db, "events_category", categoryID);
      const querySnapshot = await getDoc(categoryDoc);
      const category = querySnapshot.data();
      return category;
    } catch (error) {
      console.error("Error fetching specific category: ", error);
      return null;
    }
  },

  //delete a category
  deleteCategory: async (categoryID) => {
    console.log("GOING TO DELETE A CATEGORY WITH ID:", categoryID);
    try {
      // Get the category details
      const categoryDetails = await get().getSpecificCategory(categoryID);
      console.log("Specific category retrieved:", categoryDetails);

      // Delete the category image from the storage
      const categoryImage = categoryDetails?.image;
      try {
        const storageRef = ref(storage, categoryImage);
        await deleteObject(storageRef);
        console.log("Category image deleted");
      } catch (error) {
        console.log("Category image not deleted", error);
      }

      // Delete the category document
      const docRef = doc(db, "events_category", categoryID);
      await deleteDoc(docRef);
      console.log("Category deleted");
      //update
      const updatedEventCategories = get().eventCategories.filter(
        (eventCategory) => eventCategory?.id !== categoryID
      );
      set({ eventCategories: updatedEventCategories });
      return true;
    } catch (error) {
      console.log("Something went wrong while deleting the category", error);
      return false;
    }
  },

  // GETTING COUNTRIES IN THE DATABASE
  getCountries: async () => {
    try {
      const countries = [];
      const userDoc = collection(db, "countries");
      const querySnapshot = await getDocs(userDoc);
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const data = querySnapshot.docs.at(i).data();
        countries.push(data);
      }
      set({ countries: countries });
      return true;
    } catch (error) {
      console.log("error:", error);
      return false;
    }
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // METHOD TO GET COUNTRIES LIST
  getCountriesLists: async () => {
    const countries = [];
    const userDoc = collection(db, "countries");
    const querySnapshot = await getDocs(userDoc);
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      countries.push(querySnapshot.docs.at(i).data());
    }
    return countries;
  },

  //getting countries list
  getCountriesList: async () => {
    try {
      const countries = [];
      const userDoc = collection(db, "countries");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        countries.push(doc.data());
      });
      set({ countries });
      return countries;
    } catch (error) {
      console.log("Something went wrong fetching the countries list:", error);
      return [];
    }
  },

  //get countries where is isVisible ===true
  getVisibleCountries: async () => {
    const countries = await get().getCountriesList();
    const visibleCountries = countries.filter(
      (country) => country.isVisible !== false
    );
    return visibleCountries;
  },

  // METHOD TO UPDATE COUNTRY.
  updateCountry: async (country_id, data) => {
    try {
      await updateDoc(doc(db, "countries", country_id), data);
      get().getCountries();
      get().getCountriesList();
      set((state) => ({
        countries: state.countries.map((country) =>
          country.id === country_id ? data : country
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating country document: ", error);
      return false;
    }
  },

  deleteCountry: async (country_id) => {
    try {
      const countryRef = doc(db, "countries", country_id);
      await deleteDoc(countryRef);
      console.log("Country deleted successfully");
      //update
      const updatedCountries = get().countries.filter(
        (country) => country?.id !== country_id
      );
      set({ countries: updatedCountries });
      return true;
    } catch (error) {
      console.log("Something went wrong while deleting the country", error);
      return false;
    }
  },

  ///LANGUAGES
  //GETTING A LANGUAGE
  fetchLanguages: async () => {
    try {
      const languages = [];
      const userDoc = collection(db, "languages");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        languages.push(doc.data());
      });
      set({ languages });
      return languages;
    } catch (error) {
      console.log("Error fetching languages:", error);
      return [];
    }
  },

  //get languages where is isVisible ===true
  getVisibleLanguages: async () => {
    const languages = await get().fetchLanguages();
    const visibleLanguages = languages.filter(
      (language) => language.isVisible === true
    );
    return visibleLanguages;
  },

  //update the language
  updateLanguages: async (language_id, data) => {
    try {
      await updateDoc(doc(db, "languages", language_id), data);
      get().fetchLanguages();
      set((state) => ({
        languages: state.languages.map((language) =>
          language.id === language_id ? data : language
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating language document: ", error);
      return false;
    }
  },

  //delete a language
  deleteLanguage: async (language_id) => {
    try {
      const languageRef = doc(db, "languages", language_id);
      await deleteDoc(languageRef);
      console.log("Language deleted successfully");
      //update
      const updatedLanguages = get().languages.filter(
        (language) => language?.id !== language_id
      );
      set({ languages: updatedLanguages });
      return true;
    } catch (error) {
      console.log("Something went wrong while deleting the language", error);
      return false;
    }
  },

  //getting audiences
  getAudiences: async () => {
    try {
      const audiences = [];
      const userDoc = collection(db, "audiences");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        audiences.push(doc.data());
      });
      set({ audiences });
      return audiences;
    } catch (error) {
      console.log("Something went wrong while fetching audiences", error);
      return [];
    }
  },

  //get audiences where isVisible ===true
  getVisibleAudiences: async () => {
    const audiences = await get().getAudiences();
    const visibleAudiences = audiences.filter(
      (audience) => audience.isVisible === true
    );
    return visibleAudiences;
  },

  //get specific audience,
  getSpecificAudience: async (audience_id) => {
    const audienceDoc = doc(db, "audiences", audience_id);
    const querySnapshot = await getDoc(audienceDoc);
    const audience = querySnapshot.data();
    console.log("audience:", audience);
    return audience;
  },

  //update audience
  updateAdiences: async (audience_id, data) => {
    try {
      await updateDoc(doc(db, "audiences", audience_id), data);
      get().fetchLanguages();
      //updating zustand
      set((state) => ({
        audiences: state.audiences.map((audience) =>
          audience.id === audience_id ? data : audience
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating audience document: ", error);
      return false;
    }
  },

  // Define the handleStatusToggle function to toggle the status
  handleStatusAudiences: async (audience_id) => {
    const audienceIndex = get().audiences.findIndex(
      (audience) => audience?.id === audience_id
    );

    if (audienceIndex !== -1) {
      const updatedAudiences = [...get().audiences];
      const audience = updatedAudiences[audienceIndex];
      if ("isVisible" in audience) {
        audience.isVisible = audience.isVisible === true ? false : true;
        try {
          // Update the audience status in the database
          await get().updateAdiences(audience_id, {
            isVisible: audience?.isVisible,
          });
        } catch (error) {
          console.log("Error updating the audience status:", error);
          return;
        }
      } else {
        // If the isVisible field is missing, assume it is true and add it to the database
        audience.isVisible = true;
      }
      //displaying the message to the user
      if (audience.isVisible === true) {
        displaySuccessToast("The audience has been published");
      } else {
        displayWarningToast(
          "The audience has been hidden and it will not be viewed"
        );
      }
      //updating the state
      set({ audiences: updatedAudiences });
    }
  },

  // delete audience
  deleteAudience: async (audience_id) => {
    console.log("Going to delete an audience with ID:", audience_id);
    try {
      const audienceRef = doc(db, "audiences", audience_id);
      // Get the audience details
      const audienceDetails = await get().getSpecificAudience(audience_id);
      console.log("Specific audience retrieved:", audienceDetails);
      // Delete the audience image from storage
      try {
        const storageRef = ref(storage, audienceDetails?.image);
        await deleteObject(storageRef);
        console.log("Audience image deleted");
      } catch (error) {
        console.log("Audience image not deleted", error);
        // displayErrorToast("Audience image not deleted", error);
      }
      // Delete the audience document from Firestore
      await deleteDoc(audienceRef);
      console.log("Audience deleted successfully");
      const updatedAudiences = get().audiences.filter(
        (audience) => audience?.id !== audience_id
      );
      set({ audiences: updatedAudiences });
      return true; // Return true to indicate successful deletion
    } catch (error) {
      console.log("Something went wrong while deleting the audience", error);
      // displayErrorToast("Failed to delete audience", error);
      return false; // Return false to indicate deletion failure
    }
  },

  // Function to get event favorites based on the event ID
  getEventsFavorites: async (event_id) => {
    try {
      // Create a reference to the "favorites" collection
      const favoritesCollection = collection(db, "favorites");

      // Create a query to fetch documents from the "favorites" collection
      // where the event_id field is equal to the provided event_id
      const q = query(favoritesCollection, where("event_id", "==", event_id));

      // Execute the query and get the snapshot of the matching documents
      const querySnapshot = await getDocs(q);

      // Map the documents in the snapshot to an array of event objects
      const favorites = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Return the array of favorites
      return favorites;
    } catch (error) {
      console.log("Error fetching favorites of event: ", error);
      return null;
    }
  },

  //getting num_of_tickets for each event
  getAllNumTicketQuantityForEvent: async (event_id) => {
    if (!event_id) {
      //  check if event_id is falsy (i.e., undefined, null, 0, "", false, or NaN)
      return 0; // return 0 if event_id is falsy
    }

    try {
      let total_num_of_tickets = 0; // initialize a variable for total number of tickets
      const eventDoc = doc(db, "events_new", event_id); // get a document reference for the event

      const docSnapshot = await getDoc(eventDoc); // get the document data for the event
      const event = docSnapshot.data(); // extract the event data from the snapshot

      const eventDates = event?.event_dates || []; // get the event dates array from the event data
      // console.log("event_dates:", eventDates);

      eventDates.forEach((eventDate) => {
        // iterate through each event date
        const tickets = eventDate?.tickets || []; // get the tickets array for that event date
        // console.log("tickets:", tickets);

        tickets.forEach((ticket) => {
          // iterate through each ticket for that event date
          const num_of_tickets = ticket?.number_of_tickets || 0; // get the number of tickets for that ticket, or default to 0 if undefined
          // console.log("total number for each ticket:", num_of_tickets);
          total_num_of_tickets += parseInt(num_of_tickets.toString()); // add the number of tickets to the total
        });
      });

      return total_num_of_tickets;
    } catch (error) {
      console.error("Error getting ticket quantity:", error);
      return null; // re-throw the error if it occurs
    }
  },

  //a  method to return all event IDs for all Events associated with the organizer
  getOrganizerEventIds: (organizer_id) => {
    // Retrieve the events and filter them based on the organizer_id
    const organizerEventIDS = get()
      .events.filter((event) => event.organizer_id === organizer_id)
      .map((event) => event.id); // extract only the event IDs
    // console.log("organizer event ids", organizerEventIDS);
    return organizerEventIDS; // return the event ids
  },

  // a method to retrieve reviews for the events associated with the organizer
  getReviewsForOrganizerEvents: async (organizer_id) => {
    try {
      // Retrieve the event IDs associated with the organizer
      const eventIds = await get().getOrganizerEventIds(organizer_id);
      //query the reviews where event_id is in eventIds array
      const reviewsQuery = query(
        collection(db, "reviews"),
        where("event_id", "in", eventIds)
      );
      // Execute the query and get the reviews documents
      const querySnapshot = await getDocs(reviewsQuery);

      //map the document to an array of reviews objects
      const reviews = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return reviews; // return the reviews
    } catch (error) {
      console.error("Error fetching reviews for organizer events: ", error);
      return []; // Return an empty array in case of error
    }
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////// METHOD TO ADD A NEW COUNTRY///////////////////////////////////
  addNewCountry: async ({ name, countryCode }) => {
    // ADDING THE DATA TO FIRESTORE
    const data = {
      id: null,
      name: name,
      countryCode: countryCode,
      isVisible: true,
    };
    try {
      //creating a document
      const countryCol = collection(db, "countries");
      const response = await addDoc(countryCol, data);
      const country_id = response.id;
      const countriesDoc = doc(db, "countries", country_id);
      await updateDoc(countriesDoc, { id: country_id });
      //update countries array in zustand store
      const addCountry = (country) =>
        set((state) => ({
          eventCategories: [...state.countries, country],
        }));
      addCountry(data);
      return true;
    } catch (error) {
      console.log("ERROR ADDING COUNTRY:", error);
      return false;
    }
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////   METHOD TO ADD A NEW LANGUAGE    ///////////////////////////////////
  addNewLanguage: async ({ name, languageCode }) => {
    // ADDING THE DATA TO FIRESTORE
    const data = {
      id: null,
      name: name,
      languageCode: languageCode,
      isVisible: true,
    };
    try {
      // CREATING A DOCUMENT
      const langaugesCol = collection(db, "languages");
      const response = await addDoc(langaugesCol, data);
      const languageId = response.id;
      const languagesDoc = doc(db, "languages", languageId);
      await updateDoc(languagesDoc, { id: languageId });
      //update languages array in zustand store
      const addLanguage = (language) =>
        set((state) => ({
          languages: [...state.languages, language],
        }));
      addLanguage(data);
      return true;
    } catch (error) {
      console.log("ERROR ADDING LANGUAGE: " + error);
      return false;
    }
  },

  ///////////////////////////////////////////////////////////////////////////
  ///////////////////   METHOD TO ADD A NEW AUDIENCE    /////////////////////
  addNewAudience: async ({ name, image }) => {
    console.log("the image for the audience: " + image);

    // CREATING A COLLECTION
    const audienceCollection = collection(db, "audiences");
    // ADDING THE DATA TO FIRESTORE
    const data = {
      id: null,
      name: name,
      image: image,
      isVisible: true,
    };
    try {
      const responseFromFirestore = await addDoc(audienceCollection, data);
      const audience_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const audienceDoc = doc(db, "audiences", audience_id);

      // UPDATING THE AUDIENCE ID
      const response = await updateDoc(audienceDoc, {
        id: audience_id,
      });

      console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
      //update audience array in zustand store
      const addAudience = (audience) =>
        set((state) => ({
          audiences: [...state.audiences, audience],
        }));
      addAudience(data);
      return true;
    } catch (error) {
      console.log("THE ERROR WHEN ADDING AUDIENCE: " + error);
      return false;
    }
  },

  //UPLOADING AUDIENCE IMAGE
  uploadaudienceImage: async (imageFile) => {
    if (!imageFile) {
      return;
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, "audiences/images/" + imageFile.name);

    try {
      await uploadBytes(storageRef, imageFile);

      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
      return false;
    }
  },

  //handle delete Audience Image Url
  deleteAudienceImage: async (imageUrl) => {
    const storage = getStorage(app);
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("Audience Image deleted");
      return true;
    } catch (error) {
      console.log("Error deleting image", error);
      return false;
    }
  },

  // adding searches in the database
  storeSearch: async (data) => {
    try {
      const searchCollection = collection(db, "searches");
      const response = await addDoc(searchCollection, data);
      const search_id = response.id;
      const searchDoc = doc(db, "searches", search_id);
      await updateDoc(searchDoc, { id: search_id });

      const addSearch = (search) =>
        set((state) => ({
          searches: [...state.searches, search],
        }));
      addSearch(data);
      return true;
    } catch (error) {
      console.log("Error in Adding search: ", error);
      return false;
    }
  },

  addEventIdeas: async (data) => {
    try {
      const eventIdeaCollection = collection(db, "event_ideas");
      const responseFromFirestore = await addDoc(eventIdeaCollection, data);
      const event_idea_id = responseFromFirestore.id;
      //creaitng a document
      const eventIdeaDoc = doc(db, "event_ideas", event_idea_id);
      //updating the document
      await updateDoc(eventIdeaDoc, { id: event_idea_id });
      return true;
    } catch (error) {
      console.log("Error adding event idea:", error);
      return false;
    }
  },

  //METHOD TO DELETE event_ideas
  deleteIdea: async (idea_id) => {
    try {
      //get scanner details
      const eventIdeasRef = doc(db, "event_ideas", idea_id);
      await deleteDoc(eventIdeasRef);
      console.log("Entire event Idea deleted successfully");
      //update the scanners
      const updatedIdeas = get().event_ideas.filter(
        (event_idea) => event_idea?.id !== idea_id
      );
      set({ event_ideas: updatedIdeas });
      return true;
    } catch (error) {
      console.log("Error while deleting the event idea", error);
      return false;
    }
  },

  //GETTING A LANGUAGE
  fetcheEventIdeas: async () => {
    try {
      const event_ideas = [];
      const userDoc = collection(db, "event_ideas");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        event_ideas.push(doc.data());
      });
      set({ event_ideas });
      return event_ideas;
    } catch (error) {
      console.log("Error fetching event ideas:", error);
      return [];
    }
  },

  // GO TO FIRESTORE AND REPEAT EVENT DATES
  repeatEventDates: async () => {
    try {
      const eventsCollection = collection(db, "events_new");
      const eventsSnap = await getDocs(eventsCollection);

      for (const event_doc of eventsSnap.docs) {
        const eventData = event_doc.data();
        const event_dates = eventData.event_dates;

        for (const event_date of event_dates) {
          // GETTING THE REPEAT METRICS
          const repeat_metric = eventData.repeat_metric;
          const repeat_every = eventData.repeat_every;

          if (repeat_metric) {
            // START DATE AND END DATE
            const event_date_start_date = convertTimestampToDateTime(
              event_date.start_date
            );
            const event_date_end_date = convertTimestampToDateTime(
              event_date.end_date
            );

            let days_between_occurrences = 1;

            console.log("THE REPEAT METRIC IS: " + repeat_metric);

            if (repeat_metric === "days") {
              days_between_occurrences = repeat_every;
            } else if (repeat_metric === "weeks") {
              days_between_occurrences = repeat_every * 7;
            } else if (repeat_metric === "months") {
              days_between_occurrences = repeat_every * 28;
            } else if (repeat_metric === "years") {
              days_between_occurrences = repeat_every * 365;
            }

            console.log(
              "DAYS BETWEEN OCCURRENCES: " + days_between_occurrences
            );

            // Calculate the number of days since the last occurrence
            const days_since_last_occurrence = Math.floor(
              (new Date() - new Date(event_date_start_date)) /
                (1000 * 60 * 60 * 24)
            );

            // Calculate the number of occurrences that have happened since the last occurrence
            const occurrences_since_last_occurrence = Math.floor(
              days_since_last_occurrence / days_between_occurrences
            );

            console.log(
              `OCCURRENCES SINCE LAST OCCURRENCE: ${occurrences_since_last_occurrence}`
            );

            // Calculate the next occurrence date
            const next_start_date = new Date(
              event_date_start_date.getTime() +
                (occurrences_since_last_occurrence + 1) *
                  days_between_occurrences *
                  24 *
                  60 *
                  60 *
                  1000
            );

            console.log("PREVIOUS START DATE: " + event_date_start_date);
            console.log("NEXT START DATE: " + next_start_date);

            // Calculate the next occurrence date
            const next_end_date = new Date(
              event_date_end_date.getTime() +
                (occurrences_since_last_occurrence + 1) *
                  days_between_occurrences *
                  24 *
                  60 *
                  60 *
                  1000
            );

            // Modify the specific map inside the array
            const updatedEventDates = eventData.event_dates
              .map((eventDate, index) => {
                if (index === 0) {
                  // Update the desired properties of the map
                  return {
                    ...eventDate,
                    start_date: next_start_date,
                    end_date: next_end_date,
                  };
                }

                return null;
              })
              .filter((value) => value !== null);

            console.log("THE UPDATED EVENT DATES: ");
            console.log(updatedEventDates);

            const eventDoc = doc(db, "events_new", eventData.id);
            await updateDoc(eventDoc, {
              event_dates: updatedEventDates,
            });

            console.log(
              `EVENT DATE FOR ${eventData.name} UPDATED TO: ${next_start_date}`
            );
          }
        }
      }

      console.log(
        " /////////////////////////////////////////////// \n\n DONE REPEATING EVENT DATES ////////////////////////////////////////////////////"
      );
    } catch (error) {
      console.log("SOMETHING WENT WRONG REPEATING EVENT DATES: " + error);
    }
  },
});

// CREATING AND EXPORTING THE EVENTS STORE
export const useEvents = create(
  persist(eventsStore, {
    name: "events_store",
  })
);

// Call fetchEvents method on initial load
useEvents.getState().fetchEvents();
useEvents.getState().getUpcomingEvents();
useEvents.getState().getEventCategories();
useEvents.getState().getVisibleCountries();
useEvents.getState().getVisibleLanguages();
useEvents.getState().getVisibleAudiences();
useEvents.getState().getEventCategories();
useEvents.getState().getFeaturedCategories();
