// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots li.slick-active button:before {
  border: none;
  font: inherit;
  background-color: #f67611;
  border-radius: 20px;
  display: block;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.5;
  width: 12px;
  display: inline-block;
}

.slick-dots li button:before {
    border: none;
    font: inherit;
    background-color: #869791;
    border-radius: 20px;
    display: block;
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
    display: inline-block;
  
}

.blog-image{
    object-fit: cover !important;
}
  `, "",{"version":3,"sources":["webpack://./src/components/featured_blogs/featured_blogs.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;EACX,qBAAqB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;IACX,qBAAqB;;AAEzB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".slick-dots li.slick-active button:before {\r\n  border: none;\r\n  font: inherit;\r\n  background-color: #f67611;\r\n  border-radius: 20px;\r\n  display: block;\r\n  height: 12px;\r\n  margin: 5px 7px;\r\n  opacity: 0.5;\r\n  width: 12px;\r\n  display: inline-block;\r\n}\r\n\r\n.slick-dots li button:before {\r\n    border: none;\r\n    font: inherit;\r\n    background-color: #869791;\r\n    border-radius: 20px;\r\n    display: block;\r\n    height: 12px;\r\n    margin: 5px 7px;\r\n    opacity: 0.5;\r\n    width: 12px;\r\n    display: inline-block;\r\n  \r\n}\r\n\r\n.blog-image{\r\n    object-fit: cover !important;\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
