import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditLanguages() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();
  const updateLanguages = useEvents((state) => state.updateLanguages);
  //HANDLING LANGUAGE ADDUP
  const [isLoading, setIsLoading] = useState(false);
  const { language_id } = useParams();
  const [errors, setErrors] = useState({});

  const { document } = useFetchDocument("languages", language_id);
  const [form_languages, setFormLanguages] = useState(document || {});

  useEffect(() => {
    setFormLanguages(document);
  }, [document]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormLanguages((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditlanguage = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateLanguages(language_id, {
          name: form_languages?.name,
          languageCode: form_languages?.languageCode,
        });
        displaySuccessToast("Update successfull");
        navigate("/manage_languages");
      } catch (error) {
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!form_languages?.name) {
      errors.form_languages.name = "name is required";
    }
    if (!form_languages?.languageCode) {
      errors.form_languages.languageCode = "Language code is required";
    }

    return errors;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit language"
          link="manage_languages"
          page_link="Manage Languages"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_languages"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/* FORM TO ADD LANGUAGES */}
                    <form onSubmit={handleEditlanguage} noValidate="novalidate">
                      <div className=" tab-content">
                        <div
                          id="country_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          <div id="country_translations_en">
                            <div className="form-group d-grid">
                              <label
                                htmlFor="country_translations_en_name"
                                className="required"
                              >
                                Name
                              </label>
                              {errors.form_languages?.name && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.form_languages?.name}
                                </span>
                              )}
                              <input
                                type="text"
                                name="name"
                                value={form_languages?.name}
                                onChange={(e) => handleInputChange(e)}
                                required="required"
                                maxLength={50}
                                pattern=".{1,}"
                                className={
                                  errors.form_languages?.name
                                    ? " border border-danger form-control"
                                    : "form-control"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FIELD FOR LANGUAGE CODE */}
                      <div className="form-group">
                        <label htmlFor="language_code" className="required">
                          Language code
                        </label>
                        {errors.form_languages?.languageCode && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.form_languages?.languageCode}
                          </span>
                        )}
                        <input
                          type="text"
                          name="languageCode"
                          value={form_languages?.languageCode}
                          onChange={(e) => handleInputChange(e)}
                          required="required"
                          className={
                            errors.form_languages?.languageCode
                              ? " border border-danger form-control"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary btn">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
