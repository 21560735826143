/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSettings } from "../../store/setting_store";
import { useNavigate } from "react-router-dom";
import {
  convertTimestampToDateTime,
  determinePaymentType,
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
  generateBookingId,
  generateRandomId,
  generateUniqueId,
} from "../../services/helpers";
import { Timestamp } from "firebase/firestore";
import { ordersStore } from "../../store/orders_store";
import { useEffect } from "react";
import AirtelMTN from "./optionpics/airtelMtn.webp";
import FlexiPay from "./optionpics/flexipay.png";
import usePaymentMethodsStore from "../../store/payment_store";
import { tableStore } from "../../store/table_store";
import { userStore } from "../../store/user_store";
import { useVenues } from "../../store/venues_store";

export default function FlexPayment({
  amount,
  ticketQuantities,
  event,
  promoDetails,
  selectedTables,
  room,
  selectedDate,
}) {
  //handling validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9,9}$/, "valid phone number is required")
      .test("valid-prefix", "MTN or Airtel phone number", (value) => {
        // Ensure phoneNumber is a string
        const phoneNumberString = String(value);

        // Check the prefix to determine payment type
        if (
          phoneNumberString.startsWith("75") ||
          phoneNumberString.startsWith("74") ||
          phoneNumberString.startsWith("70") ||
          phoneNumberString.startsWith("77") ||
          phoneNumberString.startsWith("78") ||
          phoneNumberString.startsWith("76")
        ) {
          return true;
        }
        return false;
      })
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails do not match")
      .required("Confirm Email is required"),
  });

  const layout_page = useSettings((state) => state.layout_page);

  //getting the exact room WHERE TABLES ARE
  const addReservation = useVenues((state) => state.addReservation);
  const updateReservation = useVenues((state) => state.updateReservation);

  //zustand states
  const addTransaction = ordersStore((state) => state.addTransaction);
  const navigate = useNavigate();
  const getAuthToken = ordersStore((state) => state.getAuthToken);
  const makePayment = ordersStore((state) => state.makePayment);
  const checkPaymentStatus = ordersStore((state) => state.checkPaymentStatus);
  const updateTransactionStatus = ordersStore(
    (state) => state.updateTransactionStatus
  );
  const fetchTranscations = ordersStore((state) => state.fetchTranscations);
  const payOrganizer = usePaymentMethodsStore((state) => state.payOrganizer);
  const user = userStore((state) => state.user);

  // METHOD TO GO TO SUCCESS PAGE
  function goToSuccessScreen() {
    const orderTickets = [];
    const orderId = generateBookingId();

    // GENERATING THE ORDER TICKETS LIST
    for (let i = 0; i < orderTickets.length; i++) {
      var event_start_date = convertTimestampToDateTime(
        event?.event_dates[0]?.start_date
      ).toLocaleString("en-US");

      var event_end_date = convertTimestampToDateTime(
        event?.event_dates[0]?.end_date
      ).toLocaleString("en-US");

      for (let j = 0; j < orderTickets[i]; j++) {
        const ticket = event.event_dates[0].tickets[i];

        orderTickets.push({
          customer_name: values.name,
          ticket_id: ticket?.id,
          ticket_no: generateBookingId(),
          order_id: orderId,
          created_at: new Date().toLocaleString("en-US"),
          updated_at: new Date().toLocaleString("en-US"),
          customer_id: null,
          event_image: event?.main_event_image,
          event_id: event?.id,
          event_name: event?.name,
          event_start_date: event_end_date,
          event_end_date: event_end_date,
          venue: event?.event_dates[0]?.venue,
          ticket_name: ticket.ticket_name,
          status: "valid",
          ticket_price: ticket.ticket_price ?? 0,
        });
      }
    }

    // ORDER DATA
    const orderData = {
      booking_id: orderId,
      event_id: event?.id,
      event_name: event?.name,
      venue: event?.event_dates[0]?.venue,
      event_image: event?.main_event_image,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      event_start_date: event_start_date,
      event_end_date: event_end_date,
      customer_number: `256${values.phoneNumber}`,
      customer_id: user?.user_id || null,
      order_tickets: orderTickets,
      quantity: orderTickets.length.toString(),
      ticket_price: event.event_dates[0].tickets[0]?.ticket_price ?? 0,
      customer_name: values.name,
      customer_email: values.email,
    };

    navigate("/success_screen", {
      state: { orderData, selectedTables, selectedDate },
    });
  }

  useEffect(() => {
    const fetchingTransaction = async () => {
      await fetchTranscations();
    };

    fetchingTransaction();
  }, []);

  // handling the payment requests

  // METHOD TO HANDLE MAKING PAYMENT AND ORDER
  async function handlePayment() {
    // GENERATING THE TRANSACTION ID
    let selectedPaymentMethod = "Airtel or MTN";
    // a method to get the selected payment method.
    if (values.paymentMethod === "airtel_mtn") {
      selectedPaymentMethod = "Airtel or MTN";
    } else if (values.paymentMethod === "flexiPay") {
      selectedPaymentMethod = "FlexiPay";
    } else {
      console.log("Invalid payment method");
      displayWarningToast("Please choose a payment method");
      return;
    }

    const transactionId = generateRandomId();
    const reservation_id = generateUniqueId();
    const orderId = generateBookingId();
    const paymentType = determinePaymentType(
      values.phoneNumber,
      selectedPaymentMethod
    );

    console.log("THE ORDER TICKETS: " + JSON.stringify(ticketQuantities));

    // TRANSACTION DATA
    const transactionData = {
      transaction_id: transactionId,
      amount: amount,
      currency: "UGX",
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      payment_type: paymentType,
      transaction_status: "pending",
      transaction_type: "Ticket Payment",
      customer_number: `256${values.phoneNumber}`,
      email: `${values.email}`,
      customer_name: values.name ?? null,
      customer_id: null,
      tickets: ticketQuantities,
      platform: "web",
      promocode: promoDetails,
      order_id: orderId,
    };

    console.log("THE TRANSACTION DATA: " + JSON.stringify(transactionData));

    // ADDING THE TRANSACTION TO THE DATABASE

    const transactionResponse = await addTransaction(transactionData);

    const reservationData = {
      id: reservation_id,
      venue: room?.venue,
      selectedDate: selectedDate,
      selectedTables: selectedTables,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      status: "pending",
      event_id: event?.id,
      amount: amount,
    };

    console.log(" the reservation data is,", reservationData);

    if (!transactionResponse) {
      displayErrorToast("Transaction Error Adding Transaction");
      return;
    }
    // if the tables are there, lets add the response for the selected tables
    if (selectedTables?.length > 0) {
      const addReservationResponse = await addReservation(reservationData);
      if (!addReservationResponse) {
        displayErrorToast(" Error Adding Reservation");
      }
    }

    const authToken = await getAuthToken();
    const requestId = generateRandomId();
    const phoneNumber = `256${values.phoneNumber}`;
    const data = await makePayment(
      authToken,
      requestId,
      amount,
      selectedPaymentMethod,
      phoneNumber
    );
    console.log("the data after making payments is:", data);

    let transaction_status = "pending";
    let reservation_status = "pending";
    let loopCount = 40;
    if (selectedPaymentMethod === "Airtel or MTN") {
      loopCount = 40; // if airtel_mtn is selected then loopCount the value
    } else if (selectedPaymentMethod === "flexiPay") {
      loopCount = 60;
    }

    for (let i = 0; i < loopCount; i++) {
      const status = await checkPaymentStatus(authToken, requestId);
      if (status.statusCode === "00") {
        // Payment successful
        transaction_status = "successful";
        reservation_status = " successful";
        displaySuccessToast("The transaction is successful");
        await updateTransactionStatus(transactionId, {
          transaction_status: transaction_status,
        });
        //editing the status of the reservation
        if (room) {
          await updateReservation(room?.venue?.value, reservation_id, {
            status: reservation_status,
          });
        }

        // calling method to pay the organizer
        const eventId = event.id;
        const organizerId = event.organizer_id;

        payOrganizer({
          transactionId: transactionId,
          amount: amount,
          eventId: eventId,
          organizerId: organizerId,
        });

        goToSuccessScreen();

        break;
      } else if (status.statusCode === "01") {
        transaction_status = "pending";
        reservation_status = "pending";
      } else {
        //payment failed
        transaction_status = "failed";
        reservation_status = "failed";
        displayErrorToast("The transaction has failed");
        await updateTransactionStatus(transactionId, {
          transaction_status: transaction_status,
        });
        //editing the status of the reservation
        if (room) {
          await updateReservation(room?.venue?.value, reservation_id, {
            status: reservation_status,
          });
        }
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 4000));
    }
  }

  //validating the form.
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      name: "",
      paymentMethod: "airtel_mtn",
    },
    validationSchema,
    onSubmit: handlePayment,
  });

  return (
    <div>
      <div className="mx-auto">
        <form name="promocode_form" onSubmit={handleSubmit}>
          {/* SECOND  ROW */}
          <div className="row">
            {/* NAME OF THE USER */}
            <div className="col-12 col-md-6">
              <input
                type="text"
                id="name"
                placeholder="Name"
                style={{ height: "40px" }}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? "input-error"
                    : "inputs form-control my-2"
                }
              />
              {errors.name && touched.name && (
                <p className="error">{errors.name}</p>
              )}
            </div>

            {/* PHONE NUMBER */}
            <div className="col-12 col-md-6 mt-2">
              <div className="input-group inputs">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ borderRadius: "10px" }}
                  >
                    +256
                  </span>
                </div>

                <input
                  className={
                    errors.phoneNumber && touched.phoneNumber
                      ? "input-error form-control"
                      : "input form-control"
                  }
                  type="number"
                  id="phoneNumber"
                  style={{ height: "40px", borderRadius: "10px" }}
                  placeholder="7XXXXXXXX"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.phoneNumber && touched.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </div>
          </div>

          {/* THIRD ROW */}
          <div className="row">
            {/* EMAIL ADDRESS */}
            <div className="col-12 col-md-6">
              <input
                className={
                  errors.email && touched.email
                    ? "input-error"
                    : "inputs form-control my-2"
                }
                type="email"
                id="email"
                placeholder="email"
                style={{ height: "40px" }}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>

            {/* CONFIRM EMAIL ADDRESS */}
            <div className="col-12 col-md-6">
              <input
                className={
                  errors.confirmEmail && touched.confirmEmail
                    ? "input-error"
                    : "inputs form-control my-2"
                }
                type="email"
                id="confirmEmail"
                placeholder="confirmEmail"
                style={{ height: "40px" }}
                value={values.confirmEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirmEmail && touched.confirmEmail && (
                <p className="error">{errors.confirmEmail}</p>
              )}
            </div>
          </div>

          <div>
            <h5 className="my-4 font-weight-bold text-center">
              Please Choose a Payment Option
            </h5>

            {/* PAYMENT OPTIONS */}
            <div className=" row align-items-center">
              {/* SELECT PAYMENT OPTION */}
              <div className=" col"></div>

              <div className="col d-flex flex-column align-items-center mb-3">
                <div className=" mb-2">
                  <label class="form-check-label" for="airtel_mtn">
                    <img
                      src={AirtelMTN}
                      alt="AirtelMTN"
                      style={{ borderRadius: 10, width: 100 }}
                    />
                  </label>
                </div>
                <div className=" ml-3">
                  <input
                    id="airtel_mtn"
                    className="form-check-input"
                    style={{ width: 10, height: 10 }}
                    type="radio"
                    name="paymentMethod"
                    value="airtel_mtn" // Set the value to "option1"
                    checked={values.paymentMethod === "airtel_mtn"} // Option 1 is checked by default
                    onChange={handleChange} // Handle changes to update the state
                  />
                </div>
              </div>

              <div className="col d-flex flex-column align-items-center mb-3">
                <div className=" mb-2">
                  <label class="form-check-label" for="flexiPay">
                    <img src={FlexiPay} alt="FlexiPay" style={{ width: 100 }} />
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input"
                    id="flexiPay"
                    style={{ width: 10, height: 10 }}
                    type="radio"
                    name="paymentMethod"
                    value="flexiPay" // Set the value to "option2"
                    checked={values.paymentMethod === "flexiPay"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col"></div>
            </div>
            <div className="col-12 col-md-6 mt-5 center">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn text-white btn-block mt-3 mt-md-0"
                style={{
                  backgroundColor: layout_page?.primary_color,
                }}
              >
                {isSubmitting ? (
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                ) : (
                  <>
                    <i className="fas fa-money-check pr-2" /> Pay Now
                  </>
                )}
              </button>
            </div>
          </div>
          {isSubmitting && (
            <>
              {values.paymentMethod === "airtel_mtn" ? (
                <div className="alert alert-info mt-3" role="alert">
                  Check your phone and enter your pin to confirm payment
                </div>
              ) : (
                <div className="alert alert-info mt-3" role="alert">
                  Open your FlexiPay App and enter your details to complete the
                  payment
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}
