import React, { useState } from "react";
import MappingImage from "../../../assets/images/mappings.png";
import TableTypes from "./RoomDetails/tableTypes/TableTypes";
import Mappings from "./RoomDetails/mappings/Mappings";
import "./RoomDetails.scss"; // Import your SCSS file
import BookedTables from "./RoomDetails/bookedTables/BookedTables";

const TableTypeDetails = ({ tableType_id, venue_id }) => {
  const [activeTab, setActiveTab] = useState("tableTypes");
  //handling active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "tableTypes":
        return (
          <TableTypes
            handleTabClick={handleTabClick}
            tableType_id={tableType_id}
            venue_id={venue_id}
          />
        );

      case "mappings":
        return (
          <Mappings
            handleTabClick={handleTabClick}
            tableType_id={tableType_id}
            venue_id={venue_id}
          />
        );
      case "bookedTables":
        return (
          <BookedTables
            handleTabClick={handleTabClick}
            venue_room_id={tableType_id}
            venue_id={venue_id}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="cursor-pointer d-flex justify-content-around bg-gray pt-1">
        <span
          className={`text-black-50 ${
            activeTab === "tableTypes" ? "active-tab bg-white py-1 px-5" : ""
          } `}
          onClick={() => handleTabClick("tableTypes")}
        >
          <i class="fas fa-chair mr-1"></i>Table Types
        </span>

        <span
          className={`text-black-50 ${
            activeTab === "mappings" ? "active-tab bg-white py-1 px-5" : ""
          } `}
          onClick={() => handleTabClick("mappings")}
        >
          <img
            src={MappingImage}
            alt="mappings"
            style={{ width: 20 }}
            className=" mr-1"
          />
          Mappings
        </span>
        <span
          className={`text-black-50 ${
            activeTab === "bookedTables" ? "active-tab bg-white py-1 px-5" : ""
          } `}
          onClick={() => handleTabClick("bookedTables")}
        >
          <i class="fas fa-file mr-1"></i>Booked Tables
        </span>
      </div>
      <div>{renderActiveTab()}</div>
    </div>
  );
};

export default TableTypeDetails;
