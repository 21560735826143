import { useState } from 'react';

export default function useDisclosure(defaultState = false) {
  const [isOpen, setIsOpen] = useState(defaultState);

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  return { isOpen, onOpen, onClose };
}
