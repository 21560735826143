import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import Loader from "../../../components/loader/loader";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import TextEditor from "../../../components/text_editor/text_editor";
import { SwatchesPicker } from "react-color";
import TagsInputField from "../../../components/tags_input_field/tags_input";
import { useSettings } from "../../../store/setting_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function Layout() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const pagesList = useBlogs((state) => state.pages);
  const uploadLayoutImages = useSettings((state) => state.uploadLayoutImages);
  const deleteImage = useSettings((state) => state.deleteImage);
  const getLayoutPageId = useSettings((state) => state.getLayoutPageId);
  const updateLayout = useSettings((state) => state.updateLayout);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();
  const getLayoutContent = useSettings((state) => state.getLayoutContent);

  const [pages_options, setPagesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [layout_id, setLayoutID] = useState(null);
  const [layoutForm, setLayoutForm] = useState(layout_page || {});

  useEffect(() => {
    //GETTING PAGES
    function getPageOptions() {
      const pageOptions = pagesList.map((page) => {
        return {
          value: page?.id,
          label: page?.title,
        };
      });
      console.log("THE PAGE OPTIONS: ", pageOptions);
      setPagesOptions(pageOptions);
    }

    const fetchLayoutID = async () => {
      const id = await getLayoutPageId();
      setLayoutID(id);
    };
    const getPage = async () => {
      await getLayoutContent();
    };
    getPage();
    fetchLayoutID();
    getPageOptions();
  }, [pagesList.length]);

  // State initialization of urls
  const [logoUrl, setLogoUrl] = useState({ url: layoutForm?.logo, path: "" });
  const [socialMediaImageUrl, setSocialMediaImageUrl] = useState(
    layoutForm?.socialMediaImage
  );
  const [faviconUrl, setFaviconUrl] = useState({
    url: layoutForm?.favicon,
    path: "",
  });

  // Handling logo image
  const handleLogoImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePath = "settings/layout_images/logo.png";
      const imageUrl = await uploadLayoutImages(file, imagePath);
      if (imageUrl) {
        setLogoUrl({ url: imageUrl, path: imagePath });
        setLayoutForm((prevFormData) => ({
          ...prevFormData,
          logo: imageUrl,
        }));
      }
    }
  };

  // Handling favicon image
  const handleFaviconImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePath = "settings/layout_images/favicon.ico";
      const imageUrl = await uploadLayoutImages(file, imagePath);
      if (imageUrl) {
        setFaviconUrl({ url: imageUrl, path: imagePath });
        setLayoutForm((prevFormData) => ({
          ...prevFormData,
          favicon: imageUrl,
        }));
      }
    }
  };

  // Handling social media image
  const handleSocialMediaImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePath = "settings/layout_images/social_media.png";
      const imageUrl = await uploadLayoutImages(file, imagePath);
      if (imageUrl) {
        setSocialMediaImageUrl({ url: imageUrl, path: imagePath });
        setLayoutForm((prevFormData) => ({
          ...prevFormData,
          socialMediaImage: imageUrl,
        }));
      }
    }
  };

  // Handling delete logo image
  const handleDeleteLogoImage = async (e) => {
    e.preventDefault();
    if (layoutForm?.logo) {
      await deleteImage(layoutForm?.logo);
      setLogoUrl(null);
      setLayoutForm((prevFormData) => ({
        ...prevFormData,
        logo: null,
      }));
    }
  };

  // Handling delete favicon image
  const handleDeleteFaviconImage = async () => {
    if (layoutForm?.favicon) {
      await deleteImage(layoutForm?.favicon);
      setFaviconUrl(null);
      setLayoutForm((prevFormData) => ({
        ...prevFormData,
        favicon: null,
      }));
    }
  };

  // Handling delete social media image
  const handleDeleteSocialMediaImage = async (e) => {
    e.preventDefault();
    if (layoutForm?.socialMediaImage) {
      var response = await deleteImage(layoutForm?.socialMediaImage);
      if (response) {
        setSocialMediaImageUrl(null);
        setLayoutForm((prevFormData) => ({
          ...prevFormData,
          socialMediaImage: null,
        }));
      }
    } else {
      console.log("The social media image is empty");
    }
  };

  //handle change of keywords
  const handleKeyWords = (keyWords) => {
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      keyWords,
    }));
  };

  // HANDLE DESCRIPTION
  const handleDescriptionChange = (value) => {
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      description: value,
    }));
  };

  //handle Message
  const handleMessageChange = (value) => {
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      message: value,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //changing the primary color of the website
  const handleOnChange = (color) => {
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      primary_color: color.hex,
    }));
  };

  // Radio button onChange
  const handleEnableProductionChange = (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      enableProduction: value,
    }));
  };

  const handleEnableDebuggingChange = (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      enableAppDebugging: value,
    }));
  };

  const handleEnableMaintenanceChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      enableMentainanceMode: value,
    }));
  };

  const handleCompactAppChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      enableCompactApp: value,
    }));
  };

  const handleShowBackToButtonChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      showBackTopButton: value,
    }));
  };
  const handleCompPageChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      show_comp_page_link: value,
    }));
  };
  const handleShowCookieBtn = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      show_cookie_bar_bottom: value,
    }));
  };

  const handleCookiePageLink = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      show_cookie_page_link: value,
    }));
  };
  const handleShowPrivacyChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      show_privacy_page_link: value,
    }));
  };

  const handleShowTermsServiceChange = async (e) => {
    const value = e.target.value === "1" ? true : false;
    setLayoutForm((prevFormData) => ({
      ...prevFormData,
      show_terms_service_link: value,
    }));
  };

  // Handling the update process
  const handleEditing = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Update the state values with the URLs from the form data
      setLogoUrl({ url: layoutForm?.logo, path: "" });
      setFaviconUrl({ url: layoutForm?.favicon, path: "" });
      setSocialMediaImageUrl({ url: layoutForm?.socialMediaImage, path: "" });

      await updateLayout(layout_id, {
        compliance_slug: layoutForm?.compliance_slug,
        cookie_policy: layoutForm?.cookie_policy,
        privacy_policy: layoutForm?.privacy_policy,
        terms_service: layoutForm?.terms_service,
        socialMediaImage: layoutForm?.socialMediaImage || "",
        logo: layoutForm?.logo || "",
        favicon: layoutForm?.favicon || "",
        keyWords: layoutForm?.keyWords,
        description: layoutForm?.description,
        message: layoutForm?.message,
        twitter: layoutForm?.twitter,
        enableAppDebugging: layoutForm?.enableAppDebugging || false,
        enableCompactApp: layoutForm?.enableCompactApp || false,
        enableMentainanceMode: layoutForm?.enableMentainanceMode || false,
        enableProduction: layoutForm?.enableProduction || false,
        showBackTopButton: layoutForm?.showBackTopButton || false,
        primary_color: layoutForm?.primary_color || "#f76300",
        app_secret: layoutForm?.app_secret,
        date_time_format: layoutForm?.date_time_format,
        alt_date_time_format: layoutForm?.alt_date_time_format,
        date_format: layoutForm?.date_format,
        website_name: layoutForm?.website_name,
        website_slug: layoutForm?.website_slug,
        website_url: layoutForm?.website_url,
        youtube_url: layoutForm?.youtube_url,
        contact_phone: layoutForm?.contact_phone,
        contact_fax: layoutForm?.contact_fax,
        contact_address: layoutForm?.contact_address,
        facebook_url: layoutForm?.facebook_url,
        instagram_url: layoutForm?.instagram_url,
        show_terms_service_link: layoutForm?.show_terms_service_link || false,
        show_privacy_page_link: layoutForm?.show_privacy_page_link || false,
        show_cookie_page_link: layoutForm?.show_cookie_page_link || false,
        show_cookie_bar_bottom: layoutForm?.show_cookie_bar_bottom || false,
        show_comp_page_link: layoutForm?.show_comp_page_link || false,
        google_tracking_id: layoutForm?.google_tracking_id,
      });

      displaySuccessToast("Data saved successfully");
    } catch (error) {
      displayErrorToast("Something went wrong in adding data");
      console.log("Error settings:", error);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Layout settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"layout"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/* MAIN CONTENT */}
                    <form
                      onSubmit={handleEditing}
                      name="app_layout_settings"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          App Environment
                        </legend>
                        <small
                          id="app_layout_settings_app_environment_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Development environment is used for development
                          purposes only
                        </small>
                        <div
                          id="app_layout_settings_app_environment"
                          aria-describedby="app_layout_settings_app_environment_help"
                        >
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_environment_0"
                              name="enableProduction"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={layoutForm?.enableProduction === true}
                              onChange={handleEnableProductionChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_environment_0"
                            >
                              Production
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_environment_1"
                              name="enableProduction"
                              required="required"
                              value={"0"}
                              className="custom-control-input"
                              checked={layoutForm?.enableProduction === false}
                              onChange={handleEnableProductionChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_environment_1"
                            >
                              Development
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          App Debugging
                        </legend>
                        <small
                          id="app_layout_settings_app_debug_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Enable to display stacktraces on error pages or if
                          cache files should be dynamically rebuilt on each
                          request
                        </small>
                        <div
                          id="app_layout_settings_app_debug"
                          aria-describedby="app_layout_settings_app_debug_help"
                        >
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_debug_0"
                              name="app_layout_settings[app_debug]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={layoutForm?.enableAppDebugging === true}
                              onChange={handleEnableDebuggingChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_debug_0"
                            >
                              Enable
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_debug_1"
                              name="app_layout_settings[app_debug]"
                              required="required"
                              value={"0"}
                              className="custom-control-input"
                              checked={layoutForm?.enableAppDebugging === false}
                              onChange={handleEnableDebuggingChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_debug_1"
                            >
                              Disable
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_app_secret"
                          className="required"
                        >
                          App Secret
                        </label>
                        <small
                          id="app_layout_settings_app_secret_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          This is a string that should be unique to your
                          application and it is commonly used to add more
                          entropy to security related operations
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_app_secret"
                          name="app_secret"
                          required="required"
                          aria-describedby="app_layout_settings_app_secret_help"
                          className="form-control"
                          value={layoutForm?.app_secret || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Maintenance mode
                        </legend>
                        <small
                          id="app_layout_settings_maintenance_mode_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Enable maintenance mode to display a maintenance page
                          for all users but the users who are granted the
                          ROLE_ADMINISTRATOR role, if you lost your session, you
                          can edit the MAINTENANCE_MODE parameter directly in
                          the .env file
                        </small>
                        <div
                          id="app_layout_settings_maintenance_mode"
                          aria-describedby="app_layout_settings_maintenance_mode_help"
                        >
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_maintenance_mode_0"
                              name="app_layout_settings[maintenance_mode]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.enableMentainanceMode === false
                              }
                              onChange={handleEnableMaintenanceChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_maintenance_mode_0"
                            >
                              Disabled
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_maintenance_mode_1"
                              name="app_layout_settings[maintenance_mode]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={
                                layoutForm?.enableMentainanceMode === true
                              }
                              onChange={handleEnableMaintenanceChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_maintenance_mode_1"
                            >
                              Enabled
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_maintenance_mode_custom_message">
                          Maintenance mode custom message
                        </label>
                        <TextEditor
                          name="message"
                          value={layoutForm?.message || ""}
                          onChange={(value) => handleMessageChange(value)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_date_format"
                          className="required"
                        >
                          Date and time format
                        </label>
                        <small
                          id="app_layout_settings_date_format_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Project wide date and time format, follow this link
                          for a list of supported characters:
                          https://unicode-org.github.io/icu/userguide/format_parse/datetime/
                          . Please make sure to keep the double quotes ""around
                          the format string
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_date_format"
                          name="date_time_format"
                          required="required"
                          aria-describedby="app_layout_settings_date_format_help"
                          className="form-control"
                          placeholder="eee dd MMM y, h:mm a z"
                          value={layoutForm?.date_time_format || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_date_format_simple"
                          className="required"
                        >
                          Alternative date and time format
                        </label>
                        <small
                          id="app_layout_settings_date_format_simple_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Used in some specific cases, follow this link for a
                          list of supported characters:
                          https://www.php.net/manual/en/datetime.format.php .
                          Please make sure to keep the double quotes ""around
                          the format string
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_date_format_simple"
                          name="alt_date_time_format"
                          required="required"
                          aria-describedby="app_layout_settings_date_format_simple_help"
                          className="form-control"
                          value={layoutForm?.alt_date_time_format || ""}
                          placeholder="d/m/Y, g:i A T"
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_date_format_date_only"
                          className="required"
                        >
                          Date only format
                        </label>
                        <small
                          id="app_layout_settings_date_format_date_only_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Used in some specific cases, follow this link for a
                          list of supported characters:
                          https://www.php.net/manual/en/datetime.format.php .
                          Please make sure to keep the double quotes ""around
                          the format string
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_date_format_date_only"
                          name="date_format"
                          required="required"
                          aria-describedby="app_layout_settings_date_format_date_only_help"
                          className="form-control"
                          value={layoutForm?.date_format || ""}
                          placeholder="D j M Y"
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_name"
                          className="required"
                        >
                          Website name
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_website_name"
                          name="website_name"
                          required="required"
                          className="form-control"
                          value={layoutForm?.website_name || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_slug"
                          className="required"
                        >
                          Website slug
                        </label>
                        <small
                          id="app_layout_settings_website_slug_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Enter the chosen website name with no spaces and no
                          uppercase characters (for SEO purposes)
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_website_slug"
                          name="website_slug"
                          required="required"
                          aria-describedby="app_layout_settings_website_slug_help"
                          className="form-control"
                          value={layoutForm?.website_slug || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_url"
                          className="required"
                        >
                          Website url
                        </label>
                        <small
                          id="app_layout_settings_website_url_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          Enter the full website url
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_website_url"
                          name="website_url"
                          required="required"
                          aria-describedby="app_layout_settings_website_url_help"
                          className="form-control"
                          value={layoutForm?.website_url || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_root_url"
                          className="required"
                        >
                          Website root url
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_website_root_url"
                          name="website_root_url"
                          required="required"
                          className="form-control"
                          value={layoutForm?.website_root_url || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      {/* WEBSITE DESCRIPTION */}
                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_description_en"
                          className="required"
                        >
                          Website description
                        </label>
                        <TextEditor
                          name="description"
                          value={layoutForm?.description || ""}
                          onChange={(value) => handleDescriptionChange(value)}
                        />
                      </div>

                      {/* WEBSITE DESCRIPTION */}
                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_website_key_words"
                          className="required"
                        >
                          Website SEO Keywords
                        </label>
                        <TagsInputField
                          tags={
                            Array.isArray(layoutForm?.keyWords)
                              ? layoutForm?.keyWords
                              : [layoutForm?.keyWords]
                          }
                          handleChange={handleKeyWords}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_contact_phone">
                          Contact phone
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_contact_phone"
                          name="contact_phone"
                          className="form-control"
                          value={layoutForm?.contact_phone || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_contact_fax">
                          Contact fax
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_contact_fax"
                          name="contact_fax"
                          className="form-control"
                          value={layoutForm?.contact_fax || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_contact_address">
                          Contact address
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_contact_address"
                          name="contact_address"
                          className="form-control"
                          value={layoutForm?.contact_address || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_facebook_url">
                          Facebook url
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_facebook_url"
                          name="facebook_url"
                          className="form-control"
                          value={layoutForm?.facebook_url || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_instagram_url">
                          Instagram url
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_instagram_url"
                          name="instagram_url"
                          className="form-control"
                          value={layoutForm?.instagram_url || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_youtube_url">
                          Youtube url
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_youtube_url"
                          name="youtube_url"
                          className="form-control"
                          value={layoutForm?.youtube_url || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_twitter_url">
                          Twitter url
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_twitter_url"
                          name="twitter"
                          className="form-control"
                          value={layoutForm?.twitter || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      {/* APPLICATION LAYOUT */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Application layout
                        </legend>
                        <div id="app_layout_settings_app_layout">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_layout_0"
                              name="app_layout_settings[app_layout]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="container"
                              value={"1"}
                              checked={layoutForm?.enableCompactApp === true}
                              onChange={handleCompactAppChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_layout_0"
                            >
                              Compact
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_layout_1"
                              name="app_layout_settings[app_layout]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={layoutForm?.enableCompactApp === false}
                              onChange={handleCompactAppChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_layout_1"
                            >
                              Fluid
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {/* APPLICATION COLOR THEME */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Application theme
                        </legend>
                        <div>
                          <SwatchesPicker
                            color={layoutForm?.primary_color || ""}
                            onChangeComplete={handleOnChange}
                          />
                        </div>

                        {/* <div id="app_layout_settings_app_theme">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_0"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="orange"
                              defaultChecked="checked"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_0">
                              Orange
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_1"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="lightblue"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_1">
                              Light blue
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_2"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="darkblue"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_2">
                              Dark blue
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_3"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="yellow"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_3">
                              Yellow
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_4"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="purple"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_4">
                              Purple
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_5"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="pink"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_5">
                              Pink
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_6"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="red"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_6">
                              Red
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_7"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="green"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_7">
                              Green
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_app_theme_8"
                              name="app_layout_settings[app_theme]"
                              required="required"
                              className="custom-control-input"
                              defaultValue="dark"
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_app_theme_8">
                              Dark
                            </label>
                          </div>
                        </div> */}
                      </fieldset>

                      {/* <div className="form-group">
                        <div
                          id="appThemeColorPreview"
                          className="shadow img-sm"
                        />
                      </div> */}

                      <div className="form-group">
                        <label
                          htmlFor="app_layout_settings_primary_color"
                          className="required"
                        >
                          Primary color code
                        </label>
                        <input
                          type="text"
                          id="app_layout_settings_primary_color"
                          name="app_layout_settings[primary_color]"
                          required="required"
                          readOnly="readonly"
                          className="form-control"
                          onChange={handleOnChange}
                          value={layoutForm?.primary_color || ""}
                        />
                      </div>

                      {/* Logo image */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Logo</legend>
                        {/* Existing logo image */}
                        {layoutForm?.logo ? (
                          <div className="d-grid">
                            <img
                              src={layoutForm?.logo}
                              alt=""
                              style={{ maxWidth: "50%" }}
                            />
                            <div
                              onClick={handleDeleteLogoImage}
                              className="bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                              style={{ width: "50%", fontWeight: "500" }}
                            >
                              Delete Logo
                            </div>
                          </div>
                        ) : (
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="logo_post_imageFile_file"
                                name="logo_post[imageFile][file]"
                                className="custom-file-input"
                                onChange={handleLogoImage}
                                required={true}
                              />
                              <label
                                htmlFor="logo_post_imageFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        )}
                      </fieldset>

                      {/* Favicon image */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Favicon</legend>
                        {/* Existing favicon image */}
                        {layoutForm?.favicon ? (
                          <div className="d-grid">
                            <img
                              src={layoutForm?.favicon}
                              alt=""
                              style={{ maxWidth: "50%" }}
                            />
                            <div
                              onClick={handleDeleteFaviconImage}
                              className="bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                              style={{ width: "50%", fontWeight: "500" }}
                            >
                              Delete Favicon
                            </div>
                          </div>
                        ) : (
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="favicon_post_imageFile_file"
                                name="favicon_post[imageFile][file]"
                                className="custom-file-input"
                                onChange={handleFaviconImage}
                                required={true}
                              />
                              <label
                                htmlFor="favicon_post_imageFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        )}
                      </fieldset>

                      {/* Social media image */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">
                          Social media share image
                        </legend>
                        {/* Existing social media image */}
                        {layoutForm?.socialMediaImage ? (
                          <div className="d-grid">
                            <img
                              src={layoutForm?.socialMediaImage}
                              alt=""
                              style={{ maxWidth: "50%" }}
                            />
                            <div
                              onClick={handleDeleteSocialMediaImage}
                              className="bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                              style={{ width: "50%", fontWeight: "500" }}
                            >
                              Delete Social Media Image
                            </div>
                          </div>
                        ) : (
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="social_media_post_imageFile_file"
                                name="social_media_post[imageFile][file]"
                                className="custom-file-input"
                                onChange={handleSocialMediaImage}
                                required={true}
                              />
                              <label
                                htmlFor="social_media_post_imageFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        )}
                      </fieldset>

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the back to top button
                        </legend>
                        <div id="app_layout_settings_show_back_to_top_button">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_back_to_top_button_0"
                              name="app_layout_settings[show_back_to_top_button]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={layoutForm?.showBackTopButton === true}
                              onChange={handleShowBackToButtonChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_back_to_top_button_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_back_to_top_button_1"
                              name="app_layout_settings[show_back_to_top_button]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={layoutForm?.showBackTopButton === false}
                              onChange={handleShowBackToButtonChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_back_to_top_button_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {/* TERMS OF SERVICE part */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the terms of service page link
                        </legend>
                        <div id="app_layout_settings_show_terms_of_service_page">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_terms_of_service_page_0"
                              name="app_layout_settings[show_terms_of_service_page]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={
                                layoutForm?.show_terms_service_link === true
                              }
                              onChange={handleShowTermsServiceChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_terms_of_service_page_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_terms_of_service_page_1"
                              name="app_layout_settings[show_terms_of_service_page]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.show_terms_service_link === false
                              }
                              onChange={handleShowTermsServiceChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_terms_of_service_page_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <label
                          className="required"
                          htmlFor="app_layout_settings_terms_of_service_page_slug"
                        >
                          Terms of service page slug
                        </label>
                        <SearchableDropdown
                          value={layoutForm?.terms_service}
                          name="terms_service"
                          options={pages_options}
                          isMulti={false}
                          onChange={(selectedOption) =>
                            handleInputChanging({
                              target: {
                                name: "terms_service",
                                value: selectedOption,
                              },
                            })
                          }
                        />
                      </div>
                      {/* PRIVACY POLICY part */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the privacy policy page link
                        </legend>
                        <div id="app_layout_settings_show_privacy_policy_page">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_privacy_policy_page_0"
                              name="app_layout_settings[show_privacy_policy_page]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={
                                layoutForm?.show_privacy_page_link === true
                              }
                              onChange={handleShowPrivacyChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_privacy_policy_page_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_privacy_policy_page_1"
                              name="app_layout_settings[show_privacy_policy_page]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.show_privacy_page_link === false
                              }
                              onChange={handleShowPrivacyChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_privacy_policy_page_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <label
                          className="required"
                          htmlFor="app_layout_settings_privacy_policy_page_slug"
                        >
                          Privacy policy page slug
                        </label>
                        <SearchableDropdown
                          value={layoutForm?.privacy_policy}
                          name="privacy_policy"
                          options={pages_options}
                          isMulti={false}
                          onChange={(selectedOption) =>
                            handleInputChanging({
                              target: {
                                name: "privacy_policy",
                                value: selectedOption,
                              },
                            })
                          }
                        />
                      </div>
                      {/* COOKIE POLICY part */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the cookie policy page link
                        </legend>
                        <div id="app_layout_settings_show_cookie_policy_page">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_cookie_policy_page_0"
                              name="app_layout_settings[show_cookie_policy_page]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={
                                layoutForm?.show_cookie_page_link === true
                              }
                              onChange={handleCookiePageLink}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_cookie_policy_page_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_cookie_policy_page_1"
                              name="app_layout_settings[show_cookie_policy_page]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.show_cookie_page_link === false
                              }
                              onChange={handleCookiePageLink}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_cookie_policy_page_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {/* COOKIE POLICY part */}
                      <div className="form-group">
                        <label
                          className="required"
                          htmlFor="app_layout_settings_cookie_policy_page_slug"
                        >
                          Cookie policy page slug
                        </label>
                        <SearchableDropdown
                          value={layoutForm?.cookie_policy}
                          name="cookie_policy"
                          options={pages_options}
                          isMulti={false}
                          onChange={(selectedOption) =>
                            handleInputChanging({
                              target: {
                                name: "cookie_policy",
                                value: selectedOption,
                              },
                            })
                          }
                        />
                      </div>

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the cookie policy bar at the bottom
                        </legend>
                        <div id="app_layout_settings_show_cookie_policy_bar">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_cookie_policy_bar_0"
                              name="app_layout_settings[show_cookie_policy_bar]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={
                                layoutForm?.show_cookie_bar_bottom === true
                              }
                              onChange={handleShowCookieBtn}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_cookie_policy_bar_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_cookie_policy_bar_1"
                              name="app_layout_settings[show_cookie_policy_bar]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.show_cookie_bar_bottom === false
                              }
                              onChange={handleShowCookieBtn}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_cookie_policy_bar_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      {/* compliance part */}

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the GDPR compliance page link
                        </legend>
                        <div id="app_layout_settings_show_gdpr_compliance_page">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_gdpr_compliance_page_0"
                              name="app_layout_settings[show_gdpr_compliance_page]"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={layoutForm?.show_comp_page_link === true}
                              onChange={handleCompPageChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_gdpr_compliance_page_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="app_layout_settings_show_gdpr_compliance_page_1"
                              name="app_layout_settings[show_gdpr_compliance_page]"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={
                                layoutForm?.show_comp_page_link === false
                              }
                              onChange={handleCompPageChange}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="app_layout_settings_show_gdpr_compliance_page_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-group">
                        <label
                          className="required"
                          htmlFor="app_layout_settings_gdpr_compliance_page_slug"
                        >
                          Gdpr compliance page slug
                        </label>

                        <SearchableDropdown
                          value={layoutForm?.compliance_slug}
                          name="compliance_slug"
                          options={pages_options}
                          isMulti={false}
                          onChange={(selectedOption) =>
                            handleInputChanging({
                              target: {
                                name: "compliance_slug",
                                value: selectedOption,
                              },
                            })
                          }
                        />
                      </div>

                      {/* <div className="form-group">
                        <label htmlFor="app_layout_settings_custom_css">
                          Custom css
                        </label>
                        <textarea
                          id="app_layout_settings_custom_css"
                          name="app_layout_settings[custom_css]"
                          rows={15}
                          className="form-control"
                          defaultValue={""}
                        />
                      </div> */}

                      <div className="form-group">
                        <label htmlFor="app_layout_settings_google_analytics_code">
                          Google analytics Tracking ID
                        </label>
                        <small
                          id="app_layout_settings_google_analytics_code_help"
                          className="form-text text-muted mb-3"
                        >
                          <i className="fas fa-info-circle text-primary mr-1" />
                          e.g. UA-000000-2
                        </small>
                        <input
                          type="text"
                          id="app_layout_settings_google_analytics_code"
                          name="google_tracking_id"
                          aria-describedby="app_layout_settings_google_analytics_code_help"
                          className="form-control"
                          value={layoutForm?.google_tracking_id || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>

                      {/* SUBMIT BUTTON */}
                      <div className="form-group">
                        <button
                          type="submit"
                          id="app_layout_settings_save"
                          name="app_layout_settings[save]"
                          className="btn btn-primary btn"
                        >
                          Save
                        </button>
                      </div>
                      <input
                        type="hidden"
                        id="app_layout_settings__token"
                        name="app_layout_settings[_token]"
                        defaultValue="abb2JkOqG0SVp0jbezPSUzq0laBC5bxfqCNuovKQEts"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
