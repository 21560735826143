import React from "react";

const PlusButton = ({ incrementQuantity, id }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={(e) => incrementQuantity(id)}
    >
      <path
        opacity="0.9"
        d="M22.8571 14.2857H17.7143V9.14286C17.7143 8.51179 17.2025 8 16.5714 8H15.4286C14.7975 8 14.2857 8.51179 14.2857 9.14286V14.2857H9.14286C8.51179 14.2857 8 14.7975 8 15.4286V16.5714C8 17.2025 8.51179 17.7143 9.14286 17.7143H14.2857V22.8571C14.2857 23.4882 14.7975 24 15.4286 24H16.5714C17.2025 24 17.7143 23.4882 17.7143 22.8571V17.7143H22.8571C23.4882 17.7143 24 17.2025 24 16.5714V15.4286C24 14.7975 23.4882 14.2857 22.8571 14.2857Z"
        fill="#1B1B1E"
      />
      <rect
        x="0.25"
        y="0.25"
        width="31.5"
        height="31.5"
        rx="3.75"
        stroke="#1B1B1E"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default PlusButton;
