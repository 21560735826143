import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import PopingOutReports from "./PopingOutReports";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { useEvents } from "../../store/event_store";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import CustomPagination from "../../components/pagination/Pagination";
import TicketsSoldForAnEvent from "./TicketsSoldForAnEvent";
import { useSettings } from "../../store/setting_store";
import VenueOrganizer from "./venues/VenueOrganizer";
import Status from "./events/Status";
import BreadcrumpAdmin from "./components/breadcrump_admin";

export default function ManageReports() {
  const isAdmin = userStore((state) => state.isAdmin);
  const getEvents = useEvents((state) => state.getEvent);
  const events = useEvents((state) => state.events);
  const payment_page = useSettings((state) => state.payment_page);
  const currency = payment_page?.currency;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const layout_page = useSettings((state) => state.layout_page);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);

  //SETTING FILTERS
  const [eventNameFilter, setEventNameFilter] = useState("");
  const [organizerNameFilter, setOrganizerNameFilter] = useState("");
  const [eventDateFilter, setEventDateFilter] = useState("");

  //filtering logic
  const filteredEvents = events.filter((event) => {
    return (
      !eventNameFilter ||
      event.name.toLowerCase().includes(eventNameFilter.toLowerCase())
    );
  });

  //filerting by event dates
  const filteredEventsWithMatchingDates = filteredEvents.map((event) => {
    const eventStartDateMatches = event?.event_dates?.filter((date) => {
      const eventStartDate = formatDate(
        convertTimestampToDateTime(date?.start_date),
        "YYYY-MM-DD" // Format the date to include only the date part
      );
      console.log("the dates are:", eventStartDate);
      return (
        !eventDateFilter ||
        eventStartDate.toLowerCase().includes(eventDateFilter.toLowerCase())
      );
    });

    return {
      ...event,
      event_dates: eventStartDateMatches, // This will only include matching event dates
    };
  });

  //filtering logic for organizer name
  const filteredEventsByOrganizer = filteredEventsWithMatchingDates.filter(
    async (event) => {
      const eventOrganizerDetails = await getOrganizerDetails(
        event.organizer_id
      );
      const organizerName = eventOrganizerDetails?.organizer_name;
      console.log("organizer_name", organizerName);
      if (!organizerNameFilter) {
        return true;
      } else {
        return (
          organizerName &&
          organizerName
            .toLowerCase()
            .includes(organizerNameFilter.toLowerCase())
        );
      }
    }
  );

  //useeffect
  useEffect(() => {
    const fetchData = async () => {
      await getEvents();
    };
    fetchData();
  }, [events.length]);

  const [sortCriteria, setSortCriteria] = useState("start_date");
  const [sortDirection, setSortDirection] = useState("desc");

  // Sort the filtered
  const sortedEvents = filteredEventsByOrganizer.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    const startDateA = convertTimestampToDateTime(
      a?.event_dates[0]?.start_date
    );
    const startDateB = convertTimestampToDateTime(
      b?.event_dates[0]?.start_date
    );

    if (startDateA < startDateB) {
      return -1 * direction;
    }
    if (startDateA > startDateB) {
      return 1 * direction;
    }
    return 0;
  });

  // PAGINATION STATUS
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(20);
  //get current orders
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFastEvent = indexOfLastEvent - eventsPerPage;
  const current_events = sortedEvents?.slice(
    indexOfFastEvent,
    indexOfLastEvent
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Reports" />

        {/* ADMIN DASHBOARD MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_reports"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {events?.length} event date(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`start_date&direction=desc`}
                            selected={
                              sortCriteria === "start_date" &&
                              sortDirection === "desc"
                            }
                          >
                            Start date (desc)
                          </option>

                          <option
                            value={`start_date&direction=asc`}
                            selected={
                              sortCriteria === "start_date" &&
                              sortDirection === "asc"
                            }
                          >
                            Start date (asc)
                          </option>
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-4 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="event">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="name"
                              name="name"
                              data-url-list="/en/api/get-events"
                              data-minimum-input-length={0}
                              value={eventNameFilter}
                              onChange={(e) =>
                                setEventNameFilter(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">
                              Event date{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="Select a specific event date"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="event_dates"
                              name="event_dates"
                              data-url-list="/en/api/event/all/get-event-dates"
                              data-minimum-input-length={0}
                              value={eventDateFilter}
                              onChange={(e) =>
                                setEventDateFilter(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="organizer">
                              Organizer{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="All event dates for an organizer"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="organizer"
                              name="organizer"
                              data-url-list="/en/api/get-organizers"
                              data-minimum-input-length={0}
                              value={organizerNameFilter}
                              onChange={(e) =>
                                setOrganizerNameFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter">
                          <thead>
                            <tr>
                              <th className="text-center">Reference</th>
                              <th>Event date</th>
                              <th>Organizer</th>
                              <th>Tickets sold</th>
                              <th>Net sales</th>
                              <th>Ticket fees</th>
                              <th>Percentage cut</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DISPLAYING THE REPORTS DETAILS */}
                            {current_events?.map((event, index) => (
                              <tr key={event?.id}>
                                <td className="text-center text-muted text-sm">
                                  {event?.id && event?.id.substring(0, 10)}
                                </td>

                                <td className="text-sm">
                                  <Link
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                    to={"/event_details/" + event?.id}
                                  >
                                    {event?.name}
                                  </Link>
                                  <br />
                                  {formatDate(
                                    convertTimestampToDateTime(
                                      event?.event_dates[index]?.start_date
                                    )
                                  )}
                                </td>
                                <td>
                                  <VenueOrganizer
                                    organizer_ID={event?.organizer_id}
                                  />
                                </td>
                                <td>
                                  <TicketsSoldForAnEvent event_id={event?.id} />
                                </td>
                                <td className="text-sm">
                                  {currency?.label}: {""}0
                                </td>
                                <td className="text-sm">
                                  {" "}
                                  {currency?.label}: {""}0{" "}
                                </td>
                                <td className="text-sm">
                                  <b>0%</b>
                                </td>

                                <td className=" text-sm">
                                  <Status event={event} />
                                </td>
                                <td className=" text-center">
                                  <PopingOutReports
                                    setIsLoading={setIsLoading}
                                    event_id={event?.id}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* pagination */}
                    {sortedEvents?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedEvents?.length / eventsPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
