import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Exclamation from "./Exclamation";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  formatDate,
  formatNumberWithCommas,
} from "./../../services/helpers";
import { usePromoCodes } from "../../store/promocode_store";
import { useEvents } from "../../store/event_store";
import { useSettings } from "../../store/setting_store";

const AboutEvent = ({ event, description, tickets }) => {
  const navigate = useNavigate();

  const getPromoCodes = usePromoCodes((state) => state.getPromoCodes);
  const layout_page = useSettings((state) => state.layout_page);

  const getSpecificPromocode = usePromoCodes(
    (state) => state.getSpecificPromocode
  );

  const validatePromoCode = usePromoCodes((state) => state.validatePromoCode);
  const checkIfTicketHasPromoCode = useEvents(
    (state) => state.checkIfTicketHasPromoCode
  );

  // TOTAL
  const [total, setTotal] = useState(0);
  const [displayPromoInput, setDisplayPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoDetails, setPromoDetails] = useState({});
  const [ticketQuantities, setTicketQuantities] = useState([]);
  const [discountAmount, setDiscountAmount] = useState({});
  const [displayPromo, setDisplayPromo] = useState({});
  const [ticketTotal, setTicketTotal] = useState({});

  const [ticektHasPromocode, setTicektHasPromocode] = useState({});

  // METHOD TO HANDLE CHECKING IF THE TICKETS HAVE PROMOCODES
  const handleCheckIfTicketHasPromoCode = async () => {
    tickets.forEach(async (ticket) => {
      console.log(`THE TICKET ID: ` + ticket.id);
      if (ticket.id) {
        var response = await checkIfTicketHasPromoCode(ticket.id);
        console.log("THE RESPONSE IS: " + response);
        console.log(`THE TICKET ${ticket.id} HAS PROMO?: ` + response);

        const updatedTicektHasPromocode = { ...ticektHasPromocode }; // Create a copy of the existing object

        tickets.forEach((ticket) => {
          updatedTicektHasPromocode[ticket.id] = response;
          console.log(
            "THE SET RESPONSE FOR TICKET WITH ID " +
              ticket.id +
              " IS: " +
              response
          );

          // Set the value for each ticket ID
        });

        setTicektHasPromocode(updatedTicektHasPromocode);
      }
    });
  };

  // GET PROMOCODES
  useEffect(() => {
    handleCheckIfTicketHasPromoCode();
  }, [tickets.length]);

  // METHOD TO HANDLE TICKET TOTAL
  const calculateTicketTotal = (
    ticket_quantities,
    discount_amount,
    index,
    ticket
  ) => {
    console.log("THE INDEX IS: " + index);
    console.log("THE TICKET QUANTITY: " + ticket_quantities[index]);

    console.log(
      "TOTAL WITHOUT DISCOUNT: " +
        ticket_quantities[index] * ticket?.ticket_price
    );

    var total =
      ticket_quantities[index] * ticket?.ticket_price -
      (discount_amount[ticket?.id] !== undefined
        ? discount_amount[ticket?.id]
        : 0);
    console.log("THE TOTAL FOR THIS TICKET IS: " + total);
    setTicketTotal({ ...ticketTotal, [index]: total });
  };

  // METHOD TO HANDLE DISPLAY PROMO
  const handleDisplayPromo = (index) => {
    setDisplayPromo({ ...displayPromo, [index]: !displayPromo[index] });
  };

  // GET PROMOCODES
  useEffect(() => {
    getPromoCodes();
  }, []);

  // METHOD TO VALIDATE PROMOCODE
  const handleValidatePromoCode = async (e, index, ticket) => {
    e.preventDefault();

    console.log("THE TICKET ID: ", ticket?.id);
    console.log("THE PROMO CODE IS: " + promoCode);
    console.log("THE EVENT ID HERE IS: " + event.id);

    var response = await validatePromoCode({
      promocode: promoCode,
      event_id: event.id,
      ticket_id: ticket?.id,
    });

    console.log("THE PROMOCODE RESPONSE: " + response);

    if (response !== null && response) {
      displaySuccessToast("Promo code applied successfully");
      setDisplayPromoInput(false);

      // UPDATE PROMO DETAILS FOR THIS TICKET
      setPromoDetails({ ...promoDetails, [ticket?.id]: response });

      // CALCULATE THE DISCOUNT AMOUNT
      calculateDiscountAmount(ticketQuantities, response, index, ticket);
    } else {
      displayErrorToast("Invalid promo code");
    }
  };

  // METHOD TO CALCULATE THE DISCOUNT AMOUNT
  function calculateDiscountAmount(ticket_quantities, promo, index, ticket) {
    var updatedDiscountAmount = 0;
    if (promo.discountType === "percentage" && promo.discount > 0) {
      var discount =
        (ticket_quantities[index] *
          ticket?.ticket_price *
          parseFloat(promo.discount)) /
        100;

      console.log("THE DISCOUNT IS: " + discount);
      updatedDiscountAmount = { ...discountAmount, [ticket?.id]: discount };
      console.log(
        "THE UPDATED DISCOUNT AMOUNT: " + { ...updatedDiscountAmount }
      );
      setDiscountAmount(updatedDiscountAmount);
      calculateTicketTotal(
        ticket_quantities,
        updatedDiscountAmount,
        index,
        ticket
      );
    } else if (promo.discountType === "amount" && promo.discount > 0) {
      updatedDiscountAmount = {
        ...discountAmount,
        [ticket?.id]: parseFloat(promo.discount),
      };
      console.log(
        "THE UPDATED DISCOUNT AMOUNT: " + { ...updatedDiscountAmount }
      );
      setDiscountAmount(updatedDiscountAmount);
      calculateTicketTotal(
        ticket_quantities,
        updatedDiscountAmount,
        index,
        ticket
      );

      console.log("THE DISCOUNT IS: " + promo.discount);
    }
  }

  // useEffect(() => {
  //   if (promoDetails !== "") {
  //     console.log("THE PROMO DETAILS: " + promoDetails);
  //     calculateDiscountAmount(promoDetails);
  //   }
  // }, [total, promoDetails]);

  // METHOD TO UPDATE THE TICKET QUANTITIES
  const handleOrder = (e, index) => {
    const value = parseInt(e.target.value) || 0;
    const updatedQuantities = [...ticketQuantities];
    updatedQuantities[index] = value;
    setTicketQuantities(updatedQuantities);
  };

  // METHOD TO INCREMENT THE TICKET QUANTITY
  const incrementQuantity = (e, index, ticketQuantity, ticket) => {
    // RECALCULATE THE DISCOUNT AMOUNT

    console.log("THE TICKET QUANTITY: " + ticketQuantity);
    console.log("THE INDEX: " + index);

    const updatedQuantities = [...ticketQuantities];
    updatedQuantities[index] = ticketQuantity + 1;
    console.log("THE UPDATED QUANTITIES: " + updatedQuantities);

    if (promoDetails[ticket?.id]) {
      calculateDiscountAmount(
        updatedQuantities,
        promoDetails[ticket?.id],
        index,
        ticket
      );
    } else {
      calculateTicketTotal(updatedQuantities, discountAmount, index, ticket);
    }

    setTicketQuantities(updatedQuantities);
  };

  // METHOD TO DECREMENT THE TICKET QUANTITY
  const decrementQuantity = (e, index, ticketQuantity, ticket) => {
    console.log("THE TICKET QUANTITY: " + ticketQuantity);
    console.log("THE INDEX: " + index);

    if (ticketQuantity > 0) {
      const updatedQuantities = [...ticketQuantities];
      updatedQuantities[index] = ticketQuantity - 1;
      console.log("THE UPDATED QUANTITIES: " + updatedQuantities);

      // RECALCULATE THE DISCOUNT AMOUNT
      if (promoDetails[ticket?.id]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        calculateDiscountAmount(
          updatedQuantities,
          promoDetails[ticket?.id],
          index,
          ticket
        );
      } else {
        calculateTicketTotal(updatedQuantities, discountAmount, index, ticket);
      }

      setTicketQuantities(updatedQuantities);
    }
  };

  // METHOD TO CALCULATE THE TOTAL
  function calculateTotal() {
    var mytotal = 0;
    for (var i = 0; i < tickets.length; i++) {
      console.log("THE TICKET TOTAL: " + ticketTotal[i] + " FOR INDEX: " + i);
      if (ticketTotal[i] !== undefined) {
        mytotal += ticketTotal[i];
      }
    }

    console.log("THE TOTAL: " + mytotal);
    setTotal(mytotal);
  }

  // UPDATE TICKET QUANTITIES
  useEffect(() => {
    calculateTotal(ticketTotal);
  }, [ticketTotal]);

  // METHOD TO GO TO CHECKOUT
  function goToCheckout() {
    var ticketQuantity = 0;

    tickets.forEach((ticket, index) => {
      if (ticketQuantities[index] > 0) {
        ticketQuantity += ticketQuantities[index];
      }
    });

    console.log("THE TOTAL TICKET QUANTITY: " + ticketQuantity);

    if (ticketQuantity === 0) {
      displayErrorToast("Please select at least one ticket");
      return;
    }

    console.log("THE PROMO DETAILS BEFORE GOING TO CHECKOUT: " + promoDetails);
    console.log("THE TICKET QUANTITIES: " + JSON.stringify(ticketQuantities));

    navigate("/checkout_event_detail", {
      state: { event, ticketQuantities, promoDetails, total },
    });
  }

  return (
    <div className="bg-white pb-5 pt-5">
      <div class="row pl-4">
        {/* EVENT DESCRIPTION */}

        <div class="col-md-5 border-end">
          <h4
            className="mb-4 mt-2 text-uppercase fw-bolder"
            style={{ color: "#F76300", fontSize: "18px" }}>
            about event
          </h4>
          <p class="h6">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </p>

          {/* LYXA LOGO */}
        </div>

        <div class="col-md-7 mx-auto">
          <h4
            className="mb-4 mt-2 text-uppercase fw-bolder"
            style={{ color: "#F76300", fontSize: "18px" }}>
            Buy your Ticket Here
          </h4>
          <h6>
            <Exclamation style={{ color: "#F76300" }} />
            <Link
              to={"/sign_up_attendee"}
              className="ml-2"
              style={{ color: "#F76300" }}>
              Sign Up
            </Link>{" "}
            with Lyxa to get access to discounts on events and amenities at
            venues
          </h6>
          <div className=" container">
            <div className="row  mt-4">
              {/* TICKET TYPE */}

              {tickets.map((ticket, index) => {
                console.log("THE TICKET: " + ticket?.id);
                console.log(
                  "THE TICKET HAS PROMO: " + Object.values(ticektHasPromocode)
                );
                console.log("TICKET QUANTITY: " + ticketQuantities[index]);
                console.log(
                  "DISCOUNT VALUE: " +
                    (discountAmount[ticket?.id] !== undefined
                      ? discountAmount[ticket?.id]
                      : 0)
                );
                console.log("TICKET PRICE: " + ticket?.ticket_price);

                if (!ticketQuantities[index]) {
                  ticketQuantities[index] = 0;
                }
                // TICKET QUANTITY
                var ticketQuantity = ticketQuantities[index] ?? 0;

                // var ticketHasPromo = ticket

                return (
                  <div className="col-12 col-md-6">
                    <div
                      class="shadow p-3 mb-5 bg-body gap-5"
                      style={{
                        borderRadius: "17px",
                        fontFamily: "sans-serif",
                      }}>
                      <div className="row">
                        {/* LEFT COL */}
                        <div className="col-10">
                          {/* TICKET NAME */}
                          <h6 className="b">{ticket?.ticket_name}</h6>

                          <h6
                            className="my-4 b"
                            style={{
                              color: "#F76300",
                              fontFamily: "Open Sans",
                            }}>
                            UGX {ticket.ticket_price ?? 0}
                          </h6>
                          <h6 className="text-sm">
                            Ticket valid from{" "}
                            <span style={{ color: "#F76300" }}>
                              {ticket.sales_start_date &&
                                formatDate(
                                  convertTimestampToDateTime(
                                    ticket?.sales_start_date
                                  )
                                )}
                            </span>{" "}
                            till{" "}
                            <span style={{ color: "#F76300" }}>
                              {ticket.sales_end_date &&
                                formatDate(
                                  convertTimestampToDateTime(
                                    ticket?.sales_end_date
                                  )
                                )}
                            </span>
                          </h6>
                        </div>

                        {/* RIGHT COL */}
                        <div className="col-2">
                          <div className="d-flex flex-column align-items-center justify-content-center gap-1">
                            {/* PLUS BUTTON */}
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                incrementQuantity(
                                  e,
                                  index,
                                  ticketQuantity,
                                  ticket
                                )
                              }>
                              <path
                                opacity="0.9"
                                d="M22.8571 14.2857H17.7143V9.14286C17.7143 8.51179 17.2025 8 16.5714 8H15.4286C14.7975 8 14.2857 8.51179 14.2857 9.14286V14.2857H9.14286C8.51179 14.2857 8 14.7975 8 15.4286V16.5714C8 17.2025 8.51179 17.7143 9.14286 17.7143H14.2857V22.8571C14.2857 23.4882 14.7975 24 15.4286 24H16.5714C17.2025 24 17.7143 23.4882 17.7143 22.8571V17.7143H22.8571C23.4882 17.7143 24 17.2025 24 16.5714V15.4286C24 14.7975 23.4882 14.2857 22.8571 14.2857Z"
                                fill="#1B1B1E"
                              />
                              <rect
                                x="0.25"
                                y="0.25"
                                width="31.5"
                                height="31.5"
                                rx="3.75"
                                stroke="#1B1B1E"
                                stroke-width="0.5"
                              />
                            </svg>

                            {/* TICKET QUANTITY */}
                            <input
                              value={ticketQuantity}
                              className={"text-center"}
                              style={{
                                width: "40px",
                                height: "35px",
                                borderRadius: "10px",
                                color: "black",
                                border: "none",
                              }}
                            />

                            {/* MINUS BUTTON */}
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                decrementQuantity(
                                  e,
                                  index,
                                  ticketQuantity,
                                  ticket
                                )
                              }>
                              <path
                                opacity="0.9"
                                d="M22.8571 14H9.14286C8.51179 14 8 14.5971 8 15.3333V16.6667C8 17.4029 8.51179 18 9.14286 18H22.8571C23.4882 18 24 17.4029 24 16.6667V15.3333C24 14.5971 23.4882 14 22.8571 14Z"
                                fill="#1B1B1E"
                              />
                              <rect
                                x="0.25"
                                y="0.25"
                                width="31.5"
                                height="31.5"
                                rx="3.75"
                                stroke="#1B1B1E"
                                stroke-width="0.5"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      {/* PROMOCODE SECTION */}
                      <p className="d-block">
                        {/* PROMO CODE CHECKBOX */}
                        {/* {console.log(ticket.id)} */}
                        {/* {ticektHasPromocode[ticket.id] && ( */}
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"flexCheckDefault" + index}
                            checked={displayPromo[index]}
                            onChange={(e) => handleDisplayPromo(index)}
                            style={{ color: "orange" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + index}>
                            I have a promo code
                          </label>
                        </div>
                        {/* )} */}

                        {/* PROMO INPUT */}
                        {displayPromo[index] && (
                          <div className="mt-3 px-3 mb-0">
                            <input
                              type="text"
                              className="form-control mb-3"
                              placeholder="Enter promo code"
                              onChange={(e) => setPromoCode(e.target.value)}
                              style={{
                                backgroundColor: "white",
                                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                borderRadius: "15px",
                                height: "40px",
                              }}
                            />

                            {/* PROMOCODE BUTTON */}

                            <div
                              className="text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout btn-promo"
                              onClick={(e) =>
                                handleValidatePromoCode(e, index, ticket)
                              }
                              style={{
                                backgroundColor: "white",
                                border: "1px solid black",
                                color: "black",
                                fontWeight: "bold",
                                fontSize: "13px",
                                transition: "background-color 1.5s ease",
                              }}>
                              Apply Promo Code
                            </div>
                          </div>
                        )}
                      </p>

                      {/* TICKET GROSS */}
                      <div className="row">
                        <div className="col-7">
                          <h6 className="text-uppercase fw-bolder ">
                            TICKET GROSS:
                          </h6>
                        </div>

                        <div className="col-5 text-right">
                          <h6 className="text-uppercase fw-bolder ">
                            UGX:{" "}
                            {formatNumberWithCommas(
                              ticketQuantities[index] * ticket?.ticket_price
                            )}
                          </h6>
                        </div>
                      </div>

                      {/* TICKET DISCOUNT */}
                      {promoDetails[ticket?.id] && (
                        <div className="row">
                          <div className="col-7 mb-0 pb-0">
                            <h6 className="text-uppercase fw-bolder ">
                              DISCOUNT:
                            </h6>
                            <p style={{ fontSize: "10px" }}>
                              {promoDetails[ticket?.id]?.description} (promocode
                              name)
                            </p>
                          </div>

                          <div className="col-5 text-right">
                            <h6 className="text-uppercase fw-bolder ">
                              UGX:{" "}
                              {formatNumberWithCommas(
                                discountAmount[ticket?.id] ?? 0
                              )}
                            </h6>
                          </div>
                        </div>
                      )}

                      {/* TICKET SUBTOTAL */}
                      <div
                        className="row "
                        style={{
                          color: layout_page?.primary_color,
                        }}>
                        <div className="col-7">
                          <h6 className="text-uppercase fw-bolder ">
                            TICKET SUBTOTAL:
                          </h6>
                        </div>

                        {/* ALIGN COLUMN CONTENTS RIGHT */}
                        <div className="col-5 text-right">
                          <h6 className="text-uppercase fw-bolder ">
                            UGX:{" "}
                            {formatNumberWithCommas(ticketTotal[index] ?? 0)}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {/* SUB TOTAL */}
            <span className="d-flex justify-content-between mb-2 mr-3">
              <h6 className="text-uppercase fw-bolder ">Sub total: </h6>
              <h6 className="fw-bold">UGX {formatNumberWithCommas(total)}</h6>
            </span>

            {/* TOTAL */}
            <span className="d-flex justify-content-between mb-4 mr-3">
              <h6 className="text-uppercase fw-bolder ">Total: </h6>
              <h6 className="fw-bold">UGX {formatNumberWithCommas(total)}</h6>
            </span>

            <div onClick={goToCheckout}>
              <div className=" text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout">
                Checkout
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutEvent;
