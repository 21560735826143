import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../components/loader/loader";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import PopingOutOrders from "./PopingOutOrders";
import { FaUserCircle } from "react-icons/fa";
import { ordersStore } from "../../store/orders_store";
import { useSettings } from "../../store/setting_store";
import OrganizerName from "../attendee_dashboard/OrganizerName";

const OrdersAdmin = () => {
  const getAllOrdersFromDatabase = ordersStore((state) => state.fetchOrders);
  const orders = ordersStore((state) => state.orders);
  const layout_page = useSettings((state) => state.layout_page);

  const [isLoading, setIsLoading] = useState(false);

  //fetching orders from the database
  useEffect(() => {
    const fetchDataOrders = async () => {
      await getAllOrdersFromDatabase();
    };
    fetchDataOrders();
  }, [orders.length]);

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <table className="table table-hover table-vcenter">
        <thead>
          <tr>
            <th className="text-center">Reference</th>
            <th>Organizer / Event / Date / Ticket</th>
            <th>Attendee / POS</th>
            <th>Order date</th>
            <th>Status</th>
            <th className="text-center">
              <i className="fas fa-cog" />
            </th>
          </tr>
        </thead>
        {/* MAPPING OF THE ORDERS RECIEVED */}
        <tbody>
          {orders.slice(0, 3).map((order, index) => {
            const groupedTickets = order?.order_tickets.reduce(
              (acc, ticket) => {
                const key = `${ticket.event_id}_${ticket.ticket_name}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(ticket);
                return acc;
              },
              {}
            );

            return (
              <tr key={order?.booking_id}>
                <td className="text-center text-muted text-sm">
                  <Link
                    style={{
                      color: layout_page?.primary_color,
                    }}
                    to={"/ticket_details/" + order?.booking_id}
                  >
                    {order?.booking_id}
                  </Link>
                </td>

                <td className="text-sm">
                  <ul>
                    {Object.values(groupedTickets).map(
                      (tickets, eventIndex) => {
                        const event = tickets[0]; // Assuming all tickets in the group belong to the same event
                        return (
                          <li key={eventIndex}>
                            <OrganizerName event_id={event?.event_id} />
                            <i className="fas fa-chevron-right fa-sm mx-1" />
                            <Link
                              to={"/event_details/" + event?.event_id}
                              style={{
                                color: layout_page?.primary_color,
                              }}
                            >
                              {event?.event_name}
                            </Link>
                            <i className="fas fa-chevron-right fa-sm mx-1" />
                            {formatDate(
                              convertTimestampToDateTime(
                                event?.event_start_date
                              )
                            )}
                            <i className="fas fa-chevron-right fa-sm mx-1" />
                            {event?.ticket_name} ticket
                            <br />
                            <small>
                              <strong>
                                {tickets.length}{" "}
                                {tickets.length > 1 ? "tickets" : "ticket"}
                              </strong>
                            </small>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </td>

                <td className="text-sm">
                  <span className="ajax-loading">
                    <FaUserCircle size={30} color="gray" />{" "}
                    {order?.customer_name}
                  </span>
                </td>

                <td className="text-sm">
                  {formatDate(convertTimestampToDateTime(order?.created_at))}
                </td>

                <td className="text-sm">
                  <span className="badge badge-success">
                    {order?.order_tickets[index]?.status}
                  </span>
                </td>

                <td className="text-center">
                  <PopingOutOrders
                    setIsLoading={setIsLoading}
                    booking_id={order?.booking_id}
                    order={order}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersAdmin;
