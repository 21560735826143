import React, { useState } from "react";
import TextEditor from "../../components/text_editor/text_editor";
import { useSettings } from "../../store/setting_store";

function ContactAttendee({ booking_id }) {
  const [attendee_message, setAttendeeMessage] = useState("");
  const layout_page = useSettings((state) => state.layout_page);

  // HANDLE DESCRIPTION
  const handleDescription = (newValue) => {
    console.log("THE MESSAGE: " + newValue);
    setAttendeeMessage(newValue);
  };

  return (
    <div>
      <p
        className=" cursor-pointer"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@getbootstrap"
        style={{ fontSize: "13px" }}
      >
        <span>Contact the attendee</span>
      </p>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order {booking_id}: Contact the attendee
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="message-text" className="col-form-label">
                    Message:
                  </label>

                  <TextEditor
                    id="message-text"
                    className="form-control"
                    required
                    value={attendee_message}
                    onChange={(value) => handleDescription(value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn text-white"
                style={{ backgroundColor: layout_page?.primary_color }}
              >
                Send message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactAttendee;
