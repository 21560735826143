// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-device-width: 480px) {
  /* CSS styles for smartphones */

  .carousel-content {
    padding-left: 3.2rem !important;
    height: 60% !important;
  }

  .carousel-overlay {
    height: 100% !important;
  }
  .carousel {
    height: 60% !important;
  }

  .slider-image {
    height: 25rem !important;
  }

  .carousel-item {
    height: 80% !important;
  }

  .event-name {
    max-width: 300px;
    font-size: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 15px;
    margin-top: 50px;
  }
  
}
`, "",{"version":3,"sources":["webpack://./src/components/slider/slider_mobile.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;;EAE/B;IACE,+BAA+B;IAC/B,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;EACzB;EACA;IACE,sBAAsB;EACxB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;EAClB;;AAEF","sourcesContent":["@media only screen and (max-device-width: 480px) {\r\n  /* CSS styles for smartphones */\r\n\r\n  .carousel-content {\r\n    padding-left: 3.2rem !important;\r\n    height: 60% !important;\r\n  }\r\n\r\n  .carousel-overlay {\r\n    height: 100% !important;\r\n  }\r\n  .carousel {\r\n    height: 60% !important;\r\n  }\r\n\r\n  .slider-image {\r\n    height: 25rem !important;\r\n  }\r\n\r\n  .carousel-item {\r\n    height: 80% !important;\r\n  }\r\n\r\n  .event-name {\r\n    max-width: 300px;\r\n    font-size: 26px;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    white-space: nowrap;\r\n    margin-bottom: 15px;\r\n    margin-top: 50px;\r\n  }\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
