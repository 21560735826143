export const getMaxPrice = (events) => {
  let maxPrice = 0;
  events.forEach((event) => {
    event.event_dates?.forEach((date) => {
      date.tickets?.forEach((ticket) => {
        if (ticket?.ticket_price > maxPrice) {
          maxPrice = ticket?.ticket_price;
        }
      });
    });
  });

  return maxPrice;
};
