import React, { useContext, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Sidenavbar from "./components/sidenavbar";
import { useNavigate } from "react-router-dom";
import { addPOS, addScanner } from "../../services/scanners_and_pos";
import Loader from "../../components/loader/loader";
import { getUserDetails } from "../../services/auth";
import { myContext } from "../../user_context";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function AddPointOfSale() {
  const isOrganizer = userStore((state) => state.isOrganizer);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const layout_page = useSettings((state) => state.layout_page);

  // HANDLING UPLOADING THE BLOG POST
  const handleAddScanner = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    // GETTING THE CURRENT USER ID
    const userDetails = await getUserDetails();

    if (!userDetails) {
      navigate("/sign_in");
    }

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const scannerResponse = await addPOS({
        name: name,
        username: username,
        password: password,
        organizer_id: userDetails.id,
      });

      if (scannerResponse === "success") {
        navigate("/mypointsofsale");
      } else {
        setErrorMessage(scannerResponse);
        setHasErrors(true);
        console.log("error: " + scannerResponse);
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING NAME
    if (!name) {
      errors.name = "Name is required";
    }

    // VALIDATING USERNAME
    if (!username) {
      errors.username = "Username is required";
    }

    // VALIDATING PASSWORD
    if (!password) {
      errors.password = "Password is required";
    }

    // VALIDATING CONFIRM PASSWORD
    if (!confirm_password) {
      errors.confirm_password = "Please Confirm your Password ";
    }

    // VALIDATING IF PASSWORDS MATCH
    if (password !== confirm_password) {
      errors.confirm_password = "Passwords Do Not Match";
      errors.password = "Passwords Do Not Match";
    }

    return errors;
  }

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrump title={"Add a new scanner"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDEBAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      onSubmit={handleAddScanner}
                      name="scanner"
                      method="post"
                      noValidate="novalidate"
                    >
                      {hasErrors && (
                        <div class="alert alert-danger alert-icon">
                          <i class="fas fa-exclamation-circle"></i>{" "}
                          {errorMessage}
                        </div>
                      )}

                      <div id="scanner">
                        {errors.name && (
                          <span style={{ marginTop: 0, color: "red" }}>
                            {errors.name}
                          </span>
                        )}
                        <div className="form-group">
                          <label htmlFor="scanner_name" className="required">
                            Name
                          </label>
                          <input
                            type="text"
                            id="scanner_name"
                            name="scanner[name]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        {/* USERNAME */}
                        {errors.username && (
                          <span style={{ marginTop: 0, color: "red" }}>
                            {errors.username}
                          </span>
                        )}
                        <div className="form-group">
                          <label
                            htmlFor="scanner_username"
                            className="required"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            id="scanner_username"
                            name="scanner[username]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>

                        {/* PASSWORD */}
                        {errors.password && (
                          <span style={{ marginTop: 0, color: "red" }}>
                            {errors.password}
                          </span>
                        )}
                        <div className="form-group">
                          <label
                            htmlFor="scanner_password_first"
                            className="required"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="scanner_password_first"
                            name="scanner[password][first]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        {/*  CONFIRM PASSWORD */}
                        {errors.confirm_password && (
                          <span style={{ marginTop: 0, color: "red" }}>
                            {errors.confirm_password}
                          </span>
                        )}
                        <div className="form-group">
                          <label
                            htmlFor="scanner_password_second"
                            className="required"
                          >
                            Repeat password
                          </label>
                          <input
                            type="password"
                            id="scanner_password_second"
                            name="scanner[password][second]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            id="scanner_save"
                            name="scanner[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
