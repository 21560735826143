import { useEffect, useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import { FaSquareMinus } from "react-icons/fa6";
import { FaPlusSquare } from "react-icons/fa";

import AdminSideNav from "../../pages/admin_dashboard/components/admin_side_nav";
import Footer from "../footer/footer";
import Header from "../header/header";
import Table from "../table";
import usePhonebookStore from "../../store/phonebook_store";

import "./styles.css";
import { useSettings } from "../../store/setting_store";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor((user) => user.phone_number, {
    id: "phone_number",
    header: () => <div className="table_header">Phone number</div>,
    cell: (info) => {
      return <b>{info.getValue()}</b>;
    },
  }),
  columnHelper.accessor((user) => user.mutual_friends, {
    id: "mutual_friends",
    header: () => <div className="table_header">Lyxa friends with contact</div>,
    cell: (info) => {
      return <b>{info.getValue()}</b>;
    },
  }),
];

const usersColumns = [
  columnHelper.accessor((user) => user.phone_number, {
    id: "phone_number",
    header: () => <div className="table_header">Phone number</div>,
    cell: (info) => {
      return <b>{info.getValue()}</b>;
    },
  }),
];

export default function MutualFriends() {
  const phonebooks = usePhonebookStore((state) => state.phonebooks);
  const getPhonebooks = usePhonebookStore((state) => state.getPhonebooks);
  const nonUsers = usePhonebookStore((state) => state.nonUsers);
  const users = usePhonebookStore((state) => state.users);
  const layout_page = useSettings((state) => state.layout_page);

  const [showNonUsers, setShowNonUsers] = useState(true);
  const [showUsers, setShowUsers] = useState(true);

  useEffect(() => {
    getPhonebooks();
  }, []);

  return (
    <div>
      <Header />
      {/* ADMIN DASHBOARD BREADCRUMP */}
      <section className="section-pagetop bg-gray">
        <div className="container clearfix">
          <h4 className="title-page dark b float-xl-left mb-0">Manage users</h4>
          <nav className="float-xl-right mt-2 mt-xl-0">
            <ol className="breadcrumb text-white">
              <li className="breadcrumb-item">
                <a href="/en" className="dark">
                  <i className="fas fa-home" />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/en/dashboard" className="dark">
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage users
              </li>
            </ol>
          </nav>
        </div>
      </section>
      {/* ADMIN DASHBOARD MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* ADMIN DASHBOARD SIDE NAV */}
            <AdminSideNav currentPage={"mutual_friends"} />
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card px-5 py-5">
                <h4 className="mb-5">MUTUALS</h4>
                <div className="d-flex gap-3">
                  <h3
                    style={{
                      color: layout_page?.primary_color,
                    }}>
                    LYXA USERS
                  </h3>{" "}
                  {showUsers ? (
                    <FaSquareMinus
                      color="#F76300"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowUsers(!showUsers)}
                    />
                  ) : (
                    <FaPlusSquare
                      color="#F76300"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowUsers(!showUsers)}
                    />
                  )}
                </div>
                {showUsers && (
                  <Table
                    columns={usersColumns}
                    data={Object.keys(users).map((i) => ({ phone_number: i }))}
                    className="table"
                  />
                )}
                <div className="mt-5 d-flex gap-3">
                  <h3
                    style={{
                      color: layout_page?.primary_color,
                    }}>
                    MUTUALS
                  </h3>{" "}
                  {showNonUsers ? (
                    <FaSquareMinus
                      color="#F76300"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowNonUsers(!showNonUsers)}
                    />
                  ) : (
                    <FaPlusSquare
                      color="#F76300"
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowNonUsers(!showNonUsers)}
                    />
                  )}
                </div>
                {showNonUsers && (
                  <Table
                    columns={columns}
                    data={Object.keys(nonUsers).map((i) => ({
                      phone_number: i,
                      mutual_friends: nonUsers[i].length,
                    }))}
                    className="table"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
