import { BsThreeDotsVertical } from "react-icons/bs";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { useVenues } from "../../../store/venues_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";
const PopingOutVenueTypes = ({ venue_type_id, isVisible }) => {
  const [loading, setLoading] = useState(false);
  const deleteVenueType = useVenues((state) => state.deleteVenueType);
  const updateVenueTypes = useVenues((state) => state.updateVenueTypes);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE VENUE TYPE
  async function handleDeleteVenueType(e) {
    e.preventDefault();
    setLoading(true);
    const response = await deleteVenueType(venue_type_id);
    if (response) {
      displaySuccessToast("🦄 The venue type has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the venue type");
    }
    setLoading(false);
  }
  if (loading) {
    return <Loader />;
  }

  const handleIsHiddenVenueType = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateVenueTypes(venue_type_id, {
      isVisible: !isVisible,
    });
    if (isVisible) {
      setLoading(false);
      displayWarningToast("The venue type has been hidden succeesfully");
    } else {
      setLoading(false);
      displaySuccessToast("The venue type has been made visible succeesfully");
    }
    setLoading(false);
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {" "}
          {/* EDIT VENUE TYPE */}
          <Link to={"/edit-venue-type/" + venue_type_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleIsHiddenVenueType(e)}
          >
            {isVisible !== false ? (
              <>
                <i className=" fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE VENUE TYPE */}
          <DeleteComponentModal
            itemName="Venue type"
            handleDelete={handleDeleteVenueType}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutVenueTypes;
