import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import succesGif from "../../assets/images/success.gif";
// import TicketDisplay from "./ticketDisplay";

export default function SuccessScreen() {
  const { state } = useLocation();
  const { orderData, selectedTables } = state;

  // useEffect(() => {
  //   console.log("ORDER TICKETS: ");
  //   console.log(orderData);
  // }, []);

  // Create a function to group tickets by ticket_name and event_id
  // const groupTickets = (tickets) => {
  //   const groupedTickets = {};
  //   for (const ticket of tickets) {
  //     const key = `${ticket.ticket_name}_${ticket.event_id}`;
  //     if (!groupedTickets[key]) {
  //       groupedTickets[key] = [];
  //     }
  //     groupedTickets[key].push(ticket);
  //   }
  //   return Object.values(groupedTickets);
  // };

  // const groupedOrderTickets = groupTickets(orderData?.order_tickets);

  return (
    <div>
      <Header />
      <div className="">
        {/* ORDER SUCCESSFUL COLUMN */}
        <div className="text-center border-bottom">
          <img src={succesGif} alt="Success Gif" className="success_gif" />

          <h3 className="text-success pb-5">Your Order Was Successful!</h3>
          <h4 className="mb-5">
            An email with the event ticket(s) is going to be sent to{" "}
            <span style={{ color: "#F76300" }}>
              {orderData?.customer_email}
            </span>{" "}
            shortly
          </h4>
        </div>
        {/* 
        {groupedOrderTickets.map((groups, index) => (
          <div key={index}>
            <TicketDisplay tickets={groups} />
            <div className="mb-2"></div>
          </div>
        ))} */}
      </div>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
