import Select from "react-select";
import { Slider } from "@mui/material";

import adults from "../../assets/images/adults.png";
import family from "../../assets/images/family.png";
import group from "../../assets/images/group.png";
import children from "../../assets/images/children.png";
import useQueryStore from "../../store/query_store";
import MiniAccordion from "../mini_accordion/mini_accordion";
import RadioGroup from "../radio_group/radio_group";
import "./side_nav.css";
import DateRadio from "../radio/date_radio";
import AudienceComponent from "../radio/audience_radio";
import EventTypeRadio from "../radio/event_type_radio";
import { useEvents } from "../../store/event_store";
import {
  convert_date_range,
  convert_from_percentage,
} from "../../utils/convertor";
import { getMaxPrice } from "../../utils/events";
import { useEffect, useState } from "react";
import { useSettings } from "../../store/setting_store";

function SideNav() {
  const [priceRange, setPriceRange] = useState([0, 100]);
  const layout_page = useSettings((state) => state.layout_page);

  const freeEventsOnly = useQueryStore((state) => state.freeEventsOnly);
  const setFreeEventsOnly = useQueryStore((state) => state.setFreeEventsOnly);
  const selectedCategory = useQueryStore((state) => state.selectedCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );
  const selectedCountry = useQueryStore((state) => state.selectedCountry);
  const setSelectedCountry = useQueryStore((state) => state.setSelectedCountry);

  const setSearchText = useQueryStore((state) => state.setSearchText);
  const searchText = useQueryStore((state) => state.searchText);
  const setCategory = useQueryStore((state) => state.setCategory);
  const minPrice = useQueryStore((state) => state.minPrice);
  const maxPrice = useQueryStore((state) => state.maxPrice);
  const setMinPrice = useQueryStore((state) => state.setMinPrice);
  const setMaxPrice = useQueryStore((state) => state.setMaxPrice);
  const setCountry = useQueryStore((state) => state.setCountry);
  const setEndDate = useQueryStore((state) => state.setEndDate);
  const setStartDate = useQueryStore((state) => state.setStartDate);
  const setAudienceType = useQueryStore((state) => state.setAudienceType);
  const setEventType = useQueryStore((state) => state.setEventType);
  const eventCategories = useEvents((state) => state.eventCategories);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);
  const currentMaxPrice = getMaxPrice(upcomingEvents);
  const countries = useEvents((state) => state.countries);
  const audiences = useEvents((state) => state.audiences);
  const event_settings_page = useSettings((state) => state.event_settings_page);

  useEffect(() => {
    setMaxPrice(currentMaxPrice);
  }, [currentMaxPrice]);

  // CATEGORIES LIST
  const categories = eventCategories.map((event) => ({
    value: event?.id,
    label: event?.name,
  }));

  // COUNTRIES LIST
  const countriesOptions = countries.map((country) => ({
    value: country?.id,
    label: country?.name,
  }));

  // DATE OPTIONS
  const dateOptions = [
    { value: "today", label: "Today" },
    { value: "tomorrow", label: "Tomorrow" },
    { value: "this weekend", label: "This Weekend" },
    { value: "this week", label: "This Week" },
    { value: "next week", label: "Next Week" },
    { value: "this month", label: "This Month" },
    { value: "next month", label: "Next Month" },
  ];

  // AUDIENCE OPTIONS
  const audienceOptions = audiences.map((audience) => ({
    value: audience?.name,
    image: audience?.image,
  }));

  // EVENT TYPES
  const eventTypes = [
    { value: "online", label: "Online Events Only" },
    { value: "onsite", label: "Local Events Only" },
  ];

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
    setMinPrice(convert_from_percentage(newValue[0], 0, maxPrice));
    setMaxPrice(convert_from_percentage(newValue[1], 0, maxPrice));
  };

  const handleSelectChange = (item, setItem, setValue) => {
    setItem(item);
    setValue(item?.value);
  };

  const handleDateChange = (option) => {
    const dateRange = convert_date_range(option?.value);
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  const handleRadioChange = (option, setOption) => {
    console.log(option);
    setOption(option?.value);
  };

  return (
    <aside className="col-lg-3 order-2 order-lg-1">
      <div className="card card-filter">
        <div>
          {/* FILTER BY KEYWORD */}
          <MiniAccordion heading="Keyword" isVisible={true}>
            <input
              id="keyword"
              name="keyword"
              type="text"
              className="form-control"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </MiniAccordion>

          {/* FILTER BY CATEGORY */}
          {event_settings_page?.show_category_filter === true && (
            <MiniAccordion heading="Category" isVisible={true}>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={(category) =>
                  handleSelectChange(category, setSelectedCategory, setCategory)
                }
                isSearchable={true}
                placeholder="Select an option"
              />
            </MiniAccordion>
          )}

          {/* FILTER BY LOCATION */}
          {event_settings_page?.show_location_filter === true && (
            <MiniAccordion heading="Location" isVisible={true}>
              <>
                <RadioGroup
                  options={eventTypes}
                  onChange={(option) => handleRadioChange(option, setEventType)}
                  Radio={EventTypeRadio}
                />
                <p id="user-country" className="text-muted mt-3">
                  Events in Uganda <span className="flag flag-ug ml-2" />
                </p>
                <div id="filter-country-container" className="mt-4">
                  <label htmlFor="country">Country</label>
                  <Select
                    options={countriesOptions}
                    value={selectedCountry}
                    onChange={(country) =>
                      handleSelectChange(
                        country,
                        setSelectedCountry,
                        setCountry
                      )
                    }
                    isSearchable={true}
                    placeholder="Select Country"
                  />
                </div>
              </>
            </MiniAccordion>
          )}

          {/* FILTER BY DATE */}
          {event_settings_page?.show_date_filter === true && (
            <MiniAccordion heading="Date" isVisible={true}>
              <RadioGroup
                options={dateOptions}
                onChange={handleDateChange}
                Radio={DateRadio}
              />
            </MiniAccordion>
          )}

          {/* FILTER BY TICKET PRICE */}
          {event_settings_page?.show_ticket_price_filter === true && (
            <MiniAccordion heading="Ticket Price" isVisible={true}>
              <div
                className="form-check"
                style={{ marginBottom: "20px", width: "100%" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={freeEventsOnly}
                  onChange={(e) => {
                    setFreeEventsOnly(e.target.checked);
                    if (e.target.checked) {
                      setMinPrice(0);
                      setMaxPrice(0);
                    }
                  }}
                />
                <label className="form-check-label">Free Events Only</label>
              </div>
              {!freeEventsOnly && (
                <>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={(valueText) => `${valueText}UGX`}
                    color="warning"
                  />
                  <div className="row ranger-slider-inputs">
                    {[minPrice, maxPrice].map((price, index) => {
                      const label = index === 0 ? "Min" : "Max";
                      return (
                        <div className="col-12 col-sm-6 mr-0 pr-1 pl-1">
                          <label htmlFor={`price ${label}`}>{label}</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">UGX</span>
                            </div>
                            <input
                              id="pricemin"
                              name="pricemin"
                              type="text"
                              className="form-control range-slider-min-input"
                              placeholder="Min"
                              defaultValue={price}
                              value={price}
                              disabled
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </MiniAccordion>
          )}

          {/* AUDIENCE */}
          {event_settings_page?.show_audience_filter === true && (
            <MiniAccordion heading="Audience" isVisible={true}>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <RadioGroup
                  onChange={(option) =>
                    handleRadioChange(option, setAudienceType)
                  }
                  options={audienceOptions}
                  Radio={AudienceComponent}
                />
              </div>
            </MiniAccordion>
          )}
        </div>
      </div>
      <article className="card-group-item d-none">
        <div className="card-body p-0">
          <article
            className="box text-white"
            style={{
              backgroundColor: layout_page?.primary_color,
            }}>
            <figure className="itemside mb-4">
              <div className="aside align-self-center">
                <span className="icon-wrap icon-sm round bg-white">
                  <i
                    className="fas fa-mail-bulk "
                    style={{
                      color: layout_page?.primary_color,
                    }}
                  />
                </span>
              </div>
              <figcaption className="text-wrap">
                <h5 className="title text-white">
                  Subscribe to our newsletter
                </h5>
              </figcaption>
            </figure>
            <div className="form-row">
              <div className="form-group col mb-0">
                <input
                  id="newsletter-email"
                  type="email"
                  className="newsletter-email form-control rounded-0 border-0 line-height-1"
                  placeholder="Email address"
                  required={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col mb-0">
                <button
                  id="newsletter-subscribe"
                  data-target="/en/newsletter-subscribe"
                  type="button"
                  className="btn btn-dark justify-content-center w-100 rounded-0">
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </article>
        </div>
      </article>{" "}
    </aside>
  );
}

export default SideNav;
