import { MdClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/setting_store";

export default function BrowseEventsHeader({
  eventsToRender,
  clearQuery,
  isFiltering,
  showingCalendar,
  setShowingCalendar,
  showingMap,
  setShowingMap,
}) {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div className="box shadow-none bg-gray mb-4">
      <div className="row">
        <div className="col-sm-12 col-lg-6 text-center text-lg-left mb-3 mb-lg-0 d-flex">
          <p className="center-lg-y text-muted">
            {eventsToRender?.length} event(s) found
            {isFiltering && (
              <Link
                onClick={() => clearQuery()}
                className="btn text-white text-white ml-4"
                style={{ backgroundColor: layout_page?.primary_color }}
                title="clear search filters"
              >
                <MdClear color="#fff" size={20} /> Clear Filters
              </Link>
            )}
          </p>
        </div>
        <div className="col-sm-12 col-lg-6 text-center text-lg-right d-flex justify-content-end">
          <button
            className="btn text-white has-tooltip "
            style={{ backgroundColor: layout_page?.primary_color }}
            data-toggle="collapse"
            title="Show events on map"
            aria-expanded="false"
            aria-controls="events-map"
            onClick={() => {
              setShowingMap(!showingMap);
            }}
          >
            <i className="fas fa-map-marked-alt fa-fw" />
          </button>
          <button
            className="btn text-white has-tooltip mx-1"
            style={{ backgroundColor: layout_page?.primary_color }}
            data-toggle="collapse"
            title="Show events calendar"
            aria-expanded="false"
            aria-controls="events-calendar"
            onClick={() => {
              setShowingCalendar(!showingCalendar);
            }}
          >
            <i className="far fa-calendar fa-fw" />
          </button>
          <a
            href="http://eventic.mtrsolution.com/rss"
            className="btn text-white"
            style={{ backgroundColor: layout_page?.primary_color }}
            data-toggle="tooltip"
            title="Events RSS feed"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-rss fa-fw" />
          </a>
        </div>
      </div>
    </div>
  );
}
