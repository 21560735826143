/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { getTimeSinceAdded } from "../../services/blogs";
import { Link, useParams } from "react-router-dom";
import DisqusComponent from "./disqus";

import { useBlogs } from "../../store/blog_store";
import { useSettings } from "../../store/setting_store";

export default function BlogDetails() {
  //fetching all the data from storage
  const { blogpostId } = useParams();
  const getBlogById = useBlogs((state) => state.getBlogById);
  const updateBlogs = useBlogs((state) => state.updateBlogs);

  const getVisibleBlogs = useBlogs((state) => state.getVisibleBlogs);
  const layout_page = useSettings((state) => state.layout_page);

  const getVisibleBlogCategories = useBlogs(
    (state) => state.getVisibleBlogCategories
  );
  const categories = useBlogs((state) => state.categories);
  // const [categories, setCategories] = useState([]);

  const getVisibleTags = useBlogs((state) => state.getTagsOfVisibleBlogs);
  const tags = useBlogs((state) => state.visibleTags);
  const blogs = useBlogs((state) => state.blogposts);

  //HANDLING COLAPSE AND EXPAND IN SIDEBAR
  const [isOpenSearchblog, setIsOpenSearchblog] = useState(true);
  const toggleSearchblog = () => setIsOpenSearchblog(!isOpenSearchblog);

  const [isOpenCategories, setIsOpenCategories] = useState(true);
  const toggleCategories = () => setIsOpenCategories(!isOpenCategories);

  const [isOpenPopular, setIsOpenPopular] = useState(true);
  const togglePopular = () => setIsOpenPopular(!isOpenPopular);

  const [isOpenTags, setIsOpenTags] = useState(true);
  const toggleTags = () => setIsOpenTags(!isOpenTags);

  // const [tags, setTags] = useState([])

  // RETRIEVING A BLOG BY ITS ID
  const [blogpost, setBlogpost] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const data = await getBlogById(blogpostId);
      setBlogpost(data);
    }

    fetchData();
  }, [blogpostId]);

  //updating the blog views
  useEffect(() => {
    const updateViews = async () => {
      // Getting the views
      const response = await getBlogById(blogpostId);
      // Make sure response.views is a valid number before adding 1
      const viewsToAdd = parseInt(response.views) || 0;
      updateBlogs(blogpostId, {
        views: viewsToAdd + 1,
      });
    };

    updateViews();
  }, [blogs?.length]);

  //RETRIEVING BLOGS TO BE DISPLAYED IN THE SIDEBAR
  const [blogposts, setBlogposts] = useState([]);
  useEffect(() => {
    async function fetchTags() {
      //get all tags
      await getVisibleTags();
    }
    //getting categories
    const visibleCategories = async () => {
      await getVisibleBlogCategories();
    };
    //getting visible blogs
    const visibleBlog = async () => {
      const b = await getVisibleBlogs();
      setBlogposts(b);
    };

    visibleCategories();
    visibleBlog();
    fetchTags();
  }, []);

  return (
    <div>
      <Header currentPage={"blogs"} />

      {/* BREADCRUMP */}
      {blogpost ? (
        <>
          <section className="section-pagetop bg-gray">
            <div className="container clearfix">
              <h4
                className="title-page dark b float-xl-left mb-0"
                key={`${blogpost.id}-bigbreadcrump`}
              >
                {blogpost.name}
              </h4>
              <nav className="float-xl-right mt-2 mt-xl-0">
                <ol className="breadcrumb text-white">
                  <li className="breadcrumb-item">
                    <a href="/en" className="dark">
                      <i className="fas fa-home" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/en/blog" className="dark">
                      Blog
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    key={`${blogpost.id}-smallbreadcrump`}
                  >
                    {blogpost.name}
                  </li>
                </ol>
              </nav>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}

      {/* BLOG DETAILS SIDE BAR */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 order-1 order-lg-0">
              <div className="card card-filter">
                {/* SEARCH BLOG AREA IN SIDEBAR */}
                <article className="card-group-item">
                  <header className="card-header">
                    <Link
                      className="d-flex justify-content-between align-items-center"
                      onClick={toggleSearchblog}
                      aria-expanded={isOpenSearchblog}
                      href="#filterkeyword"
                      style={{ color: layout_page?.primary_color }}
                      role="button"
                      data-toggle="collapse"
                      aria-controls="filterkeyword"
                    >
                      <h6 className="title">Search blog</h6>
                      <i
                        className={`icon-action fa ${
                          isOpenSearchblog ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      />
                    </Link>
                  </header>
                  <div
                    className={`collapse ${isOpenSearchblog ? "show" : ""}`}
                    id="filterkeyword"
                  >
                    <div className="card-body">
                      <form className="pb-3" action="/en/blog">
                        <input
                          className="form-control mb-3"
                          placeholder="Keyword"
                          name="keyword"
                          type="text"
                          defaultValue
                        />
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-block"
                        >
                          <i className="fas fa-search" /> Search
                        </button>
                      </form>
                    </div>
                  </div>
                </article>

                {/* DISPLAYING AND FILTERING CATEGORIES */}
                <article className="card-group-item">
                  <header className="card-header">
                    <Link
                      onClick={toggleCategories}
                      aria-expanded={isOpenCategories}
                      style={{ color: layout_page?.primary_color }}
                      role="button"
                      data-toggle="collapse"
                      aria-controls="filtereventtype"
                    >
                      <i
                        className={`icon-action fa ${
                          isOpenCategories ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      />
                      <h6 className="title">Categories</h6>
                    </Link>
                  </header>
                  <div
                    className={`filter-content collapse ${
                      isOpenCategories ? "show" : ""
                    }`}
                    id="filtereventtype"
                  >
                    <div className="card-body">
                      <ul className="list list-unstyled">
                        {/* RETURNED CATEGORIES */}
                        {categories.map((category) => (
                          <li className="mb-2" key={categories?.id}>
                            <Link
                              to=""
                              className="font-weight-semibold"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="fas fa-angle-right ml-1 mr-1 pr-2" />
                              {category?.category}
                              <span className="float-right form-check-label">
                                <span className="badge badge-primary">1</span>
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </article>

                {/* DISPLAYING POPULAR BLOGS */}
                <article className="card-group-item">
                  <header className="card-header">
                    <a
                      onClick={togglePopular}
                      style={{ color: layout_page?.primary_color }}
                      aria-expanded={isOpenPopular}
                      href="#collapsePopular"
                      role="button"
                      data-toggle="collapse"
                      aria-controls="collapsePopular"
                    >
                      <i
                        className={`icon-action fa ${
                          isOpenPopular ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      />
                      <h6 className="title">Popular</h6>
                    </a>
                  </header>
                  <div
                    className={`filter-content collapse ${
                      isOpenPopular ? "show" : ""
                    }`}
                    id="collapsePopular"
                  >
                    <div className="card-body">
                      {/* RETURNED BLOGPOSTS */}
                      {blogposts.map((blogpost) => (
                        <article
                          key={blogposts?.id}
                          className="row align-items-center mb-3"
                        >
                          <div className="col-4 pr-0">
                            <a href="/en/blog-article/5-ways-event-managers-can-increase-event-registration">
                              <img
                                src={blogpost?.main_image}
                                className="img-lazy-load img-fluid hover-effect-2"
                                alt="5 Ways Event Managers Can Increase Event Registration"
                              />
                            </a>
                          </div>
                          <div className="col-8">
                            <small className="text-muted">
                              {blogpost?.category?.label}
                            </small>
                            <h6 className="mb-0">
                              <a
                                style={{ color: layout_page?.primary_color }}
                                href="/en/blog-article/5-ways-event-managers-can-increase-event-registration"
                                className="text-1"
                              >
                                {blogpost?.name}
                              </a>
                            </h6>
                          </div>
                        </article>
                      ))}
                    </div>
                  </div>
                </article>

                {/* TAGS SECTION */}
                <article className="card-group-item">
                  <header className="card-header">
                    <a
                      style={{ color: layout_page?.primary_color }}
                      onClick={toggleTags}
                      aria-expanded={isOpenTags}
                      href="#collapsetags"
                      role="button"
                      data-toggle="collapse"
                      aria-controls="collapsetags"
                    >
                      <i
                        className={`icon-action fa ${
                          isOpenTags ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      />
                      <h6 className="title">Tags</h6>
                    </a>
                  </header>
                  <div
                    className={`filter-content collapse ${
                      isOpenTags ? "show" : ""
                    }`}
                    id="collapsetags"
                  >
                    <div className="card-body">
                      {/* DISPLAYING THE TAGS */}
                      {tags.map((tag) => (
                        <a
                          key={tag}
                          href="/en/blog?keyword=%D8%AF%D9%84%D9%8A%D9%84"
                          className="btn btn-sm btn-default mr-2 mb-2 mt-2"
                        >
                          {tag}
                        </a>
                      ))}
                    </div>
                  </div>
                </article>

                {/* SUBSCRIBE TO NEWS LETTER */}
                <article className="card-group-item d-none">
                  <div className="card-body p-0">
                    <article
                      className="box text-white"
                      style={{ backgroundColor: layout_page?.primary_color }}
                    >
                      <figure className="itemside mb-4">
                        <div className="aside align-self-center">
                          <span className="icon-wrap icon-sm round bg-white">
                            <i
                              className="fas fa-mail-bulk"
                              style={{ color: layout_page?.primary_color }}
                            />
                          </span>
                        </div>
                        <figcaption className="text-wrap">
                          <h5 className="title text-white">
                            Subscribe to our newsletter
                          </h5>
                        </figcaption>
                      </figure>
                      <div className="form-row">
                        <div className="form-group col mb-0">
                          <input
                            id="newsletter-email"
                            type="email"
                            className="newsletter-email form-control rounded-0 border-0 line-height-1"
                            placeholder="Email address"
                            required="true"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col mb-0">
                          <button
                            id="newsletter-subscribe"
                            data-target="/en/newsletter-subscribe"
                            type="button"
                            className="btn btn-dark justify-content-center w-100 rounded-0"
                          >
                            SUBSCRIBE
                          </button>
                        </div>
                      </div>
                    </article>
                  </div>
                </article>
              </div>
            </aside>

            {blogpost ? (
              <div className="col-lg-9 order-0 order-lg-1">
                <div className="card box">
                  {/* BLOG POST IMAGE */}
                  <img
                    className="card-img-top img-lazy-load b-loaded"
                    src={blogpost?.main_image}
                    alt={blogpost?.name}
                    key={`${blogpost?.id}-main_image`}
                  />

                  {/* MAIN BLOG TITLE */}
                  <div className="card-body">
                    <header
                      className="section-heading mb-1"
                      key={`${blogpost?.id}-header`}
                    >
                      <h1 className="title-section">{blogpost?.name}</h1>
                    </header>

                    {/* LOWER BLOG DETAILS */}
                    <p className="mb-4">
                      <small
                        className="text-muted mr-2"
                        key={`${blogpost?.id}-category`}
                      >
                        <a
                          href="/en/blog/tips"
                          style={{ color: layout_page?.primary_color }}
                        >
                          <i className="fas fa-sitemap" />
                          &nbsp;{blogpost?.category?.label}
                        </a>
                      </small>
                      <small
                        className="text-muted mr-2"
                        key={`${blogpost?.id}-addedwhen`}
                      >
                        <i className="far fa-clock" />
                        &nbsp;{getTimeSinceAdded(blogpost?.added_at)}
                      </small>
                      <small
                        className="text-muted"
                        key={`${blogpost?.id}-time`}
                      >
                        <i className="fas fa-book-reader" />
                        &nbsp;{blogpost?.read_time} min read
                      </small>
                    </p>

                    {/* DISPLAYING THE HTML FOR THE MAIN CONTENT */}
                    <article
                      key={`${blogpost?.id}-html`}
                      dangerouslySetInnerHTML={{
                        __html: blogpost?.description,
                      }}
                    />

                    {/* HORIZONTAL LINE */}
                    <hr />

                    {/* BUTTON FILTERS */}
                    <div className="row">
                      <div className="col-12 col-lg-6 text-center text-lg-left">
                        <a
                          href="/en/blog?keyword=Corporate"
                          className="btn btn-sm btn-default mr-3 mb-3 mt-3"
                        >
                          Corporate
                        </a>

                        <a
                          href="/en/blog?keyword=%20Best%20Practices"
                          className="btn btn-sm btn-default mr-3 mb-3 mt-3"
                        >
                          Registration
                        </a>

                        <a
                          href="/en/blog?keyword=%20Best%20Practices"
                          className="btn btn-sm btn-default mr-3 mb-3 mt-3"
                        >
                          Tips
                        </a>
                      </div>

                      {/* intergrating disqus into the blogpost */}
                      <DisqusComponent />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      {/* END OF PAGE */}

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
