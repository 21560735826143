// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myTextField {
  background-color: #f1f3f7 !important;
  width: 100% !important;
  background-clip: padding-box !important;
  border: none !important;
  border-radius: 0.5rem !important;
  color: #8898aa !important;
  padding: 0 !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
  margin-bottom: 10px !important;
  margin-top: 15px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  /* margin-top: 10px !important; */
}

.header-search-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
}

.header-search-wrapper button {
  margin-left: -50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/search_bar/search_bar.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,uCAAuC;EACvC,uBAAuB;EACvB,gCAAgC;EAChC,yBAAyB;EACzB,qBAAqB;EACrB,sEAAsE;EACtE,8BAA8B;EAC9B,2BAA2B;AAC7B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".myTextField {\r\n  background-color: #f1f3f7 !important;\r\n  width: 100% !important;\r\n  background-clip: padding-box !important;\r\n  border: none !important;\r\n  border-radius: 0.5rem !important;\r\n  color: #8898aa !important;\r\n  padding: 0 !important;\r\n  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;\r\n  margin-bottom: 10px !important;\r\n  margin-top: 15px !important;\r\n}\r\n\r\n.css-i4bv87-MuiSvgIcon-root {\r\n  /* margin-top: 10px !important; */\r\n}\r\n\r\n.header-search-wrapper {\r\n  display: flex !important;\r\n  align-items: center !important;\r\n  justify-content: center !important;\r\n  height: 100% !important;\r\n}\r\n\r\n.header-search-wrapper button {\r\n  margin-left: -50px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
