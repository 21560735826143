import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import Footer from "../../components/footer/footer";
import {
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../services/helpers";
import { auth } from "../../Firebase";
import { useSettings } from "../../store/setting_store";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import Header from "../../components/header/header";

function CheckEmail() {
  const navigate = useNavigate();

  const location = useLocation();
  const { userData } = location.state;

  const layout_page = useSettings((state) => state.layout_page);
  const resendEmailLink = userStore((state) => state.resendEmailLink);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      try {
        if (user) {
          console.log("User is signed in:", user);
          if (user.emailVerified === true) {
            // Email is verified, set loading to false
            setLoading(false);
            // Redirect to the dashboard when email is verified
            const user_type = userData?.user_type;
            if (user_type === "attendee") {
              navigate("/dashboard");
            } else if (user_type === "organizer") {
              navigate("/dashboard_organizer");
            } else if (user_type === "admin") {
              navigate("/admin_dashboard");
            } else {
              displayWarningToast("You have no dashboard");
              console.log("no such user type");
            }
            displaySuccessToast("Email Verified");
          } else {
            console.log("Email not verified");
            setLoading(false); // Mark the loading as false so that the message appears
          }
        } else {
          console.log("User is signed out");
          setLoading(false);
        }
      } catch (error) {
        console.log("Error during email verification check:", error);
        setLoading(false); // Handle the error and set loading as false
      }
    });
    // Cleanup  when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [navigate, userData?.user_type]);

  //resending the email link
  const resendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = auth.currentUser;
      await resendEmailLink(user);
      displaySuccessToast("Another email has been sent");
    } catch (error) {
      displayInfoToast(
        "You have recieved email more than once. Please try again later."
      );
    }
    setLoading(false);
  };

  //go to dashboard

  const goToDashboard = () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      window.location.reload();
      setLoading(true);

      if (user.emailVerified === true) {
        // Email is verified, set loading to false
        setLoading(false);

        // Redirect to the dashboard when email is verified
        const user_type = userData?.user_type;
        if (user_type === "attendee") {
          navigate("/dashboard");
        } else if (user_type === "organizer") {
          navigate("/dashboard_organizer");
        } else if (user_type === "admin") {
          navigate("/admin_dashboard");
        } else {
          displayWarningToast("You have no dashboard");
          console.log("No such user type");
        }
        // displaySuccessToast("Email Verified");
      } else {
        displayWarningToast("Email not verified");
        setLoading(false);
      }
    } catch (error) {
      console.log("some thing is wrong with redirectiing to dashboard", error);
      displayErrorToast("some thing is wrong with redirectiing to dashboard");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="">
      <Header />
      <section className=" padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  <p className="text-center">
                    <i
                      className="fas fa-envelope-open-text fa-8x text-success"
                      style={{ fontSize: 50 }}
                    />
                  </p>
                  <h4 className="text-center">
                    An email has been sent to{" "}
                    <span style={{ color: layout_page?.primary_color }}>
                      {userData?.email}
                    </span>
                    . It contains an activation link you must click to activate
                    your account.{" "}
                  </h4>
                  <h5 className="text-center">
                    After activating email,click{" "}
                    <span className="text-uppercase text-danger font-weight-bold">
                      Go to Dashboard button
                    </span>{" "}
                    to proceed to your dashboard
                  </h5>
                </div>
                <div className="d-grid gap-2 d-md-flex">
                  <button
                    className=" btn btn-primary w-100 col-md-4 center"
                    onClick={(e) => resendEmail(e)}
                  >
                    Resend Email
                  </button>

                  <button
                    className=" btn btn-success w-100 col-md-4 center"
                    onClick={(e) => goToDashboard(e)}
                  >
                    Go to Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default CheckEmail;
