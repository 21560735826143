import { useNavigate } from "react-router-dom";
import "./error_page.css";

export default function ErrorPage() {
  //   const navigate = useNavigate();

  
	// function goBack(){
	// 	navigate(-1);
	// }
 

  return (
    <>
      <div className="error-page">
        <link
          href="https://fonts.googleapis.com/css?family=Encode+Sans+Semi+Condensed:100,200,300,400"
          rel="stylesheet"
        />
        <h1>500</h1>

        {/* UNEXPECTED ERROR */}
        <h2>
          Unexpected Error <b>:(</b>
        </h2>
        {/* GEARS */}
        <div className="gears">
          <div className="gear one">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="gear two">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="gear three">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </div>

        {/* GO BACK BUTTON */}
{/*         
        <button
          type="button"
          onClick={goBack}
          className="btn btn-outline-dark mt-3 ml-3"
        >
          <i className="fas fa-undo m" /> Go back
        </button> */}

      </div>
    </>
  );
}
