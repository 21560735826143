import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import app from "../Firebase";
import {
  convertTimestampToDateTime,
  displaySuccessToast,
} from "../services/helpers";

// INITIALIZING FIRESTORE
const db = getFirestore(app);

const promoCodesStore = (set, get) => ({
  promoCodes: [],
  organizerPromocodes: [],
  version: 0,

  // METHOD TO UPDATE PROMOCODE TIMES USED
  addUserToPromocodeBeneficiaries: async (email, promocode_id) => {
    console.log("THE USER EMAIL: ", email);
    console.log("THE PROMOCODE ID: ", promocode_id);
    console.log("ADDING USER TO BENEFICIARIES");
    try {
      const promoDoc = doc(db, "promocodes", promocode_id);
      const querySnapshot = await getDoc(promoDoc);
      const data = querySnapshot.data();

      await updateDoc(promoDoc, {
        beneficiaries: [...data.beneficiaries, email],
      });

      // FETCHING THE PROMOCODES AGAIN
      get().getPromoCodes();
      // console.log("USER ADDED TO BENEFICIARIES");
      return true;
    } catch (error) {
      console.log(
        "Something Went wrong updating promo code beneficiaries",
        error
      );
      return false;
    }
  },

  // METHOD TO UPDATE PROMOCODES
  updatePromoCode: async (promocode_id, data) => {
    try {
      const promoDoc = doc(db, "promocodes", promocode_id);
      await updateDoc(promoDoc, data);
      set((state) => ({ version: state.version + 1 }));
      set((state) => ({
        promoCodes: state.promoCodes.map((promoCode) =>
          promoCode.id === promocode_id ? { ...promoCode, ...data } : promoCode
        ),
      }));
      get().getPromoCodes();
      return true;
    } catch (error) {
      console.log("Something Went wrong updating promo code", error);
      return false;
    }
  },

  // GETTING EVENT CATEGORIES
  getPromoCodes: async () => {
    try {
      const promos = [];
      const userDoc = collection(db, "promocodes");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        promos.push(doc.data());
      });
      set((state) => ({ promoCodes: promos }));
      return promos;
    } catch (error) {
      console.log("Something Went wrong getting promo codes", error);
      return null;
    }
  },

  // GETTING EVENT CATEGORIES
  getOrganizerPromocodes: async (organizer_id) => {
    try {
      const promos = await get().promoCodes.filter(
        (promo) => promo.organizerId === organizer_id
      );
      console.log("PROMOS", promos.length);
      set((state) => ({ organizerPromocodes: promos }));
      return promos;
    } catch (error) {
      console.log("Something Went wrong getting organizer promo codes", error);
      return null;
    }
  },

  // GET SPECIFIC PROMOCODE FROM STATE
  getPromoCode: async (promocode) => {
    try {
      const promo = get().promoCodes.find((promo) => promo.code === promocode);
      return promo !== undefined ? promo : null;
    } catch (error) {
      console.log("Something Went wrong getting promo code", error);
      return null;
    }
  },

  // VALIDATING THE PROMOCODE
  validatePromoCode: async ({ promocode, event_id, ticket_id, email }) => {
    try {
      const response = await get().getPromoCode(promocode);

      // if the promocode does not exist
      if (response === null) {
        return null;
      }

      // if the promocode exists
      else {
        console.log("PROMOCODE EXISTS");

        const promoDoc = doc(db, "promocodes", response?.id);

        const querySnapshot = await getDoc(promoDoc);

        if (!querySnapshot.data()) {
          console.log("PROMOCODE DOES NOT EXIST IN THE DATABASE");
          return null;
        }

        const data = querySnapshot.data();

        // CHECKING IF THE USER HAS ALREADY USED THE PROMOCODE
        if (email) {
          if (data.beneficiaries.includes(email)) {
            console.log("USER HAS ALREADY USED THE PROMOCODE");
            return null;
          }
        }

        // CHECKING IF THE PROMOCODE IS ACTIVE
        if (!data.isActive) {
          console.log("PROMOCODE IS NOT ACTIVE");
          return null;
        }

        try {
          // CHECKING IF THE PROMOCODE START DATE HAS REACHED
          if (convertTimestampToDateTime(data.startData) > new Date()) {
            console.log("PROMOCODE START DATE HAS NOT REACHED");
            return null;
          }

          // CHECKING IF THE PROMOCODE END DATE HAS REACHED
          if (convertTimestampToDateTime(data.endDate) < new Date()) {
            console.log("PROMOCODE END DATE HAS REACHED");
            return null;
          }
        } catch (error) {
          console.log("SOMETHING WENT WRONG CHECKING PROMOCODE DATES", error);
        }

        // CHECKING IF THE PROMOCODE IS FOR THIS EVENT
        if (data.eventId !== event_id) {
          console.log("PROMOCODE IS NOT FOR THIS EVENT");
          return null;
        }

        // CHECKING IF THE PROMOCODE IS FOR THIS TICKET
        if (!data.ticketIds.includes(ticket_id)) {
          console.log("PROMOCODE IS NOT FOR THIS TICKET");
          return null;
        }

        // CHECKING IF THE PROMOCODE QUANTITY HAS BEEN REACHED
        let promoQuantity = parseInt(data.totalForPromoRun?.toString());
        if (data.beneficiaries.length >= promoQuantity) {
          console.log("PROMOCODE QUANTITY HAS BEEN REACHED");
          return null;
        }

        console.log("THE PROMO CODE IS VALID");
        return querySnapshot.data();
      }
    } catch (error) {
      console.log("Something Went wrong validating promo code", error);
      return null;
    }
  },

  // ADDING A NEW PROMO CODE
  addPromoCode: async (promoCode) => {
    try {
      const promoCollection = collection(db, "promocodes");
      const response = await addDoc(promoCollection, promoCode);

      set((state) => ({
        promoCodes: [...state.promoCodes, { ...promoCode, id: response?.id }],
      }));

      // UPDATE PROMOCODE ID
      const promoDoc = doc(db, "promocodes", response?.id);
      await updateDoc(promoDoc, { id: response?.id });

      return true;
    } catch (error) {
      console.error("Error adding promo code: ", error);
      return false;
    }
  },

  // DELETING A PROMO CODE
  deletePromoCode: async (promocode_id) => {
    try {
      console.log("DELETING PROMO CODE WITH ID: ", promocode_id);
      const promoDoc = doc(db, "promocodes", promocode_id);
      await deleteDoc(promoDoc);
      console.log("promocode deleted");
      //update
      const updatedPromos = get().promoCodes.filter(
        (promoCode) => promoCode?.id !== promocode_id
      );
      set({ promoCodes: updatedPromos });
      return true;
    } catch (error) {
      console.error("Error deleting promo code: ", error);
      return false;
    }
  },
});

// CREATING AND EXPORTING THE EVENTS STORE
export const usePromoCodes = create(
  persist(promoCodesStore, {
    name: "promocodes-store",
  })
);
usePromoCodes.getState().getPromoCodes();
usePromoCodes.getState().getOrganizerPromocodes();
