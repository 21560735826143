import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, MenuItem, MenuList } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { displayErrorToast } from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { displayWarningToast } from "./../../../services/helpers";
import { auth } from "../../../Firebase";
import SignupDropdown from "./signup_dropdown";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function UserProfileDropdown() {
  const navigate = useNavigate();

  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAttendee = userStore((state) => state.isAttendee);
  const isAdmin = userStore((state) => state.isAdmin);
  const logout = userStore((state) => state.logout);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [organizerDetails, setOrganizerDetails] = useState({});

  // METHOD TO GET ORGANIZER DETAILS
  async function handleGetOganizerDetails() {
    if (isOrganizer) {
      const repsonse = await getOrganizerDetails(user?.user_id);
      setOrganizerDetails(repsonse);
    }
  }
  useEffect(() => {
    handleGetOganizerDetails();
  }, []);

  // HANDLE CLICK
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // FUNCTION TO HANDLE SIGNOUT
  const handleSignout = async (e) => {
    e.preventDefault();
    const response = await logout();
    if (response) {
      setAnchorEl(null);
      navigate("/sign_in");
    } else {
      displayErrorToast("Something went wrong. Please try again later.");
    }
  };

  const open = Boolean(anchorEl);

  // IF NOT ORGANIZER, NOT USER AND NOT ADMIN, LOGOUT
  async function handleUserLoginStatus() {
    if (!isOrganizer && !isAttendee && !isAdmin) {
      const response = await logout();
      if (response === true) {
        navigate("/sign_in");
        displayWarningToast("Session Expired, Please Login Again");
      } else {
        console.log("SOMETHING WENT WRONG SIGNING YOU OUT");
      }
    }
  }
  const signOut = async () => {
    await logout();
    navigate("/sign_in");
  };

  // USE EFFECT
  useEffect(() => {
    handleUserLoginStatus();
  }, []);

  return (
    <div>
      {/* USER PROFILE  */}

      <React.Fragment>
        <div
          className="widget-header dropdown"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {auth.currentUser?.emailVerified ? (
              <>
                <span
                  className="avatar"
                  style={{
                    background: `url("${
                      user?.profile_picture ??
                      organizerDetails?.organizer_logo ??
                      "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                    }")`,
                  }}
                ></span>

                <div className="text-wrap" style={{ marginLeft: "10px" }}>
                  <span>{user?.firstname}</span>
                  <i className="fas fa-caret-down" />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <div className="widget-header">
                    <Link to="/sign_in">
                      <div className="icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none">
                        <i className="fas fa-sign-in-alt text-light fa-sm fa-fw" />
                      </div>
                      <span
                        className="d-none d-lg-block"
                        onClick={(e) => signOut(e)}
                      >
                        Sign in
                      </span>
                    </Link>
                  </div>

                  {/* SIGNUP BUTTONS */}
                  <SignupDropdown />
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
      {auth.currentUser?.emailVerified && (
        <Popover
          id="dropdown-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList>
            <MenuItem
              component={Link}
              to={
                isAdmin
                  ? "/admin_dashboard"
                  : isOrganizer
                  ? "/dashboard_organizer"
                  : "/dashboard"
              }
              onClick={handleClose}
            >
              Dashboard
            </MenuItem>

            <MenuItem onClick={(e) => handleSignout(e)}>Sign Out</MenuItem>
          </MenuList>
        </Popover>
      )}
    </div>
  );
}
