import { convertTimestampToDateTime } from "../services/helpers";

function filterEventsByCategory(events, category) {
  if (!category) return events;
  return events.filter((event) => event?.category?.value === category);
}

function filterEventsByDate(events, { startDate, endDate }) {
  if (!startDate) return events;
  return events?.filter((event) => {
    return event?.event_dates?.find((date) => {
      const eventStartDate = convertTimestampToDateTime(date?.start_date);
      return eventStartDate >= startDate && eventStartDate < endDate;
    });
  });
}

function filterEventsBySearchText(events, organizers, searchText) {
  if (!searchText) return events;
  const lowercaseQuery = searchText?.toLowerCase().trim();

  const _organizers = organizers
    ?.filter((organizer) => {
      const lowercaseOrganizerName = organizer?.organizer_name?.toLowerCase();
      return lowercaseOrganizerName?.includes(lowercaseQuery);
    })
    .map((item) => item.id);

  return events.filter((event) => {
    const lowercaseName = event?.name?.toLowerCase();
    const lowercaseDescription = event?.description?.toLowerCase();
    const lowercaseVenue =
      event?.event_dates?.map((date) => date?.venue?.toLowerCase()) ?? [];
    return (
      lowercaseName?.includes(lowercaseQuery) ||
      lowercaseDescription?.includes(lowercaseQuery) ||
      lowercaseVenue?.includes(lowercaseQuery) ||
      _organizers.includes(event.organizer_id)
    );
  });
}

function filterEventsByCountry(events, country) {
  if (!country) return events;
  return events.filter(
    (event) => event?.country?.label?.toLowerCase() === country?.toLowerCase()
  );
}

function filterEventsByAudience(events, audienceType) {
  if (!audienceType) return events;
  return events.filter((event) => {
    const lowercaseAudiences =
      event?.audiences?.map((item) => String(item)?.toLowerCase()) ?? [];
    return lowercaseAudiences?.includes(audienceType?.toLowerCase());
  });
}

function filterEventsByOnlineStatus(events, onlineStatus) {}

function filterEventsByPrice(events, minPrice, maxPrice) {}

export function filterEvents(
  events,
  {
    organizers,
    searchText,
    category,
    startDate,
    endDate,
    country,
    audienceType,
  }
) {
  const filteredEventsBySearchText = filterEventsBySearchText(
    events,
    organizers,
    searchText
  );

  const filteredEventsByDate = filterEventsByDate(filteredEventsBySearchText, {
    startDate,
    endDate,
  });

  const filteredEventsByCategory = filterEventsByCategory(
    filteredEventsByDate,
    category
  );

  const filteredEventsByCountry = filterEventsByCountry(
    filteredEventsByCategory,
    country
  );

  const filteredEventsByAudience = filterEventsByAudience(
    filteredEventsByCountry,
    audienceType
  );

  return filteredEventsByAudience;
}

export function filteredEventsByCategory(events, { category }) {
  const filteredEventsByCategory = filterEventsByCategory(events, category);
  return filteredEventsByCategory;
}
