import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import TagsInputField from "../../../components/tags_input_field/tags_input";
import TextEditor from "../../../components/text_editor/text_editor";
import Loader from "../../../components/loader/loader";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
import { displaySuccessToast } from "../../../services/helpers";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function AddNewBlogPost() {
  const getVisibleBlogCategories = useBlogs(
    (state) => state.getVisibleBlogCategories
  );
  const addBlogPost = useBlogs((state) => state.addBlogPost);
  const uploadBlogImage = useBlogs((state) => state.uploadBlogImage);
  const layout_page = useSettings((state) => state.layout_page);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [read_time, setReadTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // FETCHING THE BLOG CATEGORIES
    async function get_blog_categories() {
      const categories = [];
      const categoriesResponse = await getVisibleBlogCategories();

      for (let i = 0; i < categoriesResponse.length; i++) {
        categories.push({
          value: categoriesResponse[i].id,
          label: categoriesResponse[i].category,
        });
      }
      setCategoriesList(categories);
    }

    get_blog_categories();
  }, []);

  // HANDLING CATEGORIES
  const handleCategory = (e) => {
    setCategory(e);
  };

  // HANDLING UPLOADING THE BLOG POST
  const handleAddBlog = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      addBlogPost({
        name: name,
        category: category,
        main_image: image,
        tags: tags,
        description: description,
        read_time: read_time,
        added_at: Timestamp.fromDate(new Date()),
        status: "visible",
        updated_at: Timestamp.fromDate(new Date()),
      });
      navigate("/manage_blog_posts");
      displaySuccessToast("Blog poast added successfully");
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLING IMAGE CAPTURE
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setImage(file);

    const imageUrl = await uploadBlogImage(file);
    setImage(imageUrl);
  };

  // HANDLE CHANGE OF TAGS
  const handleChange = (e) => {
    setTags(e);
  };

  // HANDLE DESCRIPTION
  const handleDescription = (e) => {
    setDescription(e);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!category) {
      errors.category = "Please select category";
    }

    // VALIDATING NAME
    if (name.trim() === "") {
      errors.name = "Name is required";
    }

    // VALIDATING DESCRIPTION
    if (description.trim() === "") {
      errors.description = "Description is required";
    }

    // VALIDATING READ TIME
    if (read_time.trim() === "") {
      errors.read_time = "Read Time is required";
    }

    // VALIDATING IMAGE
    if (image.trim() === "") {
      errors.image = "Image is required";
    }

    return errors;
  }

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new blog post"
          page_link="Manage Blog Posts"
          link="manage_blog_posts"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_blog_posts"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="blog_post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleAddBlog}
                    >
                      <div id="blog_post">
                        {/* BLOGPOST CATEGORY */}
                        {errors.category && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.category}
                          </span>
                        )}

                        <div className="form-group">
                          <label
                            className="required"
                            htmlFor="blog_post_category"
                          >
                            Category
                          </label>
                          <small
                            id="blog_post_category_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Make sure to select right category to let the users
                            find it quickly
                          </small>
                          <SearchableDropdown
                            isMulti={false}
                            options={categoriesList}
                            onChange={handleCategory}
                            placeholder={"Select Blog Category"}
                            value={category}
                          />
                        </div>

                        {/* EVENT DETAILS */}
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Event details
                          </legend>
                          <div className="a2lix_translations">
                            <div className="a2lix_translationsFields tab-content">
                              <div
                                id="blog_post_translations_en_a2lix_translations-fields"
                                className="tab-pane show active "
                                role="tabpanel"
                              >
                                <div id="blog_post_translations_en">
                                  {errors.name && (
                                    <span className=" text-sm font-weight-bold text-danger">
                                      {errors.name}
                                    </span>
                                  )}

                                  {/* EVENT NAME */}
                                  <div className="form-group">
                                    <label
                                      htmlFor="blog_post_translations_en_name"
                                      className="required"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      id="blog_post_translations_en_name"
                                      name="blog_post[translations][en][name]"
                                      required={true}
                                      maxLength={80}
                                      pattern=".{1,}"
                                      className={
                                        errors.name
                                          ? " border border-danger form-control"
                                          : "form-control"
                                      }
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>

                                  {/* DESCRIPTION */}
                                  {errors.description && (
                                    <span className=" text-sm font-weight-bold text-danger">
                                      {errors.description}
                                    </span>
                                  )}

                                  <div className="form-group">
                                    <label
                                      htmlFor="blog_post_translations_en_content"
                                      className="required"
                                    >
                                      Description
                                    </label>
                                    <TextEditor
                                      value={description}
                                      onChange={(value) =>
                                        handleDescription(value)
                                      }
                                    />
                                  </div>

                                  {/* TAGS */}
                                  {errors.tags && (
                                    <span className=" text-sm font-weight-bold text-danger">
                                      {errors.tags}
                                    </span>
                                  )}

                                  <div className="form-group">
                                    <label
                                      htmlFor="blog_post_translations_en_tags"
                                      className="required"
                                    >
                                      Tags
                                    </label>
                                    <small
                                      id="blog_post_translations_en_tags_help"
                                      className="form-text text-muted mb-3"
                                    >
                                      <i className="fas fa-info-circle text-primary mr-1" />
                                      To help attendee find your event quickly,
                                      enter some keywords that identify your
                                      event (press Enter after each entry)
                                    </small>

                                    <TagsInputField
                                      tags={tags}
                                      handleChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {/* MAIN BLOGPOST IMAGE */}
                        {errors.image && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.image}
                          </span>
                        )}

                        <fieldset className="form-group">
                          <legend className="col-form-label">
                            Main blog post image
                          </legend>
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="blog_post_imageFile_file"
                                name="blog_post[imageFile][file]"
                                className="custom-file-input"
                                onChange={handleFileSelect}
                                required={true}
                              />
                              <label
                                htmlFor="blog_post_imageFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        </fieldset>

                        {/* READ TIME */}
                        {errors.read_time && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.read_time}
                          </span>
                        )}

                        <div className="form-group">
                          <label htmlFor="blog_post_readtime">
                            Read time in minutes
                          </label>
                          <input
                            type="text"
                            id="blog_post_readtime"
                            name="blog_post[readtime]"
                            className={
                              errors.read_time
                                ? " border border-danger touchspin-integer form-control"
                                : "form-control touchspin-integer"
                            }
                            data-min={1}
                            data-max={1000000}
                            onChange={(e) => setReadTime(e.target.value)}
                            required={true}
                          />
                        </div>

                        {/* SAVE BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="blog_post_save"
                            name="blog_post[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="blog_post__token"
                          name="blog_post[_token]"
                          defaultValue="0UpjGLTLP_hYdOXOynJLpuOVJrxJcIYLUxK_DkQAH8Y"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
