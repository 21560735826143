import { useEffect, useState } from "react";
import { FaMinusSquare, FaPlusSquare, FaRedoAlt } from "react-icons/fa";
import { useSettings } from "../../store/setting_store";
import { Rnd } from "react-rnd";
import Stage from "./../../assets/table_images/stage3.webp";
import { useVenues } from "../../store/venues_store";

const MappingVenueTables = ({
  venue_room,
  selectedDate,
  getSelectedTables,
}) => {
  const [zoomFactor, setZoomFactor] = useState(1);
  const [minZoomFactor, maxZoomFactor] = [0.7, 1.0];

  const [selectedTables, setSelectedTables] = useState([]);

  const layout_page = useSettings((state) => state.layout_page);
  const reservations = useVenues((state) => state.reservations);

  const getTableImage = (numberOfSeats, tableType, tableId) => {
    const isSelected = selectedTables[tableId];

    const imagePrefix = isSelected
      ? "grey"
      : tableType === "VIP"
      ? "blue"
      : "brown";

    return require(`./../../assets/table_images/${numberOfSeats}${imagePrefix}.svg`);
  };

  const handleZoomIn = (e) => {
    e.preventDefault();
    if (zoomFactor < maxZoomFactor) {
      setZoomFactor((prevZoom) => prevZoom + 0.1);
    }
  };

  const handleZoomOut = (e) => {
    e.preventDefault();
    if (zoomFactor > minZoomFactor) {
      setZoomFactor((prevZoom) => Math.max(0.1, prevZoom - 0.1));
    }
  };
  //resetting the zoom
  const handleResetZoom = (e) => {
    e.preventDefault();

    setZoomFactor(1);
  };

  //handling table click
  const handleTableClick = (tableId) => {
    setSelectedTables((prevSelectedTables) => {
      const isSelected = !prevSelectedTables[tableId];

      const updatedSelectedTables = { ...prevSelectedTables };

      //add or remove the tables from the array based on the selection state

      if (isSelected) {
        updatedSelectedTables[tableId] = venue_room?.tables.find(
          (table) => table.id === tableId
        );
      } else {
        delete updatedSelectedTables[tableId];
      }
      //taking selected tables to parent
      getSelectedTables(updatedSelectedTables);
      //return the updated state
      return updatedSelectedTables;
    });
  };

  //compares the year, month, and day of two date objects
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  return (
    <div className="">
      {" "}
      {/* ZOOM BUTTONS */}
      <div className=" d-flex gap-3 ">
        <FaPlusSquare
          color={layout_page?.primary_color}
          size={25}
          onClick={handleZoomIn}
        />
        <FaMinusSquare
          color={layout_page?.primary_color}
          size={25}
          onClick={handleZoomOut}
        />
        <FaRedoAlt
          color={layout_page?.primary_color}
          size={25}
          onClick={handleResetZoom}
        />
      </div>
      <div className="bg-gray p-3 categories-scroll-container m-5 ">
        {/* THE TABLES */}
        <div className="bg-white center p-3">
          <div
            className="container bg-gray p-2 border border-4 border-white"
            style={{
              backgroundImage: `url(${venue_room?.room_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              transform: `scale(${zoomFactor})`, // Apply the zoom factor
              transition: "transform 0.3s ease", // Add a smooth transition
              height: 600,
              width: 920,
            }}
          >
            {/* stage */}
            <Rnd
              // bounds="parent"
              disableDragging
              default={{
                x: (venue_room?.stage_position?.x || 0) * zoomFactor,
                y: (venue_room?.stage_position?.y || 0) * zoomFactor,
              }}
            >
              <img src={Stage} width={160} alt=" Stage Position" />
            </Rnd>
            {venue_room?.tables?.length > 0 &&
              venue_room?.tables?.map((table) => (
                <Rnd
                  disableDragging
                  bounds="parent"
                  default={{
                    x:
                      (venue_room?.tables.find((t) => t.id === table.id)
                        ?.position?.x || 0) * zoomFactor,
                    y:
                      (venue_room?.tables.find((t) => t.id === table.id)
                        ?.position?.y || 0) * zoomFactor,
                  }}
                  key={table?.id}
                >
                  <div
                    className={`col py-3 d-flex flex-column align-items-center ${
                      reservations?.find(
                        (reservation) =>
                          isSameDay(
                            new Date(reservation.selectedDate.seconds * 1000),
                            selectedDate
                          ) &&
                          reservation.selectedTables.some(
                            (selectedTable) => selectedTable.id === table.id
                          ) &&
                          reservation.status === "successful"
                      )
                        ? "disabled-table"
                        : ""
                    } `}
                    onClick={() => handleTableClick(table?.id)}
                  >
                    <span className="position-absolute top-50 translate-middle text-white fw-bold cursor-pointer">
                      {table?.tableLabel}
                    </span>
                    <img
                      src={
                        reservations?.find(
                          (reservation) =>
                            isSameDay(
                              new Date(reservation.selectedDate.seconds * 1000),
                              selectedDate
                            ) &&
                            reservation.selectedTables.some(
                              (selectedTable) => selectedTable.id === table.id
                            ) &&
                            reservation.status === "successful"
                        )
                          ? require(`./../../assets/table_images/${table.numberOfSeats}grey.svg`)
                          : getTableImage(
                              table.numberOfSeats,
                              table.table_type,
                              table.id
                            )
                      }
                      style={{
                        width: 140 * zoomFactor,
                        transition: "width 0.3s ease",
                        cursor: "pointer",
                      }}
                      alt="No table"
                    />
                  </div>
                </Rnd>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingVenueTables;
