import React from "react";
import { Link } from "react-router-dom";

function BreadCrumpOrg({title}) {
  return (
    <section className="section-pagetop bg-gray">
    <div className="container clearfix">
        <h4 className="title-page dark b float-xl-left mb-0">
        {title}
        </h4>
        <nav className="float-xl-right mt-2 mt-xl-0">
        <ol className="breadcrumb text-white">
            <li className="breadcrumb-item">
            <Link to="/" className="dark">
                <i className="fas fa-home" />
            </Link>
            </li>
            <li className="breadcrumb-item">
            <Link to="/dashboard_organizer" className="dark">
                Dashboard
            </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            {title}
            </li>
        </ol>
        </nav>
    </div>
    </section>
  );
}

export default BreadCrumpOrg;
