import React from "react";
import { useSettings } from "../../store/setting_store";

export default function MiniAccordion({
  heading,
  isVisible = false,
  children,
}) {
  const [_isVisible, setIsVisible] = React.useState(isVisible);
  const layout_page = useSettings((state) => state.layout_page);

  const icon = _isVisible ? "fa-chevron-down" : "fa-chevron-up";

  return (
    <article className="card-group-item">
      <header
        className="card-header radio-color-primary"
        style={{
          color: layout_page?.primary_color,
        }}>
        <a data-toggle="collapse" data-target={`#filter-${heading}`}>
          <i
            className={`icon-action fa ${icon} cursor-pointer`}
            onClick={() => setIsVisible(!_isVisible)}
          />
          <h6 className="title">{heading}</h6>
        </a>
      </header>

      {_isVisible && (
        <div className="filter-content collapse show" id="filter-keyword">
          <div className="card-body">{children}</div>
        </div>
      )}
    </article>
  );
}
