import React, { useState } from "react";
import "./footer.css";

import { Link, useNavigate } from "react-router-dom";
import { useSettings } from "../../store/setting_store";
import { useEvents } from "../../store/event_store";
import { useEffect } from "react";
import useQueryStore from "../../store/query_store";
import AppStore from "./../../assets/downloadButtons/appStore.png";
import GooglePlay from "./../../assets/downloadButtons/googleplaylogo-removebg-preview.png";
import PhoneGirl from "./girlOnPhone.png";
import UgandaFlag from "./ug_flag.png";

const currentYear = new Date().getFullYear();

function Footer() {
  const layout_page = useSettings((state) => state.layout_page);
  const getVisibleEventCategories = useEvents(
    (state) => state.getVisibleEventCategories
  );
  const setCategory = useQueryStore((state) => state.setCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );
  const navigate = useNavigate();

  const [eventCategories, setEventCategories] = useState([]);

  useEffect(() => {
    const fetchEventCategories = async () => {
      const categories = await getVisibleEventCategories();
      setEventCategories(categories);
    };
    fetchEventCategories();
  }, [eventCategories?.length]);

  //handle navigation
  const handleNavigation = (eventCategory) => {
    setSelectedCategory({
      value: eventCategory?.id,
      label: eventCategory?.name,
    });
    setCategory(eventCategory?.id);
    navigate("/browse_events");
  };

  const lyxaAppLink =
    "https://play.google.com/store/apps/details?id=com.lyxa.lyxa";

  const lyxaAppStoreLink = "https://apps.apple.com/us/app/lyxa/id1565144071";
  const isMobile = window.innerWidth < 550; // breakpoint for mobile here

  return (
    <footer className="section-footer border-top-gray">
      <div className="container">
        <section className="footer-top padding-top">
          <div className="row">
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">Useful Links</h5>
              <ul className="list-unstyled">
                <li className="mb-1">
                  <Link to="/about_us">About us</Link>
                </li>
                <li className="mb-1">
                  <Link to="/how_it_works">Help center</Link>
                </li>
                <li className="mb-1">
                  <Link to="/blogs">Blog</Link>
                </li>
                <li className="mb-1">
                  <Link to="/venues">Venues</Link>
                </li>
                <li className="mb-1">
                  <Link to="/contact">Send us an email</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">My Account</h5>
              <ul className="list-unstyled">
                <li className="mb-1">
                  <Link to="/sign_up_attendee">Create an account</Link>
                </li>
                <li className="mb-1">
                  <Link to="/sign_up_organizer">Sell tickets online</Link>
                </li>
                <li className="mb-1">
                  <Link to="/dashboard">My tickets</Link>
                </li>
                <li className="mb-1">
                  <Link to="/forgot_password">Forgot your password ?</Link>
                </li>
                <li className="mb-1">
                  <Link to="/pricing_and_fees">Pricing and fees</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <h5 className="title text-dark">Event Categories</h5>
              {eventCategories.slice(7, 12).map((category) => (
                <ul className="list-unstyled">
                  <li
                    onClick={() => handleNavigation(category)}
                    style={{ marginBottom: "-12px", cursor: "pointer" }}
                  >
                    {category?.name}
                  </li>
                </ul>
              ))}
              <ul className="list-unstyled">
                <li className="mb-1">
                  <Link to="/categories">All categories</Link>
                </li>
              </ul>
            </aside>
            <aside className="col-sm-6 col-lg-3">
              <article>
                <h5 className="title text-dark">Contact Us</h5>
                <p>
                  <strong>Tel: </strong> 0326 120 251
                  <br />                  
                  
                  <strong>Phone: </strong>+256776176500 | +256702059944
                  <br />

                  <strong>Email:</strong>{" "}
                  <a
                    style={{
                      color: layout_page?.primary_color,
                    }}
                    href="mailto:info@lyxaevents.com"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    info@lyxaevents.com
                  </a>
                </p>
                <div className="btn-group white">
                  <Link
                    className="btn btn-facebook"
                    title="Facebook"
                    target="_blank"
                    to="https://www.facebook.com/lyxaofficial/"
                  >
                    <i className="fab fa-facebook-f fa-fw" />
                  </Link>
                  <Link
                    className="btn btn-instagram"
                    title="Instagram"
                    target="_blank"
                    to="https://www.instagram.com/lyxaofficial/"
                  >
                    <i className="fab fa-instagram fa-fw" />
                  </Link>
                  <Link
                    className="btn btn-youtube"
                    title="Youtube"
                    target="_blank"
                    href="https://www.youtube.com/@qriscorpultd9672"
                  >
                    <i className="fab fa-youtube fa-fw" />
                  </Link>
                  <Link
                    className="btn btn-twitter"
                    title="Twitter"
                    target="_blank"
                    href="https://www.twitter.com/lyxaevents"
                  >
                    <i className="fab fa-twitter fa-fw" />
                  </Link>
                </div>
                <div className="clearfix" />
                <h5 className="title mt-4 display-inline-block">
                  <i className="fas fa-globe" /> Language
                </h5>
                <div className="dropdown display-inline-block ml-5">
                  <div data-toggle="dropdown" className="text-dark">
                    English{" "}
                    <img src={UgandaFlag} alt="" style={{ width: 25, borderRadius: "2px" }} />
                    &nbsp;
                    <i className="fas fa-caret-down" />
                  </div>
                </div>
              </article>
            </aside>
          </div>
          <br />
          <div className="">
            <span className="h5 title text-dark">
              Download the Lyxa App Now!
            </span>
            <div
              className=" d-flex align-items-center download"
              style={{
                backgroundColor: layout_page?.primary_color,
              }}
            >
              <div className="d-flex align-items-center">
                <a
                  href={lyxaAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black"
                >
                  <span
                    className="d-flex border px-1 py-1 rounded cursor-pointer ml-1 ml-lg-4 border-0"
                    style={{ width: 132, height: 40, backgroundColor: "black" }}
                  >
                    <img
                      src={GooglePlay}
                      alt="Download from Google Play"
                      style={{ width: 44, height: 32 }}
                    />

                    <p
                      className="d-grid ml-1 text-white font-weight-bold"
                      style={{ fontSize: 10 }}
                    >
                      <span>Download on</span>{" "}
                      <span className="fw-bold">Google Play</span>
                    </p>
                  </span>
                </a>

                <span className="cursor-pointer">
                  <a
                    href={lyxaAppStoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer"
                  >
                    <img
                      src={AppStore}
                      alt="Download from App Store"
                      style={{ width: 135 }}
                    />
                  </a>
                </span>
              </div>
              {!isMobile ? (
                <div className=" d-flex align-items-center">
                  <span className=" text-justify text-white font-weight-bold text-sm ml-lg-3">
                    Get lyxa Mobile App <br /> today on Google <br />
                    Play and AppStore
                  </span>

                  <span className="">
                    <img
                      src={PhoneGirl}
                      alt=""
                      className="img-100-100 rounded-circle"
                    />
                  </span>
                </div>
              ) : (
                <div className=" d-flex align-items-center">
                  <span className="">
                    <img
                      src={PhoneGirl}
                      alt=""
                      className=" img-80-80 rounded-circle"
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="footer-bottom row">
          <div className="col-sm-12">
            <p className="text-center text-dark">
              <Link to="/terms_of_service" className="text-dark">
                Terms of service
              </Link>
              <span className="text-gray">|</span>
              <Link to="/privacy_policy" className="text-dark">
                Privacy policy
              </Link>
              <span className="text-gray">|</span>
              <Link to="/cookie_policy" className="text-dark">
                Cookie policy
              </Link>
              <span className="text-gray">|</span>
              <Link to="/gdpr_compliance" className="text-dark">
                GDPR compliance
              </Link>
            </p>
          </div>
          <div className="col-sm-12">
            <p className="text-dark-50 text-center">
              Copyright © {currentYear}
            </p>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
