import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { myContext } from "../../../user_context";
import { userStore } from "../../../store/user_store";
import TextEditor from "../../../components/text_editor/text_editor";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditPaymentGateways() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const paymentMethods = useSettings((state) => state.paymentMethods);
  const uploadPaymentImage = useSettings((state) => state.uploadPaymentImage);
  const { gateway_id } = useParams();
  const deleteImage = useSettings((state) => state.deleteImage);
  const layout_page = useSettings((state) => state.layout_page);
  const updatePaymentGateway = useSettings(
    (state) => state.updatePaymentGateway
  );
  const { document } = useFetchDocument("payment_gateway", gateway_id);
  const navigate = useNavigate();
  const [payment_options, setPaymentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentForm, setPaymentForm] = useState(document || {});

  useEffect(() => {
    setPaymentForm(document);
  }, [document]);
  console.log("data is", paymentForm);

  useEffect(() => {
    const getOptions = () => {
      const paymentOptions = paymentMethods.map((paymentMethod) => {
        return {
          value: paymentMethod?.id,
          label: paymentMethod?.name,
        };
      });
      setPaymentOptions(paymentOptions);
    };
    getOptions();
  }, [paymentMethods?.length]);

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setPaymentForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //ENABLE ALL RADIO BUTTONS
  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setPaymentForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle image
  const [imageUrl, setImageUrl] = useState(paymentForm?.image);
  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadPaymentImage(file);
      if (imageUrl) {
        setImageUrl(imageUrl);
        setPaymentForm((prevFormData) => ({
          ...prevFormData,
          image: imageUrl,
        }));
      }
    }
  };
  // Handling delete image
  const handleDeleteImage = async (e) => {
    e.preventDefault();
    if (paymentForm?.image) {
      await deleteImage(paymentForm?.image);
      setImageUrl(null);
      setPaymentForm((prevFormData) => ({
        ...prevFormData,
        image: null,
      }));
    }
  };

  // HANDLE Instructions
  const handleInstructions = (value) => {
    setPaymentForm((prevFormData) => ({
      ...prevFormData,
      instructions: value,
    }));
  };

  //handling adding payment gateways
  const handleAddPaymentGateway = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setImageUrl(paymentForm?.image || "");
      await updatePaymentGateway(gateway_id, {
        name: paymentForm?.name,
        image: paymentForm?.image,
        instructions: paymentForm?.instructions,
        appearance_order: paymentForm?.appearance_order,
        status: paymentForm?.status || false,
        payment_gateway: paymentForm?.payment_gateway,
        sand_box: paymentForm?.sand_box || false,
        username: paymentForm?.username,
        password: paymentForm?.password,
        signature: paymentForm?.signature,
      });
      displaySuccessToast("Update is successfully");
      navigate("/manage_payments");
    } catch (error) {
      console.log("Error occured", error);
      displayErrorToast("Something went wrong in updating the payment gateway");
    }
    setIsLoading(false);
  };
  //loading state
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit payment gateway"
          page_link="Payment settings"
          link="manage_payments"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="payment_gateway"
                      onSubmit={handleAddPaymentGateway}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <div id="payment_gateway">
                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_name"
                            className="required"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required="required"
                            className="form-control"
                            value={paymentForm?.name || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            className="required"
                            htmlFor="payment_gateway_factoryName"
                          >
                            Choose a payment gateway
                          </label>
                          <small
                            id="payment_gateway_factoryName_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            For 'Cash / Check / Bank Transfer / Other', the
                            order will remain on the 'Awaiting payment' status
                            until the organizer or the administrator approves it
                          </small>

                          <SearchableDropdown
                            value={paymentForm?.payment_gateway}
                            name="payment_gateway"
                            options={payment_options}
                            isMulti={false}
                            onChange={(selectedOption) =>
                              handleInputChanging({
                                target: {
                                  name: "payment_gateway",
                                  value: selectedOption,
                                },
                              })
                            }
                          />
                        </div>

                        <fieldset className="form-group">
                          <legend className="col-form-label">
                            Instructions
                          </legend>
                          <div
                            id="payment_gateway_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="payment_gateway_translations_en">
                              <div className="form-group">
                                <TextEditor
                                  value={paymentForm?.instructions || ""}
                                  name="instructions"
                                  onChange={(value) =>
                                    handleInstructions(value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Image
                          </legend>{" "}
                          {paymentForm?.image ? (
                            <div className="d-grid">
                              <img
                                src={paymentForm?.image}
                                alt=""
                                style={{ maxWidth: "50%" }}
                              />
                              <div
                                onClick={handleDeleteImage}
                                className="bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Delete Image
                              </div>
                            </div>
                          ) : (
                            <div className="vich-image">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  id="custom_image"
                                  name="custom_image"
                                  className="custom-file-input"
                                  onChange={handleImage}
                                  required={true}
                                />
                                <label
                                  htmlFor="custom_image_imageFile_file"
                                  className="custom-file-label"
                                />
                              </div>
                            </div>
                          )}
                        </fieldset>

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Status
                          </legend>
                          <div id="payment_gateway_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gateway_enabled_0"
                                name="status"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={paymentForm?.status === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_0"
                              >
                                Disabled
                              </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gateway_enabled_1"
                                name="status"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={paymentForm?.status === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_1"
                              >
                                Enabled
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_number"
                            className="required"
                          >
                            Order of appearance
                          </label>
                          <input
                            type="number"
                            id="payment_gateway_number"
                            name="appearance_order"
                            required="required"
                            className="touchspin-integer form-control"
                            value={paymentForm?.appearance_order || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            SandBox
                          </legend>
                          <div id="payment_gateway_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gate"
                                name="sand_box"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={paymentForm?.sand_box === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_0"
                              >
                                No
                              </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gateway"
                                name="sand_box"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={paymentForm?.sand_box === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_1"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_username"
                            className="required"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            id="payment_gateway_username"
                            name="username"
                            required="required"
                            className="form-control"
                            value={paymentForm?.username}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_password"
                            className="required"
                          >
                            Password
                          </label>
                          <input
                            type="text"
                            id="payment_gateway_password"
                            name="password"
                            required="required"
                            className="form-control"
                            value={paymentForm?.password}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_signature"
                            className="required"
                          >
                            Signature
                          </label>
                          <input
                            type="text"
                            id="payment_gateway_signature"
                            name="signature"
                            required="required"
                            className="form-control"
                            value={paymentForm?.signature || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            id="payment_gateway_save"
                            name="payment_gateway[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
