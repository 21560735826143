import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";

import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { displaySuccessToast } from "../../../services/helpers";
import app from "../../../Firebase";
import { useSettings } from "../../../store/setting_store";
import { isMobile } from "react-device-detect";
import Sidenavbar from "../../organizer_dashboard/components/sidenavbar";
import AttendeeSideNav from "../../attendee_dashboard/components/side_bar";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ChangePassword() {
  const isAdmin = userStore((state) => state.isAdmin);
  const isAttendee = userStore((state) => state.isAttendee);

  const isOrganizer = userStore((state) => state.isOrganizer);
  const user = userStore((state) => state.user);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordCu, setShowPasswordCu] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const auth = getAuth(app);

    // Authenticate the user's current password
    try {
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credentials);
    } catch (error) {
      console.error("Error authenticating user:", error);
      setError("Incorrect current password. Please try again.");
      setIsSubmitting(false);
      return;
    }

    // Check if the new password field is empty
    if (newPassword.trim() === "") {
      setError("New password is required");
      setIsSubmitting(false);
      return;
    } else if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      setIsSubmitting(false);
      return;
    } else if (confirm_password.trim() === "") {
      setError("password confirmation is  required");
      setIsSubmitting(false);
      return;
    } else if (confirm_password !== newPassword) {
      setError("Passwords do not match");
      setIsSubmitting(false);
      return;
    } else if (currentPassword === newPassword) {
      setError("New password should be different from the current password");
      setIsSubmitting(false);
      return;
    } else if (currentPassword.trim() === "") {
      setError("Current/Old password is required");
      setIsSubmitting(false);
      return;
    }
    try {
      await updatePassword(auth.currentUser, newPassword);
      console.log("Password updated successfully");
      displaySuccessToast("Password updated successfully");
      if (isAdmin) {
        navigate("/admin_dashboard");
      } else if (isOrganizer) {
        navigate("/dashboard_organizer");
      } else {
        navigate("/dashboard_organizer");
      }
      navigate("/dashboard");
      // Handle success, such as displaying a success message
    } catch (error) {
      console.error("Error updating password:", error);
      setError("Error updating password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* BREADCRUMP */}
      <BreadcrumpAdmin title="Change Password" />

      {/* MAIN SECTION */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/*  ADMIN SIDE NAV */}
            {isAdmin && (
              <>
                {!isMobile && <AdminSideNav currentPage={"change_password"} />}
              </>
            )}

            {/* ORGANIZER SIDE BAR */}

            {isOrganizer && (
              <>
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar currentPage={"change_password"} />}
                </aside>
              </>
            )}
            {/* ATTENDEE SIDE BAR */}

            {isAttendee && (
              <>
                {!isMobile && <AttendeeSideNav activeTab={"change_password"} />}
              </>
            )}

            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <form
                    onSubmit={handleChangePassword}
                    name="fos_user_change_password_form"
                    method="post"
                    action="/en/dashboard/change-password"
                    noValidate="novalidate"
                  >
                    <div id="fos_user_change_password_form">
                      {error && (
                        <div className="mt-3 alert alert-danger" role="alert">
                          {error}
                        </div>
                      )}
                      <div className="form-group">
                        <label
                          htmlFor="fos_user_change_password_form_current_password"
                          className="required"
                        >
                          Current password
                        </label>
                        <div className=" d-flex">
                          <input
                            type={showPasswordCurrent ? "text" : "password"}
                            id="fos_user_change_password_form_current_password"
                            name="currentPassword"
                            required
                            autoComplete="currentPassword"
                            className="form-control"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />

                          <span
                            className="form-control cursor-pointer input-group-text"
                            style={{ width: "35px" }}
                            onClick={() =>
                              setShowPasswordCurrent(!showPasswordCurrent)
                            }
                          >
                            <i
                              className={
                                showPasswordCurrent
                                  ? "fa fa-eye"
                                  : "fa fa-eye-slash"
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="fos_user_change_password_form_plainPassword_first"
                          className="required"
                        >
                          New password
                        </label>
                        <div className=" d-flex">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="fos_user_change_password_form_plainPassword_first"
                            name="newPassword"
                            required
                            autoComplete="newPassword"
                            className="form-control"
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <span
                            className="form-control cursor-pointer input-group-text"
                            style={{ width: "35px" }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={
                                showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="fos_user_change_password_form_plainPassword_second"
                          className="required"
                        >
                          Repeat new password
                        </label>
                        <div className=" d-flex">
                          <input
                            type={showPasswordCu ? "text" : "password"}
                            id="fos_user_change_password_form_plainPassword_second"
                            name="confirmPassword"
                            autoComplete="confirmPassword"
                            required
                            className="form-control"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <span
                            className="form-control cursor-pointer input-group-text"
                            style={{ width: "35px" }}
                            onClick={() => setShowPasswordCu(!showPasswordCu)}
                          >
                            <i
                              className={
                                showPasswordCu ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn text-white"
                      style={{ backgroundColor: layout_page?.primary_color }}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Change password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
