import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { Link, useNavigate } from "react-router-dom";
import DropDownSettings from "../../pages/admin_dashboard/components/DropDownSettings";
import DropDownEvents from "../../pages/admin_dashboard/components/DropDownEvents";
import DropDownVenues from "../../pages/admin_dashboard/components/DropDownVenues";
import DropDownBlog from "../../pages/admin_dashboard/components/DropDownBlog";
import DropDownHelpCenter from "../../pages/admin_dashboard/components/DropDownHelpCenter";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import app from "../../Firebase";
import { useSettings } from "../../store/setting_store";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useEffect, useState } from "react";
import {
  admin_sidebar_options1,
  admin_sidebar_options2,
  admin_sidebar_options3,
  admin_sidebar_options4,
} from "./sidebar_options";

export default function AdminMobileDrawer({ currentPage }) {
  const navigate = useNavigate();

  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAttendee = userStore((state) => state.isAttendee);
  const isAdmin = userStore((state) => state.isAdmin);
  const logout = userStore((state) => state.logout);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);
  const [organizerDetails, setOrganizerDetails] = useState({});

  // METHOD TO GET ORGANIZER DETAILS
  async function handleGetOganizerDetails() {
    if (isOrganizer) {
      const repsonse = await getOrganizerDetails(user?.user_id);
      setOrganizerDetails(repsonse);
    }
  }
  useEffect(() => {
    handleGetOganizerDetails();
  }, []);
  const [state, setState] = useState({
    left: false, // Only left drawer
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const auth = getAuth(app);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  // SIGNOUT
  const handleSignOut = async () => {
    var response = window.confirm("Are you sure you want to sign out?");
    if (response === true) {
      const res = await logout();
      if (res) {
        localStorage.clear();
        navigate("/sign_in");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        // Get the user's email address
        const email = auth.currentUser.email;

        // Check if email has an associated account
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        // Check if the user registered with Google
        const isGoogleProvider = signInMethods.some((method) =>
          method.includes("google.com")
        );
        setIsGoogleSignIn(isGoogleProvider);
      }
    };

    fetchUser();
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        id="dashboard-menu"
        className="nav nav-pills nav-pills-vertical-styled"
        style={{ maxHeight: "20rem" }}
      >
        {/* DASHBOARD */}

        {admin_sidebar_options1?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* SETTINGS */}
        <li className="nav-item">
          <DropDownSettings currentPage={currentPage} />
        </li>

        {/* EVENTS */}
        <li className="nav-item">
          <DropDownEvents currentPage={currentPage} />
        </li>

        {/* MANAGE ORDERS */}

        {admin_sidebar_options2?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* VENUES */}
        <li className="nav-item">
          <DropDownVenues currentPage={currentPage} />
        </li>

        {admin_sidebar_options3?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* MANAGE BLOGS */}
        <li className="nav-item">
          <DropDownBlog currentPage={currentPage} />
        </li>

        {/* HELP CENTER */}
        <li className="nav-item">
          <DropDownHelpCenter currentPage={currentPage} />
        </li>

        {/* CHANGE PASSWORD */}
        {admin_sidebar_options4?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* SIGNOUT */}
        {isMobile && (
          <li className="nav-item" onClick={handleSignOut}>
            <Link to="/my_reports" className={"nav-link "}>
              <i className="fas fa-power-off fa-fw" /> Logout
            </Link>
          </li>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {/* Only render the left drawer */}
      <div key="left">
        <Button onClick={toggleDrawer("left", true)}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="avatar"
              style={{
                background: `url("${
                  user?.profile_picture || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                }")`,
              }}
            ></span>
          </div>
        </Button>
        <Drawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </div>
    </div>
  );
}
