import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { ordersStore } from "../../store/orders_store";
import { useSettings } from "../../store/setting_store";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutOrders = ({ setIsLoading, booking_id }) => {
  const deleteOrder = ordersStore((state) => state.deleteOrder);
  const layout_page = useSettings((state) => state.layout_page);
  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteOrder(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteOrder(booking_id);
    if (response) {
      setIsLoading(false);
      console.log("The order has been deleted successfully");
      displaySuccessToast("The order has been deleted");
    } else {
      displayErrorToast("Something went wrong while deleting the order");
    }
    setIsLoading(false);
  }

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <Link to={"/ticket_details/" + booking_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
              <h6>Details</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {" "}
          <DeleteComponentModal
            itemName="Order"
            handleDelete={handleDeleteOrder}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutOrders;
