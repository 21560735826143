import React from "react";
import { useEvents } from "../../../store/event_store";
import { convertTimestampToDateTime } from "../../../services/helpers";

const Status = ({ event }) => {
  const upcomingEvents = useEvents((state) => state.upcomingEvents);

  const isUpcomingEvent = upcomingEvents.some(
    (upcomingEvent) => upcomingEvent?.id === event?.id
  );

  return (
    <div>
      {isUpcomingEvent && (
        <span>
          <span className="badge badge-success">On sale</span>
        </span>
      )}
      <span className="text-center">
        {!isUpcomingEvent && (
          <span className="badge badge-danger">Past Event</span>
        )}
      </span>

      {event?.visibility === "draft" && (
        <span>
          <span className="badge badge-warning">Event not published</span>
        </span>
      )}
    </div>
  );
};

export default Status;
