import React, { useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import organizerSignupImage from "../../assets/img/illustrations/register-organizer.c2449d93.png";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { Timestamp } from "firebase/firestore";
import { useSettings } from "../../store/setting_store";

import { useFormik } from "formik";
import {
  displayErrorToast,
  validationSchemaOrganizer,
} from "../../services/helpers";

function Signuporg() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  const register = userStore((state) => state.register);
  const layout_page = useSettings((state) => state.layout_page);

  // CREATING THE USER ACCOUNT
  const handleSignup = async () => {
    setIsLoading(true);
    const userData = {
      user_id: null,
      firstname: values.firstName,
      lastname: values.lastName,
      username: values.userName,
      email: values.email,
      phone_number: `256${values.phoneNumber}`,
      address: null,
      bio: null,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      profile_picture: null,
      online_status: "offline",
      social_media_links: null,
      date_of_birth: null,
      fcm_token: null,
      kyc_status: null,
      last_seen_at: null,
      location: null,
      user_type: "organizer",
      point_of_sale_id: null,
      scanner_id: null,
      isorganizeronhomepageslider_id: false,
      source: "WEBSITE",
    };

    const data_organizer = {
      id: null,
      organizer_name: values.organizerName,
      email: values.email,
      phone_number: `256${values.phoneNumber}`,
      about: null,
      organizer_logo: null,
      cover_photo: null,
      country: null,
      website: null,
      phone: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,
      youtubeVideoUrl: null,
      showVenuesMap: true,
      showFollowers: true,
      showReviews: true,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
    };

    const response = await register({
      organizerData: data_organizer,
      data: userData,
      password: values.password,
      email: values.email,
    });

    console.log(response);

    if (response) {
      // navigate("/dashboard_organizer", { state: response });
      navigate("/check_email", {
        state: { userData },
      });
    } else {
      console.log("something is wrong");
      displayErrorToast("Something wrong");
    }

    setIsLoading(false);
  };

  //validating the form.
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      organizerName: "",
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchemaOrganizer,
    onSubmit: handleSignup,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="card-title text-center">
                Take your events to the next level
              </h1>
            </div>

            {/* LEFT IMAGE */}
            <div className="col-xl-5 offset-xl-1 d-none d-xl-block">
              <img src={organizerSignupImage} className="img-fluid" alt="" />
            </div>
            <div className="col-xl-4 col-12">
              <form
                name="fos_user_registration_form"
                onSubmit={handleSubmit}
                noValidate="novalidate"
              >
                {/* Orgnaizer name */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="far fa-id-card" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_organizer_name"
                      name="organizerName"
                      required="required"
                      placeholder="Organizer name"
                      className={
                        errors.organizerName && touched.organizerName
                          ? "form-control border border-danger "
                          : "form-control"
                      }
                      value={values.organizerName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.organizerName && touched.organizerName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.organizerName}
                    </p>
                  )}
                </div>

                {/* FIRST NAME */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_firstname"
                      name="firstName"
                      required="required"
                      placeholder="First name"
                      className={
                        errors.firstName && touched.firstName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.firstName && touched.firstName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                {/* last name */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_lastname"
                      name="lastName"
                      required="required"
                      placeholder="Last name"
                      className={
                        errors.lastName && touched.lastName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.lastName && touched.lastName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.lastName}
                    </p>
                  )}
                </div>

                {/* USERNAME */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        <i className="fas fa-user-tag" />
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_username"
                      name="userName"
                      required="required"
                      placeholder="Username"
                      className={
                        errors.userName && touched.userName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.userName && touched.userName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.userName}
                    </p>
                  )}
                </div>

                {/* PHONE NUMBER */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        <i className="fas fa-phone" />
                      </span>
                    </div>

                    <div className="input-group-prepend">
                      <span
                        // className="input-group-text"
                        className={
                          errors.phoneNumber && touched.phoneNumber
                            ? "input-group-text border border-danger"
                            : "input-group-text"
                        }
                        id="basic-addon1"
                        style={{ borderRadius: "5px" }}
                      >
                        +256
                      </span>
                    </div>
                    <input
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      required="required"
                      placeholder="Phone Number"
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? "form-control border-start-0 border-danger"
                          : "form-control"
                      }
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.phoneNumber && touched.phoneNumber && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>

                {/* EMAIL */}

                <div className="form-group">
                  <div className=" input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-envelope" />{" "}
                      </span>
                    </div>
                    <input
                      type="email"
                      id="fos_user_registration_form_email"
                      name="email"
                      required="required"
                      placeholder="Email"
                      className={
                        errors.email && touched.email
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.email && touched.email && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.email}
                    </p>
                  )}
                </div>

                {/* PASSWORD */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user-lock" />{" "}
                      </span>
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="fos_user_registration_form_plainPassword_first"
                      name="password"
                      required="required"
                      placeholder="Password"
                      className={
                        errors.password && touched.password
                          ? "form-control border-end-0 border-danger"
                          : "form-control"
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div>
                      <span
                        className={
                          errors.password && touched.password
                            ? "form-control border-start-0 border-danger cursor-pointer"
                            : "form-control cursor-pointer"
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {errors.password && touched.password && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.password}
                    </p>
                  )}
                </div>

                {/* CONFIRM PASSWORD */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        <i className="fas fa-user-lock" />
                      </span>
                    </div>
                    <input
                      type={showPasswordC ? "text" : "password"}
                      id="fos_user_registration_form_plainPassword_second"
                      name="confirmPassword"
                      required="required"
                      placeholder="Repeat password"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "form-control border-end-0 border-danger"
                          : "form-control"
                      }
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div>
                      <span
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "form-control border-start-0 border-danger cursor-pointer"
                            : "form-control cursor-pointer"
                        }
                        onClick={() => setShowPasswordC(!showPasswordC)}
                      >
                        <i
                          className={
                            showPasswordC ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>

                {/* TERMS OF SERVICE AND PRIVACY POLICY */}
                <div className="mb-3 text-sm text-center">
                  <span className="text-muted">
                    By clicking the Sign Up button, I agree to
                  </span>
                  <a href="/terms_of_service">Terms of service</a>
                  <span className="text-muted">and</span>
                  <a href="/privacy_policy">Privacy policy</a>
                </div>

                {/* CREATE ACCOUNT BUTTON */}
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn text-white btn-block"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                  >
                    {isSubmitting ? (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <>Create Account</>
                    )}
                  </button>
                </div>

                {/* SIGN IN */}
                <p className="text-center">
                  Already have an account? <Link to="/sign_in">Sign in</Link>
                </p>
                <input
                  type="hidden"
                  id="fos_user_registration_form__token"
                  name="fos_user_registration_form[_token]"
                  defaultValue="h5CNsZBezEDyLGY1gSveulwG8HDGfdQRfS-zQG7bTWA"
                />
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Signuporg;
