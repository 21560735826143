import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import app from "../Firebase";
import axios from "axios";
import { displayErrorToast } from "../services/helpers";

// Load environment variables from .env

// INITIALIZING FIRESTORE
const db = getFirestore(app);

const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const clientId = process.env.REACT_APP_CLIENT_ID;
const merchantCode = process.env.REACT_APP_MERCHANT_CODE;
const lyxaCode = process.env.REACT_APP_LYXA_CODE;
const payment_status_url = process.env.REACT_APP_PAYMENT_STATUS_URL;
const make_payment_url = process.env.REACT_APP_MAKE_PAYMENT_URL;
const get_token_url = process.env.REACT_APP_GET_TOKEN_URL;

const orders_store = (set, get) => ({
  orders: [],
  organizers: {},
  ticketQuantities: [],
  dates: [],
  eventTicketQuantities: [],
  event_dates: [],
  organizer_dates: [],
  organizer_tickets: [],
  organizerTickets: 0,
  organizerOrders: 0,
  transactions: [],

  // GET EVENT ORDERS
  getEventOrderTickets: async (event_id) => {
    try {
      const docRef = collection(db, "orders");
      const q = query(docRef, where("event_id", "==", event_id));

      const querySnapshot = await getDocs(q);

      const ticketsList = [];

      // LOOP THROUGH THE DOCS
      querySnapshot.docs.forEach((doc) => {
        const orderData = doc.data();
        const orderTickets = orderData.order_tickets;

        // LOOP THROUGH THE TICKETS
        orderTickets.forEach((ticket) => {
          ticketsList.push(ticket);
        });
      });

      return ticketsList;
    } catch (error) {
      console.log("ERROR GETTING ORDERS: " + error);
      return [];
    }
  },

  /////////////////////////////////////////////////////////
  ////          ADDING THE ORDER TO THE DATABASE   ///////
  ////////////////////////////////////////////////////////

  addOrder: async (data) => {
    try {
      const response = await axios.post(
        "https://us-central1-lyxa-e0c88.cloudfunctions.net/addOrder",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("THE RESPONSE WHEN MAKING ORDER IS:", response.data);

      if (response.status === 200) {
        const body = response.data;
        return body.status === "success";
      } else {
        return false;
      }
    } catch (error) {
      console.error("SOMETHING WENT WRONG ADDING ORDER:", error);
      return false;
    }
  },

  // GET THE DETAILS OF AN ORGANIZER using event_id
  getOrganizerDetail: async (event_id) => {
    if (!event_id) {
      return null;
    }
    try {
      const eventDoc = doc(db, "events_new", event_id);
      const docSnapshot = await getDoc(eventDoc);

      if (docSnapshot.exists()) {
        const eventData = docSnapshot.data();
        const organizer_id = eventData.organizer_id;

        const organizerDoc = doc(db, "organizers", organizer_id);
        const organizerSnapshot = await getDoc(organizerDoc);

        if (organizerSnapshot.exists()) {
          const organizerData = organizerSnapshot.data();
          return organizerData;
        } else {
          console.log("Organizer document does not exist");
          return null;
        }
      } else {
        console.log("Event document does not exist");
        return null;
      }
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  },

  //get order by id
  getOrderById: async (order_id) => {
    const docRef = doc(db, "orders", order_id);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return { id: docSnap.id, ...data };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getEventAttendees: async (event_id) => {
    const docRef = collection(db, "orders");
    const q = query(docRef, where("event_id", "==", event_id));

    try {
      const querySnapshot = await getDocs(q);

      const ticketsList = [];

      // LOOP THROUGH THE DOCS
      querySnapshot.docs.forEach((doc) => {
        const orderData = doc.data();

        // LIST OF TICKETS
        const tickets = orderData.order_tickets;

        for (let i = 0; i < tickets.length; i++) {
          // CHECK IF THE TICKET IS SCANNED
          const isScanned = tickets[i]?.status === "invalid";

          if (isScanned) {
            ticketsList.push(tickets[i]);
          }
        }
      });

      return ticketsList;
    } catch (error) {
      console.log("Something went wrong getting scanned tickets: " + error);
      return [];
    }
  },

  //GETTING ALL ORDERS FROM THE DATABASE and the organizer of that event ordered
  fetchOrders: async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "orders"), orderBy("created_at", "desc"))
      );
      const orders = [];
      querySnapshot.docs.forEach((doc) => {
        orders.push(doc.data());
      });
      set({ orders });
      return orders;
    } catch (error) {
      console.log("Something went wrong fetching orders", error);
      // displayErrorToast("Something went wrong fetching orders");
      return [];
    }
  },

  // GET THE ORDER basing on booking id
  getOrder: async (order_id) => {
    try {
      const orders = await get().fetchOrders();
      const order = orders.find((order) => order?.booking_id === order_id);
      return order;
    } catch (error) {
      console.log("ERROR GETTING ORDERS: " + error);
      return null;
    }
  },

  //get all orders of the attendee
  attendeeOrders: async (user_id) => {
    const orders = await get().fetchOrders();
    const attendeeOrders = orders.filter(
      (order) => order.customer_id === user_id
    );
    return attendeeOrders;
  },

  //delete order
  deleteOrder: async (booking_id) => {
    try {
      const ordersCollection = collection(db, "orders");
      const q = query(ordersCollection, where("booking_id", "==", booking_id));
      const querySnapshot = await getDocs(q);
      const orderDoc = querySnapshot.docs[0];
      await deleteDoc(orderDoc.ref);
      console.log("Order deleted successfully");
      //update the orders
      const updatedOrders = get().orders.filter(
        (order) => order?.booking_id !== booking_id
      );
      set({ orders: updatedOrders });
      return updatedOrders;
    } catch (error) {
      console.log("Something went wrong while deleting order", error);
      return false;
    }
  },

  //getting orders of the specific event
  getOrdersOfSpecificEvent: async (eventId) => {
    try {
      // Query the 'orders' collection
      const ordersCollection = collection(db, "orders");
      const ordersSnapshot = await getDocs(ordersCollection);

      // Initialize an array to store matching orders
      const matchingOrders = [];

      // Iterate through the orders
      ordersSnapshot.forEach((doc) => {
        const orderData = doc.data();

        // Check if the order has 'order_tickets' and it's an array
        if (Array.isArray(orderData.order_tickets)) {
          // Use JavaScript Array methods to check if any 'order_ticket' has a matching 'event_id'
          const hasMatchingEvent = orderData.order_tickets.some((ticket) => {
            return ticket.event_id === eventId;
          });

          // If there's a matching 'event_id', add the order to the result
          if (hasMatchingEvent) {
            matchingOrders.push({
              id: doc.id,
              ...orderData,
            });
          }
        }
      });
      return matchingOrders;
    } catch (error) {
      console.log("Error fetching orders with specific event: ", error);
      return [];
    }
  },

  // getting ticket details
  getAllTicketQuantities: async () => {
    try {
      const ticketDoc = collection(db, "orders");
      const querySnapshot = await getDocs(ticketDoc);
      let totalTicketQuantity = 0;

      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderTickets = order.order_tickets || [];
        totalTicketQuantity += orderTickets.length;
      });

      return totalTicketQuantity;
    } catch (error) {
      console.log("Error getting quantity:", error);
    }
  },

  // A method to get all tickets for an event in order collection
  getOrderTicketsForEvent: async (event_id) => {
    if (!event_id) {
      return null;
    }

    try {
      let totalTicketQuantity = 0;
      //get all the documents in the orders collection
      const querySnapshot = await getDocs(collection(db, "orders"));
      // Loop through the order documents
      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderTickets = order.order_tickets || []; // Make sure to handle cases where order_tickets might be undefined
        //loop through the order_tickets array and find if the ticket.event_id for the tickets === to the event_id
        orderTickets.forEach((ticket) => {
          const eventID = ticket.event_id;
          if (eventID === event_id) {
            totalTicketQuantity += 1;
          }
        });
      });
      return totalTicketQuantity;
    } catch (error) {
      console.error("Error getting ticket quantity:", error);
      return null;
    }
  },

  //getting total quantity of Order tickets
  getTotalOrderTickets: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      let totalQuanity = 0;
      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderQuantity = order.quantity || "0";
        totalQuanity += parseInt(orderQuantity);
      });
      return totalQuanity;
    } catch (error) {
      console.log("error occured in getting the ticket quantity", error);
      return null;
    }
  },

  // A method to get all scanned order tickets for an event
  getScannedTicketsForEvent: async (event_id) => {
    if (!event_id) {
      return []; // If event_id is not provided, return an empty array
    }
    try {
      const scannedTickets = []; // Initialize an array to store scanned order tickets
      // Create a reference to the "orders" collection
      const ordersCollection = collection(db, "orders");
      // Fetch all documents from the "orders" collection
      const querySnapshot = await getDocs(ordersCollection);
      // Loop through all the orders
      querySnapshot.docs.forEach((doc) => {
        const order = doc.data(); // Get the order data from the document
        const orderTickets = order?.order_tickets || []; // Extract the order tickets array from the order data
        // Loop through the tickets for each order
        for (let orderTicket of orderTickets) {
          // Check if the event_id of the order ticket matches the provided event_id
          // and if the ticket status is not "valid"
          if (
            orderTicket.event_id === event_id &&
            orderTicket.status !== "valid"
          ) {
            scannedTickets.push(orderTicket); // Add the scanned order ticket to the array
          }
        }
      });
      return scannedTickets; // Return the array of scanned order tickets
    } catch (error) {
      console.error("Error getting ticket quantity:", error); // Log the error message if an error occurs
      return null; // Throw the error to be caught by the caller
    }
  },

  // Function to get an array of the last 29 days and the current day
  getArrayOfLast29DaysAndCurrentDay: () => {
    const datesArray = [];
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 49); // Set current date to 29 days ago

    const endDate = new Date(); // Current day

    while (currentDate <= endDate) {
      datesArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
  },

  // getTotalOrderTicketsPerDay function with updated allDates array
  getTotalOrderTicketsPerDay: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ticketsPerDay = {}; // Object to store ticket quantities for each day

      // Create an array of the last 29 days and the current day
      const allDates = get().getArrayOfLast29DaysAndCurrentDay();

      // Initialize ticketsPerDay object with all dates and set initial ticket quantities to 0
      allDates.forEach((date) => {
        ticketsPerDay[date] = 0;
      });

      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderQuantity = parseInt(order.quantity || "0");
        const createdAt = order.created_at.toDate(); // Convert Timestamp to JavaScript Date
        const orderDate = createdAt.toISOString().split("T")[0]; // Extract the date portion

        // Add the orderQuantity to the corresponding date in ticketsPerDay
        if (ticketsPerDay.hasOwnProperty(orderDate)) {
          ticketsPerDay[orderDate] += orderQuantity;
        }
      });

      // Extract dates and ticket quantities to separate arrays for chart data
      const dates = Object.keys(ticketsPerDay);
      const ticketQuantities = Object.values(ticketsPerDay);
      // console.log("the dates are:", dates);
      // Update the state with the final data after processing all orders
      set({ ticketQuantities, dates });

      return { dates, ticketQuantities };
    } catch (error) {
      console.log("Error getting the total order tickets per day");
      return null;
    }
  },

  //get total Order Tickets Per pay for an event GETTING TOTAL ORDER TICKETS PER DAY FOR AN EVENT
  getTotalOrderTicketsPerDayForEvent: async (event_id) => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ticketsPerDay = {}; // Object to store ticket quantities for each day

      // Create an array of the last 29 days and the current day
      const allDates = get().getArrayOfLast29DaysAndCurrentDay();
      // Initialize ticketsPerDay object with all dates and set initial ticket quantities to 0
      allDates.forEach((date) => {
        ticketsPerDay[date] = 0; // Set initial quantity to 0 instead of an object
      });

      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderTickets = order.order_tickets || []; // get the order tickets array from the orders

        const createdAt = order.created_at.toDate(); // Convert Timestamp to JavaScript Date
        const orderDate = createdAt.toISOString().split("T")[0]; // Extract the date portion
        // Check if the ticket in order_ticket array is for the specified event_id
        orderTickets.forEach((ticket) => {
          const eventID = ticket.event_id;
          if (eventID === event_id && ticketsPerDay.hasOwnProperty(orderDate)) {
            ticketsPerDay[orderDate] += 1; // Increment the ticket count for the day where the event_id are found to be the same
          }
        });
      });
      // Extract ticket quantities to get data for chart data
      const eventTicketQuantities = Object.values(ticketsPerDay);
      const event_dates = Object.keys(ticketsPerDay);
      set({ eventTicketQuantities, event_dates });
      return { eventTicketQuantities, event_dates };
    } catch (error) {
      console.log("Error getting the total order tickets per day");
      return null;
    }
  },

  //getting the tickets sold per day for the organizer events
  getTotalOrderTicketsPerDayByOrganizer: async (organizer_id) => {
    try {
      // Step 1: Retrieve events from "events_new" collection based on the organizer_id
      const q = query(
        collection(db, "events_new"),
        where("organizer_id", "==", organizer_id)
      );
      const eventsSnapshot = await getDocs(q);

      // Step 2: Build an array of event_ids from the retrieved events
      const eventIds = eventsSnapshot.docs.map(
        (eventDoc) => eventDoc.data().id
      );

      // Step 3: get the orders documents which we are gonna use
      const querySnapshot = await getDocs(collection(db, "orders"));

      // Initialize an object to store the tickets sold each day
      const ticketsPerDay = {};
      // Create an array of the last 29 days and the current day
      const allDates = get().getArrayOfLast29DaysAndCurrentDay();
      // Initialize ticketsPerDay object with all dates and set initial ticket quantities to 0
      allDates.forEach((date) => {
        ticketsPerDay[date] = 0; // Set initial quantity to 0
      });

      // Loop through the order documents
      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderTickets = order.order_tickets || []; // get the order tickets array from the orders

        const createdAt = order.created_at.toDate(); // Convert Timestamp to JavaScript Date
        const orderDate = createdAt.toISOString().split("T")[0]; // Extract the date portion
        //loop the ordrer tickets array so that you compare the event ids with ticket.event_id
        orderTickets.forEach((ticket) => {
          const event_id = ticket.event_id;
          if (
            eventIds.includes(event_id) &&
            ticketsPerDay.hasOwnProperty(orderDate)
          ) {
            ticketsPerDay[orderDate] += 1; // Increment the ticket count for the day where the event_id are found to be the same
          }
        });
      });
      // Extract dates and ticket quantities to separate arrays for chart data
      const organizer_dates = Object.keys(ticketsPerDay);
      set({
        organizer_dates: organizer_dates,
        organizer_tickets: ticketsPerDay,
      });
      return { ticketsPerDay, organizer_dates };
    } catch (error) {
      console.error("Error getting tickets sold each day:", error);
      return {};
    }
  },

  //GETTING ALL TICKETS SOLD FOR THE ORGANIZER
  getTotalOrganizerTicketsSold: async (organizer_id) => {
    try {
      //1: retrieve the events of the organizer
      const q = query(
        collection(db, "events_new"),
        where("organizer_id", "==", organizer_id)
      );
      const eventsSnapshot = await getDocs(q);
      //2: extract the event ids
      const eventIds = eventsSnapshot.docs.map(
        (eventDoc) => eventDoc.data().id
      );

      // Step 3: get the orders documents which we are gonna use
      const querySnapshot = await getDocs(collection(db, "orders"));

      let ticketQuantity = 0; //set the initial total quantity  to zero.
      //4: loop through the orders documents
      querySnapshot.docs.forEach((doc) => {
        const order = doc.data();
        const orderTickets = order.order_tickets || []; // get the order tickets array from the orders
        //loop the ordrer tickets array so that you compare the event ids with ticket.event_id
        orderTickets.forEach((ticket) => {
          const event_id = ticket.event_id;
          if (eventIds.includes(event_id)) {
            ticketQuantity += 1;
          }
        });
      });
      set({ organizerTickets: ticketQuantity });
      return ticketQuantity;
    } catch (error) {
      console.log("ther is error in getting the ticket quanitity:", error);
      return null;
    }
  },

  //getting the total orders of the organizer.
  getOrganizerOrders: async (organizer_id) => {
    //1: retrieve the events of the organizer
    const q = query(
      collection(db, "events_new"),
      where("organizer_id", "==", organizer_id)
    );
    const eventsSnapshot = await getDocs(q);
    //2: extract the event ids
    const eventIds = eventsSnapshot.docs.map((eventDoc) => eventDoc.data().id);
    // Step 3: get the orders documents which we are gonna use
    const querySnapshot = await getDocs(collection(db, "orders"));

    let organizerOrders = 0; // initialize the organizer orders

    //4: loop through the orders documents
    querySnapshot.docs.forEach((doc) => {
      const order = doc.data();
      const orderTickets = order.order_tickets || [];
      //loop through the order tickets, you can use forEach loop or for loop
      for (let i = 0; i < orderTickets.length; i++) {
        if (eventIds.includes(orderTickets[i].event_id)) {
          organizerOrders += 1;
          break;
        }
      }
    });
    set({ organizerOrders: organizerOrders });
    return organizerOrders;
  },

  ////          ADDING A TRANSACTION TO DATABASE   ///////
  ////////////////////////////////////////////////////////
  addTransaction: async (data) => {
    const transactionDoc = collection(db, "transactions");

    try {
      const response = await addDoc(transactionDoc, data);
      console.log("TRANSACTION ADDED: ", response);
      return true;
    } catch (error) {
      console.log("ERROR ADDING TRANSACTION: ", error);
      return false;
    }
  },

  //getting the token
  getAuthToken: async () => {
    try {
      const response = await axios.post(get_token_url, {
        username: username,
        password: password,
      });
      return response.data;
    } catch (error) {
      console.log("Error obtaining auth token:", error);
      return null;
    }
  },

  //making flutter wave payments
  makePayment: async (
    authToken,
    requestId,
    amount,
    selectedPaymentMethod,
    phoneNumber
  ) => {
    // Initialize sourceSystem variable
    let sourceSystem = "";

    if (selectedPaymentMethod === "Airtel or MTN") {
      // Ensure phoneNumber is a string
      const phoneNumberString = String(phoneNumber);
      // Check the prefix to determine payment type
      if (
        phoneNumberString.startsWith("25675") ||
        phoneNumberString.startsWith("25674") ||
        phoneNumberString.startsWith("25670")
      ) {
        sourceSystem = "AIRTEL";
      } else if (
        phoneNumberString.startsWith("25677") ||
        phoneNumberString.startsWith("25678") ||
        phoneNumberString.startsWith("25676")
      ) {
        sourceSystem = "MTN";
      } else {
        displayErrorToast("Only Airtel and MTN are accepted here.");
        return;
      }
    } else if (selectedPaymentMethod === "FlexiPay") {
      sourceSystem = "FLEXIPAY";
    }

    try {
      const data = {
        msisdn: phoneNumber,
        requestId: requestId,
        merchantCode: merchantCode,
        referenceNumber: requestId,
        amount: amount,
        sourceSystem: sourceSystem,
        narrative: "Event Ticket Purchase",
        clientId: clientId,
      };

      const headers = {
        "auth-token": authToken,
        "app-code": lyxaCode,
      };

      const response = await axios.post(make_payment_url, data, {
        headers: headers,
      });

      console.log("Payment response status:", response.status);
      console.log("Payment response:", response.data);
      return response.data;
    } catch (error) {
      console.log("Error making payment:", error);
      return null;
    }
  },

  // Checking payment status
  checkPaymentStatus: async (authToken, requestId) => {
    console.log("STATUS REQUEST ID: " + requestId);

    try {
      const response = await axios.post(
        payment_status_url,
        {
          requestId: requestId,
          clientId: clientId,
        },
        {
          headers: {
            "auth-token": authToken,
            "app-code": lyxaCode,
          },
        }
      );
      console.log(" check Payment response status:", response.status);
      console.log(" check Payment response:", response.data);
      return response.data;
    } catch (error) {
      console.log("Error checking payment status:", error);
      return null;
    }
  },

  fetchTranscations: async () => {
    try {
      const transactions = [];
      const transactionDoc = collection(db, "transactions");
      const querySnapshot = await getDocs(transactionDoc);
      querySnapshot.docs.forEach((doc) => {
        transactions.push(doc.data());
      });
      set({ transactions });
      return transactions;
    } catch (error) {
      console.log("Error fetching transactions:", error);
      return [];
    }
  },

  //updating the transaction status
  updateTransactionStatus: async (transactionId, data) => {
    try {
      const transactionQuery = query(
        collection(db, "transactions"),
        where("transaction_id", "==", transactionId)
      );
      const transactionQuerySnapshot = await getDocs(transactionQuery);

      if (!transactionQuerySnapshot.empty) {
        // Only update the first document found, assuming there's only one matching document
        const transactionDoc = transactionQuerySnapshot.docs[0];

        await updateDoc(transactionDoc.ref, data);
        return true;
      } else {
        // No document with matching transaction_id found
        console.error(
          "No matching document found for transactionId:",
          transactionId
        );
        return false;
      }
    } catch (error) {
      console.error("Error Updating transaction document: ", error);
      return false;
    }
  },
});

// CREATING AND EXPORTING THE ORDERS STORE
export const ordersStore = create(orders_store);
