/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Alert from "./components/alert";
import Header from "../../components/header/header";
import { Link, useNavigate } from "react-router-dom";
import DashboardBreadcrump from "../../components/dashboard_breadcrump/dashboard_breadcrump";
import Footer from "../../components/footer/footer";
import { Modal, Button } from "react-bootstrap";
import "./cart.css";
import { isMobile } from "react-device-detect";
import { userStore } from "./../../store/user_store";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  formatDate,
  formatNumberWithCommas,
} from "../../services/helpers";
import { usePromoCodes } from "../../store/promocode_store";
import { useEvents } from "./../../store/event_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";
import CartTablesModal from "../../components/eventDetail/cartTableModel";

export default function Cart() {
  // ZUSTAND VARIABLES
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const cartItems = userStore((state) => state.cartItems);
  const getCartItems = userStore((state) => state.getCartItems);
  const removeCartItem = userStore((state) => state.removeCartItem);
  const clearCart = userStore((state) => state.clearCart);
  const updateCart = userStore((state) => state.updateCart);

  // const getPromoCodes = usePromoCodes((state) => state.getPromoCodes);
  const checkIfTicketHasPromoCode = useEvents(
    (state) => state.checkIfTicketHasPromoCode
  );

  const validatePromoCode = usePromoCodes((state) => state.validatePromoCode);
  const layout_page = useSettings((state) => state.layout_page);

  // REACT STATE VARIABLES
  const [numberOfTickets] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [ticektHasPromocode, setTicektHasPromocode] = useState({});
  const [selectedTables, setSelectedTables] = useState([]);

  // getting the selected tables back
  const getSelectedTables = (data) => {
    const selectedTableArray = Object.values(data);
    // const selectedTableArray = Object.keys(data);
    setSelectedTables(selectedTableArray);

    console.log("the selected tables are:", selectedTableArray);
  };

  // METHOD TO HANDLE CHECKING IF THE TICKETS HAVE PROMOCODES
  const handleCheckIfTicketHasPromoCode = async () => {
    cartItems.forEach(async (cartItem) => {
      console.log("THE TICKET ID: " + cartItem["ticket_id"]);
      var response = await checkIfTicketHasPromoCode(cartItem["ticket_id"]);
      console.log("THE TICKET HAS PROMO?: " + response);
      setTicektHasPromocode({
        ...ticektHasPromocode,
        [cartItem["ticket_id"]]: response,
      });
    });
  };

  // GET PROMOCODES
  useEffect(() => {
    handleCheckIfTicketHasPromoCode();
  }, []);

  // TOTAL
  const [total, setTotal] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [promoDetails, setPromoDetails] = useState({});
  const [ticketQuantities, setTicketQuantities] = useState([]);
  const [discountAmount, setDiscountAmount] = useState({});
  const [displayPromo, setDisplayPromo] = useState({});
  const [ticketTotal, setTicketTotal] = useState({});
  const [fees, setFees] = useState(0);

  // METHOD TO HANDLE TICKET TOTAL
  const calculateTicketTotal = (
    ticket_quantities,
    discount_amount,
    index,
    cartItem
  ) => {
    console.log("THE INDEX IS: " + index);
    console.log("THE TICKET QUANTITY: " + ticket_quantities[index]);
    console.log("THE CART ITEM:");
    console.log(cartItem);

    console.log(
      "TOTAL WITHOUT DISCOUNT: " + ticket_quantities[index] * cartItem?.price
    );

    var total =
      ticket_quantities[index] * cartItem?.price -
      (discount_amount[cartItem?.ticket_id] !== undefined
        ? discount_amount[cartItem?.ticket_id]
        : 0);
    console.log("THE TOTAL FOR THIS TICKET IS: " + total);
    var updatedTicketTotal = { ...ticketTotal, [index]: total };

    setTicketTotal(updatedTicketTotal);
    calculateTotal(updatedTicketTotal);
  };

  // // METHOD TO HANDLE DISPLAY PROMO
  const handleDisplayPromo = (index) => {
    setDisplayPromo({ ...displayPromo, [index]: !displayPromo[index] });
  };

  // METHOD TO VALIDATE PROMOCODE
  const handleValidatePromoCode = async (e, index, cartItem) => {
    e.preventDefault();

    console.log("THE TICKET ID: ", cartItem?.ticket_id);
    console.log("THE PROMO CODE IS: " + promoCode);
    console.log("THE EVENT ID HERE IS: " + cartItem?.event_id);

    var response = await validatePromoCode({
      promocode: promoCode,
      event_id: cartItem.event_id,
      ticket_id: cartItem?.ticket_id,
      email: user?.email,
    });

    console.log("THE PROMOCODE RESPONSE: " + response);

    if (response !== null && response) {
      displaySuccessToast("Promo code applied successfully");

      // UPDATE PROMO DETAILS FOR THIS TICKET
      setPromoDetails({ ...promoDetails, [cartItem.ticket_id]: response });

      // CALCULATE THE DISCOUNT AMOUNT
      calculateDiscountAmount(ticketQuantities, response, index, cartItem);
    } else {
      displayErrorToast("Invalid promo code");
    }
  };

  // METHOD TO CALCULATE THE DISCOUNT AMOUNT
  function calculateDiscountAmount(ticket_quantities, promo, index, cartItem) {
    console.log("CART ITEM IN DISCOUNT AMOUNT:");
    console.log(cartItem);

    var updatedDiscountAmount = 0;
    if (promo.discountType === "percentage" && promo.discount > 0) {
      var discount =
        (ticket_quantities[index] *
          cartItem?.price *
          parseFloat(promo.discount)) /
        100;

      console.log("THE DISCOUNT IS: " + discount);
      updatedDiscountAmount = {
        ...discountAmount,
        [cartItem?.ticket_id]: discount,
      };
      console.log(
        "THE UPDATED DISCOUNT AMOUNT: " + { ...updatedDiscountAmount }
      );
      setDiscountAmount(updatedDiscountAmount);
      calculateTicketTotal(
        ticket_quantities,
        updatedDiscountAmount,
        index,
        cartItem
      );
    } else if (promo.discountType === "amount" && promo.discount > 0) {
      updatedDiscountAmount = {
        ...discountAmount,
        [cartItem?.ticket_id]: parseFloat(promo.discount),
      };
      console.log(
        "THE UPDATED DISCOUNT AMOUNT: " + { ...updatedDiscountAmount }
      );
      setDiscountAmount(updatedDiscountAmount);
      calculateTicketTotal(
        ticket_quantities,
        updatedDiscountAmount,
        index,
        cartItem
      );

      console.log("THE DISCOUNT IS: " + promo.discount);
    }
  }

  // METHOD TO UPDATE THE TICKET QUANTITIES
  const handleOrder = (e, index) => {
    const value = parseInt(e.target.value) || 0;
    const updatedQuantities = [...ticketQuantities];
    updatedQuantities[index] = value;
    setTicketQuantities(updatedQuantities);
  };

  // METHOD TO INCREMENT THE TICKET QUANTITY
  const incrementQuantity = async (index, ticketQuantity, cartItem) => {
    // RECALCULATE THE DISCOUNT AMOUNT

    console.log("THE TICKET QUANTITY: " + ticketQuantity);
    console.log("THE INDEX: " + index);
    console.log("THE TICKET ID IS: " + cartItem?.ticket_id);
    console.log(cartItem);

    const updatedQuantities = [...ticketQuantities];
    updatedQuantities[index] = ticketQuantity + 1;
    console.log("THE UPDATED QUANTITIES: " + updatedQuantities);

    if (promoDetails[cartItem?.ticket_id]) {
      calculateDiscountAmount(
        updatedQuantities,
        promoDetails[cartItem?.ticket_id],
        index,
        cartItem
      );
    } else {
      calculateTicketTotal(updatedQuantities, discountAmount, index, cartItem);
    }

    const response = await updateCart({
      ticket_id: cartItem?.ticket_id,
      quantity: updatedQuantities[index],
      user_id: user?.user_id,
      selectedTables: selectedTables,
    });

    if (response) {
      displaySuccessToast("Cart updated successfully");
    } else {
      displayErrorToast("An error occurred while updating cart");
    }

    setTicketQuantities(updatedQuantities);
  };

  // METHOD TO DECREMENT THE TICKET QUANTITY
  const decrementQuantity = async (
    index,
    ticketQuantity,
    cartItem,
    tableId
  ) => {
    console.log("THE TICKET QUANTITY: " + ticketQuantity);
    console.log("THE INDEX: " + index);
    console.log("THE CART ITEM IN DECREMENT: ");
    console.log(cartItem);

    if (ticketQuantity > 0) {
      const updatedQuantities = [...ticketQuantities];
      updatedQuantities[index] = ticketQuantity - 1;

      // RECALCULATE THE DISCOUNT AMOUNT
      if (promoDetails[cartItem?.ticket_id]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        if (ticketQuantity > 1) {
          calculateDiscountAmount(
            updatedQuantities,
            promoDetails[cartItem?.ticket_id],
            index,
            cartItem
          );
        }
      } else {
        if (ticketQuantity > 1) {
          calculateTicketTotal(
            updatedQuantities,
            discountAmount,
            index,
            cartItem
          );
        }
      }

      // Decrement the selectedTables array
      const updatedSelectedTables =
        cartItem?.selectedTables?.filter((table) => table.id !== tableId) || [];
      console.log("THE UPDATED SELECTED TABLES: ", updatedSelectedTables);

      const response = await updateCart({
        ticket_id: cartItem?.ticket_id,
        quantity: updatedQuantities[index],
        user_id: user?.user_id,
        selectedTables: updatedSelectedTables,
      });

      if (response) {
        displaySuccessToast("Cart updated successfully");
      } else {
        displayErrorToast("An error occurred while updating cart");
      }

      setTicketQuantities(updatedQuantities);
    }
  };

  // METHOD TO CALCULATE THE TOTAL
  function calculateTotal(ticketTotal) {
    let mytotal = 0;
    for (let i = 0; i < cartItems.length; i++) {
      console.log("THE TICKET TOTAL: " + ticketTotal[i] + " FOR INDEX: " + i);
      if (ticketTotal[i] !== undefined) {
        mytotal += ticketTotal[i];
      }
    }

    console.log("THE TOTAL: " + mytotal);
    setTotal(mytotal);
  }

  // CLOSING THE CLEAR CART MODAL
  const handleClose = () => setShow(false);

  // OPENING THE TICKETS MODAL
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  // METHOD TO CLEAR THE CART
  const handleClearCart = async (e) => {
    e.preventDefault();
    const clearResponse = await clearCart(user?.user_id);

    if (clearResponse) {
      displaySuccessToast("Cart cleared successfully");
    } else {
      console.log("CART NOT CLEARED");
      displayErrorToast("Something went wrong clearing cart");
    }

    setShow(false);
  };

  const formatter = new Intl.NumberFormat("en-US");

  useEffect(() => {
    if (user && isAttendee) {
      getCartItems(user?.user_id);
    }
  }, []);

  // METHOD TO REMOVE ITEM FROM CART
  async function removeItemFromCart(e, index) {
    e.preventDefault();
    console.log("ITEM AT THIS INDEX:");
    console.log(cartItems[index]);

    const response = await removeCartItem({
      user_id: user?.user_id,
      cart_item: cartItems[index],
    });

    if (response) {
      displaySuccessToast("Item removed from cart");
      var newTicketQuantities = ticketQuantities.splice(index, 1);
      setTicketQuantities(newTicketQuantities);
      calculateTotal(ticketTotal);
    } else {
      displaySuccessToast("Item not removed from cart");
      setTicketQuantities(newTicketQuantities);
      calculateTotal(ticketTotal);
    }
  }

  // INITIALIZING USE-NAVIGATE
  const navigate = useNavigate();

  //TABLE FUNCTIONS

  //showing the table model to select anothet table
  const [modalShows, setModalShows] = useState({});
  const toggleModal = (
    item,
    table_index,
    table_name,
    tableQuantity,
    venue_id
  ) => {
    setModalShows({
      show: !modalShows.show,
      item,
      table_index,
      table_name,
      tableQuantity,
      venue_id,
    });
  };

  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <DashboardBreadcrump title={"My Cart"} />

        {/* MY CART MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE NAV */}
              {!isMobile && (
                <AttendeeSideNav
                  activeTab={"cart"}
                  numTickets={numberOfTickets}
                />
              )}

              {!cartItems || cartItems?.length === 0 ? (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <Alert message={"The Shopping Cart is Empty"} page={"cart"} />
                </div>
              ) : (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <div className="alert alert-warning alert-icon">
                    <i className="fas fa-info" /> Your tickets are not reserved
                    until checkout, the quantity you intend to buy might not be
                    available if you do not proceed to checkout right away
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="b mb-0">
                        {cartItems?.length} tickets found in your cart
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <form method="post">
                          <table className="table shopping-cart-wrap">
                            <thead className="text-muted">
                              <tr>
                                <th scope="col">Event / Ticket</th>
                                <th scope="col" width={100}>
                                  Price
                                </th>
                                <th scope="col" width={100}>
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  width={"15%"}
                                  className="text-center"
                                >
                                  Subtotal
                                </th>
                                <th className="text-center">
                                  <i className="fas fa-cog" />
                                </th>
                              </tr>
                            </thead>
                            {/* CART ITEMS */}
                            {cartItems?.map((item, index) => {
                              if (!ticketQuantities[index]) {
                                ticketQuantities[index] = item?.quantity;
                              }

                              if (
                                ticketTotal[index] === undefined ||
                                ticketTotal[index] === null
                              ) {
                                calculateTicketTotal(
                                  ticketQuantities,
                                  discountAmount,
                                  index,
                                  item
                                );
                              }

                              var hasPromocode =
                                ticektHasPromocode[item["ticket_id"]];
                              console.log(
                                "DOES THIS TICKET HAVE A PROMOCODE? " +
                                  hasPromocode
                              );

                              // GETTING THE TICKET QUANTITY
                              var ticketQuantity =
                                ticketQuantities[index] ?? item?.quantity;

                              return (
                                <tbody>
                                  <tr
                                    key={index}
                                    style={{ borderColor: "white" }}
                                  >
                                    <td>
                                      <figure className="media h-100">
                                        <div className="img-wrap my-auto">
                                          <img
                                            src={item?.event_image}
                                            className="img-thumbnail img-sm img-lazy-load b-loaded"
                                            alt={item?.event_name}
                                          />
                                        </div>

                                        <figcaption className="media-body my-auto ">
                                          <Link
                                            style={{
                                              color: layout_page?.primary_color,
                                            }}
                                            to={
                                              "/event_details/" + item?.event_id
                                            }
                                          >
                                            <h6 className="title mb-2">
                                              {item?.event_name}
                                            </h6>
                                          </Link>

                                          <dl className="dlist-inline small">
                                            <dt />
                                            <dd className="mr-0">
                                              {item?.ticket_name && (
                                                <p>{item.ticket_name} ticket</p>
                                              )}
                                              {item?.table_name && (
                                                <p>{item.table_name} table</p>
                                              )}
                                              <i
                                                className="fas fa-info-circle ml-2"
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                data-toggle="tooltip"
                                                data-original-title="Get a front seat and a popcorn bag"
                                              />
                                            </dd>
                                          </dl>
                                          <dl className="dlist-inline small">
                                            <dt className="mr-2">When: </dt>
                                            <dd>
                                              {formatDate(
                                                convertTimestampToDateTime(
                                                  item?.event_start_date
                                                )
                                              )}
                                            </dd>
                                          </dl>
                                          <dl className="dlist-inline small">
                                            <dt className="mr-2">Where: </dt>
                                            <dd>{item?.venue}</dd>
                                          </dl>
                                          <dl className="dlist-inline small mr-2">
                                            <dt className="mr-2">
                                              Organizer:{" "}
                                            </dt>
                                            <dd>
                                              <Link
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                to={
                                                  "/organizer_details/" +
                                                  item?.organizer?.id
                                                }
                                                taget="_blank"
                                              >
                                                {
                                                  item?.organizer
                                                    ?.organizer_name
                                                }
                                              </Link>
                                            </dd>
                                          </dl>

                                          {item?.is_table_ticket && (
                                            <dl className="dlist-inline small mr-2">
                                              <dt className="mr-2">
                                                Selected Tables:{" "}
                                              </dt>
                                              <dd>
                                                {item?.selectedTables?.map(
                                                  (table, index_label) => (
                                                    <span key={index_label}>
                                                      {table?.tableLabel}
                                                      {index_label <
                                                        item?.selectedTables
                                                          ?.length -
                                                          1 && ", "}
                                                    </span>
                                                  )
                                                )}
                                              </dd>
                                            </dl>
                                          )}
                                        </figcaption>
                                      </figure>
                                    </td>
                                    {/* PRICE */}
                                    <td
                                      className="pb-0 mb-0"
                                      style={{ height: "20px" }}
                                    >
                                      <div className="price-wrap">
                                        <span
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                          className="price-new"
                                        >
                                          {" "}
                                          UGX {formatter.format(item?.price)}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="input-group  bootstrap-touchspin bootstrap-touchspin-injected">
                                        <input
                                          type="text"
                                          className="form-control touchspin-integer bg-white eventdate-ticket-qte"
                                          name={index}
                                          data-min={1}
                                          data-max={500}
                                          defaultValue={ticketQuantity}
                                          value={ticketQuantity}
                                          onChange={(e) =>
                                            handleOrder(e, index)
                                          }
                                          disabled
                                        />
                                        <span className="input-group-btn-vertical">
                                          {/* INCREMENT BUTTON */}
                                          <button
                                            className="btn text-white bootstrap-touchspin-up custom-button"
                                            style={{
                                              backgroundColor:
                                                layout_page?.primary_color,
                                            }}
                                            type="button"
                                            onClick={(e) => {
                                              item.is_table_ticket
                                                ? toggleModal(
                                                    item,
                                                    index,
                                                    item.table_name,
                                                    ticketQuantity,
                                                    item.venue_id
                                                  )
                                                : incrementQuantity(
                                                    e,
                                                    index,
                                                    ticketQuantity,
                                                    item
                                                  );
                                            }}
                                          >
                                            +
                                          </button>

                                          {/* DECREMENT BUTTON */}
                                          <button
                                            className="btn text-white bootstrap-touchspin-down custom-button"
                                            style={{
                                              backgroundColor:
                                                layout_page?.primary_color,
                                            }}
                                            type="button"
                                            onClick={(e) => {
                                              item.is_table_ticket
                                                ? toggleModal(
                                                    item,
                                                    index,
                                                    item.table_name,
                                                    ticketQuantity,
                                                    item.venue_id
                                                  )
                                                : decrementQuantity(
                                                    e,
                                                    index,
                                                    ticketQuantity,
                                                    item
                                                  );
                                            }}
                                          >
                                            -
                                          </button>
                                        </span>
                                      </div>
                                    </td>
                                    {/* SUBTOTAL */}
                                    <td className="text-center">
                                      <div className="price-wrap">
                                        <div
                                          style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          UGX{" "}
                                          {formatter.format(
                                            ticketQuantity *
                                              parseInt(item?.price ?? "0")
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    {/* REMOVE ITEM FROM CART */}
                                    <td
                                      className="text-center"
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                        onClick={(e) =>
                                          removeItemFromCart(e, index)
                                        }
                                        data-toggle="tooltip"
                                        data-original-title="Remove"
                                      >
                                        <i className="far fa-minus-square" />
                                      </div>
                                    </td>
                                  </tr>

                                  {/* PROMOCODE ROW*/}
                                  <tr
                                    style={{
                                      marginTop: "-90px",
                                      paddingTop: "-20px",
                                    }}
                                  >
                                    <td colSpan={1}></td>
                                    <td
                                      colSpan={4}
                                      style={{ paddingTop: 0, marginTop: 0 }}
                                    >
                                      {/* PROMO CODE CHECKBOX */}
                                      {/* CHECKING IF THERE IS A PROMOCODE FOR THIS TICKET */}
                                      {hasPromocode && (
                                        <div
                                          className="form-check"
                                          style={{ paddingTop: "-30px" }}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={"flexCheckDefault" + index}
                                            checked={displayPromo[index]}
                                            onChange={(e) =>
                                              handleDisplayPromo(index)
                                            }
                                            style={{
                                              color: "#f67611",
                                              borderColor: "#f67611",
                                              backgroundColor: "white",
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"flexCheckDefault" + index}
                                          >
                                            I have a promo code
                                          </label>
                                        </div>
                                      )}

                                      {/* PROMO INPUT */}
                                      {displayPromo[index] && (
                                        <div className="mt-3 mb-0">
                                          <div className="form-group">
                                            {/* PROMOCODE INPUT */}
                                            <input
                                              type="text"
                                              className="form-control mb-2"
                                              placeholder="Enter promo code"
                                              onChange={(e) =>
                                                setPromoCode(e.target.value)
                                              }
                                              style={{
                                                backgroundColor: "white",
                                                boxShadow:
                                                  "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                                borderRadius: "15px",
                                                height: "35px",
                                              }}
                                            />

                                            {/* PROMOCODE BUTTON */}
                                            <div
                                              className="text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout btn-promo"
                                              onClick={(e) =>
                                                handleValidatePromoCode(
                                                  e,
                                                  index,
                                                  item
                                                )
                                              }
                                              style={{
                                                backgroundColor: "white",
                                                border: "1px solid black",
                                                color: "black",
                                                fontWeight: "bold",
                                                fontSize: "13px",
                                                height: "35px",
                                                transition:
                                                  "background-color 0.5s ease-in",
                                              }}
                                            >
                                              Apply Promo Code
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {/* TICKET GROSS */}
                                      {displayPromo[index] && (
                                        <div className="row text-dark mt-4">
                                          <div className="col-7">
                                            <h6
                                              style={{
                                                color:
                                                  layout_page?.primary_color,
                                              }}
                                              className="text-uppercase fw-bolder "
                                            >
                                              TICKET GROSS:
                                            </h6>
                                          </div>

                                          {/* ALIGN COLUMN CONTENTS RIGHT */}
                                          <div className="col-5 text-right">
                                            <h6
                                              style={{
                                                color:
                                                  layout_page?.primary_color,
                                              }}
                                              className="text-uppercase fw-bolder"
                                            >
                                              UGX:{" "}
                                              {formatNumberWithCommas(
                                                ticketQuantities[index] *
                                                  item?.price
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                      )}

                                      {/* TICKET DISCOUNT */}
                                      {displayPromo[index] &&
                                        promoDetails[item?.ticket_id] && (
                                          <div className="row">
                                            <div className="col-7 mb-0 pb-0">
                                              <h6
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                className="text-uppercase fw-bolder "
                                              >
                                                DISCOUNT:
                                              </h6>
                                              <p style={{ fontSize: "10px" }}>
                                                {
                                                  promoDetails[item?.ticket_id]
                                                    ?.description
                                                }{" "}
                                                (promocode name)
                                              </p>
                                            </div>

                                            <div className="col-5 text-right">
                                              <h6
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                className="text-uppercase fw-bolder "
                                              >
                                                UGX:{" "}
                                                {formatNumberWithCommas(
                                                  discountAmount[
                                                    item?.ticket_id
                                                  ] ?? 0
                                                )}
                                              </h6>
                                            </div>
                                          </div>
                                        )}

                                      {/* TICKET SUBTOTAL */}
                                      {displayPromo[index] && (
                                        <div
                                          className="row "
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                        >
                                          <div className="col-7">
                                            <h6 className="text-uppercase fw-bolder ">
                                              TICKET SUBTOTAL:
                                            </h6>
                                          </div>

                                          {/* ALIGN COLUMN CONTENTS RIGHT */}
                                          <div className="col-5 text-right">
                                            <h6 className="text-uppercase fw-bolder ">
                                              UGX:{" "}
                                              {formatNumberWithCommas(
                                                ticketTotal[index] ?? 0
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                      {/* some bottom spacing */}
                                      <div className="pb-2"></div>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                            {/* SHOW TABLE MODEL */}
                            <CartTablesModal
                              show={modalShows.show}
                              onHide={() => toggleModal()}
                              venue_id={modalShows.venue_id}
                              table_name={modalShows.table_name}
                              incrementTableQuantity={incrementQuantity}
                              decrementTableQuantity={decrementQuantity}
                              item={modalShows.item}
                              table_index={modalShows.table_index}
                              tableQuantity={modalShows.tableQuantity}
                              getSelectedTables={getSelectedTables}
                              user_id={user?.user_id}
                            />

                            {/* FOOOTER OF CART TABLE */}
                            <tfoot>
                              {/* GENERAL FEES */}
                              <tr>
                                <td colSpan={2} className="text-right"></td>

                                <td colSpan={2} className="text-left">
                                  <div className="price-wrap py-">
                                    <var className="price">
                                      <span
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                        className="b text-bold"
                                      >
                                        Fees:
                                      </span>
                                    </var>
                                  </div>
                                </td>

                                <td colSpan={1} className="text-right">
                                  <div className="price-wrap py-auto">
                                    <span
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      className="price"
                                    >
                                      UGX {formatter.format(fees)}
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              {/* TOTAL */}
                              <tr>
                                <td colSpan={2} className="text-right"></td>

                                <td colSpan={2} className="text-left">
                                  <div className="price-wrap py-">
                                    <var className="price">
                                      <span
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                        className="b text-bold"
                                      >
                                        Grand Total:
                                      </span>
                                    </var>
                                  </div>
                                </td>

                                <td colSpan={1} className="text-right">
                                  <div className="price-wrap py-auto">
                                    <span
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      className="price"
                                    >
                                      UGX {formatter.format(total)}
                                    </span>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={5} className="text-right">
                                  <Link
                                    to="/browse_events"
                                    className="btn btn-outline-primary mr-3"
                                  >
                                    <i className="fas fa-ticket-alt" /> Buy more
                                    tickets
                                  </Link>

                                  <span
                                    className="btn btn-outline-primary mr-3 d-none"
                                    onClick={() => clearCart(user?.user_id)}
                                  >
                                    <i className="fas fa-sync" /> Update cart
                                  </span>

                                  <span
                                    onClick={(e) => {
                                      handleShow(e);
                                    }}
                                    className="btn btn-outline-primary mr-3"
                                  >
                                    <i className="far fa-minus-square" /> Empty
                                    cart
                                  </span>

                                  {/* CONFIRM CLEAR CART MODAL */}
                                  <Modal
                                    show={show}
                                    onHide={handleClose}
                                    size="md"
                                    centered
                                  >
                                    {/* MODAL HEADER */}
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        <i className="fas fa-warning" /> Are You
                                        Sure you want to proceed?
                                      </Modal.Title>
                                    </Modal.Header>

                                    {/* MODAL BODY */}
                                    <Modal.Body>
                                      <p>You are about to clear your cart.</p>
                                    </Modal.Body>

                                    {/* MODAL FOOTER */}
                                    <Modal.Footer>
                                      <Button variant="" onClick={handleClose}>
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="primary"
                                        style={{
                                          backgroundColor:
                                            layout_page?.primary_color,
                                        }}
                                        onClick={handleClearCart}
                                      >
                                        Confirm
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>

                                  <button
                                    onClick={() => {
                                      var grossTotal = 0;

                                      for (
                                        var i = 0;
                                        i < ticketQuantities.length;
                                        i++
                                      ) {
                                        grossTotal +=
                                          (ticketQuantities[i] ??
                                            cartItems[i]?.quantity) *
                                          parseInt(cartItems[i]?.price ?? "0");
                                      }

                                      navigate("/checkout", {
                                        state: {
                                          totalAmount: total,
                                          promoDetails: promoDetails,
                                          gross: grossTotal,
                                        },
                                      });
                                    }}
                                    className="btn text-white"
                                    style={{
                                      backgroundColor:
                                        layout_page?.primary_color,
                                    }}
                                  >
                                    Checkout
                                    <i className="fas fa-chevron-right ml-2" />
                                  </button>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer />
      </div>
    );
  }
}
