import React, { useState, useEffect } from "react";
import Header from "./../../../../components/header/header";
import Footer from "./../../../../components/footer/footer";
import Sidenavbar from "../../components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumpOrg from "../../components/breadcrumporganizer";
import { isMobile } from "react-device-detect";
import Loader from "../../../../components/loader/loader";
import PopingOutMyScanners from "./PopingOutMyScanners";
import {
  convertTimestampToDateTime,
  formatDate,
} from "./../../../../services/helpers";
import { userStore } from "../../../../store/user_store";
import { useSettings } from "../../../../store/setting_store";
import CustomPagination from "../../../../components/pagination/Pagination";

export default function MyScanners() {
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const getUserScanners = userStore((state) => state.getUserScanners);
  const userScanners = userStore((state) => state.userScanners);
  const layout_page = useSettings((state) => state.layout_page);

  console.log("the scanners are:", userScanners);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // FETCHING  SCANNER DATA
    const fetchData = async () => {
      await getUserScanners(user?.user_id);
    };
    fetchData();
  }, [userScanners?.length]);

  //filtering logic
  const [scannersFilter, setScannersFilter] = useState("");
  const filteredScanners = userScanners?.filter((scanner) => {
    return (
      !scannersFilter ||
      (scanner.name &&
        scanner.name.toLowerCase().includes(scannersFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedScanners = filteredScanners?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [scannerPerPage] = useState(10);
  //get current events
  const indexOfLastscanner = currentPage * scannerPerPage;
  const indexOfFastscanner = indexOfLastscanner - scannerPerPage;
  const current_scanners = sortedScanners?.slice(
    indexOfFastscanner,
    indexOfLastscanner
  );

  // DISPLAYING THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer) {
    navigate("/access_denied");
  }

  return (
    <div>
      <Header />
      <BreadCrumpOrg title={"My Scanners"} />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"my_scanners"} />}
            </aside>

            {/* MAIN CONTENT */}
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="box shadow-none bg-gray mb-4">
                <div className="row">
                  <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0 d-flex align-items-center justify-content-start ">
                    <span className="text-muted" style={{ marginTop: -10 }}>
                      {userScanners?.length} result(s) found
                    </span>
                  </div>
                  {/* SEARCH */}
                  <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4 ">
                    <label htmlFor="search">Search</label>
                    <input
                      type="text"
                      className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                      id="search"
                      name="search"
                      value={scannersFilter}
                      onChange={(e) => setScannersFilter(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                    <label htmlFor="sortable-select" className="display-inline">
                      Sort by
                      <select
                        id="sortable-select"
                        className="form-control display-inline-block bg-white select2 ml-3"
                        data-placeholder="Select an option"
                        style={{ width: "50%" }}
                        value={`${sortCriteria}&direction=${sortDirection}`}
                        onChange={(e) => {
                          const [criteria, direction] =
                            e.target.value.split("&direction=");
                          setSortCriteria(criteria);
                          setSortDirection(direction);
                        }}
                      >
                        <option
                          value={`created_at&direction=desc`}
                          selected={
                            sortCriteria === "created_at" &&
                            sortDirection === "desc"
                          }
                        >
                          Creation date (desc)
                        </option>

                        <option
                          value={`created_at&direction=asc`}
                          selected={
                            sortCriteria === "created_at" &&
                            sortDirection === "asc"
                          }
                        >
                          Creation date (asc)
                        </option>
                      </select>
                    </label>
                    <Link
                      to="/addanewscanner"
                      className="btn text-white ml-3"
                      style={{ backgroundColor: layout_page?.primary_color }}
                      data-toggle="tooltip"
                      title="Add a new venue"
                    >
                      <i className="fas fa-plus" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* APP ALERTS IF THE SCANNERS ARE EMPTY*/}
              {userScanners?.length === 0 ? (
                <>
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" /> No Scanners
                    found
                  </div>
                </>
              ) : (
                <>
                  {/* TABLE OF SCANNERS */}
                  <div className="table-responsive" style={{ width: "100%" }}>
                    <table className="w-100 table table-hover table-vcenter">
                      <thead>
                        <tr>
                          <th>Name / Username</th>
                          <th>Creation date / Last login</th>
                          <th> Events Count</th>
                          {/* <th>Status</th> */}
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {current_scanners?.map((scanner, index) => {
                          return (
                            <tr key={scanner?.id}>
                              {/* SCANNER NAME */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                    >
                                      {scanner?.name}
                                    </Link>
                                  </div>
                                </div>
                              </td>

                              {/* DATE ADDED */}
                              <td>
                                <div>
                                  <p className=" text-sm">
                                    Created At:{" "}
                                    {formatDate(
                                      convertTimestampToDateTime(
                                        scanner?.created_at
                                      )
                                    )}
                                  </p>
                                </div>
                              </td>

                              {/* EVENTS COUNT */}
                              <td>
                                <div>
                                  <p>0</p>
                                </div>
                              </td>

                              <td className="text-center">
                                <PopingOutMyScanners
                                  scanner_id={scanner?.id}
                                  setIsLoading={setIsLoading}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* PAGINATION */}
          {sortedScanners?.length > 10 && (
            <CustomPagination
              setCurrentPage={setCurrentPage}
              totalPages={Math.ceil(sortedScanners?.length / scannerPerPage)}
            />
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
}
