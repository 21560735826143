import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { tableStore } from "../../../../../store/table_store";
import { shallow } from "zustand/shallow";
import {
  displayInfoToast,
  generateUniqueId,
} from "../../../../../services/helpers";

function AddTableModal({ handleClose, show, tableType_id, venue_id }) {
  const [tableLabel, setTableLabel] = useState("");
  const [numberOfSeats, setNumberOfSeats] = useState("");
  const [selectedTableType, setSelectedTableType] = useState("");

  //getting the room to edit

  const tables = tableStore((state) =>
    state.tableLabels.filter(
      (tableLabel) => tableLabel.venue_tableType_id === tableType_id
    )
  );
  const addTableLabel = tableStore((state) => state.addTableLabel);
  const venueTableType = tableStore((state) =>
    state.venueTableTypes.find(
      (venueTableType) => venueTableType.id === tableType_id
    )
  );
  const editVenueTableType = tableStore((state) => state.editVenueTableType);

  //addding table label
  const handleAddTableLabel = () => {
    try {
      const selectedTable = venueTableType.tableTypes.find(
        (table) => table.table_type === selectedTableType
      );
      const newTable = {
        id: generateUniqueId(),
        table_id: selectedTable.id,
        venue_tableType_id: tableType_id,
        numberOfSeats: numberOfSeats,
        tableLabel: tableLabel,
        table_type: selectedTableType,
        isBooked: false,
        position: { x: 0, y: 0 },
      };
      addTableLabel(newTable);
    } catch (error) {
      displayInfoToast("Enter all the required inputs");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className=" text-danger">Adding Tables</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="tableLabel" className="fw-bold text-lg">
              Add a table
            </label>
            <span className=" d-flex text-nowrap align-items-center gap-2">
              <p className=" w-25">Table Label</p>
              <input
                type="text"
                name="tableLabel"
                className="form-control  my-2 h-25 "
                placeholder="Table label"
                id="tableLabel"
                onChange={(e) => setTableLabel(e.target.value)}
                required="required"
              />
            </span>

            <span className=" d-flex text-nowrap align-items-center gap-2 ">
              <p className=" w-25">Table seats</p>
              <select
                value={numberOfSeats}
                onChange={(e) => setNumberOfSeats(e.target.value)}
                className="form-control my-2 h-25 form-select"
                id="numberOfSeats"
              >
                <option value="">Please Select</option>

                {Array.from({ length: 10 }, (_, index) => index * 2 + 2).map(
                  (value) => (
                    <option key={value} value={value}>
                      {value} seats
                    </option>
                  )
                )}
              </select>
            </span>
            <span className=" d-flex text-nowrap align-items-center gap-2">
              <p className=" w-25">Table Type</p>
              <select
                className="form-select form-control h-25 my-2"
                aria-label="Default select example"
                value={selectedTableType}
                onChange={(e) => setSelectedTableType(e.target.value)}
              >
                <option value="">Please Select</option>
                {venueTableType?.tableTypes?.map((table) => (
                  <>
                    <option
                      key={table?.id}
                      value={table?.table_type}
                      data-tableid={table?.id}
                    >
                      {table?.table_type}
                    </option>
                  </>
                ))}
              </select>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              handleClose();
              handleAddTableLabel();
            }}
          >
            submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTableModal;
