import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useEvents } from "./../../store/event_store";
import useQueryStore from "../../store/query_store";
import CustomPagination from "../pagination/Pagination";
import { useSettings } from "../../store/setting_store";

function FeaturedCategories() {
  const navigate = useNavigate();
  const getEventCategories = useEvents((state) => state.getFeaturedCategories);
  const setCategory = useQueryStore((state) => state.setCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );
  const eventCategories = useEvents((state) => state.eventCategories);

  const home_page = useSettings((state) => state.home_page);

  //states
  const [featuredCategories, setFeaturedCategories] = useState([]);

  const handleNavigation = (eventCategory) => {
    setSelectedCategory({
      value: eventCategory?.id,
      label: eventCategory?.name,
    });
    setCategory(eventCategory?.id);
    navigate("/browse_events");
  };

  useEffect(() => {
    const getCategories = async () => {
      const categories = await getEventCategories();
      setFeaturedCategories(categories);
    };
    getCategories();
  }, [eventCategories.length]);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(12);
  //get current events
  const indexOfLastcategories = currentPage * categoriesPerPage;
  const indexOfFastcategories = indexOfLastcategories - categoriesPerPage;
  const current_categories = featuredCategories?.slice(
    indexOfFastcategories,
    indexOfLastcategories
  );

  return (
    <section className="section-content padding-y-lg">
      <div className="container">
        <header className="section-headin mb-5">
          <h4 className="title-section text-center text-muted text-uppercase">
            Featured categories
          </h4>
        </header>
        <div className="row-sm">
          {/* CATEOGRY */}
          {current_categories &&
            current_categories.map((category, index) => (
              <div className="col-md-3 col-sm-3" key={category?.id}>
                <div
                  onClick={() => handleNavigation(category)}
                  className="zoom-wrap cursor-pointer"
                >
                  <div className="card-banner mb-3">
                    <div
                      className="card-body zoom-in img-lazy-load b-loaded"
                      style={{
                        height: "250px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundImage: `url("${category?.image}")`,
                      }}
                    ></div>
                    <div className="text-bottom">
                      <h4 className="title">{category?.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* PAGINATION */}
      {featuredCategories?.length > 12 && (
        <CustomPagination
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(featuredCategories?.length / categoriesPerPage)}
        />
      )}
    </section>
  );
}

export default FeaturedCategories;
