import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import {
  displayInfoToast,
  displaySuccessToast,
  generateUniqueId,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { Timestamp } from "firebase/firestore";
import BreadcrumpAdmin from "../components/breadcrump_admin";
import Room from "./Room";
import { AddButton } from "./Components";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import { useVenues } from "../../../store/venues_store";
import { tableStore } from "../../../store/table_store";
import { MdAdd } from "react-icons/md";

export default function ManageTablesBookings() {
  const navigate = useNavigate();
  const isAdmin = userStore((state) => state.isAdmin);
  const getVenues = useVenues((state) => state.getVenues);
  const addTableType = tableStore((state) => state.addVenueTableTypes);

  //HANDLING AA ROOM
  const [venues_list, setVenuesList] = useState([]);
  const [venue, setVenue] = useState("");
  const [isloading, setIsloading] = useState("");

  //getting all the venues from the database
  useEffect(() => {
    const get_venues_list = async () => {
      const returnedVenues = await getVenues();
      const venues = returnedVenues.map((venue) => {
        return {
          value: venue.id,
          label: venue.name,
        };
      });
      setVenuesList(venues);
    };
    //or
    // const get_venues_list = async () => {
    //   const returnedVenues = await getVenues();
    //   const venues = [];
    //   for (let i = 0; i < returnedVenues.length; i++) {
    //     venues.push({
    //       value: returnedVenues[i].id,
    //       label: returnedVenues[i].name,
    //     });
    //   }
    //   setVenuesList(venues);
    // };
    get_venues_list();
  }, []);

  //handling errors.
  function validateForm() {
    const errors = {};

    if (!venue) {
      errors.venue = "Please select the venue";
    }
    return errors;
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    setIsloading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      await addTableType({
        id: generateUniqueId(),
        venue: venue,
        room_image: "",
        tableTypes: [],
        stage_position: { x: 0, y: 0 },
        tables: [],
        created_at: Timestamp.fromDate(new Date()),
        updated_at: Timestamp.fromDate(new Date()),
      });
      setIsloading(false);
      displaySuccessToast("Table added successfully");

      setVenue("");
    } else {
      displayInfoToast("Please fill in the required fields");
    }
    setIsloading(false);
  };

  //displaying the loader
  if (isloading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        <BreadcrumpAdmin title="Manage Tables Bookings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_tables_bookings"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/*FORM TO CAPTURE DATA  */}
                    <form>
                      <div className="form-group">
                        <label htmlFor="room" className="required">
                          Select venue to add tables
                        </label>
                        <form className="">
                          <div className="row gap-2 align-items-center">
                            <div className="col-md-5 ">
                              <SearchableDropdown
                                value={venue}
                                options={venues_list}
                                onChange={(e) => setVenue(e)}
                                isMulti={false}
                                placeholder="Select venue"
                              />
                            </div>
                            <span
                              className="col-2"
                              onClick={handleAdd}
                              type="submit"
                            >
                              <AddButton
                                text="Add"
                                icon={<MdAdd size={20} />}
                              />
                            </span>
                          </div>
                        </form>
                      </div>
                      {/* RROM COMPONENT */}
                      <div className="mt-2">
                        <Room />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
