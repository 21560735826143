import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import PopingOutVenueTypes from "./PopingOutvenueTypes";

import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import VenueCount from "./venueCount";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function VenueTypes() {
  const getVenuetypes = useVenues((state) => state.getVenuetypes);
  const venuetypes = useVenues((state) => state.venuetypes);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  /////////////FETCHING VENUETYPES//////////////////////
  useEffect(() => {
    async function fetchData() {
      await getVenuetypes();
    }
    fetchData();
  }, [venuetypes.length]);

  //GETTING NUMBER OF VENUE TYPES
  const numofvenuetypes = venuetypes.length;
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  //filtering logic
  const [venueTypeFilter, setVenueTypeFilter] = useState("");

  const filteredVenueTypes = venuetypes.filter((venuetype) => {
    return (
      !venueTypeFilter ||
      (venuetype.venue_type &&
        venuetype.venue_type
          .toLowerCase()
          .includes(venueTypeFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("added_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedVenueTypes = filteredVenueTypes.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "added_at") {
      const dateA = a.added_at;
      const dateB = b.added_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [venuetypesPerPage] = useState(20);
  //get current events
  const indexOfLastvenuetypes = currentPage * venuetypesPerPage;
  const indexOfFastvenuetypes = indexOfLastvenuetypes - venuetypesPerPage;
  const current_venuetypes = sortedVenueTypes?.slice(
    indexOfFastvenuetypes,
    indexOfLastvenuetypes
  );

  //display the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Venue Types" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_venue_types"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {numofvenuetypes} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={venueTypeFilter}
                        onChange={(e) => setVenueTypeFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`added_at&direction=desc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`added_at&direction=asc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-new-venue-type"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new venue type"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Venues count</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_venuetypes.map((venueType, index) => {
                              return (
                                <tr key={venueType?.id}>
                                  <td>{venueType?.venue_type}</td>
                                  <td>
                                    <VenueCount venue_type_id={venueType?.id} />
                                  </td>
                                  <td>
                                    {venueType?.isVisible === true ? (
                                      <span className=" badge badge-success">
                                        <i className="fas fa-eye fa-fw" />{" "}
                                        Visible
                                      </span>
                                    ) : (
                                      <span className=" badge badge-warning">
                                        <i className="fas fa-eye-slash fa-fw" />{" "}
                                        Invisible
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <PopingOutVenueTypes
                                      venue_type_id={venueType?.id}
                                      setIsLoading={setIsLoading}
                                      isVisible={venueType?.isVisible}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION OF THE PAGE */}
                    {sortedVenueTypes?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedVenueTypes?.length / venuetypesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
