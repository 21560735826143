import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { useBlogs } from "../../../store/blog_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutManageBlogCategories = ({ blog_category_id, status }) => {
  const [loading, setLoading] = useState(false);
  const deleteBlogPostCategory = useBlogs(
    (state) => state.deleteBlogPostCategory
  );
  const updateBlogpostCategories = useBlogs(
    (state) => state.updateBlogpostCategories
  );
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE AMENITY
  async function handleDeleteBlogCategory(e) {
    e.preventDefault();
    setLoading(true);
    const response = await deleteBlogPostCategory(blog_category_id);
    if (response) {
      displaySuccessToast("🦄 The blog category has been deleted succeesfully");
    } else {
      displayErrorToast(
        "Something went wrong while deleting the blog category"
      );
    }
    setLoading(false);
  }
  if (loading) {
    return <Loader />;
  }

  //toggling the status of the blog category
  const handleStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateBlogpostCategories(blog_category_id, {
      status: status === "visible" ? "invisible" : "visible",
    });
    if (status === "invisible") {
      setLoading(false);
      displaySuccessToast("The blog category status has been published");
    } else {
      setLoading(false);
      displayWarningToast(
        "The blog category status has been hidden and it will not be viewed"
      );
    }
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT CATEGORY */}

          <Link to={"/edit_blog_category/" + blog_category_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}

          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {status === "visible" ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE BLOG CATEGORY */}
          <DeleteComponentModal
            itemName="Blog Category"
            handleDelete={handleDeleteBlogCategory}
          />{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutManageBlogCategories;
