import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useSettings } from "../../../store/setting_store";
import { userStore } from "../../../store/user_store";

export default function VenueOrganizer({ organizer_ID }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const layout_page = useSettings((state) => state.layout_page);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);

  const [organizer, setOrganizer] = React.useState();
  //FETCHING VENUES
  React.useEffect(() => {
    // getting the organizer
    const getOrganizer = async () => {
      const organizerDetails = await getOrganizerDetails(organizer_ID);
      setOrganizer(organizerDetails);
    };
    getOrganizer();
  }, []);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Link
          style={{
            color: layout_page?.primary_color,
          }}
          to={"/organizer_details/" + organizer_ID}
          data-toggle="tooltip"
          title=""
          data-original-title="View organizer"
        >
          <span className=" text-sm" style={{ fontSize: 12 }}>
            {organizer?.organizer_name}
          </span>
        </Link>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: 1,
            color: "white",
            backgroundColor: layout_page?.primary_color,
            fontSize: "11px",
          }}
        >
          view organizer
        </Typography>
      </Popover>
    </div>
  );
}
