import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import app from '../Firebase';

const db = getFirestore(app);

export async function addPaymentMethod({
  user_id,
  payment_method,
  account_number,
}) {
  const userPaymentMethods = collection(db, 'user_payment_methods');
  const q = query(userPaymentMethods, where('user_id', '==', user_id));

  const docSnap = await getDocs(q);

  console.log('size', docSnap.size);

  if (docSnap.size === 0) {
    await addDoc(userPaymentMethods, {
      user_id,
      payment_methods: { [payment_method]: { account_number } },
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
    });
  } else {
    const _docId = docSnap.docs[0].id;
    const docRef = doc(userPaymentMethods, _docId);
    await updateDoc(docRef, {
      payment_methods: { [payment_method]: { account_number } },
      updated_at: Timestamp.fromDate(new Date()),
    });
  }

  const _docSnap = await getDocs(q);
  const _docRef = _docSnap.docs[0];
  return { ..._docRef.data(), id: _docRef.id };
}

export async function createPayoutRequest({
  user_id,
  payment_method,
  account_number,
  amount,
  event_id,
}) {
  const _collection = collection(db, 'payout_requests');

  const docRef = await addDoc(_collection, {
    user_id,
    account_number,
    payment_method,
    amount,
    event_id,
    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
  });

  const _docRef = doc(_collection, docRef.id);
  const _docSnap = await getDoc(_docRef);
  return _docSnap;
}

export async function fetchUserPayoutRequests({ user_id }) {
  const _collection = collection(db, 'payout_requests');
  const q = query(_collection, where('user_id', '==', user_id));
  const docsSnap = await getDocs(q);

  const userPayoutRequests = [];

  if (docsSnap.size > 0) {
    docsSnap.docs.forEach((doc) => {
      userPayoutRequests.push({ ...doc.data(), id: doc.id });
    });
  }

  return userPayoutRequests;
}
