import React, { useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit, FaStar, FaUsers } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { useEvents } from "../../../store/event_store";
import StatisticsModal from "./StatisticsModal";
import DetailsModal from "../components/DetailsModal";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";
const PopingOutEvents = ({
  event_id,
  setIsLoading,
  event_name,
  registration_date,
}) => {
  const [eventData, setEventData] = useState({});

  const deleteEvent = useEvents((state) => state.deleteEvent);
  const getEvent = useEvents((state) => state.getEvent);
  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    // METHOD TO HANDLE GETTING THE EVENT DATA
    async function handleGetEventData() {
      const event_data = await getEvent(event_id);
      setEventData(event_data);
    }
    handleGetEventData();
  }, [event_id]);

  //METHOD TO HANDLE DELETING THE EVENT
  async function handleDeleteEvent(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteEvent(event_id);
    if (response) {
      displaySuccessToast("🦄 The event has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the event");
    }
    setIsLoading(false);
  }

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <StatisticsModal
            event_id={event_id}
            event_name={event_name}
            registration_date={registration_date}
          />
        </Dropdown.Item>
        <Dropdown.Item>
          <div>
            <DetailsModal event_id={event_id} />
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* EDIT EVENT */}
          <Link
            to={"/edit_event/" + event_id}
            state={{ event_data: eventData }}
          >
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* attendees */}
          <Link to={"/order_details/" + event_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaUsers />
              <h6>Attendees</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to={"/manage_event_reviews/" + event_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaStar />
              <h6>Reviews</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE EVENT */}
          <DeleteComponentModal
            itemName="Event"
            handleDelete={handleDeleteEvent}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutEvents;
