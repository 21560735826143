import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Sidenavbar from "./components/sidenavbar";
import { Link, Navigate } from "react-router-dom";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import usePaymentMethodsStore from "../../store/payment_store";
import Loader from "../../components/loader/loader";
import useFetchUserPaymentMethods from "../../customHooks/useFetchUserPaymentMethods";
import { createSlug } from "../../utils/convertor";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Payoutmethods() {
  const { isLoading } = useFetchUserPaymentMethods();
  const isOrganizer = userStore((state) => state.isOrganizer);
  const paymentMethods = usePaymentMethodsStore(
    (state) => state.paymentMethods
  );
  const userPaymentMethods = usePaymentMethodsStore(
    (state) => state.userPaymentMethods
  );
  const layout_page = useSettings((state) => state.layout_page);

  if (!isOrganizer || !auth.currentUser?.emailVerified)
    return <Navigate to="/access_denied" />;

  return (
    <div>
      <Header />
      <BreadCrumpOrg title={"Payout methods"} />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"payoutmethods"} />}
            </aside>

            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card">
                <div className="card-header">
                  <h6 className="b mb-0 float-lg-left">Payout methods</h6>
                </div>
                <div className="card-body">
                  <table className="table table-hover table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        <th>Payout method</th>
                        <th>Status</th>
                        <th className="text-center">
                          <i className="fas fa-cog" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <Loader />}
                      {paymentMethods.map((paymentOption) => {
                        const payment_method =
                          userPaymentMethods.payment_methods?.[
                            paymentOption?.name
                          ];
                        return (
                          <tr key={paymentOption?.name}>
                            <td>
                              <img src={paymentOption?.icon} />
                            </td>
                            <td>
                              <span className="badge badge-warning">
                                {!payment_method?.account_number &&
                                  "Information not provided"}
                              </span>
                            </td>
                            <td className="text-center">
                              <Link
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                                to={`/payoutmethods/${createSlug(
                                  paymentOption?.name
                                )}`}
                                className="btn text-white"
                              >
                                <i className="fas fa-plus fa-fw" /> Set
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
