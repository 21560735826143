import React, { useState } from "react";
import { useSettings } from "../../../store/setting_store";
import { tableStore } from "../../../store/table_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import TableTypeDetails from "./TableTypeDetails";

const Room = () => {
  // State methods from zustand store
  const layout_page = useSettings((state) => state.layout_page);

  const tableTypes = tableStore((state) => state.venueTableTypes);
  const [openTableTypes, setOpenTableTypes] = useState({}); // Keep track of the open room's ID

  const deleteTableType = tableStore((state) => state.deleteVenueTableType);
  const [isLoading, setIsLoading] = useState(false);

  //a function to toogle Room the open/close

  const toggleRoom = (tableYpeId) => {
    setOpenTableTypes((prevOpenTableTypes) => ({
      ...prevOpenTableTypes,
      [tableYpeId]: !prevOpenTableTypes[tableYpeId],
    }));
  };

  //deleting the room
  const handleDeleteRoom = async (e, venueId, tableId) => {
    e.preventDefault();

    setIsLoading(true);
    const response = await deleteTableType(venueId, tableId);
    if (response) {
      displaySuccessToast("The delete is successfully");
    } else {
      displayErrorToast("Something went wrong in deleting");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {tableTypes?.map((tableType) => {
        return (
          <div key={tableType?.id} className="mb-3">
            <div
              style={{ backgroundColor: layout_page?.primary_color }}
              className="py-2 rounded text-white fw-bold"
            >
              <div className="d-flex justify-content-between">
                <span
                  className="d-flex align-content-center cursor-pointer"
                  onClick={() => toggleRoom(tableType?.id)}
                >
                  {openTableTypes[tableType?.id] ? (
                    <i
                      class="fas fa-angle-double-down mx-3"
                      style={{ fontSize: 20 }}
                    ></i>
                  ) : (
                    <i
                      class="fas fa-angle-double-right mx-3"
                      style={{ fontSize: 20 }}
                    ></i>
                  )}

                  {tableType?.venue?.label}
                </span>
                <span
                  className="mr-3 cursor-pointer"
                  onClick={(e) =>
                    handleDeleteRoom(e, tableType?.venue?.value, tableType?.id)
                  }
                >
                  <i class="fas fa-copy mr-2"></i>{" "}
                  <i class="fas fa-trash-alt"></i>
                </span>
              </div>
            </div>
            {openTableTypes[tableType?.id] && ( // Only render RoomDetails if the table's ID matches the open table's ID
              <div className="mt-1">
                <TableTypeDetails
                  tableType_id={tableType?.id}
                  venue_id={tableType?.venue?.value}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Room;
