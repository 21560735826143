import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { userStore } from "../../store/user_store";
import { useEvents } from "../../store/event_store";
import { useVenues } from "../../store/venues_store";
import { useReview } from "../../store/review_store";
import { ordersStore } from "../../store/orders_store";
const MoreInformationModal = ({
  userType,
  user_name,
  user_email,
  last_login,
  registration_date,
  firstname,
  update_date,
  user_id,
  status,
}) => {
  const getOrganizerById = userStore((state) => state.getOrganizerById);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  //getting scanners of specific organizer
  const getScannersByOrganizer = userStore(
    (state) => state.getScannersByOrganizer
  );
  //get POS of the organizer
  const getPOSByOrganizer = userStore((state) => state.getPOSByOrganizer);

  //get organizer venues
  const getOrganizerVenues = useVenues((state) => state.getOrganizerVenues);

  //get user followers
  const getfollowers = userStore((state) => state.getOrganizerFollowers);

  //get attendee favorite events
  const getFavoriteEvents = userStore((state) => state.getAttendeefavorites);

  //getting attendee Reviews
  const getAttendeeReviews = useReview((state) => state.getUserReviews);

  //getting cart items of the attendee
  const getAttendeeCartItems = userStore((state) => state.getAttendeeCartItems);

  //getting orders of the attendee
  const getAttendeeTickets = ordersStore((state) => state.attendeeOrders);

  //states
  const [organizer, setOrganizer] = useState([]);
  const [organizerEvents, setOrganizerEvents] = useState([]);
  const [organizerScanners, setOrganizerScanners] = useState([]);
  const [organizerPOS, setOrganizerPOS] = useState([]);
  const [organizerVenues, setOrganizerVenues] = useState([]);
  const [organizerFollowers, setOrganizerFollowers] = useState([]);
  const [attendeeFavorites, setAttendeeFavorites] = useState([]);
  const [attendeeReviews, setAttendeeReviews] = useState([]);
  const [attendeeCart, setAttendeeCart] = useState([]);
  const [attendeeTickets, setAttendeeTickets] = useState([]);

  //fetching users from the database
  useEffect(() => {
    const fetchUsers = async () => {
      // ORGANIZER SUMMARY
      const organizerDetails = await getOrganizerById(user_id);
      setOrganizer(organizerDetails);

      const events = await getOrganizerEvents(user_id);
      setOrganizerEvents(events);

      const scanners = await getScannersByOrganizer(user_id);
      setOrganizerScanners(scanners);

      const pos = await getPOSByOrganizer(user_id);
      setOrganizerPOS(pos);

      const venues = await getOrganizerVenues(user_id);
      setOrganizerVenues(venues);

      //following.
      const followers = await getfollowers(user_id);
      setOrganizerFollowers(followers);

      // ATTENDEE SUMMARY
      //favorite events
      const favorites = await getFavoriteEvents(user_id);
      setAttendeeFavorites(favorites);

      //get attendee reviews
      const reviews = await getAttendeeReviews(user_id);
      setAttendeeReviews(reviews);

      const cart = await getAttendeeCartItems(user_id);
      setAttendeeCart(cart);

      const tickets = await getAttendeeTickets(user_id);
      setAttendeeTickets(tickets);
    };
    fetchUsers();
  }, [user_id]);

  return (
    <div>
      <div
        className=" cursor-pointer"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        <div className="d-flex gap-1" style={{ color: "gray" }}>
          <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />{" "}
          <h6>More information</h6>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className=" modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                User Information
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className=" modal-body">
              {/* Modal content goes here */}

              <div className=" d-flex gap-5 row">
                <div className="table-responsive col-md-6">
                  <table class="table table-striped table-hover">
                    <div className=" d-flex gap-1">
                      <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />
                      <h6>Account Information</h6>
                    </div>
                    <tbody>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Role</th>
                        <td> {userType} </td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Status</th>
                        <td className="text-sm">
                          {status !== "disabled" ? (
                            <span className="badge badge-success">
                              <i className="fas fa-user-check fa-fw" /> Enabled
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              <i class="fas fa-user-slash fa-fw"></i> Disabled
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">First Name</th>
                        <td> {firstname} </td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Email</th>
                        <td> {user_email} </td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Username</th>
                        <td> {user_name} </td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Registration Date</th>
                        <td>{registration_date}</td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Update Date</th>
                        <td>{update_date}</td>
                      </tr>
                      <tr className=" text-sm" style={{ fontSize: "12px" }}>
                        <th scope="row">Last login</th>
                        <td>{last_login}</td>
                      </tr>
                      {userType === "attendee" && (
                        <>
                          <tr className=" text-sm" style={{ fontSize: "12px" }}>
                            <th scope="row">Registered using Google</th>
                            <td colSpan="2">comming soon</td>
                          </tr>
                          <tr className=" text-sm" style={{ fontSize: "12px" }}>
                            <th scope="row">Facebook profile picture</th>
                            <td colSpan="2">comming soon</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                {/*attendee table */}
                {userType === "attendee" && (
                  <div className="table-responsive col">
                    <table class="table table-striped table-hover">
                      <div className=" d-flex gap-2">
                        <FaUser color="gray" /> <h6>Attendee Information</h6>
                      </div>
                      <tbody>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Cart elements</th>
                          <td> {attendeeCart?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Bought tickets</th>
                          <td> {attendeeTickets?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Reviews</th>
                          <td> {attendeeReviews?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Favourites</th>
                          <td> {attendeeFavorites?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Following</th>
                          <td>comming soon</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {/* Organizer table */}
                {userType === "organizer" && (
                  <div className="table-responsive col">
                    <table class="table table-striped table-hover">
                      <div className=" d-flex gap-2">
                        <i className="dropdown-icon far fa-id-card fa-fw text-muted" />
                        <h6>Organizer Information</h6>
                      </div>
                      <tbody>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Name</th>
                          <td> {organizer?.organizer_name} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Profile views</th>
                          <td>comming soon</td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">
                            Is featured on the homepage slider?
                          </th>
                          <td>comming soon</td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Events </th>
                          <td> {organizerEvents?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Venues</th>
                          <td> {organizerVenues?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Scanners</th>
                          <td> {organizerScanners?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Point of sale</th>
                          <td> {organizerPOS?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Followed by</th>
                          <td> {organizerFollowers?.length} </td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Show venues map</th>
                          <td>{organizer?.showVenuesMap ? "Yes" : "No"}</td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">Show followers</th>
                          <td>{organizer?.showFollowers ? "Yes" : "No"}</td>
                        </tr>
                        <tr className=" text-sm" style={{ fontSize: "12px" }}>
                          <th scope="row">show reviews</th>
                          <td>{organizer?.showReviews ? "Yes" : "No"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInformationModal;
