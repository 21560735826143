import React, { useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Manageaddlanguages() {
  const addNewLanguage = useEvents((state) => state.addNewLanguage);
  //HANDLING LANGUAGE ADDUP
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [languageCode, setLanguagecode] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const layout_page = useSettings((state) => state.layout_page);

  const handleAddLanguage = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setIsLoading(true);

    if (Object.keys(errors).length === 0) {
      const response = await addNewLanguage({
        name: name,
        languageCode: languageCode,
        created_at: Timestamp.fromDate(new Date()),
      });

      if (response) {
        displaySuccessToast("Language Added Succesfully");
        navigate("/manage_languages");
      } else {
        displayErrorToast("Failed to add language");
      }
    } else {
      setErrors(errors);
    }

    setIsLoading(false);
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!name) {
      errors.name = "name is required";
    }
    if (!languageCode) {
      errors.languageCode = "Language code is required";
    }

    return errors;
  }

  // DISPLAY LOADER IF IS LOADING
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new language"
          page_link="Manage Languages"
          link="manage_languages"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_languages"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/* FORM TO ADD LANGUAGES */}
                    <form onSubmit={handleAddLanguage} noValidate="novalidate">
                      <div className="tab-content">
                        <div
                          id="country_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          <div id="country_translations_en">
                            <div className="form-group d-grid">
                              <label
                                htmlFor="country_translations_en_name"
                                className="required"
                              >
                                Name
                              </label>
                              {errors.name && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.name}
                                </span>
                              )}
                              <input
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                required="required"
                                maxLength={50}
                                pattern=".{1,}"
                                className={
                                  errors.name
                                    ? " border border-danger form-control"
                                    : "form-control"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FIELD FOR LANGUAGE CODE */}
                      <div className="form-group d-grid">
                        <label htmlFor="language_code" className="required">
                          Language code
                        </label>
                        {errors.languageCode && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.languageCode}
                          </span>
                        )}
                        <input
                          type="text"
                          onChange={(e) => setLanguagecode(e.target.value)}
                          required="required"
                          className={
                            errors.languageCode
                              ? " border border-danger form-control"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
