import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const PopingOutCurrencies = ({ currency_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteCurrency = useSettings((state) => state.deleteCurrency);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE CURRENCY
  async function handleDeleteCurrency(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteCurrency(currency_id);
    if (response) {
      displaySuccessToast("🦄 The currency has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the currrency");
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {["left"].map((placement) => (
        <OverlayTrigger
          trigger="click"
          key={placement}
          placement={placement}
          rootClose={true}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>
              <Popover.Body>
                <div className="px-3 d-flex flex-column gap-3">
                  {/* EDIT CATEGORY */}
                  <Link to={"/edit_currency/" + currency_id}>
                    <div className="d-flex gap-1" style={{ color: "gray" }}>
                      <FaEdit />
                      <h6>Edit</h6>
                    </div>
                  </Link>

                  {/* DELETE CURRENCY */}
                  <DeleteComponentModal
                    itemName="Currency"
                    handleDelete={handleDeleteCurrency}
                  />
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <BsThreeDotsVertical
              style={{
                color: layout_page?.primary_color,
                cursor: "pointer",
              }}
            />
          </div>
        </OverlayTrigger>
      ))}
    </>
  );
};
export default PopingOutCurrencies;
