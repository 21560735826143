/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Alert from "./components/alert";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import DashboardBreadcrump from "../../components/dashboard_breadcrump/dashboard_breadcrump";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useEvents } from "./../../store/event_store";
import EventTile from "../../components/event_tile/event_tile";
import { userStore } from "./../../store/user_store";
import { auth } from "../../Firebase";

export default function Favorites() {
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const getFavoriteEvents = useEvents((state) => state.getFavoriteEvents);
  const favoriteEvents = useEvents((state) => state.favoriteEvents);

  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();

  // USE EFFECT TO GET THE USER'S FAVORITE EVENTS
  useEffect(() => {
    console.log("THE USER ID: " + user?.user_id);
    console.log("THE LENGTH OF FAVORITE EVENTS: " + favoriteEvents.length);
    if (user) {
      console.log("FAVORITE EVENTS CALLED");
      getFavoriteEvents(user?.user_id);
    }
  }, []);

  // GO TO LOGIN IF USER IS NOT LOGGED IN
  if (!user) {
    navigate("/sign_in");
    return;
  }

  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  }

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />
      <DashboardBreadcrump title={"My Favorites"} />

      {/* FAVORITES MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* SIDE NAV */}
            {!isMobile && <AttendeeSideNav activeTab={"favorites"} />}

            {/* FAVORITES */}
            {favoriteEvents?.length === 0 ? (
              <div className="col-lg-9 mt-4 mt-lg-0">
                <Alert message={"No Favorites Found"} />
              </div>
            ) : (
              <div className="col-lg-9 mt-4 mt-lg-0 d-flex flex-wrap">
                {favoriteEvents.map((event) => {
                  if (event) {
                    return <EventTile event={event} num_tiles={"6"} />;
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
