import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import app from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
} from "../services/helpers";

// INITIALIZING FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

const settingsStore = (set, get) => ({
  layout_page: {},
  sliders: [],
  home_page: {},
  paymentMethods: [],
  paymentGateways: [],
  payment_page: {},
  checkout_page: {},
  google_page: {},
  google_maps_page: {},
  social_login_page: {},
  event_settings_page: {},
  newsletter_page: {},
  blog_page: {},
  venue_page: {},
  currencies: [],

  //LAYOUT PAGE IN SETTINGS
  // get layout content
  getLayoutContent: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "settings"));
      const layout_page = querySnapshot.docs[0].data();
      set({ layout_page });
      return true;
    } catch (error) {
      console.log(
        "SOMETHING WENT WRONG in getting layout page content: " + error
      );
      // displayErrorToast("Something went wrong getting settings");
      return false;
    }
  },

  // Function to upload layout setting images
  uploadLayoutImages: async (layoutImage, imagePath) => {
    if (!layoutImage) {
      return;
    }
    const storageRef = ref(storage, imagePath);
    try {
      await uploadBytes(storageRef, layoutImage);
      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading layout image", error);
    }
  },

  // Function to delete layout image URL
  deleteImage: async (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("Image deleted");
      return true;
    } catch (error) {
      console.log("Error deleting the layout image", error);
      return false;
    }
  },

  //A METHOD TO ADD A NEW LAYOUT SETTINGS CONTENT
  addSettings: async (data) => {
    // CREATING A COLLECTION
    try {
      const settingCollection = collection(db, "settings");
      const responseFromFirestore = await addDoc(settingCollection, data);
      const setting_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const settingDoc = doc(db, "settings", setting_id);
      // UPDATING THE PAGE ID
      await updateDoc(settingDoc, { id: setting_id });
      //update page array in zustand store
      set((state) => ({
        layout_page: data,
      }));
      return true;
    } catch (error) {
      console.log("Something is wrong", error);
      return false;
    }
  },

  // METHOD TO UPDATE LAYOUT PAGE
  updateLayout: async (layout_id, data) => {
    try {
      await updateDoc(doc(db, "settings", layout_id), data);
      // UPDATING THE STATE WITH THE NEW DATA
      set((state) => ({
        layout_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating layout document: ", error);
      return false;
    }
  },

  //getting the layout page id
  getLayoutPageId: async () => {
    const layoutID = collection(db, "settings");
    const response = await getDocs(layoutID);
    const data = response.docs[0].id;
    return data;
  },

  //HOME PAGE IN SETTINGS
  //GETTING HOME PAGE CONTENT
  getHomePageContent: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "sliders"));
      const home_page = querySnapshot.docs[0].data();
      set({ home_page: home_page });
      return home_page;
    } catch (error) {
      console.log("Some thing is wrong:", error);
      return false;
    }
  },
  // METHOD TO GET HOMEPAGE SLIDERS
  getHomepageSliders: async () => {
    const homepageSlidersCollection = collection(db, "sliders");
    const response = await getDocs(homepageSlidersCollection);

    if (response.docs.length === 0) {
      return [];
    }

    const data = response.docs[0]?.data();
    const slider_events = data.slider_events;
    set({ sliders: slider_events });
    return slider_events;
  },

  //getting the id of the sliders
  getHomePageSliderId: async () => {
    const sliderID = collection(db, "sliders");
    const response = await getDocs(sliderID);
    const data = response.docs[0]?.id;
    return data;
  },

  // METHOD TO UPDATE SLIDER
  updateSlider: async (slider_id, data) => {
    try {
      await updateDoc(doc(db, "sliders", slider_id), data);
      set((state) => ({
        home_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating the slider document: ", error);
      return false;
    }
  },

  //get payment Options
  getAllPaymentOptions: async () => {
    try {
      const payments = [];
      const querySnapshot = await getDocs(collection(db, "payment_methods"));
      querySnapshot.forEach((doc) => {
        payments.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      set({ paymentMethods: payments });
      return true;
    } catch (error) {
      console.log("Something is wrong in fetching the payment options");
      return false;
    }
  },

  ////////////////////////////////////////
  // METHOD TO UPLOAD PAYMENT GATE WAY IMAGE
  ////////////////////////////////////////
  uploadPaymentImage: async (imageFile) => {
    if (!imageFile) {
      return;
    }
    const storageRef = ref(
      storage,
      "settings/payment_images/" + imageFile.name
    );
    try {
      await uploadBytes(storageRef, imageFile);
      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
    }
  },

  //A METHOD TO ADD A NEW LAYOUT SETTINGS CONTENT
  addPaymentGateway: async (data) => {
    // CREATING A COLLECTION
    try {
      const gatewayCollection = collection(db, "payment_gateway");
      const responseFromFirestore = await addDoc(gatewayCollection, data);
      const gateway_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const gatewayDoc = doc(db, "payment_gateway", gateway_id);
      // UPDATING THE PAGE ID
      await updateDoc(gatewayDoc, { id: gateway_id });
      //update page array in zustand store
      set((state) => ({ paymentGateways: [...state.paymentGateways, data] }));
      return true;
    } catch (error) {
      console.log("Something is wrong", error);
      return false;
    }
  },
  //get payment gateways
  getPaymentGateways: async () => {
    try {
      const paymentGateways = [];
      const querySnapshot = await getDocs(collection(db, "payment_gateway"));
      querySnapshot.forEach((doc) => {
        paymentGateways.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      set({ paymentGateways: paymentGateways });
      return true;
    } catch (error) {
      console.log("Something is wrong in fetching the payment gateways");
      return false;
    }
  },
  //get specific payment gateway by id
  getPaymentGateway: async (gateway_id) => {
    const payment_gateway = (await get()).paymentGateways.find(
      (paymentGateway) => paymentGateway.id === gateway_id
    );
    return payment_gateway;
  },

  //updating the payment gateway
  updatePaymentGateway: async (gateway_id, data) => {
    try {
      await updateDoc(doc(db, "payment_gateway", gateway_id), data);
      get().getPaymentGateways();
      set((state) => ({
        paymentGateways: state.paymentGateways.map((paymentGateway) =>
          paymentGateway.id === gateway_id ? data : paymentGateway
        ),
      }));
      return true;
    } catch (error) {
      console.log("error in updating the docuemnt:", error);
      return false;
    }
  },

  //getting payment settings
  getPaymentPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "payment_settings"));
      const payment_page = querySnapshot.docs[0].data();
      set({ payment_page: payment_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting payment settings");
      return false;
    }
  },
  //getting the payment page id
  getPaymentPageId: async () => {
    const paymentID = collection(db, "payment_settings");
    const response = await getDocs(paymentID);
    const data = response.docs[0].id;
    return data;
  },

  // METHOD TO UPDATE PAYMENT PAGE
  updatePaymentPage: async (payment_id, data) => {
    try {
      await updateDoc(doc(db, "payment_settings", payment_id), data);
      // UPDATING THE STATE WITH THE NEW DATA
      set((state) => ({
        payment_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating payment page document: ", error);
      return false;
    }
  },

  //CHECKOUT PAGE
  //getting the payment page id
  getCheckoutPageId: async () => {
    const checkoutTID = collection(db, "checkout_settings");
    const response = await getDocs(checkoutTID);
    const data = response.docs[0].id;
    return data;
  },

  //getting checkout content
  getCheckoutPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "checkout_settings"));
      const checkout_page = querySnapshot.docs[0].data();
      set({ checkout_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting checkout settings");
      return false;
    }
  },
  // METHOD TO UPDATE CHECKOUT PAGE
  updateCheckoutPage: async (checkout_id, data) => {
    try {
      await updateDoc(doc(db, "checkout_settings", checkout_id), data);
      set((state) => ({
        checkout_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating checkout page document: ", error);
      return false;
    }
  },

  //GOOGLE RAPTURE PAGE
  //getting the google page id
  getGooglePageId: async () => {
    const googleID = collection(db, "google_recaptcha_settings");
    const response = await getDocs(googleID);
    const data = response.docs[0].id;
    return data;
  },

  //getting google content
  getGooglePage: async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "google_recaptcha_settings")
      );
      const google_page = querySnapshot.docs[0].data();
      set({ google_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting google settings");
      return false;
    }
  },
  // METHOD TO UPDATE GOOGLE PAGE
  updateGooglePage: async (google_id, data) => {
    try {
      await updateDoc(doc(db, "google_recaptcha_settings", google_id), data);
      set((state) => ({
        google_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating google page document: ", error);
      return false;
    }
  },

  //GOOGLE MAPS PAGE
  //getting the google map page id
  getGoogleMapsPageId: async () => {
    const googleMapsID = collection(db, "google_maps_settings");
    const response = await getDocs(googleMapsID);
    const data = response.docs[0].id;
    return data;
  },

  //getting google maps content
  getGoogleMapsPage: async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "google_maps_settings")
      );
      const google_maps_page = querySnapshot.docs[0].data();
      set({ google_maps_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting google settings");
      return false;
    }
  },
  // METHOD TO UPDATE GOOGLE MAPS PAGE
  updateGoogleMapsPage: async (google_id, data) => {
    try {
      await updateDoc(doc(db, "google_maps_settings", google_id), data);
      set((state) => ({
        google_maps_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating google page document: ", error);
      return false;
    }
  },
  //SOCIAL LOGIN PAGE
  //getting the social_login page id
  getSocialLoginPageId: async () => {
    const social_loginID = collection(db, "social_login");
    const response = await getDocs(social_loginID);
    const data = response.docs[0].id;
    return data;
  },

  //getting social login content
  getSocialLoginPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "social_login"));
      const social_login_page = querySnapshot.docs[0].data();
      set({ social_login_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting google settings");
      return false;
    }
  },
  // METHOD TO UPDATE SOCIAL LOGIN PAGE
  updateSocialLoginPage: async (social_login_id, data) => {
    try {
      await updateDoc(doc(db, "social_login", social_login_id), data);
      set((state) => ({
        social_login_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating social login page document: ", error);
      return false;
    }
  },
  //EVENTS LIST PAGE
  //getting the event settings page id
  getEventSettingsPageId: async () => {
    const event_settingsID = collection(db, "event_settings");
    const response = await getDocs(event_settingsID);
    const data = response.docs[0].id;
    return data;
  },

  //getting event_settings content
  getEventSettingsPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "event_settings"));
      const event_settings_page = querySnapshot.docs[0].data();
      set({ event_settings_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting event settings");
      return false;
    }
  },
  // METHOD TO UPDATE event_settings PAGE
  updateEventSettingsPage: async (event_settings_id, data) => {
    try {
      await updateDoc(doc(db, "event_settings", event_settings_id), data);
      set((state) => ({
        event_settings_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating event settings page document: ", error);
      return false;
    }
  },
  //NEWS LETTERS PAGE
  //getting the news letter page id
  getNewsletterPageId: async () => {
    const newsletterID = collection(db, "news_letter_settings");
    const response = await getDocs(newsletterID);
    const data = response.docs[0].id;
    return data;
  },

  //getting newsletter content
  getNewsletterPage: async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "news_letter_settings")
      );
      const newsletter_page = querySnapshot.docs[0].data();
      set({ newsletter_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting news letter settings");
      return false;
    }
  },
  // METHOD TO UPDATE newsletter PAGE
  updateNewsletterPage: async (newsletter_id, data) => {
    try {
      await updateDoc(doc(db, "news_letter_settings", newsletter_id), data);
      set((state) => ({
        newsletter_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating event settings page document: ", error);
      return false;
    }
  },
  //BLOG SETTINGS PAGE
  //getting the news letter page id
  getBlogSettingsPageId: async () => {
    const blogCollection = collection(db, "blog_settings");
    const response = await getDocs(blogCollection);
    const data = response.docs[0].id;
    return data;
  },

  //getting blog settings content
  getBlogSettingsPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "blog_settings"));
      const blog_page = querySnapshot.docs[0].data();
      set({ blog_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting news letter settings");
      return false;
    }
  },
  // METHOD TO UPDATE BLOG SETTINGS PAGE
  updateBlogSettingsPage: async (blog_id, data) => {
    try {
      await updateDoc(doc(db, "blog_settings", blog_id), data);
      set((state) => ({
        blog_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating blog page document: ", error);
      return false;
    }
  },
  //VENUE SETTINGS PAGE
  //getting the venue page id
  getVenueSettingsPageId: async () => {
    const venueCollection = collection(db, "venue_settings");
    const response = await getDocs(venueCollection);
    const data = response.docs[0].id;
    return data;
  },

  //getting blog settings content
  getVenueSettingsPage: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "venue_settings"));
      const venue_page = querySnapshot.docs[0].data();
      set({ venue_page });
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      // displayErrorToast("Something went wrong getting venue settings");
      return false;
    }
  },
  // METHOD TO UPDATE VENUE SETTINGS PAGE
  updateVenueSettingsPage: async (venue_id, data) => {
    try {
      await updateDoc(doc(db, "venue_settings", venue_id), data);
      set((state) => ({
        venue_page: data,
      }));
      return true;
    } catch (error) {
      console.error("Error Updating venue page document: ", error);
      return false;
    }
  },

  //CURRENCY PAGE
  //////////////////////////////////////////
  // METHOD TO ADD A NEW CURRENCY //
  //////////////////////////////////////////
  addCurrency: async (data) => {
    // CREATING A COLLECTION
    try {
      const currencyCollection = collection(db, "currencies");
      const responseFromFirestore = await addDoc(currencyCollection, data);
      const currency_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const currecyDoc = doc(db, "currencies", currency_id);
      // UPDATING THE BLOG ID
      await updateDoc(currecyDoc, { id: currency_id });
      //update
      const addCurrency = (currency) =>
        set((state) => ({
          currencies: [...state.currencies, currency],
        }));
      addCurrency(data);
    } catch (error) {
      console.log("Error in adding the currency");
      return null;
    }
  },

  //getting currencies from the database
  getCurrencies: async () => {
    try {
      const currencies = [];
      const querySnapshot = await getDocs(collection(db, "currencies"));
      querySnapshot.forEach((doc) => {
        currencies.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      set({ currencies });
      return true;
    } catch (error) {
      console.log("error in getting currency", error);
      return false;
    }
  },
  //update currency
  updateCurrency: async (currency_id, data) => {
    try {
      await updateDoc(doc(db, "currencies", currency_id), data);
      get().getCurrencies();
      set((state) => ({
        currencies: state.currencies.map((currency) =>
          currency.id === currency_id ? data : currency
        ),
      }));
      return true;
    } catch (error) {
      console.log("error in updating the currency", error);
      return false;
    }
  },

  //delete currency
  deleteCurrency: async (currency_id) => {
    try {
      const countryRef = doc(db, "currencies", currency_id);
      await deleteDoc(countryRef);
      console.log("Currency deleted successfully");
      //update
      const updatedCurrencies = get().currencies.filter(
        (country) => country?.id !== currency_id
      );
      set({ currencies: updatedCurrencies });
      return true;
    } catch (error) {
      console.log("Something went wrong while deleting the currency", error);
      return false;
    }
  },
});
// CREATING AND EXPORTING THE SETTINGS STORE
// export const useSettings = create(settingsStore, { name: "settings_store" });

export const useSettings = create(
  persist(settingsStore, {
    name: "settings_store",
  })
);
useSettings.getState().getLayoutContent();
useSettings.getState().getHomePageContent();
useSettings.getState().getVenueSettingsPage();
useSettings.getState().getBlogSettingsPage();
useSettings.getState().getNewsletterPage();
useSettings.getState().getEventSettingsPage();
useSettings.getState().getSocialLoginPage();
useSettings.getState().getGoogleMapsPage();
useSettings.getState().getGooglePage();
useSettings.getState().getCheckoutPage();
useSettings.getState().getPaymentPage();
useSettings.getState().getPaymentGateways();
useSettings.getState().getAllPaymentOptions();
