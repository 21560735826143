// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tagsinput {
    height: calc(2.75rem + 2px);
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
    color: #8898aa;
    border: 1px solid #f1f3f7;
    border-radius: 0.25rem;
    background-clip: padding-box;
    background-color: #f1f3f7;

  }


.react-tagsinput-tag {
    background-color: #f67611;
    color: white;
    border-radius: 2px;
    border: none;
    display: inline-block;
    font-family: sans-serif;
    font-size: 85%;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 0;
    margin-right: 5px;
    padding: 0.5em;
    line-height: 1;
    vertical-align: baseline;

  }`, "",{"version":3,"sources":["webpack://./src/components/tags_input_field/tags_input.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,oDAAoD;IACpD,cAAc;IACd,yBAAyB;IACzB,sBAAsB;IACtB,4BAA4B;IAC5B,yBAAyB;;EAE3B;;;AAGF;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,cAAc;IACd,wBAAwB;;EAE1B","sourcesContent":[".react-tagsinput {\r\n    height: calc(2.75rem + 2px);\r\n    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);\r\n    color: #8898aa;\r\n    border: 1px solid #f1f3f7;\r\n    border-radius: 0.25rem;\r\n    background-clip: padding-box;\r\n    background-color: #f1f3f7;\r\n\r\n  }\r\n\r\n\r\n.react-tagsinput-tag {\r\n    background-color: #f67611;\r\n    color: white;\r\n    border-radius: 2px;\r\n    border: none;\r\n    display: inline-block;\r\n    font-family: sans-serif;\r\n    font-size: 85%;\r\n    font-weight: 400;\r\n    margin-bottom: 5px;\r\n    margin-top: 0;\r\n    margin-right: 5px;\r\n    padding: 0.5em;\r\n    line-height: 1;\r\n    vertical-align: baseline;\r\n\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
