import React, { useState, useEffect } from "react";
import BreadCrump from "../components/BreadCrump/bread_crump";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import { useEvents } from "../store/event_store";
import { Link, useNavigate } from "react-router-dom";
import useQueryStore from "../store/query_store";
export default function Categories() {
  const getVisibleEventCategories = useEvents(
    (state) => state.getVisibleEventCategories
  );
  const setCategory = useQueryStore((state) => state.setCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );
  const navigate = useNavigate();

  const [eventCategories, setEventCategories] = useState([]);

  useEffect(() => {
    const fetchEventCategories = async () => {
      const categories = await getVisibleEventCategories();
      setEventCategories(categories);
    };
    fetchEventCategories();
  }, [eventCategories?.length]);

  //handle navigation
  const handleNavigation = (eventCategory) => {
    setSelectedCategory({
      value: eventCategory?.id,
      label: eventCategory?.name,
    });
    setCategory(eventCategory?.id);
    navigate("/browse_events");
  };

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* BREADCRUMP */}
      <BreadCrump title={"Categories"} />

      {/* MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {eventCategories &&
              eventCategories.map((category) => (
                <div className="col-sm-6 col-md-4" key={category.id}>
                  <div
                    className="zoom-wrap cursor-pointer"
                    onClick={() => handleNavigation(category)}
                  >
                    <div className="card-banner mb-3">
                      <div
                        className="card-body zoom-in img-lazy-load b-loaded"
                        style={{
                          height: 250, // Set the desired height for the card
                          backgroundImage: `url("${category?.image}")`, // Set the image URL as background
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      ></div>
                      <div className="text-bottom">
                        <h4 className="title">{category?.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
