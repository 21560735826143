import React, { useEffect, useState } from "react";
import useQueryStore from "../../store/query_store";
import { getVisibleEventsCategories } from "../../services/events";

const SearchCategory = ({ clearQuery }) => {
  const [eventCategories, setEventCategories] = useState([]);

  const setCategory = useQueryStore((state) => state.setCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getVisibleEventsCategories();
      setEventCategories(data);
    };

    fetchData();
  }, []);

  const handleCategoryClick = (category_id) => {
    setSelectedCategory({
      value: category_id,
    });
    setCategory(category_id);
    setSelectedCategoryId(category_id);
  };

  return (
    <div className="categories-scroll-container d-flex justify-content-between mb-3 cursor-pointer">

      <span
        onClick={() => {
          clearQuery();
          setSelectedCategoryId(null);
        }}
        className={`cursor-pointer tag py-1 px-3 ${!selectedCategoryId ? " active" : ""}`}
      >
        <span>All</span>
      </span>

      {eventCategories.map((eventCategory) => (
        <span
          key={eventCategory?.id}
          onClick={() => handleCategoryClick(eventCategory?.id)}
          className={`cursor-pointer tag py-1 px-3 ${
            selectedCategoryId === eventCategory?.id ? " active" : ""
          }`}
        >
          <span>{eventCategory?.name}</span>
        </span>
      ))}
    </div>
  );
};

export default SearchCategory;
