import React, { useEffect, useMemo, useState } from "react";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import SideNav from "../../components/side_nav/side_nav";
import { useEvents } from "./../../store/event_store";
import Loader from "../../components/loader/loader";
import EventTileList from "../../components/event_tile/event_tile_list";
import useQueryStore from "../../store/query_store";
import { filterEvents } from "../../utils/eventQuery";
import { userStore } from "../../store/user_store";
import BrowseEventsHeader from "./browse_events_header";
import Map from "./Map";
import Calendar from "./Calendar";

function BrowseEvents() {
  const [isLoaded, setIsLoaded] = useState(false);

  const events = useEvents((state) => state.events);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);
  const getUpcomingEvents = useEvents((state) => state.getUpcomingEvents);
  const fetchEvents = useEvents((state) => state.fetchEvents);

  const searchText = useQueryStore((state) => state.searchText);
  const category = useQueryStore((state) => state.category);
  const startDate = useQueryStore((state) => state.startDate);
  const endDate = useQueryStore((state) => state.endDate);
  const country = useQueryStore((state) => state.country);
  const audienceType = useQueryStore((state) => state.audienceType);
  const clearQuery = useQueryStore((state) => state.clearQuery);
  const organizers = userStore((state) => state.organizers);

  const [showingMap, setShowingMap] = useState(false);
  const [showingCalendar, setShowingCalendar] = useState(false);

  // USE EFFECT
  useEffect(() => {
    if (upcomingEvents?.length === 0) {
      fetchEvents();
    }
    getUpcomingEvents(events);
    setIsLoaded(true);
  }, [upcomingEvents.length, events.length]);

  const queriedEvents = useMemo(
    () =>
      filterEvents(upcomingEvents, {
        organizers,
        searchText,
        category,
        startDate,
        endDate,
        country,
        audienceType,
      }),
    [searchText, category, startDate, endDate, country, audienceType]
  );

  if (!isLoaded) {
    return <Loader />;
  }

  const isFiltering =
    searchText || category || startDate || endDate || country || audienceType
      ? true
      : false;

  const eventsToRender = isFiltering ? queriedEvents : upcomingEvents;

  return (
    <div>
      <Header currentPage={"browse_events"} />
      <BreadCrump title={"Events"} />

      {/* BROWSE EVENTS MAIN BODY */}
      <section className="section-content bg-white padding-y">
        <div className="container">
          {showingMap && <Map events={events} />}

          {showingCalendar && <Calendar events={events} />}

          <BrowseEventsHeader
            eventsToRender={eventsToRender}
            clearQuery={clearQuery}
            isFiltering={isFiltering}
            showingCalendar={showingCalendar}
            setShowingCalendar={setShowingCalendar}
            showingMap={showingMap}
            setShowingMap={setShowingMap}
          />

          {/* MAIN EVENTS SECTION*/}
          <div className="row">
            <SideNav />
            <EventTileList events={eventsToRender} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default BrowseEvents;
