import React from 'react'

const Exclamation = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0C4.92529 0 0 4.92707 0 11C0 17.0765 4.92529 22 11 22C17.0747 22 22 17.0765 22 11C22 4.92707 17.0747 0 11 0ZM11 4.87903C12.0289 4.87903 12.8629 5.71308 12.8629 6.74194C12.8629 7.77079 12.0289 8.60484 11 8.60484C9.97114 8.60484 9.1371 7.77079 9.1371 6.74194C9.1371 5.71308 9.97114 4.87903 11 4.87903ZM13.4839 16.1452C13.4839 16.4391 13.2456 16.6774 12.9516 16.6774H9.04839C8.75445 16.6774 8.51613 16.4391 8.51613 16.1452V15.0806C8.51613 14.7867 8.75445 14.5484 9.04839 14.5484H9.58064V11.7097H9.04839C8.75445 11.7097 8.51613 11.4714 8.51613 11.1774V10.1129C8.51613 9.81896 8.75445 9.58064 9.04839 9.58064H11.8871C12.181 9.58064 12.4194 9.81896 12.4194 10.1129V14.5484H12.9516C13.2456 14.5484 13.4839 14.7867 13.4839 15.0806V16.1452Z" fill="#F76300"/>
</svg>
  )
}

export default Exclamation