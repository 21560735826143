import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import PopingOutManageBlogPosts from "./PopingOutManageBlogPosts";

import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import { formatNumberWithCommas } from "../../../services/helpers";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageBlogPosts() {
  const isAdmin = userStore((state) => state.isAdmin);
  //FETCHING BLOGPOSTs
  const getBlogs = useBlogs((state) => state.getBlogs);
  const blogposts = useBlogs((state) => state.blogposts);
  const layout_page = useSettings((state) => state.layout_page);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      await getBlogs();
    }
    fetchData();
  }, [blogposts.length]);

  const navigate = useNavigate();

  //filtering logic
  const [blogFilter, setBlogFilter] = useState("");
  const filteredBlogs = blogposts.filter((blogpost) => {
    return (
      !blogFilter ||
      (blogpost.name &&
        blogpost.name.toLowerCase().includes(blogFilter.toLowerCase()))
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("added_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedBlogs = filteredBlogs?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    if (sortCriteria === "views") {
      if (sortDirection === "desc") {
        // Sort by popularity (views) in descending order
        return b.views - a.views;
      } else {
        // Sort by popularity (views) in ascending order
        return a.views - b.views;
      }
    } else if (sortCriteria === "added_at") {
      // Sort by creation date
      const dateA = a.added_at;
      const dateB = b.added_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      // Handle other criteria sorting here
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [blogpostsPerPage] = useState(15);
  //get current events
  const indexOfLastBlogposts = currentPage * blogpostsPerPage;
  const indexOfFastBlogposts = indexOfLastBlogposts - blogpostsPerPage;
  const current_blogposts = sortedBlogs?.slice(
    indexOfFastBlogposts,
    indexOfLastBlogposts
  );

  //display the loader
  if (isLoading) {
    <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Blog Posts" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_blog_posts"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {blogposts.length} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={blogFilter}
                        onChange={(e) => setBlogFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`added_at-desc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>
                          <option
                            value={`added_at-asc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                          <option
                            value={`views-desc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "desc"
                            }
                          >
                            Popularity (desc)
                          </option>
                          <option
                            value={`views-asc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "asc"
                            }
                          >
                            Popularity (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-new-blog-post"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a blog post"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Category</th>
                              <th>Image</th>
                              <th>Views</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_blogposts.map((blogpost) => (
                              <tr key={blogpost?.id}>
                                {/* DISPLAYING NAME */}
                                <td>
                                  <Link
                                    to={"/blog_details/" + blogpost?.id}
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  >
                                    {blogpost?.name}
                                  </Link>
                                </td>

                                {/* DISPLAYING CATEGORY */}
                                <td>{blogpost?.category?.label}</td>
                                <td>
                                  <a
                                    className="fancybox"
                                    href={blogpost?.main_image}
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Enlarge"
                                  >
                                    <img
                                      src={blogpost?.main_image}
                                      className="img-thumbnail img-50-50"
                                      alt=""
                                    />
                                  </a>
                                </td>

                                {/* DISPALYING NUMBER OF VIEWS */}
                                <td>{blogpost?.views || 0}</td>

                                {/* DISPLAYING STATUS */}
                                <td>
                                  {blogpost?.status === "visible" ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />{" "}
                                      Invisible
                                    </span>
                                  )}
                                </td>

                                {/* DROP DOWNS */}
                                <td className="text-center">
                                  <PopingOutManageBlogPosts
                                    blog_post_id={blogpost?.id}
                                    setIsLoading={setIsLoading}
                                    status={blogpost?.status}
                                  />
                                </td>
                              </tr>
                            ))}

                            {/* END OF BLOGS */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* pagination */}
                  {sortedBlogs?.length > 15 && (
                    <CustomPagination
                      setCurrentPage={setCurrentPage}
                      totalPages={Math.ceil(
                        sortedBlogs?.length / blogpostsPerPage
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
