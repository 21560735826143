import { MenuItem, MenuList, Popover } from "@mui/material";
import { useState } from "react";
import { FaTicketAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import ContactOrganizer from "./contact_organizer";

export default function OptionsDropDown({ orderStatus, booking_id, order_id }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "menu-popover" : undefined;

  return (
    <div>
      <div
        onClick={handleClick}
        className="icon"
        style={{ cursor: "pointer", color: "orangered" }}
      >
        <i className="fas fa-ellipsis-v" />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          {/* PRINT TICKETS */}
          <MenuItem
            button
            style={{
              textDecoration: "none",
              color: "inherit",
              fontSize: ".805rem",
              fontWeight: "400",
            }}
          >
            <FaTicketAlt className="mr-3 text-black-50" />
            Print tickets
          </MenuItem>

          {/* CHECKOUT */}
          <Link
            to="/checkout"
            className={
              orderStatus === "pending"
                ? "dropdown-item"
                : "dropdown-item d-none"
            }
          >
            <MenuItem>
              <i className="dropdown-icon fas fa-money-check fa-fw text-muted" />
              Checkout{" "}
            </MenuItem>
          </Link>

          {/* CONTACT THE ORGANIZER */}
          <MenuItem>
            <ContactOrganizer />
          </MenuItem>

          {/* ORDER DETAILS */}
          <MenuItem>
            <Link
              to={"/ticket_details/" + booking_id}
              style={{
                textDecoration: "none",
                color: "inherit",
                fontSize: ".805rem",
                fontWeight: "400",
                marginTop: "-13px",
              }}
            >
              <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted mr-3" />
              Details
            </Link>
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}
