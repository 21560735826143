import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useSettings } from "../../../store/setting_store";

export default function VenueNamePopover({ venue_id, venue_name }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const layout_page = useSettings((state) => state.layout_page);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        <Link
          style={{
            color: layout_page?.primary_color,
          }}
          to={"/venue_details/" + venue_id}
          data-toggle="tooltip"
          title=""
          data-original-title="View event">
          <span className=" text-sm">{venue_name}</span>
        </Link>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography
          sx={{
            p: 1,
            color: "white",
            backgroundColor: layout_page?.primary_color,
            fontSize: "13px",
          }}>
          view venue
        </Typography>
      </Popover>
    </div>
  );
}
