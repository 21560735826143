import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
// import { myContext } from "../../user_context";
import AdminSideNav from "./components/admin_side_nav";
import { userStore } from "../../store/user_store";
// import { useBlogs } from "../../store/blog_store";
import PopingOutPages from "./PopingOutPages";
import { useSettings } from "../../store/setting_store";
import { getPages } from "../../services/blogs";
import BreadcrumpAdmin from "./components/breadcrump_admin";

export default function Pages() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  // const pages = useBlogs((state) => state.pages);
  // const getPages = useBlogs((state) => state.getPages);
  const layout_page = useSettings((state) => state.layout_page);
  const [pages, setPages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const p = await getPages();
      setPages(p);
    };
    fetchData();
  }, [pages.length]);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesPerPage] = useState(20);
  //get current page
  const indexOfLastpages = currentPage * pagesPerPage;
  const indexOfFastpages = indexOfLastpages - pagesPerPage;
  const current_pages = pages?.slice(indexOfFastpages, indexOfLastpages);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* ADMIN DASHBOARD BREADCRUMP */}
        <BreadcrumpAdmin title="Manage pages" />

        {/* ADMIN DASHBOARD MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_pages"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {pages.length} result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <Link
                        to="/add_page"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Add a new page"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Slug</th>
                              <th>Link</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_pages.map((page) => (
                              <tr key={page?.id}>
                                {/* page title */}
                                <td>{page?.title}</td>
                                {/* slug */}
                                <td>{page?.link.slice(15, 35)}</td>
                                {/* Link */}
                                <td>
                                  <Link
                                    to={`/${page?.link}`}
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  >
                                    {page?.link}
                                  </Link>
                                </td>
                                <td className="text-center">
                                  <PopingOutPages page_id={page?.id} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
