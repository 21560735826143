import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
  displayWarningToast,
  formatNumberWithCommas,
} from "../../services/helpers";
import { useEvents } from "../../store/event_store";
import { userStore } from "../../store/user_store";
import { formatDate } from "./../../services/helpers";
import { useSettings } from "../../store/setting_store";

export default function EventTile({ event, num_tiles }) {
  const user = userStore((state) => state.user);
  const favoriteEvents = useEvents((state) => state.favoriteEvents);
  const getFavoriteEvents = useEvents((state) => state.getFavoriteEvents);
  const removeFromFavorites = useEvents((state) => state.removeFromFavorites);
  const addToFavorites = useEvents((state) => state.addToFavorites);
  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    if (user) {
      getFavoriteEvents(user?.user_id);
    }
  }, []);

  // METHOD TO HANDLE FAVORITES
  async function handleFavorites(e) {
    e.preventDefault();

    if (!user) {
      alert("You must be logged in to add events to your favorites!");
      return;
    }

    if (favoriteEvents.includes(event)) {
      // IF THE USER HAS ALREADY ADDED TO FAVORITES
      const removedResponse = await removeFromFavorites({
        event_id: event?.id,
        user_id: user?.user_id,
      });

      if (removedResponse) {
        displayWarningToast("Event removed from favorites");
      } else {
        displayErrorToast("Error removing event from favorites");
      }
    } else {
      // IF THE USER HAS NOT YET ADDED TO FAVORITES
      const addResponse = await addToFavorites(event?.id, user?.user_id);

      if (addResponse) {
        displaySuccessToast("Event added to favorites");
      } else {
        console.log("Error adding event to favorites");
      }
    }
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className={`col-12  col-lg-${num_tiles}`}>
      <div className="card card-event">
        {/* EVENT MAIN IMAGE */}
        <Link to={"/event_details/" + event?.id}>
          <div
            className="img-wrap img-lazy-load b-loaded"
            style={{
              backgroundImage: `url("${event?.main_event_image}")`,
            }}
          ></div>
        </Link>

        <div className="info-wrap">
          {/* IF THE EVENT IS MARKED FAVORITE */}
          <div
            className="event-favorite"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleFavorites(e)}
          >
            <i
              className={`${
                favoriteEvents.includes(event) ? "fas" : "far"
              } fa-heart`}
              style={{ color: "black" }}
            />
          </div>
          <div className="event-info">
            {/* EVENT NAME */}
            <h5>
              <Link to={"/event_details/" + event?.id} className="text-dark">
                {truncateText(event?.name, 18)}
              </Link>
            </h5>

            {/* VENUE */}
            <div className="text-black-50 small">
              {truncateText(
                event?.event_dates[0].venue
                  ? event?.event_dates[0].venue
                  : "Online",
                25
              )}
              &nbsp;
            </div>

            {/* EVENT START TIME */}
            <div className="text-black-50 small">
              {formatDate(
                convertTimestampToDateTime(event?.event_dates[0]?.start_date)
              )}
              &nbsp;
            </div>
          </div>

          {/* EVENT PRICE */}
          <div className="price-wrap">
            <span className="price-new mb-2 d-flex flex-column align-items-end">
              {event?.event_dates[0]?.tickets[0]?.ticket_price
                ? `UGX ${formatNumberWithCommas(
                    event?.event_dates[0]?.tickets[0]?.promotional_price ||
                      event?.event_dates[0]?.tickets[0]?.ticket_price
                  )}`
                : "FREE"}
            </span>
            <span>
              <Link
                className={
                  event?.event_dates[0]?.tickets[0]?.ticket_price === 0 ||
                  event?.event_dates[0]?.tickets[0]?.ticket_price === null ||
                  event?.event_dates[0]?.tickets[0]?.ticket_price === " "
                    ? "px-4 btn btn-outline-primary btn-block text-capitalize cursor-pointer"
                    : "btn btn-outline-primary btn-block text-capitalize cursor-pointer"
                }
                style={{
                  paddingBottom: 3,
                  paddingTop: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: "pointer",
                }}
                to={"/event_details/" + event?.id}
              >
                {event?.event_dates[0]?.tickets[0]?.ticket_price === 0 ||
                event?.event_dates[0]?.tickets[0]?.ticket_price === null ||
                event?.event_dates[0]?.tickets[0]?.ticket_price === " "
                  ? "RSVP"
                  : "Buy ticket"}
              </Link>
            </span>
          </div>
          <div className="clearfix" />
        </div>

        {/* EVENT CATEOGRY */}
        <span className="event-category">{event?.category.label}</span>
        <div className="event-date text-center">
          {/* MONTH */}
          <div
            className="event-month text-light"
            style={{ backgroundColor: layout_page?.primary_color }}
          >
            {convertTimestampToDateTime(
              event?.event_dates[0]?.start_date
            ).toLocaleString("en-US", {
              month: "short",
            })}
          </div>

          {/* DAY */}
          <div className="event-day bg-white">
            {convertTimestampToDateTime(
              event?.event_dates[0]?.start_date
            ).getDate()}
          </div>
        </div>
      </div>
    </div>
  );
}
