import React, { useContext } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../user_context";
import { isMobile } from "react-device-detect";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Mypointsofsale() {
  const { isOrganizer } = useContext(myContext);
  const navigate = useNavigate();
  const layout_page = useSettings((state) => state.layout_page);

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"My points of sale"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE BAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"mypointsofsale"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        0 result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 text-center mb-3 mb-lg-0">
                      <form>
                        <ul className="list-inline">
                          <li className="list-inline-item dropdown">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              Search
                            </a>
                            <div
                              className="dropdown-menu dropdown-menu-arrow p-3"
                              style={{
                                "min-width": "300px",
                                "max-width": "500px",
                              }}
                            >
                              <label htmlFor="username">Username</label>
                              <input
                                id="username"
                                name="username"
                                type="text"
                                className="form-control"
                                defaultValue
                              />
                            </div>
                          </li>

                          <li className="list-inline-item dropdown">
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              Enabled
                            </a>
                            <div
                              className="dropdown-menu dropdown-menu-arrow p-3"
                              style={{
                                "min-width": "450px",
                                "max-width": "550px",
                              }}
                            >
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="enabled-all"
                                  name="enabled"
                                  defaultValue="all"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enabled-all"
                                >
                                  All
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="enabled-yes"
                                  name="enabled"
                                  defaultValue={1}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enabled-yes"
                                >
                                  Enabled only
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id="enabled-no"
                                  name="enabled"
                                  defaultValue={0}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="enabled-no"
                                >
                                  Disabled only
                                </label>
                              </div>
                            </div>
                          </li>

                          <li className="list-inline-item ml-3">
                            <button
                              type="submit"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                            >
                              <i className="fas fa-search" />
                            </button>
                          </li>
                        </ul>
                      </form>
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                        >
                          <option
                            data-direction="desc"
                            data-criteria="u.createdAt"
                            value="/en/dashboard/organizer/my-points-of-sale?sort=u.createdAt&direction=desc&page=1"
                          >
                            Creation date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="u.createdAt"
                            value="/en/dashboard/organizer/my-points-of-sale?sort=u.createdAt&direction=asc&page=1"
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/addpointsofsale"
                        className="btn text-white ml-3"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="tooltip"
                        title="Add a new point of sale"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* ALERTS */}
                <div className="alert alert-info alert-icon">
                  <i className="fas fa-exclamation-circle" /> No points of sale
                  found
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
