import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  capitalizeWords,
  convertTimestampToDateTime,
  formatDate,
} from "../../services/helpers";
import { useEvents } from "../../store/event_store";
import "./slider.css";
import "./slider_mobile.css";
import { useSettings } from "../../store/setting_store";

export default function EventsSlider() {
  const sliders = useSettings((state) => state.sliders);
  const getEvent = useEvents((state) => state.getEvent);
  const getHomepageSliders = useSettings((state) => state.getHomepageSliders);
  const [homepage_sliders, setHomepageSliders] = useState([]);

  useEffect(() => {
    const getSliders = async () => {
      await getHomepageSliders();
    };

    const fetchData = async () => {
      const homepageSliders = [];
      for (let i = 0; i < sliders.length; i++) {
        const eventDetails = await getEvent(sliders[i]?.value);
        homepageSliders.push(eventDetails);
      }
      setHomepageSliders(homepageSliders);
    };
    //getting sliders
    getSliders();
    fetchData();
  }, [sliders?.length, homepage_sliders.length]);

  return (
    <Carousel>
      {homepage_sliders?.slice(0, 10).map((event, index) => {
        return (
          <Carousel.Item key={index}>
            <div className="carousel-overlay">
              <div className="carousel-content">
                <h3 className="event-name mb-3">
                  {capitalizeWords(event?.name)}
                </h3>

                <h5 className="text-light mb-2">
                  <i className="fas fa-map-marker-alt fa-fw" />
                  {event?.event_dates[0]?.venue ?? "Online"}
                </h5>

                <h5 className="text-light mb-4">
                  <i className="fas fa-clock fa-fw" />{" "}
                  {formatDate(
                    convertTimestampToDateTime(
                      event?.event_dates[0]?.start_date
                    )
                  )}
                </h5>
                <Link
                  to={"/event_details/" + event?.id}
                  className="btn btn-light btn-lg"
                >
                  <i className="fas fa-ticket-alt fa-fw" /> Buy tickets
                </Link>
              </div>
            </div>
            <img
              className="d-block w-100 slider-image"
              src={event?.main_event_image}
              alt={event?.name}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
