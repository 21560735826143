/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import PopingOut from "./PopingOut";
import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import { useEvents } from "../../store/event_store";
import { userStore } from "../../store/user_store";
import { useVenues } from "../../store/venues_store";
import TicketsNumber from "./components/TicketsNumber";
import CircleProgressBar from "./components/CircleProgressBar";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function ManageEvent() {
  const navigate = useNavigate();
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);
  const getOrganizerVenues = useVenues((state) => state.getOrganizerVenues);
  const organizerEvents = useEvents((state) => state.organizerEvents);
  const events = useEvents((state) => state.events);
  const layout_page = useSettings((state) => state.layout_page);

  const { event_id } = useParams();

  const getEvent = useEvents((state) => state.getEvent);

  // OTHER VARIABLES
  const [organizerDetails, setOrganizerDetails] = useState({});
  const [organizerEvent, setOrganizerEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [organizerVenues, setOrganizerVenues] = useState([]);

  // FUNCTION TO GET THE DETAILS OF THE USER

  useEffect(() => {
    // FUNCTION TO GET THE DETAILS OF THE USER
    const fetchData = async () => {
      const organizerDetails = await getOrganizerDetails(user?.user_id);
      setOrganizerDetails(organizerDetails);

      const organizer_venues = await getOrganizerVenues(user?.user_id);
      setOrganizerVenues(organizer_venues);

      //displaying a specific event
      if (event_id) {
        const specificEvent = await getEvent(event_id);
        setOrganizerEvent([specificEvent]);
      }
    };

    fetchData();
  }, [organizerEvents?.length, events?.length, organizerEvent?.length]);

  // DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"My Events"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"myevents"} />}
              </aside>

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {organizerEvent?.length} Event result(s) found
                      </span>
                    </div>

                    <div className="col-sm-12 col-lg-8 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                        >
                          <option
                            data-direction="desc"
                            data-criteria="e.createdAt"
                            value="/en/dashboard/organizer/my-events?sort=e.createdAt&direction=desc&page=1"
                          >
                            Creation date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="e.createdAt"
                            value="/en/dashboard/organizer/my-events?sort=e.createdAt&direction=asc&page=1"
                          >
                            Creation date (asc)
                          </option>
                          <option
                            data-direction="desc"
                            data-criteria="e.views"
                            value="/en/dashboard/organizer/my-events?sort=e.views&direction=desc&page=1"
                          >
                            Popularity (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="e.views"
                            value="/en/dashboard/organizer/my-events?sort=e.views&direction=asc&page=1"
                          >
                            Popularity (asc)
                          </option>
                        </select>
                      </label>
                      <a
                        href="/add_event"
                        className="btn text-white ml-3 my-2"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                      >
                        <i className="fas fa-plus" /> Add a new event
                      </a>
                    </div>

                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="search-filters"
                        data-original-title="Toggle display the search filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />

                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="slug">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="slug"
                              name="slug"
                              data-url-list="/en/api/get-events?organizer=mestil-hotel-1&published=all&elapsed=all"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="category">Category</label>
                            <select
                              id="category"
                              name="category"
                              className="form-control bg-white select2"
                              data-sort-options={1}
                            >
                              <option value="all">All</option>

                              <option value="other">Other</option>

                              <option value="concert-music">
                                Concert / Music
                              </option>
                              <option value="trip-camp">Trip / Camp</option>
                              <option value="sport-fitness-1">
                                Sport / Fitness
                              </option>
                              <option value="cinema">Cinema</option>
                              <option value="game-competition">
                                Game / Competition
                              </option>
                              <option value="conference">Conference</option>
                              <option value="museum-monument">
                                Museum / Monument
                              </option>
                              <option value="recreation-park-attraction">
                                Recreation park / Attraction
                              </option>
                              <option value="theater">Theater</option>
                              <option value="restaurant-gastronomy">
                                Restaurant / Gastronomy
                              </option>
                              <option value="exposition">Exposition</option>
                              <option value="festival-spectacle">
                                Festival / Spectacle
                              </option>
                              <option value="workshop-training">
                                Workshop / Training
                              </option>
                              <option value="parking-services">
                                Parking / Services
                              </option>
                            </select>
                          </div>

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="venue">Venue</label>
                            <select
                              id="venue"
                              name="venue"
                              className="form-control bg-white select2"
                              data-sort-options={1}
                            >
                              <option value="all">All</option>

                              {/* ORGANIZER VENUES */}
                              {organizerVenues.map((venue) => (
                                <option value={venue?.id}>{venue?.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="elapsed-filter-all"
                                type="radio"
                                name="elapsed"
                                defaultValue="all"
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="elapsed-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="elapsed-filter-yes"
                                type="radio"
                                name="elapsed"
                                defaultValue={1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="elapsed-filter-yes"
                                className="custom-control-label"
                              >
                                Elapsed only
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="elapsed-filter-no"
                                type="radio"
                                name="elapsed"
                                defaultValue={0}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="elapsed-filter-no"
                                className="custom-control-label"
                              >
                                Not elapsed only
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-all"
                                type="radio"
                                name="published"
                                defaultValue="all"
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="published-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-yes"
                                type="radio"
                                name="published"
                                defaultValue={1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="published-filter-yes"
                                className="custom-control-label"
                              >
                                Published only
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-no"
                                type="radio"
                                name="published"
                                defaultValue={0}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="published-filter-no"
                                className="custom-control-label"
                              >
                                Draft only
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <button
                              type="submit"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Search"
                            >
                              <i className="fas fa-search" />
                            </button>
                            <button
                              type="reset"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Reset"
                            >
                              <i className="fas fa-search-minus" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {organizerEvent?.length === 0 ? (
                  //IF NO EVENTS FOUND
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" />
                    Event not found
                  </div>
                ) : (
                  //DISPLAYING USER EVENTS
                  <div class="table-responsive" style={{ width: "100%" }}>
                    <table className="table table-hover table-vcenter">
                      <thead>
                        <tr>
                          <th style={{ width: "35%" }}>Event</th>
                          <th>Organizer</th>
                          <th>Sales</th>
                          <th>Status</th>
                          <th>Attendance</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {organizerEvent?.map((event, index) => {
                          return (
                            <tr key={event?.id}>
                              {/* EVENT DETAILS */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {/* EVENT IMAGE */}
                                  <Link
                                    className="fancybox"
                                    to={"/event_details/" + event?.id}
                                    data-toggle="tooltip"
                                    data-original-title="Enlarge"
                                  >
                                    <img
                                      src={event?.main_event_image}
                                      className="img-thumbnail img-50-50"
                                      alt={event?.name}
                                    />
                                  </Link>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={"/event_details/" + event?.id}
                                      data-toggle="tooltip"
                                      data-original-title="View event"
                                    >
                                      {event.name}
                                    </Link>
                                    {/* <span className="small text-muted"># {event.street_address}</span> */}
                                  </div>
                                </div>
                              </td>

                              {/* EVENT ORGANIZER */}
                              <td>
                                <Link
                                  style={{
                                    color: layout_page?.primary_color,
                                  }}
                                  to={
                                    "/organizer_details/" + event?.organizer_id
                                  }
                                  data-toggle="tooltip"
                                  data-original-title="View organizer"
                                >
                                  {organizerDetails?.organizer_name}
                                </Link>
                              </td>

                              {/* NUMBER OF TICKETS */}
                              <td>
                                <TicketsNumber
                                  event_id={event?.id}
                                  number_of_tickets={
                                    event?.event_dates[index]?.tickets[index]
                                      ?.number_of_tickets
                                  }
                                />
                              </td>

                              {/* STATUS */}
                              <td>
                                {event?.visibility !== "draft" ? (
                                  <span className="badge badge-success">
                                    On sale
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Event not published
                                  </span>
                                )}
                              </td>

                              {/* ATTENDEES */}
                              <td className="text-center">
                                <CircleProgressBar event_id={event?.id} />
                              </td>

                              {/* ACTIONS */}
                              <td className="text-center">
                                <PopingOut
                                  event_id={event?.id}
                                  setIsLoading={setIsLoading}
                                  status={event?.visibility}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
