import Description from './Description';
import Input from './Input';

export default function AddBeneficiaryBankAccount() {
  return (
    <>
      <Input placeholder="Enter name of bank" label="Bank Name" isRequired />
      <Input placeholder="Enter account name" label="Account Name" isRequired />
      <Input placeholder="Enter number" label="Account Number" isRequired />
      <Input
        placeholder="Enter brief description"
        label="Brief Description"
        info={<Description />}
        isRequired
      />
    </>
  );
}
