import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { Timestamp } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "@mui/material";
import { useForm, Controller, set } from "react-hook-form";
import AdminSideNav from "../components/admin_side_nav";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { usePromoCodes } from "../../../store/promocode_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import Header from "../../../components/header/header";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import Footer from "../../../components/footer/footer";
import Loader from "../../../components/loader/loader";
export default function EditPromoCodes() {
  // RECEIVING THE PROMOCODE DATA
  const promoCodeData = useLocation().state.promoCode;
  const [code] = useState(promoCodeData?.code);
  const [description] = useState(promoCodeData?.description);
  const [discount] = useState(promoCodeData?.discount);
  const [discountType] = useState(promoCodeData?.discountType);
  const [startDate, setStartDate] = useState(
    convertTimestampToDateTime(promoCodeData?.startDate)
  );
  const [endDate, setEndDate] = useState(
    convertTimestampToDateTime(promoCodeData?.endDate)
  );
  const [quantity, setQuantity] = useState(promoCodeData?.totalForPromoRun);
  const [tickets_per_user, setTicketsPerUser] = useState(
    promoCodeData?.ticketsPerUser
  );
  const [eventId] = useState(promoCodeData?.eventId);
  const [isActive, setIsActive] = useState(promoCodeData?.isActive);
  const [selectedTickets, setSelectedTickets] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [eventTickets, setEventTickets] = useState([]);
  const isAdmin = userStore((state) => state.isAdmin);
  const user = userStore((state) => state.user);
  const updatePromoCode = usePromoCodes((state) => state.updatePromoCode);
  const getEvent = useEvents((state) => state.getEvent);
  const layout_page = useSettings((state) => state.layout_page);
  const events = useEvents((state) => state.events);

  const navigate = useNavigate();

  async function handleSelectEvent(event_id) {
    const eventDetails = await getEvent(event_id);
    setEventTickets(eventDetails?.event_dates[0]?.tickets);

    // selecting the tickets
    // selecting the tickets
    const ticketspro = promoCodeData?.ticketIds.map((ticket_id) => {
      return eventDetails?.event_dates[0]?.tickets.find(
        (ticket) => ticket.id === ticket_id
      );
    });
    setSelectedTickets(ticketspro);
  }

  const filteredEvents = events.filter(
    (event) => event?.organizer_id === promoCodeData?.organizerId
  );

  //getting the ticket names of the event

  useEffect(() => {
    if (eventId) {
      handleSelectEvent(eventId);
    }
  }, [eventId]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  // DISCOUNT TYPE
  const selectedDiscountType = watch("discountType");

  // HANDLING UPLOADING THE BLOG POST
  const handleEditPromocode = async (result) => {
    if (!user) {
      navigate("/sign_in");
    }
    setIsLoading(true);
    const ticketIds = selectedTickets?.map((ticket) => {
      return ticket?.value;
    });
    const data = {
      code: result.code,
      description: result.description,
      discount: result.discount,
      discountType: result.discountType,
      startDate: Timestamp.fromDate(startDate),
      endDate: Timestamp.fromDate(endDate),
      totalForPromoRun: parseInt(quantity),
      eventId: result.eventId,
      isActive: isActive,
      updatedAt: Timestamp.fromDate(new Date()),
      ticketIds: ticketIds,
      ticketsPerUser: parseInt(tickets_per_user),
    };

    const response = await updatePromoCode(promoCodeData?.id, data);
    if (response) {
      displaySuccessToast("Promo code updated successfully");
      navigate("/promocodes");
    } else {
      displayErrorToast("Something Went Wrong Updating Promo Code");
    }
    setIsLoading(false);
  };

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  // HANDLE ACTIVE
  const handleActive = (e) => {
    setIsActive(e.target.checked);
  };

  if (!isAdmin) {
    navigate("/access_denied");
  }
  return (
    <div>
      <Header />
      <Breadcrumpadmin2
        title="Edit Promocode"
        page_link="Manage Promocode"
        link="promocodes"
      />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* SIDEBAR */}
            {!isMobile && <AdminSideNav currentPage={"promocodes"} />}

            {/* MAIN CONTENT */}
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <form
                    name="promocode_form"
                    onSubmit={handleSubmit(handleEditPromocode)}
                  >
                    {/* IS ACTIVE */}
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <div>
                        <label htmlFor="scanner_username" className="required">
                          Is Promocode Active?
                        </label>
                      </div>

                      <div className="ml-auto">
                        <Switch
                          color="warning"
                          onChange={handleActive}
                          checked={isActive}
                        />
                      </div>
                    </div>

                    {/* CODE */}
                    <div className="form-group">
                      <label htmlFor="scanner_username" className="required">
                        Code
                      </label>

                      <Controller
                        name="code"
                        control={control}
                        rules={{ required: "Code is required" }}
                        defaultValue={code}
                        render={({ field }) => (
                          <input
                            type="text"
                            id="code"
                            className={`form-control ${
                              errors.code ? "is-invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />

                      {/* Display error message */}
                      {errors.code && (
                        <div className="invalid-feedback">
                          {errors.code.message}
                        </div>
                      )}
                    </div>

                    {/* EVENTS DROPDOWN */}
                    <div className="form-group">
                      <label htmlFor="scanner_username" className="required">
                        Event
                      </label>

                      <Controller
                        name="eventId"
                        control={control}
                        rules={{ required: "Event is required" }}
                        defaultValue={eventId}
                        render={({ onChange, field }) => (
                          <select
                            id="eventId"
                            className={`form-control ${
                              errors.eventId ? "is-invalid" : ""
                            }`}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              handleSelectEvent(e.target.value);
                            }}
                          >
                            <option value="">Select Event</option>
                            {filteredEvents?.map((event) => (
                              <option key={event.id} value={event.id}>
                                {event?.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />

                      {/* Display error message */}
                      {errors.eventId && (
                        <div className="invalid-feedback">
                          {errors.eventId.message}
                        </div>
                      )}
                    </div>

                    {/* TICKETS DROPDOWN */}
                    {eventTickets?.length > 0 && (
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Ticket
                        </label>

                        <SearchableDropdown
                          control={control}
                          rules={{ required: "Ticket is required" }}
                          id="ticketId"
                          className={`form-control ${
                            errors.ticketId ? "is-invalid" : ""
                          }`}
                          isMulti={true}
                          value={selectedTickets.map((ticket) => ({
                            value: ticket.id,
                            label: ticket.ticket_name,
                          }))}
                          options={eventTickets?.map((ticket) => ({
                            value: ticket.id,
                            label: ticket.ticket_name,
                          }))}
                          onChange={(selectedOptions) =>
                            setSelectedTickets(selectedOptions)
                          }
                        />

                        {/* Display error message */}
                        {errors.ticketId && (
                          <div className="invalid-feedback">
                            {errors.ticketId.message}
                          </div>
                        )}
                      </div>
                    )}

                    {/* DESCRIPTION */}
                    <div className="form-group">
                      <label htmlFor="scanner_username" className="required">
                        Description
                      </label>

                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: "Description is required" }}
                        defaultValue={description}
                        render={({ field }) => (
                          <input
                            type="text"
                            id="description"
                            className={`form-control ${
                              errors.description ? "is-invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />

                      {/* Display error message */}
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description.message}
                        </div>
                      )}
                    </div>

                    {/* DISCOUNT TYPE */}
                    <div className="form-group">
                      <label htmlFor="scanner_username" className="required">
                        Discount Type
                      </label>
                      <Controller
                        name="discountType"
                        control={control}
                        rules={{ required: "Discount Type is required" }}
                        defaultValue={discountType}
                        render={({ field }) => (
                          <select
                            id="discountType"
                            className={`form-control ${
                              errors.discountType ? "is-invalid" : ""
                            }`}
                            {...field}
                          >
                            <option value="">Select Discount Type</option>
                            <option value="percentage">Percentage</option>
                            <option value="amount">Fixed Amount</option>
                          </select>
                        )}
                      />

                      {/* Display error message */}
                      {errors.discountType && (
                        <div className="invalid-feedback">
                          {errors.discountType.message}
                        </div>
                      )}
                    </div>

                    {/* DISCOUNT */}
                    <div className="form-group">
                      <label htmlFor="scanner_username" className="required">
                        Discount
                      </label>

                      <Controller
                        name="discount"
                        control={control}
                        rules={{
                          required: "Discount is required",
                          validate: (value) => {
                            // Check if the selected discount type is 'percentage'
                            if (selectedDiscountType === "percentage") {
                              // Validate that the value is not greater than 90
                              return (
                                (value >= 0 && value <= 100) ||
                                "Discount should be between 0 and 100%"
                              );
                            }
                            // Check if the selected discount type is 'amount'
                            if (selectedDiscountType === "amount") {
                              const selectedTicketPrices = selectedTickets.map(
                                (selectedTicket) => {
                                  const ticket = eventTickets.find(
                                    (ticket) =>
                                      ticket.id === selectedTicket.value
                                  );
                                  return ticket ? ticket.ticket_price : 0; // Use 0 as default price if ticket is not found
                                }
                              );
                              const ticketPrice = Math.min(
                                ...selectedTicketPrices
                              );

                              return (
                                (value >= 0 && value < ticketPrice) ||
                                "Fixed amount discount should be less than the ticket price"
                              );
                            }

                            return true; // For other discount types, no validation needed
                          },
                        }}
                        defaultValue={discount}
                        render={({ field }) => (
                          <input
                            type="text"
                            id="discount"
                            className={`form-control ${
                              errors.discount ? "is-invalid" : ""
                            }`}
                            {...field}
                          />
                        )}
                      />

                      {/* Display error message */}
                      {errors.discount && (
                        <div className="invalid-feedback">
                          {errors.discount.message}
                        </div>
                      )}
                    </div>

                    {/* START DATE */}
                    <div className="form-group d-grid">
                      <label htmlFor="start_date" className="required">
                        Start Date
                      </label>

                      <DatePicker
                        required
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        showTimeSelect
                        value={startDate}
                        dateFormat={"dd/MM/yyyy p"}
                        placeholderText="Select Start Date For Promo Code"
                        minDate={new Date()}
                      />
                    </div>

                    {/* END DATE */}
                    <div className="form-group d-grid">
                      <label htmlFor="scanner_username" className="required">
                        End Date
                      </label>

                      <DatePicker
                        required
                        className="form-control"
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date);
                        }}
                        showTimeSelect
                        value={endDate}
                        dateFormat={"dd/MM/yyyy p"}
                        placeholderText="Select Start Date For Promo Code"
                        minDate={startDate}
                      />
                    </div>

                    {/* TOTAL FOR PROMO */}
                    <div className="form-group">
                      <label htmlFor="scanner_username">Total for Promo</label>
                      <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={quantity}
                        className="form-control"
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    {/* QUANTITY */}
                    <div className="form-group">
                      <label htmlFor="tickets_per_user">Tickets Per User</label>

                      <input
                        type="number"
                        id="tickets_per_user"
                        name="tickets_per_user"
                        value={tickets_per_user}
                        className="form-control"
                        onChange={(e) => setTicketsPerUser(e.target.value)}
                      />
                    </div>

                    {/* SAVE */}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn text-white btn"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        Save
                      </button>

                      {/* CANCEL BUTTON */}
                      <button
                        className="btn btn-dark btn ml-3"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
