import React, { useContext, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { myContext } from "../../../user_context";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import { displaySuccessToast } from "../../../services/helpers";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function AddCurrency() {
  const [ccy, setCCY] = useState("");
  const [symbol, setSymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const addCurrency = useSettings((state) => state.addCurrency);
  const layout_page = useSettings((state) => state.layout_page);

  // HANDLING UPLOADING THE CURRENCY
  const handleAddCurrency = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      await addCurrency({
        ccy: ccy,
        symbol: symbol,
      });
      navigate("/manage_currencies");
      displaySuccessToast("Currency added successfully");
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CCY
    if (ccy.trim() === "") {
      errors.ccy = "CCI is required";
    }

    // VALIDATING SYMBOL
    if (symbol.trim() === "") {
      errors.symbol = "Symbol is required";
    }

    return errors;
  }

  // const {isAdmin} = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new currency"
          page_link="Payment settings"
          link="manage_payments"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="currency"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleAddCurrency}
                    >
                      <div id="currency">
                        <div className="form-group">
                          <label htmlFor="currency_ccy" className="required">
                            CCY
                          </label>
                          <small
                            id="currency_ccy_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Please refer to the following list and use the Code
                            column: https://en.wikipedia.org/wiki/ISO_4217
                          </small>
                          <input
                            type="text"
                            id="currency_ccy"
                            name="currency[ccy]"
                            required="required"
                            aria-describedby="currency_ccy_help"
                            className="form-control"
                            onChange={(e) => setCCY(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="currency_symbol" className="required">
                            Currency symbol
                          </label>
                          <input
                            type="text"
                            id="currency_symbol"
                            name="currency[symbol]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setSymbol(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="currency_save"
                            name="currency[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="currency__token"
                          name="currency[_token]"
                          defaultValue="d8-BCAvUIy2Mk-CDKcBEY7GWZKhGxnn-KH4WC-bemag"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
