import React, { useEffect, useState } from "react";
import { useEvents } from "../../../store/event_store";

const AudienceCount = ({ audience }) => {
  const events = useEvents((state) => state.events);
  const [count, setCount] = useState(0); // Initialize count with 0

  useEffect(() => {
    const fetchCount = () => {
      if (audience.name) {
        const c = events.filter((event) =>
          event?.audiences?.some(
            (eventAudience) => eventAudience === audience.name
          )
        );
        setCount(c.length);
      } else {
        setCount(0);
      }
    };
    fetchCount();
  }, [events, audience.name]); // Include both events and audience.name in the dependency array

  return <div>{count}</div>;
};

export default AudienceCount;
