import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userStore } from "./../../../store/user_store";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import app from "../../../Firebase";
import {
  attendee_sidebar_options1,
  attendee_sidebar_options2,
} from "../../../components/header/sidebar_options";
import zIndex from "@material-ui/core/styles/zIndex";

export default function AttendeeSideNav({ activeTab }) {
  const auth = getAuth(app);

  const cartItems = userStore((state) => state.cartItems);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        // Get the user's email address
        const email = auth.currentUser.email;

        // Check if email has an associated account
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        // Check if the user registered with Google
        const isGoogleProvider = signInMethods.some((method) =>
          method.includes("google.com")
        );
        setIsGoogleSignIn(isGoogleProvider);
      }
    };

    fetchUser();
  }, []);

  return (
    <aside className="col-lg-3 pt-3 pt-lg-0">
      <div
        className={`h-100 sticky-top sticky-sidebar dashboard-sidebar ${"my-side-nav"}`}
      >
        <ul
          id="dashboard-menu"
          className="nav nav-pills nav-pills-vertical-styled overflow-auto"
          style={{ maxHeight: "20rem" }}
        >
          {attendee_sidebar_options1?.map((option, index) => (
            <li key={zIndex} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (activeTab === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}
          {/* CART */}
          <li className="nav-item">
            <Link
              to="/cart"
              className={"nav-link" + (activeTab === "cart" ? " active" : "")}
            >
              <i className="fas fa-shopping-cart fa-fw" /> My cart
              <span className="badge badge-info float-right">
                {cartItems?.length}
              </span>
            </Link>
          </li>

          {attendee_sidebar_options2?.map((option, index) => (
            <li key={index} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (activeTab === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}
