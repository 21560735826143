import { FaAsterisk } from 'react-icons/fa';

export default function Input({ label, isRequired = false, info, ...rest }) {
  return (
    <div className="input-form-container">
      <label>
        <span>{label}</span>
        {isRequired && (
          <FaAsterisk
            color="#F76300"
            size={10}
            style={{ alignSelf: 'start' }}
          />
        )}
        {info}
      </label>
      <input {...rest} className="input-form" />
    </div>
  );
}
