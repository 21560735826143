import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import TextEditor from "../../components/text_editor/text_editor";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import { getCountriesList, getEventsCategories } from "../../services/events";
import {
  deleteOrganizerImage,
  deleteOrganizerLogo,
  uploadOrganizerImage,
  uploadOrganizerLogo,
} from "../../services/users";
import { getOrganizerDetails } from "../../services/users";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { useSettings } from "../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { auth } from "../../Firebase";

export default function OrganizerProfile() {
  // const { isOrganizer, user } = useContext(myContext);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const user = userStore((state) => state.user);
  const updateOrganizerProfile = userStore(
    (state) => state.updateOrganizerProfile
  );
  const layout_page = useSettings((state) => state.layout_page);

  const [eventCategories, setEventCategories] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [organizerData, setOrganizerData] = useState({});
  const organizerId = user?.user_id;

  useEffect(() => {
    // GETTING THE EVENT CATEGORIES
    const fetchData = async () => {
      const returnedCategories = await getEventsCategories();
      const event_categories = [];

      for (var i = 0; i < returnedCategories.length; i++) {
        event_categories.push({
          value: returnedCategories[i].id,
          label: returnedCategories[i].name,
        });
      }
      setEventCategories(event_categories);

      // GETTING THE COUTNRIES LIST
      const returnedCountries = await getCountriesList();
      const countries = [];

      for (var j = 0; j < returnedCountries.length; j++) {
        countries.push({
          value: returnedCountries[j].countryCode,
          label: returnedCountries[j].name,
        });
      }
      setCountriesList(countries);

      //GETTING ORGANIZER DETAILS FOR DEFAULT FIELDS
      // GETTING ORGANIZER NAME
      const organizerDetails = await getOrganizerDetails(organizerId);
      setOrganizerData(organizerDetails);
    };

    // CALLING THE METHODS
    fetchData();
  }, [organizerId]);

  // HANDLING UPLOADING THE BLOG POST
  const handleUpdateOrganizerProfile = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await updateOrganizerProfile(organizerId, {
        organizer_name: organizerData?.organizer_name,
        email: organizerData?.email,
        about: organizerData?.about,
        organizer_logo: organizerData?.organizer_logo,
        cover_photo: organizerData?.cover_photo,
        country: organizerData?.country,
        website: organizerData?.website,
        phone: organizerData?.phone,
        facebook: organizerData?.facebook,
        twitter: organizerData?.twitter,
        instagram: organizerData?.instagram,
        linkedin: organizerData?.linkedin,
        youtubeVideoUrl: organizerData?.youtubeVideoUrl,
        showVenuesMap: organizerData?.showVenuesMap,
        showFollowers: organizerData?.showFollowers,
        showReviews: organizerData?.showReviews,
        updated_at: Timestamp.fromDate(new Date()),
      });
      setIsLoading(false);
      navigate("/dashboard_organizer");
      displaySuccessToast("Updated successfully");
    } catch (error) {
      console.log("Update failed", error);
      displayErrorToast("Error in updating the profile");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrganizerData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLING IMAGE CAPTURE
  const [logoUrl, setLogoUrl] = useState(organizerData?.organizer_logo);
  useEffect(() => {
    const fetchLogoUrl = async () => {
      const url = await uploadOrganizerLogo(organizerData?.organizer_logo);
      setLogoUrl(url);
    };
    //fetch th cover photo Url from firebase
    if (organizerData?.organizer_logo) {
      fetchLogoUrl();
    }
  }, [organizerData?.organizer_logo]);

  const handleOrganizerLogo = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadOrganizerImage(file);
      if (imageUrl) {
        setLogoUrl(imageUrl);
        setOrganizerData((prevFormData) => ({
          ...prevFormData,
          organizer_logo: imageUrl,
        }));
      }
    }
  };
  //HANDLING DELETE THE ORGANIZER_LOGO
  const handleDeleteOrganizerLogo = async () => {
    if (logoUrl) {
      await deleteOrganizerLogo(logoUrl);
      setLogoUrl("");
      setOrganizerData((prevFormData) => ({
        ...prevFormData,
        organizer_logo: "",
      }));
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLING IMAGE CAPTURE
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(
    organizerData?.cover_photo || ""
  );

  useEffect(() => {
    const fetchCoverPhotoUrl = async () => {
      const url_cover = await uploadOrganizerImage(organizerData?.cover_photo);
      setCoverPhotoUrl(url_cover);
    };
    //fetch th cover photo Url from firebase
    if (organizerData?.cover_photo) {
      fetchCoverPhotoUrl();
    }
  }, [organizerData?.cover_photo]);

  // HANDLE DELETE THE COVER PHOTO
  const handleDeleteCoverPhoto = async () => {
    if (coverPhotoUrl) {
      await deleteOrganizerImage(coverPhotoUrl);
      setCoverPhotoUrl("");
      setOrganizerData((prevFormData) => ({
        ...prevFormData,
        cover_photo: "",
      }));
    }
  };
  //HANDLE COVER PHOTO
  const handleCoverPhoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadOrganizerImage(file);
      if (imageUrl) {
        setCoverPhotoUrl(imageUrl);
        setOrganizerData((prevFormData) => ({
          ...prevFormData,
          cover_photo: imageUrl,
        }));
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // HANDLE ABOUT
  const handleAboutChange = (value) => {
    setOrganizerData((prevFormData) => ({
      ...prevFormData,
      about: value,
    }));
  };

  //ENABLE ALL RADIO BUTTONS
  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setOrganizerData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function getEmbeddedYouTubeUrl(youtubeUrl) {
    const videoId = youtubeUrl.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"Edit your organizer profile"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"organizer_profile"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <div className="alert alert-info alert-icon">
                      <i className="fas fa-info-circle" />{" "}
                      <Link to="/previeworg_profile">Click here</Link> to
                      preview your profile
                    </div>
                    <form
                      name="organizer_profile"
                      method="post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleUpdateOrganizerProfile}
                    >
                      <div id="organizer_profile">
                        {/* ORGANIZER NAME */}

                        <div className="form-group">
                          <label
                            htmlFor="organizer_profile_name"
                            className="required"
                          >
                            Organizer name
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_name"
                            name="organizer_name"
                            required="required"
                            className="form-control"
                            value={organizerData?.organizer_name}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* ABOUT THE ORGANIZER */}

                        <div className="form-group">
                          <label
                            for="organizer_profile_description"
                            class="required"
                          >
                            About the organizer
                          </label>
                          <TextEditor
                            name="about"
                            onChange={handleAboutChange}
                            value={organizerData?.about}
                          />
                        </div>

                        {/* ORGANIZER LOGO */}
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Organizer logo
                          </legend>
                          {logoUrl && (
                            <div>
                              <img
                                src={organizerData?.organizer_logo} // Use logoUrl instead of organizerData?.organizer_logo
                                alt=""
                                style={{ maxWidth: "50%" }}
                              />
                              <div
                                onClick={handleDeleteOrganizerLogo}
                                className=" bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                          {!logoUrl && (
                            <div className="vich-image">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  id="organizer_profile_logoFile_file"
                                  name="organizer_logo"
                                  required="required"
                                  className="custom-file-input"
                                  onChange={handleOrganizerLogo}
                                />
                                <label
                                  htmlFor="organizer_profile_logoFile_file"
                                  className="custom-file-label"
                                />
                              </div>
                            </div>
                          )}
                        </fieldset>

                        {/* COVER PHOTO */}
                        <fieldset className="form-group">
                          <legend className="col-form-label">
                            Cover photo
                          </legend>
                          {coverPhotoUrl && (
                            <div className=" d-grid">
                              <img
                                src={organizerData?.cover_photo}
                                alt=""
                                style={{ maxWidth: "50%" }}
                              />
                              <div
                                onClick={handleDeleteCoverPhoto}
                                className=" bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                          {!coverPhotoUrl && (
                            <div className="vich-image">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  id="organizer_profile_coverFile_file"
                                  name="cover_photo"
                                  className="custom-file-input"
                                  onChange={handleCoverPhoto}
                                />
                                <label
                                  htmlFor="organizer_profile_coverFile_file"
                                  className="custom-file-label"
                                />
                              </div>
                            </div>
                          )}
                        </fieldset>

                        {/* COUNTRY */}
                        <div className="form-group">
                          <label className="" for="organizer_profile_country">
                            Country
                          </label>

                          <SearchableDropdown
                            value={organizerData?.country}
                            options={countriesList}
                            onChange={(selectedOption) =>
                              handleInputChange({
                                target: {
                                  name: "country",
                                  value: selectedOption,
                                },
                              })
                            }
                            isMulti={false}
                            placeholder={"Select Country"}
                          />
                        </div>

                        {/* WEBSITE */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_website">
                            Website
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_website"
                            name="website"
                            className="form-control"
                            value={organizerData?.website}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* EMAIL */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_email">Email</label>
                          <input
                            type="text"
                            id="organizer_profile_email"
                            name="email"
                            className="form-control"
                            value={organizerData?.email}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* PHONE NUMBER */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_phone">Phone</label>
                          <input
                            type="text"
                            id="organizer_profile_phone"
                            name="phone"
                            className="form-control"
                            value={organizerData?.phone}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* FACEBOOK  */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_facebook">
                            Facebook
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_facebook"
                            name="facebook"
                            className="form-control"
                            value={organizerData?.facebook}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* TWITTER */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_twitter">
                            Twitter
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_twitter"
                            name="twitter"
                            className="form-control"
                            value={organizerData?.twitter}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* INSTAGRAM */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_instagram">
                            Instagram
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_instagram"
                            name="instagram"
                            className="form-control"
                            value={organizerData?.instagram}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* LINKEDIN */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_linkedin">
                            LinkedIn
                          </label>
                          <input
                            type="text"
                            id="organizer_profile_linkedin"
                            name="linkedin"
                            className="form-control"
                            value={organizerData?.linkedin}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>

                        {/* YOUTUBE VIDEO URL */}
                        <div className="form-group">
                          <label htmlFor="organizer_profile_youtubeurl">
                            Youtube video url
                          </label>
                          <small
                            id="organizer_profile_youtubeurl_help"
                            className="form-text text-muted mb-3"
                          >
                            <i
                              className="fas fa-info-circle mr-1"
                              style={{ color: layout_page?.primary_color }}
                            />
                            If you have an Youtube video that represents your
                            activities as an event organizer, add it in the
                            standard format:
                            https://www.youtube.com/watch?v=FzG4uDgje3M
                          </small>
                          <input
                            type="text"
                            id="organizer_profile_youtubeurl"
                            name="youtubeVideoUrl"
                            aria-describedby="organizer_profile_youtubeurl_help"
                            className="form-control"
                            value={organizerData?.youtubeVideoUrl}
                            onChange={(e) => handleInputChange(e)}
                          />
                          {/* YOUTUBE VIDEO */}
                          {/* YOUTUBE VIDEO */}
                          {organizerData?.youtubeVideoUrl && (
                            <div className="form-group">
                              <label htmlFor="organizer_profile_youtubeurl">
                                Youtube Video
                              </label>
                              <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                  title="Organizer Video"
                                  className="embed-responsive-item"
                                  src={getEmbeddedYouTubeUrl(
                                    organizerData?.youtubeVideoUrl
                                  )}
                                  allowFullScreen
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* SHOW VENUES MAP */}
                        {/* SHOW VENUES MAP */}
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show venues map
                          </legend>
                          <small
                            id="organizer_profile_showvenuesmap_help"
                            className="form-text text-muted mb-3"
                          >
                            <i
                              className="fas fa-info-circle mr-1"
                              style={{
                                color: layout_page?.primary_color,
                              }}
                            />
                            Show a map at the bottom of your organizer profile
                            page containing the venues you added
                          </small>
                          <div
                            id="organizer_profile_showvenuesmap"
                            aria-describedby="organizer_profile_showvenuesmap_help"
                          >
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="organizer_profile_showvenuesmap_0"
                                name="showVenuesMap"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={organizerData?.showVenuesMap === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showvenuesmap_0"
                              >
                                Show
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="organizer_profile_showvenuesmap_1"
                                name="showVenuesMap"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={organizerData?.showVenuesMap === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showvenuesmap_1"
                              >
                                Hide
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/* SHOW FOLLOWERS */}
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show followers
                          </legend>
                          <small
                            id="organizer_profile_showfollowers_help"
                            className="form-text text-muted mb-3"
                          >
                            <i
                              className="fas fa-info-circle mr-1"
                              style={{
                                color: layout_page?.primary_color,
                              }}
                            />
                            Show the number and list of people that follow you
                          </small>
                          <div
                            id="organizer_profile_showfollowers"
                            aria-describedby="organizer_profile_showfollowers_help"
                          >
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="organizer_profile_showfollowers_0"
                                name="showFollowers"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={organizerData?.showFollowers === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showfollowers_0"
                              >
                                Show
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="organizer_profile_showfollowers_1"
                                name="showFollowers"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={organizerData?.showFollowers === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showfollowers_1"
                              >
                                Hide
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/* SHOW REVIEWS */}
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show reviews
                          </legend>
                          <small
                            id="organizer_profile_showreviews_help"
                            className="form-text text-muted mb-3"
                          >
                            <i
                              className="fas fa-info-circle mr-1"
                              style={{
                                color: layout_page?.primary_color,
                              }}
                            />
                            Show the reviews that you received for your events
                          </small>
                          <div
                            id="organizer_profile_showreviews"
                            aria-describedby="organizer_profile_showreviews_help"
                          >
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="organizer_profile_showreviews_0"
                                name="showReviews"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={organizerData?.showReviews === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showreviews_0"
                              >
                                Show
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="organizer_profile_showreviews_1"
                                name="showReviews"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={organizerData?.showReviews === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="organizer_profile_showreviews_1"
                              >
                                Hide
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/* SAVE ORGANIZER PROFILE */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="organizer_profile_save"
                            name="organizer_profile[save]"
                            className="btn text-white btn-block btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
