import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import PopingOutManageBlogCategories from "./PopingOutManageBlogCategories";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import BlogPostCount from "./BlogPostCount";
import BreadcrumpAdmin from "../components/breadcrump_admin";
// import { getBlogpostCategories } from "../../../services/blogs";

export default function ManageBlogpostCategories() {
  const isAdmin = userStore((state) => state.isAdmin);
  //FETCHING BLOGPOST CATEGORIES
  const getBlogpostCategories = useBlogs(
    (state) => state.getBlogpostCategories
  );
  const categories = useBlogs((state) => state.categories);
  const [isLoading, setIsLoading] = useState(false);
  // const [categories, setCategories] = useState([])
  const layout_page = useSettings((state) => state.layout_page);
  //getting blog categories
  useEffect(() => {
    async function fetchData() {
      await getBlogpostCategories();
    }
    fetchData();
  }, [categories.length]);

  const navigate = useNavigate();

  //filtering logic
  const [categoryFilter, setCategoryFilter] = useState("");
  const filteredCategories = categories.filter((category) => {
    return (
      !categoryFilter ||
      (category.category &&
        category.category.toLowerCase().includes(categoryFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("added_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedCategories = filteredCategories.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "added_at") {
      const dateA = a.added_at;
      const dateB = b.added_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(20);
  //get current events
  const indexOfLastcategories = currentPage * categoriesPerPage;
  const indexOfFastcategories = indexOfLastcategories - categoriesPerPage;
  const current_categories = sortedCategories?.slice(
    indexOfFastcategories,
    indexOfLastcategories
  );

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Blogpost Categories" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_blogpost_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {categories.length} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`added_at&direction=desc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`added_at&direction=asc`}
                            selected={
                              sortCriteria === "added_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-blog-post-category"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new category"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Blog posts count</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DISPLAYING BLOGPOST CATEGORIES */}
                            {current_categories.map((category) => (
                              <tr key={category?.id}>
                                {/* DISPLAYING BLOGPOST NAME */}
                                <td>{category?.category}</td>
                                {/* DISPLAYING NUMBER OF BLOGPOSTS count */}
                                <td>
                                  <BlogPostCount category_id={category?.id} />
                                </td>

                                {/* DISPLAYING STATUS OF BLOGPOST */}
                                <td>
                                  {category?.status === "visible" ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> Visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />{" "}
                                      Invisible
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <PopingOutManageBlogCategories
                                    blog_category_id={category?.id}
                                    setIsLoading={setIsLoading}
                                    status={category?.status}
                                  />
                                </td>
                              </tr>
                            ))}
                            {/* END OF BLOG POST */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* pagination */}
                  {sortedCategories?.length > 20 && (
                    <CustomPagination
                      setCurrentPage={setCurrentPage}
                      totalPages={Math.ceil(
                        sortedCategories?.length / categoriesPerPage
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
