import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import { toast } from "react-toastify";
import app from "../Firebase";

const db = getFirestore(app);

function useFetchDocument(collectionName, documentID) {
  // a hook to get a document from a firestore if the document exists
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const getDocument = async () => {
      try {
        const docRef = doc(db, collectionName, documentID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const obj = {
            id: documentID,
            ...docSnap.data(),
          };

          setDocument(obj);
        } else {
          toast.error("Document Not Found");
        }
      } catch (error) {
        // Handle any errors that occur during the data retrieval
      }
    };

    getDocument();
  }, [collectionName, documentID]);

  return { document };
}

export default useFetchDocument;
