import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import app from "../Firebase";
import { displaySuccessToast, displayWarningToast } from "../services/helpers";

// INITIALIZING FIRESTORE
const db = getFirestore(app);

const reviewStore = (set, get) => ({
  reviews: [],
  userReviews: [],
  eventReviews: [],

  //////////////////////////////
  // METHOD TO ADD A REVEIW //
  ///////////////////////////
  addReview: async (data) => {
    // CREATING A COLLECTION
    const reviewCollection = collection(db, "reviews");
    try {
      const responseFromFirebase = await addDoc(reviewCollection, data);
      const review_id = responseFromFirebase.id;
      const reviewDoc = doc(db, "reviews", review_id);
      //update
      await updateDoc(reviewDoc, {
        id: review_id,
      });
      const addReview = (review) =>
        set((state) => ({
          reviews: [...state.reviews, review],
        }));
      addReview(data);
    } catch (error) {
      console.log("error: " + error);
    }
  },

  //////////////////////////////
  // METHOD TO GET REVIEWS FOR THE EVENT  //
  ///////////////////////////

  getEventReviews: async (event_id) => {
    try {
      const reviewCollection = collection(db, "reviews");
      const q = query(
        reviewCollection,
        where("event_id", "==", event_id),
        where("status", "==", "visible")
      );

      const querySnapshot = await getDocs(q);
      const eventReviews = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        eventReviews.push(data);
      });

      return eventReviews;
    } catch (error) {
      console.error("Error getting event reviews: ", error);
      return [];
    }
  },

  //////////////////////////////
  // METHOD TO GET REVIEWS FOR A USER  //
  ///////////////////////////
  getUserReviews: async (user_id) => {
    // CREATING A COLLECTION
    const reviewCollection = collection(db, "reviews");

    try {
      const q = query(reviewCollection, where("user_id", "==", user_id));
      const response = await getDocs(q);
      const userReviews = [];
      response.forEach((doc) => {
        userReviews.push(doc.data());
      });

      set({ userReviews });
      return userReviews;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  },

  // METHOD TO GET AVERAGE REVIEWS FOR AN EVENTS
  getAverageEventReviews: async (event_id) => {
    try {
      const response = await get().getEventReviews(event_id);
      let totalRatings = 0;
      let len = 0;

      response.forEach((review) => {
        let rating = review?.rating;
        totalRatings += rating;
        len++;
      });

      // CALCULATING THE AVERAGE RATING
      const averageRating = len === 0 ? 0 : totalRatings / len;
      return averageRating;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return 0;
    }
  },

  // GETTING ALL REVIEWS  FROM STORAGE
  fetchAllReviews: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "reviews"));
      const reviews = [];
      querySnapshot.forEach((doc) => {
        reviews.push({ id: doc.id, ...doc.data() });
      });
      set({ reviews });
    } catch (error) {
      console.error("Error fetching reviews:", error);
      // Handle the error as needed (e.g., display an error message)
    }
  },

  //delete a review
  deleteReview: async (review_id) => {
    try {
      const reviewRef = doc(db, "reviews", review_id);
      await deleteDoc(reviewRef);
      //update the scanners
      const updatedReviews = get().reviews.filter(
        (review) => review?.id !== review_id
      );
      set({ reviews: updatedReviews });
      console.log("Review deleted successfully");
      return true;
    } catch (error) {
      console.log("Something went wrong while deleting the review", error);
      return false;
    }
  },

  // METHOD TO UPDATE REVIEW
  updateReview: async (review_id, data) => {
    try {
      await updateDoc(doc(db, "reviews", review_id), data);
      get().fetchAllReviews();
      set((state) => ({
        reviews: state.reviews.map((review) =>
          review.id === review_id ? data : review
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating review document: ", error);
      return false;
    }
  },

  // Define the handleStatusToggle function to toggle the status
  handleStatusReviews: async (review_id) => {
    const reviewIndex = get().reviews.findIndex(
      (review) => review?.id === review_id
    );
    if (reviewIndex !== -1) {
      const updatedReviews = [...get().reviews];
      const review = updatedReviews[reviewIndex];
      if ("status" in review) {
        review.status = review.status === "visible" ? "invisible" : "visible";
        try {
          // Update the review status in the database
          await get().updateReview(review_id, {
            status: review?.status,
          });
        } catch (error) {
          console.log("Error updating the review status:", error);
          return;
        }
      } else {
        // If the status field is missing, assume it is 'visible' and add it to the database
        review.status = "visible";
      }
      //displaying the message to the user
      if (review.status === "visible") {
        displaySuccessToast("The review can be viewed");
      } else {
        displayWarningToast(
          "The review has been hidden and will not be viewed"
        );
      }
      //updating the status
      set({ reviews: updatedReviews });
    }
  },

  //getting all the total amount of the transaction
  getEventTransactionAmount: async (eventID) => {
    //getting the collection
    try {
      const querySnapshot = await getDocs(collection(db, "transactions"));
      let totalAmount = 0; //intitalize the valuable to store the amnout
      // Iterate through each document in the "transactions" collection
      querySnapshot.forEach((doc) => {
        const transaction = doc.data(); //get the data fields
        //check if the event_id of the transaction matches the provided event_id
        if (
          transaction.event_id === eventID &&
          transaction.transaction_status === "successful"
        ) {
          // If there's a match, add the amount of that transaction to the totalAmount variable
          totalAmount += parseInt(transaction.amount);
        }
      });
      // Return the total amount for the event
      return totalAmount;
    } catch (error) {
      console.log("error in calculating the amount for the event");
      return false;
    }
  },
});

// CREATING AND EXPORTING THE EVENTS STORE
export const useReview = create(reviewStore);
