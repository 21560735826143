import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import Loader from "../../../components/loader/loader";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";
const PopingOutLanguages = ({ language_id, isVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteLanguage = useEvents((state) => state.deleteLanguage);
  const updateLanguages = useEvents((state) => state.updateLanguages);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteLanguage(e) {
    e.preventDefault();
    setIsLoading(true);
    var response = await deleteLanguage(language_id);
    if (response) {
      displaySuccessToast("🦄 The language has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the language");
    }
    setIsLoading(false);
  }

  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateLanguages(language_id, {
      isVisible: !isVisible,
    });
    if (isVisible) {
      displayWarningToast("The language has been hidden succeesfully");
    } else {
      displaySuccessToast("The language has been published succeesfully");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT LANGUAGE */}

          <Link to={"/edit_languages/" + language_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {isVisible === true ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className=" fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>{" "}
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE LANGUAGE */}
          <DeleteComponentModal
            itemName="Language"
            handleDelete={handleDeleteLanguage}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutLanguages;
