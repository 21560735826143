import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { Link, useNavigate } from "react-router-dom";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import app, { auth } from "../../Firebase";
import { useSettings } from "../../store/setting_store";

import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useEffect, useState } from "react";
import {
  attendee_sidebar_options1,
  attendee_sidebar_options2,
} from "./sidebar_options";

export default function AttendeeMobileDrawer({ activeTab }) {
  const navigate = useNavigate();

  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const logout = userStore((state) => state.logout);
  const cartItems = userStore((state) => state.cartItems);

  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);
  const [organizerDetails, setOrganizerDetails] = useState({});

  // METHOD TO GET ORGANIZER DETAILS
  async function handleGetOganizerDetails() {
    if (isOrganizer) {
      const repsonse = await getOrganizerDetails(user?.user_id);
      setOrganizerDetails(repsonse);
    }
  }
  useEffect(() => {
    handleGetOganizerDetails();
  }, []);
  const [state, setState] = useState({
    left: false, // Only left drawer
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  // SIGNOUT
  const handleSignOut = async () => {
    const response = window.confirm("Are you sure you want to sign out?");
    if (response === true) {
      const res = await logout();
      if (res) {
        localStorage.clear();
        navigate("/sign_in");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        // Get the user's email address
        const email = auth.currentUser.email;

        // Check if email has an associated account
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);

        // Check if the user registered with Google
        const isGoogleProvider = signInMethods.some((method) =>
          method.includes("google.com")
        );
        setIsGoogleSignIn(isGoogleProvider);
      }
    };

    fetchUser();
  }, []);

  const signOut = async () => {
    await logout();
    navigate("/sign_in");
    return null;
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        id="dashboard-menu"
        className="nav nav-pills nav-pills-vertical-styled"
        style={{ maxHeight: "20rem" }}
      >
        {/* DASHBOARD */}

        {attendee_sidebar_options1?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (activeTab === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* MY CART ITEMS */}
        <li className="nav-item">
          <Link
            to="/cart"
            className={"nav-link" + (activeTab === "cart" ? " active" : "")}
          >
            <i className="fas fa-shopping-cart fa-fw" /> My cart
            <span className="badge badge-info float-right">
              {cartItems?.length}
            </span>
          </Link>
        </li>

        {attendee_sidebar_options2?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (activeTab === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* SIGNOUT */}
        {isMobile && (
          <li className="nav-item" onClick={handleSignOut}>
            <Link to="/my_reports" className={"nav-link "}>
              <i className="fas fa-power-off fa-fw" /> Logout
            </Link>
          </li>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {/* Only render the left drawer */}
      <div key="left">
        <Button onClick={toggleDrawer("left", true)}>
          {auth.currentUser?.emailVerified ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className="avatar"
                style={{
                  background: `url("${
                    user?.profile_picture ??
                    organizerDetails?.organizer_logo ??
                    "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                  }")`,
                }}
              ></span>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <div className="widget-header">
                  <div className="icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none">
                    <i
                      className="fas fa-sign-in-alt text-light fa-sm fa-fw"
                      onClick={(e) => signOut(e)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Button>
        <Drawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </div>
    </div>
  );
}
