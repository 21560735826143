import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import { getNumofFollowers, getOrganizerDetails } from "../../services/users";
import { getUpcomingEventsbyOrganizerId } from "../../services/events";
import { userStore } from "../../store/user_store";
import { useEvents } from "./../../store/event_store";

export default function Previeworganizerprofile() {
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);

  const navigate = useNavigate();
  const [organizerData, setOrganizerData] = useState({});
  const [events, setEvents] = useState([]);
  const [upcoming_events, setUpcomingEvents] = useState([]);
  const [numofEvents_onSale, setnumofEvents_onSale] = useState("0");
  const [numofEvents, setNumofEvents] = useState("0");
  const [numof_followers, setNumof_Followers] = useState("0");

  useEffect(() => {
    //GETTING ORGANIZER DETAILS FOR DEFAULT FIELDS
    const fetchData = async () => {
      // GETTING ORGANIZER NAME
      const organizerDetails = await getOrganizerDetails(user?.user_id);
      // console.log("THE ORGANIZER ID :", events.organizer_id);
      console.log("THE ORGANIZER DETAILS ARE :", organizerDetails);
      setOrganizerData(organizerDetails);

      // GETTING ORGANIZER EVENTS
      const eventsdata = await getOrganizerEvents(user?.user_id);
      setEvents(eventsdata);
      console.log("THE RETURNED EVENTS ARE :", eventsdata);
      setNumofEvents(eventsdata.length);

      //GETTING FOLLOWERS OF A GIVEN ORGANIZER
      const followers = await getNumofFollowers(user?.user_id);
      console.log("THE RETURNED FOLLOWERS ARE :", followers);
      setNumof_Followers(followers.length);
      console.log("THE NUM OF FOLLOWERS ARE :", followers.length);

      //GETTING UPCOMING EVENTS
      const upcomingevents = await getUpcomingEventsbyOrganizerId(
        user?.user_id
      );
      console.log("THE UPCOMING EVENTS ARE :", upcomingevents);
      setUpcomingEvents(upcomingevents);
      setnumofEvents_onSale(upcoming_events.length);
    };

    fetchData();
  }, []);

  if (!isOrganizer) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <main className="col-12">
                <div className="alert alert-info alert-icon">
                  <i className="fas fa-info-circle" />{" "}
                  <Link to="/organizer_profile">Click here</Link> to edit your
                  profile
                </div>
                <div className="shadow rounded overflow-hidden">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="organizer-profile-sidebar text-center mt-3 mb-3">
                        {/* WHEN LOGO IS NULL */}
                        {organizerData.organizer_logo === null ? (
                          <span
                            className="avatar organizer-profile-logo"
                            style={{
                              backgroundImage: `url("https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png")`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                          />
                        ) : (
                          //WHEN THE LOGO IS NOT NULL
                          <span
                            className="avatar organizer-profile-logo"
                            style={{
                              backgroundImage: `url(${organizerData.organizer_logo})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                          />
                        )}

                        <h4 className="font-weight-bold text-center my-5">
                          {organizerData.organizer_name}
                        </h4>
                      </div>
                    </div>
                    <div className="col-12 col-lg-8 bg-light">
                      <div className="p-4 d-flex justify-content-end text-center">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item mr-3">
                            <h5 className="font-weight-bold mb-0 d-block">
                              {numofEvents}
                            </h5>
                            <small className="text-muted">
                              {" "}
                              <i className="fas fa-calendar-alt fa-fw mr-1" />
                              Events
                            </small>
                          </li>
                          <li className="list-inline-item">
                            <h5 className="font-weight-bold mb-0 d-block">
                              {numof_followers}
                            </h5>
                            <small className="text-muted">
                              {" "}
                              <i className="fas fa-folder-plus mr-1" />
                              Followers
                            </small>
                          </li>
                          <li className="list-inline-item ml-3"></li>
                        </ul>
                      </div>
                      <div className="py-4 px-4">
                        <ul className="nav nav-pills mb-3">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="pill"
                              href="#organizer-onsale-events"
                              role="tab"
                              aria-controls="organizer-onsale-events"
                              aria-selected="true"
                            >
                              Events on sale ({numofEvents_onSale})
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="pill"
                              href="#organizer-past-events"
                              role="tab"
                              aria-controls="organizer-past-events"
                              aria-selected="false"
                            >
                              Past events (0)
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="organizer-onsale-events"
                            role="tabpanel"
                            aria-labelledby="organizer-onsale-events-tab"
                          >
                            <div className="alert alert-info ">
                              {" "}
                              No events on sale for now
                            </div>{" "}
                          </div>
                          <div
                            className="tab-pane fade"
                            id="organizer-past-events"
                            role="tabpanel"
                            aria-labelledby="organizer-past-events"
                          >
                            <div className="alert alert-info ">
                              {" "}
                              No past events found
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
