import React from "react"
import ErrorPage from "./error_page/error_page";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can log the error to an error reporting service here
      console.error(error, errorInfo);
    console.error("THIS IS THE ERROR SOURCE: " + error.stack);
      this.setState({ hasError: true });
    }
  
    render() {
      if (this.state.hasError) {
        // You can render a fallback UI here
        return <ErrorPage />;
      }
  
      return this.props.children;
    }
  }
  

  export default ErrorBoundary;