import React, { useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Manageaddaudiences() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const addNewAudience = useEvents((state) => state.addNewAudience);
  const uploadaudienceImage = useEvents((state) => state.uploadaudienceImage);
  const layout_page = useSettings((state) => state.layout_page);
  //HANDLING AUDIENCE ADD UP
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleAddNewAudience = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const imageUrl = await uploadaudienceImage(imageFile);
      const response = await addNewAudience({
        name: name,
        image: imageUrl,
        created_at: Timestamp.fromDate(new Date()),
      });
      if (response) {
        navigate("/manage_audiences");
        displaySuccessToast("audience added successfully");
      } else {
        navigate("/manage_audiences");
        displayErrorToast("something went wrong");
      }
    } else {
      setErrors(errors);
      setIsLoading(false);
    }
  };

  // HANDLING IMAGE CAPTURE
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!name) {
      errors.name = "name is required";
    }
    if (!imageFile) {
      errors.imageFile = "Image  is required";
    }

    return errors;
  }

  //setting the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new audience"
          page_link="Manage Audiences"
          link="manage_audiences"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_audiences"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="audience"
                      onSubmit={handleAddNewAudience}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      {/*ADDING NAME */}

                      <div
                        id="audience_translations_en_a2lix_translations-fields"
                        className="tab-pane show active "
                        role="tabpanel"
                      >
                        <div id="audience_translations_en">
                          <div className="form-group d-grid">
                            <label
                              htmlFor="audience_translations_en_name"
                              className="required"
                            >
                              Name
                            </label>
                            {errors.name && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.name}
                              </span>
                            )}
                            <input
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              required="required"
                              maxLength={50}
                              className={
                                errors.name
                                  ? " border border-danger form-control"
                                  : "form-control"
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* ADDING IMAGE */}
                      <fieldset className="form-group d-grid">
                        <legend className="col-form-label">Image</legend>
                        {errors.imageFile && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.imageFile}
                          </span>
                        )}
                        <div className="vich-image">
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={handleFileSelect}
                              className="custom-file-input"
                            />
                            <label
                              htmlFor="audience_imageFile_file"
                              className="custom-file-label"
                            />
                          </div>
                        </div>
                      </fieldset>

                      {/* SUBMIT BUTTON */}
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
