import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import app from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../services/helpers";

// INITIALIZING FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

const venuesStore = (set, get) => ({
  venues: [],
  userVenues: [],
  venuetypes: [],
  ammenities: [],
  reservations: [],

  //get venue by id
  getVenueById: async (venueId) => {
    const docRef = doc(db, "venues", venueId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return { id: docSnap.id, ...data };
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  },

  // METHOD TO GET VENUES
  getVenues: async () => {
    try {
      const venues = [];
      const userDoc = collection(db, "venues");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        venues.push(doc.data());
      });
      set({ venues });
      return venues;
    } catch (error) {
      console.log("Error fetching venues:", error);
      // displayErrorToast("Something went wrong while fetching venues");
      return [];
    }
  },
  //get venues where isHidden ===false
  getVisibleVenues: async () => {
    const venues = await get().getVenues();
    const visibleVenues = venues.filter((venue) => venue.isHidden !== true);
    return visibleVenues;
  },

  //get venues where listedondirectory===true
  getListedondirectory: async () => {
    const venues = await get().getVenues();
    const listeDondirectory = venues.filter(
      (venue) => venue.listedondirectory === true
    );
    return listeDondirectory;
  },

  // METHOD TO UPDATE VENUES
  updateVenue: async (venue_id, data) => {
    console.log(data);
    try {
      await updateDoc(doc(db, "venues", venue_id), data);
      get().getVenues();
      set((state) => ({
        venues: state.venues.map((venue) =>
          venue.id === venue_id ? data : venue
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating venue document: ", error);
      return false;
    }
  },

  getOrganizerVenues: async (organizer_id) => {
    const venues = await get().getVenues();
    const organizer_venues = venues.filter(
      (venue) => venue.organizer_id === organizer_id
    );
    return organizer_venues;
  },

  // METHOD TO ADD A VENUE
  addVenue: async (data) => {
    try {
      const docRef = await addDoc(collection(db, "venues"), data);

      console.log("Venue Added: ", docRef?.id);
      updateDoc(doc(db, "venues", docRef?.id), { id: docRef?.id });
      //update venue array in zustand store
      const addVenues = (venue) =>
        set((state) => ({
          venues: [...state.venues, venue],
        }));
      addVenues(data);
      return true;
    } catch (error) {
      console.error("Error adding venue: ", error);
      return false;
    }
  },

  //get specific venue
  getSpecificVenue: async (venue_id) => {
    try {
      const venueDoc = doc(db, "venues", venue_id);
      const venueSnapshot = await getDoc(venueDoc);
      const venue = venueSnapshot.data();
      return venue;
    } catch (error) {
      console.error("Error fetching specific venue: ", error);
      return null;
    }
  },
  //delete venue
  deleteVenue: async (venue_id) => {
    try {
      console.log("DELETING VENUE WITH ID: " + venue_id);

      // Get venue details
      const venueDetails = await get().getSpecificVenue(venue_id);

      // Delete venue images from storage
      const venueImages = venueDetails?.venue_images || [];

      const deletePromises = venueImages.map(async (imagePath) => {
        const storageRef = ref(storage, imagePath);
        // Delete the image, whether it exists or not
        await deleteObject(storageRef).catch((error) => {
          // Handle any errors, but continue even if the object doesn't exist
          console.error("Error while deleting venue image:", error);
        });
      });

      // Wait for all image deletion promises to complete
      await Promise.all(deletePromises);

      console.log("Venue images deleted successfully.");

      // Delete the venue document from the database
      const venueRef = doc(db, "venues", venue_id);
      await deleteDoc(venueRef);

      console.log("Entire venue has been deleted successfully.");

      // Update the list of venues after the deletion
      const updatedVenues = get().venues.filter(
        (venue) => venue?.id !== venue_id
      );
      set({ venues: updatedVenues });

      return true;
    } catch (error) {
      console.error("Error while deleting the venue:", error);
      return false;
    }
  },

  //////////////////////////////////////////
  // METHOD TO ADD A NEW VENUE TYPE ////////
  //////////////////////////////////////////
  addVenueType: async (data) => {
    try {
      const docRef = await addDoc(collection(db, "venue_types"), data);
      console.log("Venue type Added: ", docRef?.id);
      updateDoc(doc(db, "venue_types", docRef?.id), { id: docRef?.id });
      //update venue type array in zustand store
      const addVenueType = (venuetype) =>
        set((state) => ({
          venuetypes: [...state.venuetypes, venuetype],
        }));
      addVenueType(data);
      return true;
    } catch (error) {
      console.error("Error adding venue type: ", error);
      return false;
    }
  },

  //getting venue types
  getVenuetypes: async () => {
    try {
      const venuetypes = [];
      const venueTypesDoc = collection(db, "venue_types");
      const querySnapshot = await getDocs(venueTypesDoc);
      querySnapshot.docs.forEach((doc) => {
        venuetypes.push(doc.data());
      });
      set({ venuetypes });
      return venuetypes;
    } catch (error) {
      console.error("Error fetching venue types: ", error);
      return [];
    }
  },

  //get venues where isVisible ===true
  getVisibleVenueTypes: async () => {
    const venuetypes = await get().getVenuetypes();
    const visibleVenueTypes = venuetypes.filter(
      (venueType) => venueType.isVisible === true
    );
    return visibleVenueTypes;
  },

  // METHOD TO UPDATE VENUES
  updateVenueTypes: async (venue_type_id, data) => {
    console.log("THE VENUE TYPE DATA TO UPDATE: ");
    console.log(data);
    try {
      await updateDoc(doc(db, "venue_types", venue_type_id), data);
      get().getVenuetypes();
      set((state) => ({
        venuetypes: state.venuetypes.map((venuetype) =>
          venuetype.id === venue_type_id ? data : venuetype
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating venue type document: ", error);
      return false;
    }
  },

  ///////////////////////////////////
  // METHOD TO UPLOAD VENUE IMAGE //
  /////////////////////////// //////
  uploadVenueImage: async (image) => {
    if (!image) {
      return;
    }
    const storageRef = ref(storage, "venues/images/" + image.name);

    try {
      await uploadBytes(storageRef, image);

      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
    }
  },

  //delete venue type
  deleteVenueType: async (venueTypeId) => {
    try {
      const venueTypeRef = doc(db, "venue_types", venueTypeId);
      await deleteDoc(venueTypeRef);
      console.log("Venue Type deleted successfully");
      const updatedVenueTypes = get().venuetypes.filter(
        (venueType) => venueType?.id !== venueTypeId
      );
      set({ venuetypes: updatedVenueTypes });
      return true;
    } catch (error) {
      console.error("Error deleting venue type:", error);
      return false;
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////// METHOD TO ADD AMENITY/////////////////////////////////

  addNewAmmenity: async (data) => {
    try {
      // CREATING A COLLECTION
      const docRef = await addDoc(collection(db, "amenities"), data);
      updateDoc(doc(db, "amenities", docRef?.id), { id: docRef?.id });
      //update amenities array in zustand store
      const addAmmenities = (ammenity) =>
        set((state) => ({
          ammenities: [...state.ammenities, ammenity],
        }));
      addAmmenities(data);
      return true;
    } catch (error) {
      console.error("Error adding ammenity: ", error);
      return false;
    }
  },

  //get ammenties
  getAmmenities: async () => {
    try {
      const ammenities = [];
      const userDoc = collection(db, "amenities");
      const querySnapshot = await getDocs(userDoc);
      querySnapshot.docs.forEach((doc) => {
        ammenities.push(doc.data());
      });
      set({ ammenities });
      return ammenities;
    } catch (error) {
      console.error("Error getting ammenities:", error);
      return [];
    }
  },

  //get ammenities where isVisible ===true
  getVisibleAmmenities: async () => {
    const ammenities = await get().getAmmenities();
    const visibleAmmenities = ammenities.filter(
      (ammenity) => ammenity.isVisible === true
    );
    return visibleAmmenities;
  },

  // METHOD TO UPDATE VENUES
  updateAmmenities: async (ammenity_id, data) => {
    try {
      await updateDoc(doc(db, "amenities", ammenity_id), data);
      get().getAmmenities();
      set((state) => ({
        ammenities: state.ammenities.map((ammenity) =>
          ammenity.id === ammenity_id ? data : ammenity
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating ammenity document: ", error);
      return false;
    }
  },
  //delete ammenity
  deleteAmmenity: async (ammenity_id) => {
    try {
      const amenityRef = doc(db, "amenities", ammenity_id);
      await deleteDoc(amenityRef);
      console.log("Ammenity deleted");
      ///update
      const updatedAmmenities = get().ammenities.filter(
        (ammenity) => ammenity?.id !== ammenity_id
      );
      set({ ammenities: updatedAmmenities });
      return true;
    } catch (error) {
      console.log("Error while deleting amenity:", error);
      return false;
    }
  },

  // Define the handleStatusToggle function to toggle the status
  handleStatusToggle: async (venue_id) => {
    const venueIndex = get().venues.findIndex((venue) => venue.id === venue_id);
    if (venueIndex !== -1) {
      const updatedVenues = [...get().venues];
      const venue = updatedVenues[venueIndex];
      // Check if the ishidden field exists in the user object
      if ("isHidden" in venue) {
        venue.isHidden = venue.isHidden === true ? false : true;

        // Update the venue's status in the database
        try {
          await updateDoc(doc(db, "venues", venue_id), {
            isHidden: venue.isHidden,
          });
        } catch (error) {
          console.log("Error in updating the venue status:", error);
          return;
        }
      } else {
        // If the isHidden field is missing, assume it is false and add it to the database
        venue.isHidden = false;
      }
      // Display a success message based on the updated status
      if (venue.isHidden === false) {
        displaySuccessToast("The venue has been published");
      } else {
        displayWarningToast("The venue has been hidden and WILL NOT be viewed");
      }
      set({ venues: updatedVenues });
    }
  },

  // Define the handleStatusToggle function to toggle the status
  handleListedOnDirectoryToggle: async (venue_id) => {
    const venueIndex = get().venues.findIndex((venue) => venue.id === venue_id);
    if (venueIndex !== -1) {
      const updatedVenues = [...get().venues];
      const venue = updatedVenues[venueIndex];
      // Check if the ishidden field exists in the user object
      if ("listedondirectory" in venue) {
        venue.listedondirectory =
          venue.listedondirectory === true ? false : true;

        // Update the venue's status in the database
        try {
          await updateDoc(doc(db, "venues", venue_id), {
            listedondirectory: venue.listedondirectory,
          });
        } catch (error) {
          console.log("Error in updating the venue directory status:", error);
          return;
        }
      } else {
        // If the listedondirectory field is missing, assume it is false and add it to the database
        venue.listedondirectory = false;
      }
      // Display a success message based on the updated status
      if (venue.listedondirectory === true) {
        displaySuccessToast(
          "The venue has been published to public directory "
        );
      } else {
        displayWarningToast(
          "The venue has been hidden and WILL NOT be viewed on public directory"
        );
      }
      set({ venues: updatedVenues });
    }
  },

  ///reservation section
  addReservation: async (reservation) => {
    try {
      const id = reservation.id.toString();
      const venueDocRef = doc(db, "venues", reservation?.venue?.value);
      const reservationCollectionRef = collection(venueDocRef, "reservations");
      // Use setDoc to add a document with a specific ID
      await setDoc(doc(reservationCollectionRef, id), reservation);
      set((state) => ({ reservations: [...state.reservations, reservation] }));
      return true;
    } catch (error) {
      console.error("Error in adding reservation", error);
      // Handle the error or log it
      return false;
    }
  },
  //getting reservations from database
  getReservations: async () => {
    try {
      const venueSnapShot = await getDocs(collection(db, "venues"));
      const reservations = [];

      for (const venueDoc of venueSnapShot.docs) {
        const venueId = venueDoc.id;
        //get the reservation from the current venue
        const reservationCollectionRef = collection(
          db,
          "venues",
          venueId,
          "reservations"
        );

        //get all documents in reservation sub collection

        const reservationSnapShot = await getDocs(reservationCollectionRef);
        //iterate through each reservation document

        reservationSnapShot.forEach((reservationDoc) => {
          //add data to the result
          reservations.push({
            ...reservationDoc.data(),
          });
        });
      }
      set({ reservations: reservations }); // updating the local storage
      return reservations;
    } catch (error) {
      console.log("Error in getting reservations", error);
      return null;
    }
  },

  //update reservation
  updateReservation: async (venueId, reservationId, updatedData) => {
    try {
      const reservationCollectionRef = collection(
        db,
        "venues",
        venueId,
        "reservations"
      );
      const resDocRef = doc(reservationCollectionRef, reservationId);
      await updateDoc(resDocRef, updatedData);

      set((state) => ({
        reservations: state.reservations.map((reservation) =>
          reservation.id === reservationId
            ? { ...reservation, ...updatedData }
            : reservation
        ),
      }));
      console.log(" reservation updated successfully");
      return true;
    } catch (error) {
      console.log("Error in updating the serservation", error);
      return false;
    }
  },
});

// CREATING AND EXPORTING THE VENUE STORE
export const useVenues = create(venuesStore);
useVenues.getState().getReservations();
