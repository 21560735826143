import {
  convertTimestampToDateTime,
  formatDate,
} from "./../../services/helpers";
import React, { useEffect } from "react";
import EventDetail from "./../eventDetail/EventDetail";

// REVIEW TILE COMPONENT
export default function ReviewTile({ review }) {
  useEffect(() => {
    console.log(
      "THE VALUES OF THE RECEIVED REVIEW ARE: ",
      Object.values(review)
    );
  }, [review]);

  return (
    <div className="col-12">
      <div className="user-review-wrapper mb-5">
        <div className="mt-3">
          {/* USER IMAGE */}
          <span
            className="avatar mr-2"
            style={{
              background: `url(${
                review.user_image ??
                "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
              })`,
            }}
          ></span>

          {/* USER NAME */}
          <h6 className="user-fullname display-inline-block">
            {review.user_name}
          </h6>
        </div>

        {/* RATINGS */}
        <div className="rating-wrap mt-3">
          <ul className="rating-stars mr-2">
            <li style={{ width: "100%" }} className="stars-active">
              {/* STAR RATING */}

              {Array.from({ length: review.rating }, (_, index) => (
                <i key={index} className="fa fa-star" />
              ))}
            </li>
            <li>
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </li>
          </ul>

          {/* RATING */}
          <div className="label-rating">{review.rating} out of 5 stars</div>
        </div>

        {/* REVIEW DATE */}
        <small className="text-muted mt-1">
          {review.created_at
            ? formatDate(convertTimestampToDateTime(review.created_at))
            : "No Date"}
        </small>

        {/* REVIEW HEADLINE */}
        <h6 className="mt-3 font-weight-bold">{review.review_headline}</h6>

        {/* REVIEW BODY */}
        <p
          className="mt-2 text-sm readmore rmjs-3"
          data-collapsed-height={200}
          data-height-margin={20}
          style={{ height: "auto", maxHeight: "none" }}
        >
          {review.review_body}
        </p>
      </div>
    </div>
  );
}
