import React, { useState } from "react";
import { useSettings } from "../../../../../store/setting_store";
import { AddButton, ProceedButton } from "../../Components";
import {
  displaySuccessToast,
  generateUniqueId,
} from "../../../../../services/helpers";
import { CompactPicker } from "react-color";
import { tableStore } from "../../../../../store/table_store";
import AddedTableTypes from "./AddedTableTypes";
import { shallow } from "zustand/shallow";

const TableTypes = ({ handleTabClick, tableType_id, venue_id }) => {
  const layout_page = useSettings((state) => state.layout_page);
  const [table_color, setTableColor] = useState("#0062B1");
  const [price, setPrice] = useState("");
  const [tableTypeName, setTableTypeName] = useState("");

  const [tableShape, setTableShape] = useState("rectangular");

  const addTableType = tableStore((state) => state.addTableTypes);
  const venueTableType = tableStore(
    (state) =>
      state.venueTableTypes.find(
        (venueTableType) => venueTableType.id === tableType_id
      ),
    shallow
  );
  const editVenueTableType = tableStore((state) => state.editVenueTableType);
  const tableTypes = tableStore(
    (state) =>
      state.tableTypes.filter(
        (tableType) => tableType.venue_tableType_id === tableType_id
      ),
    shallow
  );

  const uploadImage = tableStore((state) => state.uploadImage);
  const deleteImage = tableStore((state) => state.deleteImage);

  //changing the primary color of the website
  const handleTableColorChange = (color) => {
    setTableColor(color.hex);
  };

  //adding new table to the array
  const handleAddingTables = (e) => {
    e.preventDefault();
    addTableType({
      id: generateUniqueId(),
      venue_tableType_id: tableType_id,
      table_type: tableTypeName,
      table_color: table_color,
      price: price,
      table_shape: tableShape,
    });
    // Reset fields after adding a table type
    setTableTypeName("");
    setTableColor("#9F0500");
    console.log("the venue table id is", tableType_id);
  };

  const [imagePreview, setImagePreview] = useState(
    venueTableType?.room_image || ""
  );

  //handling the image file
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      //upload file to database for storage
      const imageUrl = await uploadImage(file);
      setImagePreview(imageUrl);
      const reader = new FileReader();
      // Read the content of a file (in this case, 'file' is the File object obtained from an input element)
      reader.readAsDataURL(file);
    }
  };

  //delete the uploaded image
  const handleImageDelete = async () => {
    const response = await deleteImage(imagePreview);
    if (response) {
      editVenueTableType(venue_id, tableType_id, {
        ...venueTableType,
        room_image: "",
      });
      setImagePreview("");
      displaySuccessToast("Image deleted Successfully");
    }
  };

  //adding proceed
  const handleProceed = () => {
    editVenueTableType(venue_id, tableType_id, {
      ...venueTableType,
      tableTypes: tableTypes,
      room_image: imagePreview,
    });
    // Proceed to the next step
    handleTabClick("mappings");
  };

  return (
    <div className="">
      <div class="row">
        <div class="col-md-6 mt-5 border-right">
          <div className="form-group">
            <label htmlFor="table_type">
              Add Table type (eg ordinary, VIP, et.c)
            </label>
            <input
              type="text"
              name="table_type"
              className="form-control mt-1 h-25"
              onChange={(e) => setTableTypeName(e.target.value)}
            />
          </div>

          <div className="form-group ">
            <label htmlFor="price">Table Price</label>
            <input
              type="number"
              name="price"
              className="form-control mt-1 h-25"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="room_size">Table color</label>
            <div className="d-flex">
              <div>
                <CompactPicker
                  color={table_color}
                  onChangeComplete={handleTableColorChange}
                />
              </div>
              <div className="d-grid ml-2">
                <span
                  style={{
                    backgroundColor: layout_page?.primary_color,
                    height: 40,
                  }}
                  className=" px-3 py-2 radius8 text-white fw-bold"
                >
                  Pick Color
                </span>

                <div
                  className="border"
                  style={{
                    backgroundColor: table_color || "",
                    height: 38,
                    width: 96,
                    borderRadius: 8,
                  }}
                  onChange={handleTableColorChange}
                ></div>
              </div>
            </div>
          </div>
          <div className="form-group fw-bold ">
            <p>Table Shape</p>

            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="table_rectangle"
                name="tableStructure"
                required="required"
                className="custom-control-input"
                value="rectangular"
                onChange={(e) => {
                  setTableShape(e.target.value);
                }}
                checked={tableShape === "rectangular"}
              />

              <label
                className="custom-control-label required"
                htmlFor="table_rectangle"
              >
                Rectangle
              </label>
            </div>

            <div className="custom-control custom-radio custom-control-inline mt-3">
              <input
                type="radio"
                id="table_circular"
                name="tableStructure"
                required="required"
                className="custom-control-input"
                value="circular"
                onChange={(e) => {
                  setTableShape(e.target.value);
                }}
                checked={tableShape === "circular"}
              />

              <label
                className="custom-control-label required"
                htmlFor="table_circular"
              >
                Circular
              </label>
            </div>
          </div>

          <span onClick={handleAddingTables}>
            <AddButton text="Add Table Type" />
          </span>

          <div className="form-group mt-5">
            {imagePreview ? (
              <div className="d-grid">
                <div className="mt-4">
                  <p className=" fw-bold">Venue Room Image</p>
                  <img
                    src={imagePreview}
                    alt="Uploaded"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />

                  <div
                    onClick={handleImageDelete}
                    className=" bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                    style={{ width: "40%" }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            ) : (
              <>
                <legend className="col-form-label">
                  Upload Venue background Image
                </legend>

                <div className="vich-image">
                  <div className="custom-file">
                    <input
                      type="file"
                      id="room_image"
                      name="room_image"
                      className="custom-file-input"
                      onChange={handleImageChange}
                      // required={true}
                    />
                    <label htmlFor="room_image" className="custom-file-label" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* MAPPING  ADDED TABLE TYPES */}
        <AddedTableTypes tableType_id={tableType_id} venue_id={venue_id} />
      </div>
      <span onClick={handleProceed}>
        <ProceedButton text="Proceed" />
      </span>
    </div>
  );
};

export default TableTypes;
