import { BsThreeDotsVertical } from "react-icons/bs";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/setting_store";

const PopingOutReports = ({ event_id, setIsLoading }) => {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div>
      <div className="item-action dropdown">
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              <div variant="contained" {...bindTrigger(popupState)}>
                <BsThreeDotsVertical
                  style={{
                    color: layout_page?.primary_color,
                    cursor: "pointer",
                  }}
                />
              </div>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  <div className="px-3 d-flex flex-column gap-3">
                    {/* Manage event */}

                    <Link to={"/manage_events/" + event_id}>
                      <div className="d-flex gap-1" style={{ color: "gray" }}>
                        <i className="dropdown-icon fas fa-calendar-alt fa-fw text-muted" />{" "}
                        <h6>Manage Event</h6>
                      </div>
                    </Link>
                    {/* View Statistics */}
                    <Link to={"/event_statistics/" + event_id}>
                      <div className="d-flex gap-1" style={{ color: "gray" }}>
                        <i className="dropdown-icon fas fa-chart-line fa-fw text-muted" />{" "}
                        <h6>View Detailed Statistics</h6>
                      </div>
                    </Link>
                    {/* Order details */}
                    <Link to={"/manage_event_orders/" + event_id}>
                      <div className="d-flex gap-1" style={{ color: "gray" }}>
                        <i className="dropdown-icon fas fa-cart-arrow-down fa-fw text-muted" />{" "}
                        <h6>Orders</h6>
                      </div>
                    </Link>
                  </div>
                </Typography>
              </Popover>
            </div>
          )}
        </PopupState>
      </div>
    </div>
  );
};
export default PopingOutReports;
