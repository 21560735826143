import { useState } from "react";
import { userStore } from "../../../../store/user_store";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import {
  capitalizeWords,
  displayErrorToast,
  displaySuccessToast,
} from "../../../../services/helpers";
import Loader from "../../../../components/loader/loader";
import Header from "../../../../components/header/header";
import BreadCrump from "../../../../components/BreadCrump/bread_crump";
import Sidenavbar from "../../components/sidenavbar";
import { isMobile } from "react-device-detect";
import Footer from "../../../../components/footer/footer";
import { useSettings } from "../../../../store/setting_store";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Addnewscanner() {
  //adding adding a scanner
  const addScanner = userStore((state) => state.addScanner);
  const getUserByEmail = userStore((state) => state.getUserByEmail);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const layout_page = useSettings((state) => state.layout_page);
  const user = userStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  //handling validation
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  // HANDLING ADDSCANNER
  const handleAddScanner = async () => {
    setIsLoading(true);

    const response = await getUserByEmail(values.email);
    // IF THE USER EXISTS
    if (response) {
      const data = {
        id: response?.user_id,
        added_by: user?.user_id,
        name: response?.firstname,
        user_id: response?.user_id,
        email: response?.email,
        created_at: Timestamp.fromDate(new Date()),
        updated_at: Timestamp.fromDate(new Date()),
      };
      try {
        await addScanner(data);
        displaySuccessToast(
          `${capitalizeWords(response?.firstname)} added as a scanner`
        );
        navigate("/my_scanners");
      } catch (error) {
        displayErrorToast("Something Went Wrong Adding Scanner");
        console.log("error: " + error);
        setIsLoading(false);
      }
    } else {
      displayErrorToast("No User Account Matches That Email");
      setIsLoading(false);
      navigate("/my_scanners");
    }

    setIsLoading(false);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleAddScanner,
  });

  if (!user) {
    navigate("/sign_in");
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER

    return (
      <div>
        <Header />
        <BreadCrump title={"Add a new scanner"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDEBAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit}
                      name="scanner"
                      method="post"
                      noValidate="novalidate"
                    >
                      <div id="scanner">
                        <div className="form-group">
                          <label htmlFor="email" className="required">
                            Scanner Email Address
                          </label>
                          <input
                            placeholder="Enter email of the user you want to add as a scanner"
                            type="email"
                            id="email"
                            name="email"
                            required="required"
                            className={
                              errors.email && touched.email
                                ? "border border-danger form-control"
                                : "form-control"
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <p className=" text-danger text-sm font-weight-bold">
                              {errors.email}
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            id="scanner_save"
                            name="scanner[save]"
                            className="btn text-white btn "
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
