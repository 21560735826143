import { Dropdown } from "react-bootstrap";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { useSettings } from "../../../store/setting_store";
import { BsThreeDotsVertical } from "react-icons/bs";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { useEvents } from "../../../store/event_store";
import { useState } from "react";
import Loader from "../../../components/loader/loader";

const PopingOutEventIdeas = ({ idea_id }) => {
  const deleteIdea = useEvents((state) => state.deleteIdea);
  const layout_page = useSettings((state) => state.layout_page);
  const [isLoading, setIsLoading] = useState(false);

  // METHOD TO HANDLE DELETING THE SCANNER
  const handleDeleteEventIdea = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteIdea(idea_id);
    if (response) {
      displaySuccessToast("Event Idea deleted");
    } else {
      displayErrorToast("Something went wrong while deleting the Event Idea");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="black"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
        
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu  popperConfig={{ strategy: '' }}>
        <Dropdown.Item>
          <DeleteComponentModal
            itemName="Event Idea"
            handleDelete={handleDeleteEventIdea}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PopingOutEventIdeas;
