// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-tab {
  position: relative;
  color: #f76300;
}
.active-tab::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f76300;
}

.table {
  min-width: 900px;
  max-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin_dashboard/tables/RoomDetails.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,cAAA;AADJ;AAGI;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AADR;;AAKA;EACI,gBAAA;EACA,gBAAA;AAFJ","sourcesContent":["// RoomDetails.scss\r\n\r\n.active-tab {\r\n    position: relative;\r\n    color: #f76300; \r\n    \r\n    &::before {\r\n        content: \"\";\r\n        position: absolute;\r\n        top: 0; // Position the line at the top\r\n        left: 0;\r\n        width: 100%;\r\n        height: 2px; // Adjust line height as needed\r\n        background-color: #f76300; \r\n    }\r\n}\r\n\r\n.table{\r\n    min-width: 900px;\r\n    max-width: 400px;\r\n}\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
