import React, { useEffect, useRef } from "react";
import ProgressBar from "progressbar.js";

const StatisticsCircularSales = ({ progress, size }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const bar = new ProgressBar.Circle(containerRef.current, {
      color: "#aaa",
      strokeWidth: 4,
      trailColor: "#eee",
      trailWidth: 1,
      easing: "easeInOut",
      duration: 2000,
      svgStyle: null, // Set to null to prevent overriding default styles
      text: {
        value: "0%",
        style: {
          color: "#999",
          position: "absolute",
          left: "50%",
          top: "50%",
          padding: 0,
          margin: 0,
          transform: {
            prefix: true,
            value: "translate(-50%, -50%)",
          },
        },
      },
    });

    bar.animate(progress);
    bar.setText(`${Math.round(progress * 100)}%`);

    return () => {
      bar.destroy();
    };
  }, [progress]);

  const containerStyle = {
    width: size,
    height: size,
  };

  return <div ref={containerRef} style={containerStyle} />;
};

export default StatisticsCircularSales;
