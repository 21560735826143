import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

import AdminSideNav from "./components/admin_side_nav";

import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import EventsAdmin from "./EventsAdmin";
import UsersAdmin from "./UsersAdmin";

import OrdersAdmin from "./OrdersAdmin";

import { userStore } from "../../store/user_store";
import { useEvents } from "../../store/event_store";
import { ordersStore } from "../../store/orders_store";
import AdminGraph from "./AdminGraph";
import { useSettings } from "../../store/setting_store";
import { formatNumberWithCommas } from "../../services/helpers";

export default function AdminDashboard() {
  // USING ZUSTAND
  // fetching all the events from the database
  const isAdmin = userStore((state) => state.isAdmin);

  const events = useEvents((state) => state.events);

  // fetching the upcoming events
  const getUpcomingEvents = useEvents((state) => state.getUpcomingEvents);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);

  //fetching all the event dates from the database
  const getAllEventDates = useEvents((state) => state.getAllEventDates);
  // const numEventDates = useEvents((state) => state.eventDates);

  //fetching all the orders fromt the database
  const getAllOrdersFromDatabase = ordersStore((state) => state.fetchOrders);
  const numOfOrders = ordersStore((state) => state.orders);
  const getTotalOrderTickets = ordersStore(
    (state) => state.getTotalOrderTickets
  );

  //fetching all the attendees from the database;
  const getAllAttendeesFromDatabase = userStore((state) => state.getAttendees);

  //fetching active organizers
  const getAllOrganizers = userStore((state) => state.fetchOrganizers);
  const activeOrganizers = userStore((state) => state.organizers);

  //fetching scanners
  const getScanners = userStore((state) => state.fetchScanners);
  const scannerNumber = userStore((state) => state.scanners);
  // const attendees = userStore((state) => state.attendees);

  //fetching POS
  const getPOS = userStore((state) => state.fetchPointsOfSale);
  const numberPOS = userStore((state) => state.pointsOfSale);
  const layout_page = useSettings((state) => state.layout_page);
  const numEventDates = useEvents((state) => state.eventDates);

  const [isLoading, setIsLoading] = useState(true);
  const [attendees, setAttendees] = useState([]);
  const [totalQuantity, settotalQuantity] = useState(0);
  // const [numEventDates, setNumEventDates] = useState([]);

  const { state } = useLocation();
  const navigate = useNavigate();

  // GET THE USER DETAILS

  // FUNCTION TO GET THE DETAILS OF THE USER
  // console.log("THE STATE IS: ", state);
  useEffect(() => {
    async function fetchData() {
      //GETTING EVENT DATES FOR ALL ORGANIZERS
      await getAllEventDates();

      //GETTING ALL ORDERS FROM THE DATABASE
      await getAllOrdersFromDatabase();
    }
    //GETTING ALL ACTIVE ATTENDEES
    const getAttendees = async () => {
      const att = await getAllAttendeesFromDatabase();
      setAttendees(att);
    };

    const getOrganizers = async () => {
      //GETTING ALL ACTIVE ORGANIZERS
      await getAllOrganizers();
    };
    //GETTING ALL SCANNERS FROM THE DATABASE
    const getScanner = async () => {
      await getScanners();
    };
    //GETTING POS NUMBER FROM DATABASE
    const getPoint = async () => {
      await getPOS();
    };
    //getting total quanity of order_tickets
    const getQuantity = async () => {
      const total = await getTotalOrderTickets();
      settotalQuantity(total);
    };
    getQuantity();
    getOrganizers();
    getScanner();
    getPoint();
    fetchData();
    getAttendees();

    setIsLoading(false);
  }, []);

  //  DISPLAYING THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!state && !isAdmin) {
    // console.log("IS ADMIN: " + isAdmin);
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* ADMIN DASHBOARD BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">Dashboard</h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <Link
                    style={{
                      color: layout_page?.primary_color,
                    }}
                    to="/admin_dashboard"
                    className="dark"
                  >
                    <i className="fas fa-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/* ADMIN DASHBOARD MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              {!isMobile && <AdminSideNav currentPage={"dashboard"} />}
              {/* <AdminSideNav currentPage={"dashboard"} /> */}

              {/*  ADMMIN DASHBOARD CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <header className="section-heading mb-3">
                      <h4 className="title-section float-left">
                        <i className="fas fa-calendar-alt" /> Events summary
                      </h4>
                      <Link
                        style={{
                          color: layout_page?.primary_color,
                        }}
                        to="/manage_events"
                        className="float-right"
                      >
                        <i className="fas fa-cog" /> Manage events
                      </Link>
                      <div className="clearfix" />
                    </header>
                    <div className="row mb-3 pl-2 pr-2 d-flex">
                      {/* EVENTS ADDED */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className=" rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-calendar-alt" />
                          <h4>{formatNumberWithCommas(events?.length)}</h4>
                          <h5 className="mb-0">Events added</h5>
                        </div>
                      </div>

                      {/* PUBLISHED EVENTS */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-calendar-alt" />
                          <h4>{formatNumberWithCommas(events?.length)}</h4>
                          <h5 className="mb-0">Published events</h5>
                        </div>
                      </div>

                      {/* UPCOMING EVENTS */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-calendar-alt" />
                          <h4>
                            {formatNumberWithCommas(upcomingEvents?.length)}
                          </h4>
                          <h5 className="mb-0">Upcoming events</h5>
                        </div>
                      </div>

                      {/* EVENT DATES */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-calendar-alt" />
                          <h4>
                            {formatNumberWithCommas(numEventDates?.length)}
                          </h4>
                          <h5 className="mb-0">Event dates</h5>
                        </div>
                      </div>
                    </div>

                    {/* LIST OF EVENTS SUMMARY */}
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="table-responsive">
                            {/* DISPLAYING THE FIRST THREE EVENTS */}
                            <EventsAdmin />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <header className="section-heading mb-3 mt-5">
                      <h4 className="title-section float-left">
                        <i className="fas fa-cart-arrow-down" /> Orders summary
                      </h4>
                      <Link
                        to="/manage_orders"
                        style={{ color: layout_page?.primary_color }}
                        className="float-right"
                      >
                        <i className="fas fa-cog" /> Manage orders
                      </Link>
                      <div className="clearfix" />
                    </header>
                    <div className="row mb-3 pl-2 pr-2 d-flex">
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-cart-arrow-down" />
                          <h4>
                            {" "}
                            {formatNumberWithCommas(numOfOrders?.length)}{" "}
                          </h4>
                          <h5 className="mb-0">Orders placed</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-cart-arrow-down" />
                          <h4>{formatNumberWithCommas(numOfOrders?.length)}</h4>
                          <h5 className="mb-0">Paid orders</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-cart-arrow-down" />
                          <h4>{formatNumberWithCommas(0)}</h4>
                          <h5 className="mb-0">Awaiting payment</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-ticket-alt" />
                          <h4>{formatNumberWithCommas(totalQuantity)}</h4>
                          <h5 className="mb-0">Tickets sold</h5>
                        </div>
                      </div>
                    </div>

                    <div className=" my-5">
                      {" "}
                      <AdminGraph />{" "}
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="table-responsive">
                            <OrdersAdmin />
                          </div>
                        </div>
                      </div>
                    </div>
                    <header className="section-heading mb-3 mt-5">
                      <h4 className="title-section float-left">
                        <i className="fas fa-users-cog" /> Users summary
                      </h4>
                      <Link
                        to="/manage_users"
                        style={{ color: layout_page?.primary_color }}
                        className="float-right"
                      >
                        <i className="fas fa-cog" /> Manage users
                      </Link>
                      <div className="clearfix" />
                    </header>
                    <div className="row mb-3 pl-2 pr-2 d-flex">
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-user-check" />
                          <h4> {formatNumberWithCommas(attendees?.length)} </h4>
                          <h5 className="mb-0">Active attendees</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-id-card" />
                          <h4>
                            {" "}
                            {formatNumberWithCommas(
                              activeOrganizers?.length
                            )}{" "}
                          </h4>
                          <h5 className="mb-0">Active organizers</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-qrcode" />
                          <h4>
                            {" "}
                            {formatNumberWithCommas(scannerNumber?.length)}{" "}
                          </h4>
                          <h5 className="mb-0">Scanners</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-print" />
                          <h4> {formatNumberWithCommas(numberPOS?.length)} </h4>
                          <h5 className="mb-0">Points of sale</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="table-responsive">
                            <UsersAdmin />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
