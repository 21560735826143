import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { Link, useLocation, useParams } from "react-router-dom";
import "./venuedetails.css";
import { useVenues } from "../../store/venues_store";
import { useEvents } from "../../store/event_store";
import EventTiles from "../../components/event_tile/event_tiles";
import { useSettings } from "../../store/setting_store";
import Slider from "react-slick";
import Loader from "../../components/loader/loader";
import { tableStore } from "../../store/table_store";
import VenueTables from "./venue_tables";
import { Element, Link as ScrollLink } from "react-scroll";

export default function VenueDetails() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the target element when the component mounts
    const targetElement = document.getElementById("venue_tables");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  //getting venues from store
  const getVenueById = useVenues((state) => state.getVenueById);

  //getting events from the database.
  const upcomingEvents = useEvents((state) => state.upcomingEvents);
  const layout_page = useSettings((state) => state.layout_page);

  const { venueId } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [venueEvents, setVenueEvents] = useState([]);

  // RETRIEVING A VENUE BY ITS ID
  const [venue, setVenue] = useState(null);

  // const { state } = useLocation();
  // const { event } = state;

  // const location = useLocation();
  // const { event } = location.state || {};

  const venue_room = tableStore((state) =>
    state.venueTableTypes?.find(
      (venueTableType) => venueTableType?.venue?.value === venue?.id
    )
  );

  useEffect(() => {
    //getting the venue by id.
    async function fetchData() {
      const data = await getVenueById(venueId);
      setVenue(data);

      //getting all the ucomming  events of that venue
      const getVenueEvents = upcomingEvents.filter((event) => {
        return event?.organizer_id === data?.organizer_id;
      });
      setVenueEvents(getVenueEvents);
    }
    fetchData();
  }, [venueId]);

  //next arrow
  const nextImage = () => {
    if (currentImageIndex < venue?.venue_images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  //prevImage
  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Calculate the number of slides to show based on the length of otherOrganizerEvents
  const slidesToShow = Math.min(4, venueEvents?.length);

  // SLIDER SETTINGS
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, // Use calculated value
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(3, venueEvents?.length),
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(1, venueEvents?.length),
        },
      },
    ],
  };

  return (
    <div>
      <Header currentPage={"Venues"} />

      {/* BREADCRUMP */}
      {venue ? (
        <>
          <section className="section-pagetop bg-gray">
            <div className="container clearfix">
              <h4
                className="title-page dark b float-xl-left mb-0"
                key={`${venue.id}-bigbreadcrump`}
              >
                {venue?.name}
              </h4>
              <nav className="float-xl-right mt-2 mt-xl-0">
                <ol className="breadcrumb text-white">
                  <li className="breadcrumb-item">
                    <Link to="/" className="dark">
                      <i className="fas fa-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/venues" className="dark">
                      Venue
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    key={`${venue.id}-smallbreadcrump`}
                  >
                    {venue?.name}
                  </li>
                </ol>
              </nav>
            </div>
          </section>

          {/* VENUE IMAGE */}
          <div className="row no-gutters">
            <div className="col-12 p-0">
              <section className="section-main">
                <div
                  className="owl-init slider-main owl-carousel owl-loaded owl-drag"
                  data-items={1}
                  data-dots="false"
                  data-nav="false"
                  data-autoplay="false"
                >
                  <div className="owl-stage-outer">
                    <div
                      className="owl-stage"
                      style={{
                        transform: "translate3d(0px, 0px, 0px)",
                        transition: "all 0s ease 0s",
                        width: "100%",
                      }}
                    >
                      <div
                        className="owl-item active"
                        style={{ width: "100%" }}
                      >
                        <div className="item-slide d-flex align-items-center">
                          {/* Left arrow */}
                          {currentImageIndex > 0 && (
                            <span
                              onClick={prevImage}
                              style={{ fontSize: 30 }}
                              className="text-white fw-bolder arrow ml-5 cursor-pointer"
                            >
                              <i className="fas fa-chevron-left"></i>
                            </span>
                          )}
                          <img
                            className="slider-img justify-content-center align-self-center img-lazy-load b-loaded"
                            src={venue?.venue_images[currentImageIndex]}
                            alt={venue?.name}
                          />

                          {/* Right arrow */}
                          {currentImageIndex <
                            venue?.venue_images.length - 1 && (
                            <span
                              onClick={nextImage}
                              style={{ fontSize: 30 }}
                              className="text-white fw-bolder arrow mr-5 cursor-pointer"
                            >
                              <i className="fas fa-chevron-right "></i>
                            </span>
                          )}
                          <div
                            className="slider-blured-background"
                            style={{
                              backgroundImage: `url("${venue?.venue_images[currentImageIndex]}")`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* go to the servation section */}
          {venue_room && (
            <ScrollLink smooth={true} duration={500} to="venue_tables">
              <div className=" d-flex justify-content-end bg-white pt-3 ">
                <div
                  className=" d-flex justify-content-center py-2 text-lg btn-checkout mr-3  text-nowrap text-right"
                  style={{ width: 200 }}
                >
                  Book Reservation
                </div>
              </div>
            </ScrollLink>
          )}

          {/* VENUE DETAILS */}
          <section className="section-content padding-y bg-white">
            <div className="container">
              <div className="row">
                <aside className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      {/* DISPLAYING VENUE TITLE AND CATEGORY */}
                      <div className="row mb-5">
                        <div className="col-12 col-lg-6">
                          <h1 className="text-center text-lg-left">
                            {venue?.name}
                          </h1>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="text-muted text-center text-lg-right">
                            {venue?.name}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 ">
                          {/* DISPLAYING THE LOCATION OF THE VENUE */}
                          <dl className="dlist-align dlist-border clearfix mb-0 w-100">
                            <dt>Location</dt>
                            <dd>
                              <iframe
                                title=" "
                                height={400}
                                className="w-100 border-0 venue-map"
                                src={`https://maps.google.com/maps?q=${venue?.name}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                allowFullScreen
                              />

                              <dl className="mt-3">
                                <dt>Address</dt>
                                <dd>{venue?.street_address}</dd>
                              </dl>
                            </dd>
                          </dl>

                          <dl className="dlist-align dlist-border clearfix mb-0 w-100">
                            <dt>The space</dt>
                            <dd
                              key={`${venue.id}-html`}
                              dangerouslySetInnerHTML={{
                                __html: venue.description,
                              }}
                            ></dd>
                          </dl>

                          {/* DISPLAYING THE DESCRIPTION OF THE VENUE */}
                          <dl className="dlist-align dlist-border clearfix mb-0 w-100">
                            <dt>Amenities</dt>
                            <dd className="pb-0">
                              {venue && venue?.amenities ? (
                                <ul className="fa-ul list-lg list-inline ml-0">
                                  {venue.amenities.map((amenity, index) => (
                                    <li
                                      className="list-inline-item text-muted mr-3 mb-3"
                                      key={index}
                                    >
                                      <span>
                                        <p>{amenity}</p>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No amenities available</p>
                              )}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              {/* table bookings */}
              {venue_room && (
                <div id="venue_tables">
                  <Element name="venue_tables">
                    <VenueTables venue={venue} venue_room={venue_room} />
                  </Element>
                </div>
              )}

              {/* EVENTS ORGANIZED AT THIS VENUE */}
              {venueEvents?.length > 0 && (
                <div className="row mb-2">
                  <div className="col-12">
                    <h3
                      className="mt-5 mb-4 text-center"
                      style={{ color: layout_page.primary_color }}
                    >
                      Events organized at {venue?.name}
                    </h3>
                  </div>
                  <div>
                    {/* EVENTS FOR THIS VENUE */}
                    <Slider {...settings}>
                      {venueEvents?.map((event, index) => (
                        <EventTiles
                          key={index}
                          event={event}
                          num_tiles={venueEvents.length === 1 ? "3" : "12"}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              )}

              <div className="mt-5">
                <div id="disqus_thread" />
                <noscript>
                  Please enable JavaScript to view the &lt;a
                  href="https://disqus.com/?ref_noscript"&gt;comments powered by
                  Disqus.&lt;/a&gt;
                </noscript>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
}
