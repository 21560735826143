import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import PopingOutCurrencies from "./PopingOutCurrencies";
import CustomPagination from "../../../components/pagination/Pagination";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function ManageCurrencies() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const navigate = useNavigate();
  const currencies = useSettings((state) => state.currencies);
  const getCurrencies = useSettings((state) => state.getCurrencies);
  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    async function fetchData() {
      await getCurrencies();
    }
    fetchData();
  }, [currencies?.length]);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [currenciesPerPage] = useState(20);
  //get current events
  const indexOfLastcurrencies = currentPage * currenciesPerPage;
  const indexOfFastcurrencies = indexOfLastcurrencies - currenciesPerPage;
  const current_currencies = currencies?.slice(
    indexOfFastcurrencies,
    indexOfLastcurrencies
  );

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Manage currencies"
          page_link="Payment settings"
          link="manage_payments"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {currencies?.length} result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <Link
                        to="/add_currency"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-plus" /> Add a new currency
                      </Link>
                    </div>
                  </div>
                  <form>
                    <div className="row mt-4">
                      <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0">
                        <label htmlFor="keyword">Ccy</label>
                        <input
                          id="keyword"
                          name="ccy"
                          type="text"
                          className="form-control bg-white"
                          defaultValue=""
                        />
                      </div>
                      <div className="col-sm-12 col-lg-5 mb-3 mb-lg-0">
                        <label htmlFor="keyword">Symbol</label>
                        <input
                          id="keyword"
                          name="symbol"
                          type="text"
                          className="form-control bg-white"
                          defaultValue=""
                        />
                      </div>
                      <div className="col-sm-12 col-lg-2 text-right mb-3 mb-lg-0">
                        <button
                          type="submit"
                          className="btn text-white"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-search" /> Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Ccy</th>
                              <th>Symbol</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_currencies?.map((currency) => (
                              <tr key={currency?.id}>
                                <td>{currency?.ccy}</td>
                                <td>{currency?.symbol}</td>
                                <td className="text-center">
                                  <PopingOutCurrencies
                                    currency_id={currency?.id}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {currencies?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          currencies?.length / currenciesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
