import React from 'react';

export default function EventTypeRadio({
  isChecked,
  option,
  htmlFor,
  handleChange,
}) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        value={option?.value}
        onChange={(e) => handleChange(e, option)}
        checked={isChecked}
      />
      <label className="form-check-label" htmlFor={htmlFor}>
        {option?.label}
      </label>
    </div>
  );
}
