import React from "react";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ArticlesSideNav from "./components/side_nav";

export default function Articles() {
  
  return (
    <div>
      {/* ADDING HEADER */}
      <Header />

      {/* BREADCRUMP */}
      <section class="section-pagetop bg-gray">
        <div class="container clearfix">
          <h4 class="title-page dark b float-xl-left mb-0">
            What does a ticket look like?
          </h4>

          <nav class="float-xl-right mt-2 mt-xl-0">
            <ol class="breadcrumb text-white">
              <li class="breadcrumb-item">
                <a href="/en" class="dark">
                  <i class="fas fa-home"></i>
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="/how_it_works" class="dark">
                  Help center
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="/attendee_support" class="dark">
                  Attendee
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                What does a ticket look like?
              </li>
            </ol>
          </nav>
        </div>
      </section>

      {/* ARTICLES MAIN SECTION */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* SIDE NAV */}
            <ArticlesSideNav />

            {/* RIGHT COLUMN FOR DISPLAYING THE ARTICLES */}
            <div className="col-lg-9 order-0 order-lg-1">
              <div className="card box">
                <div className="card-body">
                  <header className="section-heading mb-5">
                    <h1 className="title-section">
                      What does a ticket look like?
                    </h1>
                  </header>
                  <article>
                    <h4
                      style={{
                        fontFamily: '"Open Sans", Cairo, sans-serif',
                        color: "rgb(0,0,0)",
                      }}
                    >
                      The standard Lorem Ipsum passage, used since the 1500s
                    </h4>
                    <div>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum."
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <h4
                      style={{
                        fontFamily: '"Open Sans", Cairo, sans-serif',
                        color: "rgb(0,0,0)",
                      }}
                    >
                      Section 1.10.32 of "de Finibus Bonorum et Malorum",
                      written by Cicero in 45 BC
                    </h4>
                    <div>
                      "Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit, sed quia consequuntur magni dolores eos
                      qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem ipsum quia dolor sit amet,
                      consectetur, adipisci velit, sed quia non numquam eius
                      modi tempora incidunt ut labore et dolore magnam aliquam
                      quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                      exercitationem ullam corporis suscipit laboriosam, nisi ut
                      aliquid ex ea commodi consequatur? Quis autem vel eum iure
                      reprehenderit qui in ea voluptate velit esse quam nihil
                      molestiae consequatur, vel illum qui dolorem eum fugiat
                      quo voluptas nulla pariatur?"
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <h4
                      style={{
                        fontFamily: '"Open Sans", Cairo, sans-serif',
                        color: "rgb(0,0,0)",
                      }}
                    >
                      1914 translation by H. Rackham
                    </h4>
                    <div>
                      "But I must explain to you how all this mistaken idea of
                      denouncing pleasure and praising pain was born and I will
                      give you a complete account of the system, and expound the
                      actual teachings of the great explorer of the truth, the
                      master-builder of human happiness. No one rejects,
                      dislikes, or avoids pleasure itself, because it is
                      pleasure, but because those who do not know how to pursue
                      pleasure rationally encounter consequences that are
                      extremely painful. Nor again is there anyone who loves or
                      pursues or desires to obtain pain of itself, because it is
                      pain, but because occasionally circumstances occur in
                      which toil and pain can procure him some great pleasure.
                      To take a trivial example, which of us ever undertakes
                      laborious physical exercise, except to obtain some
                      advantage from it? But who has any right to find fault
                      with a man who chooses to enjoy a pleasure that has no
                      annoying consequences, or one who avoids a pain that
                      produces no resultant pleasure?"
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <h4
                      style={{
                        fontFamily: '"Open Sans", Cairo, sans-serif',
                        color: "rgb(0,0,0)",
                      }}
                    >
                      Section 1.10.33 of "de Finibus Bonorum et Malorum",
                      written by Cicero in 45 BC
                    </h4>
                    <div>
                      "At vero eos et accusamus et iusto odio dignissimos
                      ducimus qui blanditiis praesentium voluptatum deleniti
                      atque corrupti quos dolores et quas molestias excepturi
                      sint occaecati cupiditate non provident, similique sunt in
                      culpa qui officia deserunt mollitia animi, id est laborum
                      et dolorum fuga. Et harum quidem rerum facilis est et
                      expedita distinctio. Nam libero tempore, cum soluta nobis
                      est eligendi optio cumque nihil impedit quo minus id quod
                      maxime placeat facere possimus, omnis voluptas assumenda
                      est, omnis dolor repellendus. Temporibus autem quibusdam
                      et aut officiis debitis aut rerum necessitatibus saepe
                      eveniet ut et voluptates repudiandae sint et molestiae non
                      recusandae. Itaque earum rerum hic tenetur a sapiente
                      delectus, ut aut reiciendis voluptatibus maiores alias
                      consequatur aut perferendis doloribus asperiores
                      repellat."
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <h4
                      style={{
                        fontFamily: '"Open Sans", Cairo, sans-serif',
                        color: "rgb(0,0,0)",
                      }}
                    >
                      1914 translation by H. Rackham
                    </h4>
                    <div>
                      "On the other hand, we denounce with righteous indignation
                      and dislike men who are so beguiled and demoralized by the
                      charms of pleasure of the moment, so blinded by desire,
                      that they cannot foresee the pain and trouble that are
                      bound to ensue; and equal blame belongs to those who fail
                      in their duty through weakness of will, which is the same
                      as saying through shrinking from toil and pain. These
                      cases are perfectly simple and easy to distinguish. In a
                      free hour, when our power of choice is untrammelled and
                      when nothing prevents our being able to do what we like
                      best, every pleasure is to be welcomed and every pain
                      avoided. But in certain circumstances and owing to the
                      claims of duty or the obligations of business it will
                      frequently occur that pleasures have to be repudiated and
                      annoyances accepted. The wise man therefore always holds
                      in these matters to this principle of selection: he
                      rejects pleasures to secure other greater pleasures, or
                      else he endures pains to avoid worse pains."
                    </div>
                  </article>
                  <hr />
                  <div className="row">
                    <div className="col-12 text-center text-lg-left">
                      <a
                        href="/how_it_works?keyword=ticket"
                        className="btn btn-sm btn-default mr-3 mb-3 mt-3"
                      >
                        ticket
                      </a>
                    </div>
                  </div>
                  <hr />
                  <article className="box mt-3 text-center">
                    <figure>
                      <div className="aside align-self-center">
                        <span className="icon-wrap icon-md round bg-primary">
                          <i className="fas fa-life-ring white" />
                        </span>
                      </div>
                      <figcaption className="text-wrap">
                        <h5 className="text-muted mt-4 mb-4">
                          You did not find an answer to your inquiry? Let us
                          know and we will be glad to give you further help
                        </h5>
                        <a
                          href="/contact"
                          className="btn btn-dark justify-content-center rounded-0"
                        >
                          CONTACT US
                        </a>
                      </figcaption>
                    </figure>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
