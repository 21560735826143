import { useEffect, useState } from "react";
import { displayErrorToast, displaySuccessToast } from "../services/helpers";
import Loader from "../components/loader/loader";
import Header from "../components/header/header";
import TextEditor from "../components/text_editor/text_editor";
import Footer from "../components/footer/footer";
import { useSettings } from "../store/setting_store";
import SearchableDropdown from "../components/searchable_dropdown/searchable_dropdown";
import { useEvents } from "../store/event_store";
import { auth } from "../Firebase";
import { Timestamp } from "firebase/firestore";

export default function AddEventIdeas() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const layout_page = useSettings((state) => state.layout_page);
  const getVisibleAudiences = useEvents((state) => state.getAudiences);
  const addEventIdeas = useEvents((state) => state.addEventIdeas);
  //get visible categories
  const getVisibleEventCategories = useEvents(
    (state) => state.getVisibleEventCategories
  );

  useEffect(() => {
    // GETTING THE EVENT CATEGORIES
    const get_event_categories = async () => {
      const returnedCategories = await getVisibleEventCategories();
      const event_categories = [];

      for (let i = 0; i < returnedCategories.length; i++) {
        event_categories.push({
          value: returnedCategories[i].id,
          label: returnedCategories[i].name,
        });
      }

      setEventCategories(event_categories);
    };

    // GETTING THE EVENT CATEGORIES
    const get_audiences_list = async () => {
      const returnedAudiences = await getVisibleAudiences();
      const audiences = [];
      for (let i = 0; i < returnedAudiences.length; i++) {
        audiences.push(returnedAudiences[i].name);
      }
      setAudienceList(audiences);
    };
    get_event_categories();
    get_audiences_list();
  }, []);

  // METHOD TO HANDLE CATEGORIES
  const handleCategories = (e) => {
    setCategory(e);
  };

  // METHOD TO ADD A NEW AUDIENCE
  const handleAudiences = (audience) => {
    const newAudiences = [...audiences];
    const audienceIndex = newAudiences.indexOf(audience);
    if (audienceIndex !== -1) {
      //if the audience is already selected, remove it
      newAudiences.splice(newAudiences, 1);
    } else {
      // If the audience is not selected, add it
      newAudiences.push(audience);
    }
    setAudiences(newAudiences);
  };

  // HANDLING UPLOADING THE CURRENCY
  const handleAddEventIdea = async (e) => {
    e.preventDefault();
    const user_id = auth.currentUser?.uid || " ";
    const user_email = auth.currentUser?.email || " ";
    const errors = validateForm();
    setIsLoading(true);
    if (Object.keys(errors).length === 0) {
      const data = {
        user_id: user_id,
        title: title,
        idea_description: description,
        category: category,
        audiences: audiences,
        user_email: user_email,
        created_at: Timestamp.fromDate(new Date()),
      };
      const response = await addEventIdeas(data);
      if (response) {
        displaySuccessToast("The I dea has been sent successfully");
        setIsLoading(false);
        setTitle("");
        setDescription("");
        setCategory("");
      } else {
        console.log("some thing is wrong");
        displayErrorToast("something is wrong");
        setIsLoading(false);
        setTitle("");
        setDescription("");
        setCategory("");
      }
    } else {
      console.log("SOMETHING WENT WRONG-");
      setErrors(errors);
      setIsLoading(false);
    }
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING TITLE
    if (title === "") {
      errors.title = "Idea Name is required";
    }

    // VALIDATING content
    if (description === "") {
      errors.description = "Description is required";
    }

    // VALIDATING AUDIENCES
    if (audiences?.length === 0) {
      errors.audiences = "Please select the audiences";
    }

    // VALIDATING CATEGORY
    if (category === "") {
      errors.category = "Please select category";
    }

    return errors;
  }

  // HANDLE DESCRIPTION
  const handleContent = (e) => {
    setDescription(e);
  };

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* BREADCRUMP */}
      <section
        className="section-intro  padding-y-lg padding-x-md"
        style={{
          backgroundColor: layout_page?.primary_color,
        }}
      >
        <div className="row">
          <div className="col-sm-8 mx-auto">
            <article className="white text-center mb-5">
              <h1 className=" display-5 font-weight-bold">
                Welcome to the Event Idea Portal!
              </h1>

              <p>
                We believe that the best events start with great ideas. Do you
                have a fantastic event concept that you'd like to see come to
                life? Share it with us, and who knows, it might just become the
                next big event!
              </p>
              <p>
                Your creativity and imagination can shape the future of our
                events. So don't hold back.{" "}
                <span className=" text-uppercase font-italic font-weight-bold">
                  let your ideas flow!
                </span>
              </p>
            </article>
          </div>
        </div>
      </section>

      {/* MAIN SECTION */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <form onSubmit={handleAddEventIdea}>
                    <div>
                      {/* PAGE CONTENT */}

                      <fieldset className="form-group">
                        <div className=" tab-content">
                          <div
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="add_page">
                              {/* TITLE */}
                              <div className="form-group">
                                <label htmlFor="add_page" className="required">
                                  Event Idea Name
                                </label>

                                {errors.title && (
                                  <span style={{ marginTop: 0, color: "red" }}>
                                    {errors.title}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="add_page_1"
                                  name="title"
                                  required="required"
                                  maxLength={80}
                                  pattern=".{1,}"
                                  className="form-control"
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>

                              <div className="form-group">
                                <label
                                  className="required"
                                  htmlFor="event_category"
                                >
                                  Category
                                </label>
                                <small
                                  id="event_category_help"
                                  className="form-text text-muted mb-3"
                                >
                                  <i
                                    className="fas fa-info-circle mr-1"
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  ></i>
                                  Make sure to select the right category for
                                  your event idea
                                </small>

                                {/* EVENT CATEGORY */}
                                {errors.category && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.category}
                                  </span>
                                )}
                                <SearchableDropdown
                                  value={category}
                                  options={eventCategories}
                                  onChange={handleCategories}
                                  isMulti={false}
                                  required="required"
                                />
                              </div>

                              {/* ARTICLE CONTENT */}
                              <div className="form-group">
                                <label
                                  htmlFor="add_page_1"
                                  className="required"
                                >
                                  Event Idea Description
                                </label>

                                {errors.description && (
                                  <span style={{ marginTop: 0, color: "red" }}>
                                    {errors.description}
                                  </span>
                                )}
                                <TextEditor
                                  value={description}
                                  onChange={handleContent}
                                />
                              </div>

                              {/* AUDIENCES */}
                              {errors.audiences && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.audiences}
                                </span>
                              )}
                              <fieldset className="form-group">
                                <legend className="col-form-label required">
                                  Audiences
                                </legend>
                                <small
                                  id="event_audiences_help"
                                  className="form-text text-muted mb-3"
                                >
                                  <i
                                    className="fas fa-info-circle mr-1"
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  ></i>
                                  Select the audience types that are targeted in
                                  your event idea
                                </small>
                                <div
                                  id="event_audiences"
                                  aria-describedby="event_audiences_help"
                                >
                                  {audienceList &&
                                    audienceList.map((audience, index) => {
                                      return (
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                          <input
                                            type="checkbox"
                                            id={"event_audiences_" + index}
                                            name={index}
                                            className="custom-control-input"
                                            value={audience}
                                            onChange={() =>
                                              handleAudiences(audience)
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={"event_audiences_" + index}
                                          >
                                            {audience}
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div className="form-group">
                        <button
                          type="submit"
                          name="help_center_article[save]"
                          className="btn btn-primary btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
