import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { Link, useParams } from "react-router-dom";
import ReadMore from "../../components/read_more/read_more";
import { displaySnackbar } from "../../services/events";
import { Avatar, Tab, Tabs } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import "./organizer_details.css";
import EventTile from "./../../components/event_tile/event_tile";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ReviewTile from "../../components/reviews/review_tile";
import { userStore } from "../../store/user_store";
import { useEvents } from "../../store/event_store";
import { useReview } from "../../store/review_store";
import AvatarComponent from "./avatar_component";
import { useSettings } from "../../store/setting_store";

const MapComponent = withGoogleMap((latitude, longitude) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -34.397, lng: 150.644 }}
    zoom={10}
    mapContainerStyle={{ width: "100%", height: "400px" }}
  >
    <Marker position={{ lat: -34.397, lng: 150.644 }} />
  </GoogleMap>
));

export default function OrganizerDetails() {
  const { id } = useParams();

  const user = userStore((state) => state.user);
  //getting organizer details
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);

  //getting all organizer events
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);

  // Getting user details
  const getUserDetails = userStore((state) => state.getUserDetails);

  //getting organizer upcomming events
  const getOrganizerUpcomingEvents = useEvents(
    (state) => state.getOrganizerUpcomingEvents
  );
  // getting organizer past events
  const getOrganizerPastEvents = useEvents(
    (state) => state.getOrganizerPastEvents
  );
  //getting organizer followers
  const getOrganizerFollowers = useEvents(
    (state) => state.getOrganizerFollowers
  );
  const organizerFollowers = useEvents((state) => state.organizerFollowers);
  //getting organizer reviews
  const getOrganizerReviews = useReview((state) => state.getUserReviews);
  const organizerReviews = useReview((state) => state.userReviews);
  const layout_page = useSettings((state) => state.layout_page);
  const followOrganizer = userStore((state) => state.followOrganizers);

  const [organizerEvents, setOrganizerEvents] = useState([]);
  const [organizerDetails, setOrganizerDetails] = useState([]);
  const [showFollowingAlert, setShowFollowingAlert] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [showUnfollowingAlert, setShowUnfollowingAlert] = useState(false);
  const [UpcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [currentTab, setCurrentTab] = useState("onsale");

  //  METHOD TO HANDLE GETTING ORGANIZER DETAILS
  async function handleGetOrganizerDetails() {
    const organizer = await getOrganizerDetails(id);
    setOrganizerDetails(organizer);
    console.log("the organizer details are:", organizer);
  }

  //  METHOD TO HANDLE GETTING ORGANIZER EVENTS
  async function handleGetOrganizerEvents() {
    const allOrganiserEvents = await getOrganizerEvents(id);
    setOrganizerEvents(allOrganiserEvents);
    console.log("the organizer events are:", allOrganiserEvents);

    // GETTING UPCOMING EVENTS
    const upcomingEvents = await getOrganizerUpcomingEvents(id);
    console.log("LENGTH OF UPCOMING EVENTS: " + upcomingEvents?.length);
    setUpcomingEvents(upcomingEvents);

    // GETTING PAST EVENTS
    const pastEvents = await getOrganizerPastEvents(id);
    console.log("LENGTH OF PAST EVENTS: " + pastEvents?.length);
    setPastEvents(pastEvents);
  }

  //  METHOD TO HANDLE GETTING ORGANIZER FOLLOWERS
  async function handleGetOrganizerFollowers() {
    await getOrganizerFollowers(id);
    console.log("organizer followers are:", organizerFollowers);
  }

  //  METHOD TO HANDLE GETTING ORGANIZER FOLLOWERS
  async function handleGetOrganizerReviews() {
    await getOrganizerReviews(id);
    console.log("organizer reviews:", organizerReviews);
  }

  // METHOD TO HANDLE FOLLOWING THE ORGANIZER
  async function handlefollowOrganizer(e) {
    e.preventDefault();
    // ADDING THE DATA TO FIRESTORE
    const data = {
      organizer_id: organizerDetails?.id,
      organizer_logo: organizerDetails?.organizer_logo,
      organizer_name: organizerDetails?.organizer_name,
      user_id: user?.user_id,
      followed_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      user_image: user?.profile_picture,
    };

    const followingResponse = await followOrganizer(data);
    if (followingResponse) {
      console.log("NOW FOLLOWING");
      setIsFollowing(true);
      setShowFollowingAlert(true);
    }
  }

  // METHOD TO HANDLE UNFOLLOWING THE ORGANIZER
  async function handleUnfollowOrganizer(e) {
    e.preventDefault();
    console.log("UNFOLLOWING");
    setShowUnfollowingAlert(true);
    setIsFollowing(false);
  }

  // METHOD TO HANDLE THE TAB CHANGE
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    console.log("CURRENT TAB: " + newValue);
  };

  useEffect(() => {
    // CALLING THE METHOD TO GET ORGANIZER DETAILS
    handleGetOrganizerReviews();
    handleGetOrganizerDetails();
    handleGetOrganizerEvents();
    handleGetOrganizerFollowers();
  }, []);

  //for handling video url
  function getEmbeddedYouTubeUrl(youtubeUrl) {
    const videoId = youtubeUrl.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  return (
    <div>
      <Header />

      {/* FOLLOWING SNACKBAR */}
      {displaySnackbar({
        message: `You are now following this organizer`,
        color: "success",
        open: showFollowingAlert,
        onClose: setShowFollowingAlert,
      })}

      {/* FOLLOWING SNACKBAR */}
      {displaySnackbar({
        message: `You have unfollowed this organizer`,
        color: "success",
        open: showUnfollowingAlert,
        onClose: setShowUnfollowingAlert,
      })}

      {/* ADDING THE MAIN ORGANIZER DETAILS SECTION */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <main className="col-12">
              <div className="shadow rounded overflow-hidden">
                {/* ORGANIZER COVER PHOTO */}
                <img
                  src={
                    organizerDetails?.cover_photo
                      ? organizerDetails?.cover_photo
                      : "https://coolarts.ca/wp-content/uploads/2020/09/qi-bin-w4hbafegiac-unsplash.jpg"
                  }
                  alt=""
                  className="organizer-profile-cover"
                  width={"100%"}
                />

                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="organizer-profile-sidebar text-center ">
                      {/* ORGANIZER LOGO */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt="Organizer Logo"
                          src={
                            organizerDetails?.organizer_logo ||
                            "https://www.randomunafestaacaso.it/site/wp-content/uploads/2014/08/random-summer-04-1024x684.jpg"
                          }
                          style={{
                            width: "12rem",
                            height: "12rem",
                            border: "2px solid white",
                          }}
                          className="avatar organizer-profile-logo"
                        />
                      </div>

                      {/* ORGANIZER NAME */}
                      <h4 className="font-weight-bold text-center my-5">
                        {organizerDetails?.organizer_name}
                      </h4>

                      {/* ORGANIZER CONTACT */}
                      <ul className="list-inline mb-0">
                        {/* WEBSITE */}
                        {organizerDetails?.website && (
                          <li className="list-inline-item">
                            <a
                              href={organizerDetails?.website}
                              className="display-inline-block mr-3"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="icon fas fa-globe fa-lg fa-fw" />
                            </a>
                          </li>
                        )}

                        {/* EMAIL */}
                        {organizerDetails?.email && (
                          <li className="list-inline-item">
                            <a
                              href={`mailto:${organizerDetails?.email}`}
                              className="display-inline-block mr-3"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="icon fas fa-at fa-lg fa-fw" />
                            </a>
                          </li>
                        )}

                        {/* PHONE NUMBER */}
                        {organizerDetails?.phone_number && (
                          <li className="list-inline-item">
                            <a
                              href={`tel:${organizerDetails?.phone_number}`}
                              className="display-inline-block mr-3"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="icon fas fa-phone fa-lg fa-fw" />
                            </a>
                          </li>
                        )}

                        {/* FACEBOOK */}
                        {organizerDetails?.facebook && (
                          <li className="list-inline-item">
                            <a
                              href={organizerDetails?.facebook}
                              className="display-inline-block mr-3"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="icon fab fa-facebook-f fa-lg fa-fw" />
                            </a>
                          </li>
                        )}

                        {/* TWITTER */}
                        {organizerDetails?.twitter && (
                          <li className="list-inline-item">
                            <a
                              href={organizerDetails?.twitter}
                              className="display-inline-block mr-3"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page?.primary_color }}
                            >
                              <i className="icon fab fa-twitter fa-lg fa-fw" />
                            </a>
                          </li>
                        )}

                        {/* INSTAGRAM */}
                        {organizerDetails?.instagram && (
                          <a
                            href={organizerDetails?.instagram}
                            className="display-inline-block mr-3"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: layout_page?.primary_color }}
                          >
                            <i className="icon fab fa-instagram fa-lg fa-fw" />
                          </a>
                        )}

                        {/* LINKEDIN */}
                        {organizerDetails?.linkedin && (
                          <a
                            href={organizerDetails?.linkedin}
                            className="display-inline-block mr-3"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: layout_page?.primary_color }}
                          >
                            <i className="icon fab fa-linkedin fa-lg fa-fw" />
                          </a>
                        )}
                      </ul>

                      {/* ORGANIZER DESCRIPTION */}
                      <div
                        className="py-4 px-4 text-muted line-height-2 readmore rmjs-1 collapsable"
                        data-collapsed-height={150}
                        data-height-margin={20}
                        style={{ height: 150, maxHeight: "none" }}
                        data-readmore=""
                        aria-expanded="false"
                        id="rmjs-2"
                      >
                        <ReadMore
                          text={organizerDetails?.description}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 bg-light">
                    <div className="p-4 d-flex justify-content-center justify-content-md-end text-center">
                      <ul className="list-inline mb-0">
                        {/* COUNTRY */}
                        {organizerDetails?.country && (
                          <li className="list-inline-item mr-3">
                            <h5 className="font-weight-bold mb-0 d-block">
                              <span className="flag flag-ug" />
                            </h5>
                            <small className="text-muted">Uganda</small>
                          </li>
                        )}

                        {/* EVENTS */}
                        <li className="list-inline-item mr-3">
                          <h5 className="font-weight-bold mb-0 d-block">
                            {organizerEvents?.length}
                          </h5>
                          <small className="text-muted">
                            <i className="fas fa-calendar-alt fa-fw mr-1" />
                            Events
                          </small>
                        </li>

                        {/* FOLLOWERS */}
                        <li className="list-inline-item">
                          <h5 className="font-weight-bold mb-0 d-block">
                            {" "}
                            {organizerFollowers?.length}{" "}
                          </h5>
                          <small className="text-muted">
                            <i className="fas fa-folder-plus mr-1" />
                            Followers
                          </small>
                        </li>
                        <li className="list-inline-item ml-3">
                          <button
                            className="organizer-follow btn btn-outline-primary btn-sm btn-block"
                            onClick={
                              !isFollowing
                                ? handlefollowOrganizer
                                : handleUnfollowOrganizer
                            }
                          >
                            <i className="fas fa-folder-plus mr-2" />
                            {isFollowing ? "Unfollow" : "Follow"}
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* UPCOMING AND PAST EVENTS */}
                    <div className="py-4 px-4">
                      <Tabs
                        variant="standard"
                        value={currentTab}
                        aria-label="Event Tabs"
                        onChange={handleTabChange}
                        className="mb-3"
                      >
                        {/* UPCOMING EVENTS */}
                        <Tab
                          label={`Events on sale (${UpcomingEvents?.length})`}
                          value="onsale"
                          aria-controls="organizer-onsale-events"
                          id="organizer-onsale-events-tab"
                          sx={{ textTransform: "none" }}
                        />

                        {/* PAST EVENTS */}
                        <Tab
                          label={`Past events (${pastEvents?.length})`}
                          value="past"
                          aria-controls="organizer-past-events"
                          id="organizer-past-events-tab"
                          sx={{ textTransform: "none" }}
                        />
                      </Tabs>

                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active row"
                          id="organizer-onsale-events"
                          role="tabpanel"
                          aria-labelledby="organizer-onsale-events-tab"
                        >
                          {/* EVENTS ON SALE */}
                          <div className="row">
                            {currentTab === "onsale" &&
                              (UpcomingEvents?.length > 0 ? (
                                UpcomingEvents.map((event) => (
                                  <EventTile event={event} num_tiles={6} />
                                ))
                              ) : (
                                <div className="alert alert-info mb-5">
                                  No Upcoming Events Found
                                </div>
                              ))}
                          </div>

                          {/* IF THE CURRENT TAB IS PAST EVENTS */}
                          <div className="row">
                            {currentTab === "past" &&
                              (pastEvents.length > 0 ? (
                                pastEvents.map((event) => (
                                  <div className="row">
                                    <EventTile event={event} num_tiles={6} />
                                  </div>
                                ))
                              ) : (
                                <div className="alert alert-info mb-5">
                                  No Past Events Found
                                </div>
                              ))}
                          </div>

                          {/* SEE ALL EVENTS ON SALE */}
                          {UpcomingEvents?.length > 3 && (
                            <Link
                              to={
                                "/browse_events/" +
                                organizerDetails?.organizer_name
                              }
                              className="btn btn-outline-primary mx-auto"
                            >
                              <i className="fas fa-ticket-alt" /> See all events
                              on sale
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="py-4 px-4">
                      <h4 className="mb-2">
                        <i className="fas fa-compass mr-1" /> Venues
                      </h4>

                      {/* GOOGLE MAP */}
                      <iframe
                        title=" "
                        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7425113333006!2d32.614175074696576!3d0.35320119964333013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dba2e2bf01621%3A0x13917945a2073612!2s${"Qriscorp (U) Limited"}!5e0!3m2!1sen!2sug!4v1684409216328!5m2!1sen!2sug`}
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>

                    {/* YOUTUBE VIDEO */}
                    {organizerDetails?.youtubeVideoUrl && (
                      <div className="py-4 px-4">
                        <h4 className="mb-2">
                          <i className="fab fa-youtube-square mr-1" /> Video
                        </h4>
                        <iframe
                          title="video"
                          width="100%"
                          height={400}
                          src={getEmbeddedYouTubeUrl(
                            organizerDetails?.youtubeVideoUrl
                          )}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}

                    {/* FOLLOWERS */}
                    <div className="py-4 px-4">
                      <h4 className="mb-2">
                        <i className="fas fa-user-plus mr-1" /> Followers
                      </h4>
                      <div className="card border-0 card-body overflow-auto text-nowrap">
                        <div className="avatar-list py-4">
                          {organizerFollowers && (
                            <React.Fragment>
                              {organizerFollowers.map((follower) => (
                                <AvatarComponent
                                  key={follower.user_id}
                                  userId={follower.user_id}
                                />
                              ))}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* REVIEWS */}
                    <div className="row mt-5 px-4">
                      <div className="col-12">
                        <h4 className="mb-2">
                          <i className="fas fa-star mr-1" /> Reviews
                        </h4>
                      </div>

                      {/* REVIEWS LIST */}

                      {organizerReviews.length > 0 ? (
                        organizerReviews?.map((review) => (
                          <ReviewTile review={review} />
                        ))
                      ) : (
                        <div className="alert alert-info mb-5">
                          No Reviews Yet
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
