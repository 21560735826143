import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import app, { db, storage } from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

const table = (set, get) => ({
  rooms: [],
  tableTypes: [],
  dateTimeSchedules: [],
  tableLabels: [],
  venueTableTypes: [],
  // reservations: [],

  // adding table type to the state
  addTableTypes: (table) =>
    set((state) => ({ tableTypes: [...state.tableTypes, table] })),

  addRoom: async (data) => {
    try {
      const room_id = data.id.toString();
      const docRef = doc(db, "venue_rooms", room_id);
      await setDoc(docRef, data);
      set((state) => ({ rooms: [...state.rooms, data] }));
      return true;
    } catch (error) {
      console.log("Error in adding room", error);
      return null;
    }
  },

  //table types

  addVenueTableTypes: async (table) => {
    try {
      const id = table.id.toString();
      const venueDocRef = doc(db, "venues", table?.venue?.value);
      const tablesCollectionRef = collection(venueDocRef, "tables");
      // Use setDoc to add a document with a specific ID
      await setDoc(doc(tablesCollectionRef, id), table);
      // Update local state only if Firestore update is successful
      set((state) => ({ venueTableTypes: [...state.venueTableTypes, table] }));
      return true;
    } catch (error) {
      console.error("Error in adding table type", error);
      // Handle the error or log it
      return false;
    }
  },

  //getting table types from the database
  getVenueTableTypes: async () => {
    try {
      //get all venues
      const venueSnapShot = await getDocs(collection(db, "venues"));
      const tables = [];
      //iterate through each venue
      for (const venueDoc of venueSnapShot.docs) {
        const venueId = venueDoc.id;
        // Get the tables subcollection for the current venue
        const tablesCollectionRef = collection(db, "venues", venueId, "tables");

        // Get all documents in the tables subcollection
        const tablesSnapshot = await getDocs(tablesCollectionRef);

        //iterate through each table document
        tablesSnapshot.forEach((tableDoc) => {
          //add table data to the result
          tables.push({
            ...tableDoc.data(),
          });
        });
      }
      set((state) => ({ venueTableTypes: tables })); //updating the local state
      return tables;
    } catch (error) {
      console.log("Error in getting table types", error);
      return null;
    }
  },

  //delete table type document
  deleteVenueTableType: async (venueId, tableId) => {
    try {
      const tableCollectionRef = collection(db, "venues", venueId, "tables");
      const tableDocRef = doc(tableCollectionRef, tableId);

      await deleteDoc(tableDocRef);
      console.log(`Table with ID ${tableId} deleted successfully.`);

      set((state) => ({
        venueTableTypes: state.venueTableTypes.filter(
          (venueTableType) => venueTableType.id !== tableId
        ),
      }));
      set((state) => ({
        tableTypes: state.tableTypes.filter(
          (tableType) => tableType.venue_tableType_id !== tableId
        ),
      }));

      set((state) => ({
        tableLabels: state.tableLabels.filter(
          (tableLabel) => tableLabel.venue_tableType_id !== tableId
        ),
      }));
      return true;
    } catch (error) {
      console.log("Error in deleting table type", error);
      return false;
    }
  },

  //edit table type
  editVenueTableType: async (venueId, tableId, updatedData) => {
    try {
      const tableCollectionRef = collection(db, "venues", venueId, "tables");
      const tableDocRef = doc(tableCollectionRef, tableId);
      await updateDoc(tableDocRef, updatedData);

      console.log(`Table with ID ${tableId} updated successfully.`);
      //update the local state here
      set((state) => ({
        venueTableTypes: state.venueTableTypes.map((tableType) =>
          tableType.id === tableId
            ? { ...tableType, ...updatedData }
            : tableType
        ),
      }));

      return true;
    } catch (error) {
      console.log("Error in updating table type", error);
      return false;
    }
  },

  deleteTableType: (id) =>
    set((state) => ({
      tableTypes: state.tableTypes.filter((tableType) => tableType.id !== id),
    })),

  editRoom: (id, updatedRoom) =>
    set((state) => ({
      rooms: state.rooms.map((room) =>
        room.id === id ? { ...room, ...updatedRoom } : room
      ),
    })),

  //deleting the room
  deleteRoom: async (id) => {
    console.log("the id is:", id);
    try {
      const roomRef = doc(db, "venue_rooms", id);
      await deleteDoc(roomRef);
      console.log("room deleted successfully");
      set((state) => ({
        rooms: state.rooms.filter((room) => room.id !== id),
      }));
      set((state) => ({
        tableTypes: state.tableTypes.filter(
          (tableType) => tableType.room_id !== id
        ),
      }));

      set((state) => ({
        tableLabels: state.tableLabels.filter(
          (tableLabel) => tableLabel.room_id !== id
        ),
      }));

      set((state) => ({
        dateTimeSchedules: state.dateTimeSchedules.filter(
          (dateTimeSchedule) => dateTimeSchedule.room_id !== id
        ),
      }));

      set((state) => ({
        prices: state.prices.filter((price) => price.room_id !== id),
      }));

      return true;
    } catch (error) {
      console.log("Error in deleting the venue room", error);
      return false;
    }
  },

  //schedules
  addDateTimeSchedule: (dateTimeSchedule) =>
    set((state) => ({
      dateTimeSchedules: [...state.dateTimeSchedules, dateTimeSchedule],
    })),

  deleteDateTimeSchedule: (id) =>
    set((state) => ({
      dateTimeSchedules: state.dateTimeSchedules.filter(
        (dateTimeSchedule) => dateTimeSchedule.id !== id
      ),
    })),

  //adding table label
  addTableLabel: (tableLabel) =>
    set((state) => ({
      tableLabels: [...state.tableLabels, tableLabel],
    })),
  //delete table label
  deleteTableLabel: (id) =>
    set((state) => ({
      tableLabels: state.tableLabels.filter((label) => label.id !== id),
    })),

  editTableLable: (id, updatedTableLabel) =>
    set((state) => ({
      tableLabels: state.tableLabels.map((tableLabel) =>
        tableLabel.id === id
          ? { ...tableLabel, ...updatedTableLabel }
          : tableLabel
      ),
    })),

  //adding room to database
  addRoomToDataBase: async (data, roomId) => {
    const roomDocRef = doc(db, "venue_rooms", roomId);
    try {
      await setDoc(roomDocRef, data);
      return true;
    } catch (error) {
      console.log("Error in adding the room:", error);
      return null;
    }
  },

  //getting the venues rooms from the database
  getVenueRooms: async () => {
    try {
      const rooms = [];
      const roomDoc = collection(db, "venue_rooms");
      const querySnapshot = await getDocs(roomDoc);
      querySnapshot.forEach((doc) => {
        rooms.push(doc.data());
      });
      set({ rooms: rooms });
      return rooms;
    } catch (error) {
      console.log("Error in getting the rooms:", error);
      return null;
    }
  },

  updateRoomInDatabase: async (id, data) => {
    try {
      await updateDoc(doc(db, "venue_rooms", id), data);
      get().getVenueRooms();
      set((state) => ({
        rooms: state.rooms.map((room) =>
          room.id === id ? { ...room, ...data } : room
        ),
      }));
      return true;
    } catch (error) {
      console.log("Error Updating blog document:", error);
      return false;
    }
  },

  //uploading the image file
  uploadImage: async (file) => {
    if (!file) {
      return;
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, `venue_room_mages/${file.name}`);

    try {
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.log("Error in uploadint the image", error);
    }
  },

  //deleting the image

  deleteImage: async (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("Image deleted successfully");
      return true;
    } catch (error) {
      console.log("Error in deleting the image", error);
      return null;
    }
  },
});

// export const tableStore = create(table);
export const tableStore = create(persist(devtools(table), { name: "table" }));
tableStore.getState().getVenueTableTypes();
