import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  convertTimestampToDateTime,
  determinePaymentType,
  displayErrorToast,
  displayWarningToast,
  formatPhoneNumber,
  generateBookingId,
  generateRandomId,
} from "../../services/helpers";
import { displaySuccessToast } from "../../services/helpers";
import { Timestamp } from "firebase/firestore";
import { usePromoCodes } from "../../store/promocode_store";
import { userStore } from "./../../store/user_store";
import { useSettings } from "../../store/setting_store";
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchableDropdown from "../searchable_dropdown/searchable_dropdown";
import { useEvents } from "../../store/event_store";
import { ordersStore } from "../../store/orders_store";
import AirtelMTN from "./optionpics/airtelMtn.webp";
import FlexiPay from "./optionpics/flexipay.png";
import usePaymentMethodsStore from "../../store/payment_store";
import { tableStore } from "../../store/table_store";

export default function FlexPaymentLoggedIn({
  amount,
  orderTickets,
  events,
  promoDetails,
  cartItems,
}) {
  //handling validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9,9}$/, "valid phone number required")
      .test("valid-prefix", "MTN or Airtel phone number", (value) => {
        // Ensure phoneNumber is a string
        const phoneNumberString = String(value);

        // Check the prefix to determine payment type
        if (
          phoneNumberString.startsWith("75") ||
          phoneNumberString.startsWith("74") ||
          phoneNumberString.startsWith("70") ||
          phoneNumberString.startsWith("77") ||
          phoneNumberString.startsWith("78") ||
          phoneNumberString.startsWith("76")
        ) {
          return true;
        }
        return false;
      })
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const clearCart = userStore((state) => state.clearCart);
  const user = userStore((state) => state.user);
  const layout_page = useSettings((state) => state.layout_page);
  const getCountriesList = useEvents((state) => state.getCountriesLists);
  const addTransaction = ordersStore((state) => state.addTransaction);
  const getAuthToken = ordersStore((state) => state.getAuthToken);
  const makePayment = ordersStore((state) => state.makePayment);
  const checkPaymentStatus = ordersStore((state) => state.checkPaymentStatus);
  const updateTransactionStatus = ordersStore(
    (state) => state.updateTransactionStatus
  );
  const payOrganizer = usePaymentMethodsStore((state) => state.payOrganizer);

  const fetchTranscations = ordersStore((state) => state.fetchTranscations);
  const editRoom = tableStore((state) => state.updateRoomInDatabase);
  const editTableLable = tableStore((state) => state.editTableLable);
  const rooms = tableStore((state) => state.rooms);

  const room = rooms.find(
    (room) =>
      room?.venue.value &&
      cartItems.map((item) => item.venue_id).includes(room.venue.value)
  );
  // //or
  // const room = rooms.find(
  //   (room) =>
  //     room?.venue.value &&
  //     cartItems.some((item) => item.venue_id === room.venue.value)
  // );

  console.log("the room is:", room);

  const tables = tableStore((state) =>
    state.tableLabels.filter((tableLabel) => tableLabel.room_id === room?.id)
  );
  console.log(" the tables are:", tables);

  useEffect(() => {
    const fetchingTransaction = async () => {
      await fetchTranscations();
    };

    fetchingTransaction();
  }, []);

  const [setCity] = useState("");
  const [setStreetAddress] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState("");

  // GETTING THE LIST OF COUNTRIES
  useEffect(() => {
    const get_countries_list = async () => {
      const returnedCountries = await getCountriesList();
      const countries = [];

      for (let i = 0; i < returnedCountries?.length; i++) {
        countries.push({
          value: returnedCountries[i].countryCode,
          label: returnedCountries[i].name,
        });
      }
      setCountriesList(countries);
    };
    get_countries_list();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: user?.firstname,
      last_name: user?.lastname,
      email: user?.email,
      phoneNumber: formatPhoneNumber(user?.phone_number),
      paymentMethod: "airtel_mtn",
    },
    validationSchema,
    onSubmit: handlePayment,
  });

  // METHOD TO HANDLE MAKING PAYMENT AND ORDER
  async function handlePayment() {
    // GENERATING THE TRANSACTION ID
    let selectedPaymentMethod = "Airtel or MTN";
    // a method to get the selected payment method.
    if (values.paymentMethod === "airtel_mtn") {
      selectedPaymentMethod = "Airtel or MTN";
    } else if (values.paymentMethod === "flexiPay") {
      selectedPaymentMethod = "FlexiPay";
    } else {
      console.log("Invalid payment method");
      displayWarningToast("Please choose a payment method");
      return;
    }

    const transactionId = generateRandomId();
    const orderId = generateBookingId();
    const paymentType = determinePaymentType(
      values.phoneNumber,
      selectedPaymentMethod
    );

    // TRANSACTION DATA
    const transactionData = {
      transaction_id: transactionId,
      amount: amount,
      currency: "UGX",
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      payment_type: paymentType,
      transaction_status: "pending",
      transaction_type: "Ticket Payment",
      customer_number: `256${values.phoneNumber}`,
      email: `${values.email}`,
      customer_name: `${values.first_name} ${values.last_name}`,
      customer_id: user?.user_id,
      tickets: orderTickets,
      platform: "web",
      promocode: promoDetails,
      order_id: orderId,
    };

    // ADDING THE TRANSACTION TO THE DATABASE
    const transactionResponse = await addTransaction(transactionData);

    if (!transactionResponse) {
      displayErrorToast("Transaction Error Adding Transaction");
      return;
    }

    const authToken = await getAuthToken();
    const requestId = generateRandomId();
    const phoneNumber = `256${values.phoneNumber}`;
    const data = await makePayment(
      authToken,
      requestId,
      amount,
      selectedPaymentMethod,
      phoneNumber
    );
    console.log("the data after making payments is:", data);

    let transaction_status = "pending";
    let loopCount = 40;
    if (selectedPaymentMethod === "Airtel or MTN") {
      loopCount = 40; // if airtel_mtn is selected then loopCount the value
    } else if (selectedPaymentMethod === "flexiPay") {
      loopCount = 60;
    }

    for (let i = 0; i < loopCount; i++) {
      const status = await checkPaymentStatus(authToken, requestId);
      if (status.statusCode === "00") {
        // Payment successful
        transaction_status = "successful";

        await updateTransactionStatus(transactionId, {
          transaction_status: transaction_status,
        });

        //if tables are there in order update is booked to true
        if (room) {
          room?.tables.forEach((table) => {
            const matchingTable = cartItems
              .flatMap((item) => item.selectedTables)
              .find((selectedTable) => selectedTable.id === table.id);
            if (matchingTable) {
              editRoom(room?.id, {
                ...room,
                tables: room.tables.map((t) =>
                  t.id === matchingTable.id ? { ...t, isBooked: true } : t
                ),
              });
              //editing also tables
              editTableLable(matchingTable.id, { isBooked: true });
            }
          });
        }

        // calling method to pay the organizers
        events.forEach((event) => {
          const eventId = event.id;
          const organizerId = event.organizer_id;
          payOrganizer({
            transactionId: transactionId,
            amount: amount,
            eventId: eventId,
            organizerId: organizerId,
          });
        });

        displaySuccessToast("The transaction is successful");

        break;
      } else if (status.statusCode === "01") {
        transaction_status = "pending";
      } else {
        //payment failed
        transaction_status = "failed";
        displayErrorToast("The transaction has failed");
        await updateTransactionStatus(transactionId, {
          transaction_status: transaction_status,
        });
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 4000));
    }
  }

  // RETURNING THE PAYMENT BUTTON COMPONENT
  return (
    <form name="checkout" onSubmit={handleSubmit}>
      {/* BILLING INFORMATION */}
      <div className="card mt-4">
        <div className="card-header">
          <h6 className="b mb-0">Billing information</h6>
        </div>
        <div className="card-body">
          <div className="form-row">
            {/* FIRST NAME */}
            <div className="col form-group">
              <label htmlFor="first_name" className="required">
                First name
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className={
                  errors.first_name && touched.first_name
                    ? "input-error form-control"
                    : "form-control"
                }
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.first_name && touched.first_name && (
                <p className="error">{errors.first_name}</p>
              )}
            </div>

            {/* LAST NAME */}
            <div className="col form-group">
              <label htmlFor="checkout_lastname" className="required">
                Last name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                className={
                  errors.last_name && touched.last_name
                    ? "input-error form-control"
                    : "form-control"
                }
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name && (
                <p className="error">{errors.last_name}</p>
              )}
            </div>
          </div>

          {/* USER EMAIL */}
          <div className="form-row">
            <div className="col form-group">
              <label htmlFor="checkout_email" className="required">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={
                  errors.email && touched.email
                    ? "input-error form-control"
                    : "form-control"
                }
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>

            {/* PHONE NUMBER */}
            <div className="col form-group">
              <label htmlFor="phoneNumber" className="required">
                Phone Number
              </label>
              <div className=" input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ borderRadius: "5px" }}
                  >
                    +256
                  </span>
                </div>
                <input
                  type="number"
                  name="phoneNumber"
                  className={
                    errors.phoneNumber && touched.phoneNumber
                      ? "input-error form-control"
                      : "form-control"
                  }
                  placeholder="+2567XXXXXXXX"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              {errors.phoneNumber && touched.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            {/* COUNTRY */}
            <div className="col form-group">
              <label htmlFor="checkout_country">Country</label>

              <SearchableDropdown
                value={country}
                options={countriesList}
                onChange={(e) => setCountry(e)}
                isMulti={false}
                defaultValue={{ value: "256", label: "Uganda" }}
                placeholder={"Select Country"}
              />
            </div>

            {/* CITY */}
            <div className="col form-group">
              <label htmlFor="checkout_state">City</label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                className="form-control"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col form-group">
              <label htmlFor="street_address">Street Address</label>
              <input
                type="text"
                name="street_address"
                className="form-control"
                onChange={(e) => setStreetAddress(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-header">
          <h6 className="b mb-0 text-center font-weight-bold">
            Please Choose a Payment method
          </h6>
        </div>
        <div className="card-body">
          <div className=" row align-items-center mb-5">
            <div className=" col"></div>

            <div className="col d-flex flex-column align-items-center h-100">
              <div className=" mb-2">
                <label class="form-check-label" for="airtel_mtn">
                  <img
                    src={AirtelMTN}
                    alt="AirtelMTN"
                    style={{ borderRadius: 10, width: 100 }}
                  />
                </label>
              </div>
              <div className=" ml-3">
                <input
                  className="form-check-input"
                  id="airtel_mtn"
                  type="radio"
                  name="paymentMethod"
                  value="airtel_mtn" // Set the value to "option1"
                  checked={values.paymentMethod === "airtel_mtn"} // Option 1 is checked by default
                  onChange={handleChange} // Handle changes to update the state
                />
              </div>
            </div>

            <div className="col d-flex flex-column align-items-center h-100">
              <div className=" mb-2">
                <label class="form-check-label" for="flexiPay">
                  <img src={FlexiPay} alt="FlexiPay" style={{ width: 100 }} />
                </label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  id="flexiPay"
                  type="radio"
                  name="paymentMethod"
                  value="flexiPay" // Set the value to "option2"
                  checked={values.paymentMethod === "flexiPay"}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col"></div>
          </div>
          <div
            id="paymentGateway18-instructions"
            className="form-row paymentGateways-instructions"
            style={{ display: "none" }}
          ></div>
        </div>
        {/* PAY NOW BUTTON */}
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn text-white btn-block mt-3 mt-md-0"
          style={{
            backgroundColor: layout_page?.primary_color,
          }}
        >
          {isSubmitting ? (
            <div class="spinner-border spinner-border-sm" role="status"></div>
          ) : (
            <>
              <i className="fas fa-money-check pr-2" /> Pay Now
            </>
          )}
        </button>
      </div>
      {isSubmitting && (
        <>
          {values.paymentMethod === "airtel_mtn" ? (
            <div className="alert alert-info mt-3" role="alert">
              Check your phone and enter your pin to confirm payment
            </div>
          ) : (
            <div className="alert alert-info mt-3" role="alert">
              Open your FlexiPay App and enter your details to complete the
              payment
            </div>
          )}
        </>
      )}
    </form>
  );
}
