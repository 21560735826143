import React, { useEffect, useState } from "react";
import { useEvents } from "../../../store/event_store";

const LanguageCount = ({ language_id }) => {
  const events = useEvents((state) => state.events);
  const [count, setCount] = useState(0); // Initialize count with 0

  useEffect(() => {
    const fetchCount = () => {
      if (language_id) {
        const c = events.filter(
          (event) =>
            event?.languages?.some(
              (language) => language?.value === language_id
            ) //use some or find to check if at least one element satisfies the condition since language is in form of array []
        );
        setCount(c.length); // Set the count based on the filtered array length
      } else {
        setCount(0); // Reset the count when language_id is not provided
      }
    };
    fetchCount();
  }, [events.length, language_id]); // Provide events and language_id as dependencies

  return <div>{count}</div>;
};

export default LanguageCount;
