///////////////////ORGANIZEROPTIONS////////////////
export const organizer_sidebar_options = [
  {
    name: "Dashboard",
    link: "dashboard_organizer",
    icon: "fas fa-tachometer-alt fa-fw",
  },
  {
    name: "My organizer profile",
    link: "organizer_profile",
    icon: "far fa-id-card fa-fw",
  },
  {
    name: "My Events",
    link: "myevents",
    icon: "fas fa-calendar fa-fw",
  },
  {
    name: "Promo Codes",
    link: "my_promocodes",
    icon: "fas fa-ticket-alt",
  },
  {
    name: "My Venues",
    link: "myvenues",
    icon: "fas fa-map-marker-alt fa-fw",
  },
  {
    name: "My Scanners",
    link: "my_scanners",
    icon: "fas far fa-qrcode fa-fw",
  },
  {
    name: "Reviews",
    link: "my_reviews",
    icon: "fas fa-star fa-fw",
  },
];

export const payout_options = [
  {
    name: "Payout requests",
    link: "payoutrequests",
  },
  {
    name: "Payout methods",
    link: "payoutmethods",
  },
];

export const organizer_sidebar_options2 = [
  {
    name: "Reports",
    link: "my_reports",
    icon: "fas fa-funnel-dollar fa-fw",
  },
  {
    name: "Change Password",
    link: "change_password",
    icon: "fas fa-user-cog fa-fw",
  },
];

////////////////////////ORGANIZER OPTIONS////////////////

////////////////////////ADMIN OPTIONS////////////////
export const admin_sidebar_options1 = [
  {
    name: "Dashboard",
    link: "admin_dashboard",
    icon: "fas fa-tachometer-alt fa-fw",
  },
];

export const admin_sidebar_options2 = [
  {
    name: "Orders",
    link: "manage_orders",
    icon: "fas fa-cart-arrow-down fa-fw",
  },
  {
    name: "Payout requests",
    link: "payout_requests",
    icon: "fas fa-file-invoice-dollar fa-fw",
  },
  {
    name: "Reports",
    link: "manage_reports",
    icon: "fas fa-funnel-dollar fa-fw",
  },
  {
    name: "Users",
    link: "manage_users",
    icon: "fas fa-users-cog fa-fw",
  },

  {
    name: "Mutual Friends",
    link: "mutual_friends",
    icon: "fas fa-users-cog fa-fw",
  },
  {
    name: "Table Bookings Management",
    link: "manage_tables_bookings",
    icon: "fas fa-couch fa-fw",
  },
];

export const admin_sidebar_options3 = [
  {
    name: "Pages",
    link: "manage_pages",
    icon: "far fa-file-alt fa-fw",
  },
  {
    name: "Reviews",
    link: "manage_reviews",
    icon: "fas fa-star fa-fw",
  },
];
export const admin_sidebar_options4 = [
  {
    name: "Change Password",
    link: "change_password",
    icon: "fas fa-user-cog fa-fw",
  },
];

//settings options
export const settings_options = [
  {
    name: "Layout, parameters and SEO",
    link: "manage_layout",
  },
  {
    name: "HomePage",
    link: "manage_homepage",
  },
  {
    name: "Payment and Fees",
    link: "manage_payments",
  },
  {
    name: "Checkout",
    link: "manage_checkout",
  },
  {
    name: "Google Recaptcha ",
    link: "google_recaptcha",
  },
  {
    name: "Google Maps",
    link: "google_maps",
  },
  {
    name: "Social Login",
    link: "social_login",
  },
  {
    name: "Event list page",
    link: "events_list",
  },
  {
    name: "Venue Page",
    link: "venue_settings",
  },
  {
    name: " Blog Settings ",
    link: "blog_settings",
  },
  {
    name: "Newsletter",
    link: "manage_newsletter",
  },
];
//events options
export const events_options = [
  {
    name: "Manage Events",
    link: "manage_events",
  },
  {
    name: "Manage Event Ideas",
    link: "manage_event_ideas",
  },
  {
    name: "Promocodes",
    link: "promocodes",
  },
  {
    name: "Categories",
    link: "manage_event_categories",
  },
  {
    name: "Countries",
    link: "manage_countries",
  },
  {
    name: "Languages",
    link: "manage_languages",
  },
  {
    name: "Audiences",
    link: "manage_audiences",
  },
];

//events options
export const venues_options = [
  {
    name: "Manage Venues",
    link: "manage_venues",
  },
  {
    name: "Venue Types",
    link: "manage_venue_types",
  },
  {
    name: "Amenities",
    link: "manage_amenities",
  },
];

//blogs options
export const blogs_options = [
  {
    name: "Posts",
    link: "manage_blog_posts",
  },
  {
    name: "Categories",
    link: "manage_blogpost_categories",
  },
];
//helper center options
export const articles_options = [
  {
    name: "Articles",
    link: "manage_articles",
  },
  {
    name: "Categories",
    link: "manage_user_categories",
  },
];

////////////////////////ADMIN OPTIONS////////////////
////////////////////////ATTENDEE OPTIONS////////////////
export const attendee_sidebar_options1 = [
  {
    name: "My tickets",
    link: "dashboard",
    icon: "fas fa-ticket-alt fa-fw",
  },
];

export const attendee_sidebar_options2 = [
  {
    name: "My favorites",
    link: "favorites",
    icon: "far fa-heart fa-fw",
  },
  {
    name: "My reviews",
    link: "reviews",
    icon: "fas fa-users-cog fa-fw",
  },
  {
    name: "Following",
    link: "following",
    icon: "far fa-folder-open fa-fw",
  },

  {
    name: "Change Password",
    link: "change_password",
    icon: "fas fa-user-cog fa-fw",
  },
];
////////////////////////ATTENDEE OPTIONS////////////////
