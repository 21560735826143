import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    fontSize: 14,
    color: '#1B1B1E',
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f2f3f7',
    color: '#7e899b',
    border: 'none',
    fontSize: 13,
    padding: '13px 24px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Open Sans"'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default function _Select({
  data,
  label,
  onChange,
  value,
  defaultValue,
}) {
  return (
    <FormControl sx={{ m: 1, width: '50%' }} variant="standard">
      <InputLabel
        shrink
        sx={{ fontSize: '20px', color: '#1b1b1e', marginBottom: '12px' }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        input={<BootstrapInput defaultValue={defaultValue} />}
      >
        {data.map((item) => (
          <MenuItem
            value={item.value}
            sx={{
              fontSize: '13px',
              padding: '13px 24px',
              color: '#7e898b',
              bgcolor: '#f2f3f7',
              borderRadius: '4px',
              border: 'none',
              fontWeight: 400,
              cursor: 'pointer',
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
