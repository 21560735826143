import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Sidenavbar from "./components/sidenavbar";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Payoutrequests() {
  const isOrganizer = userStore((state) => state.isOrganizer);
  const layout_page = useSettings((state) => state.layout_page);

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    return <Navigate to="/access_denied" />;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"Payout requests"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"payoutrequests"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="alert alert-info alert-icon">
                  <i className="fas fa-info-circle" />
                  To submit a new payout request for an event date, go to the My
                  events page and click on the Request payout link next to the
                  event
                </div>

                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0 d-flex justify-content-start align-content-center">
                      <span className="center-lg-y text-muted">
                        0 result(s) found
                      </span>
                    </div>

                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                        >
                          <option
                            data-direction="desc"
                            data-criteria="p.createdAt"
                            value="/en/dashboard/organizer/my-payout-requests?sort=p.createdAt&direction=desc&page=1"
                          >
                            Creation date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="p.createdAt"
                            value="/en/dashboard/organizer/my-payout-requests?sort=p.createdAt&direction=asc&page=1"
                          >
                            Creation date (asc)
                          </option>
                          <option
                            data-direction="desc"
                            data-criteria="p.updatedAt"
                            value="/en/dashboard/organizer/my-payout-requests?sort=p.updatedAt&direction=desc&page=1"
                          >
                            Processing date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="p.updatedAt"
                            value="/en/dashboard/organizer/my-payout-requests?sort=p.updatedAt&direction=asc&page=1"
                          >
                            Processing date (asc)
                          </option>
                        </select>
                      </label>
                    </div>

                    <div className="col-sm-12 col-lg-4 text-center text-lg-right d-flex justify-content-end align-content-center">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>

                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">Reference</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white"
                              id="reference"
                              name="reference"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="eventdate">
                              Event date{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="Select a specific event date"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="eventdate"
                              name="eventdate"
                              data-url-list="/en/api/event/all/get-event-dates"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="datefrom">From date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="datefrom"
                              name="datefrom"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="dateto">Until date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="dateto"
                              name="dateto"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-all"
                                type="radio"
                                name="status"
                                defaultValue="all"
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-paid"
                                type="radio"
                                name="status"
                                defaultValue={1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-paid"
                                className="custom-control-label"
                              >
                                Approved
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-waiting-for-payment"
                                type="radio"
                                name="status"
                                defaultValue={0}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-waiting-for-payment"
                                className="custom-control-label"
                              >
                                Pending
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-canceled"
                                type="radio"
                                name="status"
                                defaultValue={-1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-canceled"
                                className="custom-control-label"
                              >
                                Canceled
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-failed"
                                type="radio"
                                name="status"
                                defaultValue={-2}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-failed"
                                className="custom-control-label"
                              >
                                Failed
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <button
                              type="submit"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Search"
                            >
                              <i className="fas fa-search" />
                            </button>
                            <button
                              type="reset"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Reset"
                            >
                              <i className="fas fa-search-minus" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="alert alert-info alert-icon">
                        <i className="fas fa-exclamation-circle" /> No payout
                        requests found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
