import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import Loader from "../../components/loader/loader";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import PopingOutOrders from "./PopingOutOrders";
import { FaUserCircle } from "react-icons/fa";
import { userStore } from "../../store/user_store";
import { ordersStore } from "../../store/orders_store";
import CustomPagination from "../../components/pagination/Pagination";
import OrganizerName from "../attendee_dashboard/OrganizerName";
import { useSettings } from "../../store/setting_store";
import BreadcrumpAdmin from "./components/breadcrump_admin";

export default function ManageEventOrders() {
  const isAdmin = userStore((state) => state.isAdmin);
  const layout_page = useSettings((state) => state.layout_page);
  const getOrdersOfSpecificEvent = ordersStore(
    (state) => state.getOrdersOfSpecificEvent
  );
  const [event_orders, setEventOrders] = useState([]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { event_id } = useParams();

  //fetching orders from the database
  useEffect(() => {
    const getSpecificOrders = async () => {
      const getEventOrders = await getOrdersOfSpecificEvent(event_id);
      setEventOrders(getEventOrders);
    };
    getSpecificOrders();
  }, [event_orders.length]);

  // PAGINATION STATUS
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(50);
  //get current orders
  const indexOfLastorders = currentPage * ordersPerPage;
  const indexOfFastorders = indexOfLastorders - ordersPerPage;
  const current_orders = event_orders?.slice(
    indexOfFastorders,
    indexOfLastorders
  );

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Orders" />

        {/* MANAGE ORDERS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_orders"} />

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  {/* NUMBER OF RESULTS */}
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {event_orders.length} order(s) found
                      </span>
                    </div>

                    {/* SORT */}
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                        >
                          <option
                            data-direction="desc"
                            data-criteria="o.createdAt"
                            value="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&sort=o.createdAt&direction=desc&page=1"
                          >
                            Creation date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="o.createdAt"
                            value="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&sort=o.createdAt&direction=asc&page=1"
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                    </div>

                    {/* EXPORT BUTTONS */}
                    <div className="col-sm-12 col-lg-4 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&excel=1"
                        target="_blank"
                        className="btn text-white"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Export current orders to an Excel file"
                      >
                        <i className="fas fa-file-excel fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&csv=1"
                        target="_blank"
                        className="btn text-white"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Export current orders to a CSV file"
                      >
                        <i className="fas fa-file-csv fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&pdf=1"
                        target="_blank"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        title=" Export attendees list to a PDF file "
                      >
                        <i className="fas fa-file-pdf fa-lg" />
                      </a>
                    </div>

                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        {/* SDEARCH FILTERS */}
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">Reference</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white"
                              id="reference"
                              name="reference"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="event">Event </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="event"
                              name="event"
                              data-url-list="/en/api/get-events?published=all&elapsed=all"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="eventdate">
                              Event date{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="Select a specific event date"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="eventdate"
                              name="eventdate"
                              data-url-list="/en/api/event/camping-trip/get-event-dates"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="organizer">
                              Organizer{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="All orders for an organizer"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="organizer"
                              name="organizer"
                              data-url-list="/en/api/get-organizers"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="datefrom">From date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="datefrom"
                              name="datefrom"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="dateto">Until date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="dateto"
                              name="dateto"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-all"
                                type="radio"
                                name="status"
                                defaultValue="all"
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-paid"
                                type="radio"
                                name="status"
                                defaultValue={1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-paid"
                                className="custom-control-label"
                              >
                                Paid
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-waiting-for-payment"
                                type="radio"
                                name="status"
                                defaultValue={0}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-waiting-for-payment"
                                className="custom-control-label"
                              >
                                Awaiting payment
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-canceled"
                                type="radio"
                                name="status"
                                defaultValue={-1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-canceled"
                                className="custom-control-label"
                              >
                                Canceled
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-failed"
                                type="radio"
                                name="status"
                                defaultValue={-2}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-failed"
                                className="custom-control-label"
                              >
                                Failed
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <button
                              type="submit"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Search"
                            >
                              <i className="fas fa-search" />
                            </button>
                            <button
                              type="reset"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Reset"
                            >
                              <i className="fas fa-search-minus" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* DATA AREA */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <div
                          className="modal fade"
                          id="order-7c517c4d4c47a06-payment-details"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">
                                  Order payment details - 7c517c4d4c47a06
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                >
                                  ×
                                </button>
                              </div>
                              <div className="modal-body">
                                <p className="mb-3 text-center">
                                  <img
                                    src="/uploads/payment/gateways/6389f53349c3b758539451.png"
                                    className="img-80-80"
                                  />
                                </p>
                                []
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* order pament details model */}
                        <div
                          className="modal fade"
                          id="order-163adcee4e6a876-payment-details"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">
                                  Order payment details - 163adcee4e6a876
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                >
                                  ×
                                </button>
                              </div>
                              <div className="modal-body">
                                <p className="mb-3 text-center">
                                  <img
                                    src="/uploads/payment/gateways/5e96df683fa91912933591.png"
                                    className="img-80-80"
                                  />
                                </p>
                                {"{"}
                                <br />
                                "amount": 11100,
                                <br />
                                "currency": "usd",
                                <br />
                                "description": "Payment of tickets purchased on
                                Eventic",
                                <br />
                                "card": "tok_1MRRAABEpCJMd4w8yfUvlKnm",
                                <br />
                                "id": "ch_3MRRADBEpCJMd4w81zM1JbHJ",
                                <br />
                                "object": "charge",
                                <br />
                                "amount_captured": 11100,
                                <br />
                                "amount_refunded": 0,
                                <br />
                                "application": null,
                                <br />
                                "application_fee": null,
                                <br />
                                "application_fee_amount": null,
                                <br />
                                "balance_transaction":
                                "txn_3MRRADBEpCJMd4w81fuVC5zS",
                                <br />
                                "billing_details": {"{"}
                                <br />
                                "address": {"{"}
                                <br />
                                "city": null,
                                <br />
                                "country": null,
                                <br />
                                "line1": null,
                                <br />
                                "line2": null,
                                <br />
                                "postal_code": null,
                                <br />
                                "state": null
                                <br />
                                {"}"},<br />
                                "email": null,
                                <br />
                                "name": "abc@gmail.com",
                                <br />
                                "phone": null
                                <br />
                                {"}"},<br />
                                "calculated_statement_descriptor": "Stripe",
                                <br />
                                "captured": true,
                                <br />
                                "created": 1674008049,
                                <br />
                                "customer": null,
                                <br />
                                "destination": null,
                                <br />
                                "dispute": null,
                                <br />
                                "disputed": false,
                                <br />
                                "failure_balance_transaction": null,
                                <br />
                                "failure_code": null,
                                <br />
                                "failure_message": null,
                                <br />
                                "fraud_details": [],
                                <br />
                                "invoice": null,
                                <br />
                                "livemode": false,
                                <br />
                                "metadata": [],
                                <br />
                                "on_behalf_of": null,
                                <br />
                                "order": null,
                                <br />
                                "outcome": {"{"}
                                <br />
                                "network_status": "approved_by_network",
                                <br />
                                "reason": null,
                                <br />
                                "risk_level": "normal",
                                <br />
                                "risk_score": 48,
                                <br />
                                "seller_message": "Payment complete.",
                                <br />
                                "type": "authorized"
                                <br />
                                {"}"},<br />
                                "paid": true,
                                <br />
                                "payment_intent": null,
                                <br />
                                "payment_method":
                                "card_1MRRAABEpCJMd4w8uaxWvPbG",
                                <br />
                                "payment_method_details": {"{"}
                                <br />
                                "card": {"{"}
                                <br />
                                "brand": "visa",
                                <br />
                                "checks": {"{"}
                                <br />
                                "address_line1_check": null,
                                <br />
                                "address_postal_code_check": null,
                                <br />
                                "cvc_check": "pass"
                                <br />
                                {"}"},<br />
                                "country": "US",
                                <br />
                                "exp_month": 4,
                                <br />
                                "exp_year": 2024,
                                <br />
                                "fingerprint": "SLzpidxfhmGyDb08",
                                <br />
                                "funding": "credit",
                                <br />
                                "installments": null,
                                <br />
                                "last4": "4242",
                                <br />
                                "mandate": null,
                                <br />
                                "network": "visa",
                                <br />
                                "three_d_secure": null,
                                <br />
                                "wallet": null
                                <br />
                                {"}"},<br />
                                "type": "card"
                                <br />
                                {"}"},<br />
                                "receipt_email": null,
                                <br />
                                "receipt_number": null,
                                <br />
                                "receipt_url":
                                "https:\/\/pay.stripe.com\/receipts\/payment\/CAcaFwoVYWNjdF8xR1ZldVdCRXBDSk1kNHc4KPKrnZ4GMgah30ZM8iU6LBZYbM-bp7HEl8AyTz6MCegObewSfIOPOwrsJvCXO-uMiJTSOTiK9GyuJULV",
                                <br />
                                "refunded": false,
                                <br />
                                "refunds": {"{"}
                                <br />
                                "object": "list",
                                <br />
                                "data": [],
                                <br />
                                "has_more": false,
                                <br />
                                "total_count": 0,
                                <br />
                                "url":
                                "\/v1\/charges\/ch_3MRRADBEpCJMd4w81zM1JbHJ\/refunds"
                                <br />
                                {"}"},<br />
                                "review": null,
                                <br />
                                "shipping": null,
                                <br />
                                "source": {"{"}
                                <br />
                                "id": "card_1MRRAABEpCJMd4w8uaxWvPbG",
                                <br />
                                "object": "card",
                                <br />
                                "address_city": null,
                                <br />
                                "address_country": null,
                                <br />
                                "address_line1": null,
                                <br />
                                "address_line1_check": null,
                                <br />
                                "address_line2": null,
                                <br />
                                "address_state": null,
                                <br />
                                "address_zip": null,
                                <br />
                                "address_zip_check": null,
                                <br />
                                "brand": "Visa",
                                <br />
                                "country": "US",
                                <br />
                                "customer": null,
                                <br />
                                "cvc_check": "pass",
                                <br />
                                "dynamic_last4": null,
                                <br />
                                "exp_month": 4,
                                <br />
                                "exp_year": 2024,
                                <br />
                                "fingerprint": "SLzpidxfhmGyDb08",
                                <br />
                                "funding": "credit",
                                <br />
                                "last4": "4242",
                                <br />
                                "metadata": [],
                                <br />
                                "name": "abc@gmail.com",
                                <br />
                                "tokenization_method": null
                                <br />
                                {"}"},<br />
                                "source_transfer": null,
                                <br />
                                "statement_descriptor": null,
                                <br />
                                "statement_descriptor_suffix": null,
                                <br />
                                "status": "succeeded",
                                <br />
                                "transfer_data": null,
                                <br />
                                "transfer_group": null
                                <br />
                                {"}"}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table className="table table-hover table-vcenter">
                          <thead>
                            <tr>
                              <th className="text-center">Reference</th>
                              <th>Organizer / Event / Date / Ticket</th>
                              <th>Attendee / POS</th>
                              <th>Order date</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          {/* MAPPING OF THE ORDERS RECIEVED */}
                          <tbody>
                            {current_orders.map((order, index) => {
                              const groupedTickets =
                                order?.order_tickets.reduce((acc, ticket) => {
                                  if (!acc[ticket.event_id]) {
                                    acc[ticket.event_id] = [];
                                  }
                                  acc[ticket.event_id].push(ticket);
                                  return acc;
                                }, {});

                              return (
                                <tr key={order?.id}>
                                  <td className="text-center text-muted text-sm">
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={
                                        "/ticket_details/" + order?.booking_id
                                      }
                                    >
                                      {order?.booking_id}
                                    </Link>
                                  </td>

                                  <td className="text-sm">
                                    <ul>
                                      {Object.values(groupedTickets).map(
                                        (tickets, eventIndex) => (
                                          <li key={eventIndex}>
                                            <OrganizerName
                                              event_id={tickets[0]?.event_id}
                                            />
                                            <i className="fas fa-chevron-right fa-sm mx-1" />
                                            <Link
                                              to={
                                                "/event_details/" +
                                                tickets[0]?.event_id
                                              }
                                              style={{
                                                color:
                                                  layout_page?.primary_color,
                                              }}
                                            >
                                              {tickets[0]?.event_name}
                                            </Link>
                                            <i className="fas fa-chevron-right fa-sm mx-1" />
                                            {formatDate(
                                              convertTimestampToDateTime(
                                                tickets[0]?.event_start_date
                                              )
                                            )}
                                            <i className="fas fa-chevron-right fa-sm mx-1" />
                                            {tickets[0]?.ticket_name} ticket
                                            <br />
                                            <small>
                                              <strong>
                                                {tickets.length}{" "}
                                                {tickets.length > 1
                                                  ? "tickets"
                                                  : "ticket"}
                                              </strong>
                                            </small>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </td>

                                  <td className="text-sm">
                                    <span className="ajax-loading">
                                      <FaUserCircle size={30} color="gray" />
                                      {order?.customer_name}
                                    </span>
                                  </td>

                                  <td className="text-sm">
                                    {formatDate(
                                      convertTimestampToDateTime(
                                        order?.created_at
                                      )
                                    )}
                                  </td>

                                  <td className="text-sm">
                                    <span className="badge badge-success">
                                      {order?.order_tickets[index]?.status}
                                    </span>
                                  </td>

                                  <td className="text-center">
                                    <PopingOutOrders
                                      setIsLoading={setIsLoading}
                                      order_id={order?.id}
                                      booking_id={order?.booking_id}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {event_orders?.length > 50 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          event_orders?.length / ordersPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
