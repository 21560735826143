import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import TextEditor from "../../../components/text_editor/text_editor";
import TagsInputField from "../../../components/tags_input_field/tags_input";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditHelperArticle() {
  const updateArticle = useBlogs((state) => state.updateArticle);
  const layout_page = useSettings((state) => state.layout_page);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { article_id } = useParams();
  const { document } = useFetchDocument("articles", article_id);
  const [articleForm, setArticleForm] = useState(document || {});

  useEffect(() => {
    setArticleForm(document);
  }, [document]);

  //HANDLE EDITING ARTICLE
  const handleEditArticle = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateArticle(article_id, {
          title: articleForm?.title,
          content: articleForm?.content,
          userCategory: articleForm?.userCategory,
          tags: articleForm?.tags,
          updated_at: Timestamp.fromDate(new Date()),
        });
        navigate("/manage_articles");
        displaySuccessToast("Update succeeded");
      } catch (error) {
        console.log("error in updating the article:", error);
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  //handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUserCategoryChange = (e) => {
    const { value } = e.target;
    setArticleForm((prevFormData) => ({
      ...prevFormData,
      userCategory: value,
    }));
  };

  //HANDLE DESCRIPTION
  const handleDescriptionChange = (value) => {
    setArticleForm((prevFormData) => ({
      ...prevFormData,
      content: value,
    }));
  };
  // HANDLE CHANGE OF TAGS
  const handleChange = (tags) => {
    setArticleForm((prevFormData) => ({
      ...prevFormData,
      tags,
    }));
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING TITLE
    if (!articleForm?.title) {
      errors.title = "Title is required";
    }

    // VALIDATING content
    if (!articleForm?.content) {
      errors.content = "Content is required";
    }

    // VALIDATING User Category
    if (!articleForm?.userCategory) {
      errors.userCategory = "User Category is required";
    }
    return errors;
  }

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit help center article"
          page_link="Manage help center articles"
          link="manage_articles"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_articles"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="help_center_article"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleEditArticle}
                    >
                      <div id="help_center_article">
                        {/* USER CATEGORY */}
                        <div className="form-group">
                          <label
                            className="required"
                            htmlFor="help_center_article_category"
                          >
                            Category
                          </label>
                          <small
                            id="help_center_article_category_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Make sure to select right category to let the users
                            find it quickly
                          </small>

                          {errors.userCategory && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.userCategory}
                            </span>
                          )}

                          <select
                            value={articleForm?.userCategory}
                            onChange={(e) => handleUserCategoryChange(e)}
                            name="userCategory"
                            className="form-control"
                            aria-describedby="help_center_article_category_help"
                          >
                            <option value />
                            <option disabled>Select one</option>
                            <option value="Attendee">Attendee</option>
                            <option value="Organizer">Organizer</option>
                          </select>
                        </div>

                        {/* ARTICLE DETAILS */}

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Article details
                          </legend>

                          <div
                            id="help_center_article_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="help_center_article_translations_en">
                              {/* TITLE */}
                              <div className="form-group">
                                <label
                                  htmlFor="help_center_article_translations_en_title"
                                  className="required"
                                >
                                  Title
                                </label>

                                {errors.title && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.title}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="help_center_article_translations_en_title"
                                  name="title"
                                  required="required"
                                  maxLength={80}
                                  className={
                                    errors.title
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                  value={articleForm?.title}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              </div>

                              {/* ARTICLE CONTENT */}
                              <div className="form-group">
                                <label
                                  htmlFor="help_center_article_translations_en_content"
                                  className="required"
                                >
                                  Content
                                </label>

                                {errors.content && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.content}
                                  </span>
                                )}
                                <TextEditor
                                  value={articleForm?.content || ""}
                                  onChange={handleDescriptionChange}
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="help_center_article_translations_en_tags"
                                  className="required"
                                >
                                  Tags
                                </label>

                                {errors.tags && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.tags}
                                  </span>
                                )}
                                <TagsInputField
                                  tags={
                                    Array.isArray(articleForm?.tags)
                                      ? articleForm?.tags
                                      : [articleForm?.tags]
                                  }
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            name="help_center_article[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
