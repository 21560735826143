import DeleteButton, { AddButton, ProceedButton } from "../../Components";
import "./../../RoomDetails.scss";
import { tableStore } from "../../../../../store/table_store";
import { useSettings } from "../../../../../store/setting_store";
import AddTableModal from "./AddTableModal";
import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import { MdAdd } from "react-icons/md";
import { FaMinusSquare, FaPlusSquare, FaRedoAlt } from "react-icons/fa";
import { displaySuccessToast } from "../../../../../services/helpers";
import Loader from "../../../../../components/loader/loader";
import Stage from "./../../../../../assets/table_images/stage3.webp";
// import Stage from "./../../../../../assets/table_images/stage4.jpeg";
import DeleteTableModal from "./DeleteTableModal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useVenues } from "../../../../../store/venues_store";

const Mappings = ({ tableType_id, venue_id }) => {
  const [modalShow, setModalShow] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minZoomFactor, maxZoomFactor] = [0.7, 1.0];

  const venueTableType = tableStore((state) =>
    state.venueTableTypes.find(
      (venueTableType) => venueTableType.id === tableType_id
    )
  );

  const editVenueTableType = tableStore((state) => state.editVenueTableType);
  const layout_page = useSettings((state) => state.layout_page);

  const tables = tableStore((state) =>
    state.tableLabels.filter(
      (tableLabel) => tableLabel.venue_tableType_id === tableType_id
    )
  );
  const reservations = useVenues((state) => state.reservations);

  const editTableLable = tableStore((state) => state.editTableLable);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [zoomFactor, setZoomFactor] = useState(1);

  const handleZoomIn = (e) => {
    e.preventDefault();
    if (zoomFactor < maxZoomFactor) {
      setZoomFactor((prevZoom) => prevZoom + 0.1);
    }
  };

  const handleZoomOut = (e) => {
    e.preventDefault();
    if (zoomFactor > minZoomFactor) {
      setZoomFactor((prevZoom) => Math.max(0.1, prevZoom - 0.1));
    }
  };
  //resetting the zoom
  const handleResetZoom = (e) => {
    e.preventDefault();

    setZoomFactor(1);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const handleShowDelete = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  const getTableImage = (numberOfSeats, tableType, tableId) => {
    const imagePrefix = tableType === "VIP" ? "blue" : "brown";

    return require(`./../../../../../assets/table_images/${numberOfSeats}${imagePrefix}.svg`);
  };

  const handleDragStop = (e, data, tableId) => {
    const updatedTablelabel = tables.find((table) => table.id === tableId);
    if (updatedTablelabel) {
      const newX = data.x / zoomFactor;
      const newY = data.y / zoomFactor;
      editTableLable(tableId, {
        position: { x: newX, y: newY },
      });
    }
  };

  // positioning stage
  const handleDragStopStage = (e, data) => {
    const newX = data.x / zoomFactor;
    const newY = data.y / zoomFactor;
    editVenueTableType(venue_id, tableType_id, {
      ...venueTableType,
      stage_position: { x: newX, y: newY },
    });
  };

  //update the venue table type
  const handleProceed = (e) => {
    e.preventDefault();
    setIsLoading(true);
    editVenueTableType(venue_id, tableType_id, {
      ...venueTableType,
      tables: tables,
    });
    displaySuccessToast("Changes hanve been saved successfully");
    setIsLoading(false);
  };

  //getting reservations

  //compares the year, month, and day of two date objects
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  //showing the calender

  const handleDateClick = (e) => {
    e.preventDefault();
    // Toggle the visibility of the calendar when the button is clicked
    setShowCalendar(!showCalendar);
  };

  //handle selected Date
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="mt-5 position-relative">
      {/* SHOE MODAL BUTTON */}
      <div className=" position-absolute top-0 end-0 d-flex gap-2">
        <div onClick={handleDateClick}>
          <AddButton text={showCalendar ? "Hide Calender" : "Show Calender"} />
        </div>
        <div onClick={handleShow}>
          <AddButton text="Add Table" icon={<MdAdd size={20} />} />
        </div>

        <div onClick={handleShowDelete}>
          <DeleteButton text="Delete Table" />
        </div>
      </div>

      {/* ZOOM BUTTONS */}

      <div className=" d-flex gap-3">
        <FaPlusSquare
          color={layout_page?.primary_color}
          size={25}
          onClick={handleZoomIn}
        />
        <FaMinusSquare
          color={layout_page?.primary_color}
          size={25}
          onClick={handleZoomOut}
        />
        <FaRedoAlt
          color={layout_page?.primary_color}
          size={25}
          onClick={handleResetZoom}
        />

        {showCalendar && (
          <div className="my-3">
            <Calendar
              value={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
            />
          </div>
        )}
      </div>

      <div className=" bg-gray p-3 categories-scroll-container">
        {/* THE TABLES */}

        <div className=" bg-white center p-3">
          <div
            className="container bg-gray p-2  border-4 border-white"
            style={{
              backgroundImage: `url(${venueTableType?.room_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              transform: `scale(${zoomFactor})`, // Apply the zoom factor
              transition: "transform 0.3s ease", // Add a smooth transition
              height: 600,
              width: 920,
            }}
          >
            {/* stage */}
            <Rnd
              bounds="parent"
              default={{
                x: (venueTableType?.stage_position?.x || 0) * zoomFactor,
                y: (venueTableType?.stage_position?.y || 0) * zoomFactor,
              }}
              onDragStop={(e, data) => handleDragStopStage(e, data)}
            >
              <img src={Stage} width={160} alt=" Stage Position" />
            </Rnd>
            {tables?.length > 0 &&
              tables?.map((table) => (
                <Rnd
                  bounds="parent"
                  default={{
                    x:
                      (tables.find((t) => t.id === table.id)?.position?.x ||
                        0) * zoomFactor,
                    y:
                      (tables.find((t) => t.id === table.id)?.position?.y ||
                        0) * zoomFactor,
                  }}
                  key={table?.id}
                  onDragStop={(e, data) => handleDragStop(e, data, table.id)}
                >
                  <div className="col py-3 d-flex flex-column align-items-center">
                    <span className="position-absolute top-50 translate-middle text-white fw-bold">
                      {table?.tableLabel}
                    </span>
                    <img
                      src={
                        reservations?.find(
                          (reservation) =>
                            isSameDay(
                              new Date(reservation.selectedDate.seconds * 1000),
                              selectedDate
                            ) &&
                            reservation.selectedTables.some(
                              (selectedTable) => selectedTable.id === table.id
                            ) &&
                            reservation?.status === "successful"
                        )
                          ? require(`./../../../../../assets/table_images/${table.numberOfSeats}grey.svg`)
                          : getTableImage(
                              table?.numberOfSeats,
                              table?.table_type,
                              table?.id
                            )
                      }
                      style={{
                        width: 140 * zoomFactor,
                        transition: "width 0.3s ease", // Add a smooth transition
                      }}
                      alt="No table"
                    />
                  </div>
                </Rnd>
              ))}
          </div>
        </div>
      </div>

      {/* a modal to add tables */}
      <AddTableModal
        handleClose={() => setShow(false)}
        show={show}
        tableType_id={tableType_id}
        venue_id={venue_id}
      />

      {/* a modal to delete tables */}
      <DeleteTableModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        venue_room_id={tableType_id}
        venue_id={venue_id}
      />

      <span onClick={handleProceed}>
        <ProceedButton text="Save Changes" />
      </span>
    </div>
  );
};

export default Mappings;
