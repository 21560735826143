import React, { useEffect, useState } from "react";
import BreadCrump from "../components/BreadCrump/bread_crump";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import { useBlogs } from "../store/blog_store";
import Loader from "../components/loader/loader";

export default function PricingAndFees() {
  const page_id = "CNKvF94rZOC79wfN0e2A";
  const getSpecificPage = useBlogs((state) => state.getSpecificPage);
  const [page, setPage] = useState(null); // Initialize page as null

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const pageRef = await getSpecificPage(page_id);
        setPage(pageRef);
      } catch (error) {
        // Handle errors, e.g., display an error message
      }
    };
    fetchPage();
  }, [page_id]); // Use page_id as the dependency

  const parseHTML = (htmlString) => ({ __html: htmlString });

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* BREADCRUMB */}
      <BreadCrump title={"Pricing and Fees"} />

      {page ? (
        <>
          <section className="section-content padding-y bg-white">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card box">
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={parseHTML(page.content)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ADDING THE FOOTER */}
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
