import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import Loader from "../../components/loader/loader";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import PopingOutOrders from "./PopingOutOrders";
import { FaUserCircle } from "react-icons/fa";
import { userStore } from "../../store/user_store";
import { ordersStore } from "../../store/orders_store";
import CustomPagination from "../../components/pagination/Pagination";
import OrganizerName from "../attendee_dashboard/OrganizerName";
import { useSettings } from "../../store/setting_store";
import BreadcrumpAdmin from "./components/breadcrump_admin";
export default function ManageOrders() {
  const isAdmin = userStore((state) => state.isAdmin);
  //get all orders
  const getAllOrdersFromDatabase = ordersStore((state) => state.fetchOrders);
  const orders = ordersStore((state) => state.orders);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  //fetching orders from the database
  useEffect(() => {
    const fetchDataOrders = async () => {
      await getAllOrdersFromDatabase();
    };

    fetchDataOrders();
  }, [orders.length]);

  //SETTING FILTERS
  const [bookingIdFilter, setBookingIdFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");

  //filtering logic
  const filteredOrders = orders.filter((order) => {
    return (
      !bookingIdFilter ||
      (order.booking_id &&
        order.booking_id.toLowerCase().includes(bookingIdFilter.toLowerCase()))
    );
  });

  // Separate function to handle event name filtering
  const filtered_Orders = filteredOrders.filter((order) =>
    order.order_tickets.some(
      (ticket) =>
        !eventFilter ||
        (ticket.event_name &&
          ticket.event_name.toLowerCase().includes(eventFilter.toLowerCase()))
    )
  );

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedOrders = filtered_Orders.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  // PAGINATION STATUS
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(50);
  //get current orders
  const indexOfLastorders = currentPage * ordersPerPage;
  const indexOfFastorders = indexOfLastorders - ordersPerPage;
  const current_orders = sortedOrders?.slice(
    indexOfFastorders,
    indexOfLastorders
  );

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Orders" />

        {/* MANAGE ORDERS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_orders"} />

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  {/* NUMBER OF RESULTS */}
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {orders.length} order(s) found
                      </span>
                    </div>

                    {/* SORT */}
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                          {/* Add similar options for other criteria */}
                        </select>
                      </label>
                    </div>

                    {/* EXPORT BUTTONS */}
                    <div className="col-sm-12 col-lg-4 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&excel=1"
                        target="_blank"
                        className="btn text-white"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Export current orders to an Excel file"
                      >
                        <i className="fas fa-file-excel fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&csv=1"
                        target="_blank"
                        className="btn text-white"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Export current orders to a CSV file"
                      >
                        <i className="fas fa-file-csv fa-lg" />
                      </a>
                      <a
                        href="/en/dashboard/administrator/manage-orders?event=camping-trip&status=1&pdf=1"
                        target="_blank"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        title=" Export attendees list to a PDF file "
                      >
                        <i className="fas fa-file-pdf fa-lg" />
                      </a>
                    </div>

                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        {/* SDEARCH FILTERS */}
                        <div className="row">
                          {/* filter by booking id */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">Reference</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white"
                              id="reference"
                              name="reference"
                              value={bookingIdFilter}
                              onChange={(e) =>
                                setBookingIdFilter(e.target.value)
                              }
                            />
                          </div>
                          {/* filter by event name */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="event">Event </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="event"
                              name="event"
                              data-url-list="/en/api/get-events?published=all&elapsed=all"
                              data-minimum-input-length={0}
                              value={eventFilter}
                              onChange={(e) => setEventFilter(e.target.value)}
                            />
                          </div>
                          {/* filter by start date */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="eventdate">
                              Event date{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="Select a specific event date"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="eventdate"
                              name="eventdate"
                              data-url-list="/en/api/event/camping-trip/get-event-dates"
                              data-minimum-input-length={0}
                            />
                          </div>
                          {/* filter by organizer */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="organizer">
                              Organizer{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="All orders for an organizer"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="organizer"
                              name="organizer"
                              data-url-list="/en/api/get-organizers"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="datefrom">From date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="datefrom"
                              name="datefrom"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="dateto">Until date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="dateto"
                              name="dateto"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* DATA AREA */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter">
                          <thead>
                            <tr>
                              <th className="text-center">Reference</th>
                              <th>Organizer / Event / Date / Ticket</th>
                              <th>Attendee / POS</th>
                              <th>Order date</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          {/* MAPPING OF THE ORDERS RECIEVED */}
                          <tbody>
                            {current_orders.map((order, index) => {
                              const groupedTickets =
                                order?.order_tickets.reduce((acc, ticket) => {
                                  const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                  if (!acc[key]) {
                                    acc[key] = [];
                                  }
                                  acc[key].push(ticket);
                                  return acc;
                                }, {});

                              return (
                                <tr key={order?.booking_id}>
                                  <td className="text-center text-muted text-sm">
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={
                                        "/ticket_details/" + order?.booking_id
                                      }
                                    >
                                      {order?.booking_id}
                                    </Link>
                                  </td>

                                  <td className="text-sm">
                                    <ul>
                                      {Object.values(groupedTickets).map(
                                        (tickets, eventIndex) => {
                                          const event = tickets[0]; // Assuming all tickets in the group belong to the same event
                                          return (
                                            <li key={eventIndex}>
                                              <OrganizerName
                                                event_id={event?.event_id}
                                              />
                                              <i className="fas fa-chevron-right fa-sm mx-1" />
                                              <Link
                                                to={
                                                  "/event_details/" +
                                                  event?.event_id
                                                }
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                              >
                                                {event?.event_name}
                                              </Link>
                                              <i className="fas fa-chevron-right fa-sm mx-1" />
                                              {formatDate(
                                                convertTimestampToDateTime(
                                                  event?.event_start_date
                                                )
                                              )}
                                              <i className="fas fa-chevron-right fa-sm mx-1" />
                                              {event?.ticket_name} ticket
                                              <br />
                                              <small>
                                                <strong>
                                                  {tickets.length}{" "}
                                                  {tickets.length > 1
                                                    ? "tickets"
                                                    : "ticket"}
                                                </strong>
                                              </small>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </td>

                                  <td className="text-sm">
                                    <span className="ajax-loading">
                                      <FaUserCircle size={30} color="gray" />{" "}
                                      {order?.customer_name}
                                    </span>
                                  </td>

                                  <td className="text-sm">
                                    {formatDate(
                                      convertTimestampToDateTime(
                                        order?.created_at
                                      )
                                    )}
                                  </td>

                                  <td className="text-sm">
                                    <span className="badge badge-success">
                                      {order?.order_tickets[index]?.status}
                                    </span>
                                  </td>

                                  <td className="text-center">
                                    <PopingOutOrders
                                      setIsLoading={setIsLoading}
                                      booking_id={order?.booking_id}
                                      order={order}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {sortedOrders?.length > 50 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedOrders?.length / ordersPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
