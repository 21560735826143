import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { RxTriangleDown } from "react-icons/rx";
import { payout_options } from "../../../components/header/sidebar_options";

const MainHeader = ({ currentPage }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        className="border border-0 rounded-0"
      >
        <AccordionSummary
          aria-controls="payout-submenu"
          onClick={(event) => event.stopPropagation()}
        >
          <Typography
            variant=""
            style={{ fontSize: "13px" }}
            className="text-black-50"
          >
            <i className="fas fa-file-invoice-dollar fa-fw" />
            <span className=" mx-1">Payouts</span>
            <RxTriangleDown size={15} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="nav flex-column ml-3" style={{ marginTop: "-10px" }}>
            {payout_options?.map((option, index) => (
              <li key={index} className="nav-item">
                <Link
                  to={`/${option.link}`}
                  className={
                    "nav-link " + (currentPage === option.link ? "active" : "")
                  }
                >
                  {option.name}
                </Link>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MainHeader;
