import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

import PopingOutCountries from "./PopingOutCountries";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import CountriesCount from "./CountriesCount";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageCountries() {
  const getCountriesList = useEvents((state) => state.getCountriesList);
  const countries = useEvents((state) => state.countries);
  const layout_page = useSettings((state) => state.layout_page);

  /////////////FETCHING VENUETYPES//////////////////////
  useEffect(() => {
    async function fetchData() {
      await getCountriesList();
    }
    fetchData();
  }, [countries.length]);
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const navigate = useNavigate();

  //FILTERING COUNTRIES

  const [countryFilter, setCountryFilter] = useState("");

  const filteredCountries = countries.filter((country) => {
    return (
      !countryFilter ||
      (country.name &&
        country.name.toLowerCase().includes(countryFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedCountries = filteredCountries.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [countriesPerPage] = useState(20);
  //get current events
  const indexOfLastcountries = currentPage * countriesPerPage;
  const indexOfFastcountries = indexOfLastcountries - countriesPerPage;
  const current_countries = sortedCountries?.slice(
    indexOfFastcountries,
    indexOfLastcountries
  );

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Countries" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_countries"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  {/* SEARCH AND FILTERING BAR */}
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {countries.length} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={countryFilter}
                        onChange={(e) => setCountryFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-new-country"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new country"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Venues count</th>
                              <th>Flag</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DISPLAYING COUNTRIES */}

                            {current_countries.map((country) => (
                              <tr key={country?.id}>
                                {/* DISPLAYING COUNTRY NAME */}
                                <td>{country?.name}</td>

                                {/* DISPLAYING NUMBER OF EVENTS */}
                                <td>
                                  <CountriesCount country_id={country?.id} />
                                </td>

                                {/* DISPLAYING COUNTRY CODE */}
                                <td>
                                  <ReactCountryFlag
                                    countryCode={country?.countryCode}
                                    svg
                                    style={{
                                      width: "2em",
                                      height: "2em",
                                    }}
                                  />
                                </td>

                                {/* DISPLAYING STATUS */}
                                <td>
                                  {country?.isVisible === true ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> Visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />
                                      Invisible
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <PopingOutCountries
                                    country_id={country.id}
                                    isVisible={country?.isVisible}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {sortedCountries?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedCountries?.length / countriesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
