import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { IconPickerItem } from "react-fa-icon-picker-alen";
import Loader from "../../../components/loader/loader";
import PopingOutAmenities from "./PopingOutAmenities";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";
import { AmenitiesCount } from "./venueCount";

export default function ManageAmenities() {
  const navigate = useNavigate();
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const getAmmenities = useVenues((state) => state.getAmmenities);
  const ammenities = useVenues((state) => state.ammenities);
  const layout_page = useSettings((state) => state.layout_page);
  const [isLoading, setIsLoading] = useState(false);

  /////////////FETCHING VENUETYPES//////////////////////
  useEffect(() => {
    async function fetchData() {
      await getAmmenities();
    }
    fetchData();
  }, [ammenities.length]);

  // GETTING THE NUMBER OD AMMENITIES
  const numofammenities = ammenities.length;

  //filtering logic
  const [ammenitiesFiliter, setAmmenitiesFiliter] = useState("");
  const filteredAmmenities = ammenities.filter((ammenity) => {
    return (
      !ammenitiesFiliter ||
      (ammenity.name &&
        ammenity.name.toLowerCase().includes(ammenitiesFiliter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedAmmenities = filteredAmmenities.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [ammenitiesPerPage] = useState(10);
  //get current events
  const indexOfLastammenities = currentPage * ammenitiesPerPage;
  const indexOfFastammenities = indexOfLastammenities - ammenitiesPerPage;
  const current_ammenities = sortedAmmenities?.slice(
    indexOfFastammenities,
    indexOfLastammenities
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Amenities" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_amenities"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {numofammenities} result(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={ammenitiesFiliter}
                        onChange={(e) => setAmmenitiesFiliter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-new-amenities"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new amenity"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* DISPLAYING AMMENITIES */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Venues count</th>
                              <th>Icon</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_ammenities.map((ammenity, index) => (
                              <tr key={ammenity?.id}>
                                {/* DISPLAYING AMMENITY NAME */}
                                <td>{ammenity?.name}</td>
                                <td>
                                  <AmenitiesCount
                                    amenity_name={ammenity?.name}
                                  />
                                </td>

                                {/* DISPLAYING THE ICON SELECTED */}
                                <td>
                                  <IconPickerItem
                                    icon={ammenity?.icon}
                                    size={20}
                                    color="#000"
                                  />
                                </td>

                                {/* SHOWING STATUS */}
                                <td>
                                  {ammenity?.isVisible === true ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> Visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />{" "}
                                      Invisible
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <PopingOutAmenities
                                    ammenity_id={ammenity?.id}
                                    setIsLoading={setIsLoading}
                                    isVisible={ammenity?.isVisible}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* pagination */}
                    {sortedAmmenities?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedAmmenities?.length / ammenitiesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
