import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { myContext } from "../../../user_context";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import { displaySuccessToast } from "../../../services/helpers";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditCurrency() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const updateCurrency = useSettings((state) => state.updateCurrency);
  const layout_page = useSettings((state) => state.layout_page);

  const { currency_id } = useParams();
  const { document } = useFetchDocument("currencies", currency_id);
  console.log("the document is:", document);

  const [form, setForm] = useState(document || {});

  useEffect(() => {
    setForm(document);
  }, [document]);

  //handle inputs
  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // HANDLING UPLOADING THE CURRENCY
  const handleEditCurrency = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      await updateCurrency(currency_id, {
        ccy: form?.ccy,
        symbol: form?.symbol,
      });
      navigate("/manage_currencies");
      displaySuccessToast("Update is successful");
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CCY
    if (form?.ccy.trim() === "") {
      errors.ccy = "CCI is required";
    }

    // VALIDATING SYMBOL
    if (form?.symbol.trim() === "") {
      errors.symbol = "Symbol is required";
    }

    return errors;
  }

  // const {isAdmin} = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit currency"
          page_link="Payment settings"
          link="manage_payments"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="currency"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleEditCurrency}
                    >
                      <div id="currency">
                        <div className="form-group">
                          <label htmlFor="currency_ccy" className="required">
                            CCY
                          </label>
                          <small
                            id="currency_ccy_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Please refer to the following list and use the Code
                            column: https://en.wikipedia.org/wiki/ISO_4217
                          </small>
                          <input
                            type="text"
                            id="currency_ccy"
                            name="ccy"
                            required="required"
                            aria-describedby="currency_ccy_help"
                            className="form-control"
                            value={form?.ccy || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="currency_symbol" className="required">
                            Currency symbol
                          </label>
                          <input
                            type="text"
                            id="currency_symbol"
                            name="symbol"
                            required="required"
                            className="form-control"
                            value={form?.symbol || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="currency_save"
                            name="currency[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="currency__token"
                          name="currency[_token]"
                          defaultValue="d8-BCAvUIy2Mk-CDKcBEY7GWZKhGxnn-KH4WC-bemag"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
