import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { myContext } from "../../../user_context";
import Loader from "../../../components/loader/loader";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditCountry() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();
  const updateCountry = useEvents((state) => state.updateCountry);
  //HANDLING COUNTRIES ADDUP
  const [isloading, setIsloading] = useState("");
  const { country_id } = useParams();
  const layout_page = useSettings((state) => state.layout_page);
  const [errors, setErrors] = useState({});

  const { document } = useFetchDocument("countries", country_id);
  const [form_countries, setFormCountries] = useState(document || {});

  useEffect(() => {
    setFormCountries(document);
  }, [document]);

  //handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormCountries((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //edit function
  const handleEditCountry = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsloading(true);
      try {
        await updateCountry(country_id, {
          name: form_countries?.name,
          countryCode: form_countries?.countryCode,
        });
        setIsloading(false);
        displaySuccessToast("Update is successful");
        navigate("/manage_countries");
      } catch (error) {
        setIsloading(false);
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsloading(false);
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!form_countries?.name) {
      errors.form_countries.name = "name is required";
    }
    if (!form_countries?.countrycode) {
      errors.form_countries.countrycode = "country code is required";
    }

    return errors;
  }

  //displaying the loader
  if (isloading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title=" Edit Country"
          link="manage_countries"
          page_link="Manage Countries"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_countries"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/*FORM TO CAPTURE DATA  */}
                    <form
                      name="country"
                      onSubmit={handleEditCountry}
                      noValidate="novalidate"
                    >
                      <div id="country">
                        {/* ADDING NAME FIELD */}
                        <div className="tab-content">
                          <div
                            id="country_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="country_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="country_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>
                                {errors.form_countries?.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.form_countries?.name}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  name="name"
                                  value={form_countries?.name || ""}
                                  onChange={(e) => handleInputChange(e)}
                                  required="required"
                                  maxLength={50}
                                  pattern=".{1,}"
                                  className={
                                    errors.form_countries?.name
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* COUNTRY CODE FIELD */}
                        <div className="form-group d-grid">
                          <label htmlFor="country_code" className="required">
                            Country code
                          </label>
                          {errors.form_countries?.countrycode && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.form_countries?.countrycode}
                            </span>
                          )}
                          <input
                            type="text"
                            name="countryCode"
                            value={form_countries?.countryCode || ""}
                            onChange={(e) => handleInputChange(e)}
                            required="required"
                            className={
                              errors.form_countries?.countrycode
                                ? " border border-danger form-control"
                                : "form-control"
                            }
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="country__token"
                          name="country[_token]"
                          defaultValue="BErEid71VrwUsx-cfdACufd8VlvwTU0pP78jI7LWCxM"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
