import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { useBlogs } from "../../../store/blog_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutArticles = ({ article_id, status, featured }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteArticle = useBlogs((state) => state.deleteHelperArticle);
  const updateArticle = useBlogs((state) => state.updateArticle);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE article
  async function handleDeleteArticle(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteArticle(article_id);
    if (response) {
      displaySuccessToast("🦄 The article has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the article");
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }

  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateArticle(article_id, {
      status: status === "visible" ? "invisible" : "visible",
    });
    if (status === "invisible") {
      setIsLoading(false);
      displaySuccessToast("The article has been published");
    } else {
      setIsLoading(false);
      displayWarningToast("The article has been hidden and will not be viewed");
    }
  };

  const handleFeatured = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateArticle(article_id, {
      featured: featured === true ? false : true,
    });

    if (featured === false) {
      setIsLoading(false);
      displaySuccessToast("The article has been featured");
    } else {
      setIsLoading(false);
      displayWarningToast("The article has been unfeatured");
    }
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT CATEGORY */}
          <Link to={"/edit_helper_article/" + article_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* MARK AS FEATURED */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleFeatured(e)}
          >
            {featured === false ? (
              <>
                <i class="fas fa-solid fa-star fa-fw"></i>
                <h6>Mark as Featured</h6>
              </>
            ) : (
              <>
                <i class="fas fa-solid fa-thumbtack fa-fw"></i>
                <h6>Mark not as Featured</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {status === "visible" ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE ARTICLE */}
          <DeleteComponentModal
            itemName="Article"
            handleDelete={handleDeleteArticle}
          />{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutArticles;
