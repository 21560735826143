import React, { useContext, useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Alert from "./components/alert";
import Header from "../../components/header/header";
import DashboardBreadcrump from "../../components/dashboard_breadcrump/dashboard_breadcrump";
import { useNavigate } from "react-router-dom";
import UserReviewTile from "../../components/reviews/user_review_tile";
import { isMobile } from "react-device-detect";
import { userStore } from "./../../store/user_store";
import { useReview } from "../../store/review_store";
import CustomPagination from "../../components/pagination/Pagination";
import Loader from "../../components/loader/loader";
import { auth } from "../../Firebase";

export default function Reviews() {
  //getting user reviews
  const getUserReviews = useReview((state) => state.getUserReviews);
  const userReviews = useReview((state) => state.userReviews);
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);

  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function handleGetReviews() {
      await getUserReviews(user?.user_id);
    }
    handleGetReviews();
    //delay of 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(20);
  //get current events
  const indexOfLastreviews = currentPage * reviewsPerPage;
  const indexOfFastreviews = indexOfLastreviews - reviewsPerPage;
  const current_reviews = userReviews?.slice(
    indexOfFastreviews,
    indexOfLastreviews
  );

  // GO TO LOGIN IF USER IS NOT LOGGED IN
  if (!user) {
    navigate("/sign_in");
    return;
  }
  if (isLoading) {
    return <Loader />;
  }

  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />
        <DashboardBreadcrump title={"My Reviews"} />

        {/* REVIEWS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE NAV */}
              {!isMobile && <AttendeeSideNav activeTab={"reviews"} />}

              {userReviews.length === 0 ? (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <Alert message={"No Reviews Found"} />
                </div>
              ) : (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <div className="card box">
                    <div className="card-body">
                      <div className="row mb-4">
                        {current_reviews.map((review) => (
                          <UserReviewTile review={review} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* PAGINATION */}
            {userReviews?.length > 20 && (
              <CustomPagination
                setCurrentPage={setCurrentPage}
                totalPages={Math.ceil(userReviews?.length / reviewsPerPage)}
              />
            )}
          </div>
        </section>
      </div>
    );
  }
}
