// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top-button:hover {
    opacity: 1;
  }
   */`, "",{"version":3,"sources":["webpack://./src/components/Scrolltopbutton/ScrollToTopButton.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;IAkBI","sourcesContent":["/* .scroll-to-top-button {\r\n    position: fixed;\r\n    bottom: 20px;\r\n    right: 20px;\r\n    background-color: #333;\r\n    color: #fff;\r\n    border: none;\r\n    border-radius: 50%;\r\n    padding: 10px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    opacity: 0.7;\r\n    transition: opacity 0.3s ease-in-out;\r\n  }\r\n  \r\n  .scroll-to-top-button:hover {\r\n    opacity: 1;\r\n  }\r\n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
