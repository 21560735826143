import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import PopingOutArticles from "./PopingOutArticles";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import {
  convertTimestampToDateTime,
  formatDate,
  formatNumberWithCommas,
} from "../../../services/helpers";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageArticles() {
  //FETCHING ARTICLES
  const getArticles = useBlogs((state) => state.getArticles);
  const articles = useBlogs((state) => state.articles);
  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    async function fetchData() {
      await getArticles();
    }
    fetchData();
  }, [articles.length]);

  const isAdmin = userStore((state) => state.isAdmin);
  const navigate = useNavigate();

  //filtering logic
  const [articlesFilter, setArticlesFilter] = useState("");
  const filteredArticles = articles.filter((article) => {
    return (
      !articlesFilter ||
      (article.title &&
        article.title.toLowerCase().includes(articlesFilter.toLowerCase()))
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("updated_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedArticles = filteredArticles?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    if (sortCriteria === "views") {
      if (sortDirection === "desc") {
        // Sort by popularity (views) in descending order
        return b.views - a.views;
      } else {
        // Sort by popularity (views) in ascending order
        return a.views - b.views;
      }
    } else if (sortCriteria === "updated_at") {
      // Sort by creation date
      const dateA = a.updated_at;
      const dateB = b.updated_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      // Handle other criteria sorting here
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(20);
  //get current article
  const indexOfLastarticles = currentPage * articlesPerPage;
  const indexOfFastarticles = indexOfLastarticles - articlesPerPage;
  const current_articles = sortedArticles?.slice(
    indexOfFastarticles,
    indexOfLastarticles
  );

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title=" Manage Helpcenter Articles" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_articles"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {articles.length} result(s) found
                      </span>
                    </div>

                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={articlesFilter}
                        onChange={(e) => setArticlesFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`updated_at-desc`}
                            selected={
                              sortCriteria === "updated_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Update date (desc)
                          </option>
                          <option
                            value={`updated_at-asc`}
                            selected={
                              sortCriteria === "updated_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Update date (asc)
                          </option>
                          <option
                            value={`views-desc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "desc"
                            }
                          >
                            Popularity (desc)
                          </option>
                          <option
                            value={`views-asc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "asc"
                            }
                          >
                            Popularity (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-helpcenter-article"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a help center article"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Category</th>
                              <th>Views</th>
                              <th>Last updated</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_articles?.map((article) => (
                              <tr key={article?.id}>
                                {/* displaying the title */}
                                <td> {article?.title} </td>
                                {/* dispalying category */}
                                <td> {article?.userCategory} </td>
                                {/* displaying views */}
                                <td>{article?.views || 0}</td>
                                {/* displaying time */}
                                <td>
                                  {formatDate(
                                    convertTimestampToDateTime(
                                      article?.updated_at
                                    )
                                  )}
                                </td>
                                {/* displaying status */}
                                <td>
                                  {article?.status === "visible" ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> Visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />{" "}
                                      Invisible
                                    </span>
                                  )}
                                  <br />
                                  <br />
                                  {article?.featured === true && (
                                    <span className=" badge badge-info">
                                      <i class="fas fa-solid fa-star fa-fw"></i>
                                      Featured
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <PopingOutArticles
                                    article_id={article?.id}
                                    status={article?.status}
                                    featured={article?.featured}
                                  />
                                </td>
                              </tr>
                            ))}
                            {/* END OF THE ARTICLE */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {sortedArticles?.length > 20 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedArticles?.length / articlesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
