import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconPicker } from "react-fa-icon-picker-alen";
import "./icon_style.css";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditEventsCategory() {
  //zustand states
  const uploadeventsCategoryImage = useEvents(
    (state) => state.uploadeventsCategoryImage
  );
  const deleteCategoryImageUrl = useEvents(
    (state) => state.deleteCategoryImageUrl
  );

  const updateEventCategory = useEvents((state) => state.updateEventCategory);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { category_id } = useParams();
  const [errors, setErrors] = useState({});

  const { document } = useFetchDocument("events_category", category_id);
  console.log("the document fetched is:", document);
  const [form_categories, setFormCategories] = useState(document || {});

  useEffect(() => {
    setFormCategories(document);
  }, [document]);

  //handle change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormCategories((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  //error validation
  function validateForm() {
    const errors = {};
    if (!form_categories?.name) {
      errors.form_categories.name = "name is required";
    }
    if (!form_categories?.image) {
      errors.form_categories.image = "image is required";
    }
    if (!form_categories?.icon) {
      errors.form_categories.icon = "Icon is important";
    }
    return errors;
  }

  const handleEditCategory = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateEventCategory(category_id, {
          name: form_categories?.name,
          icon: form_categories?.icon,
          image: form_categories?.image,
          displayorder: form_categories?.displayorder,
        });
        setIsLoading(false);
        displaySuccessToast("Update Successful");
        navigate("/manage_event_categories");
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        displayErrorToast("Update failed", error);
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLING IMAGE CAPTURE
  const [categoryUrl, setCategoryUrl] = useState(form_categories?.image);
  useEffect(() => {
    const fetchCategoryUrl = async () => {
      const url = await uploadeventsCategoryImage(form_categories?.image);
      setCategoryUrl(url);
    };
    //fetch the audience url from the firebase
    if (form_categories?.image) {
      fetchCategoryUrl();
    }
  }, [form_categories?.image]);

  // HANDLING IMAGE CAPTURE
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await uploadeventsCategoryImage(file);
      if (imageUrl) {
        setCategoryUrl(imageUrl);
        setFormCategories((prevFormData) => ({
          ...prevFormData,
          image: imageUrl,
        }));
      }
    }
  };

  //handling deleting the image
  const handledeletecategoryImage = async () => {
    if (categoryUrl) {
      await deleteCategoryImageUrl(categoryUrl);
      setCategoryUrl("");
      setFormCategories((prevFormData) => ({
        ...prevFormData,
        image: "",
      }));
    }
  };

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit Event category"
          link="manage_event_categories"
          page_link="Manage Categories"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_venues"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/* INPUT FORM */}
                    <form
                      name="category"
                      onSubmit={handleEditCategory}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <div id="category">
                        {/* INPUT FOR NAMES */}

                        <div className="tab-content">
                          <div
                            id="category_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="category_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="category_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>
                                {errors.form_categories?.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.form_categories?.name}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  name="name"
                                  value={form_categories?.name}
                                  onChange={(e) => handleInputChange(e)}
                                  id="category_translations_en_name"
                                  required="required"
                                  maxLength={50}
                                  pattern=".{1,}"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* INPUT FOR ICONS */}
                        <div className="form-group d-grid">
                          <label htmlFor="category_icon" className="required">
                            Icon
                          </label>
                          {errors.form_categories?.icon && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.form_categories?.icon}
                            </span>
                          )}
                          <IconPicker
                            name="icon"
                            value={form_categories?.icon} // Set the value of the IconPicker to the icon value from form_categories state
                            onChange={(v) =>
                              setFormCategories((prevFormData) => ({
                                ...prevFormData,
                                icon: v, // Update the icon value in the form_categories state
                              }))
                            }
                          />
                        </div>

                        {/* INPUT FOR IMAGES */}
                        <fieldset className="form-group">
                          <legend className="col-form-label">Image</legend>
                          {categoryUrl && (
                            <div className="d-grid">
                              <img
                                src={form_categories?.image}
                                alt=""
                                style={{ maxWidth: "50%" }}
                              />
                              <div
                                onClick={handledeletecategoryImage}
                                className=" bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                          {!categoryUrl && (
                            <div className="vich-image">
                              <div className="custom-file d-grid">
                                {errors.form_categories?.image && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.form_categories?.image}
                                  </span>
                                )}
                                <input
                                  type="file"
                                  onChange={handleFileSelect}
                                  id="category_imageFile_file"
                                  name="category[imageFile][file]"
                                  className="custom-file-input"
                                />
                                <label
                                  htmlFor="category_imageFile_file"
                                  className="custom-file-label"
                                />
                              </div>
                            </div>
                          )}
                        </fieldset>

                        {/* INPUT FOR DISPALY ORDER */}
                        <div className="form-group">
                          <label className htmlFor="category_featuredorder">
                            Featured order
                          </label>
                          <small
                            id="category_featuredorder_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Set the display order for the featured categories
                          </small>
                          <select
                            id="category_featuredorder"
                            value={form_categories?.displayorder}
                            onChange={(e) => handleInputChange(e)}
                            name="displayorder"
                            aria-describedby="category_featuredorder_help"
                            className="form-control"
                          >
                            <option value />
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="category_save"
                            name="category[save]"
                            className="btn btn-primary btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
