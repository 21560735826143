import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useReview } from "../../store/review_store";
import UserReviewTile from "../../components/reviews/user_review_tile";
import CustomPagination from "../../components/pagination/Pagination";
import { useEvents } from "../../store/event_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function MyReviews() {
  const isOrganizer = userStore((state) => state.isOrganizer);
  //getting user reviews
  const getOrganizerEventReviews = useEvents(
    (state) => state.getReviewsForOrganizerEvents
  );
  // const userReviews = useEvents((state) => state.organizerReviews);
  const user = userStore((state) => state.user);
  const layout_page = useSettings((state) => state.layout_page);

  //getting a specific review
  const getEventReviews = useReview((state) => state.getEventReviews);

  const [organizerEventReviews, setOrganizerEventReviews] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function handleGetReviews() {
      const reviews = await getOrganizerEventReviews(user?.user_id);
      setOrganizerEventReviews(reviews);

      //displaying a specific review
      if (id) {
        const eventReviews = await getEventReviews(id);
        setOrganizerEventReviews(eventReviews);
      }
    }
    handleGetReviews();
  }, [user?.user_id]);

  //filtering the reviews basing on event name

  const [eventNameFilter, setEventNameFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [visibilityFilter, setVisibilityFilter] = useState("all");
  const [ratingFilter, setRatingFilter] = useState("all");

  //filtering logic
  const filteredReviews = organizerEventReviews.filter((review) => {
    return (
      (!eventNameFilter ||
        (review.event_name &&
          review.event_name
            .toLowerCase()
            .includes(eventNameFilter.toLowerCase()))) &&
      (!keywordFilter ||
        (review.review_headline &&
          review.review_headline
            .toLowerCase()
            .includes(keywordFilter.toLowerCase()))) &&
      (visibilityFilter === "all" ||
        (visibilityFilter === "visible" && review.status === "visible") ||
        (visibilityFilter === "invisible" && review.status === "invisible")) &&
      (ratingFilter === "all" || review.rating.toString() === ratingFilter)
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedReviews = filteredReviews?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    if (sortCriteria === "rating") {
      // Convert rating strings to numbers for sorting
      const ratingA = parseInt(a.rating, 10);
      const ratingB = parseInt(b.rating, 10);

      // Sort by rating
      return sortDirection === "desc" ? ratingB - ratingA : ratingA - ratingB;
    } else if (sortCriteria === "created_at") {
      // Sort by creation date
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(20);
  //get current events
  const indexOfLastreviews = currentPage * reviewsPerPage;
  const indexOfFastreviews = indexOfLastreviews - reviewsPerPage;
  const current_reviews = sortedReviews?.slice(
    indexOfFastreviews,
    indexOfLastreviews
  );

  // GO TO LOGIN IF USER IS NOT LOGGED IN
  if (!user) {
    navigate("/sign_in");
    return;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"Reviews"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE BAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"my_reviews"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5 text-center text-lg-left mb-3 mb-lg-0 d-flex align-content-center justify-content-start">
                      <span className="center-lg-y text-muted">
                        {organizerEventReviews?.length} reviews(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right d-flex align-content-center justify-content-start">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "70%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option value="rating-desc">Rating (desc)</option>
                          <option value="rating-asc">Rating (asc)</option>
                          <option
                            value={`created_at-desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>
                          <option
                            value={`created_at-asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="keyword">Keyword</label>
                            <input
                              id="keyword"
                              name="keyword"
                              type="text"
                              className="form-control bg-white"
                              placeholder="Search in reviews"
                              style={{ width: "auto" }}
                              value={keywordFilter}
                              onChange={(e) => setKeywordFilter(e.target.value)}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="event">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control autocomplete bg-white"
                              id="event"
                              name="event"
                              data-url-list="/en/api/get-events?organizer=mestil-hotel-1&published=all&elapsed=all"
                              data-minimum-input-length={0}
                              value={eventNameFilter}
                              onChange={(e) =>
                                setEventNameFilter(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="visible-filter-all"
                                type="radio"
                                name="all"
                                value="all"
                                className="custom-control-input"
                                checked={visibilityFilter === "all"}
                                onChange={() => setVisibilityFilter("all")}
                              />
                              <label
                                htmlFor="visible-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            {/* filter by visibility */}
                            <div className="custom-control custom-checkbox">
                              <input
                                id="visible-filter-yes"
                                type="radio"
                                name="visible"
                                value="visible"
                                className="custom-control-input"
                                checked={visibilityFilter === "visible"}
                                onChange={() => setVisibilityFilter("visible")}
                              />
                              <label
                                htmlFor="visible-filter-yes"
                                className="custom-control-label"
                              >
                                Visible only
                              </label>
                            </div>
                            {/* filter by invisibility */}
                            <div className="custom-control custom-checkbox">
                              <input
                                id="invisible-filter-yes"
                                type="radio"
                                name="invisible"
                                value="invisible"
                                className="custom-control-input"
                                checked={visibilityFilter === "invisible"}
                                onChange={() =>
                                  setVisibilityFilter("invisible")
                                }
                              />
                              <label
                                htmlFor="invisible-filter-yes"
                                className="custom-control-label"
                              >
                                Hidden only
                              </label>
                            </div>
                          </div>
                          {/* FILTERING BY STARS */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="rating-filter-all"
                                type="radio"
                                name="rating"
                                value="all"
                                className="custom-control-input"
                                checked={ratingFilter === "all"}
                                onChange={() => setRatingFilter("all")}
                              />
                              <label
                                htmlFor="rating-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>

                            {[5, 4, 3, 2, 1].map((star) => (
                              <div
                                key={star}
                                className="custom-control custom-checkbox"
                              >
                                <input
                                  id={`rating-filter-${star}`}
                                  type="radio"
                                  name="rating"
                                  value={star.toString()}
                                  className="custom-control-input"
                                  checked={ratingFilter === star.toString()}
                                  onChange={() =>
                                    setRatingFilter(star.toString())
                                  }
                                />
                                <label
                                  htmlFor={`rating-filter-${star}`}
                                  className="custom-control-label"
                                >
                                  {star} stars
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {organizerEventReviews?.length === 0 ? (
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" /> No reviews found
                  </div>
                ) : (
                  <div className="col mt-4 mt-lg-0 bg-gray py-4">
                    <div className="card box">
                      <div className="card-body">
                        <div className="row mb-4">
                          {current_reviews.map((review, index) => (
                            <UserReviewTile key={review.id} review={review} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* PAGINATION */}
              {sortedReviews?.length > 20 && (
                <CustomPagination
                  setCurrentPage={setCurrentPage}
                  totalPages={Math.ceil(sortedReviews?.length / reviewsPerPage)}
                />
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
