import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { ordersStore } from "../../../store/orders_store";
import Loader from "../../../components/loader/loader";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { isMobile } from "react-device-detect";
import Footer from "../../../components/footer/footer";
import EventStatisitcsGraph from "./EventStatisticsGraph";
import { BsGraphUpArrow } from "react-icons/bs";
import StatisticsCircularSales from "./statisticsComponents/StatisticsCirclularSales";
import StatisticsCircularCheckin from "./statisticsComponents/statisticsCircularCheckin";
import {
  convertTimestampToDateTime,
  formatDate,
  formatNumberWithCommas,
} from "../../../services/helpers";
import { useSettings } from "../../../store/setting_store";
import { useReview } from "../../../store/review_store";
import Sidenavbar from "../../organizer_dashboard/components/sidenavbar";
import Status from "./Status";

export default function EventStatisctics() {
  const { event_id } = useParams();
  
  // USING ZUSTAND
  // fetching all the events from the database
  const isAttendee = userStore((state) => state.isAttendee);
  const isAdmin = userStore((state) => state.isAdmin);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const user = userStore((state) => state.user);
  const getSpecificEvent = useEvents((state) => state.getEvent);
  const layout_page = useSettings((state) => state.layout_page);
  //getting the ticket quantity for an event.
  const getOrderTicketsForEvent = ordersStore(
    (state) => state.getOrderTicketsForEvent
  );
  const getEventReviews = useReview((state) => state.getEventReviews);
  const getAllTicketQuantityEvent = useEvents(
    (state) => state.getAllNumTicketQuantityForEvent
  );

  //getting scanned tickets in an order of a single event
  const getScannedTicketsForEvent = ordersStore(
    (state) => state.getScannedTicketsForEvent
  );
  const events = useEvents((state) => state.events);
  //geting total amount
  const getEventTransactionAmount = useReview(
    (state) => state.getEventTransactionAmount
  );
  //getting the payment Page Settings
  const payment_page = useSettings((state) => state.payment_page);
  const currency = payment_page?.currency;
  const upcomingEvents = useEvents((state) => state.upcomingEvents);

  //getting the cart items of the event
  const countCartItemsForEvent = userStore(
    (state) => state.countCartItemsForEvent
  );

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [event, setEvent] = useState([]);
  const [tickets, setTickets] = useState(0);
  const [event_reviews, setEventReviews] = useState([]);
  const [totalEventTickets, setTotalEventTickets] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [scannedPercentage, setScannedPercentage] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [cartItems, setCartItems] = useState(0);

  useEffect(() => {
    async function fetchData() {
      //getting all the ticket for an event
      const t = await getOrderTicketsForEvent(event_id);
      console.log("the ordered tickets for the event:", t);
      setTickets(t);
      //getting specific event
      const eventSpecific = await getSpecificEvent(event_id);
      setEvent(eventSpecific);

      //getting event reviews
      const reviews = await getEventReviews(event_id);
      setEventReviews(reviews);

      // getting total event tickets quantity which the user entered when adding the event
      const quantity = await getAllTicketQuantityEvent(event_id);
      setTotalEventTickets(quantity);

      //calculate percentage of tickets sold
      const ticketsSold = parseInt(t);
      const totalTickets = parseInt(totalEventTickets);
      if (totalTickets !== 0 && ticketsSold !== 0) {
        const soldPercentage = (ticketsSold / totalTickets) * 100;

        setPercentage(soldPercentage.toFixed(1)); // format to one decimal place
      } else {
        setPercentage(0);
      }

      //getting scannedTickets in an order for an event
      const ticketsScanned = await getScannedTicketsForEvent(event_id);
      const ts = ticketsScanned.length;

      //caliculating the scanned tickets percentage
      const scannedTicks = parseInt(ts);

      if (scannedTicks !== 0 && ticketsSold !== 0) {
        const soldScannedPercentage = (scannedTicks / ticketsSold) * 100;
        setScannedPercentage(soldScannedPercentage.toFixed(1)); // format to one decimal place
      } else {
        setScannedPercentage(0);
      }

      //getting total amount of money sold
      const amount = await getEventTransactionAmount(event_id);
      setGrossAmount(amount);

      //getting cart items of the event
      const cart = await countCartItemsForEvent(event_id);
      setCartItems(cart);
    }
    fetchData();
    setIsLoading(false);
  }, [event_id, totalEventTickets?.length, events?.length, event?.length]);

  const isUpcomingEvent = upcomingEvents.some(
    (upcomingEvent) => upcomingEvent.id === event_id
  );

  //  DISPLAYING THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin && !isOrganizer) {
    // console.log("IS ADMIN: " + isAdmin);
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* ADMIN DASHBOARD BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <div className=" d-flex justify-content-between">
              <h4 className="title-page dark b float-xl-left mb-0">
                Statistics
              </h4>
              <nav className="float-xl-right mt-2 mt-xl-0">
                <ol className="breadcrumb text-white">
                  <li className="breadcrumb-item">
                    <Link to="/admin_dashboard" className="dark">
                      <i className="fas fa-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/manage_events" className="dark">
                      Manage events
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/event_details/" + event_id} className="dark">
                      {event?.name}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Statistics
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>

        {/* STATISTICS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {isAdmin ? (
                <>
                  {!isMobile && <AdminSideNav currentPage={"manage_events"} />}
                </>
              ) : (
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar currentPage={"myevents"} />}
                </aside>
              )}

              {/*  STATISTICS CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className=" bg-gray p-4" style={{ margin: "-20px" }}>
                    {" "}
                    <div className="d-flex gap-3 cursor-pointer text-lg fw-bold">
                      <BsGraphUpArrow size={20} />
                      <p>{event?.name}</p>
                    </div>
                    {/* STATUS */}
                    {isUpcomingEvent ? (
                      <span>
                        {event?.visibility !== "draft" ? (
                          <p className="badge badge-success">On sale</p>
                        ) : (
                          <p className="badge badge-warning">
                            Event not published
                          </p>
                        )}
                      </span>
                    ) : (
                      <span className="text-center">
                        <p className="badge badge-danger">Past Event</p>
                      </span>
                    )}
                    <p className=" d-flex">
                      When:
                      {event?.event_dates?.map((date, index) => (
                        <div key={index} className="ml-2">
                          {formatDate(
                            convertTimestampToDateTime(date?.start_date)
                          )}
                        </div>
                      ))}
                    </p>
                    <p className=" d-flex">
                      Where:
                      {event?.event_dates?.map((date, index) => (
                        <div key={index} className="ml-2">
                          {date?.venue}
                        </div>
                      ))}
                    </p>
                    {/* <HoverStatisticsButton /> */}
                  </div>
                  {/* EVENT DATE DETAILS */}
                  <div className="pt-5">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col" className="text-lg">
                              Event Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Status</th>
                            <td>
                              <Status event={event} />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Reference</th>
                            <td>{event?.id && event.id?.slice(0, 10)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Venue</th>
                            <td colSpan="2">
                              {event?.event_dates?.map((date, index) => (
                                <div key={index}>{date.venue}</div>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Address</th>
                            <td colSpan="2">
                              {event?.country?.label
                                ? event?.country?.label
                                : "No Address"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Ticket types</th>
                            <td>
                              {event?.event_dates?.map((date, index) => (
                                <div key={index}>
                                  {date?.tickets?.map((ticket, ticketIndex) => (
                                    <div key={ticketIndex}>
                                      {ticket?.ticket_name}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Start date</th>
                            <td colSpan="2">
                              {event?.event_dates?.map((date, index) => (
                                <div key={index}>
                                  {formatDate(
                                    convertTimestampToDateTime(date.start_date)
                                  )}
                                </div>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">End date</th>
                            <td colSpan="2">
                              {event?.event_dates?.map((date, index) => (
                                <div key={index}>
                                  {formatDate(
                                    convertTimestampToDateTime(date.end_date)
                                  )}
                                </div>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row mb-3 d-flex mt-5">
                      {/* EVENTS ADDED */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i class="fas fa-money-bill" />
                          <h4>
                            {currency?.label}{" "}
                            {formatNumberWithCommas(grossAmount)}
                          </h4>
                          <h5 className="mb-0">Total gross sales</h5>
                        </div>
                      </div>

                      {/* PUBLISHED EVENTS */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i class="fas fa-ticket-alt" />
                          <h4>{formatNumberWithCommas(tickets)}</h4>
                          <h5 className="mb-0">Ticket(s) Generated</h5>
                        </div>
                      </div>

                      {/* UPCOMING EVENTS */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i class="fas fa-eye" />
                          <h4>
                            {event?.views
                              ? formatNumberWithCommas(parseInt(event.views))
                              : "0"}
                          </h4>

                          <h5 className="mb-0">Event views</h5>
                        </div>
                      </div>

                      {/* EVENT DATES */}
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i class="fas fa-star" />
                          <h4>{event_reviews?.length}</h4>
                          <h5 className="mb-0">Attendees reviews</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex mt-4">
                      <div className=" col mb-3">
                        <div>
                          <p>Total tickets sales</p>
                          <StatisticsCircularSales
                            progress={percentage / 100}
                            size="150px"
                          />
                        </div>
                      </div>
                      <div className=" col mb-3">
                        <div>
                          <p>Total attendees check-in</p>
                          <StatisticsCircularCheckin
                            progress={scannedPercentage / 100}
                            size="150px"
                          />
                        </div>
                      </div>
                    </div>

                    {/* A GRAPH FOR TICKET SALES FOR AN EVENT BY DATE */}
                    <div className=" my-5">
                      <EventStatisitcsGraph event_id={event_id} />
                    </div>
                    {/* IT IS NOT YET STABLE */}
                    <header className="section-heading mb-3 mt-5">
                      <h4 className="title-section float-left">
                        <i className="fas fa-cart-arrow-down" />
                        {event?.event_dates?.map((event_date, index) => (
                          <div key={event?.id}>
                            {event_date?.tickets?.map((ticket) => (
                              <span key={ticket?.id} className="">
                                {ticket?.ticket_name},
                              </span>
                            ))}
                          </div>
                        ))}
                      </h4>
                      <div className="clearfix" />
                    </header>

                    <div className="d-flex row">
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i class="fas fa-money-bill" />
                          <h4>
                            <span className=" mr-2">{currency?.label}</span>{" "}
                            {formatNumberWithCommas(grossAmount)}
                          </h4>
                          <h5 className="mb-0">Gross sales</h5>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                        <div
                          className="rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-cart-arrow-down" />
                          <h4> {formatNumberWithCommas(cartItems)} </h4>
                          <h5 className="mb-0">Still in cart</h5>
                        </div>
                      </div>
                    </div>
                    <div className=" row d-flex mt-4">
                      <div className="mb-3 px-2 col">
                        <div>
                          <p>Total tickets sales</p>
                          <StatisticsCircularSales
                            progress={percentage / 100}
                            size="150px"
                          />
                        </div>
                      </div>
                      <div className="mb-3 px-2 col">
                        <div>
                          <p>Total attendees check-in</p>
                          <StatisticsCircularCheckin
                            progress={scannedPercentage / 100}
                            size="150px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
