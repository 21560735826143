import React, { useContext, useState, useEffect } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import PopingOutVenues from "./PopingOutVenues";
import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { useVenues } from "../../store/venues_store";
import { useSettings } from "../../store/setting_store";
import CustomPagination from "../../components/pagination/Pagination";
import { auth } from "../../Firebase";

export default function Myvenues() {
  const [isLoading, setIsLoading] = useState(false);

  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const getOrganizerVenues = useVenues((state) => state.getOrganizerVenues);
  const getVenues = useVenues((state) => state.getVenues);
  const venues = useVenues((state) => state.venues);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();
  const [organizerVenues, setOrganizerVenues] = useState([]);

  useEffect(() => {
    // FETCHING DATA
    const fetchData = async () => {
      getVenues();
      const venuesdata = await getOrganizerVenues(user?.user_id);
      setOrganizerVenues(venuesdata);
    };
    fetchData();
  }, [venues?.length]);

  //filtering logic
  const [venuesFilter, setVenuesFilter] = useState("");
  const filteredVenues = organizerVenues.filter((venue) => {
    return (
      !venuesFilter ||
      (venue.name &&
        venue.name.toLowerCase().includes(venuesFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedVenues = filteredVenues.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage] = useState(20);
  //get current events
  const indexOfLastvenues = currentPage * venuesPerPage;
  const indexOfFastvenues = indexOfLastvenues - venuesPerPage;
  const current_venues = sortedVenues?.slice(
    indexOfFastvenues,
    indexOfLastvenues
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"My venues"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"myvenues"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0 d-flex align-content-center justify-content-start">
                      <span className=" center-lg-y text-muted">
                        {organizerVenues.length} result(s) found
                      </span>
                    </div>
                    {/* SEARCH */}
                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="search">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="search"
                        name="search"
                        value={venuesFilter}
                        onChange={(e) => setVenuesFilter(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/addanewvenue"
                        className="btn text-white ml-3"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="tooltip"
                        title="Add a new venue"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* APP ALERTS IF THE VENUES ARE EMPTY*/}
                {organizerVenues?.length === 0 ? (
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" /> No venues found
                  </div>
                ) : (
                  // THE TABLE OF VENUES
                  <div className="table-responsive">
                    <table className="table table-hover table-vcenter text-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "30%" }}>Venue</th>
                          <th>Seating guests</th>
                          <th>Standing guests</th>
                          <th>Pricing</th>
                          <th>Venue type</th>
                          <th>Status</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {current_venues.map((venue, index) => (
                          <tr key={venue?.id}>
                            {/* VENUE */}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* VENUE IMAGE */}
                                <Link
                                  className="fancybox"
                                  href=""
                                  data-toggle="tooltip"
                                  title
                                  data-original-title="Enlarge"
                                >
                                  <img
                                    src={venue.venue_images[0]}
                                    className="img-thumbnail img-50-50"
                                    alt=""
                                  />
                                </Link>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {/* VENUE NAME */}
                                  <Link
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                    to={"/venue_details/" + venue?.id}
                                    data-toggle="tooltip"
                                    title
                                    data-original-title="View event"
                                  >
                                    {venue?.name}
                                  </Link>

                                  {/* STREET ADDRESS */}
                                  <span className="small text-muted">
                                    # {venue?.street_address}
                                  </span>
                                </div>
                              </div>
                            </td>

                            {/* STANDING GUESTS */}
                            <td>
                              <div>
                                <p>{venue?.standing_guests}</p>
                              </div>
                            </td>

                            {/* SEATING GUESTS */}
                            <td>
                              <div>
                                <p>{venue?.seated_guests}</p>
                              </div>
                            </td>

                            {/* PRICING */}
                            <td>
                              <div>
                                {venue?.pricing === null ? (
                                  <p>Free</p>
                                ) : (
                                  <p>{venue?.pricing}</p>
                                )}
                              </div>
                            </td>

                            {/* VENUE TYPE */}
                            <td>
                              <div>
                                <p>{venue?.venue_type?.label}</p>
                              </div>
                            </td>

                            {/* VENUE STATUS */}
                            <td>
                              <span
                                className={`badge badge-${
                                  venue?.isHidden === false
                                    ? "success"
                                    : "warning"
                                }`}
                              >
                                <i className="fas fa-eye fa-fw" />{" "}
                                {venue?.isHidden === false
                                  ? "Visible"
                                  : "Invisible"}
                              </span>
                            </td>
                            <td className="text-center">
                              <PopingOutVenues
                                venue_id={venue?.id}
                                setIsLoading={setIsLoading}
                                isHidden={venue?.isHidden}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            {/* PAGINATION */}
            {sortedVenues?.length > 20 && (
              <CustomPagination
                setCurrentPage={setCurrentPage}
                totalPages={Math.ceil(sortedVenues?.length / venuesPerPage)}
              />
            )}
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
