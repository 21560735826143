import React, { useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";
// import { addBlogPostCategory } from "../../../services/blogs";

export default function AddBlogPostCategory() {
  const addBlogPostCategory = useBlogs((state) => state.addBlogPostCategory);
  const layout_page = useSettings((state) => state.layout_page);
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // HANDLING UPLOADING THE BLOG POST CATEGORY
  const handleAddBlogpostCategory = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      addBlogPostCategory({
        category: category,
        added_at: Timestamp.fromDate(new Date()),
        status: "visible",
      });
      navigate("/manage_blogpost_categories");
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!category) {
      errors.category = "Category is required";
    }

    return errors;
  }

  // const {isAdmin} = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new blog post category"
          page_link="Manage Blogpost Categories"
          link="manage_blogpost_categories"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_blogpost_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="blog_post_category"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleAddBlogpostCategory}
                    >
                      <div id="blog_post_category">
                        <div className="tab-content">
                          <div
                            id="blog_post_category_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="blog_post_category_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="blog_post_category_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>

                                {errors.category && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.category}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="blog_post_category_translations_en_name"
                                  name="blog_post_category[translations][en][name]"
                                  required="required"
                                  maxLength={50}
                                  className={
                                    errors.category
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                  onChange={(event) =>
                                    setCategory(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* SAVE BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="blog_post_category_save"
                            name="blog_post_category[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="blog_post_category__token"
                          name="blog_post_category[_token]"
                          defaultValue="trnz3MnfqenzW5WcpmEtElqVv9pFc7O_hM8LA3GRdSg"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
