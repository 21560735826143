import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import {
  displayErrorToast,
  displayWarningToast,
  validationSchemaSignIn,
} from "../../services/helpers";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";
import { useFormik } from "formik";
import { auth } from "../../Firebase";

function Signin() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const login = userStore((state) => state.login);
  const layout_page = useSettings((state) => state.layout_page);
  const signInWithGoogle = userStore((state) => state.signinWithGoogle);
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);

  // CHECKING IF THE USER IS ALREADY LOGGED IN
  useEffect(() => {
    if (user) {
      if (isAttendee) {
        navigate("/dashboard", { replace: true });
      } else if (isOrganizer) {
        navigate("/dashboard_organizer");
      } else if (isAdmin) {
        navigate("/admin_dashboard");
      }
    }
  }, []);

  // Signing in the user with username and password
  const handleSignIn = async () => {
    setIsLoading(true);
    const response = await login(values.email, values.password);
    if (response != null) {
      const loggedInUser = auth.currentUser;
      const userData = response;
      if (loggedInUser.emailVerified) {
        if (response.user_type === "attendee") {
          navigate("/dashboard", { state: response });
        } else if (response.user_type === "organizer") {
          navigate("/dashboard_organizer", { state: response });
        } else if (response.user_type === "admin") {
          navigate("/admin_dashboard", { state: response });
        } else {
          console.log("ERROR: " + response);
          displayErrorToast("Couldn't Log You In");
        }
      } else {
        navigate("/check_email", {
          state: { userData },
        });
      }
    } else {
      // Handle the case when the email doesn't exist
      displayWarningToast("Email not found. Please check your credentials.");
    }
    setIsLoading(false);
  };

  //validating the form.
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchemaSignIn,
    onSubmit: handleSignIn,
  });

  // SIGNING IN THE USER WITH USERNAME AND PASSWORD
  const handleGoogleSignin = async (e) => {
    e.preventDefault();

    const response = await signInWithGoogle();

    if (response != null) {
      if (response.user_type === "attendee") {
        navigate("/dashboard", { state: response });
      } else if (response.user_type === "organizer") {
        navigate("/dashboard_organizer", { state: response });
      } else if (response.user_type === "admin") {
        navigate("/admin_dashboard", { state: response });
      } else {
        console.log("ERROR: " + response);
        displayErrorToast("Couldnt Log You In");
      }
    } else {
      displayErrorToast("Couldnt Log You In");
    }
  };

  // return loader if is loading
  if (isLoading) return <Loader />;

  return (
    <div>
      <Header />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 offset-lg-4">
              <div className="col-12 mb-5">
                <h1 className="card-title text-center">Sign in</h1>
              </div>
              <p>
                {/* LOGIN WITH FACEBOOK */}
                <a
                  href="/connect/facebook"
                  className="btn btn-block btn-facebook"
                >
                  {" "}
                  <i className="fab fa-facebook-f" /> &nbsp; Sign in via
                  Facebook
                </a>

                {/* LOGIN WITH GOOGLE */}
                <button
                  onClick={handleGoogleSignin}
                  className="btn btn-block btn-google-plus"
                >
                  {" "}
                  <i className="fab fa-google" /> &nbsp; Sign in via Google
                </button>
              </p>
              <p className="divider-text">
                <span>OR</span>
              </p>

              <form onSubmit={handleSubmit}>
                {/* CSRF */}
                <input
                  type="hidden"
                  name="_csrf_token"
                  defaultValue="0R3UUf35O5w6QeOImGaALqwXIz3sc2LsJVk1psxfle8"
                />

                {/* USERNAME */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fa fa-user" />{" "}
                      </span>
                    </div>
                    <input
                      name="email"
                      placeholder="Your Email"
                      type="text"
                      autoComplete="username"
                      className={
                        errors.email && touched.email
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.email && touched.email && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.email}
                    </p>
                  )}
                </div>

                {/* PASSWORD */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fa fa-lock" />{" "}
                      </span>
                    </div>
                    <input
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"} // Use the "showPassword" state to toggle between text and password type
                      autoComplete="current-password"
                      className={
                        errors.password && touched.password
                          ? "form-control border-end-0 border-danger"
                          : "form-control"
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div>
                      <span
                        className={
                          errors.password && touched.password
                            ? "form-control border-start-0 border-danger cursor-pointer"
                            : "form-control cursor-pointer"
                        }
                        onClick={() => setShowPassword(!showPassword)} // Toggle the "showPassword" state when the eye icon is clicked
                      >
                        <i
                          className={
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>
                  {errors.password && touched.password && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.password}
                    </p>
                  )}
                </div>

                {/* REMEBER ME */}
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="remember_me"
                      name="_remember_me"
                      defaultValue="on"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="remember_me"
                    >
                      Remember me
                    </label>
                  </div>
                </div>

                {/* SIGN IN BUTTON */}
                <div className="form-group">
                  <button
                    name="_submit"
                    disabled={isSubmitting}
                    className="btn text-white btn-block"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <>Sign in</>
                    )}
                  </button>
                </div>

                {/* FORGOT PASSWORD */}
                <p className="text-center">
                  <Link
                    to="/forgot_password"
                    style={{ color: layout_page?.primary_color }}
                  >
                    Forgot your password ?
                  </Link>
                </p>

                {/* SIGNUP */}
                <p className="text-center">
                  Not a member yet ?{" "}
                  <Link
                    to="/sign_up_attendee"
                    style={{
                      color: layout_page?.primary_color,
                    }}
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Signin;
