import React, { useEffect, useState } from "react";
import { ordersStore } from "../../../store/orders_store";
import { useEvents } from "../../../store/event_store";

const TicketsNumber = ({ event_id }) => {
  const [tickets, setTickets] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [totalEventTickets, setTotalEventTickets] = useState(0); // Initialize to 0

  // getting the ticket quantity for an event.
  const getOrderTicketsForEvent = ordersStore(
    (state) => state.getOrderTicketsForEvent
  );

  const getAllTicketQuantityEvent = useEvents(
    (state) => state.getAllNumTicketQuantityForEvent
  );

  useEffect(() => {
    async function fetchData() {
      // getting all the ticket for an event
      const t = await getOrderTicketsForEvent(event_id);
      setTickets(t);

      // getting total event tickets quantity which the user entered when adding the event
      const quantity = await getAllTicketQuantityEvent(event_id);
      setTotalEventTickets(quantity);

      // calculate percentage
      const ticketsSold = parseInt(t);
      const totalTickets = parseInt(totalEventTickets);

      if (totalTickets !== 0 && ticketsSold !== 0) {
        const soldPercentage = (ticketsSold / totalTickets) * 100;
        setPercentage(soldPercentage.toFixed(1)); // format to one decimal place
      } else {
        setPercentage(0);
      }
    }
    fetchData();
  }, [event_id, totalEventTickets?.length]); // Removed ".length" here

  return (
    <div>
      <div className="clearfix">
        <div className="float-left">
          <strong>{percentage}%</strong>
        </div>
        <div className="float-right ml-2">
          <small className="text-muted">{tickets} ticket(s) Generated</small>
        </div>
      </div>
      <div className="progress progress-xs">
        <div
          className="progress-bar bg-yellow"
          role="progressbar"
          style={{ width: `${percentage}%` }}
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  );
};

export default TicketsNumber;
