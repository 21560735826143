import React, { useEffect, useState } from "react";
import MappingVenueTables from "./MappingVenueTables";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { displayInfoToast } from "../../services/helpers";

const VenueTables = ({ venue, venue_room }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTables, setSelectedTables] = useState([]);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  //showing the calender

  const handleDateClick = () => {
    // Toggle the visibility of the calendar when the button is clicked
    setShowCalendar(!showCalendar);
  };

  //handle selected Date
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // setShowCalendar(false); // Close the calendar after selecting a date
  };

  //acall back to get selected tables
  const getSelectedTables = (data) => {
    console.log("the selected tables are:", data);
    setSelectedTables(Object.values(data));
  };

  // calculating the total amount for the tables
  const calculateTotal = () => {
    let totalAmount = 0;

    //iterate through selected tables
    selectedTables.forEach((selectedTable) => {
      //find the corresponding tableType
      const tableType = venue_room?.tableTypes?.find(
        (type) => type.table_type === selectedTable.table_type
      );

      //if the table type is found, accumulate the taotal amount

      if (tableType) {
        const price = parseInt(tableType.price);
        totalAmount += price;
      }
    });

    setTotal(totalAmount);
  };

  //call the function whenever selected tables changes

  useEffect(() => {
    calculateTotal();
  }, [selectedTables?.length]);

  //going to checkout page to pay

  const goToCheckout = async () => {
    if (selectedTables.length > 0) {
      navigate("/checkout_event_detail", {
        state: {
          selectedTables,
          selectedDate,
          total,
          venue_room,
          venue,
        },
      });
    } else {
      displayInfoToast("Please select a table to continue");
    }
  };

  return (
    <div>
      <div className="card mt-2 ">
        {" "}
        <div className=" card-body">
          <p className=" fw-bold">Tables</p>
          <div className=" d-flex my-5">
            {venue_room?.tableTypes.map((table_type) => (
              <div
                className=" d-flex align-items-center gap-2 "
                key={table_type?.id}
              >
                <div
                  className="border"
                  style={{
                    backgroundColor: table_type?.table_color,
                    height: 40,
                    width: 60,
                    borderRadius: 8,
                  }}
                ></div>
                <p className=" h6 fw-medium mr-4">
                  {table_type?.table_type}{" "}
                  <span>(UGX {table_type?.price})</span>
                </p>
              </div>
            ))}

            <span className=" d-flex align-items-center gap-2 ">
              <div
                className="border"
                style={{
                  backgroundColor: "#C8C8C8",
                  height: 40,
                  width: 60,
                  borderRadius: 8,
                }}
              ></div>
              <p className="h6 fw-medium mr-4">Booked Table</p>
            </span>
          </div>
          <div>
            <p>Please choose a date for your table to proceed</p>

            <Button
              variant="primary"
              size="sm"
              onClick={handleDateClick}
              className=" mb-3 rounded"
            >
              Choose a date
            </Button>
          </div>
          {/* calendar to select the date for tickets */}
          {showCalendar && (
            <div className="my-3">
              <Calendar
                value={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
              />
            </div>
          )}
          <div>
            <p className="">
              Select a table of your choice (Click on a table to select){" "}
            </p>
            <MappingVenueTables
              venue_room={venue_room}
              getSelectedTables={getSelectedTables}
              selectedDate={selectedDate}
            />
          </div>

          <div onClick={goToCheckout}>
            <div className=" text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout mr-3 col-sm-12 col-12 col-md-2">
              Checkout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueTables;
