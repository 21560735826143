import { Link, Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { userStore } from "../../store/user_store";
import Header from "../header/header";
import Footer from "../footer/footer";
import Sidenavbar from "../../pages/organizer_dashboard/components/sidenavbar";

import "./styles.css";
import { useSettings } from "../../store/setting_store";

export default function BeneficiariesPage() {
  const isOrganizer = userStore((state) => state.isOrganizer);
  const layout_page = useSettings((state) => state.layout_page);

  const numberOfBeneficiaries = 0;

  if (!isOrganizer) return <Navigate to="/access_denied" />;

  return (
    <div>
      <Header />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"beneficiaries"} />}
            </aside>
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card beneficiary-card">
                <div className="beneficiary-card-header">
                  <Link
                    className="btn text-white"
                    to="/beneficiaries/add"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}>
                    <i className="fa fa-plus" /> add payout beneficiary
                  </Link>
                  <input
                    disabled
                    className="input-gray"
                    placeholder={`${numberOfBeneficiaries} payout ${
                      numberOfBeneficiaries === 1
                        ? "beneficiary"
                        : "beneficiaries"
                    } found`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
