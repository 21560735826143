import React from "react";
import errorImage from "../assets/img/illustrations/404.246f114a.png";
import { useNavigate, Link } from "react-router-dom";
import { useSettings } from "../store/setting_store";

export default function PageNotFound() {
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container h-100 vh-100">
      <div className="row align-items-center h-100">
        <div className="col-lg-6 mx-auto">
          <img src={errorImage} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 mx-auto">
          <div className="jumbotron">
            <h1>404 - Page not found</h1>
            <h4>
              The requested page couldn't be located. Checkout for any URL
              misspelling or
            </h4>
            <Link
              to="/"
              className="btn text-white mt-3"
              style={{
                backgroundColor: layout_page?.primary_color,
              }}>
              <i className="fas fa-home fa-fw" /> return to the homepage
            </Link>

            <button
              type="button"
              onClick={goBack}
              className="btn btn-outline-dark mt-3 ml-3">
              <i className="fas fa-undo m" /> Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
