import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { IconPickerItem } from "react-fa-icon-picker-alen";
import PopingOutUserCategories from "./PopingOutUserCategories";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageUserCategories() {
  const isAdmin = userStore((state) => state.isAdmin);
  const getUserCategories = useBlogs((state) => state.getUserCategories);
  const userCategories = useBlogs((state) => state.userCategories);
  const layout_page = useSettings((state) => state.layout_page);

  // fetching user categories
  useEffect(() => {
    const fetchData = async () => {
      await getUserCategories();
    };
    fetchData();
  }, [userCategories.length]);

  //filtering logic
  const [userCategoriesFilter, setUserCategoriesFilter] = useState("");
  const filteredUserCategories = userCategories.filter((userCategory) => {
    return (
      !userCategoriesFilter ||
      (userCategory.name &&
        userCategory.name
          .toLowerCase()
          .includes(userCategoriesFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedUserCategories = filteredUserCategories.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [userCategoriesPerPage] = useState(20);
  //get current events
  const indexOfLastuserCategories = currentPage * userCategoriesPerPage;
  const indexOfFastuserCategories =
    indexOfLastuserCategories - userCategoriesPerPage;
  const current_userCategories = sortedUserCategories?.slice(
    indexOfFastuserCategories,
    indexOfLastuserCategories
  );

  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Help center Categories" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_user_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {userCategories.length} result(s) found
                      </span>
                    </div>

                    <div className=" d-flex align-items-center col-12 col-sm-4 text-left mb-4">
                      <label htmlFor="user_categories">Search</label>
                      <input
                        type="text"
                        className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white ml-3"
                        id="user-categories"
                        name="user_categories"
                        value={userCategoriesFilter}
                        onChange={(e) =>
                          setUserCategoriesFilter(e.target.value)
                        }
                      />
                    </div>

                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-use-category"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new help center category"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* DISPLAYING USER CATEGORIES */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr className=" " style={{ fontWeight: "500" }}>
                              <th>Name</th>
                              <th>Articles count</th>
                              <th>Icon</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_userCategories.map(
                              (userCategory, index) => (
                                <tr key={userCategory?.id}>
                                  {/* NAME */}
                                  <td>{userCategory?.name}</td>
                                  {/* ARTICLES COUNT */}
                                  <td>14</td>
                                  {/* ICON DISPLAY */}
                                  <td>
                                    <IconPickerItem
                                      icon={userCategory.icon}
                                      size={20}
                                      color="#000"
                                    />
                                  </td>
                                  {/* SHOWING STATUS */}
                                  <td>
                                    {userCategory?.status === "visible" ? (
                                      <span className=" badge badge-success">
                                        <i className="fas fa-eye fa-fw" />{" "}
                                        Visible
                                      </span>
                                    ) : (
                                      <span className=" badge badge-warning">
                                        <i className="fas fa-eye-slash fa-fw" />{" "}
                                        Invisible
                                      </span>
                                    )}
                                  </td>
                                  <td className=" text-center">
                                    {/* SHOWING POPUP */}
                                    <PopingOutUserCategories
                                      user_category_id={userCategory?.id}
                                      status={userCategory?.status}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                            {/* END OF THE USERCATEGORY */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* PAGINATION */}
                  {sortedUserCategories?.length > 20 && (
                    <CustomPagination
                      setCurrentPage={setCurrentPage}
                      totalPages={Math.ceil(
                        sortedUserCategories?.length / userCategoriesPerPage
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
