import React, { useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import app from "../../Firebase";
import { useSettings } from "../../store/setting_store";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  // const [isGoogleUser, setIsGoogleUser] = useState(false);
  const layout_page = useSettings((state) => state.layout_page);

  const handleCheckUser = async () => {
    setIsSubmitting(true);
    setError(null);
    setIsEmailValid(true);
    setIsSuccess(false);
    // setIsGoogleUser(false);

    try {
      const auth = getAuth(app);
      // Check if email has an associated account
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.length === 0) {
        setIsEmailValid(false);
      } else {
        // // Check if the user registered with Google
        // const isGoogleProvider = signInMethods.some((method) =>
        //   method.includes("google.com")
        // );

        // if (isGoogleProvider) {
        //   setIsGoogleUser(true);
        //   setIsSuccess(false);
        //   setIsSubmitting(false);
        //   return;
        // }

        await sendPasswordResetEmail(auth, email);
        setIsSuccess(true);
        setEmail("");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCheckUser();
  };

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* FORGOT PASSWORD MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  <div className="col-12 col-md-6 mx-auto">
                    <h1 className="text-center">Reset your password</h1>
                    {/* Display success or error message */}
                    {isSuccess && (
                      <p class="alert alert-success text-center" role="alert">
                        Password reset email sent, please check your email!
                      </p>
                    )}
                    {!isEmailValid && (
                      <p
                        className="alert alert-danger text-center"
                        role="alert">
                        Email is invalid or does not exist.
                      </p>
                    )}
                    {/* {isGoogleUser && (
                      <p
                        className="alert alert-danger text-center"
                        role="alert">
                        You signed in with Google. Please reset your password
                        with Google.
                      </p>
                    )} */}
                    {error && (
                      <div class="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <form
                      action="/en/resetting/send-email"
                      method="POST"
                      onSubmit={handleSubmit}>
                      <div className="form-group input-group mt-4 mb-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-0">
                            {" "}
                            <i className="fas fa-envelope" />{" "}
                          </span>
                        </div>
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Your registered email address"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn text-white btn-block"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}>
                          Reset password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
