/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import EventsSlider from "../../components/slider/slider";
import UpcomingEvents from "../../components/upcoming_events/upcoming_events";
import FeaturedCategories from "../../components/featured_categories/featured_categories";
import SubFooter from "../../components/sub_footer/sub_footer";
import Loader from "../../components/loader/loader";
import FeaturedBlogs from "../../components/featured_blogs/featured_blogs";
import { useEvents } from "../../store/event_store";
import { useSettings } from "../../store/setting_store";
import useQueryStore from "../../store/query_store";
import { userStore } from "../../store/user_store";
import { filterEvents } from "../../utils/eventQuery";

function HomePage() {
  const [isLoaded, setIsLoaded] = useState(false);

  // ZUSTAND VARIABLES
  const events = useEvents((state) => state.events);
  const getHomepageSliders = useSettings((state) => state.getHomepageSliders);
  const getUpcomingEvents = useEvents((state) => state.getUpcomingEvents);
  const searchText = useQueryStore((state) => state.searchText);
  const category = useQueryStore((state) => state.category);
  const startDate = useQueryStore((state) => state.startDate);
  const endDate = useQueryStore((state) => state.endDate);
  const country = useQueryStore((state) => state.country);
  const audienceType = useQueryStore((state) => state.audienceType);
  const clearQuery = useQueryStore((state) => state.clearQuery);
  const organizers = userStore((state) => state.organizers);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);

  useEffect(() => {
    async function handleGetSliders() {
      await getHomepageSliders();
    }
    const data = async () => {
      await getUpcomingEvents();
    };

    data();
    handleGetSliders();
    setIsLoaded(true);
  }, [events?.length]);

  const queriedEvents = useMemo(
    () =>
      filterEvents(upcomingEvents, {
        organizers,
        searchText,
        category,
        startDate,
        endDate,
        country,
        audienceType,
      }),
    [searchText, category, startDate, endDate, country, audienceType]
  );

  if (!isLoaded) {
    return <Loader />;
  }

  const isFiltering =
    searchText || category || startDate || endDate || country || audienceType
      ? true
      : false;

  const eventsToRender = isFiltering ? queriedEvents : upcomingEvents;

  return (
    <div className="app">
      <Header currentPage={"home"} />
      <EventsSlider />
      <UpcomingEvents events={eventsToRender} clearQuery={clearQuery} />
      <FeaturedCategories />
      <FeaturedBlogs />
      <SubFooter />
      <Footer />
    </div>
  );
}

export default HomePage;
