import Container from './Container';

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const googleMapsApiKey = 'AIzaSyAdVoyn6XlG7W4xH7pgkIpkIvKMhcezPVI';
const ugandaCoordinates = { lat: 1.3733, lng: 32.2903 };

export default function Map({ events }) {
  const eventLocations = events
    .filter(
      (event) =>
        event.event_dates[0].location &&
        event.event_dates[0].location.lat &&
        event.event_dates[0].location.lng
    )
    .map((event) => ({
      lat: event.event_dates[0].location.lat,
      lng: event.event_dates[0].location.lng,
    }));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  });

  if (isLoaded) {
    return (
      <Container>
        <GoogleMap
          zoom={8}
          center={ugandaCoordinates}
          mapContainerClassName="h-100 w-100"
        >
          {eventLocations.map((coordinates) => (
            <Marker
              key={`${coordinates.lat}-${coordinates.lng}`}
              position={coordinates}
            />
          ))}
        </GoogleMap>
      </Container>
    );
  }
  return <Container>Loading</Container>;
}
