import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { useVenues } from "../../store/venues_store";
import { useSettings } from "../../store/setting_store";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";
import Dropdown from "react-bootstrap/Dropdown";
import Loader from "../../components/loader/loader";
import { useState } from "react";

const PopingOut = ({ venue_id, isHidden }) => {
  const deleteVenue = useVenues((state) => state.deleteVenue);
  const layout_page = useSettings((state) => state.layout_page);
  const getHandleStatusToggle = useVenues((state) => state.handleStatusToggle);
  const [isLoading, setIsLoading] = useState(false);

  // METHOD TO HANDLE DELETING THE VENUE
  const handleDeleteVenue = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteVenue(venue_id);
    if (response) {
      displaySuccessToast("🦄 The venue has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the venue");
    }
    setIsLoading(false);
  };
  // a method to hide and display venue
  const handleIsHiddenVenue = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await getHandleStatusToggle(venue_id);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {" "}
          <Link to={"/edit_venue/" + venue_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleIsHiddenVenue(e)}
          >
            {isHidden !== true ? (
              <>
                <i className="dropdown-icon fas fa-eye-slash fa-fw text-muted" />{" "}
                <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="dropdown-icon fas fa-eye fa-fw text-muted" />{" "}
                <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <DeleteComponentModal
            itemName="Venue"
            handleDelete={handleDeleteVenue}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PopingOut;
