export default function Container({ children }) {
  return (
    <div
      style={{
        width: '100%',
        height: '500px',
        margin: '10px 0 20px 0',
      }}
    >
      {children}
    </div>
  );
}
