import {
  Timestamp,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import Loader from "../components/loader/loader";
import * as Yup from "yup";
import app from "../Firebase";

const db = getFirestore(app);

// METHOD TO FORMAT THE DATE
export function formatDate(date) {
  return date.toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  });
}

// METHOD TO FORMAT DATE IN SHORT FORMAT
export function formatDateShort(date) {
  return new Date(date).toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}
// a method to format date date with hours
export function formatDateLong(date) {
  return new Date(date).toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

// amthod to get only hours
export const formatDateTime = (date) => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

// METHOD TO DISPLAY THE LOADER
export function displayLoader() {
  return <Loader />;
}

// METHOD TO FORMAT TIME
export function formatTime(dateTime) {
  // Extract the time components from the DateTime object
  const hours = dateTime?.getHours();
  const minutes = dateTime?.getMinutes();

  // Convert the hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Determine the meridiem (AM/PM)
  const meridiem = hours >= 12 ? "PM" : "AM";

  // Format the time as HH:MM followed by the meridiem and timezone
  const formattedTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}${meridiem} EAT`;
  return formattedTime;
}

// METHOD TO GET THE DAY OF THE WEEK
export function getDay(datetime) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return daysOfWeek[datetime.getDay()];
}

// METHOD TO GET THE DAY OF THE MONTH
export function getDate(datetime) {
  return datetime.getDate();
}

// METHOD TO GET THE MONTH
export function getMonth(datetime) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[datetime.getMonth()];
}

// METHOD TO FORMAT NUMBERS
export const formatNumberWithCommas = (number) => {
  // Convert the number to a string
  let formattedNumber = number.toString();

  // Split the string into integer and decimal parts (if any)
  const parts = formattedNumber.split(".");
  let integerPart = parts[0];
  const decimalPart = parts[1];

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer and decimal parts (if any)
  if (decimalPart) {
    formattedNumber = integerPart + "." + decimalPart;
  } else {
    formattedNumber = integerPart;
  }

  return formattedNumber;
};

// // METHOD TO GENERATE BOOKING ID
// export function generateBookingId() {
//   const  maxLength = 8;
//   const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//   const random = Math.random();
//   console.log("THE RANDOM NUMBER GENERATED: ", random);

//   var code = '';

//   for (var i = 0; i < maxLength; i++) {
//     code += charSet[random.nextInt(charSet.length)];
//   }

//   return `LYXA${code}`;
// }

export function generateBookingId() {
  const maxLength = 8;
  const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const random = Math.random();
  console.log("THE RANDOM NUMBER GENERATED: ", random);

  let code = "";

  for (let i = 0; i < maxLength; i++) {
    code += charSet.charAt(Math.floor(Math.random() * charSet.length));
  }

  return `LYXA${code}`;
}

//generating unique ids
export function generateUniqueId() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 20;
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

// METHOD TO DISPLAY SUCCESS TOAST
export function displaySuccessToast(message) {
  toast.success(`✔ ${message}`, {
    position: "top-right",
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

// METHOD TO DISPLAY ERROR TOAST
export function displayErrorToast(message) {
  toast.error(`${message}`, {
    position: "top-right",
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

//METHOD TO DISPLAY WARNING TOAST
export function displayWarningToast(message) {
  toast.warn(`${message}`, {
    position: "top-right",
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

//METHOD TO DISPLAY INFO TOAST
export function displayInfoToast(message) {
  toast.info(`${message}`, {
    position: "top-right",
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

// HELPER FUNCTION TO SEND EMAILS

// FUNCTION FOR SENDING THE EMAIL
export async function sendEmail({ data, subject, email }) {
  console.log("THE RECIPIENT EMAIL IS: " + email);
  console.log("THE SUBJECT IS: " + subject);

  try {
    const jsonData = JSON.stringify({
      subject: subject,
      data: data,
      recipientEmail: email,
    });

    // var url = "http:192.168.100.124:4000/send_email";
    const url = "https://lyxaevents.com/send_email";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    });

    const responseNew = await response.json();
    console.log("EMAIL WAS SENT?: " + responseNew);
    return responseNew;
  } catch (error) {
    console.log("SOMETHING WENT WRONG SENDING THE EMAIL: " + error);
    return false;
  }
}

// METHOD TO CONVERT TIMESTAMP TO DATETIME OBJECT
export function convertTimestampToDateTime(timestamp) {
  if (!timestamp) {
    return new Date();
  }

  if (typeof timestamp === "string") {
    return new Date(timestamp);
  }

  if (isDateObject(timestamp)) return timestamp;
  return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
}

// METHOD TO CHECK IF THE VALUE IS A TIMESTAMP
export function isDateObject(value) {
  return value instanceof Date && !isNaN(value);
}

// CAPITALIZING WORDS
export function capitalizeWords(str) {
  return str
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
}

// METHOD TO REPEAT EVENT DATES
const RepeatEvents = ({
  repeat_every,
  repeat_metric,
  last_occurrence_date,
}) => {
  let days_between_occurrences = 1;

  if (repeat_metric === "days") {
    days_between_occurrences = repeat_every;
  } else if (repeat_metric === "weeks") {
    days_between_occurrences = repeat_every * 7;
  } else if (repeat_metric === "months") {
    days_between_occurrences = repeat_every * 28;
  } else if (repeat_metric === "years") {
    days_between_occurrences = repeat_every * 365;
  }

  // Calculate the number of days since the last occurrence
  const days_since_last_occurrence = Math.floor(
    (new Date() - new Date(last_occurrence_date)) / (1000 * 60 * 60 * 24)
  );

  console.log(`DAYS SINCE LAST OCCURRENCE: ${days_since_last_occurrence}`);

  // Calculate the number of occurrences that have happened since the last occurrence
  const occurrences_since_last_occurrence = Math.floor(
    days_since_last_occurrence / days_between_occurrences
  );

  console.log(
    `OCCURRENCES SINCE LAST OCCURRENCE: ${occurrences_since_last_occurrence}`
  );

  // Calculate the next occurrence date
  const next_occurrence_date = new Date(
    new Date(last_occurrence_date).getTime() +
      (occurrences_since_last_occurrence + 1) *
        days_between_occurrences *
        24 *
        60 *
        60 *
        1000
  );

  console.log(`NEXT OCCURRENCE DATE: ${next_occurrence_date}`);

  // Print the next occurrence date
  return <p>{next_occurrence_date.toLocaleString("en-US")}</p>;
};

const getCurrentDate = () => {
  const day_of_week = 4;

  // # Set the occurrence of the day of the week in the month (e.g., last Friday)
  const occurrence_of_day = -3;

  const current_date = new Date(2023, 5, 15);

  // Get the first day of the next month
  const next_month = new Date(
    current_date.getFullYear(),
    current_date.getMonth() + 1,
    1
  );

  // Get the date of the last occurrence of the day of the week in the month
  let last_occurrence = new Date(
    next_month.getFullYear(),
    next_month.getMonth(),
    0
  );
  while (last_occurrence.getDay() !== day_of_week) {
    last_occurrence.setDate(last_occurrence.getDate() - 1);
  }
  last_occurrence.setDate(last_occurrence.getDate() + occurrence_of_day * 7);

  // Print the next occurrence date
  console.log(last_occurrence.toISOString().split("T")[0]);
};

//function to generate reandom number using current date
export function generateRandomId() {
  const now = new Date();
  const year = String(now.getFullYear()).padStart(4, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");

  const random = Math.floor(Math.random() * 100);
  const randomNumber = String(random).padStart(2, "0");

  return year + month + day + hour + minute + randomNumber;
}

// Function to determine payment type based on phone number prefix
export const determinePaymentType = (phoneNumber, selectedPaymentMethod) => {
  if (selectedPaymentMethod === "Airtel or MTN") {
    // Ensure phoneNumber is a string
    const phoneNumberString = String(phoneNumber);
    // Check the prefix to determine payment type
    if (
      phoneNumberString.startsWith("75") ||
      phoneNumberString.startsWith("74") ||
      phoneNumberString.startsWith("70")
    ) {
      return "AIRTEL";
    } else if (
      phoneNumberString.startsWith("77") ||
      phoneNumberString.startsWith("78") ||
      phoneNumberString.startsWith("76")
    ) {
      return "MTN";
    } else {
      displayErrorToast("Only Airtel and MTN are accepted here.");
      return;
    }
  } else if (selectedPaymentMethod === "FlexiPay") {
    return "FLEXIPAY";
  }
};

// Define a function to format the phone number
export const formatPhoneNumber = (phoneNumber) => {
  // Ensure phoneNumber is a string
  const phoneNumberString = String(phoneNumber);

  // Remove the first digit (assuming it's a country code)
  return phoneNumberString.substring(1);
};

export const isUsernameAlreadyUsed = async (userName) => {
  const dbRef = collection(db, "users");
  const q = query(dbRef, where("username", "==", userName));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Returns true if username exists, false otherwise
};

export const isEmailAlreadyUsed = async (email) => {
  const dbRef = collection(db, "users");
  const q = query(dbRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Returns true if user email exists, false otherwise
};
export const organizerAlreadyUsed = async (organizer_name) => {
  const dbRef = collection(db, "organizers");
  const q = query(dbRef, where("organizer_name", "==", organizer_name));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Returns true if organizer_name exists, false otherwise
};
export const organizerEmailAlreadyUsed = async (email) => {
  const dbRef = collection(db, "organizers");
  const q = query(dbRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Returns true if organizer_email exists, false otherwise
};

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
export const validationSchemaOrganizer = Yup.object().shape({
  organizerName: Yup.string()
    .min(4, "Organizer name must be at least 4 characters long")
    .required("Organizer Name is required")
    .test(
      "organizer-name-exists",
      "Organizer Name is already in use",
      async function (value) {
        if (!value) return true; //skip validation if the field is empty
        const isOrganizerNameExists = await organizerAlreadyUsed(value);
        return !isOrganizerNameExists;
      }
    ),
  userName: Yup.string()
    .min(4, "Username must be at least 4 characters long")
    .required("Username is required")
    .test(
      "username-exists",
      "Username is already in use",
      async function (value) {
        if (!value) return true; // Skip validation if the field is empty
        // Use an async validation function to check if the username exists
        const isUsernameExists = await isUsernameAlreadyUsed(value);
        return !isUsernameExists;
      }
    ),
  firstName: Yup.string()
    .min(4, "First name must be at least 4 characters long")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(4, "Last name must be at least 4 characters long")
    .required("Last name is required"),

  phoneNumber: Yup.string()
    .matches(/^[0-9]{9,9}$/, "valid phone number is required")
    .test("valid-prefix", "MTN or Airtel phone number", (value) => {
      // Ensure phoneNumber is a string
      const phoneNumberString = String(value);

      // Check the prefix to determine payment type
      if (
        phoneNumberString.startsWith("75") ||
        phoneNumberString.startsWith("74") ||
        phoneNumberString.startsWith("70") ||
        phoneNumberString.startsWith("77") ||
        phoneNumberString.startsWith("78") ||
        phoneNumberString.startsWith("76")
      ) {
        return true;
      }
      return false;
    })
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .test("email-exists", "Email is already in use", async function (value) {
      if (!value) return true; // Skip validation if the field is empty
      // Use an async validation function to check if the email exists
      const isEmailExists = await organizerEmailAlreadyUsed(value);
      return !isEmailExists;
    }),
  // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  password: Yup.string()
    .min(5, "Password must be at least 5 characters long")
    .max(15, "Password cannot be longer than 15 characters")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required"),
});

//validation schema for the attendee
export const validationSchemaAttendee = Yup.object().shape({
  userName: Yup.string()
    .min(4, "Username must be at least 4 characters long")
    .required("Username is required")
    .test(
      "username-exists",
      "Username is already in use",
      async function (value) {
        if (!value) return true; // Skip validation if the field is empty
        // Use an async validation function to check if the username exists
        const isUsernameExists = await isUsernameAlreadyUsed(value);
        return !isUsernameExists;
      }
    ),
  firstName: Yup.string()
    .min(4, "First name must be at least 4 characters long")
    .required("First Name is required"),
  lastName: Yup.string()
    .min(4, "Last name must be at least 4 characters long")
    .required("Last name is required"),

  phoneNumber: Yup.string()
    .matches(/^[0-9]{9,9}$/, "valid phone number is required")
    .test("valid-prefix", "MTN or Airtel phone number", (value) => {
      // Ensure phoneNumber is a string
      const phoneNumberString = String(value);

      // Check the prefix to determine payment type
      if (
        phoneNumberString.startsWith("75") ||
        phoneNumberString.startsWith("74") ||
        phoneNumberString.startsWith("70") ||
        phoneNumberString.startsWith("77") ||
        phoneNumberString.startsWith("78") ||
        phoneNumberString.startsWith("76")
      ) {
        return true;
      }
      return false;
    })
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .test("email-exists", "Email is already in use", async function (value) {
      if (!value) return true; // Skip validation if the field is empty
      // Use an async validation function to check if the email exists
      const isEmailExists = await isEmailAlreadyUsed(value);
      return !isEmailExists;
    }),
  // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  password: Yup.string()
    .min(5, "Password must be at least 5 characters long")
    .max(15, "Password cannot be longer than 15 characters")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required"),
});
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
export const validationSchemaSignIn = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters long")
    .max(15, "Password cannot be longer than 15 characters")
    .required("Password is required"),
});
