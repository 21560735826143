import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { myContext } from "../../user_context";
import AdminSideNav from "./components/admin_side_nav";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";

export default function PayoutRequests() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/*  BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">
              Payout requests
            </h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <a href="/en" className="dark">
                    <i className="fas fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payout requests
                </li>
              </ol>
            </nav>
          </div>
        </section>

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"payout_requests"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        15 result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline">
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}>
                          <option
                            data-direction="desc"
                            data-criteria="p.createdAt"
                            value="/en/dashboard/administrator/manage-payout-requests?sort=p.createdAt&direction=desc&page=1">
                            Creation date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="p.createdAt"
                            value="/en/dashboard/administrator/manage-payout-requests?sort=p.createdAt&direction=asc&page=1">
                            Creation date (asc)
                          </option>
                          <option
                            data-direction="desc"
                            data-criteria="p.updatedAt"
                            value="/en/dashboard/administrator/manage-payout-requests?sort=p.updatedAt&direction=desc&page=1">
                            Processing date (desc)
                          </option>
                          <option
                            data-direction="asc"
                            data-criteria="p.updatedAt"
                            value="/en/dashboard/administrator/manage-payout-requests?sort=p.updatedAt&direction=asc&page=1">
                            Processing date (asc)
                          </option>
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-4 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters">
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show">
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">Reference</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white"
                              id="reference"
                              name="reference"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="eventdate">
                              Event date{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="Select a specific event date"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="eventdate"
                              name="eventdate"
                              data-url-list="/en/api/event/all/get-event-dates"
                              data-minimum-input-length={0}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="organizer">
                              Organizer{" "}
                              <i
                                className="fas fa-info-circle"
                                data-toggle="tooltip"
                                title="All payout requests for an organizer"
                              />
                            </label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="organizer"
                              name="organizer"
                              data-url-list="/en/api/get-organizers"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="datefrom">From date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="datefrom"
                              name="datefrom"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="dateto">Until date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="dateto"
                              name="dateto"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-all"
                                type="radio"
                                name="status"
                                defaultValue="all"
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-all"
                                className="custom-control-label">
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-paid"
                                type="radio"
                                name="status"
                                defaultValue={1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-paid"
                                className="custom-control-label">
                                Approved
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-waiting-for-payment"
                                type="radio"
                                name="status"
                                defaultValue={0}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-waiting-for-payment"
                                className="custom-control-label">
                                Pending
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-canceled"
                                type="radio"
                                name="status"
                                defaultValue={-1}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-canceled"
                                className="custom-control-label">
                                Canceled
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="status-failed"
                                type="radio"
                                name="status"
                                defaultValue={-2}
                                className="custom-control-input"
                              />
                              <label
                                htmlFor="status-failed"
                                className="custom-control-label">
                                Failed
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <button
                              type="submit"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Search">
                              <i className="fas fa-search" />
                            </button>
                            <button
                              type="reset"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Reset">
                              <i className="fas fa-search-minus" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <div
                          className="modal fade"
                          id="payoutRequest-cf5ac14047349c8-payment-details">
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">
                                  Payout request payment details -
                                  cf5ac14047349c8
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal">
                                  ×
                                </button>
                              </div>
                              <div className="modal-body">
                                {"{"}
                                <br />
                                "id": "PAYID-L5TY62Q3V737737ND225953H",
                                <br />
                                "links": [<br />
                                {"{"}
                                <br />
                                "rel": "self",
                                <br />
                                "href":
                                "https:\/\/api.sandbox.paypal.com\/v1\/payments\/payment\/PAYID-L5TY62Q3V737737ND225953H",
                                <br />
                                "method": "GET"
                                <br />
                                {"}"},<br />
                                {"{"}
                                <br />
                                "rel": "approval_url",
                                <br />
                                "href":
                                "https:\/\/www.sandbox.paypal.com\/cgi-bin\/webscr?cmd=_express-checkout&amp;token=EC-2FJ658205B119505R",
                                <br />
                                "method": "REDIRECT"
                                <br />
                                {"}"},<br />
                                {"{"}
                                <br />
                                "rel": "execute",
                                <br />
                                "href":
                                "https:\/\/api.sandbox.paypal.com\/v1\/payments\/payment\/PAYID-L5TY62Q3V737737ND225953H\/execute",
                                <br />
                                "method": "POST"
                                <br />
                                {"}"}
                                <br />
                                ],
                                <br />
                                "payer": {"{"}
                                <br />
                                "payment_method": "paypal"
                                <br />
                                {"}"},<br />
                                "state": "created",
                                <br />
                                "intent": "order",
                                <br />
                                "create_time": "2020-09-20T17:20:41Z",
                                <br />
                                "transactions": [<br />
                                {"{"}
                                <br />
                                "amount": {"{"}
                                <br />
                                "total": "470.00",
                                <br />
                                "currency": "USD"
                                <br />
                                {"}"},<br />
                                "related_resources": []
                                <br />
                                {"}"}
                                <br />
                                ],
                                <br />
                                "redirect_urls": {"{"}
                                <br />
                                "cancel_url":
                                "http:\/\/127.0.0.1:8000\/en\/dashboard\/administrator\/manage-payout-requests\/cf5ac14047349c8\/failed",
                                <br />
                                "return_url":
                                "http:\/\/127.0.0.1:8000\/en\/dashboard\/administrator\/manage-payout-requests\/cf5ac14047349c8\/approved"
                                <br />
                                {"}"}
                                <br />
                                {"}"}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal">
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="modal fade"
                          id="payoutRequest-d8ae966a43a5971-payment-details">
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">
                                  Payout request payment details -
                                  d8ae966a43a5971
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal">
                                  ×
                                </button>
                              </div>
                              <div className="modal-body">
                                {"{"}
                                <br />
                                "id": "PAYID-L5TUXDQ90D00378ND197080Y",
                                <br />
                                "links": [<br />
                                {"{"}
                                <br />
                                "rel": "self",
                                <br />
                                "href":
                                "https:\/\/api.sandbox.paypal.com\/v1\/payments\/payment\/PAYID-L5TUXDQ90D00378ND197080Y",
                                <br />
                                "method": "GET"
                                <br />
                                {"}"},<br />
                                {"{"}
                                <br />
                                "rel": "approval_url",
                                <br />
                                "href":
                                "https:\/\/www.sandbox.paypal.com\/cgi-bin\/webscr?cmd=_express-checkout&amp;token=EC-1NG048059H029010B",
                                <br />
                                "method": "REDIRECT"
                                <br />
                                {"}"},<br />
                                {"{"}
                                <br />
                                "rel": "execute",
                                <br />
                                "href":
                                "https:\/\/api.sandbox.paypal.com\/v1\/payments\/payment\/PAYID-L5TUXDQ90D00378ND197080Y\/execute",
                                <br />
                                "method": "POST"
                                <br />
                                {"}"}
                                <br />
                                ],
                                <br />
                                "payer": {"{"}
                                <br />
                                "payment_method": "paypal"
                                <br />
                                {"}"},<br />
                                "state": "created",
                                <br />
                                "intent": "order",
                                <br />
                                "create_time": "2020-09-20T12:31:09Z",
                                <br />
                                "transactions": [<br />
                                {"{"}
                                <br />
                                "amount": {"{"}
                                <br />
                                "total": "1600.00",
                                <br />
                                "currency": "USD"
                                <br />
                                {"}"},<br />
                                "related_resources": []
                                <br />
                                {"}"}
                                <br />
                                ],
                                <br />
                                "redirect_urls": {"{"}
                                <br />
                                "cancel_url":
                                "http:\/\/127.0.0.1:8000\/en\/dashboard\/administrator\/manage-payout-requests\/d8ae966a43a5971\/failed",
                                <br />
                                "return_url":
                                "http:\/\/127.0.0.1:8000\/en\/dashboard\/administrator\/manage-payout-requests\/d8ae966a43a5971\/approved"
                                <br />
                                {"}"}
                                <br />
                                {"}"}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal">
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table className="table table-hover table-vcenter">
                          <thead>
                            <tr>
                              <th className="text-center">Reference</th>
                              <th>Event date</th>
                              <th>Organizer</th>
                              <th>Payout method</th>
                              <th>Net sales</th>
                              <th>Request date</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                289037de4144abe
                              </td>
                              <td className="text-sm">
                                Gun Man
                                <br />
                                Fri 28 Apr 2023, 9:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1177.5</b>
                              </td>
                              <td className="text-sm">
                                Thu 14 Jan 2021, 1:08 PM EST
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 21 Jan 2021, 2:46 AM EST"
                                />
                                <i
                                  className="far fa-comment-dots text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Note: Automatically canceled before deletion"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <Link
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/289037de4144abe/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/289037de4144abe/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                fd295d94450aa4a
                              </td>
                              <td className="text-sm">
                                SuperPower
                                <br />
                                Tue 28 Mar 2023, 9:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$3956</b>
                              </td>
                              <td className="text-sm">
                                Tue 12 Jan 2021, 12:53 PM EST
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 14 Jan 2021, 1:05 PM EST"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/fd295d94450aa4a/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/fd295d94450aa4a/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                64d1db77461dbbb
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Thu 16 Mar 2023, 5:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1857</b>
                              </td>
                              <td className="text-sm">
                                Mon 11 Jan 2021, 12:43 AM EST
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 14 Jan 2021, 1:05 PM EST"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/64d1db77461dbbb/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/64d1db77461dbbb/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                be3a752c43e5a04
                              </td>
                              <td className="text-sm">
                                Gun Man
                                <br />
                                Fri 28 Apr 2023, 9:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1177.5</b>
                              </td>
                              <td className="text-sm">
                                Mon 04 Jan 2021, 2:11 PM EST
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 14 Jan 2021, 1:06 PM EST"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/be3a752c43e5a04/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/be3a752c43e5a04/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                278102ca43fb869
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Thu 16 Mar 2023, 5:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1857</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 1:32 PM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 30 Dec 2021, 10:39 PM EST"
                                />
                                <i
                                  className="far fa-comment-dots text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Note: Automatically canceled before deletion"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/278102ca43fb869/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/278102ca43fb869/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                cf5ac14047349c8
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Thu 16 Mar 2023, 5:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1857</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 1:14 PM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Failed
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Sun 20 Sep 2020, 1:32 PM EDT"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/cf5ac14047349c8/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      className="dropdown-item cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#payoutRequest-cf5ac14047349c8-payment-details">
                                      <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />{" "}
                                      Payment details
                                    </span>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/cf5ac14047349c8/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                9e3b66234bb4936
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Thu 16 Mar 2023, 5:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$1857</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 12:53 PM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Failed
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Sun 20 Sep 2020, 12:55 PM EDT"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/9e3b66234bb4936/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      className="dropdown-item cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#payoutRequest-9e3b66234bb4936-payment-details">
                                      <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />{" "}
                                      Payment details
                                    </span>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/9e3b66234bb4936/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                d8ae966a43a5971
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Thu 23 Mar 2023, 4:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/paypal.aa9749d2.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$2040</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 8:30 AM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Failed
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Sun 20 Sep 2020, 8:32 AM EDT"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/d8ae966a43a5971/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      className="dropdown-item cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#payoutRequest-d8ae966a43a5971-payment-details">
                                      <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />{" "}
                                      Payment details
                                    </span>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/d8ae966a43a5971/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                87eab9764156b80
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Fri 17 Mar 2023, 1:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/stripe.77c6af28.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$448.6</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 6:41 AM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Sun 17 Jan 2021, 8:52 PM EST"
                                />
                                <i
                                  className="far fa-comment-dots text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Note: Automatically canceled before deletion"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/87eab9764156b80/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/87eab9764156b80/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center text-muted text-sm">
                                971523e54d83bbe
                              </td>
                              <td className="text-sm">
                                Adventure In The Jungle
                                <br />
                                Fri 17 Mar 2023, 1:00 PM EDT
                              </td>
                              <td className="text-sm">Movies Expert</td>
                              <td className="text-sm">
                                <img
                                  src="/assets/img/icons/payment/stripe.77c6af28.svg"
                                  className="img-80-80"
                                />
                              </td>
                              <td className="text-sm">
                                <b>$448.6</b>
                              </td>
                              <td className="text-sm">
                                Sun 20 Sep 2020, 6:28 AM EDT
                              </td>
                              <td className="text-sm">
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                                <i
                                  className="far fa-clock text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Processed: Thu 14 Jan 2021, 1:13 PM EST"
                                />
                                <i
                                  className="far fa-comment-dots text-primary ml-1"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Note: Automatically canceled before deletion"
                                />
                              </td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon">
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/manage-payout-requests/971523e54d83bbe/details"
                                      className="dropdown-item">
                                      <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
                                      Details{" "}
                                    </a>
                                    <span
                                      data-target="/en/dashboard/administrator/manage-payout-requests/971523e54d83bbe/delete"
                                      className="dropdown-item requires-confirmation"
                                      data-confirmation-text="You are about to delete the payout request and all its related information">
                                      <i className="dropdown-icon fas fa-times fa-fw text-muted" />{" "}
                                      Delete{" "}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <nav className="pt-3">
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                          <span className="page-link">
                            <i className="fas fa-angle-left" />
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="/en/dashboard/administrator/manage-payout-requests?page=2">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            rel="next"
                            href="/en/dashboard/administrator/manage-payout-requests?page=2">
                            <i className="fas fa-angle-right" />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
