// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input {
  transform: scale(1.3);
  background-color: #dee2e6;
}
.form-check-input:checked {
  /* background-color: #f67611; */
  border-color: #f67611;
}
`, "",{"version":3,"sources":["webpack://./src/components/side_nav/side_nav.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,+BAA+B;EAC/B,qBAAqB;AACvB","sourcesContent":[".form-check-input {\r\n  transform: scale(1.3);\r\n  background-color: #dee2e6;\r\n}\r\n.form-check-input:checked {\r\n  /* background-color: #f67611; */\r\n  border-color: #f67611;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
