import { useState } from "react";

import { isMobile } from "react-device-detect";

import AddBeneficiaryBankAccount from "./AddBeneficiaryBankAccount";
import AddBeneficiaryPhoneNumber from "./AddBeneficiaryPhoneNumber";
import Sidenavbar from "../../pages/organizer_dashboard/components/sidenavbar";
import Header from "../header/header";
import Footer from "../footer/footer";

import "./styles.css";
import Select from "./Select";
import { useSettings } from "../../store/setting_store";

const data = [
  { value: 1, label: "Bank Account" },
  { value: 2, label: "Mobile Money" },
];

export default function AddBeneficiary() {
  const [value, setValue] = useState(1);
  const isAccountNumber = value === 1;

  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div>
      <Header />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"beneficiaries"} />}
            </aside>
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card beneficiary-card">
                <div className="add-beneficiary-form-header">
                  <h4>Create payout beneficiary</h4>
                  <Select
                    placeholder="Payment method"
                    label="Payment method"
                    data={data}
                    defaultValue={1}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                <form
                  onClick={(e) => e.preventDefault()}
                  className="add-beneficiary-form">
                  {isAccountNumber ? (
                    <AddBeneficiaryBankAccount />
                  ) : (
                    <AddBeneficiaryPhoneNumber />
                  )}
                  <button
                    className="btn text-white add-beneficiary-btn"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}>
                    create payout beneficiary
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
