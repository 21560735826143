import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function EventsList() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const event_settings_page = useSettings((state) => state.event_settings_page);
  const layout_page = useSettings((state) => state.layout_page);
  console.log("the page is:", event_settings_page);
  const updateEventSettingsPage = useSettings(
    (state) => state.updateEventSettingsPage
  );
  const getEventSettingsPageId = useSettings(
    (state) => state.getEventSettingsPageId
  );
  const navigate = useNavigate();

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState(null);
  const [form, setForm] = useState(event_settings_page || {});

  useEffect(() => {
    const getID = async () => {
      const getId = await getEventSettingsPageId();
      setID(getId);
    };
    getID();
  }, []);

  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //update the checkout page
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateEventSettingsPage(id, {
        events_per_page: form?.events_per_page,
        show_audience_filter: form?.show_audience_filter,
        show_calender_button: form?.show_calender_button,
        show_category_filter: form?.show_category_filter,
        show_date_filter: form?.show_date_filter,
        show_location_filter: form?.show_location_filter,
        show_map_button: form?.show_map_button,
        show_rss_button: form?.show_rss_button,
        show_ticket_price_filter: form?.show_ticket_price_filter,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("error occurred:", error);
      displayErrorToast("Some thing is wrong");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Events List Page settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"events_list"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="events_list_page_settings"
                      method="post"
                      onSubmit={handleUpdate}
                      noValidate="novalidate"
                    >
                      <div id="form">
                        <div className="form-group">
                          <label
                            htmlFor="form_events_per_page"
                            className="required"
                          >
                            Number of events per page
                          </label>
                          <input
                            type="text"
                            id="form_events_per_page"
                            name="events_per_page"
                            required="required"
                            className="touchspin-integer form-control"
                            value={form?.events_per_page || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show map button
                          </legend>
                          <div id="form_show_map_button">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_map_button_0"
                                name="show_map_button"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_map_button === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_map_button_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_map_button_1"
                                name="show_map_button"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_map_button === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_map_button_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show calendar button
                          </legend>
                          <div id="form_show_calendar_button">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_calendar_button_0"
                                name="show_calender_button"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_calender_button === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_calendar_button_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_calendar_button_1"
                                name="show_calender_button"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_calender_button === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_calendar_button_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show RSS feed button
                          </legend>
                          <div id="form_show_rss_feed_button">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_rss_feed_button_0"
                                name="show_rss_button"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_rss_button === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_rss_feed_button_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_rss_feed_button_1"
                                name="show_rss_button"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_rss_button === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_rss_feed_button_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show category filter
                          </legend>
                          <div id="form_show_category_filter">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_category_filter_0"
                                name="show_category_filter"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_category_filter === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_category_filter_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_category_filter_1"
                                name="show_category_filter"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_category_filter === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_category_filter_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show location filter
                          </legend>
                          <div id="form_show_location_filter">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_location_filter_0"
                                name="show_location_filter"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_location_filter === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_location_filter_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_location_filter_1"
                                name="show_location_filter"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_location_filter === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_location_filter_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show date filter
                          </legend>
                          <div id="form_show_date_filter">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_date_filter_0"
                                name="show_date_filter"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_date_filter === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_date_filter_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_date_filter_1"
                                name="show_date_filter"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_date_filter === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_date_filter_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show ticket price filter
                          </legend>
                          <div id="form_show_ticket_price_filter">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_ticket_price_filter_0"
                                name="show_ticket_price_filter"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={
                                  form?.show_ticket_price_filter === true
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_ticket_price_filter_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_ticket_price_filter_1"
                                name="show_ticket_price_filter"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={
                                  form?.show_ticket_price_filter === false
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_ticket_price_filter_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show audience filter
                          </legend>
                          <div id="form_show_audience_filter">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_audience_filter_0"
                                name="show_audience_filter"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={form?.show_audience_filter === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_audience_filter_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="form_show_audience_filter_1"
                                name="show_audience_filter"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={form?.show_audience_filter === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_audience_filter_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="form_save"
                            name="form[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="form__token"
                          name="form[_token]"
                          defaultValue="S8sDVARyvUHuIizhmbdqdRQfZ6bKo1JLkwngp8e_DrQ"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
