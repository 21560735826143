import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { useBlogs } from "../../../store/blog_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutUserCategories = ({ user_category_id, status }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteUserCategory = useBlogs(
    (state) => state.deleteUserHelperCategory
  );
  const updateUserCategories = useBlogs((state) => state.updateUserCategories);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE AMENITY
  async function handleDeleteUserCategory(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteUserCategory(user_category_id);
    if (response) {
      displaySuccessToast("The user category has been deleted succeesfully");
    } else {
      displayErrorToast(
        "Something went wrong while deleting the user category"
      );
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }

  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateUserCategories(user_category_id, {
      status: status === "visible" ? "invisible" : "visible",
    });
    if (status === "invisible") {
      setIsLoading(false);
      displaySuccessToast("The user category has been published");
    } else {
      setIsLoading(false);
      displayWarningToast(
        "he user category has been hidden and it will not be viewed"
      );
    }
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT CATEGORY */}
          <Link to={"/edit_user_category/" + user_category_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {status === "visible" ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>{" "}
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE USER CATEGORY */}
          <DeleteComponentModal
            itemName="User Category"
            handleDelete={handleDeleteUserCategory}
          />{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutUserCategories;
