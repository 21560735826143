import React, { useEffect, useState } from "react";
import { ordersStore } from "../../store/orders_store";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/setting_store";

const OrganizerName = ({ event_id, className }) => {
  const getOrganizers = ordersStore((state) => state.getOrganizerDetail);
  const layout_page = useSettings((state) => state.layout_page);

  const [organizer, setOrganizer] = useState([]);

  useEffect(() => {
    const fetchOrganizer = async () => {
      const organizerDetails = await getOrganizers(event_id);
      setOrganizer(organizerDetails);
    };
    fetchOrganizer();
  }, [event_id]);
  return (
    <>
      <Link
        style={{
          color: layout_page?.primary_color,
        }}
        to={"/organizer_details/" + organizer?.id}
        className={className}
      >
        {organizer?.organizer_name}
      </Link>
    </>
  );
};

export default OrganizerName;
