import React, { useState } from 'react';

function ReadMore({ text, maxLength }) {
  const [isTruncated, setIsTruncated] = useState(true);

  const resultString = isTruncated ? text?.slice(0, maxLength) : text;

  return (
    <div>
      <p>{resultString}</p>
      {text?.length > maxLength && (
        <button onClick={() => setIsTruncated(!isTruncated)}>
          {isTruncated ? 'Read more' : 'Show less'}
        </button>
      )}
    </div>
  );
}

export default ReadMore;
