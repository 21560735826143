import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../../../services/helpers";
import { error } from "jquery";
import { useEvents } from "../../../store/event_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";
const PopingOutCategories = ({ category_id, isVisible, featured }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteCategory = useEvents((state) => state.deleteCategory);

  const updateEventCategory = useEvents((state) => state.updateEventCategory);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE EVENT
  async function handleDeleteCategory(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteCategory(category_id);
    if (response) {
      displaySuccessToast("🦄 The category has been deleted succeesfully");
    } else {
      displayErrorToast(
        "Something went wrong while deleting the category",
        error
      );
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }

  //handle toggle of categories from visible to invisible vice versa
  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateEventCategory(category_id, {
      isVisible: isVisible === false ? true : false,
    });
    if (isVisible === false) {
      setIsLoading(false);
      displaySuccessToast("The category has been made visible succeesfully");
    } else {
      setIsLoading(false);
      displayWarningToast(
        "The category has been made invisible and it will not be seen"
      );
    }
  };

  //handle toggle of featured categories
  const handleFeatured = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateEventCategory(category_id, {
      isFeatured: featured === true ? false : true,
    });
    if (featured === false) {
      setIsLoading(false);
      displaySuccessToast("The category has been featured succeesfully");
    } else {
      setIsLoading(false);
      displayWarningToast("The category has been removed from featured");
    }
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT CATEGORY */}

          <Link to={"/edit_category/" + category_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* MARK AS FEATURED */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleFeatured(e)}
          >
            {featured === false ? (
              <>
                <i class="fas fa-solid fa-star fa-fw"></i>
                <h6>Mark as Featured</h6>
              </>
            ) : (
              <>
                <i class="fas fa-solid fa-thumbtack fa-fw"></i>
                <h6>Mark not as Featured</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}

          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {isVisible === true ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className=" fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE CATEGORY */}
          <DeleteComponentModal
            itemName="Event Category"
            handleDelete={handleDeleteCategory}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutCategories;
