import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { useNavigate, useParams } from "react-router-dom";
import { IconPicker } from "react-fa-icon-picker-alen";
import "../events/icon_style.css";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditAmmenity() {
  const navigate = useNavigate();
  const updateAmmenities = useVenues((state) => state.updateAmmenities);
  const isAdmin = userStore((state) => state.isAdmin);
  const layout_page = useSettings((state) => state.layout_page);
  // HANDLING AMMENITY ADD UP
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { ammenity_id } = useParams();
  const { document } = useFetchDocument("amenities", ammenity_id);
  const [ammenityForm, setAmmenityForm] = useState(document || {});

  useEffect(() => {
    setAmmenityForm(document);
  }, [document]);

  //edit form
  const handleEditAmmenity = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateAmmenities(ammenity_id, {
          name: ammenityForm?.name,
          icon: ammenityForm?.icon,
        });
        displaySuccessToast("Update Successful");
        navigate("/manage_amenities");
      } catch (error) {
        displayErrorToast("Update failed", error);
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  //handle change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAmmenityForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function validateForm() {
    const errors = {};
    if (!ammenityForm?.name) {
      errors.name = "name is required";
    }
    if (!ammenityForm?.icon) {
      errors.icon = "icon is required";
    }
    return errors;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit Amenity"
          page_link="Manage amenities"
          link="manage_amenities"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_amenities"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="amenity"
                      onSubmit={handleEditAmmenity}
                      noValidate="novalidate"
                    >
                      <div id="amenity">
                        <fieldset className="form-group">
                          {/* NAME FIELDS */}
                          <div
                            id="name"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="amenity_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="amenity_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>
                                {errors.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.name}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  name="name"
                                  value={ammenityForm?.name}
                                  onChange={(e) => handleInputChange(e)}
                                  required="required"
                                  maxLength={50}
                                  className={
                                    errors.ammenityForm?.name
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {/* SELECTING ICON */}
                        <div className="form-group d-grid">
                          <label htmlFor="amenity_icon" className="required">
                            Icon
                          </label>
                          {errors.icon && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.icon}
                            </span>
                          )}
                          <IconPicker
                            name="icon"
                            value={ammenityForm?.icon} // Set the value of the IconPicker to the icon value from form_categories state
                            onChange={(v) =>
                              setAmmenityForm((prevFormData) => ({
                                ...prevFormData,
                                icon: v, // Update the icon value in the form_categories state
                              }))
                            }
                          />
                        </div>

                        {/* SUBMIT BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
