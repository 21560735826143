import React, { useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import { Typography } from "@mui/material";
import ModalDialog from "@mui/joy/ModalDialog";
import { isMobile } from "react-device-detect";
import { userStore } from "./../../store/user_store";
import { ordersStore } from "../../store/orders_store";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  formatDate,
} from "../../services/helpers";
import OrganizerName from "./OrganizerName";
import { FaTicketAlt } from "react-icons/fa";
import Loader from "../../components/loader/loader";
import Sidenavbar from "../organizer_dashboard/components/sidenavbar";
import AdminSideNav from "../admin_dashboard/components/admin_side_nav";
import { useSettings } from "../../store/setting_store";
import { useEvents } from "../../store/event_store";
import OrganizerButton from "./components/OrganizerButton";

export default function TicketDetails() {
  const [order, setOrder] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const isAttendee = userStore((state) => state.isAttendee);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);
  const user = userStore((state) => state.user);
  const getOrder = ordersStore((state) => state.getOrder);
  const deleteOrder = ordersStore((state) => state.deleteOrder);
  const layout_page = useSettings((state) => state.layout_page);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  const fetchOrders = ordersStore((state) => state.fetchOrders);

  const { booking_id } = useParams();
  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();
  //getting only the tickets of the specific organizer
  const [eventTickets, setEventTickets] = useState([]);
  useEffect(() => {
    // GETTING THE ORDER DETAILS
    async function handleGetOrderDetails() {
      //getting all the orders
      await fetchOrders();
      //getting specific order
      const response = await getOrder(booking_id);
      const tickets = response.order_tickets;
      setOrder(response);

      //getting organizer events
      const organizerEvents = await getOrganizerEvents(user?.user_id);
      //getting the event tickets of the the logged in organizer
      const organizerTickets = [];
      // looping through the organizer events
      for (let i = 0; i < organizerEvents.length; i++) {
        // looping through the order tickets
        for (let j = 0; j < tickets.length; j++) {
          // checking if the event for the ticket belongs to this organizer
          if (tickets[j].event_id === organizerEvents[i].id) {
            organizerTickets.push(tickets[j]);
          }
        }
      }
      // updating the order tickets to only those for events of this organizer
      response.tickets = organizerTickets;
      setEventTickets(organizerTickets);
    }
    // CALLING THE METHOD
    handleGetOrderDetails();
    // Set isLoading to false after 2 seconds (2000 milliseconds)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [booking_id, isAdmin, isOrganizer, isAttendee]);

  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteOrder() {
    setOpen(false);
    setIsLoading(true);
    const response = await deleteOrder(booking_id);
    if (response) {
      setIsLoading(false);
      console.log("The order has been deleted successfully");
      displaySuccessToast("🦄 The order has been deleted ");
      navigate("/manage_orders");
    } else {
      console.log("SOMETHING WENT WRONG DELETING THE ORDER");
      displayErrorToast("Something went wrong while deleting the order");
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header currentPage={"tickets"} />

      {/* ORDER DETAILS BREADCRUMP */}
      <section className="section-pagetop bg-gray">
        <div className="container clearfix">
          <h4 className="title-page dark b float-xl-left mb-0">
            {isOrganizer ? <>Attendees</> : <>Order details</>}
          </h4>
          <nav className="float-xl-right mt-2 mt-xl-0">
            <ol className="breadcrumb text-white">
              <li className="breadcrumb-item">
                <Link
                  style={{
                    color: layout_page?.primary_color,
                  }}
                  to="/"
                  className="dark"
                >
                  <i className="fas fa-home" />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  style={{
                    color: layout_page?.primary_color,
                  }}
                  to="/dashboard"
                  className="dark"
                >
                  Dashboard
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  style={{
                    color: layout_page?.primary_color,
                  }}
                  to="/dashboard"
                  className="dark"
                >
                  {isAdmin && <>Manage orders</>}
                  {isOrganizer && <>Manage orders</>}

                  {isAttendee && <>My tickets</>}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {isOrganizer ? <>Attendees</> : <> Order details</>}
              </li>
            </ol>
          </nav>
        </div>
      </section>

      {/* MAIN TICKETS BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {/* ATTENDEE SIDE NAV */}
            {isAttendee && (
              <>{!isMobile && <AttendeeSideNav activeTab={"tickets"} />}</>
            )}

            {/* ORGANIZER NAV */}
            {isOrganizer && (
              <>
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar currentPage={"my_reports"} />}
                </aside>
              </>
            )}
            {/* ADMIN NAV */}
            {isAdmin && (
              <>
                <AdminSideNav currentPage={"manage_orders"} />
              </>
            )}

            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="">
                        <span
                          style={{ marginRight: 5 }}
                          className={
                            order?.status === "pending"
                              ? "badge badge-warning"
                              : "badge badge-success "
                          }
                        >
                          {/* ORDER STATUS */}
                          {order?.status === "pending"
                            ? "Awaiting payment"
                            : "Succesful"}
                        </span>
                        {/* ORDER DATE */}
                        Order #{order?.booking_id} placed on{" "}
                        {formatDate(
                          convertTimestampToDateTime(order?.created_at)
                        )}
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-12 ">
                          <div className="card">
                            {/* USER DETAILS */}
                            <div className="card-header fw-bold">Attendee</div>
                            <div className="card-body p-4">
                              {/* USER FULLNAME */}
                              <div>
                                <span
                                  className="avatar mr-2"
                                  style={{
                                    background: `url("${"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}")`,
                                  }}
                                ></span>
                                {order?.customer_name}

                                {!isAttendee && (
                                  <div className="mt-2 d-flex flex-column gap-1">
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="fw-bold"
                                        style={{ width: 120 }}
                                      >
                                        Full Name:
                                      </span>
                                      <span
                                        className="fw-normal"
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                      >
                                        {order?.customer_name}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center">
                                      <span
                                        className="fw-bold"
                                        style={{ width: 120 }}
                                      >
                                        Phone Number:
                                      </span>
                                      <span
                                        className="fw-normal"
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                      >
                                        {order?.customer_number}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <span
                                        className="fw-bold"
                                        style={{ width: 120 }}
                                      >
                                        Email:
                                      </span>
                                      <span
                                        className="fw-normal"
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                      >
                                        {order?.customer_email}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-hover shopping-cart-wrap">
                              <thead className="text-muted">
                                <tr>
                                  <th scope="col">Event / Ticket</th>
                                  <th scope="col" width={100}>
                                    Price
                                  </th>
                                  <th scope="col" width={100}>
                                    Quantity
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-right"
                                    width={100}
                                  >
                                    Subtotal
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {order?.order_tickets &&
                                order?.order_tickets.length > 0 ? (
                                  Object.values(
                                    isOrganizer
                                      ? eventTickets?.reduce((acc, ticket) => {
                                          const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                          if (!acc[key]) {
                                            acc[key] = [];
                                          }
                                          acc[key].push(ticket);
                                          return acc;
                                        }, {})
                                      : order?.order_tickets?.reduce(
                                          (acc, ticket) => {
                                            const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                            if (!acc[key]) {
                                              acc[key] = [];
                                            }
                                            acc[key].push(ticket);
                                            return acc;
                                          },
                                          {}
                                        )
                                  ).map((tickets) => {
                                    const event = tickets[0]; // Assuming all tickets in the group belong to the same event
                                    const totalPrice =
                                      tickets.length * event.ticket_price;

                                    // Render the content
                                    return (
                                      <>
                                        <tr key={event.event_id}>
                                          <td>
                                            <figure className="media h-100">
                                              <div className="img-wrap my-auto">
                                                {/* IMAGE OF THE EVENT */}
                                                <img
                                                  src={
                                                    event?.event_image ||
                                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
                                                  }
                                                  className="img-thumbnail img-sm img-lazy-load b-loaded"
                                                  alt=""
                                                />
                                              </div>
                                              <figcaption className="media-body my-auto ">
                                                <Link
                                                  style={{
                                                    color:
                                                      layout_page?.primary_color,
                                                  }}
                                                  to={
                                                    "/event_details/" +
                                                    event?.event_id
                                                  }
                                                >
                                                  <h6 className="title mb-2">
                                                    {event?.event_name}
                                                  </h6>
                                                </Link>
                                                <dl className="dlist-inline small">
                                                  <dt />
                                                  <dd className="mr-0">
                                                    {event?.ticket_name}
                                                    <i
                                                      className="fas fa-info-circle ml-2"
                                                      style={{
                                                        color:
                                                          layout_page?.primary_color,
                                                      }}
                                                      data-toggle="tooltip"
                                                      title=""
                                                      data-original-title="Get a front seat and a popcorn bag"
                                                    />
                                                  </dd>
                                                </dl>
                                                <dl className="dlist-inline small">
                                                  <dt className="mr-2">
                                                    When:{" "}
                                                  </dt>
                                                  <dd>
                                                    {formatDate(
                                                      convertTimestampToDateTime(
                                                        event?.event_start_date
                                                      )
                                                    )}
                                                  </dd>
                                                </dl>
                                                <dl className="dlist-inline small">
                                                  <dt className="mr-2">
                                                    Where:{" "}
                                                  </dt>
                                                  <dd>{event?.venue}</dd>
                                                </dl>
                                                <dl className="dlist-inline small">
                                                  <dt className="mr-2">
                                                    Organizer:{" "}
                                                  </dt>
                                                  <dd>
                                                    <OrganizerName
                                                      event_id={event?.event_id}
                                                    />
                                                  </dd>
                                                </dl>
                                              </figcaption>
                                            </figure>
                                          </td>

                                          {/* TICKET PRICE */}
                                          <td>
                                            <div
                                              style={{
                                                color:
                                                  layout_page?.primary_color,
                                              }}
                                              className="price-wrap"
                                            >
                                              UGX {event?.ticket_price}
                                            </div>
                                          </td>

                                          {/* NUMBER OF TICKETS */}
                                          <td>{tickets?.length}</td>

                                          {/* TOTAL AMOUNT */}
                                          <td className="text-right">
                                            <div className="price-wrap">
                                              <span
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                className="price"
                                              >
                                                UGX {totalPrice}
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        {isAttendee && (
                                          <tr>
                                            <td colSpan={4}>
                                              {/* BUY MORE TICKETS */}
                                              <Link
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                to="/browse_events"
                                                className="btn btn-default btn-sm"
                                              >
                                                <i className="fas fa-ticket-alt mr-1" />
                                                Buy more tickets
                                              </Link>
                                              <Link
                                                style={{
                                                  color:
                                                    layout_page?.primary_color,
                                                }}
                                                to={
                                                  "/write_a_review/" +
                                                  event?.event_id
                                                }
                                                className="btn btn-default btn-sm ml-3"
                                              >
                                                <i className="far fa-star mr-1" />
                                                Leave a review
                                              </Link>
                                              {/* button */}
                                              <OrganizerButton
                                                event_id={event?.event_id}
                                              />
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={5}>No tickets found.</td>
                                  </tr>
                                )}
                              </tbody>

                              <tfoot>
                                {isAdmin && (
                                  <tr>
                                    <td className="pt-5 d-flex " colSpan={4}>
                                      {isAdmin && (
                                        <Link
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                          to="/manage_orders"
                                        >
                                          <button
                                            id="order-cancel-button"
                                            data-target="/en/dashboard/administrator/manage-orders/3b1cbdc34dd18fd/cancel"
                                            className="btn btn-outline-primary mr-3 requires-confirmation"
                                            data-confirmation-text="You are about to cancel this order (this action cannot be undone)"
                                          >
                                            <i className="fas fa-window-close fa-fw" />{" "}
                                            Cancel
                                          </button>
                                        </Link>
                                      )}

                                      <button
                                        id="order-cancel-button"
                                        className="btn text-white mr-3 requires-confirmation"
                                        style={{
                                          backgroundColor:
                                            layout_page?.primary_color,
                                        }}
                                      >
                                        <FaTicketAlt
                                          size={20}
                                          className=" mr-2"
                                        />
                                        Print Tickets
                                      </button>

                                      {isAdmin && (
                                        <div>
                                          <div onClick={() => setOpen(true)}>
                                            <button
                                              data-target="/en/dashboard/administrator/manage-orders/3b1cbdc34dd18fd/delete"
                                              className="btn btn-outline-primary mr-3 requires-confirmation"
                                              data-confirmation-text="You are about to delete the order and all its related information (payment details, tickets...)"
                                            >
                                              <i className="fas fa-times fa-fw" />{" "}
                                              Delete
                                            </button>
                                          </div>

                                          <Modal
                                            open={open}
                                            onClose={() => setOpen(false)}
                                          >
                                            <ModalDialog
                                              aria-labelledby="nested-modal-title"
                                              aria-describedby="nested-modal-description"
                                              sx={(theme) => ({
                                                [theme.breakpoints.only("xs")]:
                                                  {
                                                    top: "unset",
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    borderRadius: 0,
                                                    transform: "none",
                                                    maxWidth: "unset",
                                                  },
                                              })}
                                            >
                                              <Typography
                                                id="nested-modal-title"
                                                component="h2"
                                              >
                                                Are you sure you want to delete
                                                this order?
                                              </Typography>
                                              <Box
                                                sx={{
                                                  mt: 3,
                                                  display: "flex",
                                                  gap: 3,
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  flexDirection: {
                                                    xs: "column",
                                                    sm: "row-reverse",
                                                  },
                                                }}
                                              >
                                                <Button
                                                  className=" bg-danger"
                                                  onClick={(e) =>
                                                    handleDeleteOrder(e)
                                                  }
                                                >
                                                  Continue
                                                </Button>
                                                <Button
                                                  onClick={() => setOpen(false)}
                                                >
                                                  Cancel
                                                </Button>
                                              </Box>
                                            </ModalDialog>
                                          </Modal>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {isAttendee && (
                                  <>
                                    {/* SUB TOTAL */}
                                    <tr>
                                      <td colSpan={5} className="text-right">
                                        <div className="price-wrap">
                                          <span
                                            style={{
                                              color: layout_page?.primary_color,
                                            }}
                                            className="price"
                                          >
                                            Tickets: UGX {total} (
                                            {order?.order_tickets?.length}{" "}
                                            ticket(s))
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    {/* FEES */}
                                    <tr>
                                      <td colSpan={5} className="text-right">
                                        <div className="price-wrap">
                                          <span
                                            style={{
                                              color: layout_page?.primary_color,
                                            }}
                                            className="price"
                                          >
                                            Fees: UGX 0
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* TOTAL */}
                                    <tr>
                                      <td colSpan={5} className="text-right">
                                        <div className="price-wrap">
                                          <span
                                            style={{
                                              color: layout_page?.primary_color,
                                            }}
                                            className="price"
                                          >
                                            Total: <strong>UGX {total}</strong>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className="pt-5 text-right"
                                        colSpan={4}
                                      ></td>
                                    </tr>
                                  </>
                                )}
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
