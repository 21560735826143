/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import Loader from "../../components/loader/loader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../components/text_editor/text_editor";
import TagsInput from "react-tagsinput";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import { isMobile } from "react-device-detect";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
} from "../../services/helpers";
import { Timestamp } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userStore } from "../../store/user_store";
import { useEvents } from "./../../store/event_store";
import AdminSideNav from "../admin_dashboard/components/admin_side_nav";
import { useVenues } from "../../store/venues_store";
import { Switch } from "@mui/material";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function EditEvent() {
  const { state } = useLocation();
  var { event_data } = state ?? {};
  const navigate = useNavigate();
  const { event_id } = useParams();

  // ZUSTAND VARIABLES
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAttendee = userStore((state) => state.isAttendee);
  const isAdmin = userStore((state) => state.isAdmin);
  const getEvent = useEvents((state) => state.getEvent);
  const updateEvent = useEvents((state) => state.updateEvent);
  //getting listed directory venues
  const directoryVenues = useVenues((state) => state.getListedondirectory);
  const getVisibleEventCategories = useEvents(
    (state) => state.getVisibleEventCategories
  );
  //getting scanners
  const scanners = userStore((state) => state.scanners);
  //getting pos
  const pointsOfSale = userStore((state) => state.pointsOfSale);

  //getting visible languages
  const getVisibleLanguages = useEvents((state) => state.getVisibleLanguages);

  //getting visisble audiences
  const getVisibleAudiences = useEvents((state) => state.getVisibleAudiences);
  //getting visible countries
  const getVisibleCountries = useEvents((state) => state.getVisibleCountries);

  //upload event images
  const uploadEventImages = useEvents((state) => state.uploadEventImages);
  //getting delete method
  const deleteImageFromFirebaseStorage = useEvents(
    (state) => state.deleteImageFromFirebaseStorage
  );
  const layout_page = useSettings((state) => state.layout_page);

  // EVENT DETAILS VARIABLES
  const [category, setCategory] = useState(event_data?.category);
  const [venue, setVenue] = useState(event_data?.venue || null);
  const [name, setName] = useState(event_data?.name);
  const [description, setDescription] = useState(event_data?.description);
  const [artists, setArtists] = useState(event_data?.artists || []);
  const [tags, setTags] = useState(event_data?.tags || []);
  const [showAttendees, setShowAttendees] = useState(
    event_data?.show_attendees
  );
  const [enableReviews, setEnableReviews] = useState(
    event_data?.enable_reviews
  );
  const [event_has_tables, setEventHasTables] = useState(
    event_data?.event_has_tables
  );
  const [languages, setLanguages] = useState(event_data?.languages || []);
  const [audiences, setAudiences] = useState(event_data?.audiences);
  const [country, setCountry] = useState(event_data?.country);
  const [videoLink, setVideoLink] = useState(event_data?.video_link);
  const [externalLink, setExternalLink] = useState(event_data?.external_link);
  const [phoneNumber, setPhoneNumber] = useState(event_data?.phone_number);
  const [email, setEmail] = useState(event_data?.email);
  const [twitterLink, setTwitterLink] = useState(event_data?.twitter_link);
  const [facebookLink, setFacebookLink] = useState(event_data?.facebook_link);
  const [instagramLink, setInstagramLink] = useState(
    event_data?.instagram_link
  );
  const [linkedinLink, setLinkedinLink] = useState(event_data?.linkedin_link);

  const [mainEventImage, setMainEventImage] = useState(
    event_data?.main_event_image ?? ""
  );
  const [gallery, setGallery] = useState(event_data?.gallery ?? []);
  const [repeat_every, setRepeatEvery] = useState(
    event_data?.repeat_every ?? ""
  );
  const [repeat_metric, setRepeatMetric] = useState();
  const [eventRepeats, setEventRepeats] = useState(
    event_data?.repeat_every !== null && event_data.repeat_every !== ""
      ? true
      : false
  );

  // OTHER VARIABLES
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [venuesList, setVenuesList] = useState([]);

  const [scannersList, setScannersList] = useState([]);
  const [posList, setPOSList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);

  // CHECKING IF THE EVENT DATA WAS RECEIVED
  useEffect(() => {
    if (!event_data) {
      navigate("/organizer_dashboard");
      return;
    }
  }, []);

  // CHEKING IF THE USER IS THE OWNER OF THE EVENT
  useEffect(() => {
    if (event_data?.organizer_id !== user?.user_id && !isAdmin) {
      displayErrorToast("You Dont Have Permission To Edit This Event");
      navigate("/access_denied");
      return null;
    }
  }, []);

  // METHOD TO HANDLE DESCRIPTION
  function handleDescription(value) {
    console.log("SETTING DESCRIPTION VALUE: ", value);
    setDescription(value);
  }

  // METHOD TO HANDLE GETTING THE EVENT DATA
  async function handleGetEventData() {
    console.log("GETTING EVENT DATA FOR: ", event_id);
    const data = await getEvent(event_id);
    event_data = data;

    console.log("the event to edit is:", data);
  }

  // GET EVENT DATA IF THE STATE IS EMPTY
  useEffect(() => {
    if (!event_data) {
      console.log("THE STATE IS EMPTY, FETCHING EVENT DATA");
      handleGetEventData();
    }
  }, []);

  const repeatMetrics = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  // METHOD TO ADD METRIC
  const handleRepeatMetric = (e) => {
    setRepeatMetric(e);
  };

  // Handle Get Repeat Metric
  function handleGetRepeatMetric() {
    var metric = {
      label: event_data?.repeat_metric,
      value: event_data?.repeat_metric,
    };

    setRepeatMetric(metric);
  }

  // HANDLING MAIN EVENT IMAGE CAPTURE
  const handleMainEventImage = async (event) => {
    const file = event.target.files[0];
    const imageUrl = await uploadEventImages(file);
    setMainEventImage(imageUrl);
  };

  useEffect(() => {
    // Setting the repeat metric
    handleGetRepeatMetric();

    // GETTING THE EVENT CATEGORIES from firebase
    const get_event_categories = async () => {
      const returnedCategories = await getVisibleEventCategories();
      const event_categories = [];
      for (let i = 0; i < returnedCategories.length; i++) {
        event_categories.push({
          value: returnedCategories[i].id,
          label: returnedCategories[i].name,
        });
      }
      setEventCategories(event_categories);
    };

    // GETTING THE COUNTRIES
    const get_countries_list = async () => {
      const returnedCountries = await getVisibleCountries();
      const countries = [];
      for (let i = 0; i < returnedCountries.length; i++) {
        countries.push({
          value: returnedCountries[i].id,
          label: returnedCountries[i].name,
        });
      }
      setCountriesList(countries);
    };

    // GETTING THE SCANNERS LIST
    const get_scanners = async () => {
      const returnedScanners = scanners.map((scanner) => {
        return {
          value: scanner?.id,
          label: scanner?.name,
        };
      });
      setScannersList(returnedScanners);
    };

    // GETTING THE POINTS OF SALE LIST
    const get_points_of_sale = async () => {
      const pos = pointsOfSale.map((point) => {
        return {
          value: point?.id,
          label: point?.name,
        };
      });
      setPOSList(pos);
    };

    // GETTING THE LANGUAGES LIST
    const get_languages = async () => {
      const returnedLanguages = await getVisibleLanguages();
      const languages_list = [];

      for (var i = 0; i < returnedLanguages.length; i++) {
        languages_list.push({
          value: returnedLanguages[i].id,
          label: returnedLanguages[i].name,
        });
      }

      setLanguagesList(languages_list);
    };

    // GETTING THE EVENT CATEGORIES
    const get_audiences_list = async () => {
      const returnedAudiences = await getVisibleAudiences();
      const audiences = [];

      for (var i = 0; i < returnedAudiences.length; i++) {
        audiences.push(returnedAudiences[i].name);
      }

      setAudienceList(audiences);
    };

    //  CALLING THE METHODS TO FETCH THE DATA
    get_languages();
    get_scanners();
    get_points_of_sale();
    get_audiences_list();
    get_countries_list();
    get_event_categories();
  }, []);

  useEffect(() => {
    //GETTING THE VENUES LIST
    const getVenuesLists = async () => {
      const returnedVenues = await directoryVenues();
      const venues = [];
      for (var i = 0; i < returnedVenues.length; i++) {
        venues.push({
          value: returnedVenues[i].id,
          label: returnedVenues[i].name,
        });
      }
      //add others option
      venues.push({
        values: "others",
        label: "Others (Create your own Venue)",
      });
      setVenuesList(venues);
    };
    getVenuesLists();
  }, [venuesList?.length]);

  const handleVenueChange = (selectedOption) => {
    console.log("handleVenueChange called:", selectedOption);

    // Check if "Others" is selected
    if (selectedOption?.value === "others") {
      // Display a message or navigate to a page for creating a venue
      displayInfoToast(
        "Return to Dashboard to create your own venue or talk to admin for more information."
      );
      setVenue(null); // Clear the selection
    } else {
      setVenue(selectedOption);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////// EDITING THE EVENT  /////////////////////////////////
  const handleEditEvent = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      var data_event = {
        id: event_id,
        category: category,
        venue: venue || null,
        name: name || null,
        description: description,
        artists: artists || [],
        show_attendees: showAttendees,
        enable_reviews: enableReviews,
        repeat_every: repeat_every ?? null,
        repeat_metric: repeat_metric.value ?? null,
        languages: languages || [],
        // subtitles: subtitles || [],
        audiences: audiences || null,
        country: country ?? null,
        video_link: videoLink || null,
        external_link: externalLink || null,
        phone_number: phoneNumber || null,
        email: email || null,
        twitter_link: twitterLink || null,
        facebook_link: facebookLink || null,
        instagram_link: instagramLink || null,
        linkedin_link: linkedinLink || null,
        tags: tags || [],
        main_event_image: mainEventImage || null,
        event_has_tables: event_has_tables ?? null,
        gallery: gallery || [],
        event_dates: eventDates || [],
        updated_at: Timestamp.fromDate(new Date()),
      };

      console.log(data_event);

      // ADDING THE EVENT TO THE DATABASE
      const eventResponse = await updateEvent(event_id, data_event);
      if (eventResponse) {
        displaySuccessToast("Event Updated Successfully");
        setIsLoading(false);
        if (isOrganizer) {
          navigate("/myevents");
        } else {
          navigate("/manage_events");
        }
      } else {
        displayErrorToast("Error Updating the Event");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      displayErrorToast("SOMETHING WENT WRONG: " + error);
      setIsLoading(false);
    }
  };

  // METHOD TO ADD A NEW AUDIENCE
  const handleAudiences = (audience) => {
    const newAudiences = [...audiences];
    const audienceIndex = newAudiences.indexOf(audience);
    if (audienceIndex !== -1) {
      //if the audience is already selected, remove it
      newAudiences.splice(newAudiences, 1);
    } else {
      // If the audience is not selected, add it
      newAudiences.push(audience);
    }
    setAudiences(newAudiences);
  };

  //////////////////////////////////////////
  // HANDLING EVENT IMAGE AND GALLERY  //
  ///////////////////////////////////////////////////////
  const [numFieldsets, setNumFieldsets] = useState(0);

  const handleAddFieldset = (e) => {
    e.preventDefault();
    setNumFieldsets(numFieldsets + 1);
  };

  const handleRemoveFieldset = (e) => {
    e.preventDefault();
    if (numFieldsets > 0) {
      setNumFieldsets(numFieldsets - 1);
    }
  };

  // HANDLING GALLERY IMAGE CAPTURE
  const handleGalleryImages = async (event) => {
    const file = event.target.files[0];
    const imageUrl = await uploadEventImages(file);
    // UPDATE THE GALLERY VARIABLE
    setGallery((prevGallery) => [...prevGallery, imageUrl]);
    setNumFieldsets(0);
  };

  // Function to handle removing an image from the gallery
  const handleRemoveGalleryImage = async (index) => {
    var res = window.confirm(
      "Are you sure you want to delete this event image?"
    );

    if (res) {
      const newGallery = [...gallery];
      newGallery.splice(index, 1);
      setGallery(newGallery);
      const response = await deleteImageFromFirebaseStorage(gallery[index]);
      if (response) {
        displaySuccessToast("Image deleted successfully");
      }
    }
  };

  // Function to handle removing an image from the gallery
  const handleRemoveMainEventImage = async (e) => {
    e.preventDefault();

    const res = window.confirm("Are you sure you want to delete this image?");
    if (res) {
      setMainEventImage("");
      var response = await deleteImageFromFirebaseStorage(mainEventImage);
      if (response) {
        displaySuccessToast("Image deleted successfully");
      }
    }
  };

  const fieldsets = [];

  for (let i = 0; i < numFieldsets; i++) {
    fieldsets.push(
      <>
        <fieldset className="form-group" style={{}} key={i}>
          {/* <legend className="col-form-label">1label__</legend> */}
          <div id="event_images_1">
            <fieldset className="form-group">
              <legend className="col-form-label">Gallery image</legend>
              <div className="vich-image">
                <div className="custom-file">
                  {/* PICKING ANOTHER EVENT IMAGE */}
                  <input type="file" onChange={handleGalleryImages} />
                </div>
              </div>
            </fieldset>
          </div>
          <div onClick={handleRemoveFieldset}>
            <a href=" " className="mr-3 btn btn-outline-dark btn-sm ">
              <i className="fas fa-minus-square" /> Remove
            </a>
          </div>
        </fieldset>
      </>
    );
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [eventDates, setEventDates] = useState(event_data?.event_dates || [{}]);
  const [numberOfEventDates, setNumberOfEventDates] = useState(1);
  const [numberOfEventDateTickets, setNumberOfEventDateTickets] = useState(1);
  const [numberOfEventDateTables, setNumberOfEventDateTables] = useState(1);

  // HANDLE ADD EVENT DATE (NOT YET FINISHED)
  function handleAddEventDate(e, eventDateIndex) {
    e.preventDefault();

    const userId = user?.user_id;

    console.log("USER IS WHEN ADDING EVENT DATE: ", userId);

    // GETTING THE CURRENT TIMESTAMP
    const current_timestamp = Date.now();

    const event_date_id =
      "event_date" + numberOfEventDates + userId + current_timestamp;
    const ticket_id =
      "ticket" + numberOfEventDates + userId + current_timestamp;
    const table_id = "table" + numberOfEventDates + userId + current_timestamp;

    setNumberOfEventDates(numberOfEventDates + 1);
    const newEventDates = [...eventDates];

    newEventDates[eventDateIndex].push({
      id: event_date_id,
      tickets: [
        {
          id: ticket_id,
        },
      ],

      tables: [
        {
          id: table_id,
        },
      ],
    });
    setEventDates(newEventDates);
  }

  const handleRadioTableChange = (e) => {
    setEventHasTables(e.target.value);
  };

  // HANDLE EVENT DATE CHANGE METHOD
  function handleEventDateChange(index, key, value) {
    const newEventDates = [...eventDates];
    newEventDates[index][key] = value;
    setEventDates(newEventDates);
  }

  // HANDLE REMOVE EVENT DATE
  const handleRemoveEventDate = (e, event_date_index) => {
    e.preventDefault();
    if (numberOfEventDates > 1) {
      setNumberOfEventDates(numberOfEventDates - 1);
      const eventDatesCopy = [...eventDates];
      eventDatesCopy.splice(event_date_index, 1);
      setEventDates(eventDatesCopy);
    }
  };

  // HANDLE ADD TICKET
  function handleAddTicket(e, eventDateIndex) {
    e.preventDefault();
    setNumberOfEventDateTickets(numberOfEventDateTickets + 1);
    const newEventDates = [...eventDates];
    console.log("NEW EVENT DATES LENGTH: " + newEventDates.length);

    const userId = user?.user_id;

    console.log("USER IS WHEN ADDING EVENT DATE: ", userId);

    // GETTING THE CURRENT TIMESTAMP
    const current_timestamp = Date.now();

    const ticket_id =
      "ticket" + numberOfEventDates + userId + current_timestamp;

    newEventDates[eventDateIndex].tickets.push({
      id: ticket_id,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      event_date_id: eventDates[eventDateIndex]?.id,
    });
    setEventDates(newEventDates);
    console.log("added a ticket to event dates at index: " + eventDateIndex);
  }

  // HANDLE ADD TABLE
  function handleAddTable(e, eventDateIndex) {
    e.preventDefault();
    setNumberOfEventDateTables(numberOfEventDateTables + 1);
    const newEventDates = [...eventDates];
    console.log("NEW EVENT DATES LENGTH: " + newEventDates.length);

    const userId = user?.user_id;

    console.log("USER IS WHEN ADDING EVENT DATE: ", userId);

    // GETTING THE CURRENT TIMESTAMP
    const current_timestamp = Date.now();

    const table_id = "table" + numberOfEventDates + userId + current_timestamp;

    newEventDates[eventDateIndex].tables.push({
      id: table_id,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      event_date_id: eventDates[eventDateIndex]?.id,
    });
    setEventDates(newEventDates);
    console.log("added a table to event dates at index: " + eventDateIndex);
  }

  // HANDLE TICKET CHANGE
  function handleTicketChange(e, eventDateIndex, ticketIndex, key, value) {
    console.log("THE TICKET INDEX IS: " + ticketIndex);
    console.log("THE EVENT DATE INDEX IS: " + eventDateIndex);

    console.log(
      "STORED TICKETS LENGTH: " + eventDates[eventDateIndex]?.tickets[0]
    );

    if (!eventDates[eventDateIndex]?.tickets[ticketIndex]) {
      console.log("TICKET IS NULL?: ");
    } else {
      console.log("TICKET IS NOT NULL");
    }

    const newEventDates = [...eventDates];
    newEventDates[eventDateIndex].tickets[ticketIndex][key] = value;
    setEventDates(newEventDates);
  }

  // HANDLE TABLE CHANGE
  function handleTableChange(e, eventDateIndex, tableIndex, key, value) {
    console.log("THE TABLE INDEX IS: " + tableIndex);
    console.log("THE EVENT DATE INDEX IS: " + eventDateIndex);

    console.log(
      "STORED TABLES LENGTH: " + eventDates[eventDateIndex]?.tables[0]
    );

    if (!eventDates[eventDateIndex]?.tables[tableIndex]) {
      console.log("TABLES IS NULL?: ");
    } else {
      console.log("TABLES IS NOT NULL");
    }

    const newEventDates = [...eventDates];
    newEventDates[eventDateIndex].tables[tableIndex][key] = value;
    setEventDates(newEventDates);
  }

  // HANDLE REMOVE TICKET
  const handleRemoveTicket = (e, event_date_index, ticket_index) => {
    console.log("THE EVENT INDEX: " + event_date_index);
    console.log("THE TICKET INDEX: " + ticket_index);

    e.preventDefault();
    if (numberOfEventDateTickets > 1) {
      setNumberOfEventDateTickets(numberOfEventDateTickets - 1);

      const newEventDates = [...eventDates];
      const newTickets = newEventDates[event_date_index].tickets;

      newTickets.splice(ticket_index, 1);

      newEventDates[event_date_index].tickets = newTickets;
      setEventDates(newEventDates);
      console.log(
        "FOR EVENT DATE AT INDEX: " +
          event_date_index +
          " THE NUMBER OF TICKETS ARE: " +
          eventDates[event_date_index]?.tickets.length +
          " OR: " +
          newTickets.length
      );
    }
  };

  // HANDLE REMOVE TABLE
  const handleRemoveTable = (e, event_date_index, table_index) => {
    console.log("THE EVENT INDEX: " + event_date_index);
    console.log("THE TABLE INDEX: " + table_index);

    e.preventDefault();
    if (numberOfEventDateTables > 1) {
      setNumberOfEventDateTickets(numberOfEventDateTables - 1);

      const newEventDates = [...eventDates];
      const newTables = newEventDates[event_date_index].tables;

      newTables.splice(table_index, 1);

      newEventDates[event_date_index].tables = newTables;
      setEventDates(newEventDates);
      console.log(
        "FOR EVENT DATE AT INDEX: " +
          event_date_index +
          " THE NUMBER OF TICKETS ARE: " +
          eventDates[event_date_index]?.tables.length +
          " OR: " +
          newTables.length
      );
    }
  };

  // HANDLING EVENT DATES
  const eventdates = [];

  for (let index = 0; index < numberOfEventDates; index++) {
    const eventDate = eventDates[index] || {};

    const tickets = [];
    for (
      let ticket_index = 0;
      ticket_index < eventDate?.tickets?.length;
      ticket_index++
    ) {
      tickets.push(
        <>
          <fieldset className="form-group" key={ticket_index}>
            <div id="event_eventdates_2_tickets___eventticket__">
              {/* ENABLE SALES FOR THIS TICKET * //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Enable sales for this ticket?
                </legend>
                <div className="is-ticket-active">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      required="required"
                      className="custom-control-input"
                      value={1}
                      id={`yes_enable_sales${index}${ticket_index}`}
                      name={`enable_sales${index}${ticket_index}`}
                      checked={
                        eventDate?.tickets[ticket_index]
                          ?.enable_sales_for_ticket === true
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "enable_sales_for_ticket",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_enable_sales${index}${ticket_index}`}
                    >
                      Yes
                    </label>
                  </div>

                  {/* NO */}
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_enable_sales${index}${ticket_index}`}
                      name={`enable_sales${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      value={0}
                      checked={
                        eventDate?.tickets[ticket_index]
                          ?.enable_sales_for_ticket === false
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "enable_sales_for_ticket",
                          false
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`no_enable_sales${index}${ticket_index}`}
                    >
                      No
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TICKET NAME */}
              {errors.ticketName && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.ticketName}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___name"
                  className="required"
                >
                  Ticket name
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___name_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle  mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Early bird, General admission, VIP...
                </small>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___name"
                  name="event[eventdates][2][tickets][__eventticket__][name]"
                  required="required"
                  aria-describedby="event_eventdates_2_tickets___eventticket___name_help"
                  className="form-control"
                  value={eventDates[index]?.tickets[ticket_index]?.ticket_name}
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "ticket_name",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* TICKET DESCRIPTION */}
              <div className="form-group">
                <label htmlFor="event_eventdates_2_tickets___eventticket___description">
                  Ticket description
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___description_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Tell your attendees more about this ticket type
                </small>
                <textarea
                  id="event_eventdates_2_tickets___eventticket___description"
                  name="event[eventdates][2][tickets][__eventticket__][description]"
                  aria-describedby="event_eventdates_2_tickets___eventticket___description_help"
                  className="form-control"
                  required="required"
                  value={
                    eventDates[index]?.tickets[ticket_index]?.ticket_description
                  }
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "ticket_description",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* IS TICKET FREE */}
              {errors.is_ticket_free && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.is_ticket_free}
                </span>
              )}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Is this ticket free?
                </legend>
                <div
                  id="event_eventdates_2_tickets___eventticket___free"
                  className="is-ticket-free-radio"
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_ticket_free${index}${ticket_index}`}
                      name={`ticket_free${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      checked={
                        eventDate.tickets[ticket_index].isFree === false ||
                        eventDate.tickets[ticket_index].is_ticket_free === false
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "is_ticket_free",
                          false
                        )
                      }
                    />

                    <label
                      className="custom-control-label required"
                      htmlFor={`no_ticket_free${index}${ticket_index}`}
                    >
                      No
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`yes_ticket_free${index}${ticket_index}`}
                      name={`ticket_free${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      checked={
                        eventDate.tickets[ticket_index].isFree === true ||
                        eventDate.tickets[ticket_index].is_ticket_free === true
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "is_ticket_free",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_ticket_free${index}${ticket_index}`}
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TICKET PRICE */}
              {(!eventDate.tickets[ticket_index].is_ticket_free === true ||
                eventDate.tickets[ticket_index].isFree === true) && (
                <>
                  <div className="form-group">
                    <label htmlFor="event_eventdates_0_tickets_0_price">
                      Price
                    </label>
                    <input
                      type="text"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      value={
                        eventDates[index]?.tickets[ticket_index]?.ticket_price
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "ticket_price",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  {/* PROMOTIONAL PRICE */}
                  <div className="form-group">
                    <label htmlFor="event_eventdates_2_tickets___eventticket___promotionalprice">
                      Promotional price
                    </label>
                    <small
                      id="event_eventdates_2_tickets___eventticket___promotionalprice_help"
                      className="form-text text-muted mb-3"
                    >
                      <i
                        className="fas fa-info-circle mr-1"
                        style={{ color: layout_page?.primary_color }}
                      />
                      Set a price lesser than than the original price to
                      indicate a promotion (this price will be the SALE price)
                    </small>
                    <input
                      type="text"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      defaultValue={
                        eventDates[index]?.tickets[ticket_index]
                          ?.promotional_price
                      }
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "promotional_price",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </>
              )}

              {/* NUMBER OF TICKETS */}
              {errors.numberOfTickets && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.numberOfTickets}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___quantity"
                  className="required"
                >
                  Number Of Tickets
                </label>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___quantity"
                  name="event[eventdates][2][tickets][__eventticket__][quantity]"
                  required="required"
                  className="touchspin-integer event-date-ticket-quantity form-control"
                  data-min={1}
                  data-max={1000000}
                  value={
                    eventDates[index]?.tickets[ticket_index]?.number_of_tickets
                  }
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "number_of_tickets",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* TICKETS PER ATTENDEE */}
              <div className="form-group">
                <label htmlFor="event_eventdates_2_tickets___eventticket___ticketsperattendee">
                  Tickets per attendee
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___ticketsperattendee_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Set the number of tickets that an attendee can buy for this
                  ticket type
                </small>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___ticketsperattendee"
                  name="event[eventdates][2][tickets][__eventticket__][ticketsperattendee]"
                  className="touchspin-integer form-control"
                  data-min={1}
                  data-max={1000000}
                  required="required"
                  value={
                    eventDates[index]?.tickets[ticket_index]
                      ?.tickets_per_attendee
                  }
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "tickets_per_attendee",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* SALE START DATE */}
              <div className="form-group d-grid">
                <label
                  htmlFor={`event_eventdates_${index}_tickets___eventticket___salesstartdate`}
                  className="required"
                >
                  Sale starts On
                </label>
                {!eventDate.tickets[ticket_index].sales_start_date && (
                  <span className=" text-danger font-weight-bold text-sm ">
                    Please Make sure you select Sales Start Date
                  </span>
                )}
                <br />
                <DatePicker
                  className="form-control"
                  selected={
                    eventDates[index]?.tickets[ticket_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_start_date
                        )
                      : eventDate?.tickets[ticket_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_start_date
                        )
                      : ""
                  }
                  onChange={(date) => {
                    handleTicketChange(
                      date,
                      index,
                      ticket_index,
                      "sales_start_date",
                      date
                    );
                  }}
                  required
                  showTimeSelect
                  value={
                    eventDates[index]?.tickets[ticket_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_start_date
                        )
                      : eventDate?.tickets[ticket_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_start_date
                        )
                      : ""
                  }
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Ticket Sales Start Date"
                  minDate={new Date()}
                />
              </div>

              {/* SALE END DATE */}
              <div className="form-group d-grid">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___salesenddate"
                  className="required"
                >
                  Sale ends On
                </label>
                {!eventDate.tickets[ticket_index].sales_end_date && (
                  <span className=" text-danger font-weight-bold text-sm">
                    Please Make sure you select Sales End Date
                  </span>
                )}
                <br />
                <DatePicker
                  required="required"
                  className="form-control"
                  selected={
                    eventDates[index]?.tickets[ticket_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_end_date
                        )
                      : eventDate?.tickets[ticket_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_end_date
                        )
                      : ""
                  }
                  onChange={(date) => {
                    handleTicketChange(
                      date,
                      index,
                      ticket_index,
                      "sales_end_date",
                      date
                    );
                  }}
                  showTimeSelect
                  value={
                    eventDates[index]?.tickets[ticket_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_end_date
                        )
                      : eventDate?.tickets[ticket_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[ticket_index]?.sales_end_date
                        )
                      : ""
                  }
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Ticket Sales End Date"
                  minDate={eventDate.tickets[ticket_index].sales_start_date}
                />
              </div>

              {/*////////////////////////////////////////////////////////////////////////////////////////// */}
              {/* END OF THE TICKET */}
              {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

              {/* REMOVE TICKET */}
              {eventDate.tickets.length > 1 && (
                <Link
                  to=""
                  onClick={(e) => handleRemoveTicket(e, index, ticket_index)}
                  className="mr-3 btn btn-outline-dark btn-sm "
                >
                  <i className="fas fa-minus-square" /> Remove this ticket
                </Link>
              )}
            </div>
          </fieldset>
        </>
      );
    }

    // EVENT TABLES
    const tables = [];
    for (
      let table_index = 0;
      table_index < eventDate?.tables?.length;
      table_index++
    ) {
      tables.push(
        <>
          <fieldset className="form-group" key={table_index}>
            <div id="event_eventdates_tables">
              {/* ENABLE SALES FOR THIS TICKET * //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Enable sales for this table?
                </legend>
                <div className="is-ticket-active">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      required="required"
                      className="custom-control-input"
                      value={1}
                      id={`yes_enable_table_sales${index}${table_index}`}
                      name={`enable_table_sales${index}${table_index}`}
                      checked={
                        eventDate?.tables[table_index]
                          ?.enable_sales_for_table === true
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "enable_sales_for_table",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_enable_table_sales${index}${table_index}`}
                    >
                      Yes
                    </label>
                  </div>

                  {/* NO */}
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_enable_table_sales${index}${table_index}`}
                      name={`enable_table_sales${index}${table_index}`}
                      required="required"
                      className="custom-control-input"
                      value={0}
                      checked={
                        eventDate?.tables[table_index]
                          ?.enable_sales_for_table === false
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "enable_sales_for_table",
                          false
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`no_enable_table_sales${index}${table_index}`}
                    >
                      No
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TABLE NAME */}
              {errors.table_name && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.table_name}
                </span>
              )}
              <div className="form-group">
                <label htmlFor="table_name" className="required">
                  Table name
                </label>
                <small
                  id="event_eventdates_2_tickets___eventtable___name_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle  mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Early bird, General admission, VIP...
                </small>
                <input
                  type="text"
                  name="table_name"
                  required="required"
                  className="form-control"
                  value={eventDates[index]?.tables[table_index]?.table_name}
                  onChange={(e) =>
                    handleTableChange(
                      e,
                      index,
                      table_index,
                      "table_name",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* TABLE DESCRIPTION */}
              <div className="form-group">
                <label htmlFor="event_eventdates_2_tickets___eventticket___description">
                  Ticket description
                </label>
                <small
                  id="event_eventdates_2_table___eventtable___description_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Tell your attendees more about this ticket type
                </small>
                <textarea
                  id="event_eventdates_2_tables___eventtable___description"
                  name="table_description"
                  className="form-control"
                  required="required"
                  value={
                    eventDates[index]?.tables[table_index]?.table_description
                  }
                  onChange={(e) =>
                    handleTableChange(
                      e,
                      index,
                      table_index,
                      "table_description",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* IS TICKET FREE */}
              {errors.is_table_free && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.is_table_free}
                </span>
              )}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Is this table free?
                </legend>
                <div
                  id="event_eventdates_2_tables___eventtable___free"
                  className="is-table-free-radio"
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_table_free${index}${table_index}`}
                      name={`table_free${index}${table_index}`}
                      required="required"
                      className="custom-control-input"
                      checked={
                        eventDate.tables[table_index].isFree === false ||
                        eventDate.tables[table_index].is_table_free === false
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "is_table_free",
                          false
                        )
                      }
                    />

                    <label
                      className="custom-control-label required"
                      htmlFor={`no_table_free${index}${table_index}`}
                    >
                      No
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`yes_table_free${index}${table_index}`}
                      name={`table_free${index}${table_index}`}
                      required="required"
                      className="custom-control-input"
                      checked={
                        eventDate.tables[table_index].isFree === true ||
                        eventDate.tables[table_index].is_table_free === true
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "is_table_free",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_table_free${index}${table_index}`}
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TABLE PRICE */}
              {(!eventDate.tables[table_index].is_table_free === true ||
                eventDate.tables[table_index].isFree === true) && (
                <>
                  <div className="form-group">
                    <label htmlFor="event_eventdates_0_table_0_price">
                      Price
                    </label>
                    <input
                      type="text"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      value={
                        eventDates[index]?.tables[table_index]?.table_price
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "table_price",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  {/* PROMOTIONAL PRICE */}
                  <div className="form-group">
                    <label htmlFor="event_eventdates_2_table___eventticket___promotionalprice">
                      Promotional price
                    </label>
                    <small
                      id="event_eventdates_2_tickets___eventtable___promotionalprice_help"
                      className="form-text text-muted mb-3"
                    >
                      <i
                        className="fas fa-info-circle mr-1"
                        style={{ color: layout_page?.primary_color }}
                      />
                      Set a price lesser than the original price to indicate a
                      promotion (this price will be the SALE price)
                    </small>
                    <input
                      type="text"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      defaultValue={
                        eventDates[index]?.tables[table_index]
                          ?.promotional_price
                      }
                      onChange={(e) =>
                        handleTableChange(
                          e,
                          index,
                          table_index,
                          "promotional_price",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </>
              )}

              {/* NUMBER OF TABLES */}
              {errors.numberOfTables && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.numberOfTables}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tables___eventticket___quantity"
                  className="required"
                >
                  Number Of Tables
                </label>
                <input
                  type="text"
                  id="event_eventdates_2_tables___eventticket___quantity"
                  name="number_of_tables"
                  required="required"
                  className="touchspin-integer event-date-ticket-quantity form-control"
                  data-min={1}
                  data-max={1000000}
                  value={
                    eventDates[index]?.tables[table_index]?.number_of_tables
                  }
                  onChange={(e) =>
                    handleTableChange(
                      e,
                      index,
                      table_index,
                      "number_of_tables",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* NUMBER OF SEATS PER TABLE */}
              {errors.numberOfSeatsPerTable && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.numberOfSeatsPerTable}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_seats_per_table___eventticket___quantity"
                  className="required"
                >
                  Number Of Seats Per Table
                </label>
                <input
                  type="text"
                  id="event_eventdates_2_tables___seats_per_table___quantity"
                  name="seats_per_table"
                  required="required"
                  className="touchspin-integer event-date-ticket-quantity form-control"
                  data-min={1}
                  data-max={1000000}
                  value={
                    eventDates[index]?.tables[table_index]?.seats_per_table
                  }
                  onChange={(e) =>
                    handleTableChange(
                      e,
                      index,
                      table_index,
                      "seats_per_table",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* SALE START DATE */}
              <div className="form-group d-grid">
                <label
                  htmlFor={`event_eventdates_${index}_tables___eventtable___salesstartdate`}
                  className="required"
                >
                  Sale starts On
                </label>
                {!eventDate.tables[table_index].sales_start_date && (
                  <span className=" text-danger font-weight-bold text-sm ">
                    Please Make sure you select Sales Start Date
                  </span>
                )}
                <br />
                <DatePicker
                  className="form-control"
                  selected={
                    eventDates[index]?.tables[table_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_start_date
                        )
                      : eventDate?.tables[table_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_start_date
                        )
                      : ""
                  }
                  onChange={(date) => {
                    handleTableChange(
                      date,
                      index,
                      table_index,
                      "sales_start_date",
                      date
                    );
                  }}
                  required
                  showTimeSelect
                  value={
                    eventDates[index]?.tables[table_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_start_date
                        )
                      : eventDate?.tables[table_index]?.sales_start_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_start_date
                        )
                      : ""
                  }
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Table Sales Start Date"
                  minDate={new Date()}
                />
              </div>

              {/* SALE END DATE */}
              <div className="form-group d-grid">
                <label
                  htmlFor="event_eventdates_2_tables___eventtable___salesenddate"
                  className="required"
                >
                  Sale ends On
                </label>
                {!eventDate.tables[table_index].sales_end_date && (
                  <span className=" text-danger font-weight-bold text-sm">
                    Please Make sure you select Sales End Date
                  </span>
                )}
                <br />
                <DatePicker
                  required="required"
                  className="form-control"
                  selected={
                    eventDates[index]?.tables[table_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_end_date
                        )
                      : eventDate?.tables[table_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_end_date
                        )
                      : ""
                  }
                  onChange={(date) => {
                    handleTableChange(
                      date,
                      index,
                      table_index,
                      "sales_end_date",
                      date
                    );
                  }}
                  showTimeSelect
                  value={
                    eventDates[index]?.tables[table_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tables[table_index]?.sales_end_date
                        )
                      : eventDate?.tables[table_index]?.sales_end_date
                      ? convertTimestampToDateTime(
                          eventDate?.tickets[table_index]?.sales_end_date
                        )
                      : ""
                  }
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Ticket Sales End Date"
                  minDate={eventDate.tables[table_index].sales_start_date}
                />
              </div>

              {/*////////////////////////////////////////////////////////////////////////////////////////// */}
              {/* END OF THE TABLE */}
              {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              {/* REMOVE TABLE */}
              {eventDate.tables.length > 1 && (
                <Link
                  to=""
                  onClick={(e) => handleRemoveTable(e, index, table_index)}
                  className="mr-3 btn btn-outline-dark btn-sm "
                >
                  <i className="fas fa-minus-square" /> Remove this table
                </Link>
              )}
            </div>
          </fieldset>
        </>
      );
    }

    // PUSHING THE EVENT DATES///////////////////////////////////////////////////////////////
    eventdates.push(
      <fieldset className="mt-5" key={index}>
        {/* ENABLE SALES FOR THIS EVENT DATE */}
        <fieldset className="form-group">
          <b>Enable sales for this event date ?</b>
          <small
            id="event_eventdates_2_active_help"
            className="form-text text-muted mb-3 mt-2"
          >
            <i
              className="fas fa-info-circle mr-1"
              style={{ color: layout_page?.primary_color }}
            />
            Enabling sales for an event date does not affect the tickets
            individual sale status
          </small>
          <div
            id="event_eventdates_2_active"
            className="is-event-date-active"
            aria-describedby="event_eventdates_2_active_help"
          >
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_active_0"
                name="event[eventdates][2][active]"
                required="required"
                className="custom-control-input"
                checked={
                  eventDate.enable_sales === true ||
                  eventDate["enable_sales_for_event"] === true
                }
                onChange={() =>
                  handleEventDateChange(index, "enable_sales_for_event", true)
                }
              />

              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_active_0"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_active_1"
                name="event[eventdates][2][active]"
                required="required"
                className="custom-control-input"
                checked={
                  eventDate.enable_sales === false ||
                  eventDate["enable_sales_for_event"] === false
                }
                onClick={(e) =>
                  handleEventDateChange(index, "enable_sales_for_event", false)
                }
              />

              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_active_1"
              >
                No
              </label>
            </div>
          </div>
        </fieldset>

        {/* START DATE */}
        {errors.startDate && (
          <span style={{ marginTop: 0, color: "red" }}>{errors.startDate}</span>
        )}
        <div className="form-group d-grid">
          <label htmlFor="event_eventdates_2_startdate" className="required">
            Starts On
          </label>
          {!eventDate.start_date && (
            <span className=" text-danger font-weight-bold text-sm ">
              Please Make sure you select Event Start Date
            </span>
          )}
          <br />
          <DatePicker
            className="form-control"
            selected={
              eventDate.start_date
                ? convertTimestampToDateTime(eventDate.start_date)
                : ""
            }
            onChange={(date) => {
              handleEventDateChange(index, "start_date", date);
            }}
            showTimeSelect
            value={
              eventDate.start_date
                ? convertTimestampToDateTime(eventDate.start_date)
                : ""
            }
            dateFormat={"dd/MM/yyyy p"}
            placeholderText="Select Event Start Date"
            minDate={new Date()}
          />
        </div>

        {/* END DATE */}
        {errors.endDate && (
          <span style={{ marginTop: 0, color: "red" }}>{errors.endDate}</span>
        )}
        <div className="form-group d-grid">
          <label className="required">Ends On</label> <br />
          {!eventDate.end_date && (
            <span className=" text-danger font-weight-bold text-sm ">
              Please Make sure you select Event End Date
            </span>
          )}
          <DatePicker
            className="form-control"
            selected={
              eventDate.end_date
                ? convertTimestampToDateTime(eventDate.end_date)
                : ""
            }
            onChange={(date) => {
              handleEventDateChange(index, "end_date", date);
            }}
            showTimeSelect
            value={
              eventDate.end_date
                ? convertTimestampToDateTime(eventDate.end_date)
                : ""
            }
            dateFormat={"dd/MM/yyyy p"}
            placeholderText="Select Event End Date"
            minDate={eventDate.start_date}
          />
        </div>

        {/* IS EVENT ONLINE */}
        {errors.isEventOnline && (
          <span style={{ marginTop: 0, color: "red" }}>
            {errors.isEventOnline}
          </span>
        )}
        <fieldset className="form-group">
          <legend className="col-form-label required">
            Is this event date online ?
          </legend>
          <div id="event_eventdates_2_online" className="is-event-date-online">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_online_0"
                name="event[eventdates][2][online]"
                required="required"
                className="custom-control-input"
                checked={
                  eventDate.isOnline === false ||
                  eventDate["is_event_online"] === false
                }
                onChange={(e) =>
                  handleEventDateChange(index, "is_event_online", false)
                }
              />
              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_online_0"
              >
                No
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              {" "}
              <input
                type="radio"
                id="event_eventdates_2_online_1"
                name="event[eventdates][2][online]"
                required="required"
                className="custom-control-input"
                checked={
                  eventDate.isOnline === true ||
                  eventDate["is_event_online"] === true
                }
                onClick={(e) =>
                  handleEventDateChange(index, "is_event_online", true)
                }
              />
              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_online_1"
              >
                Yes
              </label>
            </div>
          </div>
        </fieldset>

        {/* VENUE */}
        {!eventDate["isOnline"] && (
          <div className="form-group">
            <label className htmlFor="event_eventdates_2_venue">
              Venue
            </label>

            {/* <input
              type="text"
              className="form-control"
              onChange={(e) => {
                handleEventDateChange(index, "venue", e.target.value);
              }}
              value={eventDate["venue"]}
            /> */}

            <SearchableDropdown
              value={venue}
              options={venuesList}
              onChange={(e) => handleVenueChange(e)}
              isMulti={false}
              placeholder={"Select venue"}
            />
          </div>
        )}

        {/* SCANNERS */}
        <div className="form-group">
          <label className htmlFor="event_eventdates_2_scanners">
            Scanners
          </label>

          <label htmlFor="s2id_autogen2387" className="select2-offscreen">
            Scanners
          </label>

          <SearchableDropdown
            value={eventDates[index]?.scanners}
            options={scannersList}
            onChange={(e) => handleEventDateChange(index, "scanners", e)}
            isMulti={true}
            placeholder={"Select Scanners"}
          />
        </div>

        {/* POINTS OF SALE */}
        <div className="form-group">
          <label className htmlFor="event_eventdates_2_pointofsales">
            Points of sale
          </label>
          <label htmlFor="s2id_autogen2388" className="select2-offscreen">
            Points of sale
          </label>

          <SearchableDropdown
            value={eventDates[index]?.pointsOfSale}
            options={posList}
            onChange={(e) => handleEventDateChange(index, "points_of_sale", e)}
            isMulti={true}
            placeholder={"Select Points Of Sale"}
          />
        </div>

        {/* TICKETS */}
        <div className="form-collection eventtickets-collection manual-init ">
          <legend className="col-form-label required">Event tickets</legend>

          {tickets}

          {/* ADD NEW TICKET BUTTON */}
          <Link
            to=""
            onClick={(e) => handleAddTicket(e, index)}
            className="mr-3 btn btn-outline-dark btn-sm"
          >
            <i className="fas fa-plus-square"></i> Add a new ticket
          </Link>
        </div>

        <div className="mt-5 fw-bold">
          <p className="fs-6">Does this Event has Tables?</p>
          <>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="no"
                checked={event_has_tables === "no"}
                onChange={handleRadioTableChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                No
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="yes"
                checked={event_has_tables === "yes"}
                onChange={handleRadioTableChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Yes
              </label>
            </div>
          </>
        </div>

        {/* TABLE FORM */}
        {event_has_tables === "yes" && (
          <div className=" mt-3 ">
            <div>
              <p className=" text-danger ">
                Please contact the Admin to add tables for your event and also
                contact the owner of the venue where your tables will be
                arranged
              </p>
            </div>
            {/**
           * <div className="form-group">
              <label className="required" htmlFor="reserved_venue">
                Reserved Venue
              </label>
              <small className="form-text text-muted mb-3">
                <i
                  className="fas fa-info-circle mr-1"
                  style={{ color: layout_page?.primary_color }}
                ></i>
                Make sure to select right reserved venue for your event tables
              </small>

              {errors.reserved_venue && (
                <span className=" text-sm font-weight-bold text-danger">
                  {errors.reserved_venue}
                </span>
              )}
              <SearchableDropdown
                value={venue}
                options={venuesList}
                onChange={handleVenues}
                isMulti={false}
                required="required"
              />
            </div> 
            <div className="form-collection eventtickets-collection manual-init ">
              <legend className="col-form-label required">Event Tables</legend>

              {tables}

              <button
                onClick={(e) => handleAddTable(e, index)}
                className="mr-3 btn btn-outline-dark btn-sm"
              >
                <i className="fas fa-plus-square"></i> Add a new table
              </button>
            </div>
           */}

            {/* REMOVE EVENT DATE BUTTON */}
            {numberOfEventDates > 1 && (
              <div
                onClick={(e) => handleRemoveEventDate(e, index)}
                className="mr-3 mt-4 btn btn-outline-dark btn-sm "
              >
                <i className="fas fa-minus-square" /> Remove this event date
              </div>
            )}
          </div>
        )}
      </fieldset>
    );
  }

  //////////////////////////
  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!category) {
      errors.category = "Please select category";
    }

    // VALIDATING NAME
    if (name.trim() === "") {
      errors.name = "Name is required";
    }

    // VALIDATING DESCRIPTION
    if (description.trim() === "") {
      errors.description = "Description is required";
    }

    // VALIDATING IMAGE
    if (mainEventImage.trim() === "") {
      errors.main_event_image = "Image is required";
    }

    // VALIDATING AUDIENCES
    if (audiences.length === 0) {
      errors.audiences = "Please select the audiences";
    }

    // VALIDATING PHONE NUMBER
    if (phoneNumber.trim() === "") {
      errors.phone_number = "Phone Number is required";
    }

    // VALIDATING EMAIL ADDRESS
    if (email.trim() === "") {
      errors.email = "Please select enter the contact email address";
    }

    return errors;
  }
  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  // IF THE USER IS NOT AN ORGANIZER
  if (isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  }

  return (
    <div>
      <Header />
      <BreadCrumpOrg title={"Edit Event"} />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            {isOrganizer ? (
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar />}
              </aside>
            ) : (
              <AdminSideNav />
            )}
            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-info-circle" /> The fields preceded
                    with a red * are mandatory
                  </div>{" "}
                  <form
                    name="event"
                    noValidate="novalidate"
                    encType="multipart/form-data"
                    onSubmit={handleEditEvent}
                  >
                    {hasErrors && (
                      <div className="alert alert-danger alert-icon">
                        <i className="fas fa-exclamation-circle"></i>{" "}
                        {errorMessage}
                      </div>
                    )}

                    <div id="event">
                      {/* categories */}
                      <div className="form-group">
                        <label className="required" htmlFor="event_category">
                          Category
                        </label>
                        <small
                          id="event_category_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle  mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Make sure to select right category to let the users
                          find it quickly
                        </small>

                        {/* EVENT CATEGORY */}
                        {errors.category && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.category}
                          </span>
                        )}
                        <SearchableDropdown
                          value={category}
                          options={eventCategories}
                          onChange={(value) => setCategory(value)}
                          isMulti={false}
                        />
                      </div>

                      {/* EVENT DETAILS */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Event details
                        </legend>
                        <div className="a2lix_translations">
                          <div className="tab-content">
                            <div
                              id="event_translations_en_a2lix_translations-fields"
                              className="tab-pane show active"
                              role="tabpanel"
                            >
                              <div id="event_translations_en">
                                {/* EVENT NAME */}
                                {errors.name && (
                                  <span style={{ marginTop: 0, color: "red" }}>
                                    {errors.name}
                                  </span>
                                )}
                                <div className="form-group">
                                  <label
                                    htmlFor="event_translations_en_name"
                                    className="required"
                                  >
                                    Name
                                  </label>
                                  <small
                                    id="event_translations_en_name_help"
                                    className="form-text text-muted mb-3"
                                  >
                                    <i
                                      className="fas fa-info-circle mr-1"
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                    ></i>
                                    Editing the title after the event is saved
                                    won &#039;t change the event url
                                  </small>

                                  <input
                                    type="text"
                                    id="event_translations_en_name"
                                    name="name"
                                    required="required"
                                    maxLength="100"
                                    pattern=".{10,}"
                                    aria-describedby="event_translations_en_name_help"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                {/* EVENT DESCRIPTION */}
                                {errors.description && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.description}
                                  </span>
                                )}
                                <div className="form-group">
                                  <label htmlFor="event_translations_en_description">
                                    Description
                                  </label>

                                  <TextEditor
                                    value={description}
                                    onChange={(value) =>
                                      handleDescription(value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      {/* ATTENDEES */}
                      {errors.show_attendees && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.show_attendees}
                        </span>
                      )}

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Attendees
                        </legend>
                        <small
                          id="event_showattendees_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Show the attendees number and list in the event page
                        </small>
                        <div
                          id="event_showattendees"
                          aria-describedby="event_showattendees_help"
                        >
                          {/* show attendees true */}
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_showattendees_1"
                              name="show_attendees"
                              value="true"
                              required="required"
                              className="custom-control-input"
                              checked={showAttendees === true}
                              onChange={(e) => setShowAttendees(true)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_showattendees_1"
                            >
                              Show
                            </label>
                          </div>

                          {/* HIDE ATTENDEES */}
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_showattendees_0"
                              name="show_attendees"
                              required="required"
                              value="false"
                              checked={showAttendees === false}
                              className="custom-control-input"
                              onChange={(e) => setShowAttendees(false)}
                            />

                            <label
                              className="custom-control-label required"
                              htmlFor="event_showattendees_0"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* ENABLE REVIEWS */}
                      {errors.enable_reviews && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.enable_reviews}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Enable reviews
                        </legend>
                        <div id="event_enablereviews">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_enablereviews_0"
                              name="event[enablereviews]"
                              value="true"
                              required="required"
                              checked={enableReviews === true}
                              className="custom-control-input"
                              onChange={(e) => setEnableReviews(true)}
                            />

                            <label
                              className="custom-control-label required"
                              htmlFor="event_enablereviews_0"
                            >
                              Enable
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_enablereviews_1"
                              name="event[enablereviews]"
                              value="false"
                              required="required"
                              className="custom-control-input"
                              checked={enableReviews === false}
                              onChange={(e) => setEnableReviews(false)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_enablereviews_1"
                            >
                              Disable
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* LANGUAGES */}
                      <div className="form-group">
                        <label className="" htmlFor="event_languages">
                          Languages
                        </label>
                        <small
                          id="event_languages_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Select the languages that will be spoken in your event
                        </small>
                        <SearchableDropdown
                          value={languages}
                          options={languagesList}
                          onChange={(selectedOptions) =>
                            setLanguages(selectedOptions)
                          }
                          isMulti={true}
                        />
                      </div>

                      {/* AUDIENCES */}
                      {errors.audiences && (
                        <span style={{ marginTop: 0, color: "red" }}>
                          {errors.audiences}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Audiences</legend>
                        <small
                          id="event_audiences_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Select the audience types that are targeted in your
                          event
                        </small>
                        <div
                          id="event_audiences"
                          aria-describedby="event_audiences_help"
                        >
                          {audienceList &&
                            audienceList.map((audience, index) => {
                              return (
                                <div className="custom-control custom-checkbox custom-control-inline">
                                  <input
                                    type="checkbox"
                                    id={"event_audiences_" + index}
                                    name={index}
                                    className="custom-control-input"
                                    value={audience}
                                    checked={audiences?.includes(audience)}
                                    onChange={() => handleAudiences(audience)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"event_audiences_" + index}
                                  >
                                    {audience}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </fieldset>

                      {/* COUNTRY */}
                      <div className="form-group">
                        <label className="" htmlFor="event_country">
                          Country
                        </label>
                        <small
                          id="event_country_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Select the country that your event represents (ie: A
                          movie &#039;s country of production)
                        </small>

                        <SearchableDropdown
                          value={country}
                          options={countriesList}
                          onChange={(value) => setCountry(value)}
                          isMulti={false}
                        />
                      </div>

                      {/* YOUTUBE VIDEO URL */}
                      <div className="form-group">
                        <label htmlFor="event_youtubeurl">
                          Youtube video url
                        </label>
                        <small
                          id="event_youtubeurl_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          If you have an Youtube video that represents your
                          activities as an event organizer, add it in the
                          standard format:
                          https://www.youtube.com/watch?v=FzG4uDgje3M
                        </small>
                        <input
                          type="text"
                          id="event_youtubeurl"
                          name="event[youtubeurl]"
                          aria-describedby="event_youtubeurl_help"
                          className="form-control"
                          value={videoLink}
                          onChange={(e) => setVideoLink(e.target.value)}
                        />
                      </div>

                      {/* EXTERNAL LINK */}
                      <div className="form-group">
                        <label htmlFor="event_externallink">
                          External link
                        </label>
                        <small
                          id="event_externallink_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          If your event has a dedicated website, enter its url
                          here
                        </small>
                        <input
                          type="text"
                          id="event_externallink"
                          name="event[externallink]"
                          aria-describedby="event_externallink_help"
                          className="form-control"
                          value={externalLink}
                          onChange={(e) => setExternalLink(e.target.value)}
                        />
                      </div>

                      {/* PHONE NUMBER */}
                      {errors.phone_number && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.phone_number}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="event_phonenumber">
                          Contact phone number
                        </label>
                        <small
                          id="event_phonenumber_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Enter the phone number to be called for inquiries
                        </small>
                        <input
                          type="text"
                          id="event_phonenumber"
                          name="event[phonenumber]"
                          aria-describedby="event_phonenumber_help"
                          className="form-control"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>

                      {/* EMAIL ADDRESS */}
                      {errors.email && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.email}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="event_email">
                          Contact email address
                        </label>
                        <small
                          id="event_email_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Enter the email address to be reached for inquiries
                        </small>
                        <input
                          type="text"
                          id="event_email"
                          name="event[email]"
                          aria-describedby="event_email_help"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      {/* TWITTER */}
                      <div className="form-group">
                        <label htmlFor="event_twitter">Twitter</label>
                        <input
                          type="text"
                          id="event_twitter"
                          name="event[twitter]"
                          className="form-control"
                          value={twitterLink}
                          onChange={(e) => setTwitterLink(e.target.value)}
                        />
                      </div>

                      {/* INSTAGRAM */}
                      <div className="form-group">
                        <label htmlFor="event_instagram">Instagram</label>
                        <input
                          type="text"
                          id="event_instagram"
                          name="event[instagram]"
                          className="form-control"
                          value={instagramLink}
                          onChange={(e) => setInstagramLink(e.target.value)}
                        />
                      </div>

                      {/* FACEBOOK */}
                      <div className="form-group">
                        <label htmlFor="event_facebook">Facebook</label>
                        <input
                          type="text"
                          id="event_facebook"
                          name="event[facebook]"
                          className="form-control"
                          value={facebookLink}
                          onChange={(e) => setFacebookLink(e.target.value)}
                        />
                      </div>

                      {/* LINKEDIN */}
                      <div className="form-group">
                        <label htmlFor="event_linkedin">LinkedIn</label>
                        <input
                          type="text"
                          id="event_linkedin"
                          name="event[linkedin]"
                          className="form-control"
                          value={linkedinLink}
                          onChange={(e) => setLinkedinLink(e.target.value)}
                        />
                      </div>

                      {/* ARTISTS */}
                      <div className="form-group">
                        <label htmlFor="event_artists">Artists</label>
                        <small
                          id="event_artists_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Enter the list of artists that will perform in your
                          event (press Enter after each entry)
                        </small>
                        <TagsInput
                          value={Array.isArray(artists) ? artists : []}
                          onChange={(artists) => setArtists(artists)}
                          inputProps={{ placeholder: "Enter Artists" }}
                          onlyUnique={true}
                        />
                      </div>

                      {/* TAGS */}
                      {errors.tags && (
                        <span style={{ marginTop: 0, color: "red" }}>
                          {errors.tags}
                        </span>
                      )}

                      <div className="form-group">
                        <label htmlFor="event_tags">Tags</label>
                        <small
                          id="event_tags_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          To help attendee find your event quickly, enter some
                          keywords that identify your event (press Enter after
                          each entry)
                        </small>

                        <TagsInput
                          value={Array.isArray(tags) ? tags : []}
                          onChange={(tags) => setTags(tags)}
                          inputProps={{ placeholder: "Add tags" }}
                          onlyUnique={true}
                        />
                      </div>

                      {/* MAIN EVENT IMAGE */}
                      {errors.main_event_image && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.main_event_image}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Main event image
                        </legend>
                        <small
                          id="event_imageFile_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Choose the right image to represent your event (We
                          recommend using at least a 1200x600px (2:1 ratio)
                          image )
                        </small>

                        {mainEventImage ? (
                          <>
                            <img
                              src={mainEventImage}
                              alt="Main Event"
                              width={200}
                              height={200}
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                borderRadius: "15px",
                              }}
                            />

                            {/* REMOVE MAIN EVENT IMAGE */}
                            <button
                              onClick={(e) => handleRemoveMainEventImage(e)}
                              className="btn btn-sm btn-danger d-block"
                              style={{ width: "fit-content" }}
                            >
                              Remove
                            </button>
                          </>
                        ) : (
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                onChange={handleMainEventImage}
                                type="file"
                                id="event_imageFile_file"
                                required="required"
                                className="custom-file-input"
                              />
                              <label
                                htmlFor="event_imageFile_file"
                                className="custom-file-label"
                              ></label>
                            </div>
                          </div>
                        )}
                      </fieldset>

                      {/* IMAGES GALLERY */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">
                          Images gallery
                        </legend>
                        <small
                          id="event_images_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          ></i>
                          Add other images that represent your event to be
                          displayed as a gallery
                        </small>
                        <div id="event_images" className="form-collection">
                          {fieldsets}

                          {gallery.length > 0 && (
                            <div className="image-gallery">
                              <div className="row">
                                {/* LIST OF GALLERY IMAGES */}
                                {gallery?.map((image, index) => (
                                  <div key={index} className={"col"}>
                                    <div className="d-flex flex-column ">
                                      <img
                                        src={image}
                                        alt={`Event ${index + 1}`}
                                        width={100}
                                        height={70}
                                        style={{
                                          margin: 10,
                                          marginLeft: 0,
                                          borderRadius: "8px",
                                        }}
                                      />
                                      {image && (
                                        <button
                                          onClick={() =>
                                            handleRemoveGalleryImage(index)
                                          }
                                          className="btn btn-sm btn-danger"
                                          style={{ width: "fit-content" }}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* ADDING A NEW GALLERY IMAGE */}
                          <button
                            onClick={handleAddFieldset}
                            className="mt-3 btn btn-outline-dark btn-sm"
                          >
                            <i className="fas fa-plus-square" /> Add
                          </button>
                        </div>
                      </fieldset>

                      {/* EVENT REPEATS */}
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div>
                          <label htmlFor="event_repeats">Event Repeats</label>
                        </div>

                        <div className="ml-auto">
                          <Switch
                            id="event_repeats"
                            color="warning"
                            onChange={(e) => setEventRepeats(!eventRepeats)}
                            checked={eventRepeats}
                          />
                        </div>
                      </div>

                      {/* USER SELECTING HOW OFTEN THE EVENT REPEATS */}
                      {eventRepeats && (
                        <>
                          <div className="form-group mt-3">
                            <label htmlFor="event_tags">Repeat Every</label>
                            <small
                              id="event_tags_help"
                              className="form-text text-muted mb-3"
                            >
                              <i
                                className="fas fa-info-circle mr-1"
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                              ></i>
                              How often should the event repeat. e.g 2
                            </small>
                            <input
                              type="number"
                              value={repeat_every}
                              onChange={(e) => setRepeatEvery(e.target.value)}
                              className="form-control"
                              onlyUnique={true}
                            />
                          </div>

                          {/* SELECTING THE REPEAT METRIC */}
                          <div className="form-group" style={{ zIndex: 1000 }}>
                            <label htmlFor="event_tags">Repeat Metric</label>
                            <small
                              id="event_tags_help"
                              className="form-text text-muted mb-3"
                            >
                              <i
                                className="fas fa-info-circle mr-1"
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                              ></i>
                              Choose the Repeat Metric, e.g weeks if event
                              happens every after a given number of weeks.
                            </small>
                            <SearchableDropdown
                              value={repeat_metric}
                              options={repeatMetrics}
                              onChange={handleRepeatMetric}
                            />
                          </div>
                        </>
                      )}

                      {/* EVENT DATES */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Event Dates
                        </legend>
                        <div
                          id="event_eventdates"
                          className="form-collection eventdates-collection manual-init"
                        >
                          {eventdates}

                          {/* ADD NEW EVENT DATE */}
                          {/* <button
                            onClick={handleAddEventDate}
                            className="mr-3 mt-4 btn btn-outline-dark btn-sm "
                            style={{}}
                          >
                            <i className="fas fa-plus-square" /> Add a new event
                            date
                          </button> */}
                        </div>
                      </fieldset>

                      {/* SAVE */}
                      <div className="form-group">
                        <button
                          onClick={handleEditEvent}
                          id="event_save"
                          name="event[save]"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          SAVE CHANGES
                        </button>

                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-info btn ml-3"
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
