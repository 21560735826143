import { BsThreeDotsVertical } from "react-icons/bs";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../services/helpers";
import { userStore } from "../../../../store/user_store";
import { useSettings } from "../../../../store/setting_store";
import DeleteComponentModal from "../../../../components/delete_modal/DeleteComponentModal";
import Dropdown from "react-bootstrap/Dropdown";

const PopingOutMyScanners = ({ scanner_id, setIsLoading }) => {
  const deleteScanner = userStore((state) => state.deleteScanner);
  const layout_page = useSettings((state) => state.layout_page);

  // METHOD TO HANDLE DELETING THE SCANNER
  const handleDeleteScanner = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteScanner(scanner_id);
    if (response) {
      displaySuccessToast("Scanner deleted");
    } else {
      displayErrorToast("Something went wrong while deleting the scanner");
    }
    setIsLoading(false);
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <DeleteComponentModal
            itemName="Scanner"
            handleDelete={handleDeleteScanner}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PopingOutMyScanners;
