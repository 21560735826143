// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link i {
  margin-right: 3px !important;
}

button:focus {
  outline: none !important;
  /* background-color: #f67611 !important; */
  border: none !important;
  /* color: white; */
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,0CAA0C;EAC1C,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".nav-link i {\r\n  margin-right: 3px !important;\r\n}\r\n\r\nbutton:focus {\r\n  outline: none !important;\r\n  /* background-color: #f67611 !important; */\r\n  border: none !important;\r\n  /* color: white; */\r\n}\r\n\r\n.navbar-toggler:focus {\r\n  box-shadow: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
