import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import { Link } from "react-router-dom";
import { useSettings } from "../store/setting_store";
import { useBlogs } from "../store/blog_store";

function Howitworks() {
  const layout_page = useSettings((state) => state.layout_page);
  const getArticles = useBlogs((state) => state.getArticles);
  const articles = useBlogs((state) => state.articles);

  const [organizerArticles, setOrganizerArticles] = useState([]);
  const [attendeeArticles, setAttendeeArticles] = useState([]);

  const [filteredArticles, setFilteredArticles] = useState([]);
  const [filteredArticless, setFilteredArticless] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getArticles();

      const articlesOfOrganizer = articles.filter((article) => {
        return (
          article.userCategory === "Organizer" &&
          article.featured === true &&
          article.status === "visible"
        );
      });
      setOrganizerArticles(articlesOfOrganizer);

      const articlesOfAttendee = articles.filter((article) => {
        return (
          article.userCategory === "Attendee" &&
          article.featured === true &&
          article.status === "visible"
        );
      });
      setAttendeeArticles(articlesOfAttendee);

      setFilteredArticles(articlesOfAttendee);
      setFilteredArticless(articlesOfOrganizer);
    };

    fetchData();
  }, [articles.length]);

  function filterArticles(query) {
    const filteredAttendeeArticles = attendeeArticles.filter((article) => {
      const articleName = article.title.toLowerCase();
      return articleName.includes(query.toLowerCase());
    });
    setFilteredArticles(filteredAttendeeArticles);

    const filteredOrganizerArticles = organizerArticles.filter((article) => {
      const articleName = article.title.toLowerCase();
      return articleName.includes(query.toLowerCase());
    });
    setFilteredArticless(filteredOrganizerArticles);
  }

  return (
    <div>
      <Header currentPage={"how_it_works"} />

      <section
        className="section-intro  padding-y-lg padding-x-md"
        style={{
          backgroundColor: layout_page?.primary_color,
        }}
      >
        <div className="row">
          <div className="col-sm-6 mx-auto">
            <article className="white text-center mb-5">
              <h1 className="display-4">How can we help?</h1>
              <form className="mb-3">
                <div className="input-icon">
                  <i className="fa fa-search mt-2" />
                  <input
                    name="keyword"
                    className="form-control"
                    placeholder="Search for answers"
                    type="text"
                    onChange={(e) => filterArticles(e.target.value)}
                  />
                </div>
              </form>
              <p>
                Organizers, attendees, this support center is intended to
                quickly reply to your questions, if still don't find answers,
                please contact us, we will be happy to receive your inquiry
              </p>
            </article>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="padding-y-lg">
          <div className="row">
            <div className="col-md-6 mb-3">
              <article className="box h-100 padding-y-lg">
                <figure className="itembox text-center">
                  <span
                    className="mt-2 icon-wrap rounded icon-lg text-white"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                  >
                    <i className="fas fa-user-alt  white" />
                  </span>
                  <figcaption className="text-wrap">
                    <h5 className="title">Attendee</h5>
                  </figcaption>
                </figure>
                <hr />

                {filteredArticles.slice(0, 10).map((article) => (
                  <Link
                    key={article?.id}
                    to={"/attendee_articles/" + article?.id}
                  >
                    <p
                      style={{
                        color: layout_page?.primary_color,
                        cursor: "pointer",
                      }}
                    >
                      {article?.title}
                    </p>
                  </Link>
                ))}

                <hr />
                <Link
                  className="btn btn-outline-primary btn-block"
                  to="/attendee_support"
                >
                  See more articles
                  <i className="fas fa-chevron-right ml-3" />
                </Link>
              </article>
            </div>
            <div className="col-md-6 mb-3">
              <article className="box h-100 padding-y-lg">
                <figure className="itembox text-center">
                  <span
                    className="mt-2 icon-wrap rounded icon-lg text-white"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                  >
                    <i className="fas fa-calendar-plus  white" />
                  </span>
                  <figcaption className="text-wrap">
                    <h5 className="title">Organizer</h5>
                  </figcaption>
                </figure>
                <hr />

                {filteredArticless.slice(0, 10).map((article) => (
                  <Link
                    key={article?.id}
                    to={"/organizer_articles/" + article?.id}
                  >
                    <p
                      style={{
                        color: layout_page?.primary_color,
                        cursor: "pointer",
                      }}
                    >
                      {article?.title}
                    </p>
                  </Link>
                ))}

                <hr />
                <Link
                  className="btn btn-outline-primary btn-block"
                  to="/organizer_support"
                >
                  See more articles
                  <i className="fas fa-chevron-right ml-3" />
                </Link>
              </article>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <article className="box mt-3 text-center">
                <figure>
                  <div className="aside align-self-center">
                    <span
                      className="icon-wrap icon-md round text-white"
                      style={{
                        backgroundColor: layout_page?.primary_color,
                      }}
                    >
                      <i className="fas fa-life-ring white" />
                    </span>
                  </div>
                  <figcaption className="text-wrap">
                    <h5 className="text-muted mt-4 mb-4">
                      You did not find an answer to your inquiry? Let us know
                      and we will be glad to give you further help
                    </h5>
                    <a
                      href="/contact"
                      className="btn btn-dark justify-content-center rounded-0"
                    >
                      CONTACT US
                    </a>
                  </figcaption>
                </figure>
              </article>{" "}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Howitworks;
