/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import PopingOut from "./PopingOut";
import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import { useEvents } from "../../store/event_store";
import { userStore } from "../../store/user_store";
import CustomPagination from "../../components/pagination/Pagination";
import TicketsNumber from "./components/TicketsNumber";
import CircleProgressBar from "./components/CircleProgressBar";
import { useSettings } from "../../store/setting_store";
import Status from "../admin_dashboard/events/Status";
import { convertTimestampToDateTime } from "../../services/helpers";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import { auth } from "../../Firebase";

export default function Myevents() {
  const navigate = useNavigate();
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const organizerEvents = useEvents((state) => state.organizerEvents);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);
  const layout_page = useSettings((state) => state.layout_page);
  const eventCategories = useEvents((state) => state.eventCategories);
  const events = useEvents((state) => state.events);

  // OTHER VARIABLES
  const [organizerDetails, setOrganizerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [eventsToDisplay, setEventsToDisplay] = useState(organizerEvents);
  const [eventCategoriesOptions, setEventCategoriesOptions] = useState([]);
  const [event_category, setEventCategory] = useState("");
  const [publishedFilter, setPublishedFilter] = useState("all");

  // FUNCTION TO GET THE DETAILS OF THE USER

  useEffect(() => {
    async function handleGetOrganizerEvents() {
      // GETTING THE ORGANIZER EVENTS
      const events = await getOrganizerEvents(user?.user_id);
      setEventsToDisplay(events);
    }
    handleGetOrganizerEvents();
  }, [organizerEvents.length, events.length, eventsToDisplay.length]);

  useEffect(() => {
    // FUNCTION TO GET THE DETAILS OF THE USER
    const fetchData = async () => {
      const organizerDetails = await getOrganizerDetails(user?.user_id);
      setOrganizerDetails(organizerDetails);
    };

    const getEventCategoriesOptions = () => {
      const categoriesOptions = eventCategories.map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      });
      setEventCategoriesOptions(categoriesOptions);
    };
    getEventCategoriesOptions();

    fetchData();
  }, [eventCategories.length]);

  // METHOD TO FILTER EVENTS
  function filterEvents(query) {
    const filteredEvents = organizerEvents.filter((event) => {
      const eventName = event.name.toLowerCase();
      return eventName.includes(query.toLowerCase());
    });
    setEventsToDisplay(filteredEvents);
  }

  //FILTER BY CATEGORY
  function filterCategory(selectedCategoryId) {
    if (selectedCategoryId === "all") {
      setEventsToDisplay(organizerEvents);
      setEventCategory("all");
      return;
    }

    const filteredEvents = organizerEvents.filter((event) => {
      return event?.category?.value === selectedCategoryId;
    });

    setEventCategory(selectedCategoryId);
    setEventsToDisplay(filteredEvents);
  }

  //FILTER BY VENUE
  function filterVenue(query) {
    if (query.trim() === "") {
      setEventsToDisplay(organizerEvents);
      return;
    }
    const filteredVenues = organizerEvents.filter((event) => {
      const filteredDates = event.event_dates.filter((eventDate) => {
        return eventDate.venue?.toLowerCase().includes(query.toLowerCase());
      });
      return filteredDates.length > 0;
    });
    setEventsToDisplay(filteredVenues);
  }

  const filterPublishedEvents = eventsToDisplay.filter((event) => {
    const currentDate = new Date();
    const eventStartDate = convertTimestampToDateTime(
      event?.event_dates[0]?.start_date
    );
    const eventEndDate = convertTimestampToDateTime(
      event?.event_dates[0]?.end_date
    );

    return (
      publishedFilter === "all" ||
      (publishedFilter === "published" &&
        (event.visibility === "published" ||
         event.visibility === "" ||
         event.visibility === null)) ||
      (publishedFilter === "draft" &&event.visibility === "draft") ||
      (publishedFilter === "upcoming" && eventStartDate >= currentDate) ||
      (publishedFilter === "past" && eventEndDate < currentDate)
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedEvents = filterPublishedEvents?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    if (sortCriteria === "views") {
      if (sortDirection === "desc") {
        // Sort by popularity (views) in descending order
        return b.views - a.views;
      } else {
        // Sort by popularity (views) in ascending order
        return a.views - b.views;
      }
    } else if (sortCriteria === "created_at") {
      // Sort by creation date
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      // Handle other criteria sorting here
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(10);
  //get current events
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFastEvent = indexOfLastEvent - eventsPerPage;
  const current_events = sortedEvents?.slice(
    indexOfFastEvent,
    indexOfLastEvent
  );

  // DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"My Events"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"myevents"} />}
              </aside>

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0 d-flex justify-content-start align-content-center">
                      <span className="center-lg-y text-muted">
                        {organizerEvents?.length} Event result(s) found
                      </span>
                    </div>

                    <div className="col-sm-12 col-lg-8 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at-desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>
                          <option
                            value={`created_at-asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                          <option
                            value={`views-desc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "desc"
                            }
                          >
                            Popularity (desc)
                          </option>
                          <option
                            value={`views-asc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "asc"
                            }
                          >
                            Popularity (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add_event"
                        className="btn text-white ml-3 my-2"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                      >
                        <i className="fas fa-plus" /> Add a new event
                      </Link>
                    </div>

                    <div className="col-sm-12 col-lg-2 text-center text-lg-right mt-2">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="search-filters"
                        data-original-title="Toggle display the search filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />

                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="slug">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="slug"
                              name="slug"
                              data-url-list="/en/api/get-events?organizer=mestil-hotel-1&published=all&elapsed=all"
                              data-minimum-input-length={0}
                              onChange={(e) => filterEvents(e.target.value)}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="category">Category</label>
                            <SearchableDropdown
                              value={event_category}
                              options={[
                                { value: "all", label: "All" }, // Add the "All" option
                                ...eventCategoriesOptions, // Add the existing category options
                              ]}
                              isMulti={false}
                              onChange={(selectedOption) =>
                                filterCategory(selectedOption.value)
                              }
                            />
                          </div>

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="venue">Venue</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="slug"
                              name="slug"
                              data-minimum-input-length={1}
                              onChange={(e) => filterVenue(e.target.value)}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="elapsed-filter-all"
                                type="radio"
                                name="all"
                                defaultValue="all"
                                className="custom-control-input"
                                checked={publishedFilter === "all"}
                                onChange={() => setPublishedFilter("all")}
                              />
                              <label
                                htmlFor="elapsed-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>

                            {/* filter by upcoming */}
                            <div className="custom-control custom-checkbox">
                              <input
                                id="upcoming-filter-yes"
                                type="radio"
                                name="upcoming"
                                value="upcoming"
                                className="custom-control-input"
                                checked={publishedFilter === "upcoming"}
                                onChange={() => setPublishedFilter("upcoming")}
                              />
                              <label
                                htmlFor="upcoming-filter-yes"
                                className="custom-control-label"
                              >
                                Upcoming only
                              </label>
                            </div>
                            {/* filter by past  */}

                            <div className="custom-control custom-checkbox">
                              <input
                                id="upcoming-filter-no"
                                type="radio"
                                name="past"
                                value="past"
                                className="custom-control-input"
                                checked={publishedFilter === "past"}
                                onChange={() => setPublishedFilter("past")}
                              />
                              <label
                                htmlFor="upcoming-filter-no"
                                className="custom-control-label"
                              >
                                Past only
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-all"
                                type="radio"
                                name="all-published"
                                value="all"
                                className="custom-control-input"
                                checked={publishedFilter === "all"}
                                onChange={() => setPublishedFilter("all")}
                              />
                              <label
                                htmlFor="published-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            {/* filter by published */}
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-yes"
                                type="radio"
                                name="published"
                                value="published"
                                className="custom-control-input"
                                checked={publishedFilter === "published"}
                                onChange={() => setPublishedFilter("published")}
                              />
                              <label
                                htmlFor="published-filter-yes"
                                className="custom-control-label"
                              >
                                Published only
                              </label>
                            </div>
                            {/* filter by draft */}

                            <div className="custom-control custom-checkbox">
                              <input
                                id="draft-filter-yes"
                                type="radio"
                                name="draft"
                                value="draft"
                                className="custom-control-input"
                                checked={publishedFilter === "draft"}
                                onChange={() => setPublishedFilter("draft")}
                              />
                              <label
                                htmlFor="draft-filter-yes"
                                className="custom-control-label"
                              >
                                Draft only
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {organizerEvents?.length === 0 ? (
                  //IF NO EVENTS FOUND
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" />
                    No events found
                  </div>
                ) : (
                  //DISPLAYING USER EVENTS
                  <div className=" table-responsive">
                    <table className="table table-hover table-vcenter">
                      <thead>
                        <tr>
                          <th style={{ width: "35%" }}>Event</th>
                          <th>Organizer</th>
                          <th>Sales</th>
                          <th>Status</th>
                          <th>Attendance</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {current_events?.map((event, index) => {
                          return (
                            <tr key={event?.id}>
                              {/* EVENT DETAILS */}
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {/* EVENT IMAGE */}
                                  <Link
                                    className="fancybox"
                                    to={"/event_details/" + event?.id}
                                    data-toggle="tooltip"
                                    data-original-title="Enlarge"
                                  >
                                    <img
                                      src={event?.main_event_image}
                                      className="img-thumbnail img-50-50"
                                      alt={event?.name}
                                    />
                                  </Link>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={"/event_details/" + event?.id}
                                      data-toggle="tooltip"
                                      data-original-title="View event"
                                    >
                                      {event?.name}
                                    </Link>
                                    {/* <span className="small text-muted"># {event.street_address}</span> */}
                                  </div>
                                </div>
                              </td>

                              {/* EVENT ORGANIZER */}
                              <td>
                                <Link
                                  style={{
                                    color: layout_page?.primary_color,
                                  }}
                                  to={
                                    "/organizer_details/" + event?.organizer_id
                                  }
                                  data-toggle="tooltip"
                                  data-original-title="View organizer"
                                >
                                  {organizerDetails?.organizer_name}
                                </Link>
                              </td>

                              {/* NUMBER OF TICKETS */}
                              <td>
                                <TicketsNumber
                                  event_id={event?.id}
                                  number_of_tickets={
                                    event?.event_dates[index]?.tickets[index]
                                      ?.number_of_tickets
                                  }
                                />
                              </td>

                              {/* STATUS */}
                              <td>
                                <Status event={event} />
                              </td>

                              {/* ATTENDEES */}
                              <td className="text-center">
                                <CircleProgressBar event_id={event?.id} />
                              </td>

                              {/* ACTIONS */}
                              <td className="text-center">
                                <PopingOut
                                  event_id={event?.id}
                                  setIsLoading={setIsLoading}
                                  status={event?.visibility}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              {/* PAGINATION */}
              {sortedEvents?.length > 10 && (
                <CustomPagination
                  setCurrentPage={setCurrentPage}
                  totalPages={Math.ceil(sortedEvents?.length / eventsPerPage)}
                />
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
