// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jWujcK {
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  border: none !important;
  border-radius: 0.25rem;
  background-clip: padding-box;
  background-color: #f1f3f7;
  border-color: none !important;
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin_dashboard/events/icon_style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,2DAA2D;EAC3D,cAAc;EACd,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;EAC5B,yBAAyB;EACzB,6BAA6B;EAC7B,sBAAsB;AACxB","sourcesContent":[".jWujcK {\r\n  height: calc(2.75rem + 2px);\r\n  padding: 0.625rem 0.75rem;\r\n  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);\r\n  color: #8898aa;\r\n  border: none !important;\r\n  border-radius: 0.25rem;\r\n  background-clip: padding-box;\r\n  background-color: #f1f3f7;\r\n  border-color: none !important;\r\n  width: 100% !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
