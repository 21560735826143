import React, { useContext, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { IconPicker } from "react-fa-icon-picker-alen";
import "./icon_style.css";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Addeventscategory() {
  const navigate = useNavigate();

  const addNewEventcategory = useEvents((state) => state.addNewEventcategory);
  const layout_page = useSettings((state) => state.layout_page);

  const uploadeventsCategoryImage = useEvents(
    (state) => state.uploadeventsCategoryImage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(null);
  const [icon, setIcon] = useState(null);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [displayorder, setDisplayorder] = useState(null);

  //error validation
  function validateForm() {
    const errors = {};
    if (!name) {
      errors.name = "name is required";
    }
    if (!image) {
      errors.image = "image is required";
    }
    if (!icon) {
      errors.icon = "Icon is important";
    }
    return errors;
  }

  const handleAddEventsCategory = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      try {
        addNewEventcategory({
          name: name,
          icon: icon,
          image: image,
          displayorder: displayorder,
          created_at: Timestamp.fromDate(new Date()),
        });
        setIsLoading(false);
        displaySuccessToast("Event category Added Successfully");
        navigate("/manage_event_categories");
      } catch (error) {
        displayErrorToast("Event Category failed to be added");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLING IMAGE CAPTURE
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setImage(file);
    const imageUrl = await uploadeventsCategoryImage(file);
    setImage(imageUrl);
  };

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new category"
          page_link="Manage Categories"
          link="manage_event_categories"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_event_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/* INPUT FORM */}
                    <form
                      name="category"
                      onSubmit={handleAddEventsCategory}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <div id="category">
                        {/* INPUT FOR NAMES */}
                        <fieldset className="form-group">
                          <div className="tab-content">
                            <div
                              id="category_translations_en_a2lix_translations-fields"
                              className="tab-pane show active "
                              role="tabpanel"
                            >
                              <div id="category_translations_en">
                                <div className="form-group d-grid">
                                  <label
                                    htmlFor="category_translations_en_name"
                                    className="required"
                                  >
                                    Name
                                  </label>
                                  {errors.name && (
                                    <span className=" text-sm font-weight-bold text-danger">
                                      {errors.name}
                                    </span>
                                  )}
                                  <input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    id="category_translations_en_name"
                                    name="category[translations][en][name]"
                                    required="required"
                                    maxLength={50}
                                    pattern=".{1,}"
                                    className={
                                      errors.name
                                        ? " border border-danger form-control"
                                        : "form-control"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {/* INPUT FOR ICONS */}
                        <div className="form-group d-grid">
                          <label htmlFor="category_icon" className="required">
                            Icon
                          </label>
                          {errors.icon && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.icon}
                            </span>
                          )}
                          <IconPicker
                            value={icon}
                            onChange={(v) => setIcon(v)}
                          />
                        </div>

                        {/* INPUT FOR IMAGES */}
                        <fieldset className="form-group d-grid">
                          <legend className="col-form-label">Image</legend>
                          {errors.image && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.image}
                            </span>
                          )}
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                onChange={handleFileSelect}
                                id="category_imageFile_file"
                                name="category[imageFile][file]"
                                className={
                                  errors.image
                                    ? "border border-danger custom-file-input "
                                    : "custom-file-input"
                                }
                              />
                              <label
                                htmlFor="category_imageFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        </fieldset>

                        {/* INPUT FOR DISPALY ORDER */}
                        <div className="form-group">
                          <label className htmlFor="category_featuredorder">
                            Featured order
                          </label>
                          <small
                            id="category_featuredorder_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Set the display order for the featured categories
                          </small>
                          <select
                            id="category_featuredorder"
                            onChange={(e) =>
                              setDisplayorder(parseInt(e.target.value))
                            }
                            name="category[featuredorder]"
                            aria-describedby="category_featuredorder_help"
                            className="form-control"
                          >
                            <option value />
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="category_save"
                            name="category[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
