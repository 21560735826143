/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Tickets from "./pages/attendee_dashboard/tickets";
import Reviews from "./pages/attendee_dashboard/reviews";
import CheckEmail from "./pages/auth/check_email";
import SignupConfirmed from "./pages/auth/signup_confirmed";
import Signin from "./pages/auth/sign_in";
import Signupatt from "./pages/auth/sign_up_attendee";
import Signuporg from "./pages/auth/sign_up_organizer";
import Blogs from "./pages/blogs/blogs";
import BrowseEvents from "./pages/browse_events/browse_events";
import Dashboardorganizer from "./pages/organizer_dashboard/dashboard_organizer";
import OrganizerProfile from "./pages/organizer_dashboard/organizer_profile";
import HomePage from "./pages/home/home";
import Howitworks from "./pages/how_it_works";
import Venues from "./pages/venues/venues";
import Cart from "./pages/attendee_dashboard/cart";
import Favorites from "./pages/attendee_dashboard/favorites";
import Following from "./pages/attendee_dashboard/following";
import Checkout from "./pages/attendee_dashboard/checkout";
import ResetPassword from "./pages/auth/forgot_password";
import Myevents from "./pages/organizer_dashboard/myevents";
import Mypointsofsale from "./pages/organizer_dashboard/mypointsofsale";
import Myvenues from "./pages/organizer_dashboard/myvenues";
import Payoutmethods from "./pages/organizer_dashboard/payoutmethods";
import Payoutrequests from "./pages/organizer_dashboard/payoutrequests";
import Reports from "./pages/organizer_dashboard/reports";
import MyReviews from "./pages/organizer_dashboard/my_reviews";
import Previeworganizerprofile from "./pages/organizer_dashboard/previeworg_profile";
import AddEvent from "./pages/organizer_dashboard/add_event";
import Addnewvenue from "./pages/organizer_dashboard/addanewvenue";
import AddPointOfSale from "./pages/organizer_dashboard/addpointsofsale";
import Paypalpayoutmethod from "./pages/organizer_dashboard/paypalpayout";
import Stripepayout from "./pages/organizer_dashboard/stripepayout";
import TicketDetails from "./pages/attendee_dashboard/ticket_details";
import EventDetails from "./pages/event_details";
import WriteAReview from "./pages/attendee_dashboard/write_a_review";
import OrganizerDetails from "./pages/organizer_details/organizer_details";
import AdminDashboard from "./pages/admin_dashboard/admin_dashbaord";
import Layout from "./pages/admin_dashboard/settings/layout";
import ManageMenus from "./pages/admin_dashboard/settings/manage_menus";
import ManageHomepage from "./pages/admin_dashboard/settings/manage_homepage";
import ManagePayments from "./pages/admin_dashboard/settings/manage_payments";
import ManageCheckout from "./pages/admin_dashboard/settings/manage_checkout";
import GoogleRecaptcha from "./pages/admin_dashboard/settings/google_recaptcha";
import GoogleMaps from "./pages/admin_dashboard/settings/google_maps";
import SocialLogin from "./pages/admin_dashboard/settings/social_login";
import EventsList from "./pages/admin_dashboard/settings/events_list";
import VenueSettings from "./pages/admin_dashboard/settings/venue_settings";
import BlogSettings from "./pages/admin_dashboard/settings/blog_settings";
import ManageNewsletter from "./pages/admin_dashboard/settings/manage_newsletter";
import ManageEvents from "./pages/admin_dashboard/events/manage_events";
import ManageCategories from "./pages/admin_dashboard/events/manage_categories";
import ManageCountries from "./pages/admin_dashboard/events/manage_countries";
import ManageLanguages from "./pages/admin_dashboard/events/manage_languages";
import ManageAudiences from "./pages/admin_dashboard/events/manage_audiences";
import ManageVenues from "./pages/admin_dashboard/venues/manage_venues";
import VenueTypes from "./pages/admin_dashboard/venues/venue_types";
import ManageAmenities from "./pages/admin_dashboard/venues/manage_amenities";
import ManageBlogPosts from "./pages/admin_dashboard/blog/manage_blog_posts";
import ManageBlogpostCategories from "./pages/admin_dashboard/blog/manage_blogpost_categories";
import ManageArticles from "./pages/admin_dashboard/help_center/manage_articles";
import ManageUserCategories from "./pages/admin_dashboard/help_center/manage_user_categories";
import ChangePassword from "./pages/admin_dashboard/account/change_password";
import ManageOrders from "./pages/admin_dashboard/manage_orders";
import PayoutRequests from "./pages/admin_dashboard/payout_requests";
import ManageUsers from "./pages/admin_dashboard/manage_users";
import Pages from "./pages/admin_dashboard/pages";
import ManageReviews from "./pages/admin_dashboard/manage_reviews";
import ManageReports from "./pages/admin_dashboard/manage_reports";
import AboutUs from "./pages/about_us";
import Contact from "./pages/contact";
import PricingAndFees from "./pages/pricing_and_fees";
import TermsOfService from "./pages/terms_of_service";
import PrivacyPolicy from "./pages/privacy_policy";
import CookiePolicy from "./pages/cookie_policy";
import GdprCompliance from "./pages/gdpr_compliance";
import Categories from "./pages/categories";
import Articles from "./pages/articles/articles";
import AttendeeSupport from "./pages/articles/attendee_support";
import OrganizerSupport from "./pages/articles/organizer_support";
import PageNotFound from "./pages/404";
import OrderDetails from "./pages/admin_dashboard/order_details";
import BlogDetails from "./pages/blogs/blog_details";
import Addnewpaymentgateway from "./pages/admin_dashboard/settings/addanewpaymentgateway";
import ManageAddNew_Venuetype from "./pages/admin_dashboard/venues/manage_addvenue_type";
import Manageamenities_add from "./pages/admin_dashboard/venues/manage_addnewamenity";
import AddNewBlogPost from "./pages/admin_dashboard/blog/addblogpost";
import AddBlogPostCategory from "./pages/admin_dashboard/blog/add_blogpost_category";
import AddHelpCenterArticle from "./pages/admin_dashboard/help_center/add_helpcenter_article";
import AddUserCategory from "./pages/admin_dashboard/help_center/add_user_category";
import Addeventscategory from "./pages/admin_dashboard/events/add_events_category";
import Addnewcountry from "./pages/admin_dashboard/events/add_new_country";
import Manageaddlanguages from "./pages/admin_dashboard/events/manage_addlanguages";
import Manageaddaudiences from "./pages/admin_dashboard/events/manage_addaudience";
import ManageCurrencies from "./pages/admin_dashboard/settings/manage_currencies";
import AddCurrency from "./pages/admin_dashboard/settings/add_currency";
import AccessDenied from "./pages/access_denied";
import VenueDetails from "./pages/venues/venue_details";
import EventDetail from "./components/eventDetail/EventDetail";
import CheckoutEvent from "./components/eventDetail/CheckoutEvent";
import ScannerSettings from "./pages/organizer_dashboard/scanners/scannerSettings/ScannerSettings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTopButton from "./components/Scrolltopbutton/scrolltopbutton";
import EditEvent from "./pages/organizer_dashboard/EditEvent";
import SuccessScreen from "./pages/success_screen/success_screen";
import EditVenue from "./pages/organizer_dashboard/EditVenue";
import Addnewscanner from "./pages/organizer_dashboard/scanners/myScanners/addanewscanner";
import ScrollToTop from "./components/ScrollToTop";
import EditEventsCategory from "./pages/admin_dashboard/events/EditEventsCategory";
import EditCountry from "./pages/admin_dashboard/events/EditCountry";
import EditLanguages from "./pages/admin_dashboard/events/EditLanguages";
import EditAudiences from "./pages/admin_dashboard/events/EditAudience";
import EditVenueType from "./pages/admin_dashboard/venues/EditVenueType";
import EditAmmenity from "./pages/admin_dashboard/venues/EditAmmenity";
import EditBlogPost from "./pages/admin_dashboard/blog/EditBlogPost";
import EditBlogPostCategory from "./pages/admin_dashboard/blog/EditBlogCategory";
import EditHelperArticle from "./pages/admin_dashboard/help_center/EditHelperArticle";
import EditUserCategory from "./pages/admin_dashboard/help_center/EditUserCategory";
import ManageOrganizerOrders from "./pages/organizer_dashboard/ManageOrganizerOrders";
import MyPromocodes from "./pages/organizer_dashboard/my_promocodes";
import AddPromoCode from "./pages/organizer_dashboard/add_promocode";
import EditPromoCode from "./pages/organizer_dashboard/edit_promocode";
import MyScanners from "./pages/organizer_dashboard/scanners/myScanners/MyScanners";
import PaymentMethodDetails from "./components/payment_method_details/PaymentMethodDetails";
import BeneficiariesPage from "./components/beneficiaries/BeneficiariesPage";
import AddBeneficiary from "./components/beneficiaries/AddBeneficiary";
import Attendees from "./pages/organizer_dashboard/attendees";
import EventStatisctics from "./pages/admin_dashboard/events/EventStatistics";
import AddPage from "./pages/admin_dashboard/components/AddPage";
import EditPage from "./pages/admin_dashboard/EditPage";
import { useSettings } from "./store/setting_store";
import EditPaymentGateways from "./pages/admin_dashboard/settings/EditPaymentGateways";
import MutualFriends from "./components/mutual_friends/MutualFriends";
import EditCurrency from "./pages/admin_dashboard/settings/EditCurrency";
import ManageEventOrders from "./pages/admin_dashboard/manage_event_orders";
import ManageEventReviews from "./pages/admin_dashboard/manage_event_reviews";
import ManageEvent from "./pages/organizer_dashboard/manage_event";
import AttendeeArticles from "./pages/articles/attendeeArticles";
import OrganizerArticles from "./pages/articles/organizerArticles";
import Promocodes from "./pages/admin_dashboard/events/PromoCodes";
import EditPromoCodes from "./pages/admin_dashboard/events/Edit_promocodes";
import ManageTablesBookings from "./pages/admin_dashboard/tables/ManageTablesBookings";
import DownloadModal from "./components/popupmodel/PopupModel";
import { isMobile } from "react-device-detect";
import PopoverAboveMobile from "./components/popupmodel/PopoverAboveMobile";
import AddEventIdeas from "./pages/AddEventIdeas";
import ManageEventIdeas from "./pages/admin_dashboard/events/ManageEventIdeas";
import AddEevents from "./pages/admin_dashboard/events/add_events";

function App() {
  //CHANGING THE FAVICON IMAGE DYNAMICALLY
  const layout_page = useSettings((state) => state.layout_page);
  useEffect(() => {
    const updateFavicon = () => {
      const faviconLink = document.querySelector("link[rel='icon']");
      faviconLink.href = layout_page?.favicon;
    };

    updateFavicon();
  }, [layout_page?.favicon]);

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ScrollToTop>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/event_detail/:event_id" element={<EventDetail />} />
          <Route path="/checkout_event_detail" element={<CheckoutEvent />} />

          <Route path="/" element={<HomePage />} />
          <Route path="/browse_events/:query?" element={<BrowseEvents />} />
          <Route path="/venues" element={<Venues />} />
          <Route
            exact
            path="/venue_details/:venueId"
            element={<VenueDetails />}
          />
          <Route path="/how_it_works" element={<Howitworks />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/event_details/:id" element={<EventDetails />} />
          <Route path="/organizer_details/:id" element={<OrganizerDetails />} />
          <Route path="/access_denied" element={<AccessDenied />} />
          <Route
            exact
            path="/blog_details/:blogpostId"
            element={<BlogDetails />}
          />

          {/* AUTHENTICATION ROUTES */}
          <Route path="/sign_in" element={<Signin />} />
          <Route path="/sign_up_attendee" element={<Signupatt />} />
          <Route path="/sign_up_organizer" element={<Signuporg />} />
          <Route path="/check_email" element={<CheckEmail />} />
          <Route path="/signup_confirmed" element={<SignupConfirmed />} />
          <Route path="/forgot_password" element={<ResetPassword />} />

          {/*ORGANIZER DASHBOARD ROUTES */}
          <Route path="/attendees/:event_id" element={<Attendees />} />
          <Route path="/my_promocodes" element={<MyPromocodes />} />
          <Route
            path="/edit_promocode/:promocode_id"
            element={<EditPromoCode />}
          />
          <Route path="/add_promocode" element={<AddPromoCode />} />
          <Route path="/dashboard_organizer" element={<Dashboardorganizer />} />
          <Route path="/my_scanners" element={<MyScanners />} />
          <Route path="/scanner_settings" element={<ScannerSettings />} />
          <Route path="/organizer_profile" element={<OrganizerProfile />} />
          <Route path="/myevents" element={<Myevents />} />
          <Route path="/manage_event/:event_id" element={<ManageEvent />} />
          <Route path="/mypointsofsale" element={<Mypointsofsale />} />
          <Route path="/myvenues" element={<Myvenues />} />
          <Route path="/payoutmethods" element={<Payoutmethods />} />
          <Route
            path="/payoutmethods/:payout_slug"
            element={<PaymentMethodDetails />}
          />
          <Route path="/payoutrequests" element={<Payoutrequests />} />
          <Route path="/my_reports" element={<Reports />} />
          <Route path="/my_reviews/:id?" element={<MyReviews />} />
          <Route
            path="/manage_organizer_orders/:id"
            element={<ManageOrganizerOrders />}
          />
          <Route
            path="/previeworg_profile"
            element={<Previeworganizerprofile />}
          />
          <Route path="/add_event" element={<AddEvent />} />
          <Route path="/edit_event/:event_id" element={<EditEvent />} />
          <Route path="/edit_venue/:venue_id" element={<EditVenue />} />
          <Route path="/addanewvenue" element={<Addnewvenue />} />
          <Route path="/addanewscanner" element={<Addnewscanner />} />
          <Route path="/addpointsofsale" element={<AddPointOfSale />} />
          <Route path="/paypalpayout" element={<Paypalpayoutmethod />} />
          <Route path="/stripepayout" element={<Stripepayout />} />

          {/* ATTENDEE DASHBOARD ROUTES*/}
          <Route path="/dashboard" element={<Tickets />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/following" element={<Following />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route
            path="/ticket_details/:booking_id"
            element={<TicketDetails />}
          />
          <Route path="/write_a_review/:event_id" element={<WriteAReview />} />

          {/* ADMIN DASHBOARD ROUTES */}
          <Route path="/promocodes" element={<Promocodes />} />
          <Route
            path="/edit_promocodes/:promo_id"
            element={<EditPromoCodes />}
          />
          <Route path="/admin_dashboard" element={<AdminDashboard />} />
          <Route path="/add_page" element={<AddPage />} />
          <Route path="/add_event_ideas" element={<AddEventIdeas />} />
          <Route path="/mutual_friends" element={<MutualFriends />} />
          <Route path="/manage_layout" element={<Layout />} />
          <Route path="/manage_menus" element={<ManageMenus />} />
          <Route path="/manage_homepage" element={<ManageHomepage />} />
          <Route path="/manage_payments" element={<ManagePayments />} />
          <Route path="/manage_checkout" element={<ManageCheckout />} />
          <Route path="/google_recaptcha" element={<GoogleRecaptcha />} />
          <Route path="/google_maps" element={<GoogleMaps />} />
          <Route path="/social_login" element={<SocialLogin />} />
          <Route path="/events_list" element={<EventsList />} />
          <Route path="/venue_settings" element={<VenueSettings />} />
          <Route path="/blog_settings" element={<BlogSettings />} />
          <Route path="/manage_newsletter" element={<ManageNewsletter />} />
          <Route path="/manage_events/:event_id?" element={<ManageEvents />} />
          <Route path="/add_events" element={<AddEevents />} />

          <Route
            path="/event_statistics/:event_id"
            element={<EventStatisctics />}
          />

          <Route
            path="/manage_event_categories"
            element={<ManageCategories />}
          />
          <Route path="/manage_event_ideas" element={<ManageEventIdeas />} />
          <Route path="/add-new-category" element={<Addeventscategory />} />
          <Route
            path="/edit_category/:category_id"
            element={<EditEventsCategory />}
          />
          <Route path="/manage_countries" element={<ManageCountries />} />
          <Route path="/edit_country/:country_id" element={<EditCountry />} />
          <Route path="/edit_page/:page_id" element={<EditPage />} />
          <Route path="/add-new-country" element={<Addnewcountry />} />
          <Route path="/manage_languages" element={<ManageLanguages />} />
          <Route
            path="/edit_languages/:language_id"
            element={<EditLanguages />}
          />
          <Route path="/add-languages" element={<Manageaddlanguages />} />
          <Route path="/manage_audiences" element={<ManageAudiences />} />
          <Route path="/add-audience" element={<Manageaddaudiences />} />
          <Route
            path="/edit_audience/:audience_id"
            element={<EditAudiences />}
          />
          <Route path="/manage_orders" element={<ManageOrders />} />
          <Route
            path="/manage_event_orders/:event_id"
            element={<ManageEventOrders />}
          />
          <Route path="/payout_requests" element={<PayoutRequests />} />
          <Route path="/manage_reports" element={<ManageReports />} />
          <Route path="/manage_users" element={<ManageUsers />} />
          <Route path="/manage_venues" element={<ManageVenues />} />
          <Route path="/manage_venue_types" element={<VenueTypes />} />
          <Route
            path="/edit-venue-type/:venue_type_id"
            element={<EditVenueType />}
          />
          <Route
            path="/add-new-venue-type"
            element={<ManageAddNew_Venuetype />}
          />
          <Route path="/manage_amenities" element={<ManageAmenities />} />
          <Route
            path="/edit_ammenity/:ammenity_id"
            element={<EditAmmenity />}
          />
          <Route path="/add-new-amenities" element={<Manageamenities_add />} />

          <Route path="/manage_pages" element={<Pages />} />
          <Route path="/manage_reviews" element={<ManageReviews />} />
          <Route
            path="/manage_event_reviews/:event_id"
            element={<ManageEventReviews />}
          />
          <Route path="/manage_blog_posts" element={<ManageBlogPosts />} />
          <Route
            path="/edit_blog_post/:blog_post_id"
            element={<EditBlogPost />}
          />
          <Route
            path="/edit_currency/:currency_id"
            element={<EditCurrency />}
          />
          <Route
            path="/edit_payment_gateway/:gateway_id"
            element={<EditPaymentGateways />}
          />
          <Route path="/add-new-blog-post" element={<AddNewBlogPost />} />
          <Route path="/order_details/:order_id" element={<OrderDetails />} />
          <Route
            path="/add_new_paymentgateway"
            element={<Addnewpaymentgateway />}
          />

          <Route
            path="/manage_blogpost_categories"
            element={<ManageBlogpostCategories />}
          />
          <Route
            path="/edit_blog_category/:blog_category_id"
            element={<EditBlogPostCategory />}
          />
          <Route
            path="/add-blog-post-category"
            element={<AddBlogPostCategory />}
          />

          <Route path="/manage_articles" element={<ManageArticles />} />
          <Route path="/attendee_articles/:id" element={<AttendeeArticles />} />
          <Route
            path="/organizer_articles/:id"
            element={<OrganizerArticles />}
          />
          <Route
            path="/edit_helper_article/:article_id"
            element={<EditHelperArticle />}
          />
          <Route
            path="/add-helpcenter-article"
            element={<AddHelpCenterArticle />}
          />
          <Route
            path="/manage_user_categories"
            element={<ManageUserCategories />}
          />
          <Route path="/add-use-category" element={<AddUserCategory />} />
          <Route
            path="/edit_user_category/:user_category_id"
            element={<EditUserCategory />}
          />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/manage_currencies" element={<ManageCurrencies />} />
          <Route path="/add_currency" element={<AddCurrency />} />
          <Route
            path="/manage_tables_bookings"
            element={<ManageTablesBookings />}
          />

          {/* FOOTER ROUTES */}
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing_and_fees" element={<PricingAndFees />} />
          <Route path="/terms_of_service" element={<TermsOfService />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/cookie_policy" element={<CookiePolicy />} />
          <Route path="/gdpr_compliance" element={<GdprCompliance />} />
          <Route path="/categories" element={<Categories />} />

          {/* ARTICLES */}
          <Route path="/articles" element={<Articles />} />
          <Route path="/attendee_support" element={<AttendeeSupport />} />
          <Route path="/organizer_support" element={<OrganizerSupport />} />

          {/* SUCCESS SCREEN */}
          <Route path="/success_screen" element={<SuccessScreen />} />

          {/* BENEFICIARIES */}
          <Route path="/beneficiaries" element={<BeneficiariesPage />} />
          <Route path="/beneficiaries/add" element={<AddBeneficiary />} />
        </Routes>
      </ScrollToTop>
      <ScrollToTopButton />
      {/* download popover */}
      {isMobile ? <DownloadModal /> : <PopoverAboveMobile />}
    </BrowserRouter>
  );
}

export default App;
