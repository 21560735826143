import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../../user_context";
import { userStore } from "../../../store/user_store";
import TextEditor from "../../../components/text_editor/text_editor";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Addnewpaymentgateway() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const paymentMethods = useSettings((state) => state.paymentMethods);
  const uploadPaymentImage = useSettings((state) => state.uploadPaymentImage);
  const addPaymentGateway = useSettings((state) => state.addPaymentGateway);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();
  const [payment_options, setPaymentOptions] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [payment_gateway, setPaymentGateway] = useState("");
  const [name, setName] = useState("");
  const [appearance_order, setAppearanceOrder] = useState("");
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getOptions = () => {
      const paymentOptions = paymentMethods.map((paymentMethod) => {
        return {
          value: paymentMethod?.id,
          label: paymentMethod?.name,
        };
      });
      setPaymentOptions(paymentOptions);
    };
    getOptions();
  }, [paymentMethods?.length]);

  const handleImage = async (e) => {
    const file = e.target.files[0];
    const imageUrl = await uploadPaymentImage(file);
    setImage(imageUrl);
  };

  // HANDLE Instructions
  const handleInstructions = (e) => {
    setInstructions(e);
  };

  //handling adding payment gateways
  const handleAddPaymentGateway = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = {
        id: null,
        name,
        image,
        instructions,
        appearance_order,
        status,
        payment_gateway,
        sand_box: true,
        username: null,
        password: null,
        signature: null,
      };
      await addPaymentGateway(data);
      displaySuccessToast("Payment gateway added successfully");
      navigate("/manage_payments");
    } catch (error) {
      displayErrorToast("Something went wrong in adding the payment gateway");
    }
    setIsLoading(false);
  };
  //loading state
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new payment gateway"
          page_link="Payment settings"
          link="manage_payments"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="payment_gateway"
                      onSubmit={handleAddPaymentGateway}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <div id="payment_gateway">
                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_name"
                            className="required"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            id="payment_gateway_name"
                            name="payment_gateway[name]"
                            required="required"
                            className="form-control"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="form-group">
                          <label
                            className="required"
                            htmlFor="payment_gateway_factoryName"
                          >
                            Choose a payment gateway
                          </label>
                          <small
                            id="payment_gateway_factoryName_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            For 'Cash / Check / Bank Transfer / Other', the
                            order will remain on the 'Awaiting payment' status
                            until the organizer or the administrator approves it
                          </small>

                          <SearchableDropdown
                            value={payment_gateway}
                            options={payment_options}
                            isMulti={false}
                            onChange={(e) => setPaymentGateway(e)}
                          />
                        </div>

                        <fieldset className="form-group">
                          <legend className="col-form-label">
                            Instructions
                          </legend>
                          <div
                            id="payment_gateway_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="payment_gateway_translations_en">
                              <div className="form-group">
                                <TextEditor
                                  value={instructions}
                                  onChange={handleInstructions}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Image
                          </legend>{" "}
                          <div className="vich-image">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="payment_gateway_gatewayLogoFile_file"
                                name="payment_gateway[gatewayLogoFile][file]"
                                required="required"
                                className="custom-file-input"
                                onChange={handleImage}
                              />
                              <label
                                htmlFor="payment_gateway_gatewayLogoFile_file"
                                className="custom-file-label"
                              />
                            </div>
                          </div>
                        </fieldset>

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Status
                          </legend>
                          <div id="payment_gateway_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gateway_enabled_0"
                                name="payment_gateway[enabled]"
                                required="required"
                                className="custom-control-input"
                                onChange={(e) => setStatus(false)}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_0"
                              >
                                Disabled
                              </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="payment_gateway_enabled_1"
                                name="payment_gateway[enabled]"
                                required="required"
                                className="custom-control-input"
                                onChange={(e) => setStatus(true)}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="payment_gateway_enabled_1"
                              >
                                Enabled
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        <div className="form-group">
                          <label
                            htmlFor="payment_gateway_number"
                            className="required"
                          >
                            Order of appearance
                          </label>
                          <input
                            type="number"
                            id="payment_gateway_number"
                            name="payment_gateway[number]"
                            required="required"
                            className="touchspin-integer form-control"
                            onChange={(e) => setAppearanceOrder(e.target.value)}
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            id="payment_gateway_save"
                            name="payment_gateway[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
