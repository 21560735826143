import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Alert } from "@mui/material";

import BreadCrump from "../BreadCrump/bread_crump";
import Header from "../header/header";
import Footer from "../footer/footer";
import Sidenavbar from "../../pages/organizer_dashboard/components/sidenavbar";
import usePaymentMethodsStore from "../../store/payment_store";
import { createSlug } from "../../utils/convertor";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";

export default function PaymentMethodDetails() {
  const { payout_slug } = useParams();
  const layout_page = useSettings((state) => state.layout_page);

  const user = userStore((state) => state.user);
  const paymentMethods = usePaymentMethodsStore(
    (state) => state.paymentMethods
  );
  const addPaymentMethodDetails = usePaymentMethodsStore(
    (state) => state.addPaymentMethodDetails
  );
  const userPaymentMethods = usePaymentMethodsStore(
    (state) => state.userPaymentMethods
  );
  const paymentMethod = paymentMethods.find(
    (p) => createSlug(p.name) === payout_slug
  );

  const defaultAccountNumber =
    userPaymentMethods?.payment_methods?.[paymentMethod?.name]?.account_number;

  const [accountNumber, setAccountNumber] = useState(
    defaultAccountNumber || ""
  );

  const handleSavePaymentDetails = () => {
    addPaymentMethodDetails({
      userId: user?.user_id,
      accountNumber,
      paymentMethod: paymentMethod?.name,
    });
  };

  if (!paymentMethod) return <Navigate to="/payoutmethods" />;

  const supportedPhoneCompanies = ["mtn", "airtel"];
  const isPhoneNumber = supportedPhoneCompanies.some((s) =>
    paymentMethod.name.toLowerCase().includes(s.toLowerCase())
  );

  const accountType = isPhoneNumber ? "Phone" : "Account";
  const isDisabled = defaultAccountNumber === accountNumber;

  return (
    <div>
      <Header />
      <BreadCrump title={`Set your ${paymentMethod?.name} payout method`} />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"payoutmethods"} />}
            </aside>
            <div className="col-lg-9 mt-4 mt-lg-0">
              <Alert severity="info">
                Make sure that the currency setting of your payout method
                matches the one currently used which is USD
              </Alert>
              <div className="card mt-3">
                <div className="card-body py-5">
                  <div className="form-group">
                    <label
                      htmlFor="event_translations_en_name"
                      className="required">
                      {accountType} Number
                    </label>
                    <small
                      id={accountType}
                      className="form-text text-muted mb-3">
                      <i
                        className="fas fa-info-circle mr-1"
                        style={{
                          color: layout_page?.primary_color,
                        }}></i>
                      This is the {accountType} Number the money will be sent to
                    </small>
                    <input
                      type="number"
                      id={accountType}
                      name={accountType}
                      required="required"
                      maxLength="10"
                      aria-describedby={accountType}
                      className="form-control"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>
                  <button
                    className="btn text-white"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                    disabled={isDisabled}
                    onClick={() => handleSavePaymentDetails()}>
                    save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
