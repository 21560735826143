import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { tableStore } from "../../../../../store/table_store";
import DeleteButton from "../../Components";

export default function DeleteTableModal(props) {
  const tables = tableStore((state) =>
    state.tableLabels.filter(
      (tableLabel) => tableLabel.venue_tableType_id === props.venue_room_id
    )
  );
  const deleteTableLabel = tableStore((state) => state.deleteTableLabel);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className=" text-danger">Delete Tables</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-hover table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Table Label</th>
                <th>Per Table Seats</th>
                <th>Table Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tables?.length > 0 ? (
                tables.map((label) => (
                  <tr key={label.id}>
                    <td>{label.tableLabel}</td>

                    <td>
                      <span
                        style={{
                          backgroundColor: "#DCDCDC",
                          padding: 8,
                          color: "gray",
                          borderRadius: 8,
                        }}
                        className=""
                      >
                        {label.numberOfSeats}
                      </span>
                    </td>

                    <td>{label.table_type}</td>

                    <td>
                      <span onClick={() => deleteTableLabel(label.id)}>
                        <DeleteButton text="Delete" />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <>No labels</>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
