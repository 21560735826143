import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import app from "../Firebase";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

// INITIALIZING FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);
const blogStore = (set, get) => ({
  blogposts: [],
  categories: [],
  tags: [],
  articles: [],
  userCategories: [],
  visibleTags: [],
  pages: [],
  attendeeArticles: [],
  organizerArticles: [],

  ////////////////////////////////
  // METHOD TO ADD A NEW BLOGPOST/
  ////////////////////////////////

  addBlogPost: async (data) => {
    // CREATING A COLLECTION
    const blogsCollection = collection(db, "blogs");
    try {
      const responseFromFirestore = await addDoc(blogsCollection, data);
      const blog_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const blogDoc = doc(db, "blogs", blog_id);
      // UPDATING THE BLOG ID
      await updateDoc(blogDoc, { id: blog_id });
      //update blog post array in zustand store
      const addBlogposts = (blogpost) =>
        set((state) => ({
          blogposts: [...state.blogposts, blogpost],
        }));
      addBlogposts(data);
    } catch (error) {
      console.log("Error in adding blog post:", error);
      return null;
    }
  },

  //////////////////////////////////////////
  // METHOD TO ADD A NEW BLOGPOST CATEGORY//
  //////////////////////////////////////////
  addBlogPostCategory: async (data) => {
    // CREATING A COLLECTION
    const blogPostCategoriesCollection = collection(db, "blogpost_categories");
    try {
      const responseFromFirestore = await addDoc(
        blogPostCategoriesCollection,
        data
      );
      const blogpost_category_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const blogpostDoc = doc(db, "blogpost_categories", blogpost_category_id);
      // UPDATING THE BLOG ID
      await updateDoc(blogpostDoc, {
        id: blogpost_category_id,
      });
      //update blog category array in zustand store
      const addCategories = (category) =>
        set((state) => ({
          categories: [...state.categories, category],
        }));
      addCategories(data);
    } catch (error) {
      console.log("error in adding blog category:", error);
    }
  },

  //////////////////////////////////////////
  // METHOD TO ADD A NEW ARTICLE //
  //////////////////////////////////////////
  addArticle: async (data) => {
    // CREATING A COLLECTION
    try {
      const articleCollection = collection(db, "articles");
      // ADDING THE DATA TO FIRESTORE
      const responseFromFirestore = await addDoc(articleCollection, data);
      const article_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const articleDoc = doc(db, "articles", article_id);
      // UPDATING THE ARTICLE ID
      const response = await updateDoc(articleDoc, { id: article_id });
      //update audience array in zustand store
      const addArticles = (article) =>
        set((state) => ({
          articles: [...state.articles, article],
        }));
      addArticles(data);
      console.log("RESPONSE AFTER ADDING THE BLOGPOST: " + response);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  },

  // ADDING THE USER HELPER CATEGORY
  addHelperCategory: async (data) => {
    try {
      // Create a collection reference
      const userHelperCollection = collection(db, "user_categories");
      // Add the data to Firestore
      const responseFromFirestore = await addDoc(userHelperCollection, data);
      const user_category_id = responseFromFirestore.id;
      // Update the user_category document with the generated ID
      const userCategoryDoc = doc(db, "user_categories", user_category_id);
      await updateDoc(userCategoryDoc, { id: user_category_id });
      console.log("User category added successfully");
      const addUserCategories = (userCategory) =>
        set((state) => ({
          userCategories: [...state.userCategories, userCategory],
        }));
      addUserCategories(data);
      return user_category_id;
    } catch (error) {
      console.log("Error adding user category:", error);
      // displayErrorToast("Error occurred while adding user category");
      return null;
    }
  },

  ////////////////////////////////////////
  // METHOD TO UPLOAD BLOG POST IMAGE
  ////////////////////////////////////////
  uploadBlogImage: async (imageFile) => {
    if (!imageFile) {
      return;
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, "blogposts/images/" + imageFile.name);

    try {
      await uploadBytes(storageRef, imageFile);

      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image", error);
    }
  },
  //handle delete blog image Url
  deleteBlogMainImage: async (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("blog Image deleted");
      return true;
    } catch (error) {
      console.log("Error deleting the image", error);
      return null;
    }
  },
  //handle delete blog image Url
  deleteVenueImage: async (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
      console.log("Venue Image deleted");
      return true;
    } catch (error) {
      console.log("Error deleting the image", error);
      return null;
    }
  },

  // GETTING EVENT CATEGORIES
  getBlogs: async () => {
    try {
      const blogposts = [];
      const userDoc = collection(db, "blogs");
      const querySnapshot = await getDocs(userDoc);

      querySnapshot.forEach((doc) => {
        blogposts.push({ id: doc.id, ...doc.data() });
      });
      set({ blogposts });
      return blogposts;
    } catch (error) {
      console.log("error occured in trying to get blogs");
      return null;
    }
  },
  //getting blogs where status==='visible'
  getVisibleBlogs: async () => {
    const blogs = await get().getBlogs();
    const visibleBlogs = blogs.filter((blog) => blog.status === "visible");
    return visibleBlogs;
  },

  // METHOD TO UPDATE BLOG
  updateBlogs: async (blog_id, data) => {
    try {
      await updateDoc(doc(db, "blogs", blog_id), data);
      get().getBlogs();
      set((state) => ({
        blogposts: state.blogposts.map((blogpost) =>
          blogpost.id === blog_id ? data : blogpost
        ),
      }));
      return true;
    } catch (error) {
      console.log("Error Updating blog document: ", error);
      return false;
    }
  },

  // getting specific blog
  getSpecificBlog: async (blog_post_id) => {
    try {
      const blogDoc = doc(db, "blogs", blog_post_id);
      const querySnapshot = await getDoc(blogDoc);
      const blogposts = querySnapshot.data();
      console.log("blog:", blogposts);
      return blogposts;
    } catch (error) {
      console.log("Error getting specific blog:", error);
      // handle the error or return a default value
      return null;
    }
  },

  //delete blog
  deleteBlogPost: async (blog_post_id) => {
    console.log("Going to delete blog with id:", blog_post_id);
    try {
      // Get blog details
      const blogDetails = await get().getSpecificBlog(blog_post_id);
      console.log("Specific blog retrieved:", blogDetails);
      // Delete main image from storage
      try {
        const storageRef = ref(storage, blogDetails?.main_image);
        await deleteObject(storageRef);
        console.log("Main event image deleted");
      } catch (error) {
        console.error("Error deleting blog image:", error);
        // displayErrorToast("Blog image not deleted: " + error);
      }
      // Delete the blog document
      try {
        const docRef = doc(db, "blogs", blog_post_id);
        await deleteDoc(docRef);
        //update
        console.log("Blog deleted successfully");
        const updatedBlogposts = get().blogposts.filter(
          (blogpost) => blogpost?.id !== blog_post_id
        );
        set({ blogposts: updatedBlogposts });
      } catch (error) {
        console.error("Error deleting blog document:", error);
      }
      return true;
    } catch (error) {
      console.error("Something went wrong when deleting the blog:", error);
      return false;
    }
  },

  //get blog post categories
  getBlogpostCategories: async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "blogpost_categories")
      );

      const categories = [];
      querySnapshot.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
      set({ categories }); // Update the categories state with the fetched data
      return categories;
    } catch (error) {
      console.log("Error in getting blog categories", error);
      return null;
    }
  },

  //getting blogs categories where status==='visible'
  getVisibleBlogCategories: async () => {
    const categories = await get().getBlogpostCategories();
    const visibleBlogCategories = categories.filter(
      (category) => category.status === "visible"
    );
    return visibleBlogCategories;
  },

  //getting featured blog categories
  getFeaturedBlogCategories: async () => {
    const categories = await get().getBlogpostCategories();
    const featuredBlogCategories = categories.filter(
      (category) => category.featured === true
    );
    return featuredBlogCategories;
  },

  // METHOD TO UPDATE BLOG CATEGORY
  updateBlogpostCategories: async (blog_category_id, data) => {
    try {
      await updateDoc(doc(db, "blogpost_categories", blog_category_id), data);
      get().getBlogpostCategories();
      set((state) => ({
        categories: state.categories.map((category) =>
          category.id === blog_category_id ? data : category
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating ammenity document: ", error);
      return false;
    }
  },

  //delete category
  deleteBlogPostCategory: async (blog_category_id) => {
    try {
      const categoryRef = doc(db, "blogpost_categories", blog_category_id);
      await deleteDoc(categoryRef);
      //update categories
      get().getBlogpostCategories();
      console.log("Blog category deleted");
      const updatedCategories = get().categories.filter(
        (category) => category?.id !== blog_category_id
      );
      set({ categories: updatedCategories });
      return true;
    } catch (error) {
      console.log("Error deleting a blog category:", error);
      return false;
    }
  },

  //get blog by id
  getBlogById: async (blogID) => {
    console.log("THE BLOG ID IS ::", blogID);
    const docRef = doc(db, "blogs", blogID);

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return { id: docSnap.id, ...data };
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  },

  //get specific blog category(this can be used to edit a category)
  getSpecificBlogCategory: async (blog_category) => {
    try {
      console.log("CATEGORY ID:", blog_category);
      const querySnapshot = await getDoc(
        doc(db, "blogpost_categories", blog_category)
      );
      const blogCategory = querySnapshot.data();
      return blogCategory;
    } catch (error) {
      console.log("Error getting specific blog category:", error);
      return null;
    }
  },

  //get all tags
  getAllTags: async () => {
    try {
      // Calling the getBlogs method
      const blogposts = await get().getBlogs();
      const tags = blogposts.flatMap((blog) => blog.tags);
      set({ tags: [...new Set(tags)] });
    } catch (error) {
      console.log("Error getting tags:", error);
    }
  },
  //get all tags
  getTagsOfVisibleBlogs: async () => {
    try {
      const blogposts = await get().getVisibleBlogs();
      const tags = new Set(blogposts.flatMap((blog) => blog.tags));
      set({ visibleTags: [...tags] });
    } catch (error) {
      console.log("Error getting tags:", error);
    }
  },

  //getting articles
  getArticles: async () => {
    try {
      const articles = [];
      const articleDoc = collection(db, "articles");
      const querySnapshot = await getDocs(articleDoc);

      querySnapshot.forEach((doc) => {
        articles.push(doc.data());
      });
      set({ articles });
      return articles;
    } catch (error) {
      console.log("Error getting articles:", error);
      return [];
    }
  },

  // getting articles where article.title===Attendee
  getAttendeeArticles: async () => {
    const articlesCollection = collection(db, "articles");
    const q = query(
      articlesCollection,
      where("userCategory", "==", "Attendee"),
      where("status", "==", "visible")
    );
    const querySnapshot = await getDocs(q);
    const attendeeArticles = [];
    querySnapshot.forEach((doc) => {
      attendeeArticles.push(doc.data());
    });
    set({ attendeeArticles });
    return attendeeArticles;
  },

  //getting articles where article.title===Organizer
  getOrganizerArticles: async () => {
    const articlesCollection = collection(db, "articles");
    const q = query(
      articlesCollection,
      where("userCategory", "==", "Organizer"),
      where("status", "==", "visible")
    );
    const querySnapshot = await getDocs(q);
    const organizerArticles = [];
    querySnapshot.forEach((doc) => {
      organizerArticles.push(doc.data());
    });
    set({ organizerArticles });
    return organizerArticles;
  },

  // getting specific blog
  getSpecificArticle: async (id) => {
    try {
      const articlesDoc = doc(db, "articles", id);
      const querySnapshot = await getDoc(articlesDoc);
      const article = querySnapshot.data();
      console.log("article:", article);
      return article;
    } catch (error) {
      console.log("Error getting specific article:", error);
      return null;
    }
  },

  // METHOD TO UPDATE ARTICLE
  updateArticle: async (article_id, data) => {
    try {
      await updateDoc(doc(db, "articles", article_id), data);
      get().getArticles();
      set((state) => ({
        articles: state.articles.map((article) =>
          article.id === article_id ? data : article
        ),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating article document: ", error);
      return false;
    }
  },
  //delete article
  deleteHelperArticle: async (article_id) => {
    try {
      const articleRef = doc(db, "articles", article_id);
      await deleteDoc(articleRef);
      console.log("Helper article deleted");
      const updatedArticles = get().articles.filter(
        (article) => article?.id !== article_id
      );
      set({ articles: updatedArticles });
      return true;
    } catch (error) {
      console.log("Error in deleting an article:", error);
      return false;
    }
  },

  //get user categories
  getUserCategories: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "user_categories"));
      const userCategories = [];
      querySnapshot.forEach((doc) => {
        userCategories.push({ id: doc.id, ...doc.data() });
      });
      set({ userCategories });
      return userCategories;
    } catch (error) {
      console.error("Error getting user categories:", error);
      return null;
    }
  },

  //update user categories
  updateUserCategories: async (userCategory_id, data) => {
    try {
      await updateDoc(doc(db, "user_categories", userCategory_id), data);

      set((state) => ({
        userCategories: state.userCategories.map((userCategory) =>
          userCategory.id === userCategory_id ? data : userCategory
        ),
      }));
      get().getUserCategories();
      return true;
    } catch (error) {
      console.error("Error Updating user category document: ", error);
      return false;
    }
  },

  //delete user category
  deleteUserHelperCategory: async (user_category_id) => {
    try {
      const userCategoryRef = doc(db, "user_categories", user_category_id);
      await deleteDoc(userCategoryRef);
      //update
      console.log("User category deleted successfully");

      const updatedUserCategories = get().userCategories.filter(
        (userCategory) => userCategory?.id !== user_category_id
      );
      set({ userCategories: updatedUserCategories });
      return true;
    } catch (error) {
      console.error("Error deleting user category:", error);
      return false;
    }
  },

  //A METHOD TO ADD A NEW PAGE
  addPage: async (data) => {
    // CREATING A COLLECTION
    try {
      const pageCollection = collection(db, "pages");
      const responseFromFirestore = await addDoc(pageCollection, data);
      const page_id = responseFromFirestore.id;
      // CREATING A DOCUMENT
      const pageDoc = doc(db, "pages", page_id);
      // UPDATING THE PAGE ID
      await updateDoc(pageDoc, { id: page_id });
      //update page array in zustand store
      const addPages = (page) =>
        set((state) => ({
          pages: [...state.pages, page],
        }));
      addPages(data);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  },
  //a method to get pages
  getPages: async () => {
    try {
      const pages = [];
      const querySnapshot = await getDocs(collection(db, "pages"));
      querySnapshot.forEach((doc) => {
        pages.push({ id: doc.id, ...doc.data() });
      });
      set({ pages });
      return pages;
    } catch (error) {
      console.log("error occured in trying to get pages");
      return null;
    }
  },

  //getting a specific page
  getSpecificPage: async (page_id) => {
    const pages = await get().getPages();
    const specificPage = pages.find((page) => page.id === page_id);
    console.log("the page got is:", specificPage);
    return specificPage;
  },

  //delete page
  deletePage: async (page_id) => {
    try {
      const pageRef = doc(db, "pages", page_id);
      await deleteDoc(pageRef);
      console.log("Page deleted");
      const updatedPages = get().pages.filter((page) => page?.id !== page_id);
      set({ pages: updatedPages });
      return true;
    } catch (error) {
      console.log("Error in deleting this page:", error);
      return false;
    }
  },

  // METHOD TO UPDATE PAGE
  updatePage: async (page_id, data) => {
    try {
      await updateDoc(doc(db, "pages", page_id), data);
      get().getPages();
      set((state) => ({
        pages: state.pages.map((page) => (page.id === page_id ? data : page)),
      }));
      return true;
    } catch (error) {
      console.error("Error Updating page document: ", error);
      return false;
    }
  },
  // METHOD TO GET THE TIME SINCE THE BLOG WAS ADDED
  getTimeSinceAdded: async (timestamp) => {
    const now = new Date();
    const addedDate = new Date(timestamp);
    const diffInMs = now.getTime() - addedDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
    const diffInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths} month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} year${diffInYears === 1 ? "" : "s"} ago`;
    }
  },
});

// CREATING AND EXPORTING THE EVENTS STORE
export const useBlogs = create(blogStore);
