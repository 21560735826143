/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Header from "../header/header";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Exclamation from "./Exclamation";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
  formatDate,
  formatNumberWithCommas,
  generateBookingId,
} from "../../services/helpers";
import Footer from "../footer/footer";
import { displaySnackbar } from "./../../services/events";
import Loader from "../loader/loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./EventDetail.css";
import FlexPayment from "../flexPayment/FlexPayment";
import { ordersStore } from "../../store/orders_store";
import { useSettings } from "../../store/setting_store";
import { tableStore } from "../../store/table_store";
import OrganizerName from "../../pages/attendee_dashboard/OrganizerName";
import VenueOrganizer from "../../pages/admin_dashboard/venues/VenueOrganizer";

const CheckoutEvent = () => {
  //handling validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9,9}$/, "valid phone number required")
      .test("valid-prefix", "MTN or Airtel phone number", (value) => {
        // Ensure phoneNumber is a string
        const phoneNumberString = String(value);

        // Check the prefix to determine payment type
        if (
          phoneNumberString.startsWith("75") ||
          phoneNumberString.startsWith("74") ||
          phoneNumberString.startsWith("70") ||
          phoneNumberString.startsWith("77") ||
          phoneNumberString.startsWith("78") ||
          phoneNumberString.startsWith("76")
        ) {
          return true;
        }
        return false;
      })
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails do not match")
      .required("Confirm Email is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      name: "",
    },
    validationSchema,
    onSubmit: handleOrder,
  });

  const navigate = useNavigate();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isFreeEvent, setIsFreeEvent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const addUserToPromocodeBeneficiaries = usePromoCodes(
  //   (state) => state.addUserToPromocodeBeneficiaries
  // );

  const addOrder = ordersStore((state) => state.addOrder);
  const layout_page = useSettings((state) => state.layout_page);

  const state = useLocation().state;
  const {
    event,
    ticketQuantities,
    promoDetails,
    total,
    tableQuantities,
    itemQuantity,
    selectedTables,
    selectedDate,
    venue_room,
    venue,
  } = state ?? {};

  console.log("the selected tables are:", selectedTables);

  //handling the orders
  async function handleOrder() {
    console.log("LENGTH OF TICKET QUANTITIES: " + ticketQuantities.length);
    console.log(
      "THE LENGHT OF THE QUANTITY OF TABLES ARE:" + tableQuantities.length
    );
    setIsLoading(true);
    try {
      //generating order id
      const orderId = generateBookingId();

      const orderData = {
        amount: 0,
        tickets: ticketQuantities,
        customer_email: values.email,
        customer_id: null,
        customer_name: values.name,
        customer_number: `256${values.phoneNumber}`,
        order_id: orderId,
        selectedTables: selectedTables || [],
        tables: tableQuantities || null,
      };

      // ADDING THE ORDER TO THE DATABASE
      var orderResponse = await addOrder(orderData);

      if (orderResponse) {
        displaySuccessToast("Order Placed Successfully");
        setIsLoading(false);

        // GOING TO THE SUCCESS SCREEN
        navigate("/success_screen", { state: { orderData, selectedTables } });
      } else {
        setIsLoading(false);
        displayWarningToast(
          "Something Went Wrong Placing Order, Please try again later."
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      displayErrorToast("Something Went Wrong, Please try again later.");
    }
  }

  useEffect(() => {
    if (event?.event_dates) {
      if (event?.event_dates[0]?.tickets[0]?.isFree || total === 0) {
        console.log("THE EVENT IS FREE");
        setIsFreeEvent(true);
      } else {
        console.log("ITS A PAID EVENT, THE EVENT IS NOT FREE");
        setIsFreeEvent(false);
      }
    } else {
      console.log("THE EVENT DATE IS NOT AVAILABLE");
    }
  }, []);

  // ON VALUE EMPTY
  function onValueEmpty(value) {
    setShowSnackbar(true);
  }

  if (!state) {
    navigate("/browse_events");
    return;
  }

  // METHOD TO GO BACK
  function goBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header />

      {/* VALUE EMPTY SNACKBAR */}
      {showSnackbar &&
        displaySnackbar({
          message: "Please fill all fields",
          color: "error",
          onClose: setShowSnackbar(false),
          open: showSnackbar,
        })}

      {/* EVENT DETAILS */}
      <div className="container">
        {/* BREADCRUMP */}
        <div className=" cleafix py-5 mb-md-3">
          <h4 className="title-page b dark float-xl-left mb-0">
            {event?.name}
          </h4>

          <nav className="float-xl-right mt-2 mt-xl-0">
            <ol className="breadcrumb text-white">
              <li className="breadcrumb-item">
                <a href="/en" className="dark">
                  <i className="fas fa-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                event
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {event?.name}
              </li>
            </ol>
          </nav>
        </div>

        <div className="row mb-5">
          <div className="col-md-5 border-end ">
            <h6
              className="text-uppercase"
              style={{ color: "orangered", fontSize: "18px" }}
            >
              Order Summary
            </h6>

            <span
              className="gap-2 align-items-center mt-3 d-none d-md-flex"
              onClick={goBack}
              style={{ cursor: "pointer" }}
            >
              <FaAngleLeft size={30} style={{ color: "#838784" }} />
              <h6 className="text-sm mt-1" style={{ color: "#838784" }}>
                Back
              </h6>
            </span>

            <div
              className="shadow p-2 my-5 bg-body "
              style={{ borderRadius: "10px" }}
            >
              <span className="d-flex align-items-center justify-content-between pt-1">
                <h6>Total</h6>
                <h6> UGX {formatNumberWithCommas(total ?? 0)} </h6>
              </span>
            </div>

            <div className="mb-5">
              {selectedTables?.length > 0 && (
                <>
                  <span className="mr-2">Selected Tables:</span>

                  {selectedTables
                    .reduce((tableGroups, table, index) => {
                      if (
                        index === 0 ||
                        table?.table_type !==
                          selectedTables[index - 1].table_type
                      ) {
                        // Start a new group for a different selectedTable
                        tableGroups.push([table]);
                      } else {
                        // Add to the existing group for the same selectedTable
                        tableGroups[tableGroups.length - 1].push(table);
                      }
                      return tableGroups;
                    }, [])
                    .map((tableGroup, groupIndex) => (
                      <figure key={groupIndex} className="media h-100 my-4">
                        <div className="img-wrap my-auto">
                          <img
                            src={
                              event?.main_event_image || venue?.venue_images[0]
                            }
                            className="img-thumbnail img-sm img-lazy-load b-loaded"
                            alt={event?.name}
                          />
                        </div>

                        <figcaption className="media-body my-auto">
                          <dl className="dlist-inline small">
                            <dt />
                            <dd className="mr-0">
                              {tableGroup[0]?.selectedTable ||
                                tableGroup[0]?.table_type}{" "}
                              table
                              <i
                                className="fas fa-info-circle ml-2"
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                                data-toggle="tooltip"
                                data-original-title="Get a front seat and a popcorn bag"
                              />
                            </dd>
                          </dl>
                          <dl className="dlist-inline small">
                            <dt className="mr-2">When: </dt>
                            <dd>
                              {formatDate(
                                convertTimestampToDateTime(selectedDate)
                              )}
                            </dd>
                          </dl>
                          <dl className="dlist-inline small">
                            <dt className="mr-2">Where: </dt>
                            <dd>{venue_room?.venue?.label}</dd>
                          </dl>
                          <dl className="dlist-inline small mr-2">
                            <dt className="mr-2">Organizer: </dt>
                            <dd>
                              <Link
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                                to={
                                  "/organizer_details/" +
                                    event?.organizer?.id || venue?.organizer_id
                                }
                                taget="_blank"
                              >
                                <OrganizerName
                                  event_id={event?.id}
                                  className={`small`}
                                />
                                <VenueOrganizer
                                  organizer_ID={venue?.organizer_id}
                                  className={"small"}
                                />
                              </Link>
                            </dd>
                          </dl>
                          <span className="small">
                            <span className="mr-2 fw-bold">
                              Selected Tables
                            </span>
                            {tableGroup.map((table, tableIndex) => (
                              <React.Fragment key={tableIndex}>
                                {tableIndex > 0 && ", "}
                                {table?.tableLabel}
                              </React.Fragment>
                            ))}
                          </span>
                        </figcaption>
                      </figure>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className="col-md-6 mx-auto ">
            {/* BUY YOUR TICKET HERE */}
            <h6
              className="mb-4 text-uppercase fw-bolder"
              style={{ color: "orangeRed", fontSize: "18px" }}
            >
              Buy your Ticket Here
            </h6>
            <h6>
              <Exclamation style={{ color: "orangered" }} />
              {/* SIGNUP */}
              <Link to={"/sign_up_attendee"}> Sign Up </Link>
              with Lyxa to get access to discounts on events and amenities at
              venues
            </h6>

            {/* SECTION FOR ENTERING USER DETAILS */}

            {/* FIRST ROW */}
            <div className="row">
              {/* PERSONAL INFORMATION */}
              <div className="col-12 col-md-6">
                {" "}
                <h6>Personal contact Information</h6>
              </div>

              {/* LOGIN IF ALREADY REGISTERED */}
              <div className="col-12 col-md-6">
                <h6 style={{ color: "#838784" }}>
                  Already have an account? {""}
                  <Link to={"/sign_in"}> Login </Link>
                </h6>
              </div>
            </div>

            {selectedTables?.length === 0 ? (
              isFreeEvent ? (
                <form name="promocode_form" onSubmit={handleSubmit}>
                  {/* SECOND  ROW */}
                  <div className="row">
                    {/* NAME OF THE USER */}
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        id="name"
                        placeholder="Name"
                        style={{ height: "40px" }}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name && touched.name
                            ? "input-error"
                            : "inputs form-control my-2"
                        }
                      />
                      {errors.name && touched.name && (
                        <p className="error">{errors.name}</p>
                      )}
                    </div>

                    {/* PHONE NUMBER */}
                    <div className="col-12 col-md-6">
                      <div className="input-group my-2 inputs">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{ borderRadius: "10px" }}
                          >
                            +256
                          </span>
                        </div>

                        <input
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? "input-error form-control "
                              : "inputs form-control"
                          }
                          type="number"
                          id="phoneNumber"
                          style={{ height: "40px", borderRadius: "10px" }}
                          placeholder="7XXXXXXXX"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.phoneNumber && touched.phoneNumber && (
                        <p className="error">{errors.phoneNumber}</p>
                      )}
                    </div>
                  </div>
                  {/* THIRD ROW */}
                  <div className="row">
                    {/* EMAIL ADDRESS */}
                    <div className="col-12 col-md-6">
                      <input
                        className={
                          errors.email && touched.email
                            ? "input-error"
                            : "inputs form-control my-2"
                        }
                        type="email"
                        id="email"
                        placeholder="email"
                        style={{ height: "40px" }}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email && (
                        <span className="error text-sm">{errors.email}</span>
                      )}
                    </div>

                    {/* CONFIRM EMAIL ADDRESS */}
                    <div className="col-12 col-md-6">
                      <input
                        className={
                          errors.confirmEmail && touched.confirmEmail
                            ? "input-error"
                            : "inputs form-control my-2"
                        }
                        type="email"
                        id="confirmEmail"
                        placeholder="confirmEmail"
                        style={{ height: "40px" }}
                        value={values.confirmEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmEmail && touched.confirmEmail && (
                        <p className="error">{errors.confirmEmail}</p>
                      )}
                    </div>
                  </div>
                  {/* GET FREE TICKET BUTTON */}
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="text-uppercase d-flex align-items-center justify-content-center py-2 text-base btn-checkout my-3 border-0 w-100"
                    style={{ height: "40px" }}
                  >
                    Get Free Ticket
                    <FaAngleRight size={20} className="ml-2" />
                  </button>
                </form>
              ) : (
                <FlexPayment
                  amount={total}
                  onValueEmpty={onValueEmpty}
                  event={event}
                  ticketQuantities={ticketQuantities}
                  promoDetails={promoDetails}
                  selectedTables={selectedTables}
                  room={venue_room}
                  selectedDate={selectedDate}
                />
              )
            ) : (
              <FlexPayment
                amount={total}
                onValueEmpty={onValueEmpty}
                event={event}
                ticketQuantities={ticketQuantities}
                promoDetails={promoDetails}
                selectedTables={selectedTables}
                room={venue_room}
                selectedDate={selectedDate}
              />
            )}
          </div>
        </div>
      </div>

      {/*ADDING THE FOOTER */}
      <Footer />
    </div>
  );
};

export default CheckoutEvent;
