import React, { useEffect, useState } from "react";
import { useEvents } from "../../../store/event_store";

const CountriesCount = ({ country_id }) => {
  const events = useEvents((state) => state.events);
  const [count, setCount] = useState([]);
  useEffect(() => {
    const fetchCount = () => {
      if (country_id) {
        const c = events.filter(
          (event) => event?.country?.value === country_id
        );
        setCount(c);
      } else {
        return null;
      }
    };
    fetchCount();
  });

  return <div>{count?.length}</div>;
};

export default CountriesCount;
