import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function SocialLogin() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const updateSocialLoginPage = useSettings(
    (state) => state.updateSocialLoginPage
  );
  const getSocialLoginPage = useSettings((state) => state.getSocialLoginPage);
  const getSocialLoginPageId = useSettings(
    (state) => state.getSocialLoginPageId
  );
  const social_login_page = useSettings((state) => state.social_login_page);
  console.log("the page is:", social_login_page);
  const navigate = useNavigate();
  const layout_page = useSettings((state) => state.layout_page);

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [social_id, setSocialID] = useState(null);
  const [socialForm, setSocialForm] = useState(social_login_page || {});

  useEffect(() => {
    const getID = async () => {
      const id = await getSocialLoginPageId();
      setSocialID(id);
    };

    const getPage = async () => {
      await getSocialLoginPage();
    };
    getPage();
    getID();
  }, []);

  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setSocialForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setSocialForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //update the checkout page
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateSocialLoginPage(social_id, {
        enable_facebook_social_login: socialForm?.enable_facebook_social_login,
        enable_google_social_login: socialForm?.enable_google_social_login,
        facebook_id: socialForm?.facebook_id,
        facebook_secret: socialForm?.facebook_secret,
        google_id: socialForm?.google_id,
        google_secret: socialForm?.google_secret,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("error occurred:", error);
      displayErrorToast("Some thing is wrong");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Social Login settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"social_login"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="social_login_settings"
                      method="post"
                      onSubmit={handleUpdate}
                      noValidate="novalidate"
                    >
                      <div id="form">
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Enable Facebook Social Login
                          </legend>
                          <div id="form_social_login_facebook_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_social_login_facebook_enabled_0"
                                name="enable_facebook_social_login"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={
                                  socialForm?.enable_facebook_social_login ===
                                  true
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_social_login_facebook_enabled_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_social_login_facebook_enabled_1"
                                name="enable_facebook_social_login"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={
                                  socialForm?.enable_facebook_social_login ===
                                  false
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_social_login_facebook_enabled_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <label htmlFor="form_social_login_facebook_id">
                            Facebook Id
                          </label>
                          <input
                            type="text"
                            id="form_social_login_facebook_id"
                            name="facebook_id"
                            className="form-control"
                            value={socialForm?.facebook_id || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="form_social_login_facebook_secret">
                            Facebook Secret
                          </label>
                          <input
                            type="text"
                            id="form_social_login_facebook_secret"
                            name="facebook_secret"
                            className="form-control"
                            value={socialForm?.facebook_secret || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Enable Google Social Login
                          </legend>
                          <div id="form_social_login_google_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_social_login_google_enabled_0"
                                name="enable_google_social_login"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={
                                  socialForm?.enable_google_social_login ===
                                  true
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_social_login_google_enabled_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_social_login_google_enabled_1"
                                name="enable_google_social_login"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={
                                  socialForm?.enable_google_social_login ===
                                  false
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_social_login_google_enabled_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <label htmlFor="form_social_login_google_id">
                            Google Id
                          </label>
                          <input
                            type="text"
                            id="form_social_login_google_id"
                            name="google_id"
                            className="form-control"
                            value={socialForm?.google_id || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="form_social_login_google_secret">
                            Google Secret
                          </label>
                          <input
                            type="text"
                            id="form_social_login_google_secret"
                            name="google_secret"
                            className="form-control"
                            value={socialForm?.google_secret || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="form_save"
                            name="form[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="form__token"
                          name="form[_token]"
                          defaultValue="j9MzcTmszQW3lDa63oGoUt0vxMaUeqznVLOr4vqEG_k"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
