import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, MenuItem, MenuList } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function SignupDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ cursor: "pointer" }}>
      {/* USER PROFILE  */}
      <div onClick={handleClick}>
        <div className="icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none">
          <i className="fas fa-user-plus text-light fa-sm fa-fw" />
        </div>
        <span className="d-none d-lg-inline ml-3">Sign up</span>
        <i className="fas fa-caret-down ml-1" />
      </div>

      <Popover
        id="dropdown-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {/* ATTENDEE */}
          <MenuItem
            component={Link}
            to={"/sign_up_attendee"}
            onClick={handleClose}
            style={{ fontWeight: 400, fontSize: ".805rem" }}
          >
            <i className="fas fa-user fa-fw mr-2"></i>
            Attendee
          </MenuItem>

          {/* ORGANIZER */}
          <MenuItem
            component={Link}
            to={"/sign_up_organizer"}
            onClick={handleClose}
            style={{ fontWeight: 400, fontSize: ".805rem" }}
          >
            <i className="fas fa-calendar-plus fa-fw mr-2"></i>
            Organizer
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}
