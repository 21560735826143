import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Sidenavbar from "./components/sidenavbar";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";

export default function Stripepayout() {
  // const { isOrganizer } = useContext(myContext);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();

  if (!isOrganizer) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />
        <BreadCrump title={"Set your Stripe payout method"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                <Sidenavbar />
              </aside>

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="alert alert-info alert-icon">
                  <i className="fas fa-info-circle" /> Make sure that the
                  currency setting of your payout method matches the one
                  currently used which is USD
                </div>
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="organizer_payout_payment_gateway"
                      method="post"
                      noValidate="novalidate"
                    >
                      <div id="organizer_payout_payment_gateway">
                        <fieldset className="form-group">
                          <div id="organizer_payout_payment_gateway_config">
                            <fieldset
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <legend className="col-form-label required">
                                Sandbox
                              </legend>
                              <div
                                id="organizer_payout_payment_gateway_config_sandbox"
                                className="payment_config_field paypal_express_checkout paypal_rest"
                              >
                                <div className="custom-control custom-radio custom-control-inline">
                                  {" "}
                                  <input
                                    type="radio"
                                    id="organizer_payout_payment_gateway_config_sandbox_0"
                                    name="organizer_payout_payment_gateway[config][sandbox]"
                                    required="required"
                                    className="custom-control-input"
                                    defaultValue={0}
                                  />
                                  <label
                                    className="custom-control-label required"
                                    htmlFor="organizer_payout_payment_gateway_config_sandbox_0"
                                  >
                                    No
                                  </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  {" "}
                                  <input
                                    type="radio"
                                    id="organizer_payout_payment_gateway_config_sandbox_1"
                                    name="organizer_payout_payment_gateway[config][sandbox]"
                                    required="required"
                                    className="custom-control-input"
                                    defaultValue={1}
                                  />
                                  <label
                                    className="custom-control-label required"
                                    htmlFor="organizer_payout_payment_gateway_config_sandbox_1"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                            <div
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_username"
                                className="required"
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_username"
                                name="organizer_payout_payment_gateway[config][username]"
                                required="required"
                                className="payment_config_field paypal_express_checkout form-control"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_password"
                                className="required"
                              >
                                Password
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_password"
                                name="organizer_payout_payment_gateway[config][password]"
                                required="required"
                                className="payment_config_field paypal_express_checkout form-control"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_signature"
                                className="required"
                              >
                                Signature
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_signature"
                                name="organizer_payout_payment_gateway[config][signature]"
                                required="required"
                                className="payment_config_field paypal_express_checkout form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_publishable_key"
                                className="required"
                              >
                                Stripe publishable key
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_publishable_key"
                                name="organizer_payout_payment_gateway[config][publishable_key]"
                                required="required"
                                className="payment_config_field stripe_checkout form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_secret_key"
                                className="required"
                              >
                                Stripe secret key
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_secret_key"
                                name="organizer_payout_payment_gateway[config][secret_key]"
                                required="required"
                                className="payment_config_field stripe_checkout form-control"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_client_id"
                                className="required"
                              >
                                Paypal Client Id
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_client_id"
                                name="organizer_payout_payment_gateway[config][client_id]"
                                required="required"
                                className="payment_config_field paypal_rest form-control"
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="organizer_payout_payment_gateway_config_client_secret"
                                className="required"
                              >
                                Paypal Client Secret
                              </label>
                              <input
                                type="text"
                                id="organizer_payout_payment_gateway_config_client_secret"
                                name="organizer_payout_payment_gateway[config][client_secret]"
                                required="required"
                                className="payment_config_field paypal_rest form-control"
                              />
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="organizer_payout_payment_gateway_save"
                            name="organizer_payout_payment_gateway[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="organizer_payout_payment_gateway__token"
                          name="organizer_payout_payment_gateway[_token]"
                          defaultValue="lQZJ5pswlljNCQ5ZYDXzkj_qzR_2bT2uws_4i3FReeI"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
