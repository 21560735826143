import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageMenus() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Menus" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_menus"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Header</th>
                              <th>Elements</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Header menu</td>
                              <td></td>
                              <td>8</td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/settings/menus/header-menu/edit"
                                      className="dropdown-item"
                                    >
                                      <i className="dropdown-icon fas fa-edit fa-fw text-muted" />{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>First Footer Section Menu</td>
                              <td>Useful Links</td>
                              <td>5</td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/settings/menus/first-footer-section-menu/edit"
                                      className="dropdown-item"
                                    >
                                      <i className="dropdown-icon fas fa-edit fa-fw text-muted" />{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Second Footer Section Menu</td>
                              <td>My Account</td>
                              <td>5</td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/settings/menus/second-footer-section-menu/edit"
                                      className="dropdown-item"
                                    >
                                      <i className="dropdown-icon fas fa-edit fa-fw text-muted" />{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Third Footer Section Menu</td>
                              <td>Event Categories</td>
                              <td>2</td>
                              <td className="text-center">
                                <div className="item-action dropdown">
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    data-boundary="window"
                                    className="icon"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      href="/en/dashboard/administrator/settings/menus/third-footer-section-menu/edit"
                                      className="dropdown-item"
                                    >
                                      <i className="dropdown-icon fas fa-edit fa-fw text-muted" />{" "}
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
