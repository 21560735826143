import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Alert } from "@mui/material";

import { useEvents } from "../../store/event_store";
import usePaymentMethodsStore from "../../store/payment_store";
import useFetchUserPaymentMethods from "../../customHooks/useFetchUserPaymentMethods";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";

export default function RequestPayout({ eventId, onClose, isOpen }) {
  const { isLoading } = useFetchUserPaymentMethods();
  const navigate = useNavigate();
  const layout_page = useSettings((state) => state.layout_page);

  const amount = 0;
  const payment_method = "";
  const account_number = "";

  const events = useEvents((state) => state.events);
  const event = events.find((event) => event.id === eventId);
  const user = userStore((state) => state.user);
  const requestPayout = usePaymentMethodsStore((state) => state.requestPayout);

  const userPaymentMethods = usePaymentMethodsStore(
    (state) => state.userPaymentMethods
  );

  const noUserPaymentMethodsFound = !isLoading && !userPaymentMethods?.user_id;

  const handleRequestPayout = () => {
    if (noUserPaymentMethodsFound) {
      navigate("/payoutmethods");
      return;
    }
    if (!user?.is_phone_verified) {
      requestPayout({
        user_id: user?.user_id,
        payment_method,
        account_number,
        amount,
        event_id: eventId,
      });
      return;
    }
    // Check if the funds are not more than what is being withdrawn
    // Immediate Transfer funds
    return;
  };

  useEffect(() => {
    if (!event) {
      onClose();
    }
  }, [event, onClose]);

  return (
    <Modal
      size="md"
      aria-labelledby={`${event?.name} payout request`}
      centered
      show={isOpen}
    >
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>{event?.name}: Payout Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert severity="info">
          Once the payout request submitted, the event date will be locked and
          the sales will be suspended for the specific event date. If you wish,
          you can wait until the start date of the event date before requesting
          the payout. You can cancel the payout request any time before it is
          processed
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn">
          Close
        </button>
        <button
          className="btn text-white"
          style={{
            backgroundColor: layout_page?.primary_color,
          }}
          onClick={() => handleRequestPayout()}
        >
          {noUserPaymentMethodsFound ? "Set Payment Methods" : "Request Payout"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
