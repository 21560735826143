import React, { useContext, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { displaySuccessToast } from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Addnewcountry() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();
  const addNewCountry = useEvents((state) => state.addNewCountry);
  const layout_page = useSettings((state) => state.layout_page);
  //HANDLING COUNTRIES ADDUP
  const [name, setName] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [isloading, setIsloading] = useState("");
  const [errors, setErrors] = useState({});

  const handleAddCountry = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      setIsloading(true);
      await addNewCountry({
        name: name,
        countryCode: countrycode,
        created_at: Timestamp.fromDate(new Date()),
      });

      navigate("/manage_countries");
      displaySuccessToast("Country added Successfully");
    } else {
      setErrors(errors);
    }
    setIsloading(false);
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!name) {
      errors.name = "name is required";
    }
    if (!countrycode) {
      errors.countrycode = "country code is required";
    }

    return errors;
  }

  //displaying the loader
  if (isloading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new country"
          page_link="Manage Countries"
          link="manage_countries"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_countries"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    {/*FORM TO CAPTURE DATA  */}
                    <form
                      name="country"
                      onSubmit={handleAddCountry}
                      noValidate="novalidate"
                    >
                      {/* ADDING NAME FIELD */}

                      <div
                        id="country_translations_en_a2lix_translations-fields"
                        className="tab-pane show active "
                        role="tabpanel"
                      >
                        <div id="country_translations_en">
                          <div className="form-group d-grid">
                            <label
                              htmlFor="country_translations_en_name"
                              className="required"
                            >
                              Name
                            </label>
                            {errors.name && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.name}
                              </span>
                            )}
                            <input
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              name="country[translations][en][name]"
                              required="required"
                              maxLength={50}
                              pattern=".{1,}"
                              className={
                                errors.name
                                  ? " border border-danger form-control"
                                  : "form-control"
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* COUNTRY CODE FIELD */}
                      <div className="form-group d-grid">
                        <label htmlFor="country_code" className="required">
                          Country code
                        </label>
                        {errors.countrycode && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.countrycode}
                          </span>
                        )}
                        <input
                          type="text"
                          onChange={(e) => setCountrycode(e.target.value)}
                          name="country[code]"
                          required="required"
                          className={
                            errors.countrycode
                              ? " border border-danger form-control"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                      <input
                        type="hidden"
                        id="country__token"
                        name="country[_token]"
                        defaultValue="BErEid71VrwUsx-cfdACufd8VlvwTU0pP78jI7LWCxM"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
