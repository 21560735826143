// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mutual_table {
  width: 100%;
}

.table_header {
  color: #7e898b;
}
.text-primary {
  color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/mutual_friends/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".mutual_table {\r\n  width: 100%;\r\n}\r\n\r\n.table_header {\r\n  color: #7e898b;\r\n}\r\n.text-primary {\r\n  color: var(--primary);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
