import React from 'react';

export default function DateRadio({
  isChecked,
  option,
  htmlFor,
  handleChange,
}) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        value={option?.value}
        onChange={(e) => handleChange(e, option)}
        checked={isChecked}
      />
      <label className="form-check-label" htmlFor={htmlFor}>
        {option?.label}
      </label>

      <span className="float-right form-check-label">
        <span className="badge badge-light round">0</span>
      </span>
    </div>
  );
}
