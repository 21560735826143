import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { auth, db, storage } from "../Firebase";
import { deleteObject, ref } from "firebase/storage";
import {
  displayErrorToast,
  displayInfoToast,
  displaySuccessToast,
  displayWarningToast,
} from "../services/helpers";
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

const user_store = (set, get) => ({
  user: null,
  users: [],
  userData: {},
  isOrganizer: false,
  isAdmin: false,
  isAttendee: false,
  following: [],
  userFollowing: [],
  isLoggedIn: false,
  cartItems: [],
  organizers: [],
  scanners: [],
  pointsOfSale: [],
  organizerDetails: null,
  scannerData: null,
  userScanners: null,
  attendees: [],

  getUserByEmail: async (email) => {
    console.log("GETTING DETAILS FOR: " + email);

    try {
      // GET USER DETAILS FROM FIREBASE AUTH
      const userCollection = collection(db, "users");
      const q = query(userCollection, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      return querySnapshot.docs[0].data();
    } catch (error) {
      console.log("SOMETHING WENT WRONG: " + error);
      return null;
    }
  },
  // METHOD TO GET ALL SCANNERS
  getScanners: async () => {
    try {
      const scanners = [];
      const scannerDoc = collection(db, "scanners");
      const querySnapshot = await getDocs(scannerDoc);
      querySnapshot.forEach((doc) => {
        scanners.push(doc.data());
      });
      set({ scanners });
      return scanners;
    } catch (error) {
      console.log("Error fetching scanners from the database:", error);
      return null;
    }
  },

  getUserScanners: async (userId) => {
    try {
      // Reference the "scanners" collection in Firestore
      const scannersCollection = collection(db, "scanners");
      // Query the scanners collection to get the scanners added by the given user
      const q = query(scannersCollection, where("added_by", "==", userId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs) {
        // Use the map method to iterate over the query snapshot documents and extract the scanner data
        const scanners = querySnapshot.docs.map((doc) => doc.data());
        set(() => ({ userScanners: scanners }));
        // Return the array of scanners
        return scanners;
      } else {
        console.log("No documents found");
        return [];
      }
    } catch (error) {
      console.log("Error getting documents: ", error);
      return [];
    }
  },

  //METHOD TO DELETE SCANNER
  deleteScanner: async (scanner_id) => {
    try {
      //get scanner details
      const scannerRef = doc(db, "scanners", scanner_id);
      await deleteDoc(scannerRef);
      console.log("Entire scanner deleted successfully");
      //update the scanners
      const updatedScanners = get().userScanners.filter(
        (scanner) => scanner?.id !== scanner_id
      );
      set({ userScanners: updatedScanners });
      return true;
    } catch (error) {
      console.log("Error while deleting the scanner", error);
      return false;
    }
  },

  // METHOD TO UPDATE ORGANIZER PROFILE
  updateOrganizerProfile: async (organizer_id, data) => {
    try {
      await updateDoc(doc(db, "organizers", organizer_id), data);
      get().fetchOrganizers();
      set((state) => ({
        organizers: state.organizers.map((organizer) =>
          organizer?.id === organizer_id ? data : organizer
        ),
      }));
      return true;
    } catch (error) {
      console.log("Error in Updating the organizer document: ", error);
      return false;
    }
  },

  // METHOD TO ADD ITEM TO CART
  addToCart: async ({ cart_items, user_id }) => {
    // CREATING A COLLECTION
    const cartDoc = doc(db, "cart", user_id);

    // CHECKING THE USER HAS A CART
    try {
      const querySnapshot = await getDoc(cartDoc);

      // IF THE USER HAS A CART
      if (querySnapshot.exists()) {
        // UPDATING THE CART
        for (let i = 0; i < cart_items.length; i++) {
          await updateDoc(cartDoc, {
            cart_items: arrayUnion(cart_items[i]),
          });
        }
        return true;
      }

      // IF THE USER DOES NOT HAVE A CART
      else {
        console.log("USER DOES NOT HAVE A CART");

        const data = {
          cart_items: cart_items,
          user_id: user_id,
          date_added: Timestamp.fromDate(new Date()),
        };

        try {
          await setDoc(cartDoc, data);
          return true;
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      console.log("ERROR GETTING CART ITEMS: " + error);
      return false;
    }
  },

  // METHOD TO GET CART ITEMS
  getCartItems: async (user_id) => {
    const cartItemDoc = doc(db, "cart", user_id);
    const querySnapshot = await getDoc(cartItemDoc);

    if (querySnapshot.exists()) {
      const cartItem = querySnapshot.data();
      set({ cartItems: cartItem?.cart_items });
    } else {
      console.log("NO CART ITEMS FOUND FOR THAT USER");
    }
  },

  // REMOVE AN ITEM FROM CART
  removeCartItem: async ({ cart_item, user_id }) => {
    try {
      const cart_items = get().cartItems.filter(
        (cartItem) => cartItem !== cart_item
      );

      set({ cartItems: cart_items });

      await updateDoc(doc(db, "cart", user_id), {
        cart_items: arrayRemove(cart_item),
      });

      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG DELETING CART ITEM: " + error);
      return false;
    }
  },

  // REMOVE AN ITEM FROM CART
  updateCart: async ({ ticket_id, quantity, user_id, selectedTables }) => {
    try {
      console.log("THE TICKET ID: " + ticket_id);
      console.log("THE QUANTITY: " + quantity);
      console.log("THE USER ID: " + user_id);
      console.log("THE SELECTED TABLES FROM THE CART AARE:", selectedTables);

      // Create an updated array with the modified item
      const updatedArray = get().cartItems.map((item) => {
        if (item.ticket_id === ticket_id) {
          return {
            ...item,
            quantity: quantity,
            selectedTables: [...selectedTables],
          };
        }
        return item;
      });

      console.log("THE UPDATED ARRAY: " + updatedArray);

      // Update the local state
      set((state) => ({ cartItems: updatedArray }));

      // UPDATE THE DATABASE
      await updateDoc(doc(db, "cart", user_id), {
        cart_items: updatedArray,
      });

      console.log("Cart updated successfully");
      return true;
    } catch (error) {
      console.error("SOMETHING WENT WRONG UPDATING CART ITEM: ", error);
      return false;
    }
  },

  // METHOD TO CLEAR THE CART
  clearCart: async (user_id) => {
    try {
      const cartDoc = doc(db, "cart", user_id);
      set((state) => ({ cartItems: [] }));
      await deleteDoc(cartDoc);
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG CLEARING THE CART: " + error);
      return false;
    }
  },

  // FOLLOW ORGANIZER
  followOrganizer: async (organizer_id) => {
    try {
      const user_id = get().user.user_id;
      const data = {
        user_id: user_id,
        organizer_id: organizer_id,
        date_added: Timestamp.fromDate(new Date()),
      };

      await addDoc(collection(db, "following"), data);
      return true;
    } catch (error) {
      console.log("SOMETHING WENT WRONG FOLLOWING ORGANIZER: " + error);
      return false;
    }
  },

  // METHOD TO GET FOLLOWING
  getFollowing: async (user_id) => {
    const followingList = [];
    const userDoc = collection(db, "following");
    try {
      const q = query(userDoc, where("user_id", "==", user_id));
      const querySnapshot = await getDocs(q);

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const following = querySnapshot.docs.at(i).data();
        const response = await get().getOrganizerDetails(
          following.organizer_id
        );

        followingList.push(response);
      }
      set({ following: followingList });

      return followingList;
    } catch (error) {
      console.log(
        "Something is wrong in getting organizers that are followed by you"
      );
      return false;
    }
  },

  // METHOD TO GET USER FOLLOWING
  getUserFollowing: async (user_id) => {
    try {
      const userFollowing = get().following.filter(
        (following) => following.user_id === user_id
      );
      return userFollowing;
    } catch (error) {
      console.log(
        "SOMETHING WENT WRONG GETTING THE ORGANIZERS THE USER IS FOLLOWING: " +
          error
      );
      return null;
    }
  },

  // GET THE DETAILS OF AN ORGANIZER basing on organizer id
  getOrganizerDetails: async (organizer_id) => {
    try {
      if (!organizer_id) {
        return null;
      }
      // FILTER ORGANIZER DETAILS
      const organizer = get().organizers.find(
        (organizer) => organizer.id === organizer_id
      );

      if (!organizer) {
        const organizersCollection = collection(db, "organizers");
        const q = query(organizersCollection, where("id", "==", organizer_id));
        const response = await getDocs(q);

        if (response.empty) {
          return null;
        } else {
          return response.docs[0].data();
        }
      }

      return organizer;
    } catch (error) {
      console.log("Error getting organizer details:", error);
      return null;
    }
  },

  // GET THE DETAILS OF AN ORGANIZER using event_id
  getOrganizerDetail: async (event_id) => {
    if (!event_id) {
      return null;
    }
    try {
      const eventDoc = doc(db, "events_new", event_id);
      const docSnapshot = await getDoc(eventDoc);

      if (docSnapshot.exists()) {
        const eventData = docSnapshot.data();
        const organizer_id = eventData.organizer_id;

        const organizerDoc = doc(db, "organizers", organizer_id);
        const organizerSnapshot = await getDoc(organizerDoc);

        if (organizerSnapshot.exists()) {
          const organizerData = organizerSnapshot.data();
          return organizerData;
        } else {
          console.log("Organizer document does not exist");
          return null;
        }
      } else {
        console.log("Event document does not exist");
        return null;
      }
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  },

  // LOGIN
  login: async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user || !user?.email) {
        console.log("Authentication failed.");
        displayErrorToast("Authentication failed. User does not exist");
        return null;
      }

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        console.log("Email verification sent. Please verify your email.");
      }

      // User is authenticated and email is verified
      const user_id = user.uid;
      const userDoc = doc(db, "users", user_id);
      const querySnapshot = await getDoc(userDoc);
      const userData = querySnapshot.data();

      if (userData.status !== "disabled") {
        set({ user: userData });
        set({ isLoggedIn: true });

        if (userData.user_type === "organizer") {
          set({ isOrganizer: true });
        } else if (userData.user_type === "attendee") {
          set({ isAttendee: true });
        } else if (userData.user_type === "admin") {
          set({ isAdmin: true });
        } else {
          console.log("CANT GET THE USER TYPE");
        }

        return userData;
      } else {
        console.log("User is disabled. Cannot login.");
        displayErrorToast(
          "Your Account was disabled, Please contact the Administrator."
        );
        return null;
      }
    } catch (error) {
      console.log("ERROR LOGGING IN: " + error);
      return null;
    }
  },

  signinWithGoogle: async () => {
    try {
      const authProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, authProvider);
      const isNewUser = getAdditionalUserInfo(result).isNewUser;
      const userId = result.user.uid;

      const userDoc = doc(db, "users", userId);

      // IF THE USER IS LOGGING IN FOR THE FIRST TIME
      if (isNewUser) {
        const userObject = {
          user_id: userId,
          firstname: result.user.displayName,
          lastname: null,
          username: result.user.email,
          email: result.user.email,
          phone_number: result.user.phoneNumber,
          address: null,
          bio: null,
          created_at: Timestamp.fromDate(new Date()),
          updated_at: Timestamp.fromDate(new Date()),
          profile_picture: result.user.photoURL,
          online_status: "offline",
          social_media_links: null,
          date_of_birth: null,
          fcm_token: null,
          kyc_status: null,
          last_seen_at: null,
          location: null,
          user_type: "attendee",
          status: "active",
        };

        await setDoc(userDoc, userObject);

        set({ user: userObject });
        set({ isAttendee: true });
      }
      const querySnapshot = await getDoc(userDoc);
      const userData = querySnapshot.data();

      if (userData.status !== "disabled") {
        if (userData.user_type === "organizer") {
          set({ isOrganizer: true });
        } else if (userData.user_type === "attendee") {
          set({ isAttendee: true });
        } else if (userData.user_type === "admin") {
          set({ isAdmin: true });
        } else {
          console.log("CANT GET THE USER TYPE");
        }

        set({ user: userData });
        set({ isLoggedIn: true });
        return userData;
      } else {
        console.log("User is disabled. Cannot login.");
        displayErrorToast(
          "Your Account was disabled, Please contact the Administrator"
        );
        return null;
      }
    } catch (error) {
      console.log("SOMETHING WENT WRONG SIGNING IN WITH GOOGLE" + error);
      return null;
    }
  },

  // /GET USER DETAILS
  getUserDetails: async (userId) => {
    try {
      if (!userId) {
        return null;
      }
      const userDoc = doc(db, "users", userId);
      const userSnap = await getDoc(userDoc);
      return userSnap.data();
    } catch (error) {
      console.log("THE ERROR: " + error);
      return null;
    }
  },

  // REGISTER
  register: async ({ data, email, password, organizerData }) => {
    try {
      // ADDING THE USER TO FIREBASE AUTH
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // SEND EMAIL VERIFICATION LINK
      await sendEmailVerification(response.user);

      // GETTING THE USER ID
      const userId = response.user.uid;
      console.log("the data of the registered user is:", data);

      data["user_id"] = userId;
      // ADDING THE USER DATA TO FIRESTORE DATABASE
      const userDoc = doc(db, "users", userId);
      await setDoc(userDoc, data);

      // IF THE USER IS AN ORGANIZER
      if (data.user_type === "organizer") {
        organizerData["id"] = userId;
        // ADDING THE USER DATA TO FIRESTORE DATABASE
        const organizerDoc = doc(db, "organizers", userId);
        await setDoc(organizerDoc, organizerData);
      }

      // GETTING THE USER DATA
      const userDetails = await get().getUserDetails(userId);

      set({ user: userDetails });
      set({ isLoggedIn: true });
      console.log("THE USER DETAILS: " + userDetails);

      // SETTING THE USER TYPE
      if (data.user_type === "organizer") {
        set({ isOrganizer: true });
      } else if (data.user_type === "attendee") {
        set({ isAttendee: true });
      } else if (data.user_type === "admin") {
        set({ isAdmin: true });
      } else {
        console.log("CANT GET THE USER TYPE");
      }

      return true;
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          return "Email already in use!";
        default:
          return error.message;
      }
    }
  },

  // LOGOUT
  logout: async () => {
    try {
      await auth.signOut();
      set({ user: null });
      set({ isLoggedIn: false });
      set({ isOrganizer: false });
      set({ isAttendee: false });
      set({ isAdmin: false });
      console.log("USER LOGGED OUT");

      return true;
    } catch (error) {
      console.log("THE ERROR: " + error);
      return false;
    }
  },

  //getting all orgainzers from the database
  fetchOrganizers: async () => {
    try {
      const organizers = [];
      const organizersDoc = collection(db, "organizers");
      const querySnapshot = await getDocs(organizersDoc);

      querySnapshot.forEach((doc) => {
        organizers.push(doc.data());
      });

      set({ organizers });
      return organizers;
    } catch (error) {
      console.log("Something went wrong fetching organizers", error);
      // displayErrorToast("Something went wrong fetching organizers");
      return null;
    }
  },

  //fetching all scanners from the database
  fetchScanners: async () => {
    try {
      const scanners = [];
      const scannerDoc = collection(db, "scanners");
      const querySnapshot = await getDocs(scannerDoc);
      querySnapshot.forEach((doc) => {
        scanners.push(doc.data());
      });
      set({ scanners });
      return scanners;
    } catch (error) {
      console.log("Something went wrong fetching scanners", error);
      // displayErrorToast("Something went wrong fetching scanners");
      return [];
    }
  },

  //get Scanners of the specific organizer
  getScannersByOrganizer: async (organizer_id) => {
    try {
      //query the scanners collection whwere added_by is equal to organizer_id
      const scannersCollection = collection(db, "scanners");
      const q = query(
        scannersCollection,
        where("added_by", "==", organizer_id)
      );
      // Execute the query and get the scanner documents
      const querySnapshot = await getDocs(q);
      // Map the documents to an array of scanner objects
      const scanners = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("Scanners by organizer", scanners);
      return scanners;
    } catch (error) {
      console.error("Error fetching scanners by organizer: ", error);
      return null;
    }
  },

  //fetching all point of sell POS
  fetchPointsOfSale: async () => {
    try {
      const pos = [];
      const userDoc = collection(db, "points_of_sale");
      const querySnapshot = await getDocs(userDoc);

      querySnapshot.forEach((doc) => {
        pos.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      set({ pointsOfSale: pos });
    } catch (error) {
      console.log("Something went wrong fetching points of sale", error);
      // displayErrorToast("Something went wrong fetching points of sale");
    }
  },

  //get points_of_sale of the specific organizer
  getPOSByOrganizer: async (organizer_id) => {
    try {
      //query the scanners collection whwere added_by is equal to organizer_id
      const posCollection = collection(db, "points_of_sale");
      const q = query(posCollection, where("organizer_id", "==", organizer_id));
      // Execute the query and get the scanner documents
      const querySnapshot = await getDocs(q);
      // Map the documents to an array of scanner objects
      const points_of_sale = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("POS by organizer", points_of_sale);
      return points_of_sale;
    } catch (error) {
      console.error("Error fetching POS of organizer: ", error);
      return null;
    }
  },

  //ADDING A SCANNER
  addScanner: async (data) => {
    const scannerCollection = collection(db, "scanners");
    const responseFromFirestore = await addDoc(scannerCollection, data);
    const scanner_id = responseFromFirestore.id;
    const scannerDoc = doc(db, "scanners", scanner_id);
    try {
      await updateDoc(scannerDoc, {
        id: scanner_id,
      });
      set({ scannerData: data });
      return true;
    } catch (error) {
      return error;
    }
  },

  //get Organizer followers
  getOrganizerFollowers: async (organizer_id) => {
    try {
      //query the scanners collection whwere added_by is equal to organizer_id
      const followersCollection = collection(db, "following");
      const q = query(
        followersCollection,
        where("organizer_id", "==", organizer_id)
      );
      // Execute the query and get the scanner documents
      const querySnapshot = await getDocs(q);
      // Map the documents to an array of scanner objects
      const followers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("floowers by organizer", followers);
      return followers;
    } catch (error) {
      console.error("Error fetching followers of organizer: ", error);
      return null;
    }
  },

  //getting all users from the database;
  fetchAllUsers: async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), orderBy("created_at", "desc"))
      );
      const users = [];
      querySnapshot.docs.forEach((doc) => {
        users.push(doc.data());
      });
      set({ users });
      return users;
    } catch (error) {
      console.log("Error fetching users from the database:", error);
      return [];
    }
  },

  // GETTING A SPECIFIC USER
  getUser: async (user_id) => {
    const users = await get().fetchAllUsers();

    const user = await users.find((user) => user.user_id === user_id);
    return user;
  },

  //getting all the attendees
  getAttendees: async () => {
    try {
      const users = await get().fetchAllUsers();

      const attendees = await users.filter(
        (user) => user.user_type === "attendee"
      );
      return attendees;
    } catch (error) {
      console.log("Error getting attendees:", error);
      return null;
    }
  },

  //get Organizer by Id
  getOrganizerById: async (organizer_id) => {
    const organizer = await get().organizers.find(
      (organizer) => organizer.id === organizer_id
    );
    return organizer;
  },

  //getFavorites events of attendee
  getAttendeefavorites: async (user_id) => {
    try {
      //query the scanners collection whwere added_by is equal to organizer_id
      const favoritesCollection = collection(db, "favorites");
      const q = query(favoritesCollection, where("user_id", "==", user_id));
      // Execute the query and get the scanner documents
      const querySnapshot = await getDocs(q);
      // Map the documents to an array of scanner objects
      const favorites = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("floowers by organizer", followers);
      return favorites;
    } catch (error) {
      console.error("Error fetching favorites of attendee: ", error);
      return null;
    }
  },

  //get Cart items of the attendee
  getAttendeeCartItems: async (user_id) => {
    try {
      //query the scanners collection whwere added_by is equal to organizer_id
      const cartCollection = collection(db, "cart");
      const q = query(cartCollection, where("user_id", "==", user_id));
      // Execute the query and get the cart documents
      const querySnapshot = await getDocs(q);
      // Map the documents to an array of cart objects
      const cart = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return cart;
    } catch (error) {
      console.error("Error fetching cart items of attendee: ", error);
      return null;
    }
  },

  //calicualting the cart items quantity for event
  countCartItemsForEvent: async (eventID) => {
    try {
      // Fetch the collection of "cart" from Firestore
      const querySnapshot = await getDocs(collection(db, "cart"));
      let totalQuantity = 0; // Initialize a variable to store the total quantity of cart items for the event
      querySnapshot.forEach((doc) => {
        const cart = doc.data(); // Get the data (fields) of the current cart document

        // Check if the cart_items array exists and is an array
        if (Array.isArray(cart.cart_items)) {
          // Iterate through each item in the cart_items array
          cart.cart_items.forEach((item) => {
            // Check if the item's event_id matches the provided eventID
            if (item.event_id === eventID) {
              // If there's a match, add the item's quantity to the totalQuantity variable
              totalQuantity += item.quantity;
            }
          });
        }
      });
      return totalQuantity;
    } catch (error) {
      console.log(
        "Error in counting cart items quanitity for the event:",
        error
      );
      return false;
    }
  },
  ///////////////////////////////////////////
  //  METHOD TO ADD FOLLOW ORGANIZER //////
  //////////////////////////////////////////

  followOrganizers: async (organizer_id, user_id) => {
    try {
      //check if the document already exists
      const querySnapshot = await getDocs(
        query(
          collection(db, "following"),
          where("organizer_id", "==", organizer_id),
          where("user_id", "==", user_id)
        )
      );
      //if the doument exists
      if (!querySnapshot.empty) {
        console.log("document already exists");
        displayInfoToast("You are already following this organizer");
        return;
      }
      //if the document does not exists
      const docRef = await addDoc(collection(db, "following"), {
        organizer_id: organizer_id,
        user_id: user_id,
        followed_at: Timestamp.fromDate(new Date()),
        updated_at: Timestamp.fromDate(new Date()),
      });
      console.log("Document written with ID: ", docRef.id);
      get().getFollowing(user_id);
      return true;
    } catch (error) {
      console.error("Error adding document: ", error);
      return false;
    }
  },

  unfollowOrganizer: async ({ organizer_id, user_id }) => {
    try {
      const userDoc = collection(db, "following");
      const q = query(
        userDoc,
        where("organizer_id", "==", organizer_id),
        where("user_id", "==", user_id)
      );

      const querySnapshot = await getDocs(q);
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const follow = querySnapshot.docs.at(i);
        await deleteDoc(doc(db, "following", follow.id));
      }
      get().getFollowing(user_id);
      return true;
    } catch (error) {
      console.log(
        "SOMETHING WENT WRONG When Unfollowing the organizer " + error
      );
      return false;
    }
  },

  // Define the handleStatusToggle function to toggle the status
  handleStatusToggle: async (user_id) => {
    const users = await get().fetchAllUsers();
    const userIndex = users.findIndex((user) => user.user_id === user_id);
    if (userIndex !== -1) {
      const updatedUsers = [...users];
      const user = updatedUsers[userIndex];
      // Check if the status field exists in the user object
      if ("status" in user) {
        user.status = user.status === "active" ? "disabled" : "active";
        // Update the user's status in the database
        try {
          await updateDoc(doc(db, "users", user_id), { status: user.status });
        } catch (error) {
          console.log("Error in updating the user status:", error);
          return;
        }
      } else {
        // If the status field is missing, assume it is "active" and add it to the database
        user.status = "active";
      }
      // Display a success message based on the updated status
      if (user.status === "active") {
        displaySuccessToast(
          "The user has been enabled and can use the system."
        );
      } else {
        displayWarningToast(
          "The user has been disabled and WILL NOT be able to sign in"
        );
      }
      set({ users: updatedUsers });
    }
  },

  // Delete user
  deleteUser: async (user_id) => {
    try {
      // console.log("DELETING USER WITH ID: " + user_id);
      // Get user details
      const userDetails = await get().getSpecificUser(user_id);
      const userProfilePicture = userDetails?.profile_picture;
      console.log("Specific user retrieved", userDetails);
      // Delete user profile picture from storage
      try {
        if (userProfilePicture) {
          const storageRef = ref(storage, userProfilePicture);
          await deleteObject(storageRef);
          console.log("User profile picture deleted successfully.");
        } else {
          console.log("error occured");
        }
      } catch (error) {
        console.log("error in deleting the image:", error);
        // displayErrorToast("Error in deleting the image");
      }
      if (userProfilePicture) {
        const storageRef = ref(storage, userDetails?.profile_picture);
        await deleteObject(storageRef);
        console.log("User profile picture deleted successfully.");
      }

      // Delete the user document from the database
      const userRef = doc(db, "users", user_id);
      await deleteDoc(userRef);
      console.log("Entire user has been deleted successfully.");
      const users = await get().fetchAllUsers();

      const updatedUsers = users.filter((user) => user?.user_id !== user_id);
      set({ users: updatedUsers });
      return true;
    } catch (error) {
      console.error("Error while deleting the user:", error);
      return false;
    }
  },

  checkFollowing: async ({ organizer_id, user_id }) => {
    console.log("THE ORGANIZER ID: " + organizer_id);
    console.log("THE USER ID: " + user_id);
    const followingCollection = collection(db, "following");

    const q = query(
      followingCollection,
      where("organizer_id", "==", organizer_id),
      where("userId", "==", user_id)
    );

    try {
      const responseFromFirestore = await getDocs(q);

      // CHECKING IF THE RETURNED RESPONSE IS NOT EMPTY
      if (responseFromFirestore.docs.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("SOMETHING WENT WRONG GETTING THE USER FOLLOWING: " + error);
      return false;
    }
  },

  // a function that checks whether username was already used
  isUserNameAlreadyUsed: async (userName) => {
    const users = await get().fetchAllUsers();
    const user = users.find((user) => user.username === userName);
    return !!user; // return true if username already exists, false otherwise
  },

  //resending the email link
  resendEmailLink: async (currentUser) => {
    const sendEmail = await sendEmailVerification(currentUser);
    return sendEmail;
  },
});

// CREATING AND EXPORTING THE USER STORE
export const userStore = create(
  persist(user_store, {
    name: "user_store",
  })
);

// INITIALIZING THE ORGANIZERS AND USERS
userStore.getState().getFollowing();
