import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Sidenavbar from "./components/sidenavbar";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import { Timestamp } from "firebase/firestore";
import { userStore } from "../../store/user_store";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { usePromoCodes } from "../../store/promocode_store";
import { useEvents } from "../../store/event_store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Switch } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function AddPromoCode() {
  const [code, setCode] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [quantity, setQuantity] = useState();
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [organizerEvents, setOrganizerEvents] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [ticket_quantity, setTicketQuantity] = useState();
  const [eventTickets, setEventTickets] = useState([]);
  const layout_page = useSettings((state) => state.layout_page);

  const isOrganizer = userStore((state) => state.isOrganizer);
  const user = userStore((state) => state.user);
  const addPromoCode = usePromoCodes((state) => state.addPromoCode);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  const getEvent = useEvents((state) => state.getEvent);

  const navigate = useNavigate();

  async function handleSelectEvent(event_id) {
    var eventDetails = await getEvent(event_id);
    setEventTickets(eventDetails.event_dates[0].tickets);
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  // GET THE ORGANIZER EVENTS
  useEffect(() => {
    if (user) {
      const response = getOrganizerEvents(user?.user_id);
      setOrganizerEvents(response);
    }
  }, []);

  // DISCOUNT TYPE
  const selectedDiscountType = watch("discountType");

  // HANDLING UPLOADING THE BLOG POST
  const handleAddPromocode = async (result) => {
    if (!user) {
      navigate("/sign_in");
    }

    setIsLoading(true);

    //getting ticket ids
    const ticketIds = selectedTickets.map((ticket) => {
      return ticket.value;
    });

    const data = {
      startDate,
      endDate,
      totalForPromoRun: parseInt(quantity),
      isActive,
      ticketIds,
      beneficiaries: [],
      code: result.code,
      eventId: result.eventId,
      discountType: result.discountType,
      description: result.description,
      discount: result.discount,
      createdAt: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date()),
      organizerId: user?.user_id,
      ticketsPerUser: parseInt(ticket_quantity),
    };

    const response = await addPromoCode(data);

    if (response) {
      displaySuccessToast("Promo code added successfully");
      navigate("/my_promocodes");
    } else {
      displayErrorToast("Something Went Wrong Adding Promo Code");
    }

    setIsLoading(false);
  };

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  // HANDLE ACTIVE
  const handleActive = (e) => {
    setIsActive(e.target.checked);
  };

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrump title={"Add Promocode"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDEBAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"promocodes"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="promocode_form"
                      onSubmit={handleSubmit(handleAddPromocode)}
                    >
                      {/* IS ACTIVE */}
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div>
                          <label
                            htmlFor="scanner_username"
                            className="required"
                          >
                            Is Promocode Active?
                          </label>
                        </div>

                        <div className="ml-auto">
                          <Switch
                            color="warning"
                            onChange={handleActive}
                            checked={isActive}
                          />
                        </div>
                      </div>

                      {/* CODE */}
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Code
                        </label>{" "}
                        <Tooltip
                          title="This is the code you will share with users to get a discount"
                          placement="top"
                          arrow
                        >
                          <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>
                        <Controller
                          name="code"
                          control={control}
                          rules={{ required: "Code is required" }}
                          render={({ field }) => (
                            <input
                              type="text"
                              id="code"
                              className={`form-control ${
                                errors.code ? "is-invalid" : ""
                              }`}
                              onChange={(e) => setCode(e.target.value)}
                              {...field}
                            />
                          )}
                        />
                        {/* Display error message */}
                        {errors.code && (
                          <div className="invalid-feedback">
                            {errors.code.message}
                          </div>
                        )}
                      </div>

                      {/* EVENTS DROPDOWN */}
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Event
                        </label>

                        <Controller
                          name="eventId"
                          control={control}
                          rules={{ required: "Event is required" }}
                          onChange={(e) => handleSelectEvent(e.target.value)}
                          render={({ onChange, field }) => (
                            <select
                              id="eventId"
                              className={`form-control ${
                                errors.eventId ? "is-invalid" : ""
                              }`}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handleSelectEvent(e.target.value);
                              }}
                            >
                              <option value="">Select Event</option>
                              {organizerEvents?.map((event) => (
                                <option key={event.id} value={event.id}>
                                  {event.name}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        {/* Display error message */}
                        {errors.eventId && (
                          <div className="invalid-feedback">
                            {errors.eventId.message}
                          </div>
                        )}
                      </div>

                      {/* TICKETS DROPDOWN */}
                      {eventTickets.length > 0 && (
                        <div className="form-group">
                          <label
                            htmlFor="scanner_username"
                            className="required"
                          >
                            Ticket
                          </label>

                          <SearchableDropdown
                            control={control}
                            rules={{ required: "Ticket is required" }}
                            id="ticketId"
                            className={`form-control ${
                              errors.ticketId ? "is-invalid" : ""
                            }`}
                            isMulti={true}
                            value={selectedTickets}
                            options={eventTickets?.map((ticket) => ({
                              value: ticket.id,
                              label: ticket.ticket_name,
                            }))}
                            onChange={(e) => setSelectedTickets(e)}
                          />

                          {/* Display error message */}
                          {errors.ticketId && (
                            <div className="invalid-feedback">
                              {errors.ticketId.message}
                            </div>
                          )}
                        </div>
                      )}

                      {/* DESCRIPTION */}
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Description
                        </label>

                        <Controller
                          name="description"
                          control={control}
                          rules={{ required: "Description is required" }}
                          render={({ field }) => (
                            <input
                              type="text"
                              id="description"
                              className={`form-control ${
                                errors.description ? "is-invalid" : ""
                              }`}
                              {...field}
                            />
                          )}
                        />

                        {/* Display error message */}
                        {errors.description && (
                          <div className="invalid-feedback">
                            {errors.description.message}
                          </div>
                        )}
                      </div>

                      {/* DISCOUNT TYPE */}
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Discount Type
                        </label>
                        <Controller
                          name="discountType"
                          control={control}
                          rules={{ required: "Discount Type is required" }}
                          render={({ field }) => (
                            <select
                              id="discountType"
                              className={`form-control ${
                                errors.discountType ? "is-invalid" : ""
                              }`}
                              {...field}
                            >
                              <option value="">Select Discount Type</option>
                              <option value="percentage">Percentage</option>
                              <option value="amount">Fixed Amount</option>
                            </select>
                          )}
                        />
                        {/* Display error message */}
                        {errors.discountType && (
                          <div className="invalid-feedback">
                            {errors.discountType.message}
                          </div>
                        )}
                      </div>

                      {/* DISCOUNT */}
                      <div className="form-group">
                        <label htmlFor="scanner_username" className="required">
                          Discount
                        </label>
                        <Tooltip
                          title="e.g. 40, 1000, etc."
                          placement="top"
                          arrow
                        >
                          <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>

                        <Controller
                          name="discount"
                          control={control}
                          rules={{
                            required: "Discount is required",
                            validate: (value) => {
                              // Check if the selected discount type is 'percentage'
                              if (selectedDiscountType === "percentage") {
                                // Validate that the value is not greater than 90
                                return (
                                  (value >= 0 && value <= 90) ||
                                  "Discount should be between 0 and 90%"
                                );
                              }
                              // Check if the selected discount type is 'amount'
                              if (selectedDiscountType === "amount") {
                                const selectedTicketPrices =
                                  selectedTickets.map((selectedTicket) => {
                                    const ticket = eventTickets.find(
                                      (ticket) =>
                                        ticket.id === selectedTicket.value
                                    );
                                    return ticket ? ticket.ticket_price : 0; // Use 0 as default price if ticket is not found
                                  });
                                const ticketPrice = Math.min(
                                  ...selectedTicketPrices
                                );

                                return (
                                  (value >= 0 && value < ticketPrice) ||
                                  "Fixed amount discount should be less than the ticket price"
                                );
                              }

                              return true; // For other discount types, no validation needed
                            },
                          }}
                          render={({ field }) => (
                            <input
                              type="number"
                              id="discount"
                              className={`form-control ${
                                errors.discount ? "is-invalid" : ""
                              }`}
                              {...field}
                            />
                          )}
                        />

                        {/* Display error message */}
                        {errors.discount && (
                          <div className="invalid-feedback">
                            {errors.discount.message}
                          </div>
                        )}
                      </div>

                      {/* START DATE */}
                      <div className="form-group d-grid">
                        <label htmlFor="scanner_username" className="required">
                          Start Date
                        </label>

                        <DatePicker
                          required
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          showTimeSelect
                          value={startDate}
                          dateFormat={"dd/MM/yyyy p"}
                          placeholderText="Select Start Date For Promo Code"
                          minDate={new Date()}
                        />
                      </div>

                      {/* END DATE */}
                      <div className="form-group d-grid">
                        <label htmlFor="scanner_username" className="required">
                          End Date
                        </label>

                        <DatePicker
                          required
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                          }}
                          showTimeSelect
                          value={endDate}
                          dateFormat={"dd/MM/yyyy p"}
                          placeholderText="Select Start Date For Promo Code"
                          minDate={startDate}
                        />
                      </div>

                      {/* TOTAL FOR PROMO */}
                      <div className="form-group">
                        <label htmlFor="scanner_username">
                          Total for Promo
                        </label>
                        <Tooltip
                          title="This is the number of offers you have, e.g if total is 100, the promocode can only be applied 100 times, after it becomes invalid."
                          placement="top"
                          arrow
                        >
                          <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>

                        <input
                          type="number"
                          id="quantity"
                          name="quantity"
                          className="form-control"
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                      {/* TOTAL FOR PROMO */}
                      <div className="form-group">
                        <label htmlFor="scanner_username">
                          Tickets Per User
                        </label>
                        <Tooltip
                          title="This is the number of tickets a user will buy using your promo code"
                          placement="top"
                          arrow
                        >
                          <InfoIcon color="warning" fontSize="small" />
                        </Tooltip>

                        <input
                          type="number"
                          id="ticket_quantity"
                          name="ticket_quantity"
                          className="form-control"
                          onChange={(e) => setTicketQuantity(e.target.value)}
                        />
                      </div>

                      {/* SAVE */}
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
