import { create } from "zustand";
import { persist } from "zustand/middleware";

import app from "../Firebase";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { displayErrorToast } from "../services/helpers";
import {
  addPaymentMethod,
  createPayoutRequest,
  fetchUserPayoutRequests,
} from "../services/firebase_payments";
import axios from 'axios';


const db = getFirestore(app);

const usePaymentMethodsStore = create(
  persist(
    (set, get) => ({
      paymentMethods: [],
      userPaymentMethods: {},
      payoutRequests: [],

      // MAR TRANSACTION AS SUCCESFULL
      markTransactionSuccesfull: async (transactionId) => {
        try {
          const q = query(
            collection(db, "transactions"),
            where("transaction_id", "==", transactionId)
          );
          const ref = await getDocs(q);
          const data = ref.docs[0].data();
          data.transaction_status = "successful";
          await updateDoc(ref.docs[0].ref, data);
          console.log("TRANSACTION SUCCESFULLY MARKED SUCCESFULL");
          return true;
        } catch (error) {
          return false;
        }
      },

      fetchPaymentMethods: async () => {
        try {
          const payment_methods = [];
          console.log("FETCHING PAYMENT METHODS");
          const paymentMethodsCollection = collection(db, "payment_methods");
          const paymentMethodsSnap = await getDocs(paymentMethodsCollection);

          for (const payment_methods_doc of paymentMethodsSnap.docs) {
            const eventData = payment_methods_doc.data();
            payment_methods.push(eventData);
          }

          console.log(
            "THE LENGTH OF FETCHED PAYMENT METHODS: " + payment_methods.length
          );

          set(() => ({ paymentMethods: payment_methods }));
        } catch (error) {
          console.log(
            "SOMETHING WENT WRONG FETCHING PAYMENT METHODS: " + error
          );
          // displayErrorToast('Something went wrong fetching payment methods');
        }
      },

      fetchUserPaymentMethods: async (userId) => {
        try {
          console.log("FETCHING USER PAYMENT METHODS");
          const userPaymentMethods = collection(db, "user_payment_methods");
          const q = query(userPaymentMethods, where("user_id", "==", userId));
          const userPaymentMethodsSnap = await getDocs(q);

          if (userPaymentMethodsSnap.size > 0) {
            const data = userPaymentMethodsSnap.docs[0];

            set(() => ({
              userPaymentMethods: {
                ...data.data(),
                id: data.id,
              },
            }));
          }
        } catch (error) {
          console.log(
            "SOMETHING WENT WRONG FETCHING USER PAYMENT METHODS: " + error
          );
        }
      },

      addPaymentMethodDetails: async ({
        userId,
        accountNumber,
        paymentMethod,
      }) => {
        try {
          const userPaymentMethods = await addPaymentMethod({
            user_id: userId,
            account_number: accountNumber,
            payment_method: paymentMethod,
          });

          set(() => ({ userPaymentMethods }));
        } catch (error) {
          console.log("SOMETHING WENT WRONG ADDING PAYMENT METHOD: " + error);
          // displayErrorToast('Something went wrong adding payment method');
        }
      },

      requestPayout: async ({
        user_id,
        payment_method,
        account_number,
        amount,
        event_id,
      }) => {
        try {
          const payoutRequest = await createPayoutRequest({
            user_id,
            payment_method,
            account_number,
            amount,
            event_id,
          });

          set((state) => ({
            payoutRequests: [...state.payoutRequests, payoutRequest],
          }));
        } catch (error) {
          console.log("SOMETHING WENT WRONG REQUESTING PAYOUT: " + error);
          // displayErrorToast('Something went wrong requesting payout');
        }
      },

      fetchUserPayoutRequests: async ({ user_id }) => {
        try {
          const payoutRequests = await fetchUserPayoutRequests({ user_id });

          set(() => ({ payoutRequests }));
        } catch (error) {
          console.log(
            "SOMETHING WENT WRONG FETCHING PAYOUT REQUESTS: " + error
          );
          // displayErrorToast('Something went wrong fetching payout requests');
        }
      },

      getUserPayoutRequestByEventId: async (event_id) => {
        const payoutRequest = {};
        const data = get().payoutRequests?.find(
          (request) => request.event_id === event_id
        );
        if (data) {
          payoutRequest = { ...data };
        }
        return payoutRequest;
      },

      // Async method to pay the organizer
      payOrganizer: async ({ transactionId, amount, eventId, organizerId }) => {
        const headers = {
          "app-code": "LYXA_90",
          "Content-Type": "application/json"
        };

        const url = "https://lyxaevents.com/payment/pay_organizer";

        const body = JSON.stringify({
          "transaction_id": transactionId,
          "amount": amount,
          "event_id": eventId,
          "organizer_id": organizerId,
        });

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body
          });

          if (response.ok) {
            const jsonResponse = await response.json();
            return { status: "success", message: "Transfer Successful", data: jsonResponse };
          } else {
            console.error("Server responded with status:", response.status);
            return { status: "error", message: "Something Went Wrong" };
          }
        } catch (error) {
          console.error("SOMETHING WENT WRONG PAYING ORGANIZER:", error);
          return { status: "error", message: "Something Went Wrong" };
        }
      },

      // METHOD TO ADD MONEY TO THE ORGANZIER'S WALLET
      addMoneyToWallet: async (txId) => {
        try {
          const headers = { 'app-code': 'LYXA_90' };
          const url = 'https://lyxaevents.com/payment/add_money_to_wallet';

          var data = {
            "transaction_id": txId
          }

          const response = await fetch(url, {
            method: 'POST',
            headers: {
              ...headers,
              'Content-Type': 'application/json', // Set the content type for JSON data
            },
            body: JSON.stringify(data), // Convert data to JSON string
          });

          if (response.status === 200) {
            const responseData = await response.json(); // Parse JSON response
            const status = responseData.status;
            console.log('THE STATUS:', status);
            if (status === 'success') {
              return true;
            }
          }

          return false;

        } catch (error) {
          console.error('SOMETHING WENT WRONG UPDATING WALLET:', error);
          return false;
        }

      }



    }),
    { name: "payment_methods_store" }
  )
);

export default usePaymentMethodsStore;
