import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function GoogleMaps() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const updateGoogleMapsPage = useSettings(
    (state) => state.updateGoogleMapsPage
  );
  const getGoogleMapsPage = useSettings((state) => state.getGoogleMapsPage);
  const getGoogleMapsPageId = useSettings((state) => state.getGoogleMapsPageId);
  const google_maps_page = useSettings((state) => state.google_maps_page);
  const layout_page = useSettings((state) => state.layout_page);

  //states
  const [googleForm, setGoogleForm] = useState(google_maps_page || {});
  const [googleID, setGoogleID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getID = async () => {
      const id = await getGoogleMapsPageId();
      setGoogleID(id);
    };
    const getPage = async () => {
      await getGoogleMapsPage();
    };
    getPage();
    getID();
  }, []);

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setGoogleForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateGoogleMapsPage(googleID, {
        api_key: googleForm?.api_key,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("error occurred:", error);
      displayErrorToast("Some thing is wrong");
    }
    setIsLoading(false);
  };

  //loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Google Maps settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"google_maps"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="gmaps_settings"
                      method="post"
                      onSubmit={handleUpdate}
                      noValidate="novalidate"
                    >
                      <div id="form">
                        <div className="form-group">
                          <label htmlFor="form_google_maps_api_key">
                            Google Maps Api Key
                          </label>
                          <small
                            id="form_google_maps_api_key_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Leave api key empty to disable google maps project
                            wide
                          </small>
                          <input
                            type="text"
                            id="form_google_maps_api_key"
                            name="api_key"
                            aria-describedby="form_google_maps_api_key_help"
                            className="form-control"
                            value={googleForm?.api_key || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="form_save"
                            name="form[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="form__token"
                          name="form[_token]"
                          defaultValue="j9MzcTmszQW3lDa63oGoUt0vxMaUeqznVLOr4vqEG_k"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
