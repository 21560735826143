import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropdownPayout from "./DropDownPayout";

import "./sidenav.css";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import app from "../../../Firebase";
import {
  organizer_sidebar_options,
  organizer_sidebar_options2,
} from "../../../components/header/sidebar_options";

function Sidenavbar({ currentPage }) {
  const auth = getAuth(app);

  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  //if the user signed in with google
  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        // Get the user's email address
        const email = auth.currentUser.email;
        // Check if email has an associated account
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        // Check if the user registered with Google
        const isGoogleProvider = signInMethods.some((method) =>
          method.includes("google.com")
        );
        setIsGoogleSignIn(isGoogleProvider);
      }
    };

    fetchUser();
  }, []);

  return (
    <div className=" h-100 sticky-top sticky-sidebar dashboard-sidebar d-xl-block overflow-scroll">
      <ul
        id="dashboard-menu"
        className="nav nav-pills nav-pills-vertical-styled overflow-auto"
        style={{ maxHeight: "30rem" }}
      >
        {organizer_sidebar_options?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}

        {/* PAYOUT COMPONENT */}
        <li className="nav-item">
          <DropdownPayout currentPage={currentPage} />
        </li>

        {organizer_sidebar_options2?.map((option, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${option.link}`}
              className={
                "nav-link " + (currentPage === option.link ? "active" : "")
              }
            >
              <i className={option.icon} /> {option.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidenavbar;
