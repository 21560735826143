import { useEffect, useState } from "react";
import useFetchDocument from "../../customHooks/useFetchDocument";
import { Link, useNavigate, useParams } from "react-router-dom";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { userStore } from "../../store/user_store";
import Loader from "../../components/loader/loader";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import TextEditor from "../../components/text_editor/text_editor";
import Footer from "../../components/footer/footer";
import { useSettings } from "../../store/setting_store";
import { updatePage } from "../../services/blogs";
import { Breadcrumpadmin2 } from "./components/breadcrump_admin";

export default function EditPage() {
  // const updatePage = useBlogs((state) => state.updatePage);
  const layout_page = useSettings((state) => state.layout_page);
  const isAdmin = userStore((state) => state.isAdmin);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { page_id } = useParams();
  const { document } = useFetchDocument("pages", page_id);
  console.log("the page document got is:", document);
  const [pageForm, setPageForm] = useState(document || {});

  useEffect(() => {
    setPageForm(document);
  }, [document]);

  //handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPageForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //HANDLE DESCRIPTION
  const handleDescriptionChange = (value) => {
    setPageForm((prevFormData) => ({
      ...prevFormData,
      content: value,
    }));
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING TITLE
    if (!pageForm?.title) {
      errors.title = "Title is required";
    }

    // VALIDATING content
    if (!pageForm?.content) {
      errors.content = "Content is required";
    }

    return errors;
  }
  //HANDLE EDITING ARTICLE

  const handleEditPage = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updatePage(page_id, {
          title: pageForm?.title,
          content: pageForm?.content,
        });
        setIsLoading(false);
        navigate("/manage_pages");
        displaySuccessToast("Update succeeded");
      } catch (error) {
        setIsLoading(false);
        console.log("error in updating the page:", error);
        displayErrorToast("Update failed");
      }
    } else {
      console.log("SOMETHING WENT WRONG-");
      setErrors(errors);
    }
  };

  // const { isAdmin } = useContext(myContext);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit help center article"
          page_link="Manage pages"
          link="manage_pages"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_pages"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="edit_page"
                      noValidate="novalidate"
                      onSubmit={handleEditPage}
                    >
                      <div id="edit_page">
                        {/* PAGE DETAILS */}

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Page content
                          </legend>
                          <div className="a2lix_translations">
                            <div className="a2lix_translationsFields tab-content">
                              <div
                                id="help_center_article_translations_en_a2lix_translations-fields"
                                className="tab-pane show active "
                                role="tabpanel"
                              >
                                <div id="help_center_article_translations_en">
                                  {/* TITLE */}
                                  <div className="form-group">
                                    <label
                                      htmlFor="help_center_article_translations_en_title"
                                      className="required"
                                    >
                                      Title
                                    </label>

                                    {errors.title && (
                                      <span
                                        style={{ marginTop: 0, color: "red" }}
                                      >
                                        {errors.title}
                                      </span>
                                    )}
                                    <input
                                      type="text"
                                      id="help_center_article_translations_en_title"
                                      name="title"
                                      required="required"
                                      maxLength={80}
                                      pattern=".{1,}"
                                      className="form-control"
                                      value={pageForm?.title}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  </div>

                                  {/* ARTICLE CONTENT */}
                                  <div className="form-group">
                                    <label
                                      htmlFor="help_center_article_translations_en_content"
                                      className="required"
                                    >
                                      Content
                                    </label>

                                    {errors.content && (
                                      <span
                                        style={{ marginTop: 0, color: "red" }}
                                      >
                                        {errors.content}
                                      </span>
                                    )}
                                    <TextEditor
                                      value={pageForm?.content || ""}
                                      onChange={handleDescriptionChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            name="edit_page"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
