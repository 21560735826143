/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import Sidenavbar from "./components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { isMobile } from "react-device-detect";
import { userStore } from "../../store/user_store";
import { usePromoCodes } from "../../store/promocode_store";
import { useSettings } from "../../store/setting_store";
import DeletePromocode from "../admin_dashboard/events/DeletePromocode";
import { auth } from "../../Firebase";

export default function MyPromocodes() {
  const navigate = useNavigate();
  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const getOrganizerPromocodes = usePromoCodes(
    (state) => state.getOrganizerPromocodes
  );
  const getPromoCodes = usePromoCodes((state) => state.getPromoCodes);
  const promocodes = usePromoCodes((state) => state.promoCodes);
  const version = usePromoCodes((state) => state.version);
  const layout_page = useSettings((state) => state.layout_page);

  // OTHER VARIABLES
  const [isLoading, setIsLoading] = useState(false);
  const [organizerPromocodes, setOrganizerPromocodes] = useState([]);

  // FUNCTION TO GET THE DETAILS OF THE USER
  useEffect(() => {
    const fetchPromo = async () => {
      await getPromoCodes();
    };

    const fetchData = async () => {
      const repsonse = await getOrganizerPromocodes(user?.user_id);
      console.log("response is:", repsonse);
      setOrganizerPromocodes(repsonse);
    };
    fetchPromo();
    fetchData();
  }, [promocodes?.length, version]);

  // DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"My Promocodes"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"my_promocodes"} />}
              </aside>

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    {/* NUMBER OF PROMOCODES */}
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0 d-flex justify-content-start align-content-center">
                      <span className="center-lg-y text-muted">
                        {organizerPromocodes?.length} Promocode(s) found
                      </span>
                    </div>

                    {/* ADD PRMOCODE */}
                    <div className="col-sm-12 col-lg-10 text-center text-lg-right d-flex justify-content-end align-items-center">
                      <Link
                        to="/add_promocode"
                        className="btn text-white ml-3 my-2"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                      >
                        <i className="fas fa-plus" /> Add Promocode
                      </Link>
                    </div>
                  </div>
                </div>

                {organizerPromocodes?.length === 0 ? (
                  //IF NO EVENTS FOUND
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" />
                    No Promocodes found
                  </div>
                ) : (
                  //DISPLAYING USER EVENTS
                  <div className="table-responsive" style={{ width: "100%" }}>
                    <table className="table table-hover table-vcenter">
                      <thead>
                        <tr>
                          <th style={{ width: "22%" }}>Code</th>
                          <th>Description</th>
                          <th>Discount Type</th>
                          <th>Discount</th>
                          <th>Times Used</th>
                          <th>Status</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {organizerPromocodes?.map((promoCode, index) => {
                          return (
                            <tr key={index}>
                              {/* CODE */}
                              <td>{promoCode?.code}</td>

                              {/* DESCRIPTION */}
                              <td>{promoCode?.description}</td>

                              {/* DISCOUNT TYPE */}
                              <td>{promoCode?.discountType}</td>

                              {/* DISCOUNT */}
                              <td>{promoCode?.discount}</td>

                              {/* TIMES USED */}
                              <td>{promoCode?.beneficiaries?.length ?? 0}</td>

                              {/* STATUS */}
                              <td>
                                <span
                                  className={
                                    promoCode?.isActive
                                      ? "badge badge-success"
                                      : "badge badge-danger"
                                  }
                                >
                                  {promoCode?.isActive ? "Active" : "Inactive"}
                                </span>
                              </td>
                              <td className="text-center">
                                <div className="row">
                                  {/* EDIT */}
                                  <div className="col-6">
                                    <Link
                                      to={"/edit_promocode/" + promoCode?.id}
                                      state={{ promoCode }}
                                    >
                                      <i
                                        className="fas fa-edit text-info"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </Link>
                                  </div>
                                  {/* DELETE PROMOCODE */}
                                  <DeletePromocode
                                    setIsLoading={setIsLoading}
                                    promo_id={promoCode?.id}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
