import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../../user_context";
import { userStore } from "../../../store/user_store";
import { useSettings } from "../../../store/setting_store";
import PopingOutPayments from "./PopingOutPayments";
import Loader from "../../../components/loader/loader";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManagePayments() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const getPaymentGateways = useSettings((state) => state.getPaymentGateways);
  const paymentGateways = useSettings((state) => state.paymentGateways);
  const navigate = useNavigate();
  const getPaymentPageId = useSettings((state) => state.getPaymentPageId);
  const updatePaymentPage = useSettings((state) => state.updatePaymentPage);
  const getPaymentPage = useSettings((state) => state.getPaymentPage);
  const payment_page = useSettings((state) => state.payment_page);
  const layout_page = useSettings((state) => state.layout_page);

  //states
  const [payment_id, setPaymentID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [paymentPage, setPaymentPage] = useState(payment_page || {});
  console.log("the payment page is:", paymentPage);
  const currencies = useSettings((state) => state.currencies);

  useEffect(() => {
    const fetchData = async () => {
      await getPaymentGateways();
    };
    const getID = async () => {
      const id = await getPaymentPageId();
      setPaymentID(id);
    };
    const getPage = async () => {
      await getPaymentPage();
    };

    const getCurrencies = () => {
      const get_currencies = currencies.map((currency) => {
        return {
          value: currency?.id,
          label: currency?.ccy,
        };
      });
      setCurrency(get_currencies);
    };
    getCurrencies();
    getPage();
    getID();
    fetchData();
  }, []);

  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setPaymentPage((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setPaymentPage((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //update the payment page
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updatePaymentPage(payment_id, {
        allow_paypal: paymentPage?.allow_paypal,
        allow_stripe: paymentPage?.allow_stripe,
        currency: paymentPage?.currency,
        percentage_cut: paymentPage?.percentage_cut,
        point_of_sale_fee: paymentPage?.point_of_sale_fee,
        point_of_sale_percent_cut: paymentPage?.point_of_sale_percent_cut,
        symbol_position_left: paymentPage?.symbol_position_left,
        ticket_fee: paymentPage?.ticket_fee,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("error occurred:", error);
      displayErrorToast("Some thing is wrong");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Payment settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_payments"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card">
                  <div className="card-header">
                    <h6 className="b mb-0 float-lg-left">
                      Global payment settings
                    </h6>
                    <a
                      href="/manage_currencies"
                      className="btn text-white ml-3 float-lg-right"
                      style={{ backgroundColor: layout_page?.primary_color }}
                    >
                      <i className="fas fa-coins" /> Manage currencies
                    </a>
                  </div>

                  <div className="card-body">
                    <form
                      name="payment_settings"
                      onSubmit={handleUpdate}
                      noValidate="novalidate"
                    >
                      <div id="form">
                        <div className="form-group">
                          <label className="required" htmlFor="form_currency">
                            Currency
                          </label>
                          <SearchableDropdown
                            value={paymentPage?.currency}
                            name="currency"
                            options={currency}
                            isMulti={false}
                            onChange={(selectedOption) =>
                              handleInputChanging({
                                target: {
                                  name: "currency",
                                  value: selectedOption,
                                },
                              })
                            }
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Currency symbol position
                          </legend>
                          <div id="form_position">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_position_0"
                                name="symbol_position_left"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={
                                  paymentPage?.symbol_position_left === true
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_position_0"
                              >
                                Left
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_position_1"
                                name="symbol_position_left"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={
                                  paymentPage?.symbol_position_left === false
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_position_1"
                              >
                                Right
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <label
                            htmlFor="form_ticket_fee_online"
                            className="required"
                          >
                            Ticket fee (Online)
                          </label>
                          <small
                            id="form_ticket_fee_online_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            This fee will be added to the ticket sale price
                            which are bought online, put 0 to disable additional
                            fees for tickets which are bought online, does not
                            apply for free tickets, will be applied to future
                            orders
                          </small>
                          <input
                            type="text"
                            id="form_ticket_fee_online"
                            name="ticket_fee"
                            required="required"
                            className="touchspin-decimal form-control"
                            data-min={0}
                            data-max={1000000}
                            value={paymentPage?.ticket_fee || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="form_ticket_fee_pos"
                            className="required"
                          >
                            Ticket fee (Point Of Sale)
                          </label>
                          <small
                            id="form_ticket_fee_pos_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            This fee will be added to the ticket sale price
                            which are bought from a point of sale, put 0 to
                            disable additional fees for tickets which are bought
                            from a point of sale, does not apply for free
                            tickets, will be applied to future orders
                          </small>
                          <input
                            type="text"
                            id="form_ticket_fee_pos"
                            name="point_of_sale_fee"
                            required="required"
                            className="touchspin-decimal form-control"
                            data-min={0}
                            data-max={1000000}
                            value={paymentPage?.point_of_sale_fee || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="form_online_ticket_price_percentage_cut"
                            className="required"
                          >
                            Ticket price percentage cut (Online)
                          </label>
                          <small
                            id="form_online_ticket_price_percentage_cut_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            This percentage will be deducted from each ticket
                            sold online, upon organizer payout request, this
                            percentage will be taken from each ticket sold
                            online, will be applied to future orders
                          </small>
                          <input
                            type="text"
                            id="form_online_ticket_price_percentage_cut"
                            name="percentage_cut"
                            required="required"
                            className="touchspin-integer form-control"
                            data-min={0}
                            data-max={100}
                            value={paymentPage?.percentage_cut || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="form_pos_ticket_price_percentage_cut"
                            className="required"
                          >
                            Ticket price percentage cut (Point of sale)
                          </label>
                          <small
                            id="form_pos_ticket_price_percentage_cut_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            This percentage will be deducted from each ticket
                            sold on a point of sale, upon organizer payout
                            request, this percentage will be taken from each
                            ticket sold on a point of sale, will be applied to
                            future orders
                          </small>
                          <input
                            type="text"
                            id="form_pos_ticket_price_percentage_cut"
                            name="point_of_sale_percent_cut"
                            required="required"
                            className="touchspin-integer form-control"
                            data-min={0}
                            data-max={100}
                            value={paymentPage?.point_of_sale_percent_cut || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Allow Paypal as a payout method for the organizers
                            to receive their revenue
                          </legend>
                          <div id="form_organizer_payout_paypal_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_organizer_payout_paypal_enabled_0"
                                name="allow_paypal"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={paymentPage?.allow_paypal === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_organizer_payout_paypal_enabled_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_organizer_payout_paypal_enabled_1"
                                name="allow_paypal"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={paymentPage?.allow_paypal === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_organizer_payout_paypal_enabled_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Allow Stripe as a payout method for the organizers
                            to receive their revenue
                          </legend>
                          <div id="form_organizer_payout_stripe_enabled">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_organizer_payout_stripe_enabled_0"
                                name="allow_stripe"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={paymentPage?.allow_stripe === true}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_organizer_payout_stripe_enabled_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_organizer_payout_stripe_enabled_1"
                                name="allow_stripe"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={paymentPage?.allow_stripe === false}
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_organizer_payout_stripe_enabled_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="form_save"
                            name="form[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="form__token"
                          name="form[_token]"
                          defaultValue="jF5XhPBVUOlk_fLis6AWXRUb_aa-SeHV2iTbesj6-HA"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card mt-5">
                  <div className="card-header">
                    <h6 className="b mb-0 float-lg-left">Payment gateways</h6>
                    <Link
                      to="/add_new_paymentgateway"
                      className="btn text-white ml-3 float-lg-right"
                      style={{ backgroundColor: layout_page?.primary_color }}
                      data-toggle="tooltip"
                      title="Add a new payment gateway"
                    >
                      <i className="fas fa-plus" />
                    </Link>
                  </div>
                  <div className="card-body">
                    <table className="table table-hover table-vcenter text-nowrap">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Logo</th>
                          <th>Status</th>
                          <th>Order of appearance</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentGateways?.map((paymentGateway, index) => {
                          return (
                            <tr>
                              <td> {paymentGateway?.name} </td>

                              <td>
                                <img
                                  src={paymentGateway?.image}
                                  className="img-thumbnail img-50-50"
                                  alt={paymentGateway?.name}
                                />
                              </td>

                              <td>
                                {paymentGateway?.status === true ? (
                                  <span className="badge badge-success">
                                    <i className="fas fa-eye fa-fw" /> Enabled
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    <i className="fas fa-eye fa-fw" /> Disabled
                                  </span>
                                )}
                              </td>
                              <td>{paymentGateway?.appearance_order}</td>
                              <td>
                                <PopingOutPayments
                                  gateway_id={paymentGateway?.id}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
