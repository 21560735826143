import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { BsGraphUpArrow } from "react-icons/bs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid white", // Apply white border
  boxShadow: 24,
  borderRadius: 2, // Apply border radius
  p: 4,
};

export default function StatisticsModal({
  event_id,
  event_name,
  registration_date,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        onClick={handleOpen}
        className="d-flex gap-1 cursor-pointer text-black-50"
      >
        <BsGraphUpArrow />
        <h6>Statistics</h6>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="">
              {event_name}:{""} Event dates
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className=" d-flex justify-content-between">
              {registration_date}{" "}
              <Link to={"/event_statistics/" + event_id}>View stat</Link>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
