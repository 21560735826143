import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ordersStore } from "../../../store/orders_store";
import { useSettings } from "../../../store/setting_store";

const OrganizerButton = ({ event_id }) => {
  const getOrganizers = ordersStore((state) => state.getOrganizerDetail);
  const layout_page = useSettings((state) => state.layout_page);

  const [organizer, setOrganizer] = useState([]);

  useEffect(() => {
    const fetchOrganizer = async () => {
      const organizer_details = await getOrganizers(event_id);
      setOrganizer(organizer_details);
    };
    fetchOrganizer();
  }, [event_id]);

  return (
    <Link
      style={{
        color: layout_page?.primary_color,
      }}
      to={"/organizer_details/" + organizer?.id}
      className="btn btn-default btn-sm ml-3">
      <i className="far fa-id-card mr-1" />
      Organizer
    </Link>
  );
};

export default OrganizerButton;
