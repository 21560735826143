import React from "react";
import Select from "react-select";
import "./searchable_dropdown.css";

export default function SearchableDropdown({
  options,
  value,
  onChange,
  placeholder,
  isMulti,
  defaultValue,
}) {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      isSearchable={true}
      placeholder={placeholder || "Select an option"}
      defaultValue={defaultValue}
      style={{ zIndex: 9999 }}
    />
  );
}
