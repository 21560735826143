import React from "react";

import EventTile from "./event_tile";
import CustomPagination from "../pagination/Pagination";
import { useSettings } from "../../store/setting_store";

export default function EventTileList({ events }) {
  const event_settings_page = useSettings((state) => state.event_settings_page);
  const numberOfEventsPerPage = event_settings_page?.events_per_page;
  const [currentPage, setCurrentPage] = React.useState(1);

  const indexOfLastEvent = currentPage * numberOfEventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - numberOfEventsPerPage;

  const eventsToRender = events?.slice(indexOfFirstEvent, indexOfLastEvent);

  if (!events || events.length === 0) {
    return (
      <div className="col-lg-9 order-1 order-lg-2">
        <div className="alert alert-info alert-icon">
          <i className="fas fa-exclamation-circle" /> No events found
        </div>
      </div>
    );
  }

  return (
    <div className="col-lg-9 order-1 order-lg-2">
      <div className="row">
        {eventsToRender?.map((event, index) => {
          return <EventTile event={event} num_tiles={"6"} key={index} />;
        })}
      </div>
      {events?.length > numberOfEventsPerPage && (
        <CustomPagination
          totalPages={Math.round(events?.length / numberOfEventsPerPage)}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
}
