import React, { useState } from "react";
import "./ScrollToTopButton.css"; // You can define your own styles for the button
import { useSettings } from "../../store/setting_store";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const layout_page = useSettings((state) => state.layout_page);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    setIsVisible(scrollTop > 300); // Show the button when scrolling down 300 pixels
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to the top
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          className="scroll-to-top-button material-scrolltop cursor-pointer btn btn-sm text-white reveal"
          style={{ backgroundColor: layout_page?.primary_color }}
          onClick={scrollToTop}></button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
