import React, { useEffect, useState } from "react";
import { useBlogs } from "../../../store/blog_store";

const BlogPostCount = ({ category_id }) => {
  const blogposts = useBlogs((state) => state.blogposts);
  const [count, setCount] = useState(0);

  //counting events have have same category
  useEffect(() => {
    const fetchCount = () => {
      const c = blogposts.filter(
        (blogpost) => blogpost?.category?.value === category_id
      );

      setCount(c.length);
    };
    fetchCount();
  }, [category_id]);
  return <div>{count}</div>;
};

export default BlogPostCount;
