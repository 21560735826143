import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/setting_store";
import { Dropdown } from "react-bootstrap";

const PopingOutReportsOrganizer = ({ event_id, setIsLoading }) => {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {" "}
          <Link to={"/manage_event/" + event_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <i className="dropdown-icon fas fa-calendar-alt fa-fw text-muted" />{" "}
              <h6>Manage Event</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* View Statistics */}
          <Link to={"/event_statistics/" + event_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <i className="dropdown-icon fas fa-chart-line fa-fw text-muted" />{" "}
              <h6>View Detailed Statistics</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* Order details */}
          <Link to={"/manage_organizer_orders/" + event_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <i className="dropdown-icon fas fa-cart-arrow-down fa-fw text-muted" />{" "}
              <h6>Orders</h6>
            </div>
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutReportsOrganizer;
