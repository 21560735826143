import React, { useState } from "react";
import { Typography } from "@mui/material";
import ModalDialog from "@mui/joy/ModalDialog";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { usePromoCodes } from "../../../store/promocode_store";

const DeletePromocode = ({ promo_id, setIsLoading }) => {
  // FUNCTION TO DELETE PROMOCODE
  const deletePromoCode = usePromoCodes((state) => state.deletePromoCode);
  const [open, setOpen] = useState(false);

  // method to handle delete promocodes
  const handleDeletePromocode = async () => {
    console.log("the id is:", promo_id);
    setIsLoading(true);
    const response = await deletePromoCode(promo_id);
    if (response) {
      displaySuccessToast("Promocode deleted successfully");
    } else {
      displayErrorToast("Failed to delete promocode");
    }
    setOpen(false);
    setIsLoading(false);
  };
  return (
    <>
      <div className="col-6">
        <i
          className="fas fa-trash text-danger"
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        ></i>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" component="h2">
            Are you sure you want to delete this promocode?
          </Typography>

          <Box
            sx={{
              mt: 3,
              display: "flex",
              gap: 3,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: {
                xs: "column",
                sm: "row-reverse",
              },
            }}
          >
            <Button
              className=" bg-danger"
              onClick={() => handleDeletePromocode()}
            >
              Delete
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default DeletePromocode;
