import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Loader from "../../components/loader/loader";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { userStore } from "../../store/user_store";
import { useEvents } from "./../../store/event_store";
import { useReview } from "../../store/review_store";
import { useSettings } from "../../store/setting_store";

export default function WriteAReview() {
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const getEventReviews = useReview((state) => state.getEventReviews);
  const getAverageEventReviews = useReview(
    (state) => state.getAverageEventReviews
  );
  const getEvent = useEvents((state) => state.getEvent);
  const addReview = useReview((state) => state.addReview);
  const layout_page = useSettings((state) => state.layout_page);

  const { event_id } = useParams();
  const [event, setEvent] = useState({});
  const [rating, setRating] = useState(1);
  const [review_headline, setReviewHeadline] = useState("");
  const [review_body, setReviewBody] = useState("");
  const [averageReviews, setAverageReviews] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // METHOD TO HANDLE GETTING EVENT REVIEWS
  async function handleGetEventReviews() {
    var response = await getEventReviews(event_id);
    setReviews(response);
  }

  // METHOD TO HANDLE GETTING EVENT REVIEWS
  async function handleGetAverageReviews(eventId) {
    const response = await getAverageEventReviews(eventId);
    setAverageReviews(response);
  }

  // METHOD TO HANDLE GETTING EVENT REVIEWS
  async function handleGetEventDetails() {
    const response = await getEvent(event_id);
    setEvent(response);
  }

  // USE EFFECT
  useEffect(() => {
    console.log("THE USER KEYS: " + Object.keys(user));
    handleGetEventReviews();
    handleGetAverageReviews(event_id);
    handleGetEventDetails(event_id);

    // REDIRECT THE USER TO SIGNIN PAGE IF NOT LOGGED IN
    if (!user) {
      navigate("/sign_in");
    }
  }, []);

  // METHOD TO COUNT RATINGS
  function countRatings(reviewsList, targetRating) {
    console.log("THE LENGTH OF THE REVIEWS LIST: " + reviewsList?.length);
    console.log("THE TARGET RATING: " + targetRating);

    const count = reviewsList?.filter(
      (review) => review?.rating === targetRating
    )?.length;
    var percentage =
      reviewsList?.length === 0 ? 0 : (count / reviewsList?.length) * 100;
    return percentage;
  }

  // METHOD TO HANDLE RATING
  function handleRating(event) {
    var rating = event.target.value;
    console.log("THE RATING IS: " + rating);
    setRating(rating);
  }

  // METHOD TO HANDLE RATING
  async function handleAddReview(e) {
    e.preventDefault();
    setIsLoading(true);
    var data = {
      id: null,
      event_id: event_id,
      event_image: event?.main_event_image,
      event_name: event?.name,
      user_id: user?.user_id,
      user_image: user?.profile_picture,
      user_name: user?.firstname,
      rating: rating,
      review_headline: review_headline,
      review_body: review_body,
      status: "visible",
      created_at: Timestamp.fromDate(new Date()),
    };
    var response = await addReview(data);

    // CHECKING IF THE REVIEW WAS ADDED
    if (response) {
      navigate("/event_details/" + event_id);
      displaySuccessToast("Review added successfully");
    } else {
      displayErrorToast("Error adding review");
    }
    setIsLoading(false);
  }

  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();

  if (!user) {
    navigate("/sign_in");
    return;
  }

  // DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isAttendee) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* WRITE A REVIEW BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">
              Add your review for {event.name}
            </h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <Link
                    to="/"
                    className="dark"
                    style={{ color: layout_page?.primary_color }}
                  >
                    <i className="fas fa-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to="/browse_events"
                    className="dark"
                    style={{ color: layout_page?.primary_color }}
                  >
                    Events
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add your review for {event?.name}
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/* WRITE A REVIEW MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row mb-5 event-scorecard">
              <div className="col-12 col-lg-7">
                {/* EVENT IMAGE */}
                <img
                  className="img-lazy-load b-loaded"
                  src={
                    event?.main_event_image ??
                    "https://theperfectroundgolf.com/wp-content/uploads/2022/04/placeholder.png"
                  }
                  alt={event?.name}
                  height="200"
                />
                <div className="ml-5 display-inline-block">
                  {/* EVENT NAME */}
                  <h3>
                    <Link
                      to={"/event_details/" + event?.id}
                      style={{ color: layout_page?.primary_color }}
                    >
                      {event?.name}
                    </Link>
                  </h3>

                  <div className="rating-wrap">
                    <ul className="rating-stars mr-2">
                      <li style={{ width: "100%" }} className="stars-active">
                        {Array.from({ length: averageReviews }, (_, index) => (
                          <i key={index} className="fa fa-star" />
                        ))}
                      </li>
                      <li>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                    <div className="label-rating">
                      {averageReviews} out of 5 stars
                    </div>
                  </div>
                </div>
              </div>

              {/* EVENT CURRENT RATINGS */}
              <div className="col-12 col-lg-5">
                {/* 5 STARTS */}
                <div className="side">
                  <div>5 stars</div>
                </div>
                <div className="middle">
                  <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${countRatings(reviews, "5")}%` }}
                    />
                  </div>
                </div>

                <div className="side right">
                  <div className="text-muted">
                    {countRatings(reviews, "5")}%
                  </div>
                </div>

                {/* 4 STARS */}
                <div className="side">
                  <div>4 stars</div>
                </div>
                <div className="middle">
                  <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${countRatings(reviews, "4")}%` }}
                    />
                  </div>
                </div>
                <div className="side right">
                  <div className="text-muted">
                    {countRatings(reviews, "4")}%
                  </div>
                </div>

                {/* 3 STARS */}
                <div className="side">
                  <div>3 stars</div>
                </div>
                <div className="middle">
                  <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${countRatings(reviews, "3")}%` }}
                    />
                  </div>
                </div>
                <div className="side right">
                  <div className="text-muted">
                    {countRatings(reviews, "3")}%
                  </div>
                </div>

                {/* 2 STARS */}
                <div className="side">
                  <div>2 stars</div>
                </div>
                <div className="middle">
                  <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${countRatings(reviews, "2")}%` }}
                    />
                  </div>
                </div>
                <div className="side right">
                  <div className="text-muted">
                    {countRatings(reviews, "2")}%
                  </div>
                </div>

                {/* 1 STAR */}
                <div className="side">
                  <div>1 star</div>
                </div>
                <div className="middle">
                  <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${countRatings(reviews, "1")}%` }}
                    />
                  </div>
                </div>
                <div className="side right">
                  <div className="text-muted">
                    {countRatings(reviews, "1")}%
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card box">
                  <div className="card-body">
                    {/* MAIN WRAPPER FORM */}
                    <form
                      name="review"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleAddReview}
                    >
                      <div id="review">
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Your rating (out of 5 stars)
                          </legend>
                          <div id="review_rating">
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="review_rating_0"
                                name="review[rating]"
                                required="required"
                                className="custom-control-input"
                                defaultValue={5}
                                onChange={handleRating}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="review_rating_0"
                              >
                                5 stars
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="review_rating_1"
                                name="review[rating]"
                                required="required"
                                className="custom-control-input"
                                defaultValue={4}
                                onChange={handleRating}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="review_rating_1"
                              >
                                4 stars
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="review_rating_2"
                                name="review[rating]"
                                required="required"
                                className="custom-control-input"
                                defaultValue={3}
                                onChange={handleRating}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="review_rating_2"
                              >
                                3 stars
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="review_rating_3"
                                name="review[rating]"
                                required="required"
                                className="custom-control-input"
                                defaultValue={2}
                                onChange={handleRating}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="review_rating_3"
                              >
                                2 stars
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="review_rating_4"
                                name="review[rating]"
                                required="required"
                                className="custom-control-input"
                                defaultValue={1}
                                onChange={handleRating}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="review_rating_4"
                              >
                                1 star
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/* REVIEW HEADLINE */}
                        <div className="form-group">
                          <label htmlFor="review_headline" className="required">
                            Your review headline
                          </label>
                          <input
                            type="text"
                            id="review_headline"
                            name="review[headline]"
                            required="required"
                            className="form-control"
                            placeholder="e.g. Nice Experience"
                            onChange={(e) => setReviewHeadline(e.target.value)}
                          />
                        </div>

                        {/* REVIEW MAIN BODY */}
                        <div className="form-group">
                          <label htmlFor="review_details" className="required">
                            Let the other attendee know more details about your
                            experience
                          </label>
                          <textarea
                            id="review_details"
                            name="review[details]"
                            required="required"
                            rows={10}
                            className="form-control"
                            defaultValue={""}
                            onChange={(e) => setReviewBody(e.target.value)}
                          />
                        </div>

                        {/* SUBMIT BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="review_save"
                            name="review[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
