import React from "react";
import { useSettings } from "../../../store/setting_store";

export default function DeleteButton({ text }) {
  return (
    <button
      type="submit"
      className="btn btn-outline-primary rounded-3 text-capitalize"
      style={{
        paddingBlock: 6,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <i class="fas fa-trash-alt mr-1" style={{ fontSize: 14 }}></i>
      {text}
    </button>
  );
}

export function AddButton({ text, icon }) {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <button
      type="submit"
      className="btn text-white btn rounded-3 text-capitalize"
      style={{
        backgroundColor: layout_page?.primary_color,
        paddingBlock: 6,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {/* <i class="fas fa-plus mr-2"></i>
       */}
      {icon}
      {text}
    </button>
  );
}

export function ProceedButton({ text }) {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <button
      type="submit"
      className="btn text-white btn rounded-3 text-capitalize"
      style={{
        backgroundColor: layout_page?.primary_color,
        marginTop: 60,
      }}
    >
      <i class="fas fa-save mr-2 fs-6"></i>
      {text}
    </button>
  );
}
