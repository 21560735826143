/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { userStore } from "../../../store/user_store";
import { usePromoCodes } from "../../../store/promocode_store";
import { useSettings } from "../../../store/setting_store";
import Loader from "../../../components/loader/loader";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import AdminSideNav from "../components/admin_side_nav";
import CustomPagination from "../../../components/pagination/Pagination";
import BreadcrumpAdmin from "../components/breadcrump_admin";
import DeletePromocode from "./DeletePromocode";

export default function Promocodes() {
  const navigate = useNavigate();
  const isAdmin = userStore((state) => state.isAdmin);
  const getPromoCodes = usePromoCodes((state) => state.getPromoCodes);
  const promocodes = usePromoCodes((state) => state.promoCodes);
  const version = usePromoCodes((state) => state.version);
  const layout_page = useSettings((state) => state.layout_page);

  // OTHER VARIABLES
  const [isLoading, setIsLoading] = useState(false);

  // FUNCTION TO GET THE DETAILS OF THE USER
  useEffect(() => {
    const fetchPromo = async () => {
      await getPromoCodes();
    };

    fetchPromo();
  }, [promocodes?.length, version]);

  //SETTING FILTERS
  const [percentageFilter, setPercentageFilter] = useState("all");
  const [codeFilter, setCodeFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");

  //filtering logic
  const filteredPromocodes = promocodes.filter((promocode) => {
    return (
      (percentageFilter === "all" ||
        promocode.discountType === percentageFilter) &&
      (!codeFilter ||
        (promocode.code &&
          promocode.code.toLowerCase().includes(codeFilter.toLowerCase()))) &&
      (!descriptionFilter ||
        (promocode.description &&
          promocode.description
            .toLowerCase()
            .includes(descriptionFilter.toLowerCase()))) &&
      (activeFilter === "all" ||
        (activeFilter === "active" &&
          (promocode.isActive === true ||
            promocode.isActive === "" ||
            promocode.isActive === null)) ||
        (activeFilter === "inactive" && promocode.isActive === false))
    );
  });

  console.log("the filtered promos:", filteredPromocodes);

  // Apply filtering to categories
  const [sortCriteria, setSortCriteria] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedPromocodes = filteredPromocodes.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "createdAt") {
      const dateA = a.createdAt;
      const dateB = b.createdAt;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });
  console.log("the sorted promo:", sortedPromocodes);

  //pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [promocodePerPage] = useState(10);
  //get current events
  const indexOfLastpromocode = currentPage * promocodePerPage;
  const indexOfFastpromocode = indexOfLastpromocode - promocodePerPage;
  const current_promocodes = sortedPromocodes?.slice(
    indexOfFastpromocode,
    indexOfLastpromocode
  );

  // DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />
        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Promocodes" />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              {!isMobile && <AdminSideNav currentPage={"promocodes"} />}

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    {/* NUMBER OF PROMOCODES */}
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {promocodes?.length} Promocode(s) found
                      </span>
                    </div>

                    {/* SEARCH */}
                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`createdAt&direction=desc`}
                            selected={
                              sortCriteria === "createdAt" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`createdAt&direction=asc`}
                            selected={
                              sortCriteria === "createdAt" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                    </div>

                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="role">Discount type</label>
                            <select
                              id="discountType"
                              name="discountType"
                              className="form-control bg-white select2"
                              value={percentageFilter}
                              onChange={(e) =>
                                setPercentageFilter(e.target.value)
                              }
                            >
                              <option value="all">All</option>
                              <option value="organizer">Percentage</option>
                              <option value="attendee">Fixed Amount</option>
                            </select>
                          </div>

                          {/* SEARCH USING THE ORGANIZER NAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="code">Search by Code</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="code"
                              name="code"
                              value={codeFilter}
                              onChange={(e) => setCodeFilter(e.target.value)}
                            />
                          </div>

                          {/* SEARCH BY description */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="description">Description</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="description"
                              name="description"
                              value={descriptionFilter}
                              onChange={(e) =>
                                setDescriptionFilter(e.target.value)
                              }
                            />
                          </div>

                          {/* ///////////////// */}

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="enabled-filter-all"
                                type="radio"
                                name="all"
                                value="all"
                                className="custom-control-input"
                                checked={activeFilter === "all"}
                                onChange={() => setActiveFilter("all")}
                              />
                              <label
                                htmlFor="enabled-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="active-filter-yes"
                                type="radio"
                                name="active"
                                value="active"
                                className="custom-control-input"
                                checked={activeFilter === "active"}
                                onChange={() => setActiveFilter("active")}
                              />
                              <label
                                htmlFor="active-filter-yes"
                                className="custom-control-label"
                              >
                                Active only
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="inactive-filter-yes"
                                type="radio"
                                name="inactive"
                                value="inactive"
                                className="custom-control-input"
                                checked={activeFilter === "inactive"}
                                onChange={() => setActiveFilter("inactive")}
                              />
                              <label
                                htmlFor="disabled-filter-yes"
                                className="custom-control-label"
                              >
                                Inactive only
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {current_promocodes?.length === 0 ? (
                  //IF NO EVENTS FOUND
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-exclamation-circle" />
                    No Promocodes found
                  </div>
                ) : (
                  //DISPLAYING USER EVENTS
                  <div class="table-responsive" style={{ width: "100%" }}>
                    <table className="table table-hover table-vcenter">
                      <thead>
                        <tr>
                          <th style={{ width: "22%" }}>Code</th>
                          <th>Description</th>
                          <th>Discount Type</th>
                          <th>Discount</th>
                          <th>Times Used</th>
                          <th>Status</th>
                          <th className="text-center">
                            <i className="fas fa-cog" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {promocodes?.map((promoCode, index) => {
                          return (
                            <tr key={promoCode?.id}>
                              {/* CODE */}
                              <td>{promoCode?.code}</td>

                              {/* DESCRIPTION */}
                              <td>{promoCode?.description}</td>

                              {/* DISCOUNT TYPE */}
                              <td>{promoCode?.discountType}</td>

                              {/* DISCOUNT */}
                              <td>{promoCode?.discount}</td>

                              {/* TIMES USED */}
                              <td>{promoCode?.beneficiaries?.length ?? 0}</td>

                              {/* STATUS */}
                              <td>
                                <span
                                  className={
                                    promoCode?.isActive
                                      ? "badge badge-success"
                                      : "badge badge-danger"
                                  }
                                >
                                  {promoCode?.isActive ? "Active" : "Inactive"}
                                </span>
                              </td>
                              <td className="text-center">
                                <div className="row">
                                  {/* EDIT */}
                                  <div className="col-6">
                                    <Link
                                      to={"/edit_promocodes/" + promoCode?.id}
                                      state={{ promoCode }}
                                    >
                                      <i
                                        className="fas fa-edit text-info"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </Link>
                                  </div>

                                  <DeletePromocode
                                    promo_id={promoCode?.id}
                                    setIsLoading={setIsLoading}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            {/* pagination */}
            {sortedPromocodes?.length > 10 && (
              <CustomPagination
                setCurrentPage={setCurrentPage}
                totalPages={Math.ceil(
                  sortedPromocodes?.length / promocodePerPage
                )}
              />
            )}
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
