import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import { FaTicketAlt } from "react-icons/fa";
import {
  convertTimestampToDateTime,
  formatDate,
  formatNumberWithCommas,
} from "../../services/helpers";
import { Typography } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import ModalDialog from "@mui/joy/ModalDialog";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { ordersStore } from "../../store/orders_store";
import Sidenavbar from "../organizer_dashboard/components/sidenavbar";
import { isMobile } from "react-device-detect";
import OrganizerName from "../attendee_dashboard/OrganizerName";
import { useSettings } from "../../store/setting_store";
import { Breadcrumpadmin2 } from "./components/breadcrump_admin";

export default function OrderDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { order_id } = useParams();

  // const { isAdmin } = useContext(myContext);
  const isAttendee = userStore((state) => state.isAttendee);
  const isAdmin = userStore((state) => state.isAdmin);

  const getOrder = ordersStore((state) => state.getOrder);
  const deleteOrder = ordersStore((state) => state.deleteOrder);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();

  const [order, setOrder] = useState([]);

  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteOrder() {
    setOpen(false);
    setIsLoading(true);
    const response = await deleteOrder(order_id);
    if (response) {
      setIsLoading(false);
      console.log("The order has been deleted successfully");
      displaySuccessToast("🦄 The order has been deleted ");
    } else {
      console.log("SOMETHING WENT WRONG DELETING THE ORDER");
      displayErrorToast("Something went wrong while deleting the order");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getOrder(order_id);
      console.log("the response:", response);
      setOrder(response);
    }
    fetchData();
  }, [order_id]);

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (isAttendee) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Order details"
          page_link="Manage orders"
          link="manage-orders"
        />

        {/* ORDER DETAILS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN SIDE NAV */}
              {isAdmin ? (
                <AdminSideNav currentPage={"manage_orders"} />
              ) : (
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar currentPage={"my_reports"} />}
                </aside>
              )}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {!order && (
                        <div className="alert alert-info alert-icon">
                          <i className="fas fa-exclamation-circle" /> No Orders
                          found yet
                        </div>
                      )}

                      {order && (
                        <div className="card">
                          <div className="card-header">
                            <h4>
                              <span className="badge badge-success rounded">
                                Success
                              </span>{" "}
                              Order {order?.booking_id} placed on{" "}
                              {order?.created_at && (
                                <span className="b">
                                  {formatDate(
                                    convertTimestampToDateTime(
                                      order?.created_at
                                    )
                                  )}
                                </span>
                              )}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-12 ">
                                <div className="card">
                                  <div className="card-header">
                                    Customer Details
                                  </div>
                                  <div className="card-body p-4">
                                    <FaUserCircle size={30} color="gray" />
                                    {order?.customer_name}
                                    {isAdmin && (
                                      <>
                                        <button
                                          className="btn btn-sm ml-3 ajax-loading"
                                          data-title="User information"
                                          data-url="/en/dashboard/administrator/administrator/manage-users/john/more-information"
                                          data-toggle="tooltip"
                                          title=""
                                          data-original-title="User information"
                                        >
                                          <i className="dropdown-icon fas fa-file-alt" />
                                        </button>
                                        <button
                                          data-toggle="collapse"
                                          data-target="#billing-information"
                                          className="btn btn-default btn-sm has-tooltip ml-3"
                                          title=""
                                          data-original-title="Billing information"
                                        >
                                          <i className="fas fa-plus" />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12"></div>
                              <div className="table-responsive">
                                <table className="table table-hover shopping-cart-wrap">
                                  <thead className="text-muted">
                                    <tr>
                                      <th scope="col">Event Tickets</th>

                                      {/* TICKET PRICE */}
                                      <th scope="col" width={100}>
                                        Price
                                      </th>

                                      {/* STATUS */}
                                      <th
                                        scope="col"
                                        className="text-right"
                                        width={100}
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {/* LISTING THE ORDER TICKETS */}
                                    {order?.order_tickets &&
                                    order?.order_tickets.length > 0 ? (
                                      Object.values(
                                        order?.order_tickets?.reduce(
                                          (acc, ticket) => {
                                            if (!acc[ticket.event_id]) {
                                              acc[ticket.event_id] = [];
                                            }
                                            acc[ticket.event_id].push(ticket);
                                            return acc;
                                          },
                                          {}
                                        )
                                      ).map((tickets, index) =>
                                        tickets.map((orderTicket, subIndex) => (
                                          <tr key={subIndex}>
                                            <td>
                                              <figure className="media h-100">
                                                <div className="img-wrap my-auto">
                                                  <img
                                                    src={
                                                      orderTicket?.event_image
                                                    }
                                                    className="img-thumbnail img-sm img-lazy-load b-loaded"
                                                    alt=""
                                                  />
                                                </div>
                                                <figcaption className="media-body my-auto">
                                                  <Link
                                                    to={
                                                      "/event_details/" +
                                                      orderTicket?.event_id
                                                    }
                                                  >
                                                    <h6 className="title mb-2">
                                                      {orderTicket?.event_name}
                                                    </h6>
                                                  </Link>
                                                  <dl className="dlist-inline small">
                                                    <span className="mr-0 b">
                                                      Ticket:{" "}
                                                    </span>
                                                    <span>
                                                      {orderTicket?.ticket_name}
                                                    </span>
                                                  </dl>
                                                  <dl className="dlist-inline small">
                                                    <dt className="mr-1">
                                                      When:{" "}
                                                    </dt>
                                                    <dd>
                                                      {formatDate(
                                                        convertTimestampToDateTime(
                                                          orderTicket?.event_start_date
                                                        )
                                                      )}
                                                    </dd>
                                                  </dl>
                                                  <dl className="dlist-inline small">
                                                    <dt className="mr-1">
                                                      Where:
                                                    </dt>
                                                    <dd>
                                                      {orderTicket?.venue}
                                                    </dd>
                                                  </dl>
                                                  <dl className="dlist-inline small">
                                                    <dt className="mr-1">
                                                      Organizer:
                                                    </dt>
                                                    <dd>
                                                      <OrganizerName
                                                        event_id={
                                                          orderTicket?.event_id
                                                        }
                                                      />
                                                    </dd>
                                                  </dl>
                                                </figcaption>
                                              </figure>
                                            </td>

                                            {/* PRICE */}
                                            <td>
                                              <div className="price-wrap">
                                                UGX{" "}
                                                {formatNumberWithCommas(
                                                  orderTicket?.ticket_price
                                                )}
                                              </div>
                                            </td>

                                            {/* STATUS */}
                                            <td className="text-right">
                                              <div className="price-wrap">
                                                <div className="price">
                                                  {/* BADGE */}
                                                  <span
                                                    className={
                                                      orderTicket?.status ===
                                                      "valid"
                                                        ? "badge badge-success"
                                                        : orderTicket?.status ===
                                                          "invalid"
                                                        ? "badge badge-danger"
                                                        : "badge badge-warning"
                                                    }
                                                  >
                                                    {orderTicket?.status}
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={3}>No orders</td>
                                      </tr>
                                    )}
                                  </tbody>

                                  <tfoot>
                                    <tr>
                                      <td className="pt-5 d-flex " colSpan={4}>
                                        {isAdmin && (
                                          <button
                                            id="order-cancel-button"
                                            data-target="/en/dashboard/administrator/manage-orders/3b1cbdc34dd18fd/cancel"
                                            className="btn btn-outline-primary mr-3 requires-confirmation"
                                            data-confirmation-text="You are about to cancel this order (this action cannot be undone)"
                                          >
                                            <i className="fas fa-window-close fa-fw" />{" "}
                                            Cancel
                                          </button>
                                        )}

                                        <button
                                          id="order-cancel-button"
                                          className="btn text-white mr-3 requires-confirmation"
                                          style={{
                                            backgroundColor:
                                              layout_page?.primary_color,
                                          }}
                                        >
                                          <FaTicketAlt
                                            size={20}
                                            className=" mr-2"
                                          />
                                          Print Tickets
                                        </button>

                                        {isAdmin && (
                                          <div onClick={() => setOpen(true)}>
                                            <button
                                              data-target="/en/dashboard/administrator/manage-orders/3b1cbdc34dd18fd/delete"
                                              className="btn btn-outline-primary mr-3 requires-confirmation"
                                              data-confirmation-text="You are about to delete the order and all its related information (payment details, tickets...)"
                                            >
                                              <i className="fas fa-times fa-fw" />{" "}
                                              Delete
                                            </button>

                                            <Modal
                                              open={open}
                                              onClose={() => setOpen(false)}
                                            >
                                              <ModalDialog
                                                aria-labelledby="nested-modal-title"
                                                aria-describedby="nested-modal-description"
                                                sx={(theme) => ({
                                                  [theme.breakpoints.only(
                                                    "xs"
                                                  )]: {
                                                    top: "unset",
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    borderRadius: 0,
                                                    transform: "none",
                                                    maxWidth: "unset",
                                                  },
                                                })}
                                              >
                                                <Typography
                                                  id="nested-modal-title"
                                                  component="h2"
                                                >
                                                  Are you sure you want to
                                                  delete this order?
                                                </Typography>
                                                <Box
                                                  sx={{
                                                    mt: 3,
                                                    display: "flex",
                                                    gap: 3,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: {
                                                      xs: "column",
                                                      sm: "row-reverse",
                                                    },
                                                  }}
                                                >
                                                  <Button
                                                    className=" bg-danger"
                                                    onClick={(e) =>
                                                      handleDeleteOrder(e)
                                                    }
                                                  >
                                                    Delete
                                                  </Button>
                                                  <Button
                                                    onClick={() =>
                                                      setOpen(false)
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                </Box>
                                              </ModalDialog>
                                            </Modal>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
