// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add custom styles to increase the size of the radio button */
.custom-control-input {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
}

/* Optional styling for the checked state
  .custom-control-input:checked {
    background-color: #007bff;
    border-color: #007bff;
  } */
`, "",{"version":3,"sources":["webpack://./src/pages/admin_dashboard/tables/RoomDetails/bookedTables/style.css"],"names":[],"mappings":"AAAA,+DAA+D;AAC/D;EACE,WAAW,EAAE,qBAAqB;EAClC,YAAY,EAAE,qBAAqB;AACrC;;AAEA;;;;KAIK","sourcesContent":["/* Add custom styles to increase the size of the radio button */\r\n.custom-control-input {\r\n  width: 24px; /* Adjust as needed */\r\n  height: 24px; /* Adjust as needed */\r\n}\r\n\r\n/* Optional styling for the checked state\r\n  .custom-control-input:checked {\r\n    background-color: #007bff;\r\n    border-color: #007bff;\r\n  } */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
