import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";
const PopingOutAudience = ({ audience_id, setIsLoading, isVisible }) => {
  const deleteAudience = useEvents((state) => state.deleteAudience);
  const getHandleStatusAudiences = useEvents(
    (state) => state.handleStatusAudiences
  );
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteAudience(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      await deleteAudience(audience_id);
      setIsLoading(false);
      displaySuccessToast("The audience has been deleted succeesfully");
    } catch (error) {
      console.log("Error occured in deleting the audience", error);
      displayErrorToast("some thing is wrong in deleting the audience");
    }
  }

  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await getHandleStatusAudiences(audience_id);
    setIsLoading(false);
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* EDIT AUDIENCE */}

          <Link to={"/edit_audience/" + audience_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE AUDIENCE */}
          <DeleteComponentModal
            itemName="Audience"
            handleDelete={handleDeleteAudience}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutAudience;
