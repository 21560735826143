import React from "react";
import { Link } from "react-router-dom";

function BreadCrump({ title }) {
  return (
    <section class="section-pagetop bg-gray">
      <div class="container clearfix">
        <h4 class="title-page dark b float-xl-left mb-0">{title}</h4>

        <nav class="float-xl-right mt-2 mt-xl-0">
          <ol class="breadcrumb text-white">
            <li class="breadcrumb-item">
              <Link to="/" class="dark">
                <i class="fas fa-home"></i>
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {title}
            </li>
          </ol>
        </nav>
      </div>
    </section>
  );
}

export default BreadCrump;
