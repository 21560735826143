import { useState } from 'react';

import Input from './Input';
import Select from './Select';
import Description from './Description';

const data = [
  { value: 1, label: 'Airtel Money' },
  { value: 2, label: 'MTN Mobile Money' },
];

export default function AddBeneficiaryPhoneNumber() {
  const [value, setValue] = useState('');
  return (
    <>
      <Select
        placeholder="Enter Mobile Network"
        label="Mobile Network"
        data={data}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Input placeholder="Enter phone number" label="Phone Number" />
      <Input placeholder="Enter contact name" label="Contact Name" />
      <Input
        placeholder="Enter brief description"
        label="Brief Description"
        info={<Description />}
      />
    </>
  );
}
