import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import "./search_bar.css";
import useQueryStore from "../../store/query_store";
import { useNavigate } from "react-router-dom";
import { useEvents } from "../../store/event_store";
import { auth } from "../../Firebase";
import { Timestamp } from "firebase/firestore";

const textfield_sx = {
  "& .MuiInputLabel-root": {
    color: "#8898aa",
    fontSize: "10px",
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  "& .MuiInputBase-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-input": {
    // styles for the input element
    paddingTop: "0px", // Adjust the top padding for a smaller input
  },
  "& .MuiAutocomplete-inputRoot": {
    // styles for the Autocomplete input container
    paddingLeft: 0,
  },
};

export default function SearchBar() {
  const setSearchText = useQueryStore((state) => state.setSearchText);
  const searchText = useQueryStore((state) => state.searchText);
  const storeSearch = useEvents((state) => state.storeSearch);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userSearchQuery = searchText;
    const user_id = auth.currentUser?.uid || " ";

    //adding user searches in database
    await storeSearch({
      user_id: user_id,
      user_search: userSearchQuery,
      searched_at: Timestamp.fromDate(new Date()),
    });

    navigate("/browse_events");
  };

  return (
    <Stack
      spacing={1} // Adjust the spacing to control the height of the search bar
      sx={{
        width: "100%",
        marginBottom: "5px", // Adjust the marginBottom for spacing
      }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          label="Search For Events, Venues, and Organizers"
          variant="outlined"
          className="myTextField"
          sx={{
            ...textfield_sx,
            borderRadius: "8px", // Adjust the border radius
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#f1f3f7", // Adjust the border color
            },
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={(e) => handleSubmit(e)}
                className="cursor-pointer"
              >
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Stack>
  );
}
