/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import { getAttendees, getCartItems } from "../services/events";
import "firebase/firestore";
import Button from "react-bootstrap/Button";
import Loader from "../components/loader/loader";
import MyAlert from "../components/alert/alert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { displaySnackbar } from "../services/events";
import { getEventAverageReviews } from "../services/reviews";
import ReviewTile from "../components/reviews/review_tile";
import { Timestamp } from "firebase/firestore";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "../services/helpers";
import AddToCalendarModal from "../components/add_to_calendar/add_to_calendar";
import { useEvents } from "../store/event_store";
import { userStore } from "./../store/user_store";
import ShowMoreText from "react-show-more-text";
import UnSignedUserModal from "../components/eventDetail/UnSignedUserModal";
import { useSettings } from "../store/setting_store";
import "./styles.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useReview } from "../store/review_store";
import EventTiles from "../components/event_tile/event_tiles";
import useQueryStore from "../store/query_store";
import TicketDetailsModal from "./ticketDetailsModel";
import UgandaFlag from "./../components/footer/ug_flag.png";
import { tableStore } from "../store/table_store";

export default function EventDetails() {
  const sliderRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();

  const getOrganizerDetails = userStore((state) => state.getOrganizerDetails);
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const addToCart = userStore((state) => state.addToCart);
  const getFavoriteEvents = useEvents((state) => state.getFavoriteEvents);
  const favoriteEvents = useEvents((state) => state.favoriteEvents);
  const addToFavorites = useEvents((state) => state.addToFavorites);
  const removeFromFavorites = useEvents((state) => state.removeFromFavorites);
  const getFollowing = userStore((state) => state.getFollowing);

  const getEvent = useEvents((state) => state.getEvent);
  const events = useEvents((state) => state.events);
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  const followOrganizer = userStore((state) => state.followOrganizers);
  const layout_page = useSettings((state) => state.layout_page);
  const updateEvent = useEvents((state) => state.updateEvent);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);
  const getEventReviews = useReview((state) => state.getEventReviews);
  const setTag = useQueryStore((state) => state.setTag);
  const setSelectedTag = useQueryStore((state) => state.setSelectedTag);
  const unfollowOrganizer = userStore((state) => state.unfollowOrganizer);

  // INITIALIZING EVENT DETAILS
  const [event_details, setEventDetails] = useState([{}]);
  const [country, setCountry] = useState("");
  const [languages, setLanguages] = useState("");
  const [category, setCategory] = useState("");
  const [eventDates, setEventDates] = useState([]);
  const [artistsList, setArtistsList] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tickets, setTickets] = useState([{}]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [otherOrganizerEvents, setOtherOrganizerEvents] = useState([]);
  const [similarEvents, setSimilarEvents] = useState([]);
  const [event_id, setEventId] = useState("");
  const [external_link, setExternalLink] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [venue, setVenue] = useState({});
  const [show, setShow] = useState(false);
  const [eventDate, setEventDate] = useState({});
  const [timeLeftMap, setTimeLeftMap] = useState({});
  const [organizer, setOrganizer] = useState({});
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [averageReviews, setAverageReviews] = useState(1);
  const [ticketQuantities, setTicketQuantities] = useState([]);
  const [tableQuantities, setTableQuantities] = useState([]);

  const [showNotAttendeeAlert, setShowNotAttendeeAlert] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //getting followers
  const [followers, setFollowers] = useState([]);
  const formatter = new Intl.NumberFormat("en-US");

  //getting room where tables are

  const venue_room = tableStore((state) =>
    state.venueTableTypes?.find(
      (venueTableType) => venueTableType?.venue?.value === venue.value
    )
  );

  useEffect(() => {
    // GETTING THE FAVORITE EVENTS

    if (user) {
      getFavoriteEvents(user?.user_id);
    }

    const intervalId = setInterval(() => {
      setTimeLeftMap(getTimeLeftMap(tickets));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // SCROLLING TO THE TICKETS ELEMENT
  useEffect(() => {
    if (window.location.hash === "#tickets") {
      const element = document.getElementById("tickets");
      if (element) {
        element.scrollIntoView();
        setModalShow(true);
        setIsVisible(true);
      } else {
        console.log("ELEMENT WITH ID TICKETS NOT FOUND");
      }
    }
  }, []);

  // Function to handle the click on the "Add your review" button
  const handleAddReviewClick = () => {
    // Check if the user is logged in
    if (user) {
      window.location.href = "/write_a_review/" + event_id;
    } else {
      window.location.href = "/sign_in";
    }
  };

  // GET TIME LEFT
  function getTimeLeftMap(tickets) {
    const timeLeftMap = {};

    tickets.forEach((ticket) => {
      const totalSeconds = Math.floor(
        (convertTimestampToDateTime(ticket?.sales_end_date) -
          new Date(Date.now())) /
          1000
      );

      const weeks = Math.floor(totalSeconds / (7 * 24 * 60 * 60));
      const days = Math.floor(
        (totalSeconds % (7 * 24 * 60 * 60)) / (24 * 60 * 60)
      );
      const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      timeLeftMap[ticket?.id] = { weeks, days, hours, minutes, seconds };
    });

    return timeLeftMap;
  }

  //GETTING THE FOLLOWINGS OF THE EVENT
  useEffect(() => {
    const fetchFollowings = async () => {
      const followings = await getFollowing(user?.user_id);
      setFollowers(followings);
    };
    setEventId(id);
    if (!id) {
      navigate("/browse_events");
    }
    fetchFollowings();
  }, []);

  // METHOD TO HANDLE FAVORITES
  async function handleAddToFavorites(e) {
    e.preventDefault();

    console.log("THE EVENT ID: " + event_details?.id);
    console.log("THE USER ID: " + user?.user_id);
    console.log("LENGTH OF FAVORITE EVENTS: " + favoriteEvents?.length);

    if (!user) {
      navigate("/sign_in");
      return;
    }

    if (favoriteEvents.includes(event_details)) {
      // IF THE USER HAS ALREADY ADDED TO FAVORITES
      const removedResponse = await removeFromFavorites({
        event_id: event_details?.id,
        user_id: user?.user_id,
      });

      if (removedResponse) {
        displayWarningToast("Event removed from favorites");
      } else {
        console.log("Error removing event from favorites");
      }
    } else {
      // IF THE USER HAS NOT YET ADDED TO FAVORITES
      const addResponse = await addToFavorites(
        event_details?.id,
        user?.user_id
      );

      if (addResponse) {
        displaySuccessToast("Event added to favorites");
      } else {
        console.log("Error adding event to favorites");
      }
    }
  }

  const incrementQuantity = (e, index, ticketQuantity, ticket) => {
    let numOfTickets = parseInt(ticket?.number_of_tickets.toString());
    let ticketsPerAttendee = parseInt(ticket?.tickets_per_attendee.toString());

    if (numOfTickets === 0) {
      displayErrorToast("NO TICKETS AVAILABLE");
      return;
    }

    if (
      numOfTickets !== 0 &&
      ticketsPerAttendee !== 0 &&
      ticketQuantity < numOfTickets &&
      ticketQuantity < ticket?.tickets_per_attendee
    ) {
      const updatedQuantities = [...ticketQuantities];
      updatedQuantities[index] = ticketQuantity + 1;
      setTicketQuantities(updatedQuantities);
    } else {
      displayErrorToast(
        `You Can't Buy more than ${ticketsPerAttendee} Tickets`
      );
    }
  };

  const decrementQuantity = (e, index, ticketQuantity) => {
    if (ticketQuantity > 0) {
      const updatedQuantities = [...ticketQuantities];
      updatedQuantities[index] = ticketQuantity - 1;
      setTicketQuantities(updatedQuantities);
    }
  };

  // METHOD TO HANDLE ORDER FOR TICKETS
  const handleOrder = (e, index) => {
    const value = parseInt(e.target.value) || 0;
    const updatedQuantities = [...ticketQuantities];
    updatedQuantities[index] = value;
    setTicketQuantities(updatedQuantities);
  };

  // GETTING THE CART ITEMS
  async function get_cart_items() {
    const cartResponse = await getCartItems(user?.user_id);
    setCartItems(cartResponse);
  }

  //TABLE FUNCTIONS////////////////////////////////////////////////////////////////
  //TABLE INCREASE QUANTITY
  const incrementTableQuantity = (index, tableQuantity) => {
    const updatedQuantities = [...tableQuantities];
    updatedQuantities[index] = tableQuantity + 1;
    setTableQuantities(updatedQuantities);
  };

  //TABLE DECREASE QUANTITY
  const decrementTableQuantity = (index, tableQuantity) => {
    if (tableQuantity > 0) {
      const updatedQuantities = [...tableQuantities];
      updatedQuantities[index] = tableQuantity - 1;
      setTableQuantities(updatedQuantities);
    }
  };

  // METHOD TO HANDLE ORDER FOR TABLES
  const handleTableOrder = (e, index) => {
    const value = parseInt(e.target.value) || 0;
    const updatedQuantities = [...tableQuantities];
    updatedQuantities[index] = value;
    setTableQuantities(updatedQuantities);
  };

  // a call back function used to get selected tables from the child
  const getSelectedTables = (data) => {
    console.log("the selected tables from ticket details Model are:", data);
    setSelectedTables(data);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // FETCH THE CART ITEMS
    if (user && isAttendee) {
      get_cart_items();
    }
  }, []);

  // METHOD TO GET THE ATTENDEES OF THE EVENT
  async function handleGetAttendees(event_id) {
    await getAttendees(event_id);
  }

  // METHOD TO HANDLE SHOW CALENDAR MODAL
  function handleShowCalendarModal(e) {
    e.preventDefault();
    setShowCalendarModal(true);
  }

  // FETCHING DATA
  useEffect(() => {
    setIsLoading(true);

    // GETTING THE ATTENDEES OF THE EVENT
    handleGetAttendees(id);
    async function getEventDetails() {
      // GETTING THE EVENT DETAILS
      var response = await getEvent(id);

      if (!response) {
        setIsLoading(false);
        // displayErrorToast("EVENT NOT FOUND");
        return;
      }

      // GETTING THE EVENT REVIEWS
      handleGetEventReviews(response?.id);
      // GETTING THE AVERAGE REVIEWS OF THE EVENT
      handleGetAverageReviews(response?.id);
      setEventDetails(response);
      setCountry(response?.country?.label);
      // setYear(response?.create);
      setLanguages(response?.languages);
      setArtistsList(response?.artists);
      // setSubtitlesList(response?.subtitles);
      setAudienceList(response?.audiences);
      setTags(response?.tags || []);
      setGallery(response?.gallery);
      setEmail(response?.email);
      setPhone(response?.phone_number);
      setTwitter(response?.twitter);
      setLinkedin(response?.linkedin_link);
      setTwitter(response?.twitter_link);
      setInstagram(response?.instagram_link);
      setFacebook(response?.facebook_link);
      setExternalLink(response?.external_link);
      setEventDates(response?.event_dates);
      setEventDate(response?.event_dates[0]);
      setVenue(response?.event_dates[0]?.venue || response?.venue);
      setTickets(response?.event_dates?.[0]?.tickets);

      // GETTING THE ORGANIZER DETAILS
      const organzierResponse = await getOrganizerDetails(
        response?.organizer_id
      );

      setOrganizer(organzierResponse);
      // GETTING OTHER EVENTS FOR THIS ORGANIZER
      const organizerEvents = await getOrganizerEvents(response?.organizer_id);
      const other_organizerEvents = organizerEvents.filter(
        (event) => event?.id !== response?.id
      );
      setOtherOrganizerEvents(other_organizerEvents);

      //getting similar events
      const similar = upcomingEvents.filter(
        (event) =>
          event?.category?.label === response?.category?.label &&
          event?.id !== response?.id
      );
      setSimilarEvents(similar);

      setIsLoading(false);
    }
    getEventDetails();
  }, [events.length]);

  // METHOD TO HANDLE FOLLOWING THE ORGANIZER
  async function handlefollowOrganizer(e) {
    e.preventDefault();
    if (!user) {
      alert("You must be logged in to add events to your favorites!");
      return;
    }
    //if the organizer is included in the following
    if (followers.includes(organizer)) {
      const unfollow = await unfollowOrganizer({
        organizer_id: organizer?.id,
        user_id: user?.user_id,
      });

      if (unfollow) {
        displayWarningToast("You have unfollowed this organizer");
      } else {
        displayErrorToast("Eror in unfollowing the organizer");
      }
    } else {
      const followOrgan = await followOrganizer(organizer?.id, user?.user_id);
      if (followOrgan) {
        displaySuccessToast("You are now following this organizer");
      } else {
        console.log("Error in following this organizer");
      }
    }
  }

  // METHOD TO HANDLE GETTING EVENT REVIEWS
  async function handleGetEventReviews(eventId) {
    const response = await getEventReviews(eventId);
    setReviews(response);
  }

  // METHOD TO HANDLE GETTING EVENT REVIEWS
  async function handleGetAverageReviews(eventId) {
    const response = await getEventAverageReviews(eventId);
    setAverageReviews(response);
  }

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!category) {
      errors.category = "Please select category";
    }
    if (order.size === 0) {
      console.log("NO ORDER SELECTED");
      // setHasErrors(true);
      errors.no_tickets = "Please Select the tickets quantity you want to buy";
    }
  }

  // METHOD TO COUNT RATINGS
  function countRatings(reviewsList, targetRating) {
    const count = reviewsList?.filter(
      (review) => review?.rating === targetRating
    )?.length;
    var percentage =
      reviewsList?.length === 0 ? 0 : (count / reviewsList?.length) * 100;
    return percentage;
  }

  // HANDLE ADD TO CART
  async function handleAddToCart(e) {
    e.preventDefault();

    // CHECKING IF THE USER IS LOGGED IN
    if (!user) {
      navigate("/sign_in");
    }

    // CHECKING IF THE USER IS AN ATTENDEE
    if (user && !isAttendee) {
      setShowNotAttendeeAlert(true);
    } else {
      const errors = validateForm();
      setIsLoading(true);

      let cartItems = [];
      console.log("NUMBER OF ORDERS IN KEY: " + Object.keys(order)?.length);

      if (!order) {
        console.log("NO ORDER SELECTED");
      } else {
        console.log("THE ORDER IS: " + order);
        console.log("ticketQuantities:", ticketQuantities);

        //for tickets
        for (var i = 0; i < ticketQuantities.length; i++) {
          console.log("Loop for tickets at index", i);

          if (ticketQuantities[i]) {
            cartItems.push({
              event_id: event_details?.id,
              event_image: event_details?.main_event_image,
              event_name: event_details?.name,
              event_start_date: Timestamp.fromDate(
                convertTimestampToDateTime(eventDate?.start_date)
              ),
              organizer: organizer,
              price: tickets[i]?.promotional_price
                ? tickets[i]?.promotional_price
                : tickets[i]?.ticket_price,
              ticket_name: tickets[i]?.ticket_name,
              ticket_id: tickets[i]?.id,
              venue: venue,
              quantity: ticketQuantities[i],
              is_table_ticket: false,
            });
          }
        }

        if (cartItems?.length !== 0) {
          console.log("THE LENGTH OF CART ITEMS:" + cartItems?.length);
          console.log("Cart Items:", cartItems);

          const orderResponse = await addToCart({
            cart_items: cartItems,
            user_id: user?.user_id,
          });

          if (orderResponse) {
            console.log("ORDER SUCCESFULLY ADDED TO CART");
            displaySuccessToast("Added To Cart Succesfully!");
            setShow(false);
          } else {
            console.log("SOMETHING WENT WRONG ADDING ORDER TO CART");
            displayErrorToast("Something Went Wrong Adding To Cart");
          }
        } else {
          console.log("PLEASE SELECT THE TICKET NUMBER");
        }
      }

      setIsLoading(false);
    }
  }

  useEffect(() => {
    const updateViews = async () => {
      //getting the views
      const response = await getEvent(id);
      // Make sure response.views is a valid number before adding 1
      const viewsToAdd = parseInt(response?.views) || 0;
      updateEvent(id, {
        views: parseInt(viewsToAdd) + 1,
      });
    };
    updateViews();
  }, [events?.length]);

  //handling show video URl
  const getEmbeddedYouTubeUrl = (youtubeUrl) => {
    const videoId = youtubeUrl.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  // Calculate the number of slides to show based on the length of otherOrganizerEvents
  const slidesToShow = Math.min(4, otherOrganizerEvents.length);

  // SLIDER SETTINGS for other events
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, // Use calculated value
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(3, otherOrganizerEvents.length), // Adjust for smaller screens
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(1, otherOrganizerEvents.length), // Adjust for even smaller screens
        },
      },
    ],
  };

  const slidesToShows = Math.min(3, similarEvents.length);

  //similar events slider settings
  const similarSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShows,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, similarEvents.length), // Adjust for smaller screens
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(1, similarEvents.length), // Adjust for smaller screens
        },
      },
    ],
    beforeChange: (currentSlide, nextSlide) => {
      if (sliderRef.current) {
        sliderRef.current.slickPause(); // Pause autoplay when user interacts
      }
    },
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  // display loader

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />

      {/* ADDING TO CART SUCCESS ALERT */}
      {showAlert && (
        <MyAlert
          onClose={(e) => setShowAlert(false)}
          message={"Added To Cart Succesfully!"}
          type="success"
        />
      )}

      {/* EVENT DETAILS BREADCRUMP */}
      <section className="section-pagetop bg-gray">
        <div className="container clearfix">
          <h4 className="title-page dark b float-xl-left mb-0">
            {event_details?.name}
          </h4>

          <nav className="float-xl-right mt-2 mt-xl-0">
            <ol className="breadcrumb text-white">
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  className="dark"
                  style={{ color: layout_page.primary_color }}
                >
                  <i className="fas fa-home" />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/browse_events"
                  className="dark"
                  style={{ color: layout_page.primary_color }}
                >
                  Events
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {event_details?.name}
              </li>
            </ol>
          </nav>
        </div>
      </section>

      {/* EVENT IMAGE */}
      <div className="row no-gutters">
        <div className="col-12 p-0">
          <section className="section-main">
            <div
              className="owl-init slider-main owl-carousel owl-loaded owl-drag"
              data-items={1}
              data-dots="false"
              data-nav="false"
              data-autoplay="false"
            >
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(0px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: "100%",
                  }}
                >
                  {/* EVENT MAIN IMAGE */}
                  <div className="owl-item active" style={{ width: "100%" }}>
                    <div className="item-slide d-flex">
                      <img
                        className="slider-img justify-content-center align-self-center img-lazy-load b-loaded"
                        src={event_details?.main_event_image}
                        alt={event_details?.name}
                      />
                      <div
                        className="slider-blured-background"
                        style={{
                          backgroundImage: `url("${event_details?.main_event_image}")`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* ARROWS */}
              <div className="owl-nav disabled">
                <button type="button" role="presentation" className="owl-prev">
                  <i className="fa fa-chevron-left" />
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <i className="fa fa-chevron-right" />
                </button>
              </div>
              <div className="owl-dots disabled" />
            </div>
          </section>
        </div>
      </div>

      {/* EVENT DETAILS */}
      <section
        className="section-content padding-y bg-white "
        id="event_details"
      >
        <div className="container">
          <div className="card">
            <div className="row">
              <div className="col-12 col-lg-8 border-right order-1 order-lg-0">
                <div className="card-body">
                  <h1 className="card-title b d-none d-lg-block">
                    {event_details?.name}
                  </h1>

                  {/* EVENT RATING */}
                  <div className="rating-wrap d-none d-lg-block">
                    <ul className="rating-stars mr-2">
                      <li style={{ width: "100%" }} className="stars-active">
                        {Array.from({ length: averageReviews }, (_, index) => (
                          <i key={index} className="fa fa-star" />
                        ))}
                      </li>
                      <li>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                    <div className="label-rating">
                      {reviews?.length} review(s)
                    </div>
                  </div>

                  {/* EVENT DESCRIPTION */}
                  <dl className="mt-5 event-description">
                    <dt className="text-muted w-100">Description</dt>

                    <ShowMoreText
                      lines={8}
                      more="Show more"
                      less="Show less"
                      anchorClass="show-more-less-clickable btn-link"
                      expanded={true}
                      width={280}
                    >
                      {/* THE EVENT DESCRIPTION */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event_details?.description,
                        }}
                      ></div>
                    </ShowMoreText>
                  </dl>
                  <hr className=" mt-4" />
                  {/* OTHER EVENT DETAILS SECTION */}
                  <ul className="list-group list-group-flush mt-5 mb-5 p-0">
                    <li className="list-group-item pl-0 pr-0">
                      {/* EVENT CATEGORY */}
                      {event_details?.category?.label && (
                        <dl className="dlist-align">
                          <dt className="text-muted">Category</dt>
                          <dd className="text-right">
                            {event_details?.category?.label}{" "}
                          </dd>
                        </dl>
                      )}

                      {/* COUNTRY */}
                      {event_details?.country && (
                        <dl className="dlist-align">
                          <dt className="text-muted">Country</dt>
                          <dd className="text-right">
                            <img
                              src={UgandaFlag}
                              alt=""
                              style={{ width: 30 }}
                              className="mr-2"
                            />
                            {event_details?.country?.label}
                          </dd>
                        </dl>
                      )}

                      {/* LANGUAGES */}
                      {languages?.length > 0 && (
                        <dl className="dlist-align">
                          <dt className="text-muted">Languages</dt>
                          <dd className="text-right">
                            {languages.map((language, index) => (
                              <span key={index} className="ml-2">
                                {language?.label}
                              </span>
                            ))}
                          </dd>
                        </dl>
                      )}

                      {/* ARTISTS */}
                      {artistsList?.length > 0 && (
                        <dl className="dlist-align">
                          <dt className="text-muted">Artists</dt>
                          <dd className="text-right">
                            {artistsList.toString()}
                          </dd>
                        </dl>
                      )}

                      {audienceList?.length > 0 && (
                        <dl className="dlist-align">
                          <dt className="text-muted">Audience</dt>
                          <dd className="text-right">
                            <ul className="list-inline">
                              {audienceList?.map((e, index) => (
                                <span key={index} className="ml-2">
                                  <li className="list-inline-item ml-1">
                                    <i className={"fas fa-user"}></i> {e}
                                  </li>
                                </span>
                              ))}
                            </ul>
                          </dd>
                        </dl>
                      )}
                    </li>
                  </ul>

                  <div className="row">
                    {/* EVENT IMAGES */}
                    {gallery?.length > 0 && (
                      <>
                        <hr className="" />
                        <dl className="mt-5 col">
                          <dt className="text-muted">Photos</dt>
                          <dd className="mr-0">
                            <div className="gallery photos-gallery clearfix">
                              {gallery?.map((image, index) => (
                                <figure>
                                  <div key={index} className="img-container">
                                    <img
                                      className="img"
                                      src={image}
                                      alt={event_details?.name}
                                    />
                                  </div>
                                </figure>
                              ))}
                            </div>
                          </dd>
                        </dl>
                      </>
                    )}
                  </div>

                  {/* EVENT VIDEO*/}
                  {event_details?.video_link && (
                    <>
                      <hr className="" />
                      <dl className="mt-5">
                        <dt className="text-muted">Video</dt>
                        <dd className="mr-0">
                          <iframe
                            title=" "
                            className="w-100 border-0"
                            height={400}
                            src={getEmbeddedYouTubeUrl(
                              event_details?.video_link
                            )}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </dd>
                      </dl>
                    </>
                  )}

                  {/* CONTACT */}
                  <dl className="mt-5">
                    <dt className="text-muted">Contact &amp; Social media</dt>
                    <dd className="mr-0">
                      <ul className="list-icon row no-gutters">
                        {/* EXTERNAL LINK */}
                        {external_link && (
                          <li className="col-md-6">
                            <a
                              href={external_link}
                              className="pl-4"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fas fa-globe fa-fw" />
                              <span>{external_link}</span>
                            </a>
                          </li>
                        )}

                        {/* EMAIL */}
                        {email && (
                          <li className="col-md-6">
                            <a
                              href={`mailto:${email}`}
                              className="pl-4"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fas fa-at fa-fw" />
                              <span>{email}</span>
                            </a>
                          </li>
                        )}

                        {/* PHONE NUMBER */}
                        {phone && (
                          <li className="col-md-6">
                            <a
                              href={"tel:" + phone}
                              className="pl-4"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fas fa-phone fa-fw" />
                              <span>{phone}</span>
                            </a>
                          </li>
                        )}

                        {/* FACEBOOK */}
                        {facebook && (
                          <li className="col-md-6">
                            <a
                              href={facebook}
                              className="pl-4"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fab fa-facebook-f fa-fw" />
                              <span>{facebook}</span>
                            </a>
                          </li>
                        )}

                        {/* TWITTER */}
                        {twitter && (
                          <li className="col-md-6">
                            <a
                              href={twitter}
                              className="pl-4"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fab fa-twitter fa-fw" />
                              <span>{twitter}</span>
                            </a>
                          </li>
                        )}

                        {/* INSTAGRAM */}
                        {instagram && (
                          <li className="col-md-6">
                            <a
                              href={instagram}
                              className="pl-4"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fab fa-instagram fa-fw" />
                              <span>{instagram}</span>
                            </a>
                          </li>
                        )}

                        {/* LINKEDIN */}
                        {linkedin && (
                          <li className="col-md-6">
                            <a
                              href={linkedin}
                              className="pl-4"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: layout_page.primary_color }}
                            >
                              <i className="icon fab fa-linkedin fa-fw mt-2" />
                              <span>{linkedin}</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </dd>
                  </dl>

                  {/* SHARE */}
                  <dl className="mt-5">
                    <dt className="text-muted">Share</dt>
                    <dd className="mr-0">
                      <div className="jssocials-share">
                        <iframe
                          id="twitter-widget-0"
                          allowtransparency="true"
                          allowFullScreen={true}
                          className="twitter-share-button twitter-share-button-rendered twitter-tweet-button"
                          style={{
                            position: "static",
                            visibility: "visible",
                            width: 73,
                            height: 50,
                          }}
                          title="Twitter Tweet Button"
                          src={`https://platform.twitter.com/widgets/tweet_button.2b2d73daf636805223fb11d48f3e94f7.en.html#dnt=false&id=twitter-widget-0&lang=en&original_referer=${
                            window.location.hostname +
                            "/event_details/" +
                            event_details?.id
                          }&size=m&text=${event_details?.name}&time=${
                            eventDate?.start_date
                          }&type=share&url=${
                            window.location.hostname +
                            "/event_details/" +
                            event_id
                          }`}
                        />
                      </div>
                    </dd>
                  </dl>
                  <hr className="mt-5" />
                  <div className="row">
                    <div className="col">
                      {/* TAGS */}
                      {Array.isArray(tags) &&
                        tags?.map((tag, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setSelectedTag({
                                value: tag,
                                label: tag,
                              });
                              setTag(tag);
                              navigate("/browse_events");
                            }}
                            className="btn btn-sm btn-default mr-3 mb-3 mt-3"
                            style={{ color: layout_page.primary_color }}
                          >
                            {tag}
                          </div>
                        ))}
                    </div>
                  </div>
                  {tags.length > 0 && <hr className="mb-5" />}

                  {/* REVIEWS */}
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-5">
                      <h3 className="mb-1">{reviews?.length} review(s)</h3>
                      <div className="rating-wrap">
                        <ul className="rating-stars mr-2">
                          <li
                            style={{ width: "100%" }}
                            className="stars-active"
                          >
                            {Array.from(
                              { length: averageReviews },
                              (_, index) => (
                                <i key={index} className="fa fa-star" />
                              )
                            )}
                          </li>
                          <li>
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                          </li>
                        </ul>
                        <div className="label-rating">
                          {averageReviews} out of 5 stars
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                      <Link
                        onClick={handleAddReviewClick}
                        className="btn btn-outline-primary"
                      >
                        <i className="far fa-star" /> Add your review
                      </Link>
                    </div>
                  </div>

                  <div className="row mb-5 event-scorecard">
                    <div className="col-12">
                      {/* 5 STARTS */}
                      <div className="side">
                        <div>5 stars</div>
                      </div>
                      <div className="middle">
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{ width: `${countRatings(reviews, "5")}%` }}
                          />
                        </div>
                      </div>

                      <div className="side right">
                        <div className="text-muted">
                          {countRatings(reviews, "5")}%
                        </div>
                      </div>

                      {/* 4 STARS */}
                      <div className="side">
                        <div>4 stars</div>
                      </div>
                      <div className="middle">
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{ width: `${countRatings(reviews, "4")}%` }}
                          />
                        </div>
                      </div>
                      <div className="side right">
                        <div className="text-muted">
                          {countRatings(reviews, "4")}%
                        </div>
                      </div>

                      {/* 3 STARS */}
                      <div className="side">
                        <div>3 stars</div>
                      </div>
                      <div className="middle">
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{ width: `${countRatings(reviews, "3")}%` }}
                          />
                        </div>
                      </div>
                      <div className="side right">
                        <div className="text-muted">
                          {countRatings(reviews, "3")}%
                        </div>
                      </div>

                      {/* 2 STARS */}
                      <div className="side">
                        <div>2 stars</div>
                      </div>
                      <div className="middle">
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{ width: `${countRatings(reviews, "2")}%` }}
                          />
                        </div>
                      </div>
                      <div className="side right">
                        <div className="text-muted">
                          {countRatings(reviews, "2")}%
                        </div>
                      </div>

                      {/* 1 STAR */}
                      <div className="side">
                        <div>1 star</div>
                      </div>
                      <div className="middle">
                        <div className="bar-container">
                          <div
                            className="bar"
                            style={{ width: `${countRatings(reviews, "1")}%` }}
                          />
                        </div>
                      </div>
                      <div className="side right">
                        <div className="text-muted">
                          {countRatings(reviews, "1")}%
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* REVIEWS */}
                  <div id="reviews" className="row">
                    {reviews?.map((review, index) => (
                      <ReviewTile key={index} review={review} />
                    ))}
                  </div>
                </div>
              </div>

              {/* RIGHT COLUMN */}
              <div className="col-12 col-lg-4 order-0 order-lg-1 ">
                <div className="card-body">
                  {/* NAME OF THE EVENT */}
                  <h1 className="card-title b d-lg-none text-center">
                    {event_details?.name}
                  </h1>
                  <div className="rating-wrap mb-5 d-lg-none text-center">
                    <ul className="rating-stars">
                      <li style={{ width: "100%" }} className="stars-active">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                    <div className="label-rating">
                      {reviews?.length} reviews
                    </div>
                  </div>

                  <form
                    id="add-to-cart-form"
                    action="/en/dashboard/attendee/cart/add"
                    method="post"
                  >
                    <div
                      id="eventDate-6404713645-wrapper"
                      className="event-eventDate-wrapper"
                    >
                      {/* dates */}
                      <dl>
                        <dt className="text-muted">
                          <span>Dates</span>
                        </dt>
                        <dd>
                          {eventDates?.map((event_date, index) => (
                            <div key={index} className="text-center">
                              <div className="display-inline-block">
                                {/* EVENT START DATE */}
                                <div className="display-inline-block">
                                  <span className="font-size-3rem">
                                    {" "}
                                    {convertTimestampToDateTime(
                                      event_date?.start_date
                                    ).getDate()}{" "}
                                  </span>
                                  <div className="display-inline-block mr-3">
                                    <div>
                                      <span className="font-size-1rem">
                                        {convertTimestampToDateTime(
                                          event_date?.start_date
                                        ).toLocaleString("en-US", {
                                          month: "short",
                                        })}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        {convertTimestampToDateTime(
                                          event_date?.start_date
                                        ).getFullYear()}
                                      </span>
                                    </div>
                                  </div>{" "}
                                  <div className="mb-2">
                                    <span className="text-muted b">
                                      {convertTimestampToDateTime(
                                        event_date?.start_date
                                      ).toLocaleTimeString("en-US")}
                                    </span>
                                  </div>
                                </div>

                                {/* EVENT END DATE */}
                                <div className="display-inline-block">
                                  <span className="font-size-3rem">
                                    -{" "}
                                    {convertTimestampToDateTime(
                                      event_date?.end_date
                                    ).getDate()}{" "}
                                  </span>
                                  <div className="display-inline-block mr-3">
                                    <div>
                                      <span className="font-size-1rem">
                                        {convertTimestampToDateTime(
                                          event_date?.end_date
                                        ).toLocaleString("en-US", {
                                          month: "short",
                                        })}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        {convertTimestampToDateTime(
                                          event_date?.end_date
                                        ).getFullYear()}
                                      </span>
                                    </div>
                                  </div>{" "}
                                  <div className="mb-2">
                                    <span className="text-muted b">
                                      {convertTimestampToDateTime(
                                        event_date?.end_date
                                      ).toLocaleTimeString("en-US")}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <br />

                              <Link
                                id="add-to-calendar-link"
                                onClick={(e) => handleShowCalendarModal(e)}
                                style={{ color: layout_page.primary_color }}
                              >
                                <i className="fas fa-calendar-plus" /> Add to
                                calendar
                              </Link>

                              {/* DISPLAY THE CALENDAR MODAL */}
                              <AddToCalendarModal
                                eventDates={eventDates}
                                eventDetails={event_details}
                                eventId={event_id}
                                onHide={() => setShowCalendarModal(false)}
                                show={showCalendarModal}
                              />

                              <div className="clearfix" />
                            </div>
                          ))}
                        </dd>
                      </dl>
                      <hr />

                      {/* TICKETS */}
                      <dl className="mt-5" id="tickets">
                        <dt className="text-muted">Tickets</dt>
                        <dd className="mr-0">
                          <div className="">
                            {/* TICKETS */}
                            {tickets?.map((ticket, index) => (
                              <div
                                key={ticket?.id}
                                className="bg-gray d-flex justify-content-between px-2 border-bottom"
                              >
                                {/* TICKET NAME */}
                                <span className="py-2">
                                  {ticket?.ticket_name} ticket
                                </span>

                                {/* TICKET PRICE */}
                                {ticket.promotional_price ? (
                                  <span className=" text-right py-2">
                                    UGX{" "}
                                    {formatter.format(
                                      ticket?.promotional_price
                                    )}
                                    <del className="price-old pl-1 py-2">
                                      UGX{" "}
                                      {formatter.format(ticket?.ticket_price)}
                                    </del>
                                  </span>
                                ) : (
                                  <td className="text-right py-2">
                                    UGX {formatter.format(ticket?.ticket_price)}
                                  </td>
                                )}
                              </div>
                            ))}
                          </div>

                          <>
                            {/* TICKETS MODAL WHEN A USER IS LOGGED IN */}
                            <Button
                              className="btn text-white btn-block my-2"
                              variant="primary"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              onClick={() => {
                                setModalShow(true);
                                setIsVisible(true);
                              }}
                              disabled={
                                convertTimestampToDateTime(
                                  eventDate?.end_date
                                ) < new Date() ||
                                ((!tickets[0]?.sales_start_date ||
                                  convertTimestampToDateTime(
                                    tickets[0]?.sales_start_date
                                  ) > Date.now()) &&
                                  (!tickets[0]?.sales_end_date ||
                                    convertTimestampToDateTime(
                                      tickets[0]?.sales_end_date
                                    ) < Date.now()))
                              }
                            >
                              {/* <i className="fas fa-ticket-alt" /> Tickets */}
                              <i className="fas fa-ticket-alt" /> Buy Tickets
                            </Button>

                            {/* BUYING TABLES */}
                            {venue_room && (
                              <Link
                                to={`/venue_details/${venue?.value}/#venue_tables`}
                              >
                                <Button
                                  variant="outline-primary"
                                  className="btn-block"
                                >
                                  Book Reservation
                                </Button>
                              </Link>
                            )}

                            <>
                              {user ? (
                                <TicketDetailsModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                  eventDate={eventDate?.start_date}
                                  event_name={event_details?.name}
                                  venue={eventDates[0]?.venue || venue}
                                  address={eventDates[0]?.address}
                                  tickets={tickets}
                                  cartItems={cartItems}
                                  event_id={event_id}
                                  ticketQuantities={ticketQuantities}
                                  tableQuantities={tableQuantities}
                                  formatter={formatter}
                                  timeLeftMap={timeLeftMap}
                                  errors={errors}
                                  handleOrder={handleOrder}
                                  handleTableOrder={handleTableOrder}
                                  incrementQuantity={incrementQuantity}
                                  decrementQuantity={decrementQuantity}
                                  incrementTableQuantity={
                                    incrementTableQuantity
                                  }
                                  decrementTableQuantity={
                                    decrementTableQuantity
                                  }
                                  handleAddToCart={handleAddToCart}
                                  venue_id={event_details?.venue?.value}
                                  getSelectedTables={getSelectedTables}
                                />
                              ) : (
                                <UnSignedUserModal
                                  isVisible={isVisible}
                                  onClose={() => setIsVisible(false)}
                                  tickets={tickets}
                                  event={event_details}
                                  venue_id={event_details?.venue?.value}
                                  event_id={event_id}
                                  tables={venue_room?.tableTypes}
                                />
                              )}
                            </>
                          </>
                        </dd>
                      </dl>

                      {/* NOT ATTENDEE SNACKBAR */}
                      {displaySnackbar({
                        message: `SORRY, Only Attendees are allowed to buy tickets, You Are an Organizer`,
                        color: "warning",
                        open: showNotAttendeeAlert,
                        onClose: setShowNotAttendeeAlert,
                      })}

                      {/* ADD TO FAVORITES */}
                      {isAttendee && (
                        <>
                          <button
                            className="event-favorites-remove btn btn-outline-primary btn-block"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            onClick={(e) => handleAddToFavorites(e)}
                          >
                            <i className="fas fa-heart mr-1" />{" "}
                            {favoriteEvents &&
                            favoriteEvents.includes(event_details)
                              ? "REMOVE FROM FAVORITES"
                              : "ADD TO FAVORITES"}
                          </button>
                        </>
                      )}
                      <hr className="hr-md" />
                      <dl>
                        {/* VENUE */}
                        <dt className="text-muted">
                          <span className="float-left">Venue</span>
                          <a
                            href={"/venue_details/" + venue || venue?.value}
                            className="float-right text-sm"
                            style={{ color: layout_page.primary_color }}
                          >
                            <i className="far fa-building" /> Details
                          </a>
                          <div className="clearfix" />
                        </dt>
                        <dd className="mr-0">
                          <h4 className="text-center">
                            {eventDates[0]?.venue || venue?.label}
                          </h4>
                          <p>{eventDates[0]?.address}</p>
                          <div className="d-flex mb-3">
                            <ul className="list-inline mx-auto">
                              <li className="list-inline-item">
                                {/* GOING TO THE VENUE BY CAR */}
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${eventDates[0]?.longitude},${eventDates[0]?.latitude}&travelmode=driving`}
                                  className="text-black-50"
                                  data-toggle="tooltip"
                                  title=""
                                  target="_blank"
                                  data-original-title="Display itinerary driving"
                                  rel="noreferrer"
                                  style={{ color: layout_page.primary_color }}
                                >
                                  <i className="fas fa-car fa-fw" />
                                </a>
                              </li>

                              {/* GOING TO THE VENUE BY WALKING */}
                              <li className="list-inline-item">
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${eventDates[0]?.longitude},${eventDates[0]?.latitude}&travelmode=walking`}
                                  className="text-black-50"
                                  data-toggle="tooltip"
                                  title=""
                                  target="_blank"
                                  data-original-title="Display itinerary walking"
                                  rel="noreferrer"
                                  style={{ color: layout_page.primary_color }}
                                >
                                  <i className="fas fa-walking fa-fw" />
                                </a>
                              </li>

                              {/* TRAIN */}
                              <li className="list-inline-item">
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${eventDates[0]?.longitude},${eventDates[0]?.latitude}&travelmode=transit`}
                                  className="text-black-50"
                                  data-toggle="tooltip"
                                  title=""
                                  target="_blank"
                                  data-original-title="Display itinerary on public transportation"
                                  rel="noreferrer"
                                  style={{ color: layout_page.primary_color }}
                                >
                                  <i className="fas fa-bus fa-fw" />
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${eventDates[0]?.longitude},${eventDates[0]?.latitude}&travelmode=bicycling`}
                                  className="text-black-50"
                                  data-toggle="tooltip"
                                  title=""
                                  target="_blank"
                                  data-original-title="Display itinerary bicycling"
                                  rel="noreferrer"
                                  style={{ color: layout_page.primary_color }}
                                >
                                  <i className="fas fa-bicycle fa-fw" />
                                </a>
                              </li>
                            </ul>
                          </div>

                          {/* GOOGLE MAP */}
                          <iframe
                            title=" "
                            className="w-100 border-0 venue-map"
                            height={300}
                            src={`https://maps.google.com/maps?q=${
                              eventDates[0]?.venue || venue?.label
                            }&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                          />

                          <hr />
                          <dl className="mt-5 mb-5">
                            {/* ORGANIZER DETAILS */}
                            <dt className="text-muted">
                              <span className="float-left">Organizer</span>
                              <Link
                                to={"/organizer_details/" + organizer?.id}
                                className="float-right text-sm"
                                style={{ color: layout_page.primary_color }}
                              >
                                <i className="far fa-id-card" /> Details
                              </Link>
                              <div className="clearfix" />
                            </dt>

                            {/* ORGANIZER BANNER */}
                            <dd className="mr-0">
                              <div
                                className="card-banner "
                                style={{
                                  height: 250,
                                  backgroundImage: `url("${
                                    organizer?.cover_photo ||
                                    "https://coolarts.ca/wp-content/uploads/2020/09/qi-bin-w4hbafegiac-unsplash.jpg"
                                  }")`,
                                }}
                              >
                                <article className="overlay bottom text-center">
                                  {/* ORGANIZER NAME */}
                                  <h4 className="card-title">
                                    <Link
                                      to={"/organizer_details/" + organizer?.id}
                                      style={{
                                        color: layout_page.primary_color,
                                      }}
                                    >
                                      {organizer?.organizer_name}
                                    </Link>
                                  </h4>

                                  {/* ORGANIZER IMAGE */}
                                  <img
                                    src={organizer?.organizer_logo}
                                    className="d-block mx-auto mb-3"
                                    alt={organizer?.organizer_name}
                                    height={"60px"}
                                    width={"60px"}
                                    style={{
                                      borderRadius: "50%",
                                      border: "2px solid #fff",
                                    }}
                                  />

                                  {/* FOLLOW THE ORGANIZER */}
                                  {isAttendee && (
                                    <button
                                      className="organizer-follow btn text-white btn-sm"
                                      style={{
                                        backgroundColor:
                                          layout_page?.primary_color,
                                      }}
                                      onClick={handlefollowOrganizer}
                                    >
                                      <i
                                        className={`fas ${
                                          followers &&
                                          followers.includes(organizer)
                                            ? "fa-folder-minus"
                                            : "fa-folder-plus"
                                        }`}
                                      />
                                      {followers &&
                                      followers.includes(organizer)
                                        ? " Unfollow"
                                        : " Follow"}
                                    </button>
                                  )}
                                </article>
                              </div>
                            </dd>
                          </dl>
                          <hr className="hr-md" />
                        </dd>
                      </dl>
                    </div>
                  </form>
                  {/* DOWN SECTION STARTS HERE */}
                  <dl className="mt-5 d-none">
                    <dt className="text-muted mb-3">
                      <span className="float-left">
                        Attendees <b>(2)</b>
                      </span>
                      <Link
                        to="/"
                        className="float-right text-sm"
                        data-toggle="modal"
                        data-target="#attendees-modal"
                        style={{ color: layout_page.primary_color }}
                      >
                        <i className="fas fa-users" /> See all
                      </Link>
                      <div className="modal fade" id="attendees-modal">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <div className="modal-title h4">
                                Attendees (2)
                              </div>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                              >
                                x
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="card border-0 card-body overflow-auto text-nowrap">
                                <div className="avatar-list py-4">
                                  <span
                                    className="avatar"
                                    style={{
                                      background:
                                        'url("https://lh3.googleusercontent.com/a/AEdFTp60ykkLd6BrgLK27MyRE1N3JUkAbepsK8RC4tTdbw=s96-c")',
                                    }}
                                    data-toggle="tooltip"
                                    title=""
                                    data-container="body"
                                    data-original-title="oboemusik"
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn text-white"
                                style={{
                                  backgroundColor: layout_page?.primary_color,
                                }}
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </dt>
                    <dd className="mr-0">
                      <div className="card border-0 card-body">
                        <div className="avatar-list avatar-list-stacked">
                          <span
                            className="avatar"
                            style={{
                              background:
                                'url("https://lh3.googleusercontent.com/a/AEdFTp60ykkLd6BrgLK27MyRE1N3JUkAbepsK8RC4tTdbw=s96-c")',
                            }}
                            data-toggle="tooltip"
                            title=""
                            data-container="body"
                            data-original-title="oboemusik"
                          ></span>
                        </div>
                      </div>
                    </dd>
                  </dl>

                  {/* SUBSCRIBE TO NEWSLETTER */}
                  <div className="mt-5 d-none">
                    <article className="card-group-item">
                      <div className="card-body p-0">
                        <article
                          className="box text-white"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <figure className="itemside mb-4">
                            <div className="aside align-self-center">
                              <span className="icon-wrap icon-sm round bg-white">
                                <i
                                  className="fas fa-mail-bulk"
                                  style={{
                                    color: layout_page?.primary_color,
                                  }}
                                />
                              </span>
                            </div>
                            <figcaption className="text-wrap">
                              <h5 className="title text-white">
                                Subscribe to our newsletter
                              </h5>
                            </figcaption>
                          </figure>
                          <div className="form-row">
                            <div className="form-group col mb-0">
                              <input
                                id="newsletter-email"
                                type="email"
                                className="newsletter-email form-control rounded-0 border-0 line-height-1"
                                placeholder="Email address"
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col mb-0">
                              <button
                                id="newsletter-subscribe"
                                data-target="/en/newsletter-subscribe"
                                type="button"
                                className="btn btn-dark justify-content-center w-100 rounded-0"
                              >
                                SUBSCRIBE
                              </button>
                            </div>
                          </div>
                        </article>
                      </div>
                    </article>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* OTHER EVENTS BY THIS ORGANIZER */}
          {otherOrganizerEvents?.length > 0 && (
            <div className="row mb-2">
              <div className="col-12">
                <h3
                  className="mt-5 mb-4 text-center"
                  style={{ color: layout_page.primary_color }}
                >
                  Other events by {organizer?.organizer_name}
                </h3>
              </div>
              <div>
                {/* OTHER EVENTS ORGANIZED BY THE ORGANIZER */}
                <Slider {...settings}>
                  {otherOrganizerEvents?.map((event, index) => (
                    <EventTiles
                      key={index}
                      event={event}
                      num_tiles={otherOrganizerEvents.length === 1 ? "3" : "12"}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          )}

          {/* SIMILLAR EVENTS */}
          <div className="row mb-2">
            {similarEvents.length > 0 && (
              <div className="col-12">
                <h3
                  className="mt-5 mb-4 text-center"
                  style={{ color: layout_page.primary_color }}
                >
                  Similar Events
                </h3>
              </div>
            )}
            {/* Navigation arrows */}
            <div className="position-relative">
              {/* left arrow */}
              {similarEvents.length > 3 && (
                <span
                  onClick={() => sliderRef.current?.slickPrev()}
                  className="rounded-pill cursor-pointer bg-dark-50 position-absolute start-0 top-50 translate-middle-y ml-2"
                  style={{ height: 35, width: 35, zIndex: 1 }}
                >
                  <FaAngleLeft size={25} color="white" className="center-xy" />
                </span>
              )}
              {/* slider */}
              <div className="">
                <Slider {...similarSettings} ref={sliderRef}>
                  {/* Add the settings object here */}
                  {similarEvents?.map((event, index) => (
                    <EventTiles
                      key={index}
                      event={event}
                      num_tiles={similarEvents.length === 1 ? "4" : "12"}
                    />
                  ))}
                </Slider>
              </div>
              {/* right arrow */}
              {similarEvents.length > 3 && (
                <span
                  onClick={() => sliderRef.current?.slickNext()}
                  className="rounded-pill cursor-pointer bg-dark-50 position-absolute end-0 top-50 translate-middle-y"
                  style={{ height: 35, width: 35, zIndex: 1, marginRight: 10 }}
                >
                  <FaAngleRight size={25} color="white" className="center-xy" />
                </span>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
