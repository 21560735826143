import { Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { userStore } from './../../store/user_store';

export default function AvatarComponent({ userId }) {
  const [userData, setUserData] = useState(null);
  const [organizerData, setOrganizerData] = useState(null);

  const getUserDetails = userStore((state)=>state.getUserDetails);
  const getOrganizerDetails = userStore((state)=>state.getOrganizerDetails);

  useEffect(() => {
    async function fetchData() {
        // GET USER DETAILS
      const userDetails = await getUserDetails(userId);

      if(userDetails.user_type === 'organizer'){
        // GET ORGANIZER DETAILS
        const organizerDetails = await getOrganizerDetails(userId);
        setOrganizerData(organizerDetails);
      }
      setUserData(userDetails);
    }

    fetchData();
  }, []);

  return (
    <Tooltip title={userData?.firstname}>
      <span
        className="avatar"
        style={{
          background: `url(${
            userData?.user_type === "organizer" ? organizerData?.organizer_logo :  userData?.profile_picture ??
            "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
          })`,
        }}
      ></span>
    </Tooltip>
  );
}
