import React, { useEffect, useState } from "react";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { useSettings } from "../../store/setting_store";
import { useBlogs } from "../../store/blog_store";
import { Link } from "react-router-dom";
import CustomPagination from "../../components/pagination/Pagination";

export default function OrganizerSupport() {
  const layout_page = useSettings((state) => state.layout_page);
  const organizerArticles = useBlogs((state) => state.organizerArticles);
  const getOrganizerArticles = useBlogs((state) => state.getOrganizerArticles);

  useEffect(() => {
    const fetchData = async () => {
      await getOrganizerArticles();
    };

    fetchData();
  }, [organizerArticles.length]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(25);
  //get current orders
  const indexOfLastarticles = currentPage * articlesPerPage;
  const indexOfFastarticles = indexOfLastarticles - articlesPerPage;
  const current_articles = organizerArticles?.slice(
    indexOfFastarticles,
    indexOfLastarticles
  );

  return (
    <div>
      <Header />
      <BreadCrump title={"Support For Organizer"} />

      {/* MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  <article className="h-100">
                    <figure className="itembox text-center">
                      <span
                        className="mt-2 icon-wrap rounded icon-lg"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-user-alt  white" />
                      </span>
                      <figcaption className="text-wrap">
                        <h5 className="title">Organizer</h5>
                      </figcaption>
                    </figure>
                    <hr />

                    {current_articles.map((article) => (
                      <Link
                        key={article?.id}
                        to={"/organizer_articles/" + article?.id}
                      >
                        <p
                          style={{
                            color: layout_page?.primary_color,
                            cursor: "pointer",
                          }}
                        >
                          {article?.title}
                        </p>
                      </Link>
                    ))}
                  </article>
                </div>
              </div>
              {/* pagination */}
              {organizerArticles?.length > 25 && (
                <CustomPagination
                  setCurrentPage={setCurrentPage}
                  totalPages={Math.ceil(
                    organizerArticles?.length / articlesPerPage
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
