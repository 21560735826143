import { Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCartItems } from "../../../services/events";
import { userStore } from "./../../../store/user_store";
import {
  convertTimestampToDateTime,
  formatDate,
} from "./../../../services/helpers";

export default function CartComponent() {
  const [cartItems, setCartItems] = useState([]);
  const [numberOfTickets, setNumberOfTickets] = useState(0);

  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);

  //   INITIALIZING USENAVIGATE
  const navigate = useNavigate();

  // FETCH THE CART ITEMS
  async function get_cart_items() {
    const cartResponse = await getCartItems(user?.user_id);
    setCartItems(cartResponse);
    // CALCULATING THE NUMBER OF TICKETS
    let numTickets = 0;
    for (let i = 0; i < cartResponse?.length; i++) {
      numTickets += cartResponse[i]?.quantity;
    }
    setNumberOfTickets(numTickets);
  }

  useEffect(() => {
    if (user && isAttendee) {
      get_cart_items();
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //   VIEW EVENT
  function viewEvent(e, event_id) {
    navigate("/event_details/" + event_id);
  }

  return isAttendee ? (
    <div className="">
      <div className="d-flex text-wrap " onClick={handleMenuOpen}>
        <div
          className="icontext badge-notification pr-md-3 d-flex  flex-column align-items-center"
          data-toggle="dropdown"
          data-offset="40,10"
          data-badge={cartItems?.length}
          style={{ cursor: "pointer" }}
        >
          <div className="icon-wrap icon-xs bg-primary round text-white">
            <i className="fas fa-shopping-cart" />
          </div>
        </div>
        <div className="text-wrap cursor-pointer">
          <span className="d-none d-md-block">
            Cart <i className="fas fa-caret-down" />
          </span>
        </div>
      </div>

      <Menu
        id="cart-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="p-3 pr-5"
      >
        {!cartItems || cartItems?.length === 0 ? (
          <MenuItem className="p-3">Your cart is empty</MenuItem>
        ) : (
          <div>
            {cartItems?.map((item, index) => (
              <div onClick={(e) => viewEvent(e, item?.event_id)} key={index}>
                <MenuItem
                  key={index}
                  sx={{
                    paddingRight: "30px",
                    paddingTop: "20px",
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  <figure
                    className="itemside badge-notification badge-notification-right"
                    data-badge={item?.quantity}
                    key={index}
                  >
                    <div className="aside">
                      <div className="img-wrap img-sm">
                        <img src={item?.event_image} alt={""} />
                      </div>
                    </div>
                    <figcaption className="text-wrap">
                      <h6 className="title mb-2">{item?.event_name}</h6>
                      <div className="text-black-50 small mb-1">
                        {formatDate(
                          convertTimestampToDateTime(item?.event_start_date)
                        )}
                      </div>
                    </figcaption>
                  </figure>
                </MenuItem>

                {/* DIVIDER */}
                <div className="dropdown-divider m-0" />
              </div>
            ))}

            {/* VIEW CART LINK */}
            <MenuItem className="mt-3">
              <Link to="/cart" className="btn btn-outline-dark btn-block">
                <i className="fas fa-cart-arrow-down" /> View cart
              </Link>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  ) : (
    <div></div>
  );
}
