import React from "react";

const MinusButton = ({ decrementQuantity, id }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={(e) => decrementQuantity(id)}
    >
      <path
        opacity="0.9"
        d="M22.8571 14H9.14286C8.51179 14 8 14.5971 8 15.3333V16.6667C8 17.4029 8.51179 18 9.14286 18H22.8571C23.4882 18 24 17.4029 24 16.6667V15.3333C24 14.5971 23.4882 14 22.8571 14Z"
        fill="#1B1B1E"
      />
      <rect
        x="0.25"
        y="0.25"
        width="31.5"
        height="31.5"
        rx="3.75"
        stroke="#1B1B1E"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default MinusButton;
