import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Sidenavbar from "./components/sidenavbar";

import { Link, useNavigate, useParams } from "react-router-dom";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import Loader from "../../components/loader/loader";
import TextEditor from "../../components/text_editor/text_editor";
import { myContext } from "../../user_context";
import { isMobile } from "react-device-detect";
import useFetchDocument from "../../customHooks/useFetchDocument";
import app, { auth, storage } from "../../Firebase";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { userStore } from "../../store/user_store";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import AdminSideNav from "../admin_dashboard/components/admin_side_nav";
import { useEvents } from "../../store/event_store";
import { useVenues } from "../../store/venues_store";
import { useSettings } from "../../store/setting_store";

// INITILIZE FIRESTORE

export default function EditVenue() {
  const { venue_id } = useParams();
  //users from zustand store
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);
  const isAttendee = userStore((state) => state.isAttendee);
  const updateVenue = useVenues((state) => state.updateVenue);
  const getAmmenitiesofStatusVisible = useVenues(
    (state) => state.getVisibleAmmenities
  );
  const getVenueTypesofStatusVisible = useVenues(
    (state) => state.getVisibleVenueTypes
  );
  const uploadVenueImage = useVenues((state) => state.uploadVenueImage);

  //getting visible countries
  const getVisibleCountries = useEvents((state) => state.getVisibleCountries);
  const layout_page = useSettings((state) => state.layout_page);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [numFieldsets, setNumFieldsets] = useState(0);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [venueTypesList, setVenuetypeList] = useState([]);
  /////////////FETCHING COUNTRIES//////////////////////
  /////////////////////////////////////////////////////
  const [countriesList, setCountriesList] = useState([]);

  //getting data from database
  useEffect(() => {
    async function fetchData() {
      //GETTING AMENITIES
      const amenitydata = await getAmmenitiesofStatusVisible();
      //GETTING AMMENITY NAMES
      const amenitynames = amenitydata.map((amenity) => amenity.name);
      setAmenitiesList(amenitynames);
    }

    // GETTING THE COUNTRIES
    const get_countries_list = async () => {
      const returnedCountries = await getVisibleCountries();
      console.log("the countries:", returnedCountries);
      const countries = [];
      for (var i = 0; i < returnedCountries.length; i++) {
        countries.push({
          value: returnedCountries[i].countryCode,
          label: returnedCountries[i].name,
        });
      }
      setCountriesList(countries);
    };
    // GETTING VENUE TYPES
    const get_venue_types = async () => {
      const venuetypeData = await getVenueTypesofStatusVisible();
      const venuetypeoptions = venuetypeData.map((venuetype) => {
        return {
          value: venuetype?.id,
          label: venuetype?.venue_type,
        };
      });
      setVenuetypeList(venuetypeoptions);
    };
    get_countries_list();
    get_venue_types();
    fetchData();
  }, []);

  //fetching the venues from the database USING A CUSTOM HOOK
  const { document } = useFetchDocument("venues", venue_id);
  console.log(document);

  const [formVenues, setFormVenues] = useState(document || {}); // Provide an empty object as the default value

  useEffect(() => {
    setFormVenues(document);
  }, [document]);

  // HANDLING NAME, VENUE_TYPES, STREET_ADDRESS,COUNTRY, ETC
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormVenues((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //HANDLE DESCRIPTION
  const handleDescriptionChange = (value) => {
    setFormVenues((prevFormData) => ({
      ...prevFormData,
      description: value,
    }));
  };

  const handleEditVenue = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateVenue(venue_id, {
          name: formVenues?.name,
          venue_type: formVenues?.venue_type,
          venue_images: formVenues?.venue_images,
          description: formVenues?.description,
          country: formVenues?.country,
          city: formVenues?.city,
          amenities: formVenues?.amenities,
          seated_guests: formVenues?.seated_guests,
          standing_guests: formVenues?.standing_guests,
          neighborhoods: formVenues?.neighborhoods,
          food_and_beverage_details: formVenues?.food_and_beverage_details,
          showMapWithAddress: formVenues?.showMapWithAddress || false,
          showQuoteFormOnVenuePage:
            formVenues?.showQuoteFormOnVenuePage || false,
          street_address: formVenues?.street_address,
          latitude: formVenues?.latitude,
          longitude: formVenues?.longitude,
          contact_email: formVenues?.contact_email,
          pricing: formVenues?.pricing,
          availability: formVenues?.availability,
        });

        displaySuccessToast("Your update is successful");
        setIsLoading(false);
        if (isOrganizer) {
          navigate("/myvenues");
        } else {
          navigate("/manage_venues");
        }
      } catch (error) {
        console.log("ERROR OCCURED WHILE UPDATING THE VENUE", error);
        displayErrorToast("Your update is not successfull");
        setIsLoading(false);
      }
    } else {
      setErrors(errors);
      console.log("ERRORS: " + Object.keys(errors).length);
    }
  };

  // Radio button onChange handler for showMapWithAddress field
  const handleShowMapChange = (e) => {
    const value = e.target.value === "1" ? true : false;
    setFormVenues((prevFormData) => ({
      ...prevFormData,
      showMapWithAddress: value,
    }));
  };

  // Method to handle amenities
  const handleAmenities = (amenity) => {
    const newAmenities = [...formVenues.amenities];
    const amenityIndex = newAmenities.indexOf(amenity);

    if (amenityIndex !== -1) {
      // If the amenity is already selected, remove it
      newAmenities.splice(amenityIndex, 1);
    } else {
      // If the amenity is not selected, add it
      newAmenities.push(amenity);
    }
    setFormVenues((prevFormData) => ({
      ...prevFormData,
      amenities: newAmenities,
    }));
  };

  // HANDLING VENUE IMAGES
  const handleVenueImages = async (e) => {
    const file = e.target.files[0];
    const imageUrl = await uploadVenueImage(file);

    const images = [...formVenues.venue_images, imageUrl];

    // Setting the venue images
    setFormVenues((prevFormData) => ({
      ...prevFormData,
      venue_images: images,
    }));
  };

  const contextValue = useContext(myContext);

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING VENUE NAME
    if (!formVenues.name) {
      errors.name = "Please select the venue name";
    }

    // VALIDATING VENUE TYPE
    if (!formVenues.venue_type) {
      errors.venue_type = "Please select the venue type";
    }

    // VALIDATING VENUE STREET ADDRESS
    if (!formVenues.street_address) {
      errors.street_address = "Please select the street address";
    }

    // VALIDATING VENUE COUNTRY
    if (!formVenues.country) {
      errors.country = "Please select the country";
    }

    // VALIDATING VENUE CITY
    if (!formVenues.city) {
      errors.city = "This option is required";
    }
    if (!formVenues.description) {
      errors.description = "Please select the venue description";
    }

    return errors;
  }

  //
  useEffect(() => {
    console.log("THE VALUE FROM CONTEXT IS: " + contextValue.user?.user_id);
  }, [contextValue.user?.user_id]);

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  //venue images fields
  const handleAddFieldset = (e) => {
    e.preventDefault();
    setNumFieldsets(numFieldsets + 1);
  };

  const handleRemoveFieldset = (e) => {
    e.preventDefault();
    if (numFieldsets > 0) {
      setNumFieldsets(numFieldsets - 1);
    }
  };

  // handling remove image an image from the venue gallery
  const handleRemoveVenueImage = async (index) => {
    if (index >= 0 && index < formVenues.venue_images.length) {
      setIsLoading(true);
      const removeImageUrl = formVenues.venue_images[index];
      const storageRef = ref(storage, removeImageUrl);

      // Update the URL to an empty string in Firestore
      const updatedImages = [...formVenues.venue_images];
      updatedImages[index] = "";
      const filteredImages = updatedImages.filter((url) => url !== "");

      setFormVenues((prevFormData) => ({
        ...prevFormData,
        venue_images: filteredImages,
      }));

      await updateVenue(venue_id, {
        venue_images: filteredImages,
      });

      try {
        // Delete the image from Firebase Storage
        await deleteObject(storageRef);
      } catch (error) {
        // Handle the error
        console.log("Error removing image from storage:", error);
        // toast.error("Error removing image");
      }
      setIsLoading(false);
    }
  };

  const fieldsets = [];

  for (let i = 0; i < numFieldsets; i++) {
    fieldsets.push(
      <>
        <fieldset className="form-group" style={{}} key={i}>
          <div id={"venue_images" + i}>
            <fieldset className="form-group">
              <legend className="col-form-label">Venue Image</legend>
              <div className="vich-image">
                <div className="custom-file">
                  {/* PICKING ANOTHER EVENT IMAGE */}
                  <input type="file" onChange={handleVenueImages} />
                </div>
              </div>
            </fieldset>
          </div>

          {/* REMOVE IMAGE BUTTON */}
          <div onClick={handleRemoveFieldset}>
            <a href="#" className="mr-3 btn btn-outline-dark btn-sm ">
              <i className="fas fa-minus-square" /> Remove
            </a>
          </div>
        </fieldset>
      </>
    );
  }

  if (isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrump title={"Edit a venue"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {isOrganizer ? (
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar />}
                </aside>
              ) : (
                <AdminSideNav />
              )}

              {/* MAIN CONTNET*/}

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="venue"
                      method="post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleEditVenue}
                    >
                      {/* TRANSLATIONS PART */}
                      <fieldset className="form-group">
                        <div className="a2lix_translations">
                          <div className="tab-content">
                            {/* ENGLISH */}
                            <div
                              id="venue_translations_en_a2lix_translations-fields"
                              className="tab-pane show active "
                              role="tabpanel"
                            >
                              <div id="venue_translations_en">
                                {/* VENUE NAME */}
                                {errors.formVenues?.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.formVenues?.name}
                                  </span>
                                )}
                                <div className="form-group">
                                  <label
                                    htmlFor="venue_translations_en_name"
                                    className="required"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required="required"
                                    maxLength={50}
                                    pattern=".{1,}"
                                    className={
                                      errors.name
                                        ? "form-control border border-danger"
                                        : "form-control"
                                    }
                                    value={formVenues?.name || ""}
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </div>

                                {/* DESCRIPTION */}
                                {errors.formVenues?.description && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.formVenues?.description}
                                  </span>
                                )}
                                <div className="form-group">
                                  <label
                                    htmlFor="venue_translations_en_description"
                                    className="required"
                                  >
                                    Description
                                  </label>

                                  <TextEditor
                                    name="description"
                                    value={formVenues?.description || ""}
                                    onChange={(value) =>
                                      handleDescriptionChange(value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      {/* TYPES OF VENUE DROPDOWN */}
                      {errors.formVenues?.venue_type && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.formVenues?.venue_type}
                        </span>
                      )}
                      <div className="form-group">
                        <label className="required" htmlFor="venue_type">
                          Type
                        </label>
                        <SearchableDropdown
                          options={venueTypesList}
                          placeholder={"Select Venue Type"}
                          value={formVenues?.venue_type}
                          onChange={(selectedOption) =>
                            handleInputChange({
                              target: {
                                name: "venue_type",
                                value: selectedOption,
                              },
                            })
                          }
                          isMulti={false}
                        />
                      </div>

                      {/* AMENITIES SECTION */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Amenities</legend>
                        <div id="venue_amenities">
                          {amenitiesList.map((amenity, index) => (
                            <div
                              className="custom-control custom-checkbox custom-control-inline"
                              key={index}
                            >
                              <input
                                type="checkbox"
                                id={"venue_amenities_" + index}
                                name={index}
                                className="custom-control-input"
                                value={amenity}
                                checked={formVenues?.amenities?.includes(
                                  amenity
                                )}
                                onChange={() => handleAmenities(amenity)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={"venue_amenities_" + index}
                              >
                                {amenity}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>

                      {/* SEATED GUESTS */}
                      <div className="form-group">
                        <label htmlFor="venue_seatedguests">
                          Seated guests number
                        </label>
                        <input
                          type="text"
                          id="venue_seatedguests"
                          name="seated_guests"
                          className="touchspin-integer form-control"
                          data-max={100000}
                          value={formVenues?.seated_guests || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* STANDING GUESTS NUMBER */}
                      <div className="form-group">
                        <label htmlFor="venue_standingguests">
                          Standing guests number
                        </label>
                        <input
                          type="text"
                          id="venue_standingguests"
                          name="standing_guests"
                          className="touchspin-integer form-control"
                          data-max={100000}
                          value={formVenues?.standing_guests || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* NEIGHBORHOODS */}
                      <div className="form-group">
                        <label htmlFor="venue_neighborhoods">
                          Neighborhoods
                        </label>
                        <input
                          type="text"
                          id="venue_neighborhoods"
                          name="neighborhoods"
                          className="form-control"
                          value={formVenues?.neighborhoods || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* PRICING */}
                      <div className="form-group">
                        <label htmlFor="venue_pricing">Pricing</label>
                        <textarea
                          id="venue_pricing"
                          name="pricing"
                          className="form-control"
                          value={formVenues?.pricing || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* AVAILABILITY */}
                      <div className="form-group">
                        <label htmlFor="venue_availibility">Availability</label>
                        <textarea
                          id="venue_availibility"
                          name="availability" // corrected attribute name
                          className="form-control"
                          value={formVenues?.availability || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* FOOD AND BEVERAGE DETAILS */}
                      <div className="form-group">
                        <label htmlFor="venue_foodbeverage">
                          Food and beverage details
                        </label>
                        <textarea
                          id="venue_foodbeverage"
                          name="food_and_beverage_details"
                          className="form-control"
                          value={formVenues?.food_and_beverage_details || ""}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* SHOW QUOTE ON THE VENUE PAGE */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the quote form on the venue page
                        </legend>
                        <div id="venue_quoteform">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="venue_quoteform_0"
                              name="showQuoteFormOnVenuePage"
                              required="required"
                              className="custom-control-input"
                              defaultValue={0}
                              checked={
                                formVenues?.showQuoteFormOnVenuePage === false
                              }
                              onChange={(e) =>
                                setFormVenues((prevFormData) => ({
                                  ...prevFormData,
                                  showQuoteFormOnVenuePage:
                                    e.target.value === true ? true : false,
                                }))
                              }
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="venue_quoteform_0"
                            >
                              No
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="venue_quoteform_1"
                              name="showQuoteFormOnVenuePage"
                              required="required"
                              className="custom-control-input"
                              defaultValue={1}
                              checked={
                                formVenues?.showQuoteFormOnVenuePage === true
                              }
                              onChange={(e) =>
                                setFormVenues((prevFormData) => ({
                                  ...prevFormData,
                                  showQuoteFormOnVenuePage:
                                    e.target.value === true ? true : false,
                                }))
                              }
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="venue_quoteform_1"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* EMAIL FIELD */}
                      <div className="form-group">
                        <label htmlFor="venue_contactemail">
                          Contact email
                        </label>
                        <small
                          id="venue_contactemail_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          />
                          This email address will be used to receive the quote
                          requests, make sure to mention it if you want to show
                          the quote form
                        </small>
                        <input
                          type="email"
                          id="venue_contactemail"
                          name="contact_email"
                          aria-describedby="venue_contactemail_help"
                          className="form-control"
                          value={formVenues?.contact_email}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {/* STREET ADDRESS */}
                      {errors.formVenues?.street_address && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.formVenues?.street_address}
                        </span>
                      )}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="venue_street" className="required">
                              Street address
                            </label>
                            <input
                              type="text"
                              id="venue_street"
                              name="street_address"
                              required="required"
                              className={
                                errors.street_address
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              value={formVenues?.street_address || ""}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>

                          {/* CITY */}
                          {errors.formVenues?.city && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.formVenues?.city}
                            </span>
                          )}
                          <div className="form-group">
                            <label htmlFor="venue_city" className="required">
                              City
                            </label>
                            <input
                              type="text"
                              id="venue_city"
                              name="city"
                              required="required"
                              className={
                                errors.city
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              value={formVenues?.city || ""}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>

                          {/* COUNTRY SELECTION */}
                          {errors.formVenues?.country && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.formVenues?.country}
                            </span>
                          )}
                          <div className="form-group">
                            <label className="required" htmlFor="venue_country">
                              Country
                            </label>

                            <SearchableDropdown
                              name="country"
                              value={formVenues?.country || ""}
                              options={countriesList}
                              onChange={(selectedOption) =>
                                handleInputChange({
                                  target: {
                                    name: "country",
                                    value: selectedOption,
                                  },
                                })
                              }
                              isMulti={false}
                              placeholder="Select Country"
                            />
                          </div>

                          {/* MAP SECTION */}
                          <fieldset className="form-group">
                            <legend className="col-form-label required">
                              Show the map along with the address on the venue
                              page and event page
                            </legend>
                            <div id="venue_showmap">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="venue_showmap_0"
                                  name="showMapWithAddress"
                                  required="required"
                                  className="custom-control-input"
                                  value={"0"}
                                  checked={
                                    formVenues?.showMapWithAddress === false
                                  }
                                  onChange={handleShowMapChange}
                                />
                                <label
                                  className="custom-control-label required"
                                  htmlFor="venue_showmap_0"
                                >
                                  No
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="venue_showmap_1"
                                  name="showMapWithAddress"
                                  required="required"
                                  className="custom-control-input"
                                  value={"1"}
                                  checked={
                                    formVenues?.showMapWithAddress === true
                                  }
                                  onChange={handleShowMapChange}
                                />
                                <label
                                  className="custom-control-label required"
                                  htmlFor="venue_showmap_1"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <div className="sticky-top sticky-sidebar pt-0">
                            <iframe
                              title={formVenues?.name}
                              className="w-100 border-0 venue-map"
                              height={300}
                              src={`https://maps.google.com/maps?q=${formVenues?.name}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            />
                          </div>
                        </div>
                      </div>

                      {/* IMAGE GALLERY */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Images</legend>
                        <div id="venue_images" className="form-collection">
                          {formVenues?.venue_images &&
                            formVenues?.venue_images.map((image, index) => (
                              <div key={index} className="d-flex flex-column">
                                <img
                                  className="w-25 rounded mt-2"
                                  src={image}
                                  alt={`Venue ${index}`}
                                />
                                {image && (
                                  <button
                                    className="btn btn-outline-dark btn-sm mb-2"
                                    style={{ maxWidth: "fit-content" }}
                                    onClick={() =>
                                      handleRemoveVenueImage(index)
                                    }
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            ))}

                          {fieldsets}

                          <Link
                            to=""
                            onClick={handleAddFieldset}
                            className="mr-3 btn btn-outline-dark btn-sm mt-2"
                          >
                            <i className="fas fa-plus-square" /> Add
                          </Link>
                        </div>
                      </fieldset>

                      {/* SAVE BUTTON */}
                      <div className="form-group">
                        <button
                          type="submit"
                          id="venue_save"
                          name="venue[save]"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
