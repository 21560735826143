import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditVenueType() {
  const updateVenueTypes = useVenues((state) => state.updateVenueTypes);
  const layout_page = useSettings((state) => state.layout_page);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { venue_type_id } = useParams();
  const { document } = useFetchDocument("venue_types", venue_type_id);
  const [venueForm, setVenueForm] = useState(document || {});
  console.log(document);

  const navigate = useNavigate();

  useEffect(() => {
    setVenueForm(document);
  }, [document]);

  // HANDLING UPLOADING THE BLOG POST CATEGORY

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVenueForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // handling edit venue type
  const handleEditVenueType = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateVenueTypes(venue_type_id, {
          venue_type: venueForm?.venue_type,
        });
        displaySuccessToast("Update Successful");
        navigate("/manage_venue_types");
      } catch (error) {
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING VENUE TYPE
    if (!venueForm?.venue_type) {
      errors.venueForm.venue_type = "Venue Type is required";
    }

    return errors;
  }

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit venue type"
          page_link="Manage Venues types"
          link="manage_venue_types"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_venue_types"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="venue"
                      method="post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleEditVenueType}
                    >
                      <div className=" tab-content">
                        <div
                          id="venue_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          <div id="venue_translations_en">
                            {/* NAME */}
                            <div className="form-group d-grid">
                              <label
                                htmlFor="venue_translations_en_name"
                                className="required"
                              >
                                Name
                              </label>
                              {errors.venueForm?.venue_type && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.venueForm?.venue_type}
                                </span>
                              )}
                              <input
                                type="text"
                                id="venue_translations_en_name"
                                name="venue_type"
                                required="required"
                                maxLength={50}
                                pattern=".{1,}"
                                className={
                                  errors.venueForm?.venue_type
                                    ? " border border-danger form-control"
                                    : "form-control"
                                }
                                value={venueForm?.venue_type}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          id="venue_type"
                          name="venue"
                          className="btn text-white"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
