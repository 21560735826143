import React from "react";
import ReactDOM from "react-dom/client";
import "./new_css.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./components/error_boundary";

// DISABLE PRINTING TO THE CONSOLE IN PRODUCTION
if (process.env.NODE_ENV === "production") {
  console.log("PRODUCTION");
  console.warn = () => {};
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
