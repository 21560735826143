import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import app from "../Firebase";

// INITILIZE FIRESTORE
const db = getFirestore(app);

//////////////////////////////
// METHOD TO ADD A REVEIW //
///////////////////////////
export async function addReview(data) {
  // CREATING A COLLECTION
  const reviewCollection = collection(db, "reviews");

  try {
    const responseFromFirestore = await addDoc(reviewCollection, data);
    return true;
  } catch (error) {
    console.log("SOMETHING WENT WRONG ADDING REVEIW: " + error);
    return false;
  }
}

//////////////////////////////
// METHOD TO GET REVIEWS FOR THE EVENT
///////////////////////////

export async function getEventReviews(event_id) {
  // CREATING A COLLECTION
  const reviewCollection = collection(db, "reviews");

  try {
    var q = query(reviewCollection, where("event_id", "==", event_id));
    var response = await getDocs(q);
    var reviews = [];
    response.forEach((doc) => {
      var data = doc.data();
      if (data) {
        reviews.push(data);
      }
    });
    return reviews;
  } catch (error) {
    console.log("Error getting documents: ", error);
  }
}

//////////////////////////////
// METHOD TO GET REVIEWS FOR A USER  //
///////////////////////////
export async function getUserReviews(user_id) {
  console.log("GETTING REVIEWS FOR USER ID: " + user_id);
  // CREATING A COLLECTION
  const reviewCollection = collection(db, "reviews");

  try {
    var q = query(reviewCollection, where("user_id", "==", user_id));
    var response = await getDocs(q);
    var reviews = [];
    response.forEach((doc) => {
      reviews.push(doc.data());
    });

    return reviews;
  } catch (error) {
    console.log("Error getting documents: ", error);
  }
}

//////////////////////////////
// METHOD TO GET THE AVERAGE REVIEWS FOR THE EVENT  //
///////////////////////////
export async function getEventAverageReviews(event_id) {
  // CREATING A COLLECTION
  const reviewCollection = collection(db, "reviews");

  try {
    var q = query(reviewCollection, where("event_id", "==", event_id));

    var response = await getDocs(q);
    var totalRatings = 0;
    var len = 0;

    response.forEach((doc) => {
      var rating = doc.data().rating;
      totalRatings += rating;
      len++;
    });

    // CALCULATING THE AVERAGE RATING
    var averageRating = len === 0 ? 0 : totalRatings / len;
    return averageRating;
  } catch (error) {
    console.log("Error getting documents: ", error);
    return 0;
  }
}

//A METHOD TO GET ALL REVIEWS FROM THE DATABASE
export const getAllReviewsFromDatabase = async () => {
  const querySnapshot = await getDocs(collection(db, "reviews"));
  const reviews = [];
  querySnapshot.forEach((doc) => {
    reviews.push({ id: doc.id, ...doc.data() });
  });
  return reviews;
};

//A METHOD TO DELETE A REVIEW
export async function deleteReview(review_id) {
  try {
    const reviewRef = doc(db, "reviews", review_id);
    await deleteDoc(reviewRef);
    console.log("review deleted successfully");
    return true;
  } catch (error) {
    console.log("Something went wrong while deleting the review", error);
    return false;
  }
}
