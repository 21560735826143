import React, { useEffect, useState } from "react";
import { ordersStore } from "../../store/orders_store";

const TicketsSoldForAnEvent = ({ event_id }) => {
  const getOrderTicketsForEvent = ordersStore(
    (state) => state.getOrderTicketsForEvent
  );
  const [tickets, setTickets] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      //getting all the ticket for an event
      const t = await getOrderTicketsForEvent(event_id);
      setTickets(t);
    };
    fetchData();
  });
  return <div className=" text-sm">{tickets}</div>;
};

export default TicketsSoldForAnEvent;
