import { collection, getDocs, getFirestore } from 'firebase/firestore';

import app from '../../Firebase';

const db = getFirestore(app);

export async function fetchPhonebooks() {
  const phonebooks = collection(db, 'phonebooks');
  const docsSnap = await getDocs(phonebooks);
  let _phonebooks = [];

  if (docsSnap.size > 0) {
    docsSnap.docs.forEach((doc) => {
      _phonebooks.push({ ...doc.data(), id: doc.id });
    });
  }

  return _phonebooks;
}
