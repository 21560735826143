import React, { useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSettings } from "../../store/setting_store";
import { applyActionCode, getAuth } from "firebase/auth";
import app from "../../Firebase";
import { displayErrorToast } from "../../services/helpers";
import { userStore } from "../../store/user_store";
import Loader from "../../components/loader/loader";

function SignupConfirmed() {
  const layout_page = useSettings((state) => state.layout_page);
  const login = userStore((state) => state.login);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useState(() => {
    const auth = getAuth(app);
    const actionCode = location.search.split("oobCode=")[1];
    const verifyEmail = async () => {
      try {
        await applyActionCode(auth, actionCode);
        const email = localStorage.getItem("emailForSignIn");
        const password = localStorage.getItem("passwordForSignIn");
        console.log("the password is: " + password);
        console.log("the email is: " + email);
        const response = await login(email, password);
        if (response != null) {
          if (response.user_type === "attendee") {
            navigate("/dashboard", { state: response });
            setVerificationStatus(true);
          } else if (response.user_type === "organizer") {
            navigate("/dashboard_organizer", { state: response });
            setVerificationStatus(true);
          } else if (response.user_type === "admin") {
            navigate("/admin_dashboard", { state: response });
          } else {
            console.log("ERROR: " + response);
            displayErrorToast("Couldn't Log You In");
            setVerificationStatus(true);
          }
        } else {
          displayErrorToast("Invalid credentials");
        }
      } catch (error) {
        console.log("error has occurred: " + error);
      }
      setIsLoading(false);
    };

    verifyEmail();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  {verificationStatus ? (
                    <>
                      <p className="text-center">
                        <i className="far fa-check-circle fa-8x text-success" />
                      </p>

                      <h3 className="text-center">
                        Congrats Alozious, your account is now activated.
                      </h3>
                      <p className="text-center mt-5">
                        <Link
                          to="/dashboard_organizer"
                          className="btn text-white mb-3"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          <i className="fas fa-tachometer-alt fa-fw" />{" "}
                          Dashboard
                        </Link>
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className="text-center">
                        Verification failed. Please contact support for
                        assistance.
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default SignupConfirmed;
