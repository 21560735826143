import { useState } from 'react';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FaInfoCircle } from 'react-icons/fa';

export default function Description() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <FaInfoCircle
        color="#f76300"
        size={20}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{ marginLeft: '10px' }}
      >
        <Typography
          sx={{
            p: '12px 16px',
            bgcolor: '#f76300',
            color: '#fff',
            fontSize: '13px',
          }}
        >
          A few details about the payout beneficiary being added as an option
          for payout
        </Typography>
      </Popover>
    </div>
  );
}
