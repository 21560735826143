import { useNavigate } from "react-router-dom";
import Header from "../components/header/header";
import { userStore } from "./../store/user_store";
import Footer from "../components/footer/footer";
import { auth } from "../Firebase";
import { useEffect } from "react";

export default function AccessDenied() {
  const user = userStore((state) => state.user);
  const navigate = useNavigate();

  // CHECKING IF USER IS LOGGED IN
  useEffect(() => {
    // CHECKING IF USER IS LOGGED IN
    if (!user) {
      navigate("/sign_in");
    }
  }, [user, navigate]);

  // Check if the user is not verified
  if (!auth.currentUser?.emailVerified && user) {
    return (
      <>
        <Header />

        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">
              Access denied
            </h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <a href="/en" className="dark">
                    <i className="fas fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Access denied
                </li>
              </ol>
            </nav>
          </div>
        </section>
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="alert alert-danger alert-icon">
                  <i className="fas fa-ban" /> You are an {user?.user_type} but
                  you have not yet verified. Please verify your email to access
                  this resource.
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }

  return (
    <>
      {user && auth.currentUser?.emailVerified && (
        <>
          <Header />

          {/* BREADCRUMP */}
          <section className="section-pagetop bg-gray">
            <div className="container clearfix">
              <h4 className="title-page dark b float-xl-left mb-0">
                Access denied
              </h4>
              <nav className="float-xl-right mt-2 mt-xl-0">
                <ol className="breadcrumb text-white">
                  <li className="breadcrumb-item">
                    <a href="/en" className="dark">
                      <i className="fas fa-home" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Access denied
                  </li>
                </ol>
              </nav>
            </div>
          </section>

          {/* ACCESS DENIED */}

          <section className="section-content padding-y bg-white">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="alert alert-danger alert-icon">
                    <i className="fas fa-ban" /> Access is denied. You may not
                    have the appropriate permissions to access this resource.
                    You're
                    {user && " logged in as an: "}
                    {user?.user_type}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </>
      )}
    </>
  );
}
