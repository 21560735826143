import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import "./homePage.css";
import { useSettings } from "../../../store/setting_store";
import { useVenues } from "../../../store/venues_store";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageHomepage() {
  const isAdmin = userStore((state) => state.isAdmin);
  const organizers = userStore((state) => state.organizers);
  //getting events from the database.
  const events = useEvents((state) => state.events);
  const getHomePageSliderId = useSettings((state) => state.getHomePageSliderId);
  const home_page = useSettings((state) => state.home_page);
  const getHomePageContent = useSettings((state) => state.getHomePageContent);
  const venues = useVenues((state) => state.venues);
  const layout_page = useSettings((state) => state.layout_page);
  //update the slider
  const updateSlider = useSettings((state) => state.updateSlider);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [event_options, setEventOptions] = useState([]);
  const [venues_options, setVenuesOptions] = useState([]);
  const [slider_id, setSliderID] = useState(null);
  const uploadLayoutImages = useSettings((state) => state.uploadLayoutImages);
  const deleteImage = useSettings((state) => state.deleteImage);
  const [slider, setSlider] = useState(home_page || {});
  //handle custom image
  const [customUrl, setCustomUrl] = useState({
    url: slider?.custom_image,
    path: "",
  });
  // Handling logo image
  const handleCustomImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePath = "settings/layout_images/custom_image";
      const imageUrl = await uploadLayoutImages(file, imagePath);
      if (imageUrl) {
        setCustomUrl({ url: imageUrl, path: imagePath });
        setSlider((prevFormData) => ({
          ...prevFormData,
          custom_image: imageUrl,
        }));
      }
    }
  };

  // Handling delete custom image
  const handleDeleteCustomImage = async (e) => {
    e.preventDefault();
    if (slider?.custom_image) {
      await deleteImage(slider?.custom_image);
      setCustomUrl(null);
      setSlider((prevFormData) => ({
        ...prevFormData,
        custom_image: null,
      }));
    }
  };

  // Handle Input change
  const handleInputChange = (selectedEvents) => {
    setSlider((prevFormData) => ({
      ...prevFormData,
      slider_events: selectedEvents,
    }));
  };

  //for venues
  const handleInputVenues = (selectedVenues) => {
    setSlider((prevFormData) => ({
      ...prevFormData,
      slider_organizers: selectedVenues,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setSlider((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //ENABLE ALL RADIO BUTTONS
  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setSlider((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // GET EVENT OPTIONS
  function getEventOptions() {
    const eventOptions = events.map((event) => {
      return {
        value: event?.id,
        label: event?.name,
      };
    });

    console.log("THE LENGTH OF EVENT OPTIONS: ", eventOptions.length);
    setEventOptions(eventOptions);
  }

  //GET VENUES OPTIONS
  const getVenuesOptions = () => {
    const venuesOptions = venues.map((venue) => {
      return {
        value: venue?.id,
        label: venue?.name,
      };
    });
    console.log("the venues are:", venuesOptions);
    setVenuesOptions(venuesOptions);
  };

  useEffect(() => {
    const fetchSliderID = async () => {
      const id = await getHomePageSliderId();
      setSliderID(id);
    };
    const homePageContent = async () => {
      await getHomePageContent();
    };
    homePageContent();
    fetchSliderID();
    getEventOptions();
    getVenuesOptions();
  }, [events.length, organizers.length]);

  //const update the homepage
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateSlider(slider_id, {
        slider_events: slider?.slider_events,
        slider_organizers: slider?.slider_organizers,
        blog_posts_show: slider?.blog_posts_show,
        featured_categories_show: slider?.featured_categories_show,
        events_to_show: slider?.events_to_show,
        custom_hero_title: slider?.custom_hero_title,
        custom_hero_paragraph: slider?.custom_hero_paragraph,
        show_home_page_hero_search_box:
          slider?.show_home_page_hero_search_box || false,
        show_search_box: slider?.show_search_box || false,
        show_call: slider?.show_call || false,
        custom_image: slider?.custom_image || "",
        what_to_show: slider?.what_to_show,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("Error in updating the layout page", error);
      displayErrorToast("something is wrong");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Homepage settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_homepage"} />

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="homepage_hero_settings"
                      noValidate="novalidate"
                      onSubmit={handleUpdate}
                    >
                      <div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            What to show in the homepage hero?
                          </legend>
                          <div id="homepage_hero_settings_content">
                            {/* HIDE SLIDER */}
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="homepage_hero_settings_content_0"
                                name="what_to_show"
                                required
                                className="custom-control-input"
                                value="none"
                                checked={slider?.what_to_show === "none"}
                                onChange={handleInputChanging}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="homepage_hero_settings_content_0"
                              >
                                Hide slider
                              </label>
                            </div>
                            {/* EVENT SLIDER */}
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="homepage_hero_settings_content_1"
                                name="what_to_show"
                                required
                                className="custom-control-input"
                                value="events_slider"
                                checked={
                                  slider?.what_to_show === "events_slider"
                                }
                                onChange={handleInputChanging}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="homepage_hero_settings_content_1"
                              >
                                Events slider
                              </label>
                            </div>
                            {/* ORGANIZER SLIDER */}
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="homepage_hero_settings_content_2"
                                name="what_to_show"
                                required
                                className="custom-control-input"
                                value="venues_slider"
                                checked={
                                  slider?.what_to_show === "venues_slider"
                                }
                                onChange={handleInputChanging}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="homepage_hero_settings_content_2"
                              >
                                Venues slider
                              </label>
                            </div>
                            {/* CUSTOM HERO SLIDER */}
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="homepage_hero_settings_content_3"
                                name="what_to_show"
                                required
                                className="custom-control-input"
                                value="custom"
                                checked={slider?.what_to_show === "custom"}
                                onChange={handleInputChanging}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="homepage_hero_settings_content_3"
                              >
                                Custom hero
                              </label>
                            </div>
                          </div>
                        </fieldset>

                        {/* EVENTS TO SLIDE */}
                        {slider?.what_to_show === "events_slider" && (
                          <div className="form-group">
                            <label htmlFor="homepage_hero_settings_events">
                              Events
                            </label>
                            <SearchableDropdown
                              value={slider?.slider_events}
                              options={event_options}
                              isMulti={true}
                              onChange={(e) => handleInputChange(e)}
                              className="custom-dropdown"
                            />
                          </div>
                        )}

                        {/* ORGANIZERS TO SLIDE */}
                        {slider?.what_to_show === "venues_slider" && (
                          <div className="form-group">
                            <label
                              className
                              htmlFor="homepage_hero_settings_organizers"
                            >
                              Venues
                            </label>
                            <small
                              id="homepage_hero_settings_organizers_help"
                              className="form-text text-muted mb-3"
                            >
                              <i className="fas fa-info-circle text-primary mr-1" />
                              Make sure to select venues that have nice front
                              images
                            </small>
                            <SearchableDropdown
                              value={slider?.slider_organizers}
                              options={venues_options}
                              isMulti={true}
                              onChange={(e) => handleInputVenues(e)}
                              className="custom-dropdown"
                            />
                          </div>
                        )}

                        {/* CUSTOM HERO BACKGROUND IMAGE */}
                        {slider?.what_to_show === "custom" && (
                          <div>
                            <fieldset className="form-group">
                              <legend className="col-form-label">
                                Custom hero background image
                              </legend>{" "}
                              {/* Existing custom image */}
                              {slider?.custom_image ? (
                                <div className="d-grid">
                                  <img
                                    src={slider?.custom_image}
                                    alt=""
                                    style={{ maxWidth: "50%" }}
                                  />
                                  <div
                                    onClick={handleDeleteCustomImage}
                                    className="bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                    style={{ width: "50%", fontWeight: "500" }}
                                  >
                                    Delete Custom Image
                                  </div>
                                </div>
                              ) : (
                                <div className="vich-image">
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      id="custom_image"
                                      name="custom_image"
                                      className="custom-file-input"
                                      onChange={handleCustomImage}
                                      required={true}
                                    />
                                    <label
                                      htmlFor="custom_image_imageFile_file"
                                      className="custom-file-label"
                                    />
                                  </div>
                                </div>
                              )}
                            </fieldset>
                            <fieldset className="form-group">
                              <legend className="col-form-label">
                                Custom hero title and paragraph
                              </legend>
                              <div className="a2lix_translations">
                                <div className="a2lix_translationsFields tab-content">
                                  <div
                                    id="homepage_hero_settings_translations_en_a2lix_translations-fields"
                                    className="tab-pane show active "
                                    role="tabpanel"
                                  >
                                    <div id="homepage_hero_settings_translations_en">
                                      <div className="form-group">
                                        <label htmlFor="homepage_hero_settings_translations_en_title">
                                          Title
                                        </label>
                                        <input
                                          type="text"
                                          id="homepage_hero_settings_translations_en_title"
                                          name="custom_hero_title"
                                          maxLength={100}
                                          className="form-control"
                                          value={
                                            slider?.custom_hero_title || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChanging(e)
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="homepage_hero_settings_translations_en_paragraph">
                                          Paragraph
                                        </label>
                                        <input
                                          type="text"
                                          id="homepage_hero_settings_translations_en_paragraph"
                                          name="custom_hero_paragraph"
                                          maxLength={500}
                                          className="form-control"
                                          value={
                                            slider?.custom_hero_paragraph || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChanging(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <fieldset className="form-group">
                              <legend className="col-form-label">
                                Show the homepage hero seach box
                              </legend>
                              <div id="homepage_hero_settings_show_search_box">
                                <div className="custom-control custom-radio custom-control-inline">
                                  {" "}
                                  <input
                                    type="radio"
                                    id="homepage_hero_settings_show_search_box_0"
                                    name="show_home_page_hero_search_box"
                                    className="custom-control-input"
                                    value={"1"}
                                    checked={
                                      slider?.show_home_page_hero_search_box ===
                                      true
                                    }
                                    onChange={handleEnableRadioInput}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="homepage_hero_settings_show_search_box_0"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  {" "}
                                  <input
                                    type="radio"
                                    id="homepage_hero_settings_show_search_box_1"
                                    name="show_home_page_hero_search_box"
                                    className="custom-control-input"
                                    value={"0"}
                                    checked={
                                      slider?.show_home_page_hero_search_box ===
                                      false
                                    }
                                    onChange={handleEnableRadioInput}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="homepage_hero_settings_show_search_box_1"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        )}
                      </div>

                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the search box
                        </legend>
                        <div id="homepage_hero_settings_homepage_show_search_box">
                          <div className="custom-control custom-radio custom-control-inline">
                            {" "}
                            <input
                              type="radio"
                              id="homepage_hero_settings_homepage_show_search_box_0"
                              name="show_search_box"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={slider?.show_search_box === true}
                              onChange={handleEnableRadioInput}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="homepage_hero_settings_homepage_show_search_box_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            {" "}
                            <input
                              type="radio"
                              id="homepage_hero_settings_homepage_show_search_box_1"
                              name="show_search_box"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={slider?.show_search_box === false}
                              onChange={handleEnableRadioInput}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="homepage_hero_settings_homepage_show_search_box_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <div className="form-group">
                        <label
                          htmlFor="homepage_hero_settings_homepage_events_number"
                          className="required"
                        >
                          Number of events to show
                        </label>
                        <input
                          type="text"
                          id="homepage_hero_settings_homepage_events_number"
                          name="events_to_show"
                          required="required"
                          className="touchspin-integer form-control"
                          data-min={0}
                          data-max={36}
                          value={slider?.events_to_show || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="homepage_hero_settings_homepage_categories_number"
                          className="required"
                        >
                          Number of featured categories to show
                        </label>
                        <input
                          type="text"
                          id="homepage_hero_settings_homepage_categories_number"
                          name="featured_categories_show"
                          required="required"
                          className="touchspin-integer form-control"
                          data-min={0}
                          data-max={21}
                          value={slider?.featured_categories_show || ""}
                          onChange={(e) => handleInputChanging(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="homepage_hero_settings_homepage_blogposts_number"
                          className="required"
                        >
                          Number of blog posts to show
                        </label>
                        <input
                          type="text"
                          id="homepage_hero_settings_homepage_blogposts_number"
                          name="blog_posts_show"
                          required="required"
                          className="touchspin-integer form-control"
                          data-min={0}
                          data-max={15}
                          onChange={(e) => handleInputChanging(e)}
                          value={slider?.blog_posts_show || ""}
                        />
                      </div>
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the call to action block
                        </legend>
                        <div id="homepage_hero_settings_homepage_show_call_to_action">
                          <div className="custom-control custom-radio custom-control-inline">
                            {" "}
                            <input
                              type="radio"
                              id="homepage_hero_settings_homepage_show_call_to_action_0"
                              name="show_call"
                              required="required"
                              className="custom-control-input"
                              value={"1"}
                              checked={slider?.show_call === true}
                              onChange={handleEnableRadioInput}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="homepage_hero_settings_homepage_show_call_to_action_0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            {" "}
                            <input
                              type="radio"
                              id="homepage_hero_settings_homepage_show_call_to_action_1"
                              name="show_call"
                              required="required"
                              className="custom-control-input"
                              value={"0"}
                              checked={slider?.show_call === false}
                              onChange={handleEnableRadioInput}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="homepage_hero_settings_homepage_show_call_to_action_1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="homepage_hero_settings_save"
                          name="homepage_hero_settings[save]"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                      <input
                        type="hidden"
                        id="homepage_hero_settings__token"
                        name="homepage_hero_settings[_token]"
                        defaultValue="Ag8evAXnQacsfrOcUI_4YGqYIF0ZOh7OM7Y1YcjFvQY"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
