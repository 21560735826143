import { create } from "zustand";

const useQueryStore = create((set) => ({
  searchText: "",
  category: "",
  location: "",
  country: "",
  startDate: "",
  endDate: "",
  minPrice: 0,
  maxPrice: 0,
  audienceType: "",
  eventType: "",
  selectedCategory: {},
  selectedCountry: {},
  freeEventsOnly: false,
  tag: "",

  setSearchText: (searchText) => set(() => ({ searchText })),
  setCategory: (category) => set(() => ({ category })),
  setTag: (tag) => set(() => ({ tag })),
  setLocation: (location) => set(() => ({ location })),
  setCountry: (country) => set(() => ({ country })),
  setStartDate: (startDate) => set(() => ({ startDate })),
  setEndDate: (endDate) => set(() => ({ endDate })),
  setMaxPrice: (maxPrice) => set(() => ({ maxPrice })),
  setMinPrice: (minPrice) => set(() => ({ minPrice })),
  setAudienceType: (audienceType) => set(() => ({ audienceType })),
  setEventType: (eventType) => set(() => ({ eventType })),
  setSelectedCategory: (selectedCategory) => set(() => ({ selectedCategory })),
  setSelectedTag: (selectedTag) => set(() => ({ selectedTag })),
  setSelectedCountry: (selectedCountry) => set(() => ({ selectedCountry })),
  setFreeEventsOnly: (freeEventsOnly) => set(() => ({ freeEventsOnly })),

  clearQuery: () =>
    set(() => ({
      searchText: "",
      category: "",
      location: "",
      country: "",
      startDate: "",
      endDate: "",
      minPrice: 0,
      maxPrice: 0,
      audienceType: "",
      eventType: "",
      selectedCategory: {},
      selectedCountry: {},
      setSelectedTag: {},
      freeEventsOnly: false,
      tag: "",
    })),
}));

export default useQueryStore;