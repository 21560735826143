import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { useReview } from "../../store/review_store";
import Loader from "../../components/loader/loader";
import { useSettings } from "../../store/setting_store";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutReviews = ({ review_id, status }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteReview = useReview((state) => state.deleteReview);

  const getHandleStatusReviews = useReview(
    (state) => state.handleStatusReviews
  );
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE LANGUAGE
  async function handleDeleteReview(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await deleteReview(review_id);
    if (response) {
      displaySuccessToast("🦄 The review has been deleted ");
    } else {
      displayErrorToast("Something went wrong while deleting the review");
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }
  const handleStatus = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await getHandleStatusReviews(review_id);
    setIsLoading(false);
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <div>
          <BsThreeDotsVertical
            style={{
              color: layout_page?.primary_color,
              cursor: "pointer",
            }}
          />
          <span
            style={{
              color: layout_page?.primary_color,
              cursor: "pointer",
            }}
          >
            Actions
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {/* HIDE */}
          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleStatus(e)}
          >
            {status === "visible" ? (
              <>
                <i className="fas fa-eye-slash fa-fw" /> <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="fas fa-eye fa-fw" /> <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE REVIEW */}
          <DeleteComponentModal
            itemName="Review"
            handleDelete={handleDeleteReview}
          />{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutReviews;
