import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import backgroundImg from "./../../assets/downloadButtons/bbg.png";
import AppStore from "./../../assets/downloadButtons/appLogo-removebg-preview.png";
import GooglePlay from "./../../assets/downloadButtons/googleplaylogo-removebg-preview.png";

function PopoverAboveMobile() {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasModalBeenShown = localStorage.getItem("hasModalBeenShown");

    if (!hasModalBeenShown) {
      setOpen(true);
      localStorage.setItem("hasModalBeenShown", "true");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const lyxaAppLink =
    "https://play.google.com/store/apps/details?id=com.lyxa.lyxa";
  const lyxaAppStoreLink = "https://apps.apple.com/us/app/lyxa/id1565144071";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(true);
    }, 60000); // 60,000 milliseconds = 1 minute

    return () => clearTimeout(timeout);
  }, []);

  const modalStyle = {
    dialogContent: {
      width: "100%",
      minWidth: "500px",
      height: "300px",
      background: `url(${backgroundImg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  //   const mobileModalStyle = {
  //     dialogContent: {
  //       width: "100%",
  //       minWidth: "420px", // Adjust this for mobile
  //       height: "280px", // Adjust this for mobile
  //       background: `url(${backgroundImg})`,
  //       backgroundSize: "cover",
  //       backgroundRepeat: "no-repeat",
  //       backgroundPosition: "center",
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //     },
  //   };

  const closeButtonStyle = {
    position: "absolute",
    right: 0,
    top: 0,
    color: "orangeRed",
  };

  //   const isMobile = window.innerWidth <= 768; // breakpoint for mobile here

  return (
    showModal && (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={modalStyle.dialogContent} className="relative">
          <span
            onClick={handleClose}
            style={closeButtonStyle}
            className="mr-2 mt-2"
          >
            <AiOutlineClose size={30} />
          </span>

          {/* Buttons */}
          <div
            className="d-flex align-items-center gap-1 mr-2 mb-2 cursor-pointer"
            style={{ position: "absolute", right: 0, bottom: 0, zIndex: 100 }}
          >
            <a
              href={lyxaAppLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black"
            >
              <span
                className="d-flex border px-2 pb-1 cursor-pointer bg-white"
                style={{ width: 130, height: 35, borderRadius: 12 }}
              >
                <img
                  src={GooglePlay}
                  alt="Download from Google Play"
                  style={{ width: 32, height: 25 }}
                  className="mt-1"
                />
                <p
                  className="d-grid ml-2 text-black"
                  style={{ fontSize: 10, color: "black" }}
                >
                  <span>Download on</span>{" "}
                  <span className="fw-bold">Google Play</span>
                </p>
              </span>
            </a>
            <span className="cursor-pointer">
              <a
                href={lyxaAppStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                <span
                  className="d-flex border px-2 pb-1 cursor-pointer bg-white"
                  style={{ width: 130, height: 35, borderRadius: 12 }}
                >
                  <img
                    src={AppStore}
                    alt="Download from Apple Store"
                    style={{ width: 32, height: 30 }}
                  />
                  <p
                    className="d-grid ml-2 text-black"
                    style={{ fontSize: 10, color: "black" }}
                  >
                    <span>Download on</span>{" "}
                    <span className="fw-bold">Apple Store</span>
                  </p>
                </span>
              </a>
            </span>
          </div>
        </DialogContent>
      </Dialog>
    )
  );
}

export default PopoverAboveMobile;
