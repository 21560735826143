import React, { useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import TextEditor from "../../../components/text_editor/text_editor";
import TagsInputField from "../../../components/tags_input_field/tags_input";
import { displaySuccessToast } from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
// import { addArticle } from "../../../services/blogs";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function AddHelpCenterArticle() {
  const addArticle = useBlogs((state) => state.addArticle);
  const layout_page = useSettings((state) => state.layout_page);
  const [userCategory, setUserCategory] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // HANDLING UPLOADING THE CURRENCY
  const handleAddArticle = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      addArticle({
        title: title,
        content: content,
        userCategory: userCategory,
        tags: tags,
        views: 0,
        status: "visible",
        featured: false,
        created_at: Timestamp.fromDate(new Date()),
        updated_at: Timestamp.fromDate(new Date()),
      });
      navigate("/manage_articles");
      displaySuccessToast("Successfully added");
    } else {
      console.log("SOMETHING WENT WRONG-");
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING TITLE
    if (!title) {
      errors.title = "Title is required";
    }

    // VALIDATING content
    if (!content) {
      errors.content = "Content is required";
    }

    // VALIDATING User Category
    if (!userCategory) {
      errors.userCategory = "User Category is required";
    }

    return errors;
  }

  // HANDLE CHANGE OF TAGS
  const handleChange = (e) => {
    setTags(e);
  };

  // HANDLE DESCRIPTION
  const handleContent = (e) => {
    setContent(e);
  };

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new help center article"
          page_link=" Manage help center articles"
          link="manage_articles"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_articles"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="help_center_article"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleAddArticle}
                    >
                      <div id="help_center_article">
                        {/* USER CATEGORY */}
                        <div className="form-group d-grid">
                          <label
                            className="required"
                            htmlFor="help_center_article_category"
                          >
                            Category
                          </label>
                          <small
                            id="help_center_article_category_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Make sure to select right category to let the users
                            find it quickly
                          </small>

                          {errors.userCategory && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.userCategory}
                            </span>
                          )}

                          <select
                            onChange={(e) => setUserCategory(e.target.value)}
                            className="form-control"
                            aria-describedby="help_center_article_category_help"
                          >
                            <option value />
                            <option disabled> Select one</option>
                            <option value="Attendee">Attendee</option>
                            <option value="Organizer">Organizer</option>
                          </select>
                        </div>

                        {/* ARTICLE DETAILS */}

                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Article details
                          </legend>

                          <div
                            id="help_center_article_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="help_center_article_translations_en">
                              {/* TITLE */}
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="help_center_article_translations_en_title"
                                  className="required"
                                >
                                  Title
                                </label>

                                {errors.title && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.title}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="help_center_article_translations_en_title"
                                  name="help_center_article[translations][en][title]"
                                  required="required"
                                  maxLength={80}
                                  pattern=".{1,}"
                                  className={
                                    errors.title
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>

                              {/* ARTICLE CONTENT */}
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="help_center_article_translations_en_content"
                                  className="required"
                                >
                                  Content
                                </label>

                                {errors.content && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.content}
                                  </span>
                                )}
                                <TextEditor
                                  value={content}
                                  onChange={handleContent}
                                />
                              </div>
                              <br />
                              <br />
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="help_center_article_translations_en_tags"
                                  className="required"
                                >
                                  Tags
                                </label>

                                <TagsInputField
                                  tags={tags}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            name="help_center_article[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
