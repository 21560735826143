import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import ContactAttendee from "./ContactAttendee";
import { useSettings } from "../../store/setting_store";
import { Dropdown } from "react-bootstrap";

const PopingOutOrderDetailsOrganizer = ({ order_id, booking_id }) => {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <div className="d-flex gap-1" style={{ color: "gray" }}>
            <i className="dropdown-icon far fa-envelope fa-fw text-muted" />{" "}
            <ContactAttendee booking_id={booking_id} />
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DETAILS */}
          <Link to={"/ticket_details/" + booking_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <i className="dropdown-icon fas fa-file-invoice fa-fw text-muted" />{" "}
              <h6>Details</h6>
            </div>
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutOrderDetailsOrganizer;
