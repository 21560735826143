import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import { myContext } from "../../../user_context";
import AdminSideNav from "../components/admin_side_nav";
import { userStore } from "../../../store/user_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageCheckout() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const navigate = useNavigate();
  const getCheckoutPageId = useSettings((state) => state.getCheckoutPageId);
  const updateCheckoutPage = useSettings((state) => state.updateCheckoutPage);
  const getCheckoutPage = useSettings((state) => state.getCheckoutPage);
  const checkout_page = useSettings((state) => state.checkout_page);
  const layout_page = useSettings((state) => state.layout_page);
  console.log("the page is:", checkout_page);

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [checkout_id, setCheckoutID] = useState(null);
  const [checkoutForm, setCheckoutForm] = useState(checkout_page || {});

  useEffect(() => {
    const getID = async () => {
      const id = await getCheckoutPageId();
      setCheckoutID(id);
    };
    const page = async () => {
      await getCheckoutPage();
    };
    page();
    getID();
  }, []);

  const handleEnableRadioInput = (e) => {
    const name = e.target.name;
    const value = e.target.value === "1" ? true : false;
    setCheckoutForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChanging = (e) => {
    const { name, value } = e.target;
    setCheckoutForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //update the checkout page
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateCheckoutPage(checkout_id, {
        time_left: checkoutForm?.time_left,
        show_tickets_left_count: checkoutForm?.show_tickets_left_count,
      });
      displaySuccessToast("Data added successfully");
    } catch (error) {
      console.log("error occurred:", error);
      displayErrorToast("Some thing is wrong");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Checkout settings" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_checkout"} />

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="checkout_settings"
                      method="post"
                      onSubmit={handleUpdate}
                      noValidate="novalidate"
                    >
                      <div id="form">
                        <div className="form-group">
                          <label
                            htmlFor="form_checkout_timeleft"
                            className="required"
                          >
                            Timeleft
                          </label>
                          <small
                            id="form_checkout_timeleft_help"
                            className="form-text text-muted mb-3"
                          >
                            <i className="fas fa-info-circle text-primary mr-1" />
                            Number of seconds before the reserved tickets are
                            released if the order is still awaiting payment
                          </small>
                          <input
                            type="text"
                            id="form_checkout_timeleft"
                            name="time_left"
                            required="required"
                            className="touchspin-integer form-control"
                            data-min={100}
                            data-max={3600}
                            value={checkoutForm?.time_left || ""}
                            onChange={(e) => handleInputChanging(e)}
                          />
                        </div>
                        <fieldset className="form-group">
                          <legend className="col-form-label required">
                            Show tickets left count on cart modal
                          </legend>
                          <div id="form_show_tickets_left_on_cart_modal">
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_tickets_left_on_cart_modal_0"
                                name="show_tickets_left_count"
                                required="required"
                                className="custom-control-input"
                                value={"1"}
                                checked={
                                  checkoutForm?.show_tickets_left_count === true
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_tickets_left_on_cart_modal_0"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {" "}
                              <input
                                type="radio"
                                id="form_show_tickets_left_on_cart_modal_1"
                                name="show_tickets_left_count"
                                required="required"
                                className="custom-control-input"
                                value={"0"}
                                checked={
                                  checkoutForm?.show_tickets_left_count ===
                                  false
                                }
                                onChange={handleEnableRadioInput}
                              />
                              <label
                                className="custom-control-label required"
                                htmlFor="form_show_tickets_left_on_cart_modal_1"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                        <div className="form-group">
                          <button
                            type="submit"
                            id="form_save"
                            name="form[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="form__token"
                          name="form[_token]"
                          defaultValue="S8sDVARyvUHuIizhmbdqdRQfZ6bKo1JLkwngp8e_DrQ"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
