import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./featured_blogs.css";
import { useBlogs } from "../../store/blog_store";
import { useSettings } from "../../store/setting_store";

export default function FeaturedBlogs() {
  var screen_size = window.innerWidth;
  var isMobile = screen_size < 480 ? true : false;
  var isTablet = screen_size < 720 ? true : false;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 5,
    slidesToScroll: 1,
  };

  const getVisibleBlogs = useBlogs((state) => state.getVisibleBlogs);
  const [blogs, setBlogs] = useState([]);
  const home_page = useSettings((state) => state.home_page);

  useEffect(() => {
    const fetchBlogs = async () => {
      const visibleBlogs = await getVisibleBlogs();
      setBlogs(visibleBlogs);
    };
    fetchBlogs();
  }, [blogs.length]);

  //controlling the number of blogs to show
  const maxBlogs = home_page?.blog_posts_show;
  const blogsToShow = blogs?.slice(0, maxBlogs) ?? [];

  if (!blogsToShow.length) {
    return null;
  }

  return (
    <section className="section-content padding-y-lg bg-white">
      <div className="container">
        {/* BLOG POSTS HEADER */}
        <header className="section-headin mb-5">
          <h4 className="title-section text-center text-muted text-uppercase">
            Lastest blog posts
          </h4>
        </header>

        <Slider {...settings}>
          {blogsToShow?.map((blog, index) => {
            return (
              <div
                className="card mb-4 shadow-none blog-post-card pr-2 "
                key={blog?.id}
              >
                <a href={"/blog_details/" + blog?.id}>
                  <img
                    className="card-img-top img-lazy-load b-loaded blog-image"
                    src={blog?.main_image}
                    alt={blog?.name}
                    height={250}
                  />
                </a>
                <div className="card-body">
                  <p className="card-text">
                    <small className="text-muted mr-3">
                      <a href="/en/blog/marketing" className="text-dark">
                        <i className="fas fa-sitemap mr-1" />
                        {blog.category?.label}
                      </a>
                    </small>
                    <small className="text-muted">
                      <i className="fas fa-book-reader mr-1" />{" "}
                      {blog?.read_time} min read
                    </small>
                  </p>
                  <h5 className="card-title">
                    <a
                      href="/en/blog-article/5-ways-event-managers-can-increase-event-registration"
                      className="text-dark"
                    >
                      {blog?.name}
                    </a>
                  </h5>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}
