import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Sidenavbar from "./components/sidenavbar";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import Loader from "../../components/loader/loader";
import TextEditor from "../../components/text_editor/text_editor";
import { getUserDetails } from "../../services/auth";
import { isMobile } from "react-device-detect";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { userStore } from "../../store/user_store";
import { useVenues } from "../../store/venues_store";
import { Timestamp } from "firebase/firestore";
import AdminSideNav from "../admin_dashboard/components/admin_side_nav";
import { useEvents } from "../../store/event_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Addnewvenue() {
  // ZUSTAND VARIABLES
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);
  const isAttendee = userStore((state) => state.isAttendee);
  const addVenue = useVenues((state) => state.addVenue);
  const getAmmenitiesofStatusVisible = useVenues(
    (state) => state.getVisibleAmmenities
  );
  const getVenueTypesofStatusVisible = useVenues(
    (state) => state.getVisibleVenueTypes
  );
  const uploadVenueImage = useVenues((state) => state.uploadVenueImage);

  //getting visible countries
  const getVisibleCountries = useEvents((state) => state.getVisibleCountries);
  const layout_page = useSettings((state) => state.layout_page);
  const organizers = userStore((state) => state.organizers);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [venue_type, setVenueType] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [seated_guests, setSeatedGuests] = useState("");
  const [standing_guests, setStandingGuests] = useState("");
  const [neighborhoods, setNeighborhoods] = useState("");
  const [pricing, setPricing] = useState("");
  const [availability, setAvailability] = useState("");
  const [food_and_beverage_details, setFoodAndBeverageDetails] = useState("");
  const [showQuoteFormOnVenuePage, setShowQuoteFormOnVenuePage] =
    useState(true);
  const [contact_email, setContactEmail] = useState("");
  const [street_address, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [showMapWithAddress, setShowMapWithAddress] = useState(true);
  const [venue_images, setVenueImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [organizerList, setOrganizersList] = useState([]);
  const [organizer, setOrganizer] = useState("");
  const [organizerId, setOrganizerId] = useState(null);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [numFieldsets, setNumFieldsets] = useState(0);

  const [amenitiesList, setAmenitiesList] = useState([]);
  const [venueTypesList, setVenuetypeList] = useState([]);
  /////////////FETCHING COUNTRIES//////////////////////
  /////////////////////////////////////////////////////
  const [countriesList, setCountriesList] = useState([]);

  //getting data from database
  useEffect(() => {
    async function fetchData() {
      //GETTING AMENITIES
      const amenitydata = await getAmmenitiesofStatusVisible();
      //GETTING AMMENITY NAMES
      const amenitynames = amenitydata.map((amenity) => amenity.name);
      setAmenitiesList(amenitynames);
    }

    // GETTING THE COUNTRIES
    const get_countries_list = async () => {
      const returnedCountries = await getVisibleCountries();
      const countries = [];
      for (let i = 0; i < returnedCountries.length; i++) {
        countries.push({
          value: returnedCountries[i].id,
          label: returnedCountries[i].name,
        });
      }
      setCountriesList(countries);
    };
    // GETTING VENUE TYPES
    const get_venue_types = async () => {
      const venuetypeData = await getVenueTypesofStatusVisible();
      const venuetypes = [];
      for (let i = 0; i < venuetypeData.length; i++) {
        venuetypes.push({
          value: venuetypeData[i].id,
          label: venuetypeData[i].venue_type,
        });
      }
      setVenuetypeList(venuetypes);
    };

    //get organizers
    const get_organizers_list = async () => {
      const organizersData = organizers.map((organizer) => {
        return {
          value: organizer.id,
          label: organizer.organizer_name,
        };
      });
      setOrganizersList(organizersData);
    };
    get_organizers_list();
    get_countries_list();
    get_venue_types();
    fetchData();
  }, []);

  //handling the organizer id on admin side
  const handleOrganizer = (e) => {
    setOrganizer(e);
    setOrganizerId(e.value);
  };

  // HANDLING UPLOADING THE BLOG POST
  const handleAddVenue = async (e) => {
    e.preventDefault();

    const venueImages = [];
    console.log("THE LENGTH OF VENUE IMAGES: " + venue_images.length);

    for (var i = 0; i < venue_images.length; i++) {
      const imageUrl = await uploadVenueImage(venue_images[i]);
      console.log("THE VENUE IMAGE URL: " + imageUrl);
      venueImages.push(imageUrl);
    }

    const errors = validateForm();

    // GETTING THE CURRENT USER ID
    const userDetails = await getUserDetails();

    if (!userDetails) {
      navigate("/sign_in");
    }

    const userId = userDetails?.user_id;

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      const venue_data = {
        id: null,
        name: name,
        venue_type: venue_type,
        venue_images: venueImages,
        description: description,
        organizer_id: isOrganizer ? userId : organizerId,
        country: country,
        amenities: amenities,
        seated_guests: seated_guests,
        standing_guests: standing_guests,
        neighborhoods: neighborhoods,
        food_and_beverage_details: food_and_beverage_details,
        showMapWithAddress: showMapWithAddress,
        showQuoteFormOnVenuePage: showQuoteFormOnVenuePage,
        street_address: street_address,
        latitude: "0.23",
        longitude: "0.588",
        contact_email: contact_email,
        pricing: pricing,
        availability: availability,
        city: city,
        listedondirectory: true,
        isHidden: false,
        added_on: Timestamp.fromDate(new Date()),
        updated_on: Timestamp.fromDate(new Date()),
      };

      const venueResponse = await addVenue(venue_data);

      // AFTER GETTING THE RESPONSE
      if (venueResponse) {
        setIsLoading(false);
        displaySuccessToast("Venue added successfully");
        if (isOrganizer) {
          navigate("/myvenues");
        } else {
          navigate("/manage_venues");
        }
      } else {
        setIsLoading(false);
        displayErrorToast("Something went wrong adding a venue");
        console.log("SOMETHING WENT WRONG ADDING A VENUE");
      }
    } else {
      setErrors(errors);
      displayErrorToast("Please fill in all the required fields");
    }
    setIsLoading(false);
  };

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  // Handling venue image capture
  const handleVenueImages = async (e) => {
    const file = e.target.files[0];
    // Create a new array with the existing images and the new file
    const images = [...venue_images, file];
    // Set the venue images
    setVenueImages(images);
  };

  // HANDLE DESCRIPTION
  const handleDescription = (newValue) => {
    console.log("THE DESCRIPTION: " + newValue);
    setDescription(newValue);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING VENUE NAME
    if (!name) {
      errors.name = "Please select the venue name";
    }

    // VALIDATING VENUE DESCRIPTION
    if (!description) {
      errors.description = "Please select the venue description";
    }

    // VALIDATING VENUE TYPE
    if (!venue_type) {
      errors.venue_type = "Please select the venue type";
    }

    // VALIDATING VENUE STREET ADDRESS
    if (!street_address) {
      errors.street_address = "Please select the street address";
    }

    // VALIDATING VENUE COUNTRY
    if (!country) {
      errors.country = "Please select the country ";
    }

    // VALIDATING VENUE CITY
    if (!city) {
      errors.city = "This Option is required";
    }

    //validating organizer
    if (!organizer) {
      errors.organizer = "Please select the organizer";
    }

    return errors;
  }

  const handleAddFieldset = (e) => {
    e.preventDefault();
    setNumFieldsets(numFieldsets + 1);
  };

  const handleRemoveFieldset = (e) => {
    e.preventDefault();
    if (numFieldsets > 0) {
      setNumFieldsets(numFieldsets - 1);
    }
  };

  const fieldsets = [];
  // METHOD HANDLE AMENITIES
  const handleAmenities = (amenity) => {
    const newAmenities = [...amenities, amenity.target.value];
    setAmenities(newAmenities);
  };

  for (let i = 0; i < numFieldsets; i++) {
    fieldsets.push(
      <>
        <fieldset className="form-group" style={{}} key={i}>
          <div id={"venue_images" + i}>
            <fieldset className="form-group">
              <legend className="col-form-label">Venue Image</legend>
              <div className="vich-image">
                <div className="custom-file">
                  {/* PICKING ANOTHER EVENT IMAGE */}
                  <input type="file" onChange={handleVenueImages} />
                </div>
              </div>
            </fieldset>
          </div>

          {/* REMOVE IMAGE BUTTON */}
          <div onClick={handleRemoveFieldset}>
            <a href="#" className="mr-3 btn btn-outline-dark btn-sm ">
              <i className="fas fa-minus-square" /> Remove
            </a>
          </div>
        </fieldset>
      </>
    );
  }

  if (isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />
        <BreadCrump title={"Add a new venue"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {isOrganizer ? (
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar />}
                </aside>
              ) : (
                <AdminSideNav />
              )}
              {/* MAIN CONTNET */}

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="venue"
                      method="post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleAddVenue}
                    >
                      {/* TRANSLATIONS PART */}

                      <div className=" tab-content">
                        {/* ENGLISH */}
                        <div
                          id="venue_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          <div id="venue_translations_en">
                            {/* VENUE NAME */}
                            {errors.name && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.name}
                              </span>
                            )}
                            <div className="form-group">
                              <label
                                htmlFor="venue_translations_en_name"
                                className="required"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                id="venue_translations_en_name"
                                name="venue[translations][en][name]"
                                required="required"
                                maxLength={50}
                                pattern=".{1,}"
                                className={
                                  errors.name
                                    ? " border border-danger form-control"
                                    : "form-control"
                                }
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>

                            {/* ORGANIZER  */}
                            {isAdmin && (
                              <div className="form-group">
                                <label className="required">Organizer</label>

                                {errors.organizer && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.organizer}
                                  </span>
                                )}
                                <SearchableDropdown
                                  value={organizer}
                                  options={organizerList}
                                  onChange={handleOrganizer}
                                  isMulti={false}
                                  required="required"
                                />
                              </div>
                            )}

                            {/* DESCRIPTION */}
                            {errors.description && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.description}
                              </span>
                            )}
                            <div className="form-group">
                              <label
                                htmlFor="venue_translations_en_description"
                                className="required"
                              >
                                Description
                              </label>

                              <TextEditor
                                value={description}
                                onChange={(value) => handleDescription(value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* TYPES OF VENUE DROPDOWN */}
                      {errors.venue_type && (
                        <span className="text-sm font-weight-bold text-danger">
                          {errors.venue_type}
                        </span>
                      )}
                      <div className="form-group">
                        <label className="required" htmlFor="venue_type">
                          Type
                        </label>
                        <SearchableDropdown
                          isMulti={false}
                          onChange={(e) => setVenueType(e)}
                          options={venueTypesList}
                          placeholder={"Select Venue Type"}
                          value={venue_type}
                        />
                      </div>

                      {/* AMENITIES SECTION */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">Amenities</legend>
                        <div id="venue_amenities">
                          {amenitiesList.map((amenity, index) => (
                            <div
                              className="custom-control custom-checkbox custom-control-inline"
                              key={index}
                            >
                              <input
                                type="checkbox"
                                id={"venue_amenities_" + index}
                                name={index}
                                className="custom-control-input"
                                value={amenity}
                                onChange={handleAmenities}
                              />

                              <label
                                className="custom-control-label"
                                for={"venue_amenities_" + index}
                              >
                                {amenity}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>

                      {/* SEATED GUESTS */}
                      <div className="form-group">
                        <label htmlFor="venue_seatedguests">
                          Seated guests number
                        </label>
                        <input
                          type="text"
                          id="venue_seatedguests"
                          name="venue[seatedguests]"
                          className="touchspin-integer form-control"
                          data-max={100000}
                          onChange={(e) => setSeatedGuests(e.target.value)}
                        />
                      </div>

                      {/* STANDING GUESTS NUMBER */}
                      <div className="form-group">
                        <label htmlFor="venue_standingguests">
                          Standing guests number
                        </label>
                        <input
                          type="text"
                          id="venue_standingguests"
                          name="venue[standingguests]"
                          className="touchspin-integer form-control"
                          data-max={100000}
                          onChange={(e) => setStandingGuests(e.target.value)}
                        />
                      </div>

                      {/* NEIGHBORHOODS */}
                      <div className="form-group">
                        <label htmlFor="venue_neighborhoods">
                          Neighborhoods
                        </label>
                        <input
                          type="text"
                          id="venue_neighborhoods"
                          name="venue[neighborhoods]"
                          className="form-control"
                          onChange={(e) => setNeighborhoods(e.target.value)}
                        />
                      </div>

                      {/* PRICING */}
                      <div className="form-group">
                        <label htmlFor="venue_pricing">Pricing</label>
                        <textarea
                          id="venue_pricing"
                          name="venue[pricing]"
                          className="form-control"
                          defaultValue={""}
                          onChange={(e) => setPricing(e.target.value)}
                        />
                      </div>

                      {/* AVAILABILITY */}
                      <div className="form-group">
                        <label htmlFor="venue_availibility">Availibility</label>
                        <textarea
                          id="venue_availibility"
                          name="venue[availibility]"
                          className="form-control"
                          defaultValue={""}
                          onChange={(e) => setAvailability(e.target.value)}
                        />
                      </div>

                      {/* FOOD AND BEVERAGE DETAILS */}
                      <div className="form-group">
                        <label htmlFor="venue_foodbeverage">
                          Food and beverage details
                        </label>
                        <textarea
                          id="venue_foodbeverage"
                          name="venue[foodbeverage]"
                          className="form-control"
                          defaultValue={""}
                          onChange={(e) =>
                            setFoodAndBeverageDetails(e.target.value)
                          }
                        />
                      </div>

                      {/* SHOW QUOTE ON THE VENUE PAGE */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Show the quote form on the venue page
                        </legend>
                        <div id="venue_quoteform">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="venue_quoteform_0"
                              name="venue[quoteform]"
                              required="required"
                              className="custom-control-input"
                              defaultValue={0}
                              onChange={(e) =>
                                setShowQuoteFormOnVenuePage(false)
                              }
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="venue_quoteform_0"
                            >
                              No
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="venue_quoteform_1"
                              name="venue[quoteform]"
                              required="required"
                              className="custom-control-input"
                              defaultValue={1}
                              defaultChecked="checked"
                              onChange={(e) =>
                                setShowQuoteFormOnVenuePage(true)
                              }
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="venue_quoteform_1"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* EMAIL FIELD */}
                      <div className="form-group">
                        <label htmlFor="venue_contactemail">
                          Contact email
                        </label>
                        <small
                          id="venue_contactemail_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{
                              color: layout_page?.primary_color,
                            }}
                          />
                          This email address will be used to receive the quote
                          requests, make sure to mention it if you want to show
                          the quote form
                        </small>
                        <input
                          type="text"
                          id="venue_contactemail"
                          name="venue[contactemail]"
                          aria-describedby="venue_contactemail_help"
                          className="form-control"
                          onChange={(e) => setContactEmail(e.target.value)}
                        />
                      </div>

                      {/* STREET ADDRESS */}
                      {errors.street_address && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.street_address}
                        </span>
                      )}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="venue_street" className="required">
                              Street address
                            </label>
                            <input
                              type="text"
                              id="venue_street"
                              name="venue[street]"
                              required="required"
                              className={
                                errors.street_address
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              onChange={(e) => setStreetAddress(e.target.value)}
                            />
                          </div>

                          {/* CITY */}
                          {errors.city && (
                            <span className="text-sm font-weight-bold text-danger">
                              {errors.city}
                            </span>
                          )}
                          <div className="form-group">
                            <label htmlFor="venue_city" className="required">
                              City
                            </label>
                            <input
                              type="text"
                              id="venue_city"
                              name="venue[city]"
                              required="required"
                              className={
                                errors.city
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>

                          {/* COUNTRY SELECTION */}
                          {errors.country && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.country}
                            </span>
                          )}
                          <div className="form-group">
                            <label className="required" htmlFor="venue_country">
                              Country
                            </label>

                            <SearchableDropdown
                              options={countriesList}
                              isMulti={false}
                              onChange={(e) => setCountry(e)}
                              placeholder="Select Country"
                              value={country}
                            />
                          </div>

                          {/* MAP SECTION */}
                          <fieldset className="form-group">
                            <legend className="col-form-label required">
                              Show the map along with the address on the venue
                              page and event page
                            </legend>
                            <div id="venue_showmap">
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="venue_showmap_0"
                                  name="venue[showmap]"
                                  required="required"
                                  className="custom-control-input"
                                  defaultValue={0}
                                />
                                <label
                                  className="custom-control-label required"
                                  htmlFor="venue_showmap_0"
                                >
                                  No
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="venue_showmap_1"
                                  name="venue[showmap]"
                                  required="required"
                                  className="custom-control-input"
                                  defaultValue={1}
                                  defaultChecked="checked"
                                />
                                <label
                                  className="custom-control-label required"
                                  htmlFor="venue_showmap_1"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-lg-6">
                          <div className="sticky-top sticky-sidebar pt-0">
                            <iframe
                              title={name}
                              className="w-100 border-0 venue-map"
                              height={300}
                              src={`https://maps.google.com/maps?q=${name}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            />
                          </div>
                        </div>
                      </div>

                      <fieldset className="form-group">
                        <legend className="col-form-label">Images</legend>
                        <div id="venue_images" className="form-collection">
                          {fieldsets}

                          <a
                            onClick={handleAddFieldset}
                            href="#"
                            className="mr-3 btn btn-outline-dark btn-sm"
                          >
                            <i className="fas fa-plus-square" /> Add
                          </a>
                        </div>
                      </fieldset>

                      {/* SAVE BUTTON */}
                      <div className="form-group">
                        <button
                          type="submit"
                          id="venue_save"
                          name="venue[save]"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
