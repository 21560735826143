import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Sidenavbar from "./components/sidenavbar";
import BreadCrumpOrg from "./components/breadcrumporganizer";
import { Link, useNavigate } from "react-router-dom";
import AttendeeSummary from "./components/AttendeeSummary";
import { isMobile } from "react-device-detect";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { useEvents } from "../../store/event_store";
import { ordersStore } from "../../store/orders_store";
import { useSettings } from "../../store/setting_store";
import OrganizerGraph from "./components/OrganizerGraph";
import { formatNumberWithCommas } from "../../services/helpers";
import { auth } from "../../Firebase";
export default function Dashboardorganizer() {
  const navigate = useNavigate();

  const user = userStore((state) => state.user);
  const isOrganizer = userStore((state) => state.isOrganizer);
  //get upcoming events of the organizer
  const getOrganizerUpcomingEvents = useEvents(
    (state) => state.getOrganizerUpcomingEvents
  );
  //get past events of organizer
  const getOrganizerPastEvents = useEvents(
    (state) => state.getOrganizerPastEvents
  );
  // const organizerPastEvents = useEvents((state) => state.organizerPastEvents);
  //get all organizer events.
  const getOrganizerEvents = useEvents((state) => state.getOrganizerEvents);
  // const organizerEvents = useEvents((state) => state.organizerEvents);
  const layout_page = useSettings((state) => state.layout_page);

  //getting organizer published events
  const getPublishedOrganizerEvents = useEvents(
    (state) => state.getPublishedOrganizerEvents
  );
  //getting organizer tickets sold
  const getTotalOrganizerTicketsSold = ordersStore(
    (state) => state.getTotalOrganizerTicketsSold
  );
  const organizerTickets = ordersStore((state) => state.organizerTickets);
  const organizerOrders = ordersStore((state) => state.organizerOrders);
  const organizerPastEvents = useEvents((state) => state.organizerPastEvents);

  //getting the organizer orders
  const getOrganizerOrders = ordersStore((state) => state.getOrganizerOrders);
  // STATE VARIABLES
  const [organizerPublishedEvents, setOrganizerPublishedEvents] = useState([]);
  const [organizerUpcomingEvents, setOrganizerUpcomingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [organizerEvents, setOrganizerEvents] = useState();

  // FUNCTION TO GET THE DETAILS OF THE USER
  useEffect(() => {
    // FETCHING THE ORGANIZER DAT
    const fetchData = async () => {
      // console.log("THE ORGANIZER ID IS ", user?.user_id);
      // GETTING ALL ORGANIZER EVENTS
      const eventsOfOrganizer = await getOrganizerEvents(user?.user_id);
      // console.log("THE ORGANIZER EVENTS LENGTH:", organizerEvents);
      setOrganizerEvents(eventsOfOrganizer);
      //GETTING UPCOMING EVENTS FOR THIS ORGANIZER
      const upcomming = await getOrganizerUpcomingEvents(user?.user_id);
      setOrganizerUpcomingEvents(upcomming);
      // console.log("upcoming events for this organizer:", upcomming);
      //GETTING PAST EVENTS FROM ALL ORGANIZER EVENTS(eventdata)
      await getOrganizerPastEvents(user?.user_id);
      // console.log("Length of past events for this organizer:", pastEvent);
      //GETTING ORGANIZER PUBLISHED EVENTS
      const published_events = await getPublishedOrganizerEvents(user?.user_id);
      // console.log("the published organizer events are:", published_events);
      setOrganizerPublishedEvents(published_events);
      //GETTING ALL THE ORDERS OF THE ORGANIZER
      await getOrganizerOrders(user?.user_id);
      // ticket quantities for the organizer
      await getTotalOrganizerTicketsSold(user?.user_id);
    };
    fetchData();
    setIsLoading(false);
  }, []);

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null; // Return null to prevent rendering the dashboard
  }

  // LOADING
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <BreadCrumpOrg title={""} />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 pt-3 pt-lg-0">
              {!isMobile && <Sidenavbar currentPage={"dashboard_organizer"} />}
            </aside>

            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <header className="section-heading mb-3">
                    <h4 className="title-section float-left">
                      <i className="fas fa-calendar-alt" /> Events summary
                    </h4>
                    <Link
                      style={{ color: layout_page?.primary_color }}
                      to="/myevents"
                      className="float-right"
                    >
                      <i className="fas fa-cog" /> My events
                    </Link>
                    <div className="clearfix" />
                  </header>
                  <div className="row mb-3 pl-2 pr-2 d-flex">
                    {/* NUMBER OF EVENTS ADDED */}
                    <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                      <div
                        className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-calendar-alt" />
                        <h4>
                          {formatNumberWithCommas(organizerEvents?.length)}
                        </h4>
                        <h5 className="mb-0">Events added</h5>
                      </div>
                    </div>

                    {/* NUMBER OF PUBLISHED EVENTS */}
                    <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                      <div
                        className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-calendar-alt" />
                        <h4>
                          {formatNumberWithCommas(
                            organizerPublishedEvents?.length
                          )}
                        </h4>
                        <h5 className="mb-0">Published events</h5>
                      </div>
                    </div>

                    {/* NUMBER OF UPCOMING EVENTS */}
                    <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                      <div
                        className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-calendar-alt" />
                        <h4>
                          {formatNumberWithCommas(
                            organizerUpcomingEvents?.length
                          )}
                        </h4>
                        <h5 className="mb-0">Upcoming events</h5>
                      </div>
                    </div>

                    {/* NUMBER OF PAST EVENTS */}
                    <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
                      <div
                        className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: layout_page?.primary_color }}
                      >
                        <i className="fas fa-calendar-alt" />
                        <h4>
                          {formatNumberWithCommas(organizerPastEvents?.length)}
                        </h4>
                        <h5 className="mb-0">Past events</h5>
                      </div>
                    </div>
                  </div>

                  <>
                    <AttendeeSummary
                      organizerorders={organizerOrders}
                      totalTickets={organizerTickets}
                    />

                    <OrganizerGraph user_id={user?.user_id} />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
