import React, { useEffect, useState } from "react";
import { FaImage, FaUserAltSlash } from "react-icons/fa";
import { MdRequestQuote } from "react-icons/md";
import { useEvents } from "../../../store/event_store";
import {
  convertTimestampToDateTime,
  formatDate,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";

const DetailsModal = ({ event_id }) => {
  const getSpecificEvent = useEvents((state) => state.getEvent);
  const getOrganizerDetails = userStore((state) => state.getOrganizerDetail);
  const getEventsFavorites = useEvents((state) => state.getEventsFavorites);

  const [event, setEvent] = useState([]);
  const [organizer, setOrganizer] = useState([]);
  const [eventFavorites, setEventFavorites] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //getting an event
      const specificEvent = await getSpecificEvent(event_id);
      console.log("the event is:", specificEvent);
      setEvent(specificEvent);
      //getting organizer details
      const organizerDetails = await getOrganizerDetails(event_id);
      console.log("the orgainzer is:", organizerDetails);
      setOrganizer(organizerDetails);
      //getting followers of the event
      const eventsfavorites = await getEventsFavorites(event_id);
      console.log("the favorites of the event is:", eventsfavorites);
      setEventFavorites(eventsfavorites);
    };
    fetchData();
  }, [event_id]);

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <div
        className=" cursor-pointer"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        <div className="d-flex gap-1" style={{ color: "gray" }}>
          <MdRequestQuote />
          <h6>Details</h6>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                {event?.name}
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Custom content for the modal body */}
              <div className="d-flex gap-5 row">
                <div className="table-responsive col-md-6">
                  <table class="table table-striped table-hover">
                    <div className=" d-flex gap-1">
                      <i className="dropdown-icon fas fa-file-alt fa-fw text-muted" />{" "}
                      <h6>General Information</h6>
                    </div>
                    <tbody>
                      <tr className=" text-sm">
                        <th scope="row">Status</th>
                        <td>
                          {event?.visibility ? (
                            <span className="badge badge-success">
                              <i className="fas fa-user-check fa-fw" />{" "}
                              {event?.visibility}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              <FaUserAltSlash /> No status
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Title</th>
                        <td> {event?.name} </td>
                      </tr>

                      <tr className=" text-sm">
                        <th scope="row">Organizer</th>
                        <td>{organizer?.organizer_name} </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Reference</th>
                        <td> {event?.id && event?.id.substring(0, 10)} </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Creation Date</th>
                        <td>
                          {" "}
                          {formatDate(
                            convertTimestampToDateTime(event?.created_at)
                          )}{" "}
                        </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Update date</th>
                        <td>
                          {" "}
                          {formatDate(
                            convertTimestampToDateTime(event?.updated_at)
                          )}{" "}
                        </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Views</th>
                        <td>{event?.views} </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Added to favorites by</th>
                        <td> {eventFavorites?.length} User(s)</td>
                      </tr>
                      <tr className="text-sm">
                        <th scope="row">Category</th>
                        <td>
                          {event?.category?.label
                            ? event?.category?.label
                            : "No category selected"}
                        </td>
                      </tr>

                      <tr className="text-sm">
                        <th scope="row">Audiences</th>
                        <td>
                          {Array.isArray(event?.audiences)
                            ? event?.audiences?.join(", ")
                            : "No audiences"}
                        </td>
                      </tr>

                      <tr className=" text-sm">
                        <th scope="row">Country</th>
                        <td>
                          {event?.country?.label
                            ? event?.country?.label
                            : "No country selected"}{" "}
                        </td>
                      </tr>
                      <tr className="text-sm">
                        <th scope="row">Tags</th>
                        <td>
                          {Array.isArray(event?.tags)
                            ? event?.tags?.join(", ")
                            : "No Tags"}
                        </td>
                      </tr>

                      {/* <tr className=" text-sm">
                        <th scope="row">Year</th>
                        <td>
                          {event?.year ? event?.year : "No published year"}{" "}
                        </td>
                      </tr> */}

                      <tr className="text-sm">
                        <th scope="row">Youtube video</th>
                        <td>
                          {event?.video_link ? (
                            <iframe
                              width="560"
                              height="315"
                              src={event?.video_link}
                              title="YouTube Video"
                              allowFullScreen
                            ></iframe>
                          ) : (
                            "Event has no video"
                          )}
                        </td>
                      </tr>

                      <tr className=" text-sm">
                        <th scope="row">Publicly show attendees</th>
                        <td>
                          {" "}
                          {event?.show_attendees === true ? "Yes" : "No"}{" "}
                        </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Allow attendees to leave reviews</th>
                        <td>
                          {event?.enable_reviews === true ? "Yes" : "No"}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* ANOTHER TABLE */}
                <div className="table-responsive col">
                  <table class="table table-striped table-hover">
                    <div className=" d-flex gap-1">
                      <FaImage /> <h6>Images</h6>
                    </div>
                    <tbody>
                      <tr className=" text-sm">
                        <th scope="row">Main Image</th>
                        <td>
                          {event?.main_event_image ? (
                            <img
                              src={event?.main_event_image}
                              alt=""
                              className="w-50"
                            />
                          ) : (
                            <span>No main image for this event</span>
                          )}
                        </td>
                      </tr>
                      <tr className=" text-sm">
                        <th scope="row">Gallery</th>
                        <td>
                          {event?.gallery && event.gallery.length > 0 ? (
                            event.gallery.map((image, index) => (
                              <img
                                key={index}
                                src={image}
                                alt=""
                                className="w-50"
                              />
                            ))
                          ) : (
                            <span>No gallery found</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
