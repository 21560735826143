import React, { useEffect, useState } from "react";

import moment from "moment";
import PopingOutUsers from "./PopingOutUsers";
import Loader from "../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import { FaUserCircle } from "react-icons/fa";

const UsersAdmin = () => {
  const getAllUsersFromDatabase = userStore((state) => state.fetchAllUsers);
  const organizers = userStore((state) => state.filteredOrganizers);

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //fetching users from the database
  useEffect(() => {
    const fetchUsers = async () => {
     var usersResponse =  await getAllUsersFromDatabase();
     setUsers(usersResponse);
    };
    fetchUsers();
  }, []);

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <table className="table table-hover table-vcenter text-nowrap">
        <thead>
          <tr>
            <th className="text-center">
              <i className="fas fa-sitemap" />
            </th>
            <th>Name</th>
            <th>Username / Email</th>
            <th>Registration date</th>
            <th>Last login</th>
            <th>Status</th>
            <th className="text-center">
              <i className="fas fa-cog" />
            </th>
          </tr>
        </thead>
        <tbody>
          {users.slice(0, 3).map((user, index) => (
            <tr key={user.id}>
              {/* USER CATEGORY */}
              <td className="text-sm">{user?.user_type || "N/A"}</td>

              {/* USER NAMES */}
              <td className="text-sm">
                <span className="mr-1">
                  {user?.profile_picture ? (
                    <img
                      src={user?.profile_picture}
                      alt=""
                      className="rounded-pill"
                      style={{ width: "30px" }}
                    />
                  ) : (
                    <FaUserCircle size={30} color="gray" />
                  )}
                </span>
                {user?.firstname} {user?.lastname}
              </td>

              {/* USERNAME / EMAIL */}
              <td className="text-sm">
                {user?.username || "N/A"} / {user?.email || "N/A"}
              </td>

              {/* REGISTRATION DATE */}
              <td className="text-sm">
                {user?.created_at
                  ? formatDate(convertTimestampToDateTime(user?.created_at))
                  : "N/A"}
              </td>

              {/* LAST LOGIN */}
              <td className="text-sm">
                {user?.last_seen_at
                  ? moment(user?.last_seen_at).fromNow()
                  : "N/A"}
              </td>

              {/* STATUS */}
              <td className="text-sm">
                <span className="badge badge-success">
                  <i className="fas fa-user-check fa-fw" /> Enabled
                </span>
              </td>

              <td className="text-center">
                <PopingOutUsers
                  user_id={user.id}
                  setIsLoading={setIsLoading}
                  userType={user?.user_type}
                  organizerID={organizers?.[index]?.id}
                />
              </td>
            </tr>
          ))}

          {/* END OF USERS DISPLAYING */}
        </tbody>
      </table>
    </div>
  );
};

export default UsersAdmin;
