// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTabs-indicator {
  background: transparent !important;
}

.MuiTab-root {
  font-family: Open Sans, Cairo, sans-serif;
  font-size: 0.805rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: #f67611 !important; */
}

.Mui-selected {
  color: #fff !important;
  background-color: #f67611 !important;
  border-radius: 0.25rem !important;
  margin-right: 1rem !important;
}

`, "",{"version":3,"sources":["webpack://./src/pages/organizer_details/organizer_details.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,yCAAyC;EACzC,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,oCAAoC;EACpC,iCAAiC;EACjC,6BAA6B;AAC/B","sourcesContent":[".MuiTabs-indicator {\r\n  background: transparent !important;\r\n}\r\n\r\n.MuiTab-root {\r\n  font-family: Open Sans, Cairo, sans-serif;\r\n  font-size: 0.805rem;\r\n  font-weight: 400;\r\n  line-height: 1.5;\r\n  /* color: #f67611 !important; */\r\n}\r\n\r\n.Mui-selected {\r\n  color: #fff !important;\r\n  background-color: #f67611 !important;\r\n  border-radius: 0.25rem !important;\r\n  margin-right: 1rem !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
