import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import Container from './Container';
import { convertTimestampToDateTime } from '../../services/helpers';
import { useNavigate } from 'react-router-dom';

export default function Calendar({ events }) {
  const navigate = useNavigate();

  const _events = events.map((event) => {
    const _dates = event.event_dates.map((event_date) => ({
      start: convertTimestampToDateTime(event_date?.start_date),
      end: convertTimestampToDateTime(event_date?.end_date),
    }));

    return { local_event_id: event?.id, title: event?.name, ..._dates[0] };
  });

  const handleEventClick = (clickInfo) => {
    const id = clickInfo.event.extendedProps.local_event_id;
    navigate(`/event_details/${id}`);
  };

  return (
    <Container>
      <FullCalendar
        initialView="dayGridMonth"
        events={_events}
        height="100%"
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        eventClick={handleEventClick}
      />
    </Container>
  );
}
