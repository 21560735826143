import React from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "./tags_input.css";


export default function TagsInputField({tags, handleChange}) {
  return (
    <TagsInput
      value={tags}
      onChange={handleChange}
      inputProps={{ placeholder: "Add tags" }}
      onlyUnique={true}
    />
  );
}
