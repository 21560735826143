/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import SideNav from "../../components/side_nav/side_nav";
import { Link, useNavigate } from "react-router-dom";
import { myContext } from "../../user_context";
import CustomPagination from "../../components/pagination/Pagination";
import { useVenues } from "../../store/venues_store";
import { useSettings } from "../../store/setting_store";
import { tableStore } from "../../store/table_store";
import Loader from "../../components/loader/loader";
import { Button } from "react-bootstrap";

function Venues() {
  const getVenues = useVenues((state) => state.getVisibleVenues);
  const layout_page = useSettings((state) => state.layout_page);
  const [venues, setVenues] = useState([]);
  //getting venue rooms
  const venue_rooms = tableStore((state) => state.venueTableTypes);

  //CHANGING HTML TO PLAIN TEXT
  function converthtmltotext(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  //FETCHING FILTERS
  useEffect(() => {
    const fetchVenues = async () => {
      const v = await getVenues();
      setVenues(v);
    };
    fetchVenues();
  }, [venues?.length]);

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("added_on");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedVenues = venues?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "added_on") {
      const dateA = a.added_on;
      const dateB = b.added_on;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage] = useState(10);
  //get current events
  const indexOfLastVenue = currentPage * venuesPerPage;
  const indexOfFastVenue = indexOfLastVenue - venuesPerPage;
  const current_venues = sortedVenues?.slice(
    indexOfFastVenue,
    indexOfLastVenue
  );

  if (venues?.length === 0) {
    return <Loader />;
  }

  return (
    <div>
      <Header currentPage={"venues"} />
      <BreadCrump title={"Venues"} />

      {/* VENUS MAIN BODY */}
      <section className="section-content bg-white padding-y">
        <div className="container">
          <div className="box shadow-none bg-gray mb-4">
            <div className="row">
              <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0 d-flex justify-content-around">
                <span className="center-lg-y text-muted">
                  {venues?.length} result(s) found
                </span>
              </div>
              <div className="col-sm-12 col-lg-6 offset-lg-3 text-center text-lg-right">
                <label htmlFor="sortable-select" className="display-inline">
                  Sort by
                  <select
                    id="sortable-select"
                    className="form-control display-inline-block bg-white select2 ml-3"
                    data-placeholder="Select an option"
                    style={{ width: "50%" }}
                    value={`${sortCriteria}&direction=${sortDirection}`}
                    onChange={(e) => {
                      const [criteria, direction] =
                        e.target.value.split("&direction=");
                      setSortCriteria(criteria);
                      setSortDirection(direction);
                    }}
                  >
                    <option
                      value={`added_on&direction=desc`}
                      selected={
                        sortCriteria === "added_on" && sortDirection === "desc"
                      }
                    >
                      Creation date (desc)
                    </option>

                    <option
                      value={`added_on&direction=asc`}
                      selected={
                        sortCriteria === "added_on" && sortDirection === "asc"
                      }
                    >
                      Creation date (asc)
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          {/* MAIN EVENTS SECTION */}
          <div className="row">
            <SideNav />

            {/* VENUES */}
            <div className="col-lg-9 order-1 order-lg-2">
              {current_venues.map((venue) => (
                <article key={venues.id} className="card mb-3 card-hover">
                  <div className="card-body">
                    <div className="row">
                      {/* DISPLAYING THE VENUE IMAGE */}
                      <aside className="col-md-3">
                        <div className="img-wrap">
                          <Link to={"/venue_details/" + venue?.id}>
                            <img
                              className="img-lazy-load b-loaded cursor-pointer"
                              src={venue.venue_images[0]}
                              alt={venue.name}
                              height="150px"
                              width={"100%"}
                              style={{
                                objectFit: "cover",
                                color: layout_page?.primary_color,
                              }}
                            />
                          </Link>
                        </div>
                      </aside>
                      <article className="col-md-6 mt-3 mt-md-0">
                        {/* DISPLAYING THE VENUE NAME */}
                        <Link to={"/venue_details/" + venue?.id}>
                          <h4
                            className="title cursor-pointer"
                            style={{
                              objectFit: "cover",
                              color: layout_page?.primary_color,
                            }}
                          >
                            {venue?.name}
                          </h4>
                        </Link>

                        {/* DISPLAYING THE VENUE TYPE */}
                        <p className="text-muted">
                          {venue.venue_type && venue.venue_type.label}
                        </p>

                        {/* DISPLAYING THE DESCRIPTION */}
                        <p className="text-muted">
                          {converthtmltotext(venue?.description)}
                        </p>

                        {/* DISPLAYING THE LOCATION */}
                        <dl>
                          <span className="b mr-1">Location: </span>
                          <span>{venue?.street_address}</span>
                        </dl>
                      </article>

                      <aside className="col-md-3 border-left mt-3 mt-md-0">
                        {/* DISPLAYING AMENITIES */}
                        <div className="action-wrap center-lg-y">
                          <ul className="fa-ul list-lg list-inline">
                            {venue?.amenities?.map((amenity) => (
                              <li className="list-inline-item text-muted mb-5 mr-5">
                                <span
                                  className="fa-li"
                                  data-toggle="tooltip"
                                  title
                                  data-original-title="Spa"
                                >
                                  <i className="fas fa-spa fa-lg" />
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* MORE DETAILS BUTTON */}
                        <div className=" d-flex  gap-5 flex-md-column  ">
                          <Link to={"/venue_details/" + venue?.id}>
                            <button
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                            >
                              <i className="far fa-building" /> More details
                            </button>
                          </Link>
                          {/* only render if the venue has reservation room */}
                          {venue_rooms?.find(
                            (venue_room) =>
                              venue_room?.venue?.value === venue?.id
                          ) && (
                            <Link to={"/venue_details/" + venue?.id}>
                              <Button
                                variant="outline-primary"
                                style={{ padding: "10px 10px" }}
                              >
                                Book Reservation
                              </Button>
                            </Link>
                          )}
                        </div>
                      </aside>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>

          {/* PAGINATION */}
          {venues?.length > 10 && (
            <CustomPagination
              setCurrentPage={setCurrentPage}
              totalPages={Math.ceil(venues?.length / venuesPerPage)}
            />
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Venues;
