/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AttendeeSideNav from "./components/side_bar";
import Alert from "./components/alert";
import Header from "../../components/header/header";
import DashboardBreadcrump from "../../components/dashboard_breadcrump/dashboard_breadcrump";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { userStore } from "./../../store/user_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Following() {
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const getUserFollowing = userStore((state) => state.getFollowing);
  const layout_page = useSettings((state) => state.layout_page);

  const [following, setFollowing] = useState([]);

  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();

  // METHOD TO HANDLE GETTING ORGANIZERS THE USER IS FOLLOWING
  async function handleGetFollowing() {
    const response = await getUserFollowing(user?.user_id);
    console.log(
      "NUMBER OF ORGANIZERS THE USER IS FOLLOWING: " + response?.length
    );
    setFollowing(response);
  }

  useEffect(() => {
    handleGetFollowing();
  }, []);

  // GO TO LOGIN IF USER IS NOT LOGGED IN
  if (!user) {
    navigate("/sign_in");
    return;
  }

  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        {/* IMPORTING THE HEADER */}
        <Header />
        <DashboardBreadcrump title={"Following"} />

        {/* FOLLOWING MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE NAV */}
              {!isMobile && <AttendeeSideNav activeTab={"following"} />}

              {/* IF THE USER IS NOT FOLLOWIGN ANYONE */}
              {following?.length === 0 ? (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <Alert message={"You are not following any organizer"} />
                </div>
              ) : (
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <div className="card box">
                    <div className="card-body">
                      <div className="row">
                        {/* ORGANIZERS FOLLOWING */}
                        {following?.map((organizer, index) => (
                          <div
                            className="col-12 col-sm-6 col-lg-6 mb-3"
                            key={index}
                          >
                            <div className="card">
                              <figure className="itemside">
                                <div className="aside">
                                  <div className="img-wrap img-sm d-flex">
                                    {/* ORGANIZER IMAGE */}
                                    <img
                                      className="img-100-100 align-self-center ml-2"
                                      src={
                                        organizer?.organizer_logo ??
                                        "https://images.unsplash.com/photo-1599009434802-ca1dd09895e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGxhY2Vob2xkZXJ8ZW58MHx8MHx8&w=1000&q=80"
                                      }
                                      alt={organizer?.organizer_name}
                                    />
                                  </div>
                                </div>
                                <figcaption className="p-2 align-self-center">
                                  {/* ORGANIZER NAME */}
                                  <h6 className="title">
                                    {organizer?.organizer_name}
                                  </h6>

                                  {/* LINK TO ORGANIZER DETAILS */}
                                  <Link
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                    to={
                                      "/organizer_details/" +
                                      organizer?.organizer_id
                                    }
                                  >
                                    More details
                                  </Link>
                                </figcaption>
                              </figure>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
