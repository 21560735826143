import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import Loader from "../../components/loader/loader";
import PopingOutUsers from "./PopingOutUsers";
import moment from "moment";
import { FaUserCircle } from "react-icons/fa";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import { userStore } from "../../store/user_store";
import CustomPagination from "../../components/pagination/Pagination";
import { useSettings } from "../../store/setting_store";
import BreadcrumpAdmin from "./components/breadcrump_admin";

export default function ManageUsers() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const getAllUsersFromDatabase = userStore((state) => state.fetchAllUsers);
  const layout_page = useSettings((state) => state.layout_page);
  const allUsers = userStore((state) => state.users);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //SETTING FILTERS
  const [roleFilter, setRoleFilter] = useState("all");
  const [firstNameFilter, setFirstNameFilter] = useState("");
  const [lastNameFilter, setLastNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");
  const [organizerNameFilter, setOrganizerNameFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");

  //fetching users from the database
  useEffect(() => {
    const fetchUsers = async () => {
      var usersResponse = await getAllUsersFromDatabase();
      setUsers(usersResponse);
    };
    fetchUsers();
  }, [allUsers.length]);

  //filtering logic
  const filteredUsers = users.filter((user) => {
    return (
      (roleFilter === "all" || user.user_type === roleFilter) &&
      (!firstNameFilter ||
        (user.firstname &&
          user.firstname
            .toLowerCase()
            .includes(firstNameFilter.toLowerCase()))) &&
      (!lastNameFilter ||
        (user.lastname &&
          user.lastname
            .toLowerCase()
            .includes(lastNameFilter.toLowerCase()))) &&
      (!emailFilter ||
        (user.email &&
          user.email.toLowerCase().includes(emailFilter.toLowerCase()))) &&
      (!usernameFilter ||
        (user.username &&
          user.username
            .toLowerCase()
            .includes(usernameFilter.toLowerCase()))) &&
      (!organizerNameFilter ||
        (user.organizer &&
          user.organizer
            .toLowerCase()
            .includes(organizerNameFilter.toLowerCase()))) &&
      (activeFilter === "all" ||
        (activeFilter === "active" &&
          (user.status === "active" ||
            user.status === "" ||
            user.status === null)) ||
        (activeFilter === "disabled" && user.status === "disabled"))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState(
    "created_at",
    "last_seen_at"
  );
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedUsers = filteredUsers.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at" || sortCriteria === "last_seen_at") {
      const dateA = a[sortCriteria];
      const dateB = b[sortCriteria];
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      // Handle other criteria sorting here
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(50);
  //get current events
  const indexOfLastusers = currentPage * usersPerPage;
  const indexOfFastusers = indexOfLastusers - usersPerPage;
  const current_users = sortedUsers?.slice(indexOfFastusers, indexOfLastusers);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />
        {/* ADMIN DASHBOARD BREADCRUMP */}
        <BreadcrumpAdmin title="Manage users" />

        {/* ADMIN DASHBOARD MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_users"} />
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-4 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {users.length} result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at-desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Registration date (desc)
                          </option>
                          <option
                            value={`created_at-asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Registration date (asc)
                          </option>
                          <option
                            value={`last_seen_at-desc`}
                            selected={
                              sortCriteria === "last_seen_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Last login (desc)
                          </option>
                          <option
                            value={`last_seen_at-asc`}
                            selected={
                              sortCriteria === "last_seen_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Last login (asc)
                          </option>
                        </select>
                      </label>
                    </div>

                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="role">Role</label>
                            <select
                              id="role"
                              name="role"
                              className="form-control bg-white select2"
                              value={roleFilter}
                              onChange={(e) => setRoleFilter(e.target.value)}
                            >
                              <option value="all">All</option>
                              <option value="organizer">Organizer</option>
                              <option value="attendee">Attendee</option>
                            </select>
                          </div>

                          {/* SEARCH USING THE ORGANIZER NAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="organizer">Organizer name</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="organizer"
                              name="organizer"
                              value={organizerNameFilter}
                              onChange={(e) =>
                                setOrganizerNameFilter(e.target.value)
                              }
                            />
                          </div>

                          {/* SEARCH BY USERNAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="username">Username</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="username"
                              name="username"
                              value={usernameFilter}
                              onChange={(e) =>
                                setUsernameFilter(e.target.value)
                              }
                            />
                          </div>

                          {/* SEARCH BY EMAIL */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="email"
                              name="email"
                              value={emailFilter}
                              onChange={(e) => setEmailFilter(e.target.value)}
                            />
                          </div>
                          {/* SEARCH BY FIRST NAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="firstname">First name</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="firstname"
                              name="firstname"
                              value={firstNameFilter}
                              onChange={(e) =>
                                setFirstNameFilter(e.target.value)
                              }
                            />
                          </div>
                          {/* SEARCH BY LAST NAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="lastname">Last name</label>
                            <input
                              type="text"
                              className="form-control mr-sm-2 mb-2 mb-sm-0 bg-white"
                              id="lastname"
                              name="lastname"
                              value={lastNameFilter}
                              onChange={(e) =>
                                setLastNameFilter(e.target.value)
                              }
                            />
                          </div>
                          {/* ///////////////// */}

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="enabled-filter-all"
                                type="radio"
                                name="all"
                                value="all"
                                className="custom-control-input"
                                checked={activeFilter === "all"}
                                onChange={() => setActiveFilter("all")}
                              />
                              <label
                                htmlFor="enabled-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="active-filter-yes"
                                type="radio"
                                name="active"
                                value="active"
                                className="custom-control-input"
                                checked={activeFilter === "active"}
                                onChange={() => setActiveFilter("active")}
                              />
                              <label
                                htmlFor="active-filter-yes"
                                className="custom-control-label"
                              >
                                Enabled only
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="disabled-filter-yes"
                                type="radio"
                                name="diasbled"
                                value="disabled"
                                className="custom-control-input"
                                checked={activeFilter === "disabled"}
                                onChange={() => setActiveFilter("disabled")}
                              />
                              <label
                                htmlFor="disabled-filter-yes"
                                className="custom-control-label"
                              >
                                Disabled only
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th className="text-center">
                                <i className="fas fa-sitemap" />
                              </th>
                              <th>Name</th>
                              <th>Username / Email</th>
                              <th>Registration date</th>
                              <th>Last login</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_users.map((user, index) => {
                              return (
                                <tr key={user?.user_id}>
                                  {/* USER CATEGORY */}
                                  <td className=" text-sm">
                                    {user?.user_type}
                                  </td>

                                  {/* USER NAMES */}
                                  <td className=" text-sm">
                                    <span className="mr-1">
                                      {user?.profile_picture ? (
                                        <img
                                          src={user?.profile_picture}
                                          alt=""
                                          className="rounded-pill"
                                          style={{ width: "30px" }}
                                        />
                                      ) : (
                                        <FaUserCircle size={30} color="gray" />
                                      )}
                                    </span>
                                    {user?.firstname}
                                    {""} {user?.lastname}{" "}
                                  </td>
                                  {/* USERNAME / EMAIL */}
                                  <td className=" text-sm">
                                    {user?.username} / {user?.email}
                                  </td>
                                  {/* REGISTRATION DATE */}
                                  <td className=" text-sm">
                                    {formatDate(
                                      convertTimestampToDateTime(
                                        user?.created_at
                                      )
                                    )}
                                  </td>

                                  {/* LAST LOGIN */}
                                  <td className="text-sm">
                                    {user?.last_seen_at
                                      ? moment(user?.last_seen_at).fromNow()
                                      : "N/A"}
                                  </td>

                                  {/* STATUS */}
                                  <td className="text-sm">
                                    {user?.status !== "disabled" ? (
                                      <span className="badge badge-success">
                                        <i className="fas fa-user-check fa-fw" />{" "}
                                        Enabled
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        <i class="fas fa-user-slash fa-fw"></i>{" "}
                                        Disabled
                                      </span>
                                    )}
                                  </td>

                                  <td className=" text-center">
                                    <PopingOutUsers
                                      update_date={formatDate(
                                        convertTimestampToDateTime(
                                          user?.updated_at
                                        )
                                      )}
                                      last_login={
                                        user && user?.last_seen_at
                                          ? moment(user?.last_seen_at).fromNow()
                                          : "N/A"
                                      }
                                      registration_date={formatDate(
                                        convertTimestampToDateTime(
                                          user?.created_at
                                        )
                                      )}
                                      firstname={user?.firstname}
                                      user_name={user?.username}
                                      user_email={user?.email}
                                      user_id={user?.user_id}
                                      setIsLoading={setIsLoading}
                                      userType={user?.user_type}
                                      status={user?.status}
                                    />
                                  </td>
                                </tr>
                              );
                            })}

                            {/* END OF USERS DISPLAYING */}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>
                    {/* PAGINATION */}
                    {sortedUsers?.length > 50 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedUsers?.length / usersPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
