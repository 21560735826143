import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { convertTimestampToDateTime } from "../services/helpers";
import { useSettings } from "../store/setting_store";
import { tableStore } from "../store/table_store";
import { useState } from "react";
import UserSignedTablesModal from "../components/eventDetail/userSignedTableModel";

export default function TicketDetailsModal(props) {
  const layout_page = useSettings((state) => state.layout_page);
  //GETTING TABLE TYPES FROM THE STORE
  const rooms = tableStore((state) => state.rooms);
  const room = rooms.find((room) => room?.event?.value === props.event_id);

  //showing the table model depending on the table id
  const [modalShows, setModalShows] = useState({});
  //function to toggle modal for a specific table ID
  // const toggleModal = (tableId) => {
  //   setModalShows((prevModalShows) => ({
  //     ...prevModalShows,
  //     [tableId]: !prevModalShows[tableId],
  //   }));
  // };

  const toggleModal = (table_id, table_index, table_name, tableQuantity) => {
    setModalShows({
      show: !modalShows.show,
      table_id,
      table_index,
      table_name,
      tableQuantity,
    });
  };

  const [selectedTables, setSelectedTables] = useState([]);
  // a call back function used to get selected tables from the child
  const getSelectedTables = (data) => {
    console.log("the selected tables from signed table model are:", data);
    // Convert the values of the object to an array
    const selectedTablesArray = Object.values(data);

    props.getSelectedTables(selectedTablesArray);
    setSelectedTables(selectedTablesArray);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="fas fa-ticket-alt" /> Buy tickets
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* event name */}
          <h6 className="b">{props?.event_name}</h6>
          <p className="text-sm mb-1 text-muted">
            <i className="fas fa-clock fa-fw" />
            {convertTimestampToDateTime(props?.eventDate).toLocaleString(
              "en-US",
              {
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZoneName: "short",
              }
            )}
          </p>

          {/* event venue */}
          <p className="text-sm text-muted">
            <i className="fas fa-map-marker-alt fa-fw" />
            {props?.venue || props.venue.label}: {props?.address}
          </p>
        </div>

        <div className="table-hover">
          <div>
            {/* ticket row */}
            {props?.tickets?.map((ticket, index) => {
              var isInCart = false;

              const eventIds = props?.cartItems?.map((map) => map?.event_id);
              const ticketNames = props?.cartItems?.map(
                (map) => map?.ticket_name
              );

              if (
                eventIds?.includes(props?.event_id) &&
                ticketNames?.includes(ticket?.ticket_name)
              ) {
                console.log("THE TICKET IS ALREADY IN CART");
                isInCart = true;
              }

              // SETTING THE VALUE FOR THE INPUT FIELD
              var ticketQuantity = props?.ticketQuantities[index] ?? 0;

              return (
                <div
                  key={index}
                  className="bg-gray d-flex justify-content-between border-bottom py-2 px-3"
                >
                  <div className="">
                    <div>
                      {ticket?.ticket_name} ticket
                      <i
                        className="fas fa-info-circle ml-2"
                        style={{
                          color: layout_page?.primary_color,
                        }}
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Get your ticket and print it or present it via your smartphone"
                      />
                    </div>
                    <div className="clearfix" />
                    <div className="b mt-1">
                      UGX {props?.formatter.format(ticket?.ticket_price)}
                      {ticket.promotional_price && (
                        <del className="price-old ml-1">
                          {" "}
                          UGX {props?.formatter.format(ticket?.ticket_price)}
                        </del>
                      )}
                    </div>
                    <div className="clearfix" />
                    <small className="text-success font-size-10px mt-1">
                      Sales started on{" "}
                      {convertTimestampToDateTime(
                        ticket?.sales_start_date
                      )?.toDateString()}
                    </small>

                    <div className="clearfix" />
                    <small className="text-danger font-size-10px  mt-1">
                      Sales end in
                      <span className="countdown">
                        <span>{props?.timeLeftMap[ticket?.id]?.weeks}</span>{" "}
                        weeks
                        <span>{props?.timeLeftMap[ticket?.id]?.days}</span> days
                        <span>
                          {props?.timeLeftMap[ticket?.id]?.hours}
                        </span> hr{" "}
                        <span>{props?.timeLeftMap[ticket?.id]?.minutes}</span>
                        min{" "}
                        <span>
                          {props?.timeLeftMap[ticket?.id]?.seconds}
                        </span>{" "}
                        sec
                      </span>
                    </small>
                  </div>
                  <div className=" text-right">
                    {props?.errors.no_tickets && (
                      <span
                        style={{
                          marginTop: 0,
                          color: "red",
                        }}
                      >
                        {props?.errors.no_tickets}
                      </span>
                    )}
                    <div className="input-group  bootstrap-touchspin bootstrap-touchspin-injected">
                      <input
                        type="text"
                        className="form-control touchspin-integer bg-white eventdate-ticket-qte"
                        data-min={0}
                        data-max={500}
                        // defaultValue={ticketQuantity}
                        value={ticketQuantity}
                        onChange={(e) => {
                          ticketQuantity = e.target.value;
                          props?.handleOrder(e, index);
                        }}
                        disabled={isInCart}
                      />
                      <span className="input-group-btn-vertical">
                        <button
                          className="btn text-white bootstrap-touchspin-up "
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                          type="button"
                          onClick={(e) =>
                            props?.incrementQuantity(
                              e,
                              index,
                              ticketQuantity,
                              ticket
                            )
                          }
                        >
                          +
                        </button>
                        <button
                          className="btn text-white bootstrap-touchspin-down"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                          type="button"
                          onClick={(e) =>
                            props?.decrementQuantity(e, index, ticketQuantity)
                          }
                        >
                          -
                        </button>
                      </span>
                    </div>
                    <span className="badge badge-info mt-2 d-none">
                      {ticket?.number_of_tickets} tickets left
                    </span>
                    <span className="badge badge-info ml-3 mt-2 d-none">
                      {ticket?.tickets_per_attendee} tickets per attendee
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          {/* TABLES */}
          <div>
            {room?.tableTypes?.map((table_type, index) => {
              // SETTING THE VALUE FOR THE INPUT FIELD
              var tableQuantity = props?.tableQuantities[index] || 0;

              return (
                <div
                  key={index}
                  className="bg-gray d-flex justify-content-between border-bottom py-2 px-3"
                >
                  <div className="">
                    <div>
                      {table_type?.table_type} table
                      <i
                        className="fas fa-info-circle ml-2"
                        style={{
                          color: layout_page?.primary_color,
                        }}
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Get your ticket and print it or present it via your smartphone"
                      />
                    </div>
                    <div className="clearfix" />

                    {room?.price.map((price, index) => {
                      if (price?.table_type === table_type?.table_type) {
                        return (
                          <div className="b mt-1" key={price?.id}>
                            UGX {props?.formatter.format(price?.price)}
                            {price.promotional_price && (
                              <del className="price-old ml-1">
                                {" "}
                                UGX {props?.formatter.format(price?.price)}
                              </del>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className="clearfix" />

                    {props?.tickets?.length > 0 &&
                      props?.tickets?.slice(0, 1).map((ticket, index) => (
                        <>
                          <small className="text-success font-size-10px mt-1">
                            Sales started on{" "}
                            {convertTimestampToDateTime(
                              ticket?.sales_start_date
                            )?.toDateString()}
                          </small>

                          <div className="clearfix" />
                          <small className="text-danger font-size-10px  mt-1">
                            Sales end in
                            <span className="countdown">
                              <span>
                                {props?.timeLeftMap[ticket?.id]?.weeks}
                              </span>{" "}
                              weeks
                              <span>
                                {props?.timeLeftMap[ticket?.id]?.days}
                              </span>{" "}
                              days
                              <span>
                                {props?.timeLeftMap[ticket?.id]?.hours}
                              </span>{" "}
                              hr{" "}
                              <span>
                                {props?.timeLeftMap[ticket?.id]?.minutes}
                              </span>
                              min{" "}
                              <span>
                                {props?.timeLeftMap[ticket?.id]?.seconds}
                              </span>{" "}
                              sec
                            </span>
                          </small>
                        </>
                      ))}
                  </div>
                  <div className=" text-right">
                    <div className="input-group  bootstrap-touchspin bootstrap-touchspin-injected">
                      {/* <input
                        type="text"
                        className="form-control touchspin-integer bg-white eventdate-ticket-qte"
                        data-min={0}
                        data-max={500}
                        value={tableQuantity}
                        onChange={(e) => {
                          tableQuantity = e.target.value;
                          props?.handleTableOrder(e.target.value, index);
                        }}
                        disabled
                      /> */}
                      <input
                        type="text"
                        className="form-control touchspin-integer bg-white eventdate-ticket-qte"
                        data-min={0}
                        data-max={500}
                        value={selectedTables.reduce(
                          (count, table) =>
                            table.selectedTable === table_type?.table_type
                              ? count + 1
                              : count,
                          0
                        )}
                        disabled
                      />

                      <span className="input-group-btn-vertical">
                        <button
                          className="btn text-white bootstrap-touchspin-up "
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                          type="button"
                          onClick={(e) => {
                            toggleModal(
                              table_type.id,
                              index,
                              table_type.table_type,
                              tableQuantity
                            );
                          }}
                        >
                          +
                        </button>
                        <button
                          className="btn text-white bootstrap-touchspin-down"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                          type="button"
                          onClick={(e) => {
                            toggleModal(
                              table_type?.id,
                              index,
                              table_type.table_type,
                              tableQuantity
                            );
                          }}
                        >
                          -
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}

            <UserSignedTablesModal
              show={modalShows.show}
              onHide={() => toggleModal()}
              venue_id={props?.venue_id}
              table_name={modalShows.table_name}
              incrementTableQuantity={props?.incrementTableQuantity}
              decrementTableQuantity={props?.decrementTableQuantity}
              table_id={modalShows.table_id}
              table_index={modalShows.table_index}
              tableQuantity={modalShows.tableQuantity}
              getSelectedTables={getSelectedTables}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{
            backgroundColor: layout_page?.primary_color,
          }}
          onClick={(e) => {
            props.handleAddToCart(e);
            props.onHide(e);
          }}
        >
          Add To Cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
