import {
  addDoc,
  addDocl,
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import app from "../Firebase";

const db = getFirestore(app);

///////////////////////////////////////////
//  METHOD TO ADD AN EVENT ORGANIZER //////
//////////////////////////////////////////
export const addOrganizer = async (event_date_id, organizer) => {
  const organizersCollection = collection(db, "organizers");
  // ADDING THE DATA TO FIRESTORE

  const data = {
    id: "",
    user_id: event_date_id,
    country_id: organizer.active,
    name: organizer.organizer_description,
    slug: organizer.free,
    description: organizer.promotional_price,
    website: organizer.number_of_tickers,
    email: organizer.organizers_per_attendee,
    phone: organizer.sales_start_date,
    facebook: organizer.sales_end_date,
    twitter: organizer.position,
    instagram: organizer.price,
    googleplus: null,
    linkedin: null,
    youtubeurl: null,
    logo_name: null,
    cover_name: null,
    showvenuesmap: null,
    showfollowers: null,
    showreviews: null,
    show_event_date_stats_on_scanner_app: null,
    allow_tap_to_check_in_on_scanner_app: null,
    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
    views: null,
  };

  const responseFromFirestore = await addDoc(organizersCollection, data);

  const organizer_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const organizerDoc = doc(db, "organizers", organizer_id);

  // UPDATING THE BLOG ID
  const response = await updateDoc(organizerDoc, { id: organizer_id });

  console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
  return organizer_id;
};

// GET ORGANIZER DETAILS TO MAP
export async function getOrganizerDetails(organizer_id) {
  if (!organizer_id) {
    return null;
  }
  const userDoc = doc(db, "organizers", organizer_id);
  const querySnapshot = await getDoc(userDoc);
  const organizer = querySnapshot.data();
  return organizer;
}

//geting organizer details based on event_id
export async function getOrganizerDetail(event_id) {
  if (!event_id) {
    return null;
  }
  try {
    const eventDoc = doc(db, "events", event_id);
    const docSnapshot = await getDoc(eventDoc);

    if (docSnapshot.exists()) {
      const eventData = docSnapshot.data();
      const organizer_id = eventData.organizer_id;

      const organizerDoc = doc(db, "organizers", organizer_id);
      const organizerSnapshot = await getDoc(organizerDoc);

      if (organizerSnapshot.exists()) {
        const organizerData = organizerSnapshot.data();
        return organizerData;
      } else {
        console.log("Organizer document does not exist");
        return null;
      }
    } else {
      console.log("Event document does not exist");
      return null;
    }
  } catch (error) {
    console.log("erorr", error);
  }
}

// GETTING ALL THE  ORGANIZERS IN THE DATABASE
export const getAllOrganizers = async () => {
  const organizers = [];
  const organizersDoc = collection(db, "organizers");
  const querySnapshot = await getDocs(organizersDoc);

  for (let i = 0; i < querySnapshot.docs.length; i++) {
    organizers.push(querySnapshot.docs.at(i).data());
  }
  return organizers;
};

///////////////////////////////////////////
//  METHOD TO ADD AN EVENT ORGANIZER //////
//////////////////////////////////////////
export const addOrganizerCategory = async (event_date_id, organizer) => {
  const organizersCollection = collection(db, "organizers");
  // ADDING THE DATA TO FIRESTORE

  const data = {
    organizer_id: "",
    category: event_date_id,

    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
    views: null,
  };

  const responseFromFirestore = await addDoc(organizersCollection, data);

  const organizer_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const organizerDoc = doc(db, "organizers", organizer_id);

  // UPDATING THE BLOG ID
  const response = await updateDoc(organizerDoc, { id: organizer_id });

  console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
  return organizer_id;
};

///////////////////////////////////////////
//  METHOD TO ADD FOLLOW ORGANIZER //////
//////////////////////////////////////////
export const followOrganizer = async (data) => {
  const followingCollection = collection(db, "following");

  try {
    const responseFromFirestore = await addDoc(followingCollection, data);

    return "success";
  } catch (error) {
    return error;
  }
};

///////////////////////////////////////////
//  METHOD TO CHECK IF THE USER FOLLOWS THE ORGANIZER //////
//////////////////////////////////////////
export const checkFollowing = async ({ organizer_id, user_id }) => {
  console.log("THE ORGANIZER ID: " + organizer_id);
  console.log("THE USER ID: " + user_id);
  const followingCollection = collection(db, "following");

  const q = query(
    followingCollection,
    where("organizer_id", "==", organizer_id),
    where("userId", "==", user_id)
  );

  try {
    const responseFromFirestore = await getDocs(q);

    // CHECKING IF THE RETURNED RESPONSE IS NOT EMPTY
    if (responseFromFirestore.docs.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("SOMETHING WENT WRONG GETTING THE USER FOLLOWING: " + error);
    return false;
  }
};

///////////////////////////////////////////
//  METHOD TO CHECK IF THE USER FOLLOWS THE ORGANIZER /////
//////////////////////////////////////////
export const getFollowing = async (user_id) => {
  console.log("THE USER ID: " + user_id);

  var followingList = [];

  const followingCollection = collection(db, "following");

  const q = query(followingCollection, where("user_id", "==", user_id));

  try {
    const responseFromFirestore = await getDocs(q);

    for (const doc of responseFromFirestore.docs) {
      followingList.push(doc.data());
    }
  } catch (error) {
    console.log(
      "SOMETHING WENT WRONG GETTING THE ORGANIZERS THE USER IS FOLLOWING: " +
        error
    );
  }

  return followingList;
};

////////////////////////////////////////
// METHOD TO UPLOAD THE ORGANIZER IMAGE
//////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadOrganizerImage = async (imageFile) => {
  if (!imageFile) {
    return;
  }

  const storage = getStorage(app);
  const storageRef = ref(storage, "organizers/images" + imageFile.name);

  try {
    await uploadBytes(storageRef, imageFile);
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image", error);
  }
};

export const deleteOrganizerImage = async (imageUrl) => {
  const storage = getStorage(app);
  const imageRef = ref(storage, imageUrl);

  try {
    await deleteObject(imageRef);
    console.log("Image deleted successfully");
  } catch (error) {
    console.error("Error deleting image", error);
    throw error;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const uploadOrganizerLogo = async (imageFile) => {
  if (!imageFile) {
    return;
  }

  const storage = getStorage(app);
  const storageRef = ref(storage, "organizers/images" + imageFile.name);

  try {
    await uploadBytes(storageRef, imageFile);
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading Logo Image", error);
  }
};

export const deleteOrganizerLogo = async (imageUrl) => {
  const storage = getStorage(app);
  const imageRef = ref(storage, imageUrl);

  try {
    await deleteObject(imageRef);
    console.log("Logo deleted successfully");
  } catch (error) {
    console.error("Error deleting Logo", error);
    throw error;
  }
};

////////////////////////GETTING NUMBER OF FOLLOWERS/////////////////
export async function getNumofFollowers(organizer_id) {
  var followers = [];
  const userDoc = collection(db, "following");

  const q = query(userDoc, where("organizer_id", "==", organizer_id));

  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    followers.push(querySnapshot.docs.at(i).data());
  }

  return followers;
}

//A METHOD OF GETTING USERS.
export const getAllUsersFromDatabase = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const users = [];
  querySnapshot.forEach((doc) => {
    users.push({ id: doc.id, ...doc.data() });
  });
  return users;
};

// GET ALL ATTENDEES FROM THE USERS COLLECTION
export const getAllAttendeesFromDatabase = async () => {
  const querySnapshot = await getDocs(
    query(collection(db, "users"), where("user_type", "==", "attendee"))
  );
  const attendees = [];
  querySnapshot.forEach((doc) => {
    attendees.push({ id: doc.id, ...doc.data() });
  });

  return attendees;
};

// A FUNCTION TO GET USER'S DETAILS  TO MAP
export const usersDetails = async (user_id) => {
  if (!user_id) {
    return null;
  }

  try {
    const userDoc = doc(db, "users", user_id);
    const querySnapshot = await getDoc(userDoc);

    if (querySnapshot.exists()) {
      const user = querySnapshot.data();
      return user;
    } else {
      // Handle the case when the user document does not exist
      console.log("User document not found");
      return null;
    }
  } catch (error) {
    // Handle any errors that occur during the Firestore operation
    console.error("Error retrieving user details:", error);
    return null;
  }
};
