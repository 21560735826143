import {
  addDoc,
  addDocl,
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
  Timestamp,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import app from "../Firebase";

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////// METHOD TO ADD A SCANNER /////////////////////////////////

// INITILIZE FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

export async function addScanner(data) {
  // CREATING A COLLECTION
  const scannerCollection = collection(db, "scanners");

  const responseFromFirestore = await addDoc(scannerCollection, data);
  const scanner_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const scannerDoc = doc(db, "scanners", scanner_id);

  try {
    // UPDATING THE AUDIENCE ID
    const response = await updateDoc(scannerDoc, {
      id: scanner_id,
    });
    console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
    return "success";
  } catch (error) {
    return error;
  }
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////// METHOD TO ADD A POINT OF SALE ////////////////////////

export async function addPOS({ name, username, password, organizer_id }) {
  // CREATING A COLLECTION

  const posColletion = collection(db, "points_of_sale");
  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: "",
    organizer_id: organizer_id,
    name: name,
    username: username,
    password: password,
    created_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
  };

  try {
    const responseFromFirestore = await addDoc(posColletion, data);
    const pos_id = responseFromFirestore.id;

    // CREATING A DOCUMENT
    const posDoc = doc(db, "points_of_sale", pos_id);

    // UPDATING THE AUDIENCE ID
    const response = await updateDoc(posDoc, {
      id: pos_id,
    });

    return "success";
  } catch (error) {
    return error;
  }
}

//////////////////////////////////////////////////////////////////////
/////////////////// METHOD TO ADD AN EVENT DATE POS /////////////////

export async function addEventDatePOS({ event_date_id, pos_id }) {
  // CREATING A COLLECTION

  const eventDatePOSCollection = collection(db, "event_date_pos");
  // ADDING THE DATA TO FIRESTORE
  const data = {
    event_date_id: event_date_id,
    pos_id: pos_id,
  };

  const responseFromFirestore = await addDoc(eventDatePOSCollection, data);
  const event_date_pos_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const docToAdd = doc(db, "event_date_pos", event_date_pos_id);

  // UPDATING THE AUDIENCE ID
  const response = await updateDoc(docToAdd, {
    id: event_date_pos_id,
  });

  console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
}

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////// METHOD TO ADD AN EVENTDATE  SCANNER /////////////////////////////////

export async function addEventDateScanner({
  event_date_id,
  selected_scanner_id,
}) {
  // CREATING A COLLECTION

  const scannerCollection = collection(db, "event_date_scanner");
  // ADDING THE DATA TO FIRESTORE
  const data = {
    event_date_id: event_date_id,
    scanner_id: scanner_id,
  };

  const responseFromFirestore = await addDoc(scannerCollection, data);
  const scanner_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const scannerDoc = doc(db, "event_date_scanner", scanner_id);

  // UPDATING THE AUDIENCE ID
  const response = await updateDoc(scannerDoc, {
    id: scanner_id,
  });

  console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
}

// METHOD TO GET ALL SCANNERS
export async function getScanners() {
  var scanners = [];
  const scannerDoc = collection(db, "scanners");
  const querySnapshot = await getDocs(scannerDoc);
  for (var i = 0; i < querySnapshot.docs.length; i++) {
    scanners.push(querySnapshot.docs.at(i).data());
  }
  return scanners;
}

// METHOD TO GET SCANNERS FOR GIVEN USER
export async function getUserScanners(userId) {
  var scanners = [];
  const scannersCollection = collection(db, "scanners");
  const q = query(scannersCollection, where("added_by", "==", userId));
  const querySnapshot = await getDocs(q);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    scanners.push(querySnapshot.docs.at(i).data());
  }

  return scanners;
}

// METHOD TO GET POINTS OF SALE
export async function getPOS() {
  var pos = [];
  const userDoc = collection(db, "points_of_sale");
  const querySnapshot = await getDocs(userDoc);

  for (var i = 0; i < querySnapshot.docs.length; i++) {
    pos.push(querySnapshot.docs.at(i).data());
  }

  return pos;
}

//A METHOD TO GET A SPECIFIC SCANNER
export const getSpecificScanner = async (scanner_id) => {
  const scannerDoc = doc(db, "scanners", scanner_id);
  const querySnapshot = await getDocs(scannerDoc);
  const scanner = querySnapshot.data();
  return scanner;
};

//METHOD TO DELETE SCANNER
export const deleteScanner = async (scanner_id) => {
  try {
    //get scanner details
    const scannerRef = doc(db, "scanners", scanner_id);
    await deleteDoc(scannerRef);
    console.log("Entire scanner deleted successfully");
    return true;
  } catch (error) {
    console.log("Error while deleting the scanner", error);
    return false;
  }
};
