import React, { useEffect, useState } from "react";
import { useEvents } from "../../../store/event_store";

const EventsCount = ({ category_id }) => {
  const events = useEvents((state) => state.events);
  const [eventsCount, setEventsCount] = useState([]);

  //counting events have have same category
  useEffect(() => {
    const fetchCount = () => {
      const count = events.filter(
        (event) => event?.category?.value === category_id
      );

      setEventsCount(count);
    };
    fetchCount();
  }, [category_id]);
  return <div>{eventsCount.length}</div>;
};

export default EventsCount;
