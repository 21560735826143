import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Exclamation from "./Exclamation";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
  formatDate,
  formatNumberWithCommas,
} from "./../../services/helpers";
import { usePromoCodes } from "../../store/promocode_store";
import { useEvents } from "../../store/event_store";
import { Modal } from "react-bootstrap";
import TablesModal from "./tablesModel";
import MinusButton from "./MinusButton";
import PlusButton from "./PlusButton";
import { tableStore } from "../../store/table_store";

const UnSignedUserModal = ({
  event,
  tickets,
  isVisible,
  onClose,
  venue_id,
  event_id,
  tables,
}) => {
  const navigate = useNavigate();

  const getPromoCodes = usePromoCodes((state) => state.getPromoCodes);

  const rooms = tableStore((state) => state.rooms);
  const room = rooms.find((room) => room?.event?.value === event_id);
  const [selectedTables, setSelectedTables] = useState([]);
  // const tables = room.tableTypes;

  //showing the table model depending on the table id
  const [modalShows, setModalShows] = useState({});
  //function to toggle modal for a specific table ID
  // const toggleModal = (tableId) => {
  //   setModalShows((prevModalShows) => ({
  //     ...prevModalShows,
  //     [tableId]: !prevModalShows[tableId],
  //   }));
  // };

  const toggleModal = (table_id, table_name) => {
    setModalShows({
      show: !modalShows.show,
      table_id,
      table_name,
    });
  };

  const getSpecificPromocode = usePromoCodes(
    (state) => state.getSpecificPromocode
  );

  // acall back that gets data(selectedTables) from the child to the parent
  const getSelectedTables = (data) => {
    const selectedTables = Object.values(data);
    // const selectedTableArray = Object.keys(data);
    setSelectedTables(selectedTables);
    // console.log("the selected tables are:", selectedTables);
    // console.log("the selected tables are:", data);
  };

  const validatePromoCode = usePromoCodes((state) => state.validatePromoCode);
  const checkIfTicketHasPromoCode = useEvents(
    (state) => state.checkIfTicketHasPromoCode
  );

  // TOTAL
  const [total, setTotal] = useState(0);
  const [displayPromoInput, setDisplayPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoDetails, setPromoDetails] = useState({});
  const [ticketQuantities, setTicketQuantities] = useState([]);
  const [discountAmount, setDiscountAmount] = useState({});
  const [discountTableAmount, setDiscountTableAmount] = useState({});
  const [displayPromo, setDisplayPromo] = useState({});
  const [ticketTotal, setTicketTotal] = useState(0);
  const [tableTotal, setTableTotal] = useState(0);

  const [ticektHasPromocode, setTicektHasPromocode] = useState({});

  //tables
  const [tableQuantities, setTableQuantities] = useState([]);

  useEffect(() => {
    initializeTicketQuantities(tickets);
    initializeDiscountAmounts(tickets);

    initializeTableQuantities(tables);
    initializeDiscountTableAmounts(tables);

    console.log("TICKETS: " + tickets?.length);
  }, [tickets, tables]);

  // METHOD TO GET THE QUANTITY BY TICKET ID
  function getQuantityByTicketId(ticketQuantities, ticketId) {
    const ticketQuantity = ticketQuantities.find(
      (t) => t.ticket_id === ticketId
    );
    return ticketQuantity ? ticketQuantity.quantity : 0;
  }

  // METHOD TO GET THE number_of_tables BY Table ID
  function getQuantityByTableId(tableQuantities, tableId) {
    const tableQuantity = tableQuantities.find((t) => t.id === tableId);
    return tableQuantity ? tableQuantity.quantity : 0;
  }

  // // METHOD TO GET THE QUANITY BY TICKET ID
  // function getTicketTotal(ticketTotal, ticketId) {
  //   const ticketQuantity = ticketQuantities.find(
  //     (t) => t.ticket_id === ticketId
  //   );
  //   return ticketQuantity ? ticketQuantity.quantity : 0;
  // }

  // Initialize ticketQuantities with a quantity of 0 for each ticket
  const initializeTicketQuantities = (tickets) => {
    const localQuantities = [];
    for (let i = 0; i < tickets?.length; i++) {
      const ticket = tickets[i];
      localQuantities.push({
        ticket_id: ticket?.id,
        event_id: event?.id,
        quantity: 0,
      });
    }
    setTicketQuantities(localQuantities);
  };

  // Initialize tableQuantities with a quantity of 0 for each table
  const initializeTableQuantities = (tables) => {
    const localQuantities = [];
    for (let i = 0; i < tables?.length; i++) {
      const table = tables[i];
      localQuantities.push({
        id: table?.id,
        event_id: event?.id,
        quantity: 0,
      });
    }
    setTableQuantities(localQuantities);
  };

  // Initialize ticketQuantities with a quantity of 0 for each ticket
  const initializeDiscountAmounts = (tickets) => {
    const discountAmounts = {};
    tickets.forEach((ticket) => {
      discountAmounts[ticket.ticket_id] = 0;
    });
    setDiscountAmount(discountAmounts);
  };
  // Initialize ticketQuantities with a quantity of 0 for each table
  const initializeDiscountTableAmounts = (tables) => {
    const discountAmounts = {};
    tables?.forEach((table) => {
      discountAmounts[table.id] = 0;
    });
    setDiscountTableAmount(discountAmounts);
  };

  // METHOD TO HANDLE CHECKING IF THE TICKETS HAVE PROMOCODES
  const handleCheckIfTicketHasPromoCode = async () => {
    tickets.forEach(async (ticket) => {
      console.log(`THE TICKET ID: ` + ticket.id);
      if (ticket.id) {
        var response = await checkIfTicketHasPromoCode(ticket.id);
        console.log("THE RESPONSE IS: " + response);
        console.log(`THE TICKET ${ticket.id} HAS PROMO?: ` + response);

        const updatedTicektHasPromocode = { ...ticektHasPromocode }; // Create a copy of the existing object

        tickets.forEach((ticket) => {
          updatedTicektHasPromocode[ticket.id] = response;
          console.log(
            "THE SET RESPONSE FOR TICKET WITH ID " +
              ticket.id +
              " IS: " +
              response
          );

          // Set the value for each ticket ID
        });

        setTicektHasPromocode(updatedTicektHasPromocode);
      }
    });
  };

  // GET PROMOCODES
  useEffect(() => {
    handleCheckIfTicketHasPromoCode();
  }, [tickets.length]);

  // METHOG TO CALCULATE THE TICKET TOTAL
  const calculateTicketTotal = (ticketQuantities, discountAmount, ticketId) => {
    console.log("THE LENGTH OF TICKET QUANTITIES1: " + ticketQuantities.length);
    console.log("THE DISCOUNT AMOUNT CALC TOTAL: " + discountAmount[ticketId]);
    console.log("THE TICKET ID WHEN CALCULATING TOTAL: " + ticketId);
    // Find the ticket object using ticketId
    const quantityTicket = ticketQuantities.find(
      (t) => t.ticket_id === ticketId
    );
    if (!quantityTicket) {
      console.error("Ticket not found");
      return; // Exit if the ticket is not found
    }
    const ticket = tickets.find((t) => t.id === ticketId);

    console.log("THE TICKET QUANTITY:", quantityTicket.quantity);

    const ticketPrice = ticket.promotional_price || ticket.ticket_price || 0;
    console.log(
      "TOTAL WITHOUT DISCOUNT:",
      quantityTicket.quantity * ticketPrice
    );

    const discount =
      discountAmount[ticketId] !== undefined ? discountAmount[ticketId] : 0;
    const total = quantityTicket.quantity * ticketPrice - discount;

    console.log("THE TOTAL FOR THIS TICKET IS:", total);

    // Update the ticket total using the ticket_id as the key
    setTicketTotal({ ...ticketTotal, [ticketId]: total });
  };

  // METHOD TO HANDLE DISPLAY PROMO
  const handleDisplayPromo = (index) => {
    setDisplayPromo({ ...displayPromo, [index]: !displayPromo[index] });
  };

  // GET PROMOCODES
  useEffect(() => {
    getPromoCodes();
  }, []);

  // METHOD TO VALIDATE PROMOCODE
  const handleValidatePromoCode = async (e, index, ticket) => {
    e.preventDefault();

    console.log("THE TICKET ID: ", ticket?.id);
    console.log("THE PROMO CODE IS: " + promoCode);
    console.log("THE EVENT ID HERE IS: " + event.id);

    var response = await validatePromoCode({
      promocode: promoCode,
      event_id: event.id,
      ticket_id: ticket?.id,
    });

    console.log("THE PROMOCODE RESPONSE: " + response);

    if (response !== null && response) {
      displaySuccessToast("Promo code applied successfully");
      setDisplayPromoInput(false);

      // UPDATE PROMO DETAILS FOR THIS TICKET
      setPromoDetails({ ...promoDetails, [ticket?.id]: response });

      // CALCULATE THE DISCOUNT AMOUNT
      calculateDiscountAmount(ticketQuantities, promoCode, ticket?.id);
    } else {
      displayErrorToast("Invalid promo code");
    }
  };

  // METHOD TO CALCULATE THE DISCOUNT AMOUNT
  function calculateDiscountAmount(ticketQuantities, promo, ticketId) {
    // Find the ticket object using ticketId
    const ticket = ticketQuantities.find((t) => t.ticket_id === ticketId);
    if (!ticket) {
      console.error("Ticket not found");
      return; // Exit if the ticket is not found
    }

    let updatedDiscountAmount = { ...discountAmount }; // Assuming discountAmount is an object state
    const ticketPrice = ticket.promotional_price || ticket.ticket_price || 0;

    if (promo.discountType === "percentage" && promo.discount > 0) {
      const discount =
        (ticket.quantity * ticketPrice * parseFloat(promo.discount)) / 100;
      console.log("THE DISCOUNT IS:", discount);
      updatedDiscountAmount[ticketId] = discount;
    } else if (promo.discountType === "amount" && promo.discount > 0) {
      console.log("THE DISCOUNT IS:", promo.discount);
      updatedDiscountAmount[ticketId] = parseFloat(promo.discount);
    }

    console.log("THE UPDATED DISCOUNT AMOUNT:", updatedDiscountAmount);
    setDiscountAmount(updatedDiscountAmount);
    calculateTicketTotal(ticketQuantities, updatedDiscountAmount, ticketId);
  }

  // // METHOD TO UPDATE THE TICKET QUANTITIES
  // const handleOrder = (e, index) => {
  //   const value = parseInt(e.target.value) || 0;
  //   const updatedQuantities = [...ticketQuantities];
  //   updatedQuantities[index] = value;
  //   setTicketQuantities(updatedQuantities);
  // };

  // METHOD TO INCREMENT THE TICKET QUANTITY
  const incrementQuantity = (ticketId) => {
    console.log("THE TICKET ID WHEN INCREMENTING: " + ticketId);

    // Find the ticket object using ticketId
    const ticketIndex = ticketQuantities.findIndex(
      (ticket) => ticket.ticket_id === ticketId
    );

    if (ticketIndex === -1) {
      console.log("Ticket not found");
      return; // Exit if the ticket is not found
    }

    // Clone the array for immutability
    const updatedQuantities = [...ticketQuantities];
    // Increment the quantity for the found ticket
    updatedQuantities[ticketIndex] = {
      ...updatedQuantities[ticketIndex],
      quantity: updatedQuantities[ticketIndex].quantity + 1,
    };

    console.log("THE UPDATED QUANTITIES:", updatedQuantities);

    // Assuming promoDetails is keyed by ticket_id
    if (promoDetails[ticketId]) {
      calculateDiscountAmount(
        updatedQuantities,
        promoDetails[ticketId],
        ticketId
      );
    } else {
      calculateTicketTotal(updatedQuantities, discountAmount, ticketId);
    }

    setTicketQuantities(updatedQuantities);
  };

  // METHOD TO DECREMENT QUANTITY
  const decrementQuantity = (ticketId) => {
    console.log("THE TICKET ID WHEN DECREMENTING: " + ticketId);
    // Find the ticket object and its index using ticketId
    const ticketIndex = ticketQuantities.findIndex(
      (t) => t.ticket_id === ticketId
    );
    const ticket = ticketQuantities[ticketIndex];

    if (!ticket) {
      console.error("Ticket not found");
      return; // Exit if the ticket is not found
    }

    console.log("THE TICKET QUANTITY:", ticket.quantity);

    if (ticket.quantity > 0) {
      // Clone the array for immutability
      const updatedQuantities = [...ticketQuantities];
      // Decrement the quantity for the found ticket
      updatedQuantities[ticketIndex] = {
        ...updatedQuantities[ticketIndex],
        quantity: updatedQuantities[ticketIndex].quantity - 1,
      };

      console.log(
        "THE UPDATED QUANTITIES:",
        updatedQuantities.map((t) => t.quantity)
      );

      // RECALCULATE THE DISCOUNT AMOUNT
      if (promoDetails[ticketId]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        calculateDiscountAmount(
          updatedQuantities,
          promoDetails[ticketId],
          ticketId
        );
      } else {
        calculateTicketTotal(updatedQuantities, discountAmount, ticketId);
      }

      setTicketQuantities(updatedQuantities);
    }
  };

  // METHOD TO UPDATE THE TICKET QUANTITY
  const updateQuantity = (newQuantity, ticketId) => {
    if (newQuantity > 0) {
      // Find the ticket object and its index using ticketId
      const ticketIndex = ticketQuantities.findIndex(
        (t) => t.ticket_id === ticketId
      );
      if (ticketIndex === -1) {
        console.error("Ticket not found");
        return; // Exit if the ticket is not found
      }

      const ticket = ticketQuantities[ticketIndex];
      console.log("THE TICKET QUANTITY:", ticket.quantity);

      // Clone the array for immutability and update the quantity
      const updatedQuantities = [...ticketQuantities];
      updatedQuantities[ticketIndex] = {
        ...ticket,
        quantity: newQuantity,
      };
      console.log("THE UPDATED QUANTITIES:", updatedQuantities);

      // Recalculate the discount amount
      if (promoDetails[ticketId]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        calculateDiscountAmount(
          updatedQuantities,
          promoDetails[ticketId],
          ticketId
        );
      } else {
        calculateTicketTotal(updatedQuantities, discountAmount, ticketId);
      }

      setTicketQuantities(updatedQuantities);
    }
  };

  //TABLES FUNCTIONS/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // METHOG TO CALCULATE THE TABLE TOTAL
  const calculateTableTotal = (tableQuantities, discountAmount, tableId) => {
    console.log("THE LENGTH OF TABLE QUANTITIES1: " + tableQuantities.length);
    console.log("THE DISCOUNT AMOUNT CALC TOTAL: " + discountAmount[tableId]);
    console.log("THE TABLE ID WHEN CALCULATING TOTAL: " + tableId);
    // Find the ticket object using ticketId
    const quantityTable = tableQuantities.find((t) => t.id === tableId);
    if (!quantityTable) {
      console.log("Table not found");
      return; // Exit if the table is not found
    }
    //getting price of the table
    const table = room?.tableTypes.find((table) => table.id === tableId);
    const price = room?.price.find(
      (price) => price.table_type === table.table_type
    );
    console.log("THE TABLE QUANTITY:", quantityTable.quantity);
    const tablePrice = price?.promotional_price || price?.price || 0;
    console.log("TOTAL WITHOUT DISCOUNT:", quantityTable.quantity * tablePrice);

    const discount =
      discountAmount[tableId] !== undefined ? discountAmount[tableId] : 0;
    const total = quantityTable.quantity * tablePrice - discount;

    console.log("THE TOTAL FOR THIS TABLE IS:", total);
    // Update the ticket total using the ticket_id as the key
    setTableTotal({ ...tableTotal, [tableId]: total });
  };

  // METHOD TO CALCULATE THE DISCOUNT AMOUNT FOR TABLES
  function calculateDiscountTableAmount(tableQuantities, promo, tableId) {
    // Find the ticket object using ticketId
    const table = tableQuantities.find((t) => t.id === tableId);
    if (!table) {
      console.error("Table not found");
      return; // Exit if the ticket is not found
    }

    let updatedDiscountAmount = { ...discountAmount }; // Assuming discountAmount is an object state
    //getting price of the table
    const tableType = room?.tableTypes.find((table) => table.id === tableId);
    const price = room?.price.find(
      (price) => price.table_type === tableType.table_type
    );
    const tablePrice = price?.promotional_price || price?.price || 0;

    if (promo.discountType === "percentage" && promo.discount > 0) {
      const discount =
        (table.quantity * tablePrice * parseFloat(promo.discount)) / 100;
      console.log("THE DISCOUNT IS:", discount);
      updatedDiscountAmount[tableId] = discount;
    } else if (promo.discountType === "amount" && promo.discount > 0) {
      console.log("THE DISCOUNT IS:", promo.discount);
      updatedDiscountAmount[tableId] = parseFloat(promo.discount);
    }

    console.log("THE UPDATED DISCOUNT AMOUNT:", updatedDiscountAmount);
    setDiscountAmount(updatedDiscountAmount);
    calculateTableTotal(tableQuantities, updatedDiscountAmount, tableId);
  }

  // METHOD TO INCREMENT THE TABLE QUANTITY
  const incrementTableQuantity = (tableId) => {
    console.log("THE TABLE ID WHEN INCREMENTING: " + tableId);

    // Find the ticket object using ticketId
    const tableIndex = tableQuantities.findIndex(
      (table) => table.id === tableId
    );

    if (tableIndex === -1) {
      console.log("Table not found");
      return; // Exit if the table is not found
    }

    // Clone the array for immutability
    const updatedQuantities = [...tableQuantities];
    // Increment the quantity for the found ticket
    updatedQuantities[tableIndex] = {
      ...updatedQuantities[tableIndex],
      quantity: updatedQuantities[tableIndex].quantity + 1,
    };

    console.log("THE UPDATED QUANTITIES:", updatedQuantities);
    // Assuming promoDetails is keyed by table id
    if (promoDetails[tableId]) {
      calculateDiscountTableAmount(
        updatedQuantities,
        promoDetails[tableId],
        tableId
      );
    } else {
      calculateTableTotal(updatedQuantities, discountAmount, tableId);
    }
    setTableQuantities(updatedQuantities);
  };

  // METHOD TO UPDATE THE TICKET QUANTITY
  const updateTableQuantity = (newQuantity, tableId) => {
    if (newQuantity > 0) {
      // Find the ticket object and its index using ticketId
      const tableIndex = tableQuantities.findIndex((t) => t.id === tableId);
      if (tableIndex === -1) {
        console.error("Table not found");
        return; // Exit if the ticket is not found
      }
      const table = tableQuantities[tableIndex];
      console.log("THE TABLE QUANTITY:", table.quantity);

      // Clone the array for immutability and update the quantity
      const updatedQuantities = [...tableQuantities];
      updatedQuantities[tableIndex] = {
        ...table,
        quantity: newQuantity,
      };
      console.log("THE UPDATED QUANTITIES:", updatedQuantities);
      // Recalculate the discount amount
      if (promoDetails[tableId]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        calculateDiscountTableAmount(
          updatedQuantities,
          promoDetails[tableId],
          tableId
        );
      } else {
        calculateTableTotal(updatedQuantities, discountAmount, tableId);
      }
      setTableQuantities(updatedQuantities);
    }
  };

  // METHOD TO DECREMENT TABLE QUANTITY
  const decrementTableQuantity = (tableId) => {
    console.log("THE Table ID WHEN DECREMENTING: " + tableId);
    // Find the ticket object and its index using ticketId
    const tableIndex = tableQuantities.findIndex((t) => t.id === tableId);
    const table = tableQuantities[tableIndex];

    if (!table) {
      console.error("Table not found");
      return; // Exit if the table is not found
    }
    console.log("THE TABLE QUANTITY:", table.quantity);

    if (table.quantity > 0) {
      // Clone the array for immutability
      const updatedQuantities = [...tableQuantities];
      // Decrement the quantity for the found ticket
      updatedQuantities[tableIndex] = {
        ...updatedQuantities[tableIndex],
        quantity: updatedQuantities[tableIndex].quantity - 1,
      };

      console.log(
        "THE UPDATED QUANTITIES:",
        updatedQuantities.map((t) => t.quantity)
      );

      // RECALCULATE THE DISCOUNT AMOUNT
      if (promoDetails[tableId]) {
        console.log("RECALCULATING DISCOUNT AMOUNT");
        calculateDiscountTableAmount(
          updatedQuantities,
          promoDetails[tableId],
          tableId
        );
      } else {
        calculateTableTotal(updatedQuantities, discountAmount, tableId);
      }
      setTableQuantities(updatedQuantities);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // METHOD TO CALCULATE THE TOTAL
    function calculateTotal() {
      // INITIAL TOTAL FOR BOTH TABLES AND TICKETS
      var mytotal = 0;
      //FOR TICKETS
      for (var i = 0; i < tickets?.length; i++) {
        var ticket = tickets[i];
        console.log(
          "THE TICKET TOTAL: " + ticketTotal[ticket?.id] + " FOR INDEX: " + i
        );
        if (ticketTotal[ticket?.id] !== undefined) {
          mytotal += ticketTotal[ticket?.id];
        }
      }

      //TABLES
      for (var j = 0; j < tables?.length; j++) {
        var table = tables[j];
        console.log(
          "THE TABLE TOTAL: " + tableTotal[table?.id] + " FOR INDEX: " + i
        );
        if (tableTotal[table?.id] !== undefined) {
          mytotal += tableTotal[table?.id];
        }
      }
      console.log("THE TOTAL: " + mytotal);
      setTotal(mytotal);
    }
    calculateTotal();
  }, [ticketTotal, tableTotal]);

  // METHOD TO GO TO CHECKOUT
  function goToCheckout() {
    var itemQuantity = 0;

    tickets?.forEach((ticket, index) => {
      console.log("TICKET WHEN CHECKING OUT: " + ticket);
      const quantity = getQuantityByTicketId(ticketQuantities, ticket?.id);
      if (quantity > 0) {
        itemQuantity += quantity;
      }
    });

    tables?.forEach((table, index) => {
      console.log("TICKET WHEN CHECKING OUT: " + table);
      const quantity = getQuantityByTableId(tableQuantities, table?.id);
      if (quantity > 0) {
        itemQuantity += quantity;
      }
    });

    console.log("THE TOTAL QUANTITY: " + itemQuantity);

    if (itemQuantity === 0) {
      displayWarningToast(
        "Please select at least one ticket OR one table to proceed"
      );
      return;
    }

    console.log("THE PROMO DETAILS BEFORE GOING TO CHECKOUT: " + promoDetails);
    console.log("THE TICKET QUANTITIES: " + JSON.stringify(ticketQuantities));
    console.log("THE TABLE QUANTITIES: " + JSON.stringify(tableQuantities));

    navigate("/checkout_event_detail", {
      state: {
        event,
        ticketQuantities,
        tableQuantities,
        itemQuantity,
        promoDetails,
        selectedTables,
        total,
      },
    });
  }

  return (
    <Modal onHide={onClose} show={isVisible} size="xl">
      <div className="bg-white py-5 rounded">
        <div class="row">
          <div class="col-sm-12 col-lg-10 mx-2 mx-lg-auto">
            <h4
              className="mb-4 mt-2 text-uppercase fw-bolder"
              style={{ color: "#F76300", fontSize: "18px" }}
            >
              Buy your Ticket Here
            </h4>
            <h6>
              <Exclamation style={{ color: "#F76300" }} />
              <Link
                to={"/sign_up_attendee"}
                className="ml-2"
                style={{ color: "#F76300" }}
              >
                Sign Up
              </Link>{" "}
              with Lyxa to get access to discounts on events and amenities at
              venues
            </h6>
            <div className=" container">
              <div className="row  mt-4">
                {/* TICKET TYPE */}
                {tickets.map((ticket, index) => {
                  // TICKET QUANTITY
                  var ticketQuantity = getQuantityByTicketId(
                    ticketQuantities,
                    ticket?.id
                  );

                  const ticketGross =
                    ticketQuantity *
                    (ticket?.promotional_price || (ticket?.ticket_price ?? 0));

                  return (
                    <div className="col-12 col-md-6">
                      <div
                        class="shadow p-3 mb-5 bg-body gap-5"
                        style={{
                          borderRadius: "17px",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <div className="row">
                          {/* LEFT COL */}
                          <div className="col-10">
                            {/* TICKET NAME */}
                            <h6 className="b">{ticket?.ticket_name}</h6>

                            <h6
                              className="my-4 b"
                              style={{
                                color: "#F76300",
                                fontFamily: "Open Sans",
                              }}
                            >
                              UGX{" "}
                              {formatNumberWithCommas(
                                ticket?.promotional_price ||
                                  (ticket?.ticket_price ?? 0)
                              )}
                            </h6>
                            <h6 className="text-sm">
                              Ticket valid from{" "}
                              <span style={{ color: "#F76300" }}>
                                {ticket.sales_start_date &&
                                  formatDate(
                                    convertTimestampToDateTime(
                                      ticket?.sales_start_date
                                    )
                                  )}
                              </span>{" "}
                              till{" "}
                              <span style={{ color: "#F76300" }}>
                                {ticket.sales_end_date &&
                                  formatDate(
                                    convertTimestampToDateTime(
                                      ticket?.sales_end_date
                                    )
                                  )}
                              </span>
                            </h6>
                          </div>

                          {/* RIGHT COL */}
                          <div className="col-2">
                            <div className="d-flex flex-column align-items-center justify-content-center gap-1">
                              {/* PLUS BUTTON */}
                              <PlusButton
                                incrementQuantity={incrementQuantity}
                                id={ticket?.id}
                              />

                              {/* TICKET QUANTITY */}
                              <input
                                value={ticketQuantity}
                                className={"text-center"}
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  borderRadius: "10px",
                                  color: "black",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  updateQuantity(e.target.value, ticket.id)
                                }
                              />

                              {/* MINUS BUTTON */}
                              <MinusButton
                                decrementQuantity={decrementQuantity}
                                id={ticket?.id}
                              />
                            </div>
                          </div>
                        </div>

                        {/* PROMOCODE SECTION */}
                        <p className="d-block">
                          {/* PROMO CODE CHECKBOX */}
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={"flexCheckDefault" + index}
                              checked={displayPromo[index]}
                              onChange={(e) => handleDisplayPromo(index)}
                              style={{ color: "orange" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"flexCheckDefault" + index}
                            >
                              I have a promo code
                            </label>
                          </div>
                          {/* )} */}

                          {/* PROMO INPUT */}
                          {displayPromo[index] && (
                            <div className="mt-3 px-3 mb-0">
                              <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter promo code"
                                onChange={(e) => setPromoCode(e.target.value)}
                                style={{
                                  backgroundColor: "white",
                                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "15px",
                                  height: "40px",
                                }}
                              />

                              {/* PROMOCODE BUTTON */}

                              <div
                                className="text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout btn-promo"
                                onClick={(e) =>
                                  handleValidatePromoCode(e, index, ticket)
                                }
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid black",
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                  transition: "background-color 1.5s ease",
                                }}
                              >
                                Apply Promo Code
                              </div>
                            </div>
                          )}
                        </p>

                        {/* TICKET GROSS */}
                        <div className="row">
                          <div className="col-7">
                            <h6 className="text-uppercase fw-bolder ">
                              TICKET GROSS:
                            </h6>
                          </div>

                          <div className="col-5 text-right">
                            <h6 className="text-uppercase fw-bolder ">
                              UGX: {formatNumberWithCommas(ticketGross)}
                            </h6>
                          </div>
                        </div>

                        {/* TICKET DISCOUNT */}
                        {promoDetails[ticket?.id] && (
                          <div className="row">
                            <div className="col-7 mb-0 pb-0">
                              <h6 className="text-uppercase fw-bolder ">
                                DISCOUNT:
                              </h6>
                              <p style={{ fontSize: "10px" }}>
                                {promoDetails[ticket?.id]?.description}{" "}
                                (promocode name)
                              </p>
                            </div>

                            <div className="col-5 text-right">
                              <h6 className="text-uppercase fw-bolder ">
                                UGX:{" "}
                                {formatNumberWithCommas(
                                  discountAmount[ticket?.id] ?? 0
                                )}
                              </h6>
                            </div>
                          </div>
                        )}

                        {/* TICKET SUBTOTAL */}
                        <div className="row text-primary">
                          <div className="col-7">
                            <h6 className="text-uppercase fw-bolder ">
                              TICKET SUBTOTAL:
                            </h6>
                          </div>

                          {/* ALIGN COLUMN CONTENTS RIGHT */}
                          <div className="col-5 text-right">
                            <h6 className="text-uppercase fw-bolder ">
                              UGX:{" "}
                              {formatNumberWithCommas(
                                ticketTotal[ticket?.id] ?? 0
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* IF YOU HAVE TABLES */}
              <div className="row  mt-4">
                {room?.tableTypes.length > 0 &&
                  room?.tableTypes.map((table_type, index) => {
                    // TABLE QUANTITY
                    var tableQuantity = getQuantityByTableId(
                      tableQuantities,
                      table_type?.id
                    );

                    // Calculate table gross
                    let tableGross = 0;
                    room?.price.forEach((price) => {
                      if (price.table_type === table_type.table_type) {
                        tableGross =
                          tableQuantity *
                          (price?.promotional_price || (price?.price ?? 0));
                      }
                    });

                    return (
                      <div className="col-12 col-md-6">
                        <div
                          class="shadow p-3 mb-5 bg-body gap-5"
                          style={{
                            borderRadius: "17px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          <div className="row">
                            {/* LEFT COL */}
                            <div className="col-10">
                              {/* TABLE NAME */}
                              <h6 className="b">
                                {table_type?.table_type} Table{" "}
                              </h6>

                              {room?.price.map((price, index) => {
                                if (
                                  price?.table_type === table_type.table_type
                                ) {
                                  return (
                                    <h6
                                      key={price.id}
                                      className="my-4 b"
                                      style={{
                                        color: "#F76300",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      UGX{" "}
                                      {formatNumberWithCommas(
                                        price?.promotional_price ||
                                          (price?.price ?? 0)
                                      )}
                                    </h6>
                                  );
                                }
                                return null;
                              })}

                              {/* START DATE AND END DATE OF TABLES */}
                              {tickets.length > 0 &&
                                tickets.slice(0, 1).map((ticket, index) => (
                                  <h6 className="text-sm" key={table_type.id}>
                                    Table valid from{" "}
                                    <span style={{ color: "#F76300" }}>
                                      {ticket.sales_start_date &&
                                        formatDate(
                                          convertTimestampToDateTime(
                                            ticket?.sales_start_date
                                          )
                                        )}
                                    </span>{" "}
                                    till{" "}
                                    <span style={{ color: "#F76300" }}>
                                      {ticket.sales_end_date &&
                                        formatDate(
                                          convertTimestampToDateTime(
                                            ticket?.sales_end_date
                                          )
                                        )}
                                    </span>
                                  </h6>
                                ))}
                            </div>

                            {/* RIGHT COL */}
                            <div className="col-2">
                              <div className="d-flex flex-column align-items-center justify-content-center gap-1">
                                {/* PLUS BUTTON */}
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    // incrementTableQuantity(table_type?.id);
                                    toggleModal(
                                      table_type?.id,
                                      table_type?.table_type
                                    );
                                  }}
                                >
                                  <path
                                    opacity="0.9"
                                    d="M22.8571 14.2857H17.7143V9.14286C17.7143 8.51179 17.2025 8 16.5714 8H15.4286C14.7975 8 14.2857 8.51179 14.2857 9.14286V14.2857H9.14286C8.51179 14.2857 8 14.7975 8 15.4286V16.5714C8 17.2025 8.51179 17.7143 9.14286 17.7143H14.2857V22.8571C14.2857 23.4882 14.7975 24 15.4286 24H16.5714C17.2025 24 17.7143 23.4882 17.7143 22.8571V17.7143H22.8571C23.4882 17.7143 24 17.2025 24 16.5714V15.4286C24 14.7975 23.4882 14.2857 22.8571 14.2857Z"
                                    fill="#1B1B1E"
                                  />
                                  <rect
                                    x="0.25"
                                    y="0.25"
                                    width="31.5"
                                    height="31.5"
                                    rx="3.75"
                                    stroke="#1B1B1E"
                                    stroke-width="0.5"
                                  />
                                </svg>
                                {/* TABLE QUANTITY */}
                                <input
                                  value={tableQuantity}
                                  className={"text-center"}
                                  style={{
                                    width: "40px",
                                    height: "35px",
                                    borderRadius: "10px",
                                    color: "black",
                                    border: "none",
                                  }}
                                  onChange={(e) =>
                                    updateTableQuantity(
                                      e.target.value,
                                      table_type.id
                                    )
                                  }
                                />

                                {/* MINUS BUTTON */}
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    // decrementTableQuantity(table_type?.id);
                                    toggleModal(
                                      table_type?.id,
                                      table_type?.table_type
                                    );
                                  }}
                                >
                                  <path
                                    opacity="0.9"
                                    d="M22.8571 14H9.14286C8.51179 14 8 14.5971 8 15.3333V16.6667C8 17.4029 8.51179 18 9.14286 18H22.8571C23.4882 18 24 17.4029 24 16.6667V15.3333C24 14.5971 23.4882 14 22.8571 14Z"
                                    fill="#1B1B1E"
                                  />
                                  <rect
                                    x="0.25"
                                    y="0.25"
                                    width="31.5"
                                    height="31.5"
                                    rx="3.75"
                                    stroke="#1B1B1E"
                                    stroke-width="0.5"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          {/* PROMOCODE SECTION */}
                          <p className="d-block">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={"flexCheckDefault" + index}
                                checked={displayPromo[index]}
                                onChange={(e) => handleDisplayPromo(index)}
                                style={{ color: "orange" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"flexCheckDefault" + index}
                              >
                                I have a promo code
                              </label>
                            </div>

                            {/* PROMO INPUT */}
                            {displayPromo[index] && (
                              <div className="mt-3 px-3 mb-0">
                                <input
                                  type="text"
                                  className="form-control mb-3"
                                  placeholder="Enter promo code"
                                  onChange={(e) => setPromoCode(e.target.value)}
                                  style={{
                                    backgroundColor: "white",
                                    boxShadow:
                                      "0px 4px 15px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "15px",
                                    height: "40px",
                                  }}
                                />

                                {/* PROMOCODE BUTTON */}

                                <div
                                  className="text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout btn-promo"
                                  onClick={(e) =>
                                    handleValidatePromoCode(
                                      e,
                                      index,
                                      table_type
                                    )
                                  }
                                  style={{
                                    backgroundColor: "white",
                                    border: "1px solid black",
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    transition: "background-color 1.5s ease",
                                  }}
                                >
                                  Apply Promo Code
                                </div>
                              </div>
                            )}
                          </p>

                          {/* TABLE GROSS */}
                          <div className="row">
                            <div className="col-7">
                              <h6 className="text-uppercase fw-bolder ">
                                TABLE GROSS:
                              </h6>
                            </div>

                            <div className="col-5 text-right">
                              <h6 className="text-uppercase fw-bolder ">
                                UGX: {formatNumberWithCommas(tableGross)}
                              </h6>
                            </div>
                          </div>

                          {/* TABLE DISCOUNT */}
                          {promoDetails[table_type?.id] && (
                            <div className="row">
                              <div className="col-7 mb-0 pb-0">
                                <h6 className="text-uppercase fw-bolder ">
                                  DISCOUNT:
                                </h6>
                                <p style={{ fontSize: "10px" }}>
                                  {promoDetails[table_type?.id]?.description}{" "}
                                  (promocode name)
                                </p>
                              </div>

                              <div className="col-5 text-right">
                                <h6 className="text-uppercase fw-bolder ">
                                  UGX:{" "}
                                  {formatNumberWithCommas(
                                    discountAmount[table_type?.id] ?? 0
                                  )}
                                </h6>
                              </div>
                            </div>
                          )}

                          {/* TABLE SUBTOTAL */}
                          <div className="row text-primary">
                            <div className="col-7">
                              <h6 className="text-uppercase fw-bolder ">
                                TABLE SUBTOTAL:
                              </h6>
                            </div>

                            {/* ALIGN COLUMN CONTENTS RIGHT */}
                            <div className="col-5 text-right">
                              <h6 className="text-uppercase fw-bolder ">
                                UGX:{" "}
                                {formatNumberWithCommas(
                                  tableTotal[table_type?.id] ?? 0
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <TablesModal
                  show={modalShows.show}
                  onHide={() => toggleModal()}
                  venue_id={venue_id}
                  table_name={modalShows?.table_name}
                  incrementQuantity={incrementTableQuantity}
                  decrementQuantity={decrementTableQuantity}
                  table_id={modalShows?.table_id}
                  getSelectedTables={getSelectedTables}
                />
              </div>
            </div>

            <div>
              {/* SUB TOTAL */}
              <span className="d-flex justify-content-between mb-2 mr-3">
                <h6 className="text-uppercase fw-bolder ">Sub total: </h6>
                <h6 className="fw-bold">UGX {formatNumberWithCommas(total)}</h6>
              </span>

              {/* TOTAL */}
              <span className="d-flex justify-content-between mb-4 mr-3">
                <h6 className="text-uppercase fw-bolder ">Total: </h6>
                <h6 className="fw-bold">UGX {formatNumberWithCommas(total)}</h6>
              </span>

              <div onClick={goToCheckout}>
                <div className=" text-uppercase d-flex justify-content-center py-2 text-lg btn-checkout mr-3">
                  Checkout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UnSignedUserModal;
