import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function MyAlert({
  onClose,
  type,
  message,
  buttonText = "Go To Cart",
}) {
  const navigate = useNavigate();
  return (
    <>
      {/* <Alert variant={type} onClose={onClose} dismissible style={{ width: '60%' }} className="mx-auto my-3"> 
    <p>
     {message}  
     
     <Button onClick={() => navigate("/cart")} className="ml-5 px-3 py-1">
            {buttonText}
          </Button>
    </p>
  </Alert> */}

      <Alert
        variant={type}
        onClose={onClose}
        dismissible
        style={{ width: "60%" }}
        className="mx-auto my-3"
      >
        <p className="d-flex justify-content-between align-items-center">
          <span>{message}</span>
          <Button onClick={() => navigate("/cart")} className="ml-5 px-3 py-1">
            {buttonText}
          </Button>
        </p>
      </Alert>
    </>
  );
}
