import React, { useContext, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import { Timestamp } from "firebase/firestore";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function ManageAddNew_Venuetype() {
  // const {isAdmin} = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const addVenueType = useVenues((state) => state.addVenueType);
  const layout_page = useSettings((state) => state.layout_page);
  const [errors, setErrors] = useState({});
  const [venue_type, setVenueType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // HANDLING UPLOADING THE BLOG POST CATEGORY
  const handleAddVenueType = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const data = {
        id: null,
        venue_type: venue_type,
        isVisible: true,
        added_at: Timestamp.fromDate(new Date()),
      };
      const response = await addVenueType(data);
      if (response) {
        displaySuccessToast("Venue type added successfully");
        navigate("/manage_venue_types");
      } else {
        displayErrorToast("something is wrong");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};
    if (!venue_type) {
      errors.venue_type = "type name is required";
    }
    return errors;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new venue type"
          page_link=" Manage Venues types"
          link="manage_venue_types"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"add-new-venue-type"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="venue"
                      method="post"
                      noValidate="novalidate"
                      encType="multipart/form-data"
                      onSubmit={handleAddVenueType}
                    >
                      <div className="tab-content">
                        <div
                          id="venue_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          {/* NAME */}
                          <div className="form-group d-grid">
                            <label htmlFor="venue_type" className="required">
                              Name
                            </label>

                            {errors.venue_type && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.venue_type}
                              </span>
                            )}
                            <input
                              type="text"
                              id="venue_type"
                              name="venue_type"
                              required="required"
                              maxLength={50}
                              onChange={(e) => setVenueType(e.target.value)}
                              className={
                                errors.venue_type
                                  ? " border border-danger form-control"
                                  : "form-control"
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          id="venue_type"
                          name="venue"
                          className="btn text-white"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
