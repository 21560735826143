import { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { userStore } from "../../store/user_store";
import MoreInformationModal from "./MoreInformationModal";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import { useSettings } from "../../store/setting_store";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";

export default function PopingOutUsers({
  user_id,
  setIsLoading,
  userType,
  status,
  user_name,
  user_email,
  last_login,
  registration_date,
  firstname,
  update_date,
}) {
  const [organizer, setOrganizer] = useState([]);
  const [cart_items, setCartItems] = useState([]);
  const getOrganizerById = userStore((state) => state.getOrganizerById);
  const getHandleStatusToggle = userStore((state) => state.handleStatusToggle);
  const getDeleteUser = userStore((state) => state.deleteUser);
  //getting cart items of the attendee
  const getAttendeeCartItems = userStore((state) => state.getAttendeeCartItems);
  const layout_page = useSettings((state) => state.layout_page);
  const clearCart = userStore((state) => state.clearCart);

  //fetching users from the database
  useEffect(() => {
    const fetchUsers = async () => {
      const organizerDetails = await getOrganizerById(user_id);
      setOrganizer(organizerDetails);
    };
    //getting cart items
    const fetchCartItems = async () => {
      const items = await getAttendeeCartItems(user_id);
      setCartItems(items);
    };
    fetchCartItems();
    fetchUsers();
  }, [user_id]);

  // METHOD TO CLEAR THE CART
  const handleClearCart = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const clearResponse = await clearCart(user_id);
    if (clearResponse) {
      displaySuccessToast("Cart cleared successfully");
    } else {
      console.log("CART NOT CLEARED");
      displayErrorToast("Something went wrong clearing cart");
    }
    setIsLoading(false);
  };

  //METHOD TO HANDLE DELETING THE USER
  async function handleDeleteUser(e) {
    e.preventDefault();
    setIsLoading(true);
    var response = await getDeleteUser(user_id);
    if (response) {
      setIsLoading(false);
      console.log("THE USER HAS BEEN SUCCESFULLY DELETED");
      displaySuccessToast("🦄 The user has been deleted succeesfully");
    } else {
      console.log("SOMETHING WENT WRONG DELETING THE USER");
      displayErrorToast("Something went wrong while deleting the user");
      setIsLoading(false);
    }
  }

  const handleStatusToggle = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await getHandleStatusToggle(user_id);
    setIsLoading(false);
  };

  return (
    <>
      {["left"].map((placement) => (
        <OverlayTrigger
          trigger="click"
          key={placement}
          placement={placement}
          rootClose={true}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>
              <Popover.Body>
                <div className="px-3 d-flex flex-column gap-3">
                  {/* MORE INFORMATION */}
                  <div style={{ color: "gray" }}>
                    {/* modal */}
                    <MoreInformationModal
                      user_id={user_id}
                      userType={userType}
                      user_name={user_name}
                      user_email={user_email}
                      last_login={last_login}
                      registration_date={registration_date}
                      firstname={firstname}
                      update_date={update_date}
                      status={status}
                    />
                    {/* modal end */}
                  </div>
                  {/* display only if the user type is attendee */}
                  {userType === "attendee" && (
                    <>
                      <Link to="">
                        <div
                          className="d-flex gap-1 align-items-center"
                          style={{ color: "gray" }}
                          onClick={(e) => handleClearCart(e)}
                        >
                          <i className="dropdown-icon fas fa-shopping-cart fa-fw text-muted" />{" "}
                          <h6>Empty Cart</h6>
                          <span
                            className="badge badge-warning float-right ml-3"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="0 event(s) currently in cart"
                          >
                            {cart_items?.length}
                          </span>
                        </div>
                      </Link>
                    </>
                  )}

                  {/* display only if the user_type is organizer */}
                  {userType === "organizer" && (
                    <Link to={"/organizer_details/" + organizer?.id}>
                      <div className="d-flex gap-1" style={{ color: "gray" }}>
                        <i className="dropdown-icon far fa-id-card fa-fw text-muted" />{" "}
                        <h6>Organizer Profile</h6>
                      </div>
                    </Link>
                  )}
                  {/* <Link>
                      <div className="d-flex gap-1" style={{ color: "gray" }}>
                        <i className="dropdown-icon fas fa-user-secret fa-fw text-muted" />{" "}
                        <h6>Impersonate</h6>
                      </div>
                    </Link> */}

                  <div
                    className="d-flex gap-1"
                    style={{ color: "gray", cursor: "pointer" }}
                    onClick={(e) => handleStatusToggle(e)}
                  >
                    {status === "disabled" ? (
                      <>
                        <i className="dropdown-icon fas fa-user-check fa-fw text-muted" />{" "}
                        <h6>Enable</h6>
                      </>
                    ) : (
                      <>
                        <i className="dropdown-icon fas fa-user-slash fa-fw text-muted" />{" "}
                        <h6>Disable</h6>
                      </>
                    )}
                  </div>
                  {/* DELETE USER */}

                  {/* <DeleteComponentModal
                    itemName="User"
                    handleDelete={handleDeleteUser}
                  /> */}
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <BsThreeDotsVertical
              style={{
                color: layout_page?.primary_color,
                cursor: "pointer",
              }}
            />
          </div>
        </OverlayTrigger>
      ))}
    </>
  );
}
