import React, { useContext, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { IconPicker } from "react-fa-icon-picker-alen";
import "../events/icon_style.css";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import { useSettings } from "../../../store/setting_store";
import { Timestamp } from "firebase/firestore";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function Manageamenities_add() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  const navigate = useNavigate();
  const addNewAmmenity = useVenues((state) => state.addNewAmmenity);
  const layout_page = useSettings((state) => state.layout_page);
  // HANDLING AMMENITY ADD UP
  const [name, setName] = useState(null);
  const [icon, setIcon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleAddAmmenities = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const data = {
        id: null,
        name: name,
        icon: icon,
        isvisible: true,
        created_at: Timestamp.fromDate(new Date()),
      };
      const response = await addNewAmmenity(data);
      if (response) {
        displaySuccessToast("Ammenity added successfully");
        navigate("/manage_amenities");
      } else {
        displayErrorToast("something is wrong");
      }
    } else {
      setErrors(errors);
    }

    setIsLoading(false);
  };
  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};
    if (!name) {
      errors.name = "name is required";
    }
    if (!icon) {
      errors.icon = "icon is required";
    }
    return errors;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Add a new amenity"
          page_link="Manage amenities"
          link="manage_amenities"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"add-new-amenities"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="amenity"
                      onSubmit={handleAddAmmenities}
                      noValidate="novalidate"
                    >
                      <fieldset className="form-group">
                        <div
                          id="amenity_translations_en_a2lix_translations-fields"
                          className="tab-pane show active "
                          role="tabpanel"
                        >
                          <div className="form-group d-grid">
                            <label htmlFor="name" className="required">
                              Name
                            </label>
                            {errors.name && (
                              <span className=" text-sm font-weight-bold text-danger">
                                {errors.name}
                              </span>
                            )}
                            <input
                              type="text"
                              name="name"
                              onChange={(e) => setName(e.target.value)}
                              required="required"
                              maxLength={50}
                              className={
                                errors.name
                                  ? " border border-danger form-control"
                                  : "form-control"
                              }
                            />
                          </div>
                        </div>
                      </fieldset>

                      {/* SELECTING ICON */}
                      <div className="form-group d-grid">
                        <label htmlFor="amenity_icon" className="required">
                          Icon
                        </label>
                        {errors.icon && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.icon}
                          </span>
                        )}
                        <IconPicker value={icon} onChange={(v) => setIcon(v)} />
                      </div>

                      {/* SUBMIT BUTTON */}
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
