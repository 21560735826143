import React, { useEffect, useState } from "react";
import DropDownEvents from "./DropDownEvents";
import { Link } from "react-router-dom";
import DropDownSettings from "./DropDownSettings";
import DropDownVenues from "./DropDownVenues";
import DropDownBlog from "./DropDownBlog";
import DropDownHelpCenter from "./DropDownHelpCenter";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import app from "../../../Firebase";
import { useSettings } from "../../../store/setting_store";
import {
  admin_sidebar_options1,
  admin_sidebar_options2,
  admin_sidebar_options3,
  admin_sidebar_options4,
} from "../../../components/header/sidebar_options";

export default function AdminSideNav({ currentPage }) {
  const auth = getAuth(app);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.currentUser) {
        // Get the user's email address
        const email = auth.currentUser.email;
        // Check if email has an associated account
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        // Check if the user registered with Google
        const isGoogleProvider = signInMethods.some((method) =>
          method.includes("google.com")
        );
        setIsGoogleSignIn(isGoogleProvider);
      }
    };

    fetchUser();
  }, []);

  return (
    <aside className="col-lg-3 pt-3 pt-lg-0">
      <div className="sticky-top sticky-sidebar dashboard-sidebar d-none d-lg-block d-xl-block">
        <ul
          id="dashboard-menu"
          className="nav nav-pills nav-pills-vertical-styled overflow-auto"
          style={{ maxHeight: "30rem" }}
        >
          {/* DASHBOARD */}

          {admin_sidebar_options1?.map((option, index) => (
            <li key={index} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (currentPage === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}

          {/* SETTINGS */}
          <li className="nav-item">
            <DropDownSettings currentPage={currentPage} />
          </li>

          {/* EVENTS */}
          <li className="nav-item">
            <DropDownEvents currentPage={currentPage} />
          </li>

          {admin_sidebar_options2?.map((option, index) => (
            <li key={index} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (currentPage === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}

          {/* VENUES */}
          <li className="nav-item">
            <DropDownVenues currentPage={currentPage} />
          </li>

          {admin_sidebar_options3?.map((option, index) => (
            <li key={index} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (currentPage === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}

          {/* MANAGE BLOGS */}
          <li className="nav-item">
            <DropDownBlog currentPage={currentPage} />
          </li>

          {/* HELP CENTER */}
          <li className="nav-item">
            <DropDownHelpCenter currentPage={currentPage} />
          </li>

          {admin_sidebar_options4?.map((option, index) => (
            <li key={index} className="nav-item">
              <Link
                to={`/${option.link}`}
                className={
                  "nav-link " + (currentPage === option.link ? "active" : "")
                }
              >
                <i className={option.icon} /> {option.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>{" "}
    </aside>
  );
}
