import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useSettings } from "../../store/setting_store";

const StyledPagination = styled(Pagination)(({ theme, primaryColor }) => ({
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: primaryColor + " !important",
    color: "YOUR_TEXT_COLOR",
  },
}));

export default function CustomPagination({ setCurrentPage, totalPages }) {
  const layout_page = useSettings((state) => state.layout_page);
  const primaryColor = layout_page?.primary_color || "#f76300"; // Replace "YOUR_DEFAULT_COLOR" with a default color in case primary_color is not available

  return (
    <Stack
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginTop: "25px",
        marginBottom: "25px",
      }}>
      <StyledPagination
        count={totalPages}
        onChange={(_, page) => setCurrentPage(page)}
        primaryColor={primaryColor}
      />
    </Stack>
  );
}
