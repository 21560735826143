import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditBlogPostCategory() {
  const updateBlogCategory = useBlogs(
    (state) => state.updateBlogpostCategories
  );
  const [errors, setErrors] = useState({});
  const layout_page = useSettings((state) => state.layout_page);

  const [isLoading, setIsLoading] = useState(false);
  const { blog_category_id } = useParams();
  const { document } = useFetchDocument(
    "blogpost_categories",
    blog_category_id
  );
  console.log("the document of blog category is:", document);
  const [category_form, setCategoryForm] = useState(document || {});

  const navigate = useNavigate();

  useEffect(() => {
    setCategoryForm(document);
  }, [document]);

  //handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handling editing
  const handleEditBlogCategory = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateBlogCategory(blog_category_id, {
          category: category_form?.category,
        });
        displaySuccessToast("Update successful");
        navigate("/manage_blogpost_categories");
      } catch (error) {
        console.log("ERROR OCCURED WHILE UPDATING BLOG CATEGORY:", error);
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!category_form?.category) {
      errors.category = "Category is required";
    }

    return errors;
  }

  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    // DISPLAY THE LOADER
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit blog post category"
          page_link="Manage Blogpost Categories"
          link="manage_blogpost_categories"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_blogpost_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="blog_post_category"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleEditBlogCategory}
                    >
                      <div id="blog_post_category">
                        <fieldset className="form-group">
                          <div
                            id="blog_post_category_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="blog_post_category_translations_en">
                              <div className="form-group">
                                <label
                                  htmlFor="blog_post_category_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>

                                {errors.category && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.category}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="blog_post_category_translations_en_name"
                                  name="category"
                                  required="required"
                                  maxLength={50}
                                  pattern=".{1,}"
                                  className={
                                    errors.category
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                  value={category_form?.category}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        {/* SAVE BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="blog_post_category_save"
                            name="blog_post_category[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="blog_post_category__token"
                          name="blog_post_category[_token]"
                          defaultValue="trnz3MnfqenzW5WcpmEtElqVv9pFc7O_hM8LA3GRdSg"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
