import React, { useState, useEffect } from "react";
import "./header.css";
// import "./header_mobile.css";
// import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { getVisibleEventsCategories } from "../../services/events";
import { IconPickerItem } from "react-fa-icon-picker-alen";
import SearchBar from "../search_bar/search_bar";
import UserProfileDropdown from "./components/user_profile_dropdown";
import CartComponent from "./components/shopping_cart";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import SignupDropdown from "./components/signup_dropdown";
import { userStore } from "../../store/user_store";
import useQueryStore from "../../store/query_store";
import { useSettings } from "../../store/setting_store";
import { isMobile } from "react-device-detect";
import OrganizerMobileDrawer from "./OrganizerMobileDrawer";
import AttendeeMobileDrawer from "./AttendeeMobileDrawer";
import AdminMobileDrawer from "./AdminMobileDrawer";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { auth } from "../../Firebase";

export default function Header({ currentPage }) {
  const navigate = useNavigate();
  const isAttendee = userStore((state) => state.isAttendee);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);

  const isLoggedIn = userStore((state) => state.isLoggedIn);
  const clearQuery = useQueryStore((state) => state.clearQuery);
  const layout_page = useSettings((state) => state.layout_page);

  const [eventCategories, setEventCategories] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const setCategory = useQueryStore((state) => state.setCategory);
  const setSelectedCategory = useQueryStore(
    (state) => state.setSelectedCategory
  );

  // FUNCTION THAT RUNS ON COMPONENT LOAD
  useEffect(() => {
    // FETCHING EVENT CATEGORIES
    const get_event_categories = async () => {
      const data = await getVisibleEventsCategories();
      setEventCategories(data);
    };
    get_event_categories();
  }, [layout_page.length]);

  const handleNavigation = (route) => {
    const _route = "/" + route;
    navigate(_route);
    clearQuery();
  };

  return (
    <div className="rootDiv">
      {/* TOP NAVBAR */}
      <section className="header-main sticky-top">
        <div
          className={`container ${isMobile && "mt-1"} `}
          style={{ marginTop: -7, marginBottom: -15 }}
        >
          <div className="row align-items-center justify-content-between">
            <div className="col-5 col-lg-3 order-0 order-lg-0 header-logo-wrapper">
              {/* LOGO */}
              <div className="brand-wrap mt-3 mt-lg-0">
                <img
                  className="logo img-fluid cursor-pointer"
                  style={{ marginLeft: 20 }}
                  src={layout_page?.logo || "./logo.png"}
                  alt="Lyxa Events"
                  onClick={() => handleNavigation("")}
                />
              </div>
            </div>

            {/* SEARCH BAR */}
            {/* {home_page?.show_search_box === true && ( */}
            <div className="col-12 col-lg-5 order-2 order-lg-1 mt-3 mb-3 mt-lg-0 mb-lg-0 header-search-wrapper">
              <SearchBar />
            </div>
            {/* )} */}

            <div className="col-7 col-lg-4 text-right order-1 order-lg-2 header-actions-wrapper">
              <div className="widgets-wrap d-flex justify-content-end mr-3">
                {/* SHOPPING CART */}
                <div className="mt-1">
                  {isAttendee && auth.currentUser?.emailVerified && (
                    <CartComponent />
                  )}
                </div>

                {/* DISPLAYING THE USER PROFILE ICON */}
                {isLoggedIn ? (
                  <div className=" mt-lg-0">
                    {isMobile && isAdmin && <AdminMobileDrawer />}
                    {isMobile && isOrganizer && <OrganizerMobileDrawer />}
                    {isMobile && isAttendee && <AttendeeMobileDrawer />}
                    {!isMobile && <UserProfileDropdown />}
                  </div>
                ) : (
                  // DISPLAYING THE SIGNIN AND SIGNUP BUTTONS
                  <div className="d-flex align-items-center mt-2 mt-lg-0">
                    <div className="widget-header">
                      <Link to="/sign_in">
                        <div className="icon-wrap icon-xs bg-primary round text-secondary d-lg-none d-xl-none">
                          <i className="fas fa-sign-in-alt text-light fa-sm fa-fw" />
                        </div>
                        <span className="d-none d-lg-block">Sign in</span>
                      </Link>
                    </div>

                    {/* SIGNUP BUTTONS */}
                    <SignupDropdown />
                  </div>
                )}

                {/* DRAWER HAMBURGER */}
                <div className="d-lg-none mt-2">
                  <div
                    className={`navbar-toggler ${
                      !navbarOpen ? "collapsed" : ""
                    }`}
                    onClick={() => setNavbarOpen(!navbarOpen)}
                  >
                    {navbarOpen ? (
                      <AiOutlineClose
                        size={30}
                        color={`${layout_page?.primary_color}`}
                      />
                    ) : (
                      <GiHamburgerMenu
                        size={30}
                        color={`${layout_page?.primary_color}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* BOTTOM NAV BAR */}
      <Navbar expand="lg" bg="white" variant="light">
        <div className="container ">
          <Navbar.Collapse id="main_nav" in={navbarOpen}>
            <Nav className="" fill>
              {/* HOME */}
              <li
                className={
                  "nav-item " + (currentPage === "home" ? "active" : "")
                }
                onClick={() => handleNavigation("")}
              >
                <span className="nav-link cursor-pointer">
                  <i className="fas fa-home fa-fw" />
                  Home
                </span>
              </li>

              {/* BROWSE EVENTS */}
              <li
                className={
                  "nav-item" +
                  " " +
                  (currentPage === "browse_events" ? "active" : "")
                }
              >
                <Link className="nav-link" to="/browse_events">
                  <i className="fas fa-calendar fa-fw" />
                  Browse Events
                </Link>
              </li>

              {/* EVENT CATEGORIES */}
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-stream fa-fw" /> Explore
                  </span>
                }
                id="explore-dropdown"
              >
                {eventCategories.map((eventCategory) => (
                  <NavDropdown.Item
                    key={eventCategory?.id}
                    onClick={() => {
                      setSelectedCategory({
                        value: eventCategory?.id,
                        label: eventCategory?.name,
                      });
                      setCategory(eventCategory?.id);
                      navigate("/browse_events");
                    }}
                  >
                    <div
                      key={eventCategory?.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconPickerItem
                        icon={eventCategory?.icon}
                        size={15}
                        color="#000"
                      />
                      <span style={{ marginLeft: "10px" }}>
                        {eventCategory?.name}
                      </span>
                    </div>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>

              {/* VENUES */}
              <li
                className={
                  "nav-item " + (currentPage === "venues" ? "active" : "")
                }
              >
                <span
                  className="nav-link cursor-pointer"
                  onClick={() => handleNavigation("venues")}
                >
                  <i className="fas fa-compass fa-fw" />
                  Venues
                </span>
              </li>

              {/* HOW IT WORKS */}
              <li
                className={
                  "nav-item" +
                  " " +
                  (currentPage === "how_it_works" ? "active" : "")
                }
              >
                <span
                  className="nav-link cursor-pointer"
                  onClick={() => handleNavigation("how_it_works")}
                >
                  <i className="fas fa-question-circle fa-fw" />
                  How It works?
                </span>
              </li>

              {/* BLOG */}
              <li
                className={
                  "nav-item " + (currentPage === "blog" ? "active" : "")
                }
                style={{ cursor: "pointer" }}
              >
                <span
                  className="nav-link"
                  onClick={() => handleNavigation("blogs")}
                >
                  <i className="fas fa-newspaper fa-fw" />
                  Blog
                </span>
              </li>

              {/* MY TICKETS */}
              <li
                className={
                  "nav-item " + (currentPage === "tickets" ? "active" : "")
                }
                style={{ cursor: "pointer" }}
              >
                <span
                  className="nav-link"
                  onClick={() => handleNavigation("dashboard")}
                >
                  <i className="fas fa-ticket-alt fa-fw" />
                  My tickets
                </span>
              </li>

              {/* ADD AN EVENT */}
              <li
                className={
                  "nav-item " + (currentPage === "add_event" ? "active" : "")
                }
              >
                <span
                  className="nav-link cursor-pointer"
                  onClick={() => handleNavigation("add_event")}
                >
                  <i className="fas fa-calendar-plus fa-fw" />
                  Add my event
                </span>
              </li>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}
