export const convert_from_percentage = (percentage, minimum, maximum) => {
  const value = (percentage / 100) * (maximum - minimum);
  return Math.floor(value + minimum);
};

export const createSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .trim();
};

export const convert_date_range = (date_definition) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const currentDayOfWeek = today.getDay();

  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (6 - currentDayOfWeek));

  const startOfNextWeek = new Date(today);
  startOfNextWeek.setDate(today.getDate() + (7 - currentDayOfWeek) + 1);
  const endOfNextWeek = new Date(today);
  endOfNextWeek.setDate(today.getDate() + (13 - currentDayOfWeek));

  switch (date_definition) {
    case 'today':
      return [today, tomorrow];
    case 'tomorrow':
      return [tomorrow, new Date(tomorrow).setHours(23, 59, 59, 999)];
    case 'this weekend':
      const currentDayOfWeek = today.getDay();
      const daysUntilNextSaturday = 6 - currentDayOfWeek;
      const daysUntilNextSunday = 7 - currentDayOfWeek;
      const nextSaturday = new Date(today);
      nextSaturday.setDate(today.getDate() + daysUntilNextSaturday);
      const nextSunday = new Date(today);
      nextSunday.setDate(today.getDate() + daysUntilNextSunday);
      return [nextSaturday, new Date(nextSunday).setHours(23, 59, 59, 999)];
    case 'this week':
      return [today, new Date(endOfWeek).setHours(23, 59, 59, 999)];
    case 'next week':
      return [
        startOfNextWeek,
        new Date(endOfNextWeek).setHours(23, 59, 59, 999),
      ];
    case 'this month':
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return [today, new Date(endOfMonth).setHours(23, 59, 59, 999)];
    case 'next month':
      const currentMonth = today.getMonth();
      const nextMonth = (currentMonth + 1) % 12;
      const nextMonthFirstDay = new Date(today.getFullYear(), nextMonth, 1);
      const nextMonthLastDay = new Date(today.getFullYear(), nextMonth + 1, 0);
      return [nextMonthFirstDay, nextMonthLastDay];
    default:
      break;
  }
};
