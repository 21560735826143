import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import { IconPicker } from "react-fa-icon-picker-alen";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { userStore } from "../../../store/user_store";
import { useBlogs } from "../../../store/blog_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditUserCategory() {
  const updateUserCategory = useBlogs((state) => state.updateUserCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { user_category_id } = useParams();
  const { document } = useFetchDocument("user_categories", user_category_id);
  const [userCategoryForm, setuserCategoryForm] = useState(document || {});
  const isAdmin = userStore((state) => state.isAdmin);
  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    setuserCategoryForm(document);
  }, [document]);

  //handling inputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setuserCategoryForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //edit form
  const handleEditUserCategory = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateUserCategory(user_category_id, {
          name: userCategoryForm?.name,
          icon: userCategoryForm?.icon,
        });
        displaySuccessToast("Edit is successful");
        navigate("/manage_user_categories");
      } catch (error) {
        console.log("Error occurred while editing", error);
        displayErrorToast("Editing is not successful");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  function validateForm() {
    const errors = {};

    // VALIDATING CATEGORY
    if (!userCategoryForm?.name) {
      errors.name = "Name is required";
    }
    if (!userCategoryForm?.icon) {
      errors.icon = "Icon is required";
    }
    return errors;
  }

  if (isLoading) {
    <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <Breadcrumpadmin2
          title="Edit help center category"
          page_link="Manage Help center Categories"
          link="manage_user_categories"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_user_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="help_center_category"
                      method="post"
                      noValidate="novalidate"
                      onSubmit={handleEditUserCategory}
                    >
                      <div id="help_center_category">
                        <fieldset className="form-group">
                          <div
                            id="help_center_category_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="help_center_category_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="help_center_category_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>
                                {errors.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.name}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  id="help_center_category_translations_en_name"
                                  required="required"
                                  maxLength={50}
                                  className={
                                    errors.name
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                  name="name"
                                  value={userCategoryForm?.name}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        {/* SELECTING ICON */}
                        <div className="form-group d-grid">
                          <label htmlFor="help_center_category_icon">
                            Icon
                          </label>
                          {errors.icon && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.icon}
                            </span>
                          )}
                          <IconPicker
                            name="icon"
                            value={userCategoryForm?.icon}
                            onChange={(v) =>
                              setuserCategoryForm((prevFormData) => ({
                                ...prevFormData,
                                icon: v,
                              }))
                            }
                          />
                        </div>
                        {/* SUBMIT BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            id="help_center_category_save"
                            name="help_center_category[save]"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <input
                          type="hidden"
                          id="help_center_category__token"
                          name="help_center_category[_token]"
                          defaultValue="OAjjVfXs1e69u6YZNTgertmDokY0ShcyOMxV9RWkUac"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
