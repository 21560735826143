import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import React from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../../store/setting_store";

// REVIEW TILE COMPONENT
export default function UserReviewTile({ review }) {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div key={review.id} className="col-md-3">
      <div key={review.id} className="user-review-wrapper mb-5">
        {/* event image */}
        <Link
          to={"/event_details/" + review?.event_id}
          style={{
            color: layout_page?.primary_color,
          }}>
          <img
            src={review?.event_image}
            className="img-thumbnail img-100-100 mr-2"
            alt={review?.event_name}
          />

          {/* EVENT NAME */}
          <p> {review?.event_name}</p>
        </Link>

        {/* RATINGS */}
        <div className="rating-wrap mt-1">
          <ul className="rating-stars mr-2">
            <li className="stars-active">
              {/* STAR RATING */}

              {Array.from({ length: review?.rating }, (_, index) => (
                <i
                  key={index}
                  className="fa fa-star"
                  style={{
                    color: layout_page?.primary_color,
                  }}
                />
              ))}
            </li>
            <li>
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </li>
          </ul>

          {/* RATING */}
          <div className="label-rating">
            <span>{review?.rating}</span>
            out of 5 stars
          </div>
        </div>

        {/* REVIEW DATE */}
        <small className="text-muted mt-1">
          {formatDate(convertTimestampToDateTime(review?.created_at))}
        </small>

        {/* REVIEW HEADLINE */}
        <h6 className="mt-3 font-weight-bold">{review?.review_headline}</h6>

        {/* REVIEW BODY */}
        <p
          className="mt-2 text-sm readmore rmjs-3"
          data-collapsed-height={200}
          data-height-margin={20}
          style={{ height: "auto", maxHeight: "none" }}>
          {review?.review_body}
        </p>
      </div>
    </div>
  );
}
