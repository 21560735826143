// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    background-color: #f67611; /* Set your desired background color */
    color: #fff; /* Set your desired text color */
    outline: none; 
    box-shadow: none; 
  }
  
  .custom-button:focus,
  .custom-button:active {
    background-color: #f67611; 
  }
  
  .promo-code-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .shopping-cart-wrap td{
    padding-bottom: 0px !important;
  }

  `, "",{"version":3,"sources":["webpack://./src/pages/attendee_dashboard/cart.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,sCAAsC;IACjE,WAAW,EAAE,gCAAgC;IAC7C,aAAa;IACb,gBAAgB;EAClB;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,8BAA8B;EAChC","sourcesContent":[".custom-button {\r\n    background-color: #f67611; /* Set your desired background color */\r\n    color: #fff; /* Set your desired text color */\r\n    outline: none; \r\n    box-shadow: none; \r\n  }\r\n  \r\n  .custom-button:focus,\r\n  .custom-button:active {\r\n    background-color: #f67611; \r\n  }\r\n  \r\n  .promo-code-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n  }\r\n\r\n  .shopping-cart-wrap td{\r\n    padding-bottom: 0px !important;\r\n  }\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
