// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.arrow {
    z-index: 1; 
    display: inline-block;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/venues/venuedetails.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,qBAAqB;EACvB","sourcesContent":["\r\n.arrow {\r\n    z-index: 1; \r\n    display: inline-block;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
