import React from "react"

import { DiscussionEmbed } from "disqus-react";
import Disqus from "disqus-react";

export default function DisqusComponent() {
  
  const disqusShortname = "your-disqus-shortname"
  const disqusConfig = {
    url: "http://localhost:3000/",
    identifier: "post.id", // Single post id
    title: "post.title" // Single post title
  }

      return (
        <div>
          <DiscussionEmbed
            shortname={disqusShortname}
            config={disqusConfig}
          />
        </div>
      )
    
  }
