import React, { useEffect, useState } from "react";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import Loader from "../../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../../components/text_editor/text_editor";
import TagsInput from "react-tagsinput";
import { getUserDetails } from "../../../services/auth";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWarningToast,
} from "./../../../services/helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEvents } from "../../../store/event_store";
import { Timestamp } from "firebase/firestore";
import { userStore } from "../../../store/user_store";
import { useVenues } from "../../../store/venues_store";
import { Switch } from "@mui/material";
import { useSettings } from "../../../store/setting_store";
import { auth } from "../../../Firebase";
import Sidenavbar from "../../organizer_dashboard/components/sidenavbar";
import BreadCrumpOrg from "../../organizer_dashboard/components/breadcrumporganizer";
import AdminSideNav from "../components/admin_side_nav";

export default function AddEvents() {
  // EVENT VARIABLES
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [organizerID, setOrganizerID] = useState(null);
  const [show_attendees, setShowAttendees] = useState(true);
  const [enable_reviews, setEnableReviews] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [country, setCountry] = useState("");
  const [video_link, setVideoLink] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [external_link, setExternalLink] = useState("");
  const [email, setEmail] = useState("");
  const [facebook_link, setFacebook] = useState("");
  const [twitter_link, setTwitter] = useState("");
  const [instagram_link, setInstagram] = useState("");
  const [linkedin_link, setLinkedin] = useState("");
  const [repeat_every, setRepeatEvery] = useState("");
  const [repeat_metric, setRepeatMetric] = useState();
  const [artists, setArtists] = useState([]);
  const [tags, setTags] = useState([]);
  const [main_event_image, setMainEventImage] = useState("");
  const [gallery, setGallery] = useState([]);

  // OTHER VARIABLES
  const [eventCategories, setEventCategories] = useState([]);
  const [eventOrganizers, setEventOrganizers] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [venuesList, setVenuesList] = useState([]);
  const [scannersList, setScannersList] = useState([]);
  const [posList, setPOSList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [eventRepeats, setEventRepeats] = useState(false);

  const navigate = useNavigate();

  // ZUSTAND HOOKS
  const addEvent = useEvents((state) => state.addEvent);
  const isAdmin = userStore((state) => state.isAdmin);
  //getting listed directory venues
  const directoryVenues = useVenues((state) => state.getListedondirectory);
  const layout_page = useSettings((state) => state.layout_page);
  //get visible categories
  const getVisibleEventCategories = useEvents(
    (state) => state.getVisibleEventCategories
  );
  //getting scanners
  const scanners = userStore((state) => state.scanners);
  //getting pos
  const pointsOfSale = userStore((state) => state.pointsOfSale);

  //getting visible languages
  const getVisibleLanguages = useEvents((state) => state.getVisibleLanguages);

  //getting visisble audiences
  const getVisibleAudiences = useEvents((state) => state.getVisibleAudiences);
  //getting visible countries
  const getVisibleCountries = useEvents((state) => state.getVisibleCountries);

  //upload event images
  const uploadEventImages = useEvents((state) => state.uploadEventImages);
  //getting delete method
  const deleteImageFromFirebaseStorage = useEvents(
    (state) => state.deleteImageFromFirebaseStorage
  );

  const organizers = userStore((state) => state.fetchOrganizers);

  useEffect(() => {
    // GETTING THE EVENT CATEGORIES
    const get_event_categories = async () => {
      const returnedCategories = await getVisibleEventCategories();
      const event_categories = [];

      for (let i = 0; i < returnedCategories.length; i++) {
        event_categories.push({
          value: returnedCategories[i].id,
          label: returnedCategories[i].name,
        });
      }

      setEventCategories(event_categories);
    };

    //getting all the organizers
    const get_event_organizers = async () => {
      const returnedOrganizers = await organizers();
      const event_organizers = [];
      for (let i = 0; i < returnedOrganizers.length; i++) {
        event_organizers.push({
          value: returnedOrganizers[i].id,
          label: returnedOrganizers[i].organizer_name,
        });
      }
      setEventOrganizers(event_organizers);
    };

    // GETTING THE COUNTRIES
    const get_countries_list = async () => {
      const returnedCountries = await getVisibleCountries();
      const countries = [];

      for (let i = 0; i < returnedCountries.length; i++) {
        countries.push({
          value: returnedCountries[i].id,
          label: returnedCountries[i].name,
        });
      }
      setCountriesList(countries);
    };

    // GETTING THE VENUES LIST
    const get_venues_list = async () => {
      const returnedVenues = await directoryVenues();
      const venues = [];
      for (var i = 0; i < returnedVenues.length; i++) {
        venues.push({
          value: returnedVenues[i].id,
          label: returnedVenues[i].name,
        });
      }
      setVenuesList(venues);
    };

    // GETTING THE SCANNERS LIST
    const get_scanners = async () => {
      const returnedScanners = scanners.map((scanner) => {
        return {
          value: scanner?.id,
          label: scanner?.name,
        };
      });
      setScannersList(returnedScanners);
    };

    // GETTING THE POINTS OF SALE LIST
    const get_points_of_sale = async () => {
      const pos = pointsOfSale.map((point) => {
        return {
          value: point?.id,
          label: point?.name,
        };
      });
      setPOSList(pos);
    };

    // GETTING THE LANGUAGES LIST
    const get_languages = async () => {
      const returnedLanguages = await getVisibleLanguages();
      const languages_list = [];
      for (let i = 0; i < returnedLanguages.length; i++) {
        languages_list.push({
          value: returnedLanguages[i].id,
          label: returnedLanguages[i].name,
        });
      }
      setLanguagesList(languages_list);
    };

    // GETTING THE EVENT CATEGORIES
    const get_audiences_list = async () => {
      const returnedAudiences = await getVisibleAudiences();
      const audiences = [];
      for (let i = 0; i < returnedAudiences.length; i++) {
        audiences.push(returnedAudiences[i].name);
      }
      setAudienceList(audiences);
    };

    //  CALLING THE METHODS TO FETCH THE DATA
    get_languages();
    get_scanners();
    get_points_of_sale();
    get_venues_list();
    get_audiences_list();
    get_countries_list();
    get_event_categories();
    get_event_organizers();
  }, []);

  const repeatMetrics = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  // METHOD TO ADD METRIC
  const handleRepeatMetric = (e) => {
    setRepeatMetric(e);
  };

  // METHOD TO ADD A NEW AUDIENCE
  const handleAudiences = (audience) => {
    const newAudiences = [...audiences];
    const audienceIndex = newAudiences.indexOf(audience);
    if (audienceIndex !== -1) {
      //if the audience is already selected, remove it
      newAudiences.splice(newAudiences, 1);
    } else {
      // If the audience is not selected, add it
      newAudiences.push(audience);
    }
    setAudiences(newAudiences);
  };

  // METHOD TO HANDLE CATEGORIES
  const handleCategories = (e) => {
    setCategory(e);
  };
  // METHOD TO HANDLE ORGANIZER
  const handleOrganizer = (e) => {
    //e means the selected
    setOrganizer(e);
    setOrganizerID(e.value);
  };
  //method to handle country
  const handleCountry = (e) => {
    setCountry(e);
  };

  // METHOD TO ADD A NEW ARTIST
  const handleArtists = (artist) => {
    setArtists(artist);
  };

  //////////////////////////////////////////
  // HANDLING EVENT IMAGE AND GALLERY  //
  /////////////////////////////////////////
  const [numFieldsets, setNumFieldsets] = useState(0);

  const handleAddFieldset = (e) => {
    e.preventDefault();
    setNumFieldsets(numFieldsets + 1);
  };

  const handleRemoveFieldset = (e) => {
    e.preventDefault();
    if (numFieldsets > 0) {
      setNumFieldsets(numFieldsets - 1);
    }
  };

  // HANDLING GALLERY IMAGE CAPTURE
  const handleGalleryImages = async (event) => {
    const file = event.target.files[0];
    const imageUrl = await uploadEventImages(file);
    const galleryImages = [...gallery, imageUrl];

    // Setting the gallery images
    setGallery(galleryImages);
    setNumFieldsets(0);
  };

  const fieldsets = [];

  for (let i = 0; i < numFieldsets; i++) {
    fieldsets.push(
      <>
        <fieldset className="form-group" style={{}} key={i}>
          {/* <legend className="col-form-label">1label__</legend> */}
          <div id="event_images_1">
            <fieldset className="form-group">
              <legend className="col-form-label">Gallery image</legend>
              <div className="vich-image">
                <div className="custom-file">
                  {/* PICKING ANOTHER EVENT IMAGE */}
                  <input type="file" onChange={handleGalleryImages} />
                </div>
              </div>
            </fieldset>
          </div>

          <div onClick={handleRemoveFieldset}>
            <a href=" " className="mr-3 btn btn-outline-dark btn-sm ">
              <i className="fas fa-minus-square" /> Remove
            </a>
          </div>
        </fieldset>
      </>
    );
  }

  // Function to handle removing an image from the gallery
  const handleRemoveGalleryImage = async (index) => {
    const res = window.confirm(
      "Are you sure you want to delete this event image?"
    );

    if (res) {
      const newGallery = [...gallery];
      newGallery.splice(index, 1);
      setGallery(newGallery);
      var response = await deleteImageFromFirebaseStorage(gallery[index]);
      if (response) {
        displaySuccessToast("Image deleted successfully");
      }
    }
  };

  // Function to handle removing an image from the gallery
  const handleRemoveMainEventImage = async (e) => {
    e.preventDefault();

    const res = window.confirm("Are you sure you want to delete this image?");
    if (res) {
      setMainEventImage("");
      const response = await deleteImageFromFirebaseStorage(main_event_image);
      if (response) {
        displaySuccessToast("Image deleted successfully");
      }
    }
  };

  //////////////////////////////////////////
  // HANDLING EVENT DATES AND TICKETS  //
  /////////////////////////////////////////
  const [eventDates, setEventDates] = useState([
    {
      tickets: [{}],
    },
  ]);

  const [numberOfEventDates, setNumberOfEventDates] = useState(1);
  const [numberOfEventDateTickets, setNumberOfEventDateTickets] = useState(1);

  // HANDLE ADD EVENT DATE
  function handleAddEventDate(e) {
    e.preventDefault();

    setNumberOfEventDates(numberOfEventDates + 1);
    setEventDates([
      ...eventDates,
      {
        tickets: [{}],
      },
    ]);
  }

  // HANDLE EVENT DATE CHANGE METHOD
  function handleEventDateChange(index, key, value) {
    console.log(value);
    const newEventDates = [...eventDates];
    newEventDates[index][key] = value;
    setEventDates(newEventDates);
  }

  // HANDLE REMOVE EVENT DATE
  const handleRemoveEventDate = (e, event_date_index) => {
    e.preventDefault();
    if (numberOfEventDates > 1) {
      setNumberOfEventDates(numberOfEventDates - 1);
      const eventDatesCopy = [...eventDates];
      eventDatesCopy.splice(event_date_index, 1);
      setEventDates(eventDatesCopy);
    }
  };

  // HANDLE ADD TICKET
  function handleAddTicket(e, eventDateIndex) {
    e.preventDefault();
    setNumberOfEventDateTickets(numberOfEventDateTickets + 1);
    const newEventDates = [...eventDates];
    console.log("NEW EVENT DATES LENGTH: " + newEventDates.length);
    newEventDates[eventDateIndex].tickets.push({});
    setEventDates(newEventDates);
    console.log("added a ticket to event dates at index: " + eventDateIndex);
  }

  // HANDLE TICKET CHANGE
  function handleTicketChange(e, eventDateIndex, ticketIndex, key, value) {
    console.log("THE TICKET INDEX IS: " + ticketIndex);
    console.log("THE EVENT DATE INDEX IS: " + eventDateIndex);

    console.log("KEY: " + key);
    console.log("VALUE: " + value);

    const newEventDates = [...eventDates];
    newEventDates[eventDateIndex].tickets[ticketIndex][key] = value;
    setEventDates(newEventDates);
  }

  // HANDLE REMOVE TICKET
  const handleRemoveTicket = (e, event_date_index, ticket_index) => {
    console.log("THE EVENT INDEX: " + event_date_index);
    console.log("THE TICKET INDEX: " + ticket_index);

    e.preventDefault();
    if (numberOfEventDateTickets > 1) {
      setNumberOfEventDateTickets(numberOfEventDateTickets - 1);

      const newEventDates = [...eventDates];
      const newTickets = newEventDates[event_date_index].tickets;

      newTickets.splice(ticket_index, 1);

      newEventDates[event_date_index].tickets = newTickets;
      setEventDates(newEventDates);
      console.log(
        "FOR EVENT DATE AT INDEX: " +
          event_date_index +
          " THE NUMBER OF TICKETS ARE: " +
          eventDates[event_date_index].tickets.length +
          " OR: " +
          newTickets.length
      );
    } else {
      console.log("THE NUMBER OF TICKETS IS: " + numberOfEventDateTickets);
    }
  };

  useEffect(() => {
    // setEventDates([...eventDates, {}]);
    console.log("EVENT DATES LENGTH: " + eventDates.length);
    console.log("TICKETS LENGTH: " + eventDates[0].tickets.length);
  }, [eventDates]);

  // HANDLING EVENT DATES
  const eventdates = [];

  for (let index = 0; index < numberOfEventDates; index++) {
    const eventDate = eventDates[index] || {};

    const tickets = [];
    eventDate.tickets.map((ticket, ticket_index) => {
      return tickets.push(
        <>
          <fieldset className="form-group" key={ticket_index}>
            <div id="event_eventdates_2_tickets___eventticket__">
              {/* ENABLE SALES FOR THIS TICKET */}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Enable sales for this ticket?
                </legend>
                <div className="is-ticket-active">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      required="required"
                      className="custom-control-input"
                      value={1}
                      id={`yes_enable_sales${index}${ticket_index}`}
                      name={`enable_sales${index}${ticket_index}`}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "enable_sales_for_ticket",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_enable_sales${index}${ticket_index}`}
                    >
                      Yes
                    </label>
                  </div>

                  {/* NO */}
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_enable_sales${index}${ticket_index}`}
                      name={`enable_sales${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      value={0}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "enable_sales_for_ticket",
                          false
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`no_enable_sales${index}${ticket_index}`}
                    >
                      No
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TICKET NAME */}
              {errors.ticketName && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.ticketName}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___name"
                  className="required"
                >
                  Ticket name
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___name_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle  mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Early bird, General admission, VIP...
                </small>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___name"
                  name="event[eventdates][2][tickets][__eventticket__][name]"
                  required="required"
                  aria-describedby="event_eventdates_2_tickets___eventticket___name_help"
                  className="form-control"
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "ticket_name",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* TICKET DESCRIPTION */}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___description"
                  className="required"
                >
                  Ticket description
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___description_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Tell your attendees more about this ticket type
                </small>
                <textarea
                  id="event_eventdates_2_tickets___eventticket___description"
                  name="event[eventdates][2][tickets][__eventticket__][description]"
                  aria-describedby="event_eventdates_2_tickets___eventticket___description_help"
                  className="form-control"
                  required="required"
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "ticket_description",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* IS TICKET FREE */}
              {errors.is_ticket_free && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.is_ticket_free}
                </span>
              )}
              <fieldset className="form-group">
                <legend className="col-form-label required">
                  Is this ticket free ?
                </legend>
                <div
                  id="event_eventdates_2_tickets___eventticket___free"
                  className="is-ticket-free-radio"
                >
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`no_ticket_free${index}${ticket_index}`}
                      name={`ticket_free${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      value={0}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "is_ticket_free",
                          false
                        )
                      }
                    />

                    <label
                      className="custom-control-label required"
                      htmlFor={`no_ticket_free${index}${ticket_index}`}
                    >
                      No
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`yes_ticket_free${index}${ticket_index}`}
                      name={`ticket_free${index}${ticket_index}`}
                      required="required"
                      className="custom-control-input"
                      value={1}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "is_ticket_free",
                          true
                        )
                      }
                    />
                    <label
                      className="custom-control-label required"
                      htmlFor={`yes_ticket_free${index}${ticket_index}`}
                    >
                      Yes
                    </label>
                  </div>
                </div>
              </fieldset>

              {/* TICKET PRICE */}
              {!eventDates[index].tickets[ticket_index]["is_ticket_free"] && (
                <>
                  <div className="form-group">
                    <label htmlFor="event_eventdates_0_tickets_0_price">
                      Price
                    </label>
                    <input
                      type="number"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "ticket_price",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  {/* PROMOTIONAL PRICE */}
                  <div className="form-group">
                    <label htmlFor="event_eventdates_2_tickets___eventticket___promotionalprice">
                      Promotional price
                    </label>
                    <small
                      id="event_eventdates_2_tickets___eventticket___promotionalprice_help"
                      className="form-text text-muted mb-3"
                    >
                      <i
                        className="fas fa-info-circle mr-1"
                        style={{ color: layout_page?.primary_color }}
                      />
                      Set a price lesser than than the original price to
                      indicate a promotion (this price will be the SALE price)
                    </small>
                    <input
                      type="number"
                      className="touchspin-integer  form-control"
                      data-min={0}
                      data-max={100000000}
                      onChange={(e) =>
                        handleTicketChange(
                          e,
                          index,
                          ticket_index,
                          "promotional_price",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </>
              )}

              {/* NUMBER OF TICKETS */}
              {errors.numberOfTickets && (
                <span style={{ marginTop: 0, color: "red" }}>
                  {errors.numberOfTickets}
                </span>
              )}
              <div className="form-group">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___quantity"
                  className="required"
                >
                  Number Of Tickets
                </label>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___quantity"
                  name="event[eventdates][2][tickets][__eventticket__][quantity]"
                  required="required"
                  className="touchspin-integer event-date-ticket-quantity form-control"
                  data-min={1}
                  data-max={1000000}
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "number_of_tickets",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* TICKETS PER ATTENDEE */}
              <div className="form-group required">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___ticketsperattendee"
                  className="required"
                >
                  Tickets per attendee
                </label>
                <small
                  id="event_eventdates_2_tickets___eventticket___ticketsperattendee_help"
                  className="form-text text-muted mb-3"
                >
                  <i
                    className="fas fa-info-circle mr-1"
                    style={{ color: layout_page?.primary_color }}
                  />
                  Set the number of tickets that an attendee can buy for this
                  ticket type
                </small>
                <input
                  type="text"
                  id="event_eventdates_2_tickets___eventticket___ticketsperattendee"
                  name="event[eventdates][2][tickets][__eventticket__][ticketsperattendee]"
                  className="touchspin-integer form-control"
                  data-min={1}
                  data-max={1000000}
                  required="required"
                  onChange={(e) =>
                    handleTicketChange(
                      e,
                      index,
                      ticket_index,
                      "tickets_per_attendee",
                      e.target.value
                    )
                  }
                />
              </div>

              {/* SALE START DATE */}
              <div className="form-group d-grid ">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___salesstartdate"
                  className="required"
                >
                  Sale starts On
                </label>
                {!eventDate.tickets[ticket_index].sales_start_date && (
                  <span className=" text-danger font-weight-bold text-sm ">
                    Please Make sure you select Sales Start Date
                  </span>
                )}
                <DatePicker
                  required
                  className="form-control"
                  selected={eventDate.tickets[ticket_index].sales_start_date}
                  onChange={(date) => {
                    handleTicketChange(
                      date,
                      index,
                      ticket_index,
                      "sales_start_date",
                      date
                    );
                  }}
                  showTimeSelect
                  value={eventDate.tickets[ticket_index].sales_start_date}
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Ticket Sales Start Date"
                  minDate={new Date()}
                />
              </div>

              {/* SALE END DATE */}
              <div className="form-group d-grid">
                <label
                  htmlFor="event_eventdates_2_tickets___eventticket___salesenddate"
                  className="required"
                >
                  Sale ends On
                </label>

                {!eventDate.tickets[ticket_index].sales_end_date && (
                  <span className=" text-danger font-weight-bold text-sm">
                    Please Make sure you select Sales End Date
                  </span>
                )}
                <DatePicker
                  required
                  className="form-control"
                  selected={eventDate.tickets[ticket_index].sales_end_date}
                  onChange={(date) => {
                    handleTicketChange(
                      date,
                      index,
                      ticket_index,
                      "sales_end_date",
                      date
                    );
                  }}
                  showTimeSelect
                  value={eventDate.tickets[ticket_index].sales_end_date}
                  dateFormat={"dd/MM/yyyy p"}
                  placeholderText="Select Ticket Sales End Date"
                  minDate={eventDate.tickets[ticket_index].sales_start_date}
                />
              </div>

              {/* REMOVE TICKET */}
              {numberOfEventDateTickets > 1 && (
                <button
                  onClick={(e) => handleRemoveTicket(e, index, ticket_index)}
                  className="mr-3 btn btn-outline-dark btn-sm "
                >
                  <i className="fas fa-minus-square" /> Remove this ticket
                </button>
              )}
            </div>
          </fieldset>
        </>
      );
    });

    // PUSHING THE EVENT DATES///////////////////////////////////////////////////////////////
    eventdates.push(
      <fieldset className="mt-5" key={index}>
        {/* ENABLE SALES FOR THIS EVENT DATE */}
        <fieldset className="form-group">
          <b>Enable sales for this event date ?</b>
          <small
            id="event_eventdates_2_active_help"
            className="form-text text-muted mb-3 mt-2"
          >
            <i
              className="fas fa-info-circle mr-1"
              style={{ color: layout_page?.primary_color }}
            />
            Enabling sales for an event date does not affect the tickets
            individual sale status
          </small>
          <div
            id="event_eventdates_2_active"
            className="is-event-date-active"
            aria-describedby="event_eventdates_2_active_help"
          >
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_active_0"
                name="event[eventdates][2][active]"
                required="required"
                className="custom-control-input"
                // defaultChecked="checked"
                onChange={() =>
                  handleEventDateChange(index, "enable_sales", true)
                }
              />

              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_active_0"
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_active_1"
                name="event[eventdates][2][active]"
                required="required"
                className="custom-control-input"
                onClick={(e) =>
                  handleEventDateChange(index, "enable_sales", false)
                }
              />

              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_active_1"
              >
                No
              </label>
            </div>
          </div>
        </fieldset>

        {/* START DATE */}
        {errors.startDate && (
          <span style={{ marginTop: 0, color: "red" }}>{errors.startDate}</span>
        )}
        <div className="form-group d-grid">
          <label htmlFor="event_eventdates_2_startdate" className="required">
            Starts On
          </label>
          {!eventDate.start_date && (
            <span className=" text-danger font-weight-bold text-sm ">
              Please Make sure you select Event Start Date
            </span>
          )}
          <DatePicker
            required
            className="form-control"
            selected={eventDate.start_date}
            onChange={(date) => {
              handleEventDateChange(index, "start_date", date);
            }}
            showTimeSelect
            value={eventDate.start_date}
            dateFormat={"dd/MM/yyyy p"}
            placeholderText="Select Event Start Date"
            minDate={new Date()}
          />
          {/* )} */}
        </div>

        {/* END DATE */}
        {errors.endDate && (
          <span style={{ marginTop: 0, color: "red" }}>{errors.endDate}</span>
        )}
        <div className="form-group d-grid">
          <label className="required">Ends On</label>
          {!eventDate.end_date && (
            <span className=" text-danger font-weight-bold text-sm ">
              Please Make sure you select Event End Date
            </span>
          )}
          <DatePicker
            required
            className="form-control"
            selected={eventDate.end_date}
            onChange={(date) => {
              handleEventDateChange(index, "end_date", date);
            }}
            showTimeSelect
            value={eventDate.end_date}
            dateFormat={"dd/MM/yyyy p"}
            placeholderText="Select Event End Date"
            minDate={eventDate.start_date}
          />
        </div>

        {/* IS EVENT ONLINE */}
        {errors.isEventOnline && (
          <span style={{ marginTop: 0, color: "red" }}>
            {errors.isEventOnline}
          </span>
        )}
        <fieldset className="form-group">
          <legend className="col-form-label required">
            Is this event date online ?
          </legend>
          <div id="event_eventdates_2_online" className="is-event-date-online">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="event_eventdates_2_online_0"
                name="event[eventdates][2][online]"
                required="required"
                className="custom-control-input"
                defaultValue={0}
                // defaultChecked="checked"
                onChange={(e) =>
                  handleEventDateChange(index, "is_event_online", false)
                }
              />
              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_online_0"
              >
                No
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              {" "}
              <input
                type="radio"
                id="event_eventdates_2_online_1"
                name="event[eventdates][2][online]"
                required="required"
                className="custom-control-input"
                defaultValue={1}
                onClick={(e) =>
                  handleEventDateChange(index, "is_event_online", true)
                }
              />
              <label
                className="custom-control-label required"
                htmlFor="event_eventdates_2_online_1"
              >
                Yes
              </label>
            </div>
          </div>
        </fieldset>

        {/* VENUE */}
        {!eventDates[index]["is_event_online"] && (
          <div className="form-group">
            <label className htmlFor="event_eventdates_2_venue">
              Venue
            </label>

            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                handleEventDateChange(index, "venue", e.target.value);
              }}
              value={eventDates[index]["venue"]}
            />
          </div>
        )}

        {/* SCANNERS */}
        <div className="form-group">
          <label className htmlFor="event_eventdates_2_scanners">
            Scanners
          </label>

          <label htmlFor="s2id_autogen2387" className="select2-offscreen">
            Scanners
          </label>

          <SearchableDropdown
            value={eventDates[index].scanners}
            options={scannersList}
            onChange={(e) => handleEventDateChange(index, "scanners", e)}
            isMulti={true}
            placeholder={"Select Scanners"}
          />
        </div>

        {/* POINTS OF SALE */}
        <div className="form-group d-none">
          <label className htmlFor="event_eventdates_2_pointofsales">
            Points of sale
          </label>
          <label htmlFor="s2id_autogen2388" className="select2-offscreen">
            Points of sale
          </label>

          <SearchableDropdown
            value={eventDates[index].pointsOfSale}
            options={posList}
            onChange={(e) => handleEventDateChange(index, "points_of_sale", e)}
            isMulti={true}
            placeholder={"Select Points Of Sale"}
          />
        </div>

        {/* TICKETS */}
        <div className="form-collection eventtickets-collection manual-init">
          <legend className="col-form-label required">Event tickets</legend>

          {tickets}

          {/* ADD NEW TICKET BUTTON */}
          <button
            onClick={(e) => handleAddTicket(e, index)}
            className="mr-3 btn btn-outline-dark btn-sm"
          >
            <i className="fas fa-plus-square"></i> Add a new ticket
          </button>
        </div>

        {/* REMOVE EVENT DATE BUTTON */}
        {numberOfEventDates > 1 && (
          <div
            onClick={(e) => handleRemoveEventDate(e, index)}
            className="mr-3 mt-4 btn btn-outline-dark btn-sm "
          >
            <i className="fas fa-minus-square" /> Remove this event date
          </div>
        )}
      </fieldset>
    );
  }

  // HANDLING MAIN EVENT IMAGE CAPTURE
  const handleMainEventImage = async (event) => {
    const file = event.target.files[0];
    const imageUrl = await uploadEventImages(file);
    setMainEventImage(imageUrl);
  };

  // HANDLE CHANGE OF TAGS
  const handleChange = (e) => {
    setTags(e);
  };

  // HANDLE DESCRIPTION
  const handleDescription = (e) => {
    setDescription(e);
  };

  //////////////////////////
  // HANDLE FORM VALIDATOIN
  function validateForm() {
    const errors = {};
    // VALIDATING CATEGORY
    if (!category) {
      errors.category = "Please select category";
    }
    if (!organizer) {
      errors.orgnizer_name = "Please select the organizer";
    }

    // VALIDATING NAME
    if (name?.trim() === "") {
      errors.name = "Name is required";
    }

    // VALIDATING DESCRIPTION
    if (description?.trim() === "") {
      errors.description = "Description is required";
    }

    // VALIDATING IMAGE
    if (main_event_image?.trim() === "") {
      errors.main_event_image = "Image is required";
    }

    // VALIDATING AUDIENCES
    if (audiences?.length === 0) {
      errors.audiences = "Please select the audiences";
    }

    // VALIDATING PHONE NUMBER
    if (phone_number?.trim() === "") {
      errors.phone_number = "Phone Number is required";
    }

    // VALIDATING EMAIL ADDRESS
    if (email?.trim() === "") {
      errors.email = "Please select enter the contact email address";
    }

    return errors;
  }

  /////////////////////////////////////////
  ////////// HANDLING SUBMISSION ///////////
  /////////////////////////////////////////

  const handleAddEvent = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    // GETTING THE CURRENT USER ID
    const userDetails = await getUserDetails();

    if (!userDetails) {
      navigate("/sign_in");
    }

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      // GETTING THE CURRENT TIMESTAMP
      const current_timestamp = Date.now();
      console.log("THE USER ID IS:", organizerID);

      // GENERATING THE EVENT ID
      const event_id = "event" + organizerID + current_timestamp;
      console.log("THE EVENT ID IS:", event_id);

      try {
        var event_dates = [];

        // ADDING THE EVENT DATES TO THE DATABASE
        for (let i = 0; i < eventDates.length; i++) {
          const event_date_id =
            "event_date" + i + organizerID + current_timestamp;
          const tickets = [];

          // GET THE LIST OF TICKET IDS FOR THIS EVENT
          for (let j = 0; j < eventDates[i].tickets.length; j++) {
            const ticket_id = "ticket" + j + organizerID + current_timestamp;
            const my_ticket = eventDates[i].tickets[j];

            let data_ticket = {
              event_date_id: event_date_id ?? null,
              id: ticket_id ?? null,
              enable_sales_for_ticket:
                my_ticket.enable_sales_for_ticket ?? null,
              ticket_name: my_ticket.ticket_name ?? null,
              ticket_description: my_ticket.ticket_description ?? null,
              is_ticket_free: my_ticket.is_ticket_free ?? true,
              ticket_price: my_ticket.ticket_price ?? null,
              promotional_price: my_ticket.promotional_price ?? null,
              number_of_tickets: my_ticket.number_of_tickets ?? null,
              tickets_per_attendee: my_ticket.tickets_per_attendee ?? null,
              sales_start_date: my_ticket.sales_start_date ?? null,
              sales_end_date: my_ticket.sales_end_date ?? null,
              active: false,
              created_at: Timestamp.fromDate(new Date()),
              updated_at: Timestamp.fromDate(new Date()),
            };

            console.log("THE TICKET DATA IS:");
            console.log(data_ticket);

            tickets.push(data_ticket);
          }

          console.log(
            `LENGTH OF TICKETS FOR ${event_date_id}: ${tickets.length}`
          );

          // ADDING THE EVENT DATE TO THE DATABASE
          const data_event_date = {
            tickets: tickets ?? null,
            id: event_date_id ?? null,
            enable_sales: eventDates[i]?.enable_sales ?? null,
            start_date: eventDates[i]?.start_date ?? null,
            end_date: eventDates[i]?.end_date ?? null,
            is_event_online: eventDates[i]?.is_event_online ?? null,
            venue: eventDates[i]?.venue ?? null,
            location: {
              lat: eventDates[i]?.location?.lat ?? null,
              lng: eventDates[i]?.location?.lng ?? null,
            },
            scanners: eventDates[i]?.scanners ?? null,
            points_of_sale: eventDates[i]?.points_of_sale ?? null,
            event_id: event_id ?? null,
            active: false,
            created_at: Timestamp.fromDate(new Date()),
            updated_at: Timestamp.fromDate(new Date()),
          };

          event_dates.push(data_event_date);
        }

        const fullEventData = {
          id: event_id ?? null,
          category: category ?? null,
          name: name ?? null,
          description: description ?? null,
          artists: artists ?? null,
          show_attendees: show_attendees ?? null,
          enable_reviews: enable_reviews ?? null,
          repeat_every: repeat_every ?? null,
          repeat_metric: repeat_metric?.value ?? null,
          languages: languages || [],
          // subtitles: subtitles ?? null,
          audiences: audiences ?? null,
          country: country ?? null,
          video_link: video_link ?? null,
          external_link: external_link ?? null,
          phone_number: phone_number ?? null,
          email: email ?? null,
          twitter_link: twitter_link ?? null,
          facebook_link: facebook_link ?? null,
          instagram_link: instagram_link ?? null,
          linkedin_link: linkedin_link ?? null,
          tags: tags ?? null,
          main_event_image: main_event_image ?? null,
          gallery: gallery ?? null,
          organizer_id: organizerID ?? null,
          event_dates: event_dates ?? null,
          created_at: Timestamp.fromDate(new Date()),
          updated_at: Timestamp.fromDate(new Date()),
          status: "upcoming",
          visibility: "published",
          views: 0,
        };

        const response = await addEvent(fullEventData);

        if (response) {
          displaySuccessToast("Event added successfully");
          navigate("/manage_events", { replace: true });
        } else {
          displayErrorToast("Something went wrong. Please try again");
        }
      } catch (error) {
        displayErrorToast("Something is wrong: " + error);
      }
    } else {
      console.log("THERE ARE SOME ERRORS");
      displayWarningToast("Please fill in all the required fields");
      setErrors(errors);
    }

    setIsLoading(false);
  };

  ////////// RENDERING THE PAGE /////////////

  // DISPLAY THE LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  }

  return (
    <div>
      <Header currentPage={"add_events"} />
      <BreadCrumpOrg title={"Add an event for organizer"} />

      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <AdminSideNav />

            <div className="col-lg-9 mt-4 mt-lg-0">
              <div className="card box">
                <div className="card-body">
                  <div className="alert alert-info alert-icon">
                    <i className="fas fa-info-circle" /> The fields preceded
                    with a red * are mandatory
                  </div>{" "}
                  <form
                    name="event"
                    noValidate="novalidate"
                    encType="multipart/form-data"
                    onSubmit={handleAddEvent}
                  >
                    <div id="event">
                      <div className="form-group">
                        <label className="required" htmlFor="event_category">
                          Category
                        </label>
                        <small
                          id="event_category_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Make sure to select right category to let the users
                          find it quickly
                        </small>

                        {/* EVENT CATEGORY */}
                        {errors.category && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.category}
                          </span>
                        )}
                        <SearchableDropdown
                          value={category}
                          options={eventCategories}
                          onChange={handleCategories}
                          isMulti={false}
                          required="required"
                        />
                      </div>
                      {/* organizer name */}
                      <div className="form-group">
                        <label className="required" htmlFor="event_category">
                          Organizer name
                        </label>
                        <small
                          id="event_category_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Make sure to select right organizer
                        </small>

                        {/* ORGANIZER  */}
                        {errors.orgnizer_name && (
                          <span className=" text-sm font-weight-bold text-danger">
                            {errors.orgnizer_name}
                          </span>
                        )}
                        <SearchableDropdown
                          value={organizer}
                          options={eventOrganizers}
                          onChange={handleOrganizer}
                          isMulti={false}
                          required="required"
                        />
                      </div>

                      {/* EVENT DETAILS */}
                      <fieldset className="form-group">
                        {/* <legend className="col-form-label required">
                          Event details
                        </legend>                          */}
                        <div className="tab-content">
                          <div
                            id="event_translations_en_a2lix_translations-fields"
                            className="tab-pane show active"
                            role="tabpanel"
                          >
                            <div id="event_translations_en">
                              {/* EVENT NAME */}
                              {errors.name && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.name}
                                </span>
                              )}
                              <div className="form-group">
                                <label
                                  htmlFor="event_translations_en_name"
                                  className="required"
                                >
                                  Event Name
                                </label>
                                <small
                                  id="event_translations_en_name_help"
                                  className="form-text text-muted mb-3"
                                >
                                  <i
                                    className="fas fa-info-circle mr-1"
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  ></i>
                                  Editing the title after the event is saved won
                                  &#039;t change the event url
                                </small>

                                <input
                                  type="text"
                                  id="event_translations_en_name"
                                  name="event[translations][en][name]"
                                  required="required"
                                  maxLength="100"
                                  pattern=".{10,}"
                                  aria-describedby="event_translations_en_name_help"
                                  className="form-control"
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>

                              {/* EVENT DESCRIPTION */}
                              {errors.description && (
                                <span className=" text-sm font-weight-bold text-danger">
                                  {errors.description}
                                </span>
                              )}
                              <div className="form-group">
                                <label htmlFor="event_translations_en_description">
                                  Description
                                </label>

                                <TextEditor
                                  value={description}
                                  onChange={handleDescription}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      {/* ATTENDEES */}
                      {errors.show_attendees && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.show_attendees}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Attendees
                        </legend>
                        <small
                          id="event_showattendees_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Show the attendees number and list in the event page
                        </small>
                        <div
                          id="event_showattendees"
                          aria-describedby="event_showattendees_help"
                        >
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_showattendees_0"
                              name="event[showattendees]"
                              required="required"
                              className="custom-control-input"
                              onChange={(e) => setShowAttendees(false)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_showattendees_0"
                            >
                              Hide
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_showattendees_1"
                              name="event[showattendees]"
                              required="required"
                              className="custom-control-input"
                              // value="1"
                              onChange={(e) => setShowAttendees(true)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_showattendees_1"
                            >
                              Show
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* ENABLE REVIEWS */}
                      {errors.enable_reviews && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.enable_reviews}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Enable reviews
                        </legend>
                        <div id="event_enablereviews">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_enablereviews_0"
                              name="event[enablereviews]"
                              required="required"
                              className="custom-control-input"
                              // value="1"
                              onChange={(e) => setEnableReviews(true)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_enablereviews_0"
                            >
                              Enable
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="event_enablereviews_1"
                              name="event[enablereviews]"
                              required="required"
                              className="custom-control-input"
                              // value="0"
                              onChange={(e) => setEnableReviews(false)}
                            />
                            <label
                              className="custom-control-label required"
                              htmlFor="event_enablereviews_1"
                            >
                              Disable
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      {/* LANGUAGES */}
                      <div className="form-group">
                        <label className="" htmlFor="event_languages">
                          Languages
                        </label>
                        <small
                          id="event_languages_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Select the languages that will be spoken in your event
                        </small>
                        <SearchableDropdown
                          value={languages}
                          options={languagesList}
                          onChange={(e) => setLanguages(e)}
                          isMulti={true}
                          placeholder={"Select Languages"}
                        />
                      </div>

                      {/* AUDIENCES */}
                      {errors.audiences && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.audiences}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Audiences
                        </legend>
                        <small
                          id="event_audiences_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Select the audience types that are targeted in your
                          event
                        </small>
                        <div
                          id="event_audiences"
                          aria-describedby="event_audiences_help"
                        >
                          {audienceList &&
                            audienceList.map((audience, index) => {
                              return (
                                <div className="custom-control custom-checkbox custom-control-inline">
                                  <input
                                    type="checkbox"
                                    id={"event_audiences_" + index}
                                    name={index}
                                    className="custom-control-input"
                                    value={audience}
                                    onChange={() => handleAudiences(audience)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"event_audiences_" + index}
                                  >
                                    {audience}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </fieldset>

                      {/* COUNTRY */}
                      <div className="form-group">
                        <label className="" htmlFor="event_country">
                          Country
                        </label>
                        <small
                          id="event_country_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Select the country that your event represents (ie: A
                          movie &#039;s country of production)
                        </small>

                        <SearchableDropdown
                          value={country}
                          options={countriesList}
                          onChange={handleCountry}
                          isMulti={false}
                          placeholder={"Select Country"}
                          required="required"
                        />
                      </div>

                      {/* YOUTUBE VIDEO URL */}
                      <div className="form-group">
                        <label htmlFor="event_youtubeurl">
                          Youtube video url
                        </label>
                        <small
                          id="event_youtubeurl_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          If you have an Youtube video that represents your
                          activities as an event organizer, add it in the
                          standard format:
                          https://www.youtube.com/watch?v=FzG4uDgje3M
                        </small>
                        <input
                          type="text"
                          id="event_youtubeurl"
                          name="event[youtubeurl]"
                          aria-describedby="event_youtubeurl_help"
                          className="form-control"
                          onChange={(e) => setVideoLink(e.target.value)}
                        />
                      </div>

                      {/* EXTERNAL LINK */}
                      <div className="form-group">
                        <label htmlFor="event_externallink">
                          External link
                        </label>
                        <small
                          id="event_externallink_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          If your event has a dedicated website, enter its url
                          here
                        </small>
                        <input
                          type="text"
                          id="event_externallink"
                          name="event[externallink]"
                          aria-describedby="event_externallink_help"
                          className="form-control"
                          onChange={(e) => setExternalLink(e.target.value)}
                        />
                      </div>

                      {/* PHONE NUMBER */}
                      {errors.phone_number && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.phone_number}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="event_phonenumber" className="required">
                          Contact phone number
                        </label>
                        <small
                          id="event_phonenumber_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Enter the phone number to be called for inquiries
                        </small>
                        <input
                          type="text"
                          id="event_phonenumber"
                          name="event[phonenumber]"
                          aria-describedby="event_phonenumber_help"
                          className="form-control"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required="required"
                        />
                      </div>

                      {/* EMAIL ADDRESS */}
                      {errors.email && (
                        <span className=" text-sm font-weight-bold text-danger">
                          {errors.email}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="event_email" className="required">
                          Contact email address
                        </label>
                        <small
                          id="event_email_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Enter the email address to be reached for inquiries
                        </small>
                        <input
                          type="text"
                          id="event_email"
                          name="event[email]"
                          aria-describedby="event_email_help"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          required="required"
                        />
                      </div>

                      {/* TWITTER */}
                      <div className="form-group">
                        <label htmlFor="event_twitter">Twitter</label>
                        <input
                          type="text"
                          id="event_twitter"
                          name="event[twitter]"
                          className="form-control"
                          onChange={(e) => setTwitter(e.target.value)}
                        />
                      </div>

                      {/* INSTAGRAM */}
                      <div className="form-group">
                        <label htmlFor="event_instagram">Instagram</label>
                        <input
                          type="text"
                          id="event_instagram"
                          name="event[instagram]"
                          className="form-control"
                          onChange={(e) => setInstagram(e.target.value)}
                        />
                      </div>

                      {/* FACEBOOK */}
                      <div className="form-group">
                        <label htmlFor="event_facebook">Facebook</label>
                        <input
                          type="text"
                          id="event_facebook"
                          name="event[facebook]"
                          className="form-control"
                          onChange={(e) => setFacebook(e.target.value)}
                        />
                      </div>

                      {/* LINKEDIN */}
                      <div className="form-group">
                        <label htmlFor="event_linkedin">LinkedIn</label>
                        <input
                          type="text"
                          id="event_linkedin"
                          name="event[linkedin]"
                          className="form-control"
                          onChange={(e) => setLinkedin(e.target.value)}
                        />
                      </div>

                      {/* ARTISTS */}
                      <div className="form-group">
                        <label htmlFor="event_artists">Artists</label>
                        <small
                          id="event_artists_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Enter the list of artists that will perform in your
                          event (press Enter after each entry)
                        </small>
                        <TagsInput
                          value={artists}
                          onChange={handleArtists}
                          inputProps={{ placeholder: "Enter Artists" }}
                          onlyUnique={true}
                        />
                      </div>

                      {/* TAGS */}
                      {errors.tags && (
                        <span style={{ marginTop: 0, color: "red" }}>
                          {errors.tags}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="event_tags">Tags</label>
                        <small
                          id="event_tags_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          To help attendee find your event quickly, enter some
                          keywords that identify your event (press Enter after
                          each entry)
                        </small>
                        <TagsInput
                          value={tags}
                          onChange={handleChange}
                          inputProps={{ placeholder: "Add tags" }}
                          onlyUnique={true}
                        />
                      </div>

                      {/* MAIN EVENT IMAGE */}
                      {errors.main_event_image && (
                        <span style={{ marginTop: 0, color: "red" }}>
                          {errors.main_event_image}
                        </span>
                      )}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Main event image
                        </legend>
                        <small
                          id="event_imageFile_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Choose the right image to represent your event (We
                          recommend using at least a 1200x600px (2:1 ratio)
                          image )
                        </small>
                        <div className="vich-image">
                          <div className="custom-file">
                            <input
                              onChange={handleMainEventImage}
                              type="file"
                              id="event_imageFile_file"
                              required="required"
                              className="custom-file-input"
                            />
                            <label
                              htmlFor="event_imageFile_file"
                              className="custom-file-label"
                            ></label>
                          </div>
                        </div>

                        {main_event_image && (
                          <>
                            <img
                              src={main_event_image}
                              alt="Main Event"
                              width={200}
                              height={200}
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                borderRadius: "15px",
                              }}
                            />

                            {/* REMOVE MAIN EVENT IMAGE */}
                            <button
                              onClick={(e) => handleRemoveMainEventImage(e)}
                              className="btn btn-sm btn-danger d-block"
                              style={{ width: "fit-content" }}
                            >
                              Remove
                            </button>
                          </>
                        )}
                      </fieldset>

                      {/* IMAGES GALLERY */}
                      <fieldset className="form-group">
                        <legend className="col-form-label">
                          Images gallery
                        </legend>
                        <small
                          id="event_images_help"
                          className="form-text text-muted mb-3"
                        >
                          <i
                            className="fas fa-info-circle mr-1"
                            style={{ color: layout_page?.primary_color }}
                          ></i>
                          Add other images that represent your event to be
                          displayed as a gallery
                        </small>
                        <div id="event_images" className="form-collection">
                          {fieldsets}
                          <div className="image-gallery">
                            <div className="row">
                              {/* LIST OF GALLERY IMAGES */}
                              {gallery.map((image, index) => (
                                <div key={index} className={"col"}>
                                  <div className="d-flex flex-column ">
                                    <img
                                      src={image}
                                      alt={`Event ${index + 1}`}
                                      width={100}
                                      height={70}
                                      style={{
                                        margin: 10,
                                        marginLeft: 0,
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <button
                                      onClick={() =>
                                        handleRemoveGalleryImage(index)
                                      }
                                      className="btn btn-sm btn-danger"
                                      style={{ width: "fit-content" }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* ADDING A NEW GALLERY IMAGE */}
                          <button
                            onClick={handleAddFieldset}
                            className="mt-3 btn btn-outline-dark btn-sm"
                          >
                            <i className="fas fa-plus-square" /> Add
                          </button>
                        </div>
                      </fieldset>

                      {/* EVENT REPEATS */}
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div>
                          <label htmlFor="event_repeats">Event Repeats</label>
                        </div>

                        <div className="ml-auto">
                          <Switch
                            id="event_repeats"
                            color="warning"
                            onChange={(e) => setEventRepeats(!eventRepeats)}
                            checked={eventRepeats}
                          />
                        </div>
                      </div>

                      {/* USER SELECTING HOW OFTEN THE EVENT REPEATS */}
                      {eventRepeats && (
                        <>
                          <div className="form-group mt-3">
                            <label htmlFor="event_tags">Repeat Every</label>
                            <small
                              id="event_tags_help"
                              className="form-text text-muted mb-3"
                            >
                              <i
                                className="fas fa-info-circle mr-1"
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                              ></i>
                              How often should the event repeat. e.g 2
                            </small>
                            <input
                              type="number"
                              value={repeat_every}
                              onChange={(e) => setRepeatEvery(e.target.value)}
                              className="form-control"
                              onlyUnique={true}
                            />
                          </div>

                          {/* SELECTING THE REPEAT METRIC */}
                          <div className="form-group" style={{ zIndex: 1000 }}>
                            <label htmlFor="event_tags">Repeat Metric</label>
                            <small
                              id="event_tags_help"
                              className="form-text text-muted mb-3"
                            >
                              <i
                                className="fas fa-info-circle mr-1"
                                style={{
                                  color: layout_page?.primary_color,
                                }}
                              ></i>
                              Choose the Repeat Metric, e.g weeks if event
                              happens every after a given number of weeks.
                            </small>
                            <SearchableDropdown
                              value={repeat_metric}
                              options={repeatMetrics}
                              onChange={handleRepeatMetric}
                            />
                          </div>
                        </>
                      )}

                      {/* EVENT DATES */}
                      <fieldset className="form-group">
                        <legend className="col-form-label required">
                          Event Dates
                        </legend>
                        <div
                          id="event_eventdates"
                          className="form-collection eventdates-collection manual-init"
                        >
                          {eventdates}
                          <button
                            onClick={handleAddEventDate}
                            className="mr-3 mt-4 btn btn-outline-dark btn-sm "
                            style={{}}
                          >
                            <i className="fas fa-plus-square" /> Add a new event
                            date
                          </button>
                        </div>
                      </fieldset>

                      {/* SAVE */}
                      <div className="form-group">
                        <button
                          type="submit"
                          id="event_save"
                          name="event[save]"
                          className="btn text-white btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
