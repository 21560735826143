import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { tableStore } from "../../store/table_store";

export default function TablesModal(props) {
  const {
    incrementQuantity,
    decrementQuantity,
    table_id,
    venue_id,
    onHide,
    table_name,
    getSelectedTables,
  } = props;

  const rooms = tableStore((state) => state.rooms);
  const room = rooms.find((room) => room?.venue?.value === venue_id);

  // State to keep track of selected tables
  const [selectedTables, setSelectedTables] = useState({});

  const getTableImage = (numberOfSeats, tableType, tableId) => {
    const isSelected = selectedTables[tableId];

    const imagePrefix = isSelected
      ? "grey"
      : tableType === "VIP"
      ? "blue"
      : "brown";

    return require(`./../../assets/table_images/${numberOfSeats}${imagePrefix}.svg`);
  };

  // taggle the table(select or unselect the table)
  // const handleTableClick = (tableId) => {
  //   setSelectedTables((prevSelectedTables) => ({
  //     ...prevSelectedTables,
  //     [tableId]: !prevSelectedTables[tableId],
  //   }));

  //   const isSelected = !selectedTables[tableId];
  //   isSelected ? incrementQuantity(table_id) : decrementQuantity(table_id);

  // };

  // taggle the table(select or unselect the table)
  const handleTableClick = (tableId, selectedTable) => {
    // Check if the selectedTable matches the current table_name
    if (selectedTable === table_name) {
      setSelectedTables((prevSelectedTables) => {
        const isSelected = !prevSelectedTables[tableId];

        // Trigger the increment or decrement based on the selection state
        isSelected ? incrementQuantity(table_id) : decrementQuantity(table_id);

        const updatedSelectedTables = { ...prevSelectedTables };

        // Add or remove the table from the array based on the selection state
        if (isSelected) {
          updatedSelectedTables[tableId] = room?.tables.find(
            (table) => table.id === tableId
          );
        } else {
          delete updatedSelectedTables[tableId];
        }

        // Pass the most up-to-date selected tables to the parent
        getSelectedTables(updatedSelectedTables);

        // Return the updated state
        return updatedSelectedTables;
      });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className=" fw-bold ">
            Select <span className="text-danger">{table_name} table </span> of
            your choice{" "}
          </p>

          <div className=" d-flex gap-2">
            {room?.tableTypes?.map((table_type) => (
              <div key={table_type?.id}>
                <div
                  className="border"
                  style={{
                    backgroundColor: table_type?.table_color,
                    height: 30,
                    width: 40,
                    borderRadius: 8,
                  }}
                ></div>
                <p className=" h6 fw-medium  ">
                  {table_type?.table_type} Table
                </p>
              </div>
            ))}
            <span>
              <div
                className="border"
                style={{
                  backgroundColor: "#C8C8C8",
                  height: 30,
                  width: 40,
                  borderRadius: 8,
                }}
              ></div>
              <p className=" h6 fw-medium  ">Booked Table</p>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 bg-gray p-3">
          <div className="bg-white">
            <div className="row mx-5 position-relative">
              {/* Filter tables based on table_name prop and map over them */}
              {room?.tables?.length > 0 &&
                room?.tables.map((table) => (
                  <div
                    className={`col py-3 d-flex flex-column align-items-center ${
                      table?.selectedTable !== table_name || table?.isBooked
                        ? " disabled-table "
                        : ""
                    } `}
                    key={table?.id}
                    onClick={() =>
                      handleTableClick(table?.id, table?.selectedTable)
                    }
                  >
                    <span className=" position-absolute top-50 translate-middle cursor-pointer text-white fw-bold">
                      {table?.tableLabel}
                    </span>
                    <img
                      src={
                        table?.isBooked
                          ? require(`./../../assets/table_images/${table?.numberOfSeatsPerTable}grey.svg`)
                          : getTableImage(
                              table?.numberOfSeatsPerTable,
                              table?.selectedTable,
                              table?.id
                            )
                      }
                      width={120}
                      height={80}
                      className="cursor-pointer"
                      alt="No table"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
