import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa";

function DeleteComponentModal({ itemName, handleDelete }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex gap-1" style={{ color: "gray" }}>
        <span
          className="d-flex gap-1 cursor-pointer"
          style={{ marginTop: "5px" }}
          onClick={() => {
            handleShow();
          }}
        >
          <FaTrash />
          <h6>Delete</h6>
        </span>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="text-danger"
            style={{ margin: "0 auto", textAlign: "center", width: "100%" }}
          >
            Delete {itemName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you want to delete this {itemName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={(e) => handleDelete(e)}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteComponentModal;
