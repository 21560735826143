import React, { useState } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { useNavigate, useParams } from "react-router-dom";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import Loader from "../../../components/loader/loader";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import { useEffect } from "react";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import { useSettings } from "../../../store/setting_store";
import { Breadcrumpadmin2 } from "../components/breadcrump_admin";

export default function EditAudiences() {
  // const { isAdmin } = useContext(myContext);
  const isAdmin = userStore((state) => state.isAdmin);
  const updateAdiences = useEvents((state) => state.updateAdiences);
  const uploadaudienceImage = useEvents((state) => state.uploadaudienceImage);
  const deleteAudienceImage = useEvents((state) => state.deleteAudienceImage);
  const layout_page = useSettings((state) => state.layout_page);
  //HANDLING AUDIENCE ADD UP
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { audience_id } = useParams();
  const { document } = useFetchDocument("audiences", audience_id);
  const [form_audience, setFormAudience] = useState(document || {});
  const navigate = useNavigate();

  useEffect(() => {
    setFormAudience(document);
  }, [document]);

  //handle change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormAudience((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditAudience = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      try {
        await updateAdiences(audience_id, {
          name: form_audience?.name,
          image: form_audience?.image,
        });
        displaySuccessToast("Update Successful");
        navigate("/manage_audiences");
      } catch (error) {
        displayErrorToast("Update failed");
      }
    } else {
      setErrors(errors);
    }
    setIsLoading(false);
  };

  // HANDLING IMAGE CAPTURE
  const [audienceUrl, setAudienceUrl] = useState(form_audience?.image);
  useEffect(() => {
    const fetchAudienceUrl = async () => {
      const url = await uploadaudienceImage(form_audience?.image);
      setAudienceUrl(url);
    };
    //fetch the audience url from the firebase
    if (form_audience?.image) {
      fetchAudienceUrl();
    }
  }, [form_audience?.image]);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await uploadaudienceImage(file);
      if (imageUrl) {
        setAudienceUrl(imageUrl);
        setFormAudience((prevFormData) => ({
          ...prevFormData,
          image: imageUrl,
        }));
      }
    }
  };

  //handling the delete audience Image
  const handledeleteAudienceImage = async () => {
    if (audienceUrl) {
      await deleteAudienceImage(audienceUrl);
      setAudienceUrl("");
      setFormAudience((prevFormData) => ({
        ...prevFormData,
        image: "",
      }));
    }
  };

  //valification
  function validateForm() {
    const errors = {};
    if (!form_audience?.name) {
      errors.form_audience.name = "name is required";
    }
    if (!form_audience?.imageFile) {
      errors.form_audience.imageFile = "Image  is required";
    }

    return errors;
  }

  //setting the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}

        <Breadcrumpadmin2
          title="Edit audience"
          page_link="Manage Audiences"
          link="manage_audiences"
        />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_audiences"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="card box">
                  <div className="card-body">
                    <form
                      name="audience"
                      onSubmit={handleEditAudience}
                      noValidate="novalidate"
                      encType="multipart/form-data"
                    >
                      <div id="audience">
                        {/*ADDING NAME */}

                        <div className=" tab-content">
                          <div
                            id="audience_translations_en_a2lix_translations-fields"
                            className="tab-pane show active "
                            role="tabpanel"
                          >
                            <div id="audience_translations_en">
                              <div className="form-group d-grid">
                                <label
                                  htmlFor="audience_translations_en_name"
                                  className="required"
                                >
                                  Name
                                </label>
                                {errors.form_audience?.name && (
                                  <span className=" text-sm font-weight-bold text-danger">
                                    {errors.form_audience?.name}
                                  </span>
                                )}
                                <input
                                  type="text"
                                  name="name"
                                  value={form_audience?.name || ""}
                                  onChange={(e) => handleInputChange(e)}
                                  required="required"
                                  maxLength={50}
                                  pattern=".{1,}"
                                  className={
                                    errors.form_audience?.name
                                      ? " border border-danger form-control"
                                      : "form-control"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ADDING IMAGE */}
                        <fieldset className="form-group d-grid">
                          <legend className="col-form-label">Image</legend>
                          {errors.form_audience?.imageFile && (
                            <span className=" text-sm font-weight-bold text-danger">
                              {errors.form_audience?.imageFile}
                            </span>
                          )}
                          {audienceUrl && (
                            <div className="d-grid">
                              <img
                                src={form_audience?.image}
                                alt=""
                                style={{ maxWidth: "50%" }}
                              />
                              <div
                                onClick={handledeleteAudienceImage}
                                className=" bg-danger py-1 px-2 text-white d-flex justify-content-center cursor-pointer"
                                style={{ width: "50%", fontWeight: "500" }}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                          {!audienceUrl && (
                            <div className="vich-image">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  onChange={handleFileSelect}
                                  className="custom-file-input"
                                />
                                <label
                                  htmlFor="audience_imageFile_file"
                                  className="custom-file-label"
                                />
                              </div>
                            </div>
                          )}
                        </fieldset>

                        {/* SUBMIT BUTTON */}
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn text-white btn"
                            style={{
                              backgroundColor: layout_page?.primary_color,
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
