import React from "react";
import DeleteButton from "../../Components";
import { tableStore } from "../../../../../store/table_store";
import { shallow } from "zustand/shallow";
import { formatNumberWithCommas } from "../../../../../services/helpers";

const AddedTableTypes = ({ tableType_id, venue_id }) => {
  //delete table type
  const deleteTableType = tableStore((state) => state.deleteTableType);
  const tableTypes = tableStore(
    (state) =>
      state.tableTypes.filter(
        (tableType) => tableType.venue_tableType_id === tableType_id
      ),
    shallow
  );

  return (
    <div class="col-md-6 mt-5">
      <span className=" fw-bolder text-lg">Added Types</span>

      <div className="table-responsive">
        <table className="table table-hover table-vcenter text-nowrap">
          <>
            <thead>
              <tr>
                <th>Table Type</th>
                <th>Table Color</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <>
                {Array.isArray(tableTypes) && tableTypes.length > 0 ? (
                  tableTypes.map((table) => (
                    <>
                      <tr key={table?.id}>
                        <td>
                          <p>{table?.table_type}</p>
                          <span className=" d-flex">
                            <p className="mr-1">Table:</p>{" "}
                            <p>{table?.table_shape}</p>
                          </span>
                        </td>

                        <td>
                          <div
                            className="border"
                            style={{
                              backgroundColor: table.table_color,
                              height: 30,
                              width: 60,
                              borderRadius: 8,
                            }}
                          ></div>
                        </td>

                        <td>
                          <p> Shs:{formatNumberWithCommas(table?.price)}</p>
                        </td>
                      </tr>

                      <div
                        className="mt-2 mb-5"
                        onClick={() => deleteTableType(table?.id)}
                      >
                        <DeleteButton text="delete type" />
                      </div>
                    </>
                  ))
                ) : (
                  <>No Table Added yet</>
                )}
              </>
            </tbody>
          </>
        </table>
      </div>
    </div>
  );
};

export default AddedTableTypes;
