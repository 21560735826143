import { create } from "zustand";

import { fetchPhonebooks } from "../services/firebase/phone_book_repository";
import { displayErrorToast } from "../services/helpers";

// MUTUAL FRIENDS ALGORITHM
const getMutualFriends = (phonebooks) => {
  let users = {};
  let nonUsers = {};
  phonebooks.forEach((item) => {
    if (!item.phone_number) {
    } else {
      if (nonUsers[item.phone_number]) {
        delete nonUsers[item.phone_number];
      }
      users[item.phone_number] = item.phone_number;
    }
    item.phone_book.forEach((n) => {
      if (users[n]) {
        return;
      }
      if (!nonUsers[n]) {
        nonUsers[n] = [item.phone_number || ""];
        return;
      }
      const isMutualFriend = nonUsers[n].includes(item.phone_number || "");
      if (isMutualFriend) return;
      nonUsers[n].push(item.phone_number || "");
      return;
    });
  });
  return { users, nonUsers };
};

const usePhonebookStore = create((set, get) => ({
  phonebooks: [],
  users: {},
  nonUsers: {},

  getPhonebooks: async () => {
    try {
      const phonebooks = await fetchPhonebooks();
      const { users, nonUsers } = getMutualFriends(phonebooks);

      set(() => ({ phonebooks }));
      set(() => ({ users }));
      set(() => ({ nonUsers }));
    } catch (error) {
      console.log("SOMETHING WENT WRONG FETCHING PHONEBOOKS: " + error);
      // displayErrorToast('Something went wrong fetching phonebooks');
    }
  },
}));

export default usePhonebookStore;
