import React from "react";
import BreadCrump from "../../../components/BreadCrump/bread_crump";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";

export default function ArticlesSideNav() {
  return (
    <aside className="col-lg-3 order-1 order-lg-0">
      <div className="card card-filter">
        <article className="card-group-item">
          <header className="card-header">
            <a
              className=""
              aria-expanded="true"
              href="#"
              data-toggle="collapse"
              data-target="#filter-keyword"
            >
              <i className="icon-action fa fa-chevron-down" />
              <h6 className="title">Search help center</h6>
            </a>
          </header>
          <div className="filter-content collapse show" id="filter-keyword">
            <div className="card-body">
              <form className="pb-3" action="/how_it_works">
                <input
                  className="form-control mb-3"
                  placeholder="Keyword"
                  name="keyword"
                  type="text"
                />
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-block"
                >
                  <i className="fas fa-search" /> Search
                </button>
              </form>
            </div>
          </div>
        </article>
        <article className="card-group-item">
          <header className="card-header">
            <a href="#" data-toggle="collapse" data-target="#filter-event-type">
              <i className="icon-action fa fa-chevron-down" />
              <h6 className="title">Categories</h6>
            </a>
          </header>
          <div className="filter-content collapse show" id="filter-event-type">
            <div className="card-body">
              <ul className="list list-unstyled">
                <li className="mb-2">
                  <a
                    href="/attendee_support"
                    className="font-weight-semibold"
                  >
                    <i className="fas fa-angle-right ml-1 mr-1 pr-2" />
                    Attendee
                    <span className="float-right form-check-label">
                      <span className="badge badge-primary">17</span>
                    </span>
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/organizer_support"
                    className="font-weight-semibold"
                  >
                    <i className="fas fa-angle-right ml-1 mr-1 pr-2" />
                    Organizer
                    <span className="float-right form-check-label">
                      <span className="badge badge-primary">10</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className="card-group-item">
          <header className="card-header">
            <a href="#" data-toggle="collapse" data-target="#collapsePopular">
              <i className="icon-action fa fa-chevron-down" />
              <h6 className="title">Popular</h6>
            </a>
          </header>
          <div className="filter-content collapse show" id="collapsePopular">
            <div className="card-body">
              <article className="row align-items-center mb-3">
                <div className="col-12">
                  <small className="text-muted">Attendee</small>
                  <h6 className="mb-0">
                    <a
                      href="/articles"
                      className="text-1"
                    >
                      How to book tickets?
                    </a>
                  </h6>
                </div>
              </article>
              <article className="row align-items-center mb-3">
                <div className="col-12">
                  <small className="text-muted">Organizer</small>
                  <h6 className="mb-0">
                    <a
                      href="/articles"
                      className="text-1"
                    >
                      How to verify a purchased ticket?
                    </a>
                  </h6>
                </div>
              </article>
            </div>
          </div>
        </article>
        <article className="card-group-item">
          <header className="card-header">
            <a href="#" data-toggle="collapse" data-target="#collapseTags">
              <i className="icon-action fa fa-chevron-down" />
              <h6 className="title">Tags</h6>
            </a>
          </header>
          <div className="filter-content collapse show" id="collapseTags">
            <div className="card-body">
              <a
                href="/how_it_works?keyword=%D8%A7%D9%84%D8%AA%D8%B0%D9%83%D8%B1%D8%A9"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                التذكرة
              </a>
              <a
                href="/how_it_works?keyword=ticket"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                ticket
              </a>
              <a
                href="/how_it_works?keyword=billet"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                billet
              </a>
              <a
                href="/how_it_works?keyword=%D8%AA%D8%AA%D8%B5%D9%84%20"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                تتصل{" "}
              </a>
              <a
                href="/how_it_works?keyword=%D9%85%D9%86%D8%B8%D9%85%20%D8%A7%D9%84%D8%AD%D8%AF%D8%AB"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                منظم الحدث
              </a>
              <a
                href="/how_it_works?keyword=contact%20"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                contact{" "}
              </a>
              <a
                href="/how_it_works?keyword=%20event%20organizer"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                {" "}
                event organizer
              </a>
              <a
                href="/how_it_works?keyword=contacter"
                className="btn btn-sm btn-default mr-2 mb-2 mt-2"
              >
                contacter
              </a>
            </div>
          </div>
        </article>
      </div>
      <article className="card-group-item d-none">
        <div className="card-body p-0">
          <article className="box bg-primary">
            <figure className="itemside mb-4">
              <div className="aside align-self-center">
                <span className="icon-wrap icon-sm round bg-white">
                  <i className="fas fa-mail-bulk text-primary" />
                </span>
              </div>
              <figcaption className="text-wrap">
                <h5 className="title text-white">
                  Subscribe to our newsletter
                </h5>
              </figcaption>
            </figure>
            <div className="form-row">
              <div className="form-group col mb-0">
                <input
                  id="newsletter-email"
                  type="email"
                  className="newsletter-email form-control rounded-0 border-0 line-height-1"
                  placeholder="Email address"
                  required="true"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col mb-0">
                <button
                  id="newsletter-subscribe"
                  data-target="/en/newsletter-subscribe"
                  type="button"
                  className="btn btn-dark justify-content-center w-100 rounded-0"
                >
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </article>
        </div>
      </article>{" "}
    </aside>
  );
}
