import React from "react";

const AudienceComponent = ({ isChecked, option, htmlFor, handleChange }) => {
  const ref = React.useRef(null);

  const onClick = () => {
    if (!ref.current) return;
    ref.current.click();
  };

  const className = isChecked ? 'audience-btn' : '';
  return (
    <label
      className={`btn btn-light btn-sm py-2 px-3 ${className}`}
      data-toggle="tooltip"
      title={''}
      data-original-title={option.value}
      htmlFor={htmlFor}
      onClick={onClick}
    >
      <input
        type="radio"
        value={option?.value}
        onChange={(e) => handleChange(e, option)}
        checked={isChecked}
        ref={ref}
      />
      <img src={option.image} className="img-15-15" alt={option.value} />
    </label>
  );
};

export default AudienceComponent;
