import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import { Link, useNavigate } from "react-router-dom";
import { IconPickerItem } from "react-fa-icon-picker-alen";
import PopingOutCategories from "./PopingOutCategories";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import CustomPagination from "../../../components/pagination/Pagination";
import { useSettings } from "../../../store/setting_store";
import EventsCount from "./EventsCount";
import BreadcrumpAdmin from "../components/breadcrump_admin";

export default function ManageCategories() {
  //fetching events categories
  const isAdmin = userStore((state) => state.isAdmin);
  const getEventsCategories = useEvents((state) => state.getEventCategories);
  const categories = useEvents((state) => state.eventCategories);

  const layout_page = useSettings((state) => state.layout_page);

  useEffect(() => {
    async function fetchData() {
      await getEventsCategories();
    }
    fetchData();
  }, [categories.length]);

  // GETTING THE NUMBER OF AUDIENCES
  const numofCategories = categories.length;

  const navigate = useNavigate();
  const [categoryFilter, setCategoryFilter] = useState("");

  //filtering by name
  const filteredCateg = categories.filter((category) => {
    return (
      !categoryFilter ||
      (category.name &&
        category.name.toLowerCase().includes(categoryFilter.toLowerCase()))
    );
  });

  // Apply filtering to categories
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const filteredCategories = filteredCateg.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  // PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(15);

  // Get current filtered categories for the current page
  const indexOfLastcategories = currentPage * categoriesPerPage;
  const indexOfFirstCategories = indexOfLastcategories - categoriesPerPage;
  const current_categories = filteredCategories.slice(
    indexOfFirstCategories,
    indexOfLastcategories
  );

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Event Categories" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_event_categories"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  {/* SEARCH AND FILTERING BAR */}
                  <div className="row">
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {numofCategories} result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 text-center mb-3 mb-lg-0">
                      <form className="">
                        <div className=" d-flex text-center">
                          <label htmlFor="slug" className="text-center mt-2">
                            Search
                          </label>
                          <input
                            type="text"
                            className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                            id="slug"
                            name="slug"
                            data-minimum-input-length={0}
                            onChange={(e) => setCategoryFilter(e.target.value)}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-sm-12 col-lg-6 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add-new-category"
                        className="btn text-white ml-3"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="tooltip"
                        title="Add a new category"
                      >
                        <i className="fas fa-plus" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Events count</th>
                              <th>Icon</th>
                              <th>Image</th>
                              <th>Is featured</th>
                              <th>Status</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_categories.map((category) => (
                              <tr key={category?.id}>
                                {/* DISPLAYING CATEGORY NAME */}
                                <td>{category?.name}</td>

                                {/* DISPLAYING NUMBER OF EVENTS */}
                                <td>
                                  <EventsCount category_id={category?.id} />
                                </td>

                                {/* DISPLAYING CATEGORY IMAGE */}
                                <td>
                                  <IconPickerItem
                                    icon={category.icon}
                                    size={20}
                                    color="#000"
                                  />
                                </td>

                                {/* DISPLAYING IMAGE */}
                                <td>
                                  <Link
                                    className="fancybox"
                                    data-toggle="tooltip"
                                    to=""
                                    title=""
                                    data-original-title="Enlarge"
                                  >
                                    <img
                                      src={category?.image}
                                      alt=""
                                      className="img-thumbnail img-50-50"
                                    />
                                  </Link>
                                </td>

                                {/* FEATURED DISPLAY ORDER */}
                                <td>
                                  {category?.isFeatured === true
                                    ? `Yes (Order ${category?.displayorder})`
                                    : "No"}
                                </td>

                                {/* DISPLAYING STATUS */}
                                <td>
                                  {category?.isVisible === true ? (
                                    <span className=" badge badge-success">
                                      <i className="fas fa-eye fa-fw" /> Visible
                                    </span>
                                  ) : (
                                    <span className=" badge badge-warning">
                                      <i className="fas fa-eye-slash fa-fw" />
                                      Invisible
                                    </span>
                                  )}
                                  <br />
                                  <br />
                                  {category?.isFeatured === true && (
                                    <span className=" badge badge-info">
                                      <i class="fas fa-solid fa-star fa-fw"></i>
                                      Featured
                                    </span>
                                  )}
                                </td>

                                <td className="text-center">
                                  <div className="item-action dropdown">
                                    <PopingOutCategories
                                      category_id={category?.id}
                                      isVisible={category?.isVisible}
                                      featured={category?.isFeatured}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {filteredCategories?.length > 15 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          filteredCategories?.length / categoriesPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
