import { useEffect, useState } from "react";
import { useVenues } from "../../../store/venues_store";

export default function VenueCount({ venue_type_id }) {
  const getVenues = useVenues((state) => state.getVenues);

  const [count, setCount] = useState(0);

  //counting venues that have the same venue_type
  useEffect(() => {
    const getV = async () => {
      const venues = await getVenues();

      const count = venues.filter(
        (venue) => venue?.venue_type?.value === venue_type_id
      );

      setCount(count.length);
    };
    getV();
  }, [venue_type_id]);

  return <div>{count}</div>;
}

export function AmenitiesCount({ amenity_name }) {
  const getVenues = useVenues((state) => state.getVenues);

  const [count, setCount] = useState(0);
  useEffect(() => {
    const getAmmenities = async () => {
      const venues = await getVenues();

      const count = venues?.filter((venue) =>
        venue?.amenities?.some((amenity) => amenity === amenity_name)
      );

      setCount(count.length);
    };
    getAmmenities();
  }, [amenity_name]);

  return <div>{count}</div>;
}
