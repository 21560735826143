import React, { useEffect, useState } from "react";

import EventTile from "../event_tile/event_tile";
import { useEvents } from "../../store/event_store";
import CustomPagination from "../pagination/Pagination";
import SearchCategory from "./SearchCategory";

function UpcomingEvents({ events, clearQuery }) {
  const getUpcomingEvents = useEvents((state) => state.getUpcomingEvents);

  useEffect(() => {
    const fetchData = async () => {
      await getUpcomingEvents();
    };

    fetchData();
  }, [events?.length]);

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(21);
  //get current events
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFastEvent = indexOfLastEvent - eventsPerPage;
  const current_events = events?.slice(indexOfFastEvent, indexOfLastEvent);

  return (
    <section className="section-content padding-y-lg bg-white">
      <div className="container">
        <header className="section-headin mb-5">
          <h4 className="title-section text-center text-muted text-uppercase">
            Upcoming events
          </h4>
        </header>

        {/* a nav to search by category */}
        <SearchCategory clearQuery={clearQuery} />

        <div className="row-sm">
          {current_events.length > 0 ? (
            current_events?.map((upcomingEvent) => {
              return (
                <EventTile
                  event={upcomingEvent}
                  key={upcomingEvent?.id}
                  num_tiles={"4"}
                />
              );
            })
          ) : (
            <>
              <div className="col order-1 order-lg-2">
                <div className="alert alert-info alert-icon">
                  <i className="fas fa-exclamation-circle" /> No events found
                  for now, but something big is coming soon as possible
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* PAGINATION */}
      {events?.length > 21 && (
        <CustomPagination
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(events?.length / eventsPerPage)}
        />
      )}
    </section>
  );
}

export default UpcomingEvents;
