import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

import PopingOutEvents from "./events/PopingOutEvents";
import EventNamePopover from "./events/EventName";
import Loader from "../../components/loader/loader";
import { useEvents } from "../../store/event_store";
import TicketsNumber from "../organizer_dashboard/components/TicketsNumber";
import CircleProgressBar from "../organizer_dashboard/components/CircleProgressBar";
import VenueOrganizer from "./venues/VenueOrganizer";
import Status from "./events/Status";
export default function EventsAdmin() {
  const getEvents = useEvents((state) => state.fetchEvents);
  const events = useEvents((state) => state.events);
  const upcomingEvents = useEvents((state) => state.upcomingEvents);
  const [isLoading, setIsLoading] = useState(false);
  /////////////FETCHING VENUETYPES//////////////////////
  useEffect(() => {
    async function fetchData() {
      await getEvents();
    }
    fetchData();
  }, [events.length, upcomingEvents?.length]);
  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* MAIN SECTION */}
      <table className="table table-hover table-vcenter">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Event</th>
            <th>Organizer</th>
            <th>Sales</th>
            <th>Status</th>
            <th>Attendance</th>
            <th className="text-center">
              <i className="fas fa-cog" />
            </th>
          </tr>
        </thead>
        <tbody>
          {upcomingEvents.slice(0, 3).map((event, index) => (
            <tr key={event?.id}>
              {/* DISPLAYING EVENT IMAGE */}
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {event?.main_event_image && (
                    <Link
                      className="fancybox"
                      to=""
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Enlarge"
                    >
                      <img
                        src={event.main_event_image}
                        alt=""
                        className="img-thumbnail img-50-50"
                      />
                    </Link>
                  )}

                  {/* DISPLAYING EVENT NAME */}
                  <EventNamePopover
                    event_id={event?.id}
                    event_name={event?.name}
                  />
                </div>
              </td>

              {/* DISPLAYING EVENT ORGANIZER */}
              <td>
                <VenueOrganizer organizer_ID={event.organizer_id} />
              </td>
              <td>
                <TicketsNumber event_id={event?.id} />
              </td>
              {/* STATUS */}
              <td>
                <Status event={event} />
              </td>
              <td className="text-center">
                <CircleProgressBar event_id={event?.id} />
              </td>
              <td className="text-center">
                <PopingOutEvents
                  event_id={event.id}
                  setIsLoading={setIsLoading}
                />
              </td>
            </tr>
          ))}

          {/* END OF EVENTS */}
        </tbody>
      </table>
    </div>
  );
}
