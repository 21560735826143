/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import AdminSideNav from "../components/admin_side_nav";
import PopingOutEvents from "./PopingOutEvents";
import Loader from "../../../components/loader/loader";
import EventNamePopover from "./EventName";
import { userStore } from "../../../store/user_store";
import { useEvents } from "../../../store/event_store";
import CustomPagination from "../../../components/pagination/Pagination";
import {
  convertTimestampToDateTime,
  formatDate,
} from "../../../services/helpers";
import TicketsNumber from "../../organizer_dashboard/components/TicketsNumber";
import CircleProgressBar from "../../organizer_dashboard/components/CircleProgressBar";
import { useSettings } from "../../../store/setting_store";
import VenueOrganizer from "../venues/VenueOrganizer";
import Status from "./Status";
import SearchableDropdown from "../../../components/searchable_dropdown/searchable_dropdown";
import BreadcrumpAdmin from "../components/breadcrump_admin";
export default function ManageEvents() {
  const isAdmin = userStore((state) => state.isAdmin);
  const navigate = useNavigate();

  const { event_id } = useParams();
  //fetching events with their organizer
  const events = useEvents((state) => state.events);

  const getSpecificEvent = useEvents((state) => state.getEvent);
  const layout_page = useSettings((state) => state.layout_page);
  const eventCategories = useEvents((state) => state.eventCategories);

  const [isLoading, setIsLoading] = useState(false);
  const [eventsToDisplay, setEventsToDisplay] = useState(events);
  const [eventCategoriesOptions, setEventCategoriesOptions] = useState([]);
  const [event_category, setEventCategory] = useState("");
  const [publishedFilter, setPublishedFilter] = useState("all");

  //getting event categories

  useEffect(() => {
    async function fetchData() {
      if (event_id) {
        const specificEvent = await getSpecificEvent(event_id);
        setEventsToDisplay([specificEvent]);
      }
    }

    const getEventCategoriesOptions = () => {
      const categoriesOptions = eventCategories.map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      });
      setEventCategoriesOptions(categoriesOptions);
    };
    getEventCategoriesOptions();
    fetchData();
  }, [events.length, event_id, eventCategories.length]);

  // METHOD TO FILTER EVENTS
  function filterEvents(query) {
    const filteredEvents = events.filter((event) => {
      const eventName = event.name.toLowerCase();
      return eventName.includes(query.toLowerCase());
    });
    setEventsToDisplay(filteredEvents);
  }

  //FILTER BY CATEGORY
  function filterCategory(selectedCategoryId) {
    if (selectedCategoryId === "all") {
      setEventsToDisplay(events);
      setEventCategory("all");
      return;
    }

    const filteredEvents = events.filter((event) => {
      return event?.category?.value === selectedCategoryId;
    });

    setEventCategory(selectedCategoryId);
    setEventsToDisplay(filteredEvents);
  }

  //FILTER BY VENUE
  function filterVenue(query) {
    if (query.trim() === "") {
      setEventsToDisplay(events);
      return;
    }
    const filteredVenues = events.filter((event) => {
      const filteredDates = event.event_dates.filter((eventDate) => {
        return eventDate.venue?.toLowerCase().includes(query.toLowerCase());
      });
      return filteredDates.length > 0;
    });
    setEventsToDisplay(filteredVenues);
  }

  const filterPublishedEvents = eventsToDisplay.filter((event) => {
    const currentDate = new Date();
    const eventStartDate = convertTimestampToDateTime(
      event?.event_dates[0]?.start_date
    );
    const eventEndDate = convertTimestampToDateTime(
      event?.event_dates[0]?.end_date
    );

    return (
      publishedFilter === "all" ||
      (publishedFilter === "published" &&
        (event.visibility === "published" ||
          event.visibility === "" ||
          event.visibility === null)) ||
      (publishedFilter === "draft" && event.visibility === "draft") ||
      (publishedFilter === "upcoming" && eventStartDate >= currentDate) ||
      (publishedFilter === "past" && eventEndDate < currentDate)
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedEvents = filterPublishedEvents?.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;
    if (sortCriteria === "views") {
      if (sortDirection === "desc") {
        // Sort by popularity (views) in descending order
        return b.views - a.views;
      } else {
        // Sort by popularity (views) in ascending order
        return a.views - b.views;
      }
    } else if (sortCriteria === "created_at") {
      // Sort by creation date
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      // Handle other criteria sorting here
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(20);
  //get current events
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const current_events = sortedEvents?.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  //DISPLAY LOADER
  if (isLoading) {
    return <Loader />;
  }

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        {/* ADDING THE HEADER */}
        <Header />

        {/* BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Events" />

        {/* MAIN SECTION */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  ADDING THE ADMIN SIDE NAV */}
              <AdminSideNav currentPage={"manage_events"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    {/* DISPLAYING NUMBER OF EVENTS */}
                    <div className="col-sm-12 col-lg-2 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {eventsToDisplay?.length} result(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-8 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}-${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("-");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at-desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>
                          <option
                            value={`created_at-asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>
                          <option
                            value={`views-desc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "desc"
                            }
                          >
                            Popularity (desc)
                          </option>
                          <option
                            value={`views-asc`}
                            selected={
                              sortCriteria === "views" &&
                              sortDirection === "asc"
                            }
                          >
                            Popularity (asc)
                          </option>
                        </select>
                      </label>
                      <Link
                        to="/add_events"
                        className="btn text-white ml-3 my-2"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                      >
                        <i className="fas fa-plus" /> Add a new event
                      </Link>
                    </div>
                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          {/* SEARCH BY EVENT NAME */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="slug">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="slug"
                              name="slug"
                              data-minimum-input-length={0}
                              onChange={(e) => filterEvents(e.target.value)}
                            />
                          </div>

                          {/* EVENT CATEGORY */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="category">Category</label>
                            <SearchableDropdown
                              value={event_category}
                              options={[
                                { value: "all", label: "All" }, // Add the "All" option
                                ...eventCategoriesOptions, // Add the existing category options
                              ]}
                              isMulti={false}
                              onChange={(selectedOption) =>
                                filterCategory(selectedOption.value)
                              }
                            />
                          </div>

                          {/* EVENT VENUE */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="venue">Venue</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="slug"
                              name="slug"
                              data-minimum-input-length={1}
                              onChange={(e) => filterVenue(e.target.value)}
                            />
                          </div>

                          {/* FILTERING */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="elapsed-filter-all"
                                type="radio"
                                name="all"
                                defaultValue="all"
                                className="custom-control-input"
                                checked={publishedFilter === "all"}
                                onChange={() => setPublishedFilter("all")}
                              />
                              <label
                                htmlFor="elapsed-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>

                            {/* filter by upcoming */}

                            <div className="custom-control custom-checkbox">
                              <input
                                id="upcoming-filter-yes"
                                type="radio"
                                name="upcoming"
                                value="upcoming"
                                className="custom-control-input"
                                checked={publishedFilter === "upcoming"}
                                onChange={() => setPublishedFilter("upcoming")}
                              />
                              <label
                                htmlFor="upcoming-filter-yes"
                                className="custom-control-label"
                              >
                                Upcoming only
                              </label>
                            </div>

                            {/* filter by past  */}

                            <div className="custom-control custom-checkbox">
                              <input
                                id="upcoming-filter-no"
                                type="radio"
                                name="past"
                                value="past"
                                className="custom-control-input"
                                checked={publishedFilter === "past"}
                                onChange={() => setPublishedFilter("past")}
                              />
                              <label
                                htmlFor="upcoming-filter-no"
                                className="custom-control-label"
                              >
                                Past only
                              </label>
                            </div>
                          </div>

                          {/* filter by all , draft only, published */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-all"
                                type="radio"
                                name="all-published"
                                value="all"
                                className="custom-control-input"
                                checked={publishedFilter === "all"}
                                onChange={() => setPublishedFilter("all")}
                              />
                              <label
                                htmlFor="published-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <input
                                id="published-filter-yes"
                                type="radio"
                                name="published"
                                value="published"
                                className="custom-control-input"
                                checked={publishedFilter === "published"}
                                onChange={() => setPublishedFilter("published")}
                              />
                              <label
                                htmlFor="published-filter-yes"
                                className="custom-control-label"
                              >
                                Published only
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <input
                                id="draft-filter-yes"
                                type="radio"
                                name="draft"
                                value="draft"
                                className="custom-control-input"
                                checked={publishedFilter === "draft"}
                                onChange={() => setPublishedFilter("draft")}
                              />
                              <label
                                htmlFor="draft-filter-yes"
                                className="custom-control-label"
                              >
                                Draft only
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-hover table-vcenter">
                          <thead>
                            <tr>
                              <th style={{ width: "35%" }}>Event</th>
                              <th>Organizer</th>
                              <th>Sales</th>
                              <th>Status</th>
                              <th>Attendance</th>
                              <th className="text-center">
                                <i className="fas fa-cog" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {current_events?.map((event, index) => (
                              <tr key={event?.id}>
                                {/* DISPLAYING EVENT IMAGE */}
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {event?.main_event_image && (
                                      <Link
                                        className="fancybox"
                                        to=""
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Enlarge"
                                      >
                                        <img
                                          src={event.main_event_image}
                                          alt=""
                                          className="img-thumbnail img-50-50"
                                        />
                                      </Link>
                                    )}

                                    {/* DISPLAYING EVENT NAME */}
                                    {event?.id && event?.name && (
                                      <EventNamePopover
                                        event_id={event.id}
                                        event_name={event.name}
                                      />
                                    )}
                                  </div>
                                </td>

                                {/* DISPLAYING EVENT ORGANIZER */}
                                <td>
                                  <VenueOrganizer
                                    organizer_ID={event.organizer_id}
                                  />
                                </td>

                                {/* TICKET SALES */}
                                <td>
                                  {event?.id && (
                                    <TicketsNumber event_id={event.id} />
                                  )}
                                </td>

                                {/* STATUS */}
                                <td>
                                  <Status event={event} />
                                </td>

                                {/* ATTENDANCE*/}
                                <td className="text-center">
                                  {event?.id && (
                                    <CircleProgressBar event_id={event.id} />
                                  )}
                                </td>

                                {/* ACTIONS */}
                                <td className="text-center">
                                  {event?.id &&
                                    event?.name &&
                                    event?.created_at && (
                                      <PopingOutEvents
                                        event_id={event.id}
                                        setIsLoading={setIsLoading}
                                        event_name={event.name}
                                        registration_date={formatDate(
                                          convertTimestampToDateTime(
                                            event.created_at
                                          )
                                        )}
                                      />
                                    )}
                                </td>
                              </tr>
                            ))}

                            {/* END OF EVENTS */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* PAGINATION */}
                  {sortedEvents?.length > 20 && (
                    <CustomPagination
                      setCurrentPage={setCurrentPage}
                      totalPages={Math.ceil(
                        sortedEvents?.length / eventsPerPage
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ADDING THE FOOTER */}
        <Footer />
      </div>
    );
  }
}
