import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import {
  convertTimestampToDateTime,
  formatDate,
  formatNumberWithCommas,
} from "../../services/helpers";
import { userStore } from "../../store/user_store";
import { ordersStore } from "../../store/orders_store";
import Sidenavbar from "../organizer_dashboard/components/sidenavbar";
import { isMobile } from "react-device-detect";
import { useEvents } from "./../../store/event_store";
import { useSettings } from "../../store/setting_store";
import { auth } from "../../Firebase";

export default function Attendees() {
  const { event_id } = useParams();

  const isAdmin = userStore((state) => state.isAdmin);
  const isOrganizer = userStore((state) => state.isOrganizer);

  const getOrganizerDetail = ordersStore((state) => state.getOrganizerDetail);
  const getEventAttendees = ordersStore((state) => state.getEventAttendees);
  const getEvent = useEvents((state) => state.getEvent);
  const getEventOrderTickets = ordersStore(
    (state) => state.getEventOrderTickets
  );
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [event, setEvent] = useState({});
  const [allTickets, setAllTickets] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // GETTING ALL TICKETS
      const allTickets = await getEventOrderTickets(event_id);
      setAllTickets(allTickets);

      // GETTING EVENT DETAILS
      var eventGot = await getEvent(event_id);
      setEvent(eventGot);
      console.log("THE GOT EVENT: ", eventGot);

      const ticketsGot = await getEventAttendees(event_id);

      setTickets(ticketsGot);

      // Getting organizer details
      const organizerDetails = [];

      for (let i = 0; i < tickets?.length; i++) {
        const event_id = tickets[i].event_id;
        const organizerData = await getOrganizerDetail(event_id);
        organizerDetails.push(organizerData);
      }
      setOrganizers(organizerDetails);
    }

    fetchData();
  }, []);

  //displaying the loader

  if (!isOrganizer || !isAdmin || auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <section class="section-pagetop bg-gray">
          <div class="container clearfix">
            <h4 class="title-page dark b float-xl-left mb-0">Attendees</h4>
            <nav class="float-xl-right mt-2 mt-xl-0">
              <ol class="breadcrumb text-white">
                <li class="breadcrumb-item">
                  <a href="/en" class="dark">
                    <i class="fas fa-home"></i>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="/" class="dark">
                    Dashboard
                  </a>
                </li>
                <li class="breadcrumb-item text-dark">Attendees</li>
              </ol>
            </nav>
          </div>
        </section>

        {/* TICKETS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/*  SIDE NAV */}
              {
                <aside className="col-lg-3 pt-3 pt-lg-0">
                  {!isMobile && <Sidenavbar currentPage={"my_reports"} />}
                </aside>
              }

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {tickets?.length === 0 && (
                        <div className="alert alert-info alert-icon">
                          <i className="fas fa-exclamation-circle" /> No Orders
                          found yet
                        </div>
                      )}

                      {tickets?.length > 0 && (
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12"></div>
                              <div className="table-responsive">
                                <table className="table table-hover shopping-cart-wrap">
                                  <thead className="text-muted">
                                    <h4>
                                      {/* EVENT NAME */}
                                      {event?.name}
                                    </h4>
                                    <h6>
                                      <span className="b">
                                        TOTAL TICKETS GENERATED:
                                      </span>
                                      <span> {allTickets?.length} </span>
                                    </h6>
                                    <h6>
                                      <span
                                        className="b"
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                      >
                                        TOTAL SCANNED TICKETS:
                                      </span>
                                      <span
                                        style={{
                                          color: layout_page?.primary_color,
                                        }}
                                      >
                                        {" "}
                                        {tickets?.length}{" "}
                                      </span>
                                    </h6>
                                    <tr>
                                      <th scope="col">Event Tickets</th>

                                      {/* TICKET PRICE */}
                                      <th scope="col" width={100}>
                                        Price
                                      </th>

                                      {/* STATUS */}
                                      <th
                                        scope="col"
                                        className="text-right"
                                        width={100}
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* LISTING THE SCANNED TICKETS*/}
                                    {tickets?.map((orderTicket, index) => (
                                      <tr key={index}>
                                        <td>
                                          <figure className="media h-100">
                                            <div className="img-wrap my-auto">
                                              <img
                                                src={event?.main_event_image}
                                                className="img-thumbnail img-sm img-lazy-load b-loaded"
                                                alt=""
                                              />
                                            </div>
                                            <figcaption className="media-body my-auto ">
                                              <Link
                                                to={
                                                  "/event_details/" +
                                                  orderTicket?.event_id
                                                }
                                              >
                                                <h6 className="title mb-2">
                                                  {orderTicket?.ticket_name}
                                                </h6>
                                              </Link>

                                              <dl className="dlist-inline small">
                                                <dt className=" mr-1">
                                                  Event Date: {""}
                                                </dt>
                                                <dd>
                                                  {formatDate(
                                                    convertTimestampToDateTime(
                                                      orderTicket?.event_start_date
                                                    )
                                                  )}
                                                </dd>
                                              </dl>
                                              <dl className="dlist-inline small">
                                                <dt className=" mr-1">
                                                  Where:
                                                </dt>
                                                <dd> {orderTicket?.venue} </dd>
                                              </dl>
                                            </figcaption>
                                          </figure>
                                        </td>

                                        {/* PRICE */}
                                        <td>
                                          <div className="price-wrap">
                                            UGX{" "}
                                            {formatNumberWithCommas(
                                              orderTicket?.ticket_price ?? 0
                                            )}{" "}
                                          </div>
                                        </td>

                                        {/* STATUS */}
                                        <td className="text-right">
                                          <div className="price-wrap">
                                            <var className="price">
                                              {/* BADGE */}
                                              <span
                                                className={
                                                  "rounded" +
                                                    orderTicket?.status ===
                                                  "valid"
                                                    ? "badge badge-success"
                                                    : orderTicket?.status ===
                                                      "invalid"
                                                    ? "badge badge-danger"
                                                    : "badge badge-warning"
                                                }
                                              >
                                                {orderTicket?.status === "valid"
                                                  ? "Not Scanned"
                                                  : "Scanned"}
                                              </span>
                                            </var>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                    {/* end */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
