import { useEffect, useState } from 'react';

import { userStore } from '../store/user_store';
import usePaymentMethodsStore from '../store/payment_store';

export default function useFetchUserPaymentMethods() {
  const [isLoading, setIsLoading] = useState(false);

  const user = userStore((state) => state.user);

  const fetchUserPaymentMethods = usePaymentMethodsStore(
    (state) => state.fetchUserPaymentMethods
  );
  const userPaymentMethods = usePaymentMethodsStore(
    (state) => state.userPaymentMethods
  );

  useEffect(() => {
    if (!userPaymentMethods?.user_id) {
      setIsLoading(true);
      fetchUserPaymentMethods(user?.user_id).then(() => {
        setIsLoading(false);
      });
    }
  }, [userPaymentMethods?.user_id]);

  return { isLoading };
}
