import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useBlogs } from "../../store/blog_store";
import Header from "../../components/header/header";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import Footer from "../../components/footer/footer";
import SideNav from "../../components/side_nav/side_nav";

export default function OrganizerArticles() {
  const { id } = useParams();
  const getSpecificArticle = useBlogs((state) => state.getSpecificArticle);
  const [article, setArticle] = useState({});
  const updateArticle = useBlogs((state) => state.updateArticle);

  useEffect(() => {
    const fetchPage = async () => {
      const getArticle = await getSpecificArticle(id);

      const viewsToAdd = parseInt(getArticle.views) || 0;
      updateArticle(id, {
        views: viewsToAdd + 1,
      });
      setArticle(getArticle);
    };
    fetchPage();
  }, [article?.length]);

  const parseHTML = (htmlString) => ({ __html: htmlString });
  return (
    <div>
      {/* ADDING THE HEADER */}
      <Header currentPage={"Helper center"} />

      {/* BREADCRUMP */}
      <BreadCrump title={"Organizer"} />

      {/* MAIN BODY */}
      <section className="section-content bg-white padding-y">
        <div className=" container">
          <div className="row">
            <SideNav />
            <div className="col-lg-9 order-1 order-lg-2">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card box">
                    <div className="card-body">
                      <div>
                        <p className="h1">{article?.title}</p>
                      </div>
                      <div
                        dangerouslySetInnerHTML={parseHTML(article.content)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ADDING THE FOOTER */}
      <Footer />
    </div>
  );
}
