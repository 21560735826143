import React from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../../../store/setting_store";
import { formatNumberWithCommas } from "../../../services/helpers";

const AttendeeSummary = ({ organizerorders, totalTickets }) => {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div>
      <header className="section-heading mb-3 mt-5">
        <h4 className="title-section float-left">
          <i className="fas fa-cart-arrow-down" /> Attendees summary
        </h4>
        <Link
          style={{ color: layout_page?.primary_color }}
          to="/my_reports"
          className="float-right"
        >
          <i className="fas fa-users" /> Manage attendees
        </Link>
        <div className="clearfix" />
      </header>
      <div className="row mb-3 pl-2 pr-2 d-flex">
        <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
          <div
            className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: layout_page?.primary_color }}
          >
            <i className="fas fa-cart-arrow-down" />
            <h4>{formatNumberWithCommas(organizerorders)}</h4>
            <h5 className="mb-0">Orders placed</h5>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
          <div
            className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: layout_page?.primary_color }}
          >
            <i className="fas fa-cart-arrow-down" />
            <h4>{formatNumberWithCommas(organizerorders)}</h4>
            <h5 className="mb-0">Paid orders</h5>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
          <div
            className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: layout_page?.primary_color }}
          >
            <i class="fas fa-money-bill"></i>
            <h4>{formatNumberWithCommas(0)}</h4>
            <h5 className="mb-0">Awaiting payment</h5>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 p-1 d-flex">
          <div
            className=" text-white rounded p-4 text-center text-white position-relative counter-box w-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: layout_page?.primary_color }}
          >
            <i className="fas fa-ticket-alt" />
            <h4>{formatNumberWithCommas(totalTickets)}</h4>
            <h5 className="mb-0">Tickets sold</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeSummary;
