import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ordersStore } from "../../../store/orders_store";
import { useSettings } from "../../../store/setting_store";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EventStatisticsGraph = ({ event_id }) => {
  const getTotalOrderTicketsPerDayForEvent = ordersStore(
    (state) => state.getTotalOrderTicketsPerDayForEvent
  );
  const layout_page = useSettings((state) => state.layout_page);

  const eventTicketQuantities = ordersStore(
    (state) => state.eventTicketQuantities
  );
  const event_dates = ordersStore((state) => state.event_dates);

  //sort the dates in the ascending order
  const sortedDates = Array.isArray(event_dates)
    ? event_dates.sort((a, b) => new Date(a) - new Date(b))
    : [];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Ticket sales by date",
      },
    },
  };

  const data = {
    labels: sortedDates,
    datasets: [
      {
        label: "Ticket sales",
        data: eventTicketQuantities,
        borderColor: layout_page?.primary_color,
        backgroundColor: layout_page?.primary_color,
        tension: 0.4, // Set tension to create a spline curve
      },
    ],
  };

  useEffect(() => {
    const fetchdata = async () => {
      await getTotalOrderTicketsPerDayForEvent(event_id);
    };
    fetchdata();
  }, [event_id]);

  return <Line options={options} data={data} />;
};

export default EventStatisticsGraph;
