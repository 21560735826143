import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../services/helpers";
import { useVenues } from "../../../store/venues_store";
import Loader from "../../../components/loader/loader";
import { useSettings } from "../../../store/setting_store";
import DeleteComponentModal from "../../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutVenue = ({
  venue_id,

  isHidden,
  listedOnDirectory,
}) => {
  const [loading, setLoading] = useState(false);
  const deleteVenue = useVenues((state) => state.deleteVenue);
  const getHandleStatusToggle = useVenues((state) => state.handleStatusToggle);
  const getHandleListedOnDirectoryToggle = useVenues(
    (state) => state.handleListedOnDirectoryToggle
  );
  const layout_page = useSettings((state) => state.layout_page);
  // METHOD TO HANDLE DELETING THE VENUE
  const handleDeleteVenue = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await deleteVenue(venue_id);
      if (response) {
        displaySuccessToast("🦄 The venue has been deleted succeesfully");
      } else {
        displayErrorToast("Something went wrong while deleting the venue");
      }
    } catch (error) {
      console.log("error while deleting the venue:", error);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loader />;
  }

  // a method to hide and display venue
  const handleIsHiddenVenue = async (e) => {
    e.preventDefault();
    setLoading(true);
    await getHandleStatusToggle(venue_id);
    setLoading(false);
  };

  //a method to hide and view venue in public directory
  const handlePublicDirectory = async (e) => {
    e.preventDefault();
    setLoading(true);
    await getHandleListedOnDirectoryToggle(venue_id);
    setLoading(false);
  };

  return (
    <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
      <Dropdown.Toggle
        variant="white"
        size="sm"
        className="hide-dropdown-icon"
        style={{ padding: 0 }}
      >
        <BsThreeDotsVertical
          style={{
            color: layout_page?.primary_color,
            cursor: "pointer",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          {" "}
          {/* EDIT VENUE */}
          <Link to={"/edit_venue/" + venue_id}>
            <div className="d-flex gap-1" style={{ color: "gray" }}>
              <FaEdit />
              <h6>Edit</h6>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* HIDE */}

          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handleIsHiddenVenue(e)}
          >
            {isHidden !== true ? (
              <>
                <i className="dropdown-icon fas fa-eye-slash fa-fw text-muted" />{" "}
                <h6>Hide</h6>
              </>
            ) : (
              <>
                <i className="dropdown-icon fas fa-eye fa-fw text-muted" />{" "}
                <h6>Publish</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* MARK AS FEATURED */}

          <div
            className="d-flex gap-1 cursor-pointer"
            style={{ color: "gray" }}
            onClick={(e) => handlePublicDirectory(e)}
          >
            {listedOnDirectory === true ? (
              <>
                <i className="fas fa-minus-square fa-fw" />{" "}
                <h6>Hide from Public directory</h6>
              </>
            ) : (
              <>
                <i className="fas fa-plus-square fa-fw" />{" "}
                <h6>View in Public directory</h6>
              </>
            )}
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          {/* DELETE VENUE */}
          <DeleteComponentModal
            itemName="Venue"
            handleDelete={handleDeleteVenue}
          />{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default PopingOutVenue;
