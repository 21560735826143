import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useState } from "react";
import { displayErrorToast, displaySuccessToast } from "../../services/helpers";
import Loader from "../../components/loader/loader";
import { useSettings } from "../../store/setting_store";
import { useBlogs } from "../../store/blog_store";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DeleteComponentModal from "../../components/delete_modal/DeleteComponentModal";
import { Dropdown } from "react-bootstrap";

const PopingOutPages = ({ page_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deletePage = useBlogs((state) => state.deletePage);
  const layout_page = useSettings((state) => state.layout_page);

  //METHOD TO HANDLE DELETING THE PAGE
  async function handleDeletePage() {
    setIsLoading(true);
    const response = await deletePage(page_id);
    if (response) {
      displaySuccessToast("🦄 The page has been deleted succeesfully");
    } else {
      displayErrorToast("Something went wrong while deleting the page");
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Dropdown className="hide-dropdown-shadow" style={{ boxShadow: "none" }}>
        <Dropdown.Toggle
          variant="white"
          size="sm"
          className="hide-dropdown-icon"
          style={{ padding: 0 }}
        >
          <BsThreeDotsVertical
            style={{
              color: layout_page?.primary_color,
              cursor: "pointer",
            }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            {/* EDIT PAGE */}
            <Link to={"/edit_page/" + page_id}>
              <div className="d-flex gap-1" style={{ color: "gray" }}>
                <FaEdit />
                <h6>Edit</h6>
              </div>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <DeleteComponentModal
              itemName="Page Permanently"
              handleDelete={handleDeletePage}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
export default PopingOutPages;
