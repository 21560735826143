import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import attendeeSignupImage from "../../assets/img/illustrations/register-attendee.29d4d837.png";
import Loader from "../../components/loader/loader";
import { Timestamp } from "firebase/firestore";
import {
  displayErrorToast,
  displayWarningToast,
  validationSchemaAttendee,
} from "../../services/helpers";
import { userStore } from "../../store/user_store";
import { useSettings } from "../../store/setting_store";
import { useFormik } from "formik";

function Signupatt() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  const register = userStore((state) => state.register);
  const layout_page = useSettings((state) => state.layout_page);

  const signInWithGoogle = userStore((state) => state.signinWithGoogle);
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const isOrganizer = userStore((state) => state.isOrganizer);
  const isAdmin = userStore((state) => state.isAdmin);

  // CREATING THE USER ACCOUNT
  const handleSignup = async () => {
    setIsLoading(true);

    // IF THERE ARE NO VALIDATION ERRORS
    const userData = {
      user_id: null,
      firstname: values.firstName,
      lastname: values.lastName,
      username: values.userName,
      email: values.email,
      phone_number: `256${values.phoneNumber}`,
      address: null,
      bio: null,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      profile_picture: null,
      online_status: "offline",
      social_media_links: null,
      date_of_birth: null,
      fcm_token: null,
      kyc_status: null,
      last_seen_at: null,
      location: null,
      user_type: "attendee",
      point_of_sale_id: null,
      scanner_id: null,
      isorganizeronhomepageslider_id: false,
      source: "WEBSITE",
    };

    const response = await register({
      email: values.email,
      password: values.password,
      data: userData,
    });

    if (response) {
      // navigate("/dashboard", { state: response });
      navigate("/check_email", {
        state: { userData },
      });
    } else {
      displayErrorToast("Something went wrong: " + response);
    }

    setIsLoading(false);
  };

  //validating the form.
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchemaAttendee,
    onSubmit: handleSignup,
  });
  // HANDLE FORM VALIDATOIN

  // CREATE ACCOUNT WITH GOOGLE
  const handleGoogleSignin = async (e) => {
    e.preventDefault();

    const response = await signInWithGoogle();

    if (response != null) {
      if (response.user_type === "attendee") {
        navigate("/dashboard", { state: response });
      } else if (response.user_type === "organizer") {
        navigate("/dashboard_organizer", { state: response });
      } else if (response.user_type === "admin") {
        navigate("/admin_dashboard", { state: response });
      } else {
        console.log("ERROR: " + response);
        displayErrorToast("Couldnt Log You In");
      }
    } else {
      displayErrorToast("Couldnt Log You In");
    }
  };

  // CHECKING IF THE USER IS ALREADY LOGGED IN
  useEffect(() => {
    if (user) {
      if (isAttendee) {
        console.log("GOING TO ATTENDEE");
        navigate("/dashboard", { replace: true });
      } else if (isOrganizer) {
        console.log("GOING TO ORGANIZER");

        navigate("/dashboard_organizer");
      } else if (isAdmin) {
        console.log("GOING TO ADMIN");
        navigate("/admin_dashboard");
      } else {
        displayWarningToast("You are not allowed to any of the dashboard");
      }
    }
  }, []);

  // DISPLAY A LOADER IF LOADING
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="card-title text-center">Sign up</h1>
            </div>

            {/* LEFT IMAGE */}
            <div className="col-xl-5 offset-xl-1 d-none d-xl-block">
              <img
                src={attendeeSignupImage}
                className="img-fluid"
                alt="SIGNUP "
              />
            </div>
            <div className="col-xl-4 col-12">
              <p>
                {/* SIGNUP WITH FACEBOOK */}
                <a
                  href="/connect/facebook"
                  className="btn btn-block btn-facebook"
                >
                  {" "}
                  <i className="fab fa-facebook-f" /> &nbsp; Sign up via
                  Facebook
                </a>

                {/* SIGNUP WITH GOOGLE */}
                <button
                  onClick={handleGoogleSignin}
                  className="btn btn-block btn-google-plus"
                >
                  {" "}
                  <i className="fab fa-google" /> &nbsp; Sign up via Google
                </button>
              </p>
              <p className="divider-text">
                <span>OR</span>
              </p>

              <form
                name="fos_user_registration_form"
                onSubmit={handleSubmit}
                noValidate="novalidate"
              >
                {/* FIRST NAME */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_firstname"
                      name="firstName"
                      required="required"
                      placeholder="First name"
                      className={
                        errors.firstName && touched.firstName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.firstName && touched.firstName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                {/* LAST NAME */}

                <div className="form-group">
                  <div className=" input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_lastname"
                      name="lastName"
                      required="required"
                      placeholder="Last name"
                      className={
                        errors.lastName && touched.lastName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.lastName && touched.lastName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.lastName}
                    </p>
                  )}
                </div>

                {/* USERNAME */}

                <div className="form-group">
                  <div className=" input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user-tag" />{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fos_user_registration_form_username"
                      name="userName"
                      required="required"
                      placeholder="Username"
                      className={
                        errors.userName && touched.userName
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.userName && touched.userName && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.userName}
                    </p>
                  )}
                </div>

                {/* PHONE NUMBER */}

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        <i className="fas fa-phone" />
                      </span>
                    </div>

                    <div className="input-group-prepend">
                      <span
                        // className="input-group-text"
                        className={
                          errors.phoneNumber && touched.phoneNumber
                            ? "input-group-text border border-danger"
                            : "input-group-text"
                        }
                        id="basic-addon1"
                        style={{ borderRadius: "5px" }}
                      >
                        +256
                      </span>
                    </div>
                    <input
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      required="required"
                      placeholder="Phone Number"
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? "form-control border-start-0 border-danger"
                          : "form-control"
                      }
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.phoneNumber && touched.phoneNumber && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>

                {/* EMAIL */}
                <div className="form-group">
                  <div className=" input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-envelope" />{" "}
                      </span>
                    </div>
                    <input
                      type="email"
                      id="fos_user_registration_form_email"
                      name="email"
                      required="required"
                      placeholder="Email"
                      className={
                        errors.email && touched.email
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  {errors.email && touched.email && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.email}
                    </p>
                  )}
                </div>

                {/* PASSWORD */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        {" "}
                        <i className="fas fa-user-lock" />{" "}
                      </span>
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="fos_user_registration_form_plainPassword_first"
                      name="password"
                      required="required"
                      placeholder="Password"
                      className={
                        errors.password && touched.password
                          ? "form-control border-end-0 border-danger"
                          : "form-control"
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div>
                      <span
                        className={
                          errors.password && touched.password
                            ? "form-control border-start-0 border-danger cursor-pointer"
                            : "form-control cursor-pointer"
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {errors.password && touched.password && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.password}
                    </p>
                  )}
                </div>

                {/* CONFIRM PASSWORD */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0">
                        <i className="fas fa-user-lock" />
                      </span>
                    </div>
                    <input
                      type={showPasswordC ? "text" : "password"}
                      id="fos_user_registration_form_plainPassword_second"
                      name="confirmPassword"
                      required="required"
                      placeholder="Repeat password"
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "form-control border-end-0 border-danger"
                          : "form-control"
                      }
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div>
                      <span
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "form-control border-start-0 border-danger cursor-pointer"
                            : "form-control cursor-pointer"
                        }
                        onClick={() => setShowPasswordC(!showPasswordC)}
                      >
                        <i
                          className={
                            showPasswordC ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="text-sm text-danger font-weight-bold">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>

                {/* TERMS OF SERVICE AND PRIVACY POLICY */}
                <div className="mb-3 text-sm text-center">
                  <span className="text-muted">
                    By clicking the Sign Up button, I agree to
                  </span>
                  <a href="/terms_of_service" target={"_blank"}>
                    Terms of service
                  </a>
                  <span className="text-muted">and</span>
                  <a href="/privacy_policy" target={"_blank"}>
                    Privacy policy
                  </a>
                </div>

                {/* CREATE ACCOUNT BUTTON */}
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn text-white btn-block"
                    style={{
                      backgroundColor: layout_page?.primary_color,
                    }}
                  >
                    {isSubmitting ? (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <>Create Account</>
                    )}
                  </button>
                </div>

                {/* SIGN IN BUTTON */}
                <p className="text-center">
                  Already have an account? <a href="/sign_in">Sign in</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Signupatt;
