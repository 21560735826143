import React from 'react';

const RadioComponent = ({ isChecked, option, htmlFor, handleChange }) => {
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      value={option?.value}
      onChange={(e) => handleChange(e, option)}
      checked={isChecked}
    />
    <label className="form-check-label" htmlFor={htmlFor}>
      {option?.label}
    </label>

    <span className="float-right form-check-label">
      <span className="badge badge-light round">0</span>
    </span>
  </div>;
};

export default function RadioGroup({ options, onChange, Radio }) {
  const [_option, setOption] = React.useState();

  const handleChange = (event, option) => {
    setOption(option);
    onChange(option);
  };

  return (
    <>
      {options.map((option) => {
        const htmlFor = 'option-' + option?.value;
        const isChecked = _option?.value === option?.value;

        return Radio ? (
          <Radio
            isChecked={isChecked}
            option={option}
            htmlFor={htmlFor}
            handleChange={handleChange}
          />
        ) : (
          <RadioComponent
            isChecked={isChecked}
            option={option}
            htmlFor={htmlFor}
            handleChange={handleChange}
          />
        );
      })}
    </>
  );
}
