import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  sendEmailVerification,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  signInWithPopup,
  getAdditionalUserInfo,
  reauthenticateWithRedirect,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { UNSAFE_DataRouterStateContext } from "react-router-dom";

import app from "../Firebase";

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// initalizing firebase auth
const auth = getAuth(app);

// HANDLING USER LOGIN
export const login = async (username, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, username, password);
    const userId = user.user.uid;

    const userDoc = doc(db, "users", userId);
    const querySnapshot = await getDoc(userDoc);
    console.log("RESPONSE FROM FIRESTORE: " + querySnapshot.data());

    var userData = querySnapshot.data();
    console.log("THE EMAIL ADDRESS OF THE USER IS: " + userData?.email);

    var isAttendee = false;
    var isOrganizer = false;
    var isAdmin = false;

    if (userData.user_type === "organizer") {
      isOrganizer = true;
    } else if (userData.user_type === "attendee") {
      isAttendee = true;
    } else if (userData.user_type === "admin") {
      isAdmin = true;
    } else {
      console.log("CANT GET THE USER TYPE");
    }

    // CACHING THE USER DATA
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("isAttendee", JSON.stringify(isAttendee));
    localStorage.setItem("isOrganizer", JSON.stringify(isOrganizer));
    localStorage.setItem("isAdmin", JSON.stringify(isAdmin));

    return userData;
  } catch (error) {
    console.log("SOMETHING WENT WRONG LOGGING IN: " + error);
    return null;
  }
};

/////////////////////////////////////////////////////////
//////////////  CREATING AN ACCOUNT /////////////////////
export async function register({
  firstname,
  lastname,
  username,
  email,
  password,
  phone_number,
  userType,
  organizer,
}) {
  try {
    // ADDING THE USER TO FIREBASE AUTH
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // GETTING THE USER ID
    const userId = response.user.uid;

    // CREATING THE USER DATA OBJECT
    const userObject = {
      user_id: userId,
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      phone_number: phone_number,
      address: null,
      bio: null,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
      profile_picture: null,
      online_status: "offline",
      social_media_links: null,
      date_of_birth: null,
      fcm_token: null,
      kyc_status: null,
      last_seen_at: null,
      location: null,
      user_type: userType,
      point_of_sale_id: null,
      scanner_id: null,
      isorganizeronhomepageslider_id: false,
    };

    // ADDING THE USER DATA TO FIRESTORE DATABASE
    const userDoc = doc(db, "users", userId);
    await setDoc(userDoc, userObject);

    // CREATING THE ORGANIZER DATA OBJECT
    const organizerData = {
      id: userId,
      organizer_name: firstname,
      email: email,
      phone_number: phone_number,
      about: null,
      organizer_logo: null,
      cover_photo: null,
      country: null,
      website: null,
      phone: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,
      youtubeVideoUrl: null,
      showVenuesMap: true,
      showFollowers: true,
      showReviews: true,
      created_at: Timestamp.fromDate(new Date()),
      updated_at: Timestamp.fromDate(new Date()),
    };

    // ADDING THE USER DATA TO FIRESTORE DATABASE
    const organizerDoc = doc(db, "organizers", userId);
   await setDoc(organizerDoc, organizerData);

    var isAttendee = false;
    var isOrganizer = false;
    var isAdmin = false;

    if (userType === "organizer") {
      isOrganizer = true;
    } else if (userType === "attendee") {
      isAttendee = true;
    } else if (userType === "admin") {
      isAdmin = true;
    } else {
      console.log("CANT GET THE USER TYPE");
    }

    // CACHING THE USER DATA
    localStorage.setItem("user", JSON.stringify(userObject));
    localStorage.setItem("isAttendee", JSON.stringify(isAttendee));
    localStorage.setItem("isOrganizer", JSON.stringify(isOrganizer));
    localStorage.setItem("isAdmin", JSON.stringify(isAdmin));

    // SENDIGN THE EMAIL VERIFICATION LINK
    sendEmailVerificationLink();
    return "success";
  } catch (error) {
    switch (error.code) {
      case "auth/email-already-in-use":
        return "Email already in use!";
      default:
        return error.message;
    }
  }
}

// LOGGING OUT THE USER
export const logout = async () => {
  try {
    await signOut(auth);
    return true;
  } catch (error) {
    console.log("SOMETHING WENT WRONG LOGGING OUT: " + error);
    return false;
  }
};

const authProvider = new GoogleAuthProvider();

// SIGNING IN WITH GOOGLE
export const googleSignin = async () => {
  try {
    const result = await signInWithPopup(auth, authProvider);
    const isNewUser = getAdditionalUserInfo(result).isNewUser;
    const userId = result.user.uid;
    const userDoc = doc(db, "users", userId);

    // IF THE USER IS LOGGING IN FOR THE FIRST TIME
    if (isNewUser) {
      const userObject = {
        user_id: userId,
        firstname: result.user.displayName,
        lastname: null,
        username: result.user.email,
        email: result.user.email,
        phone_number: result.user.phoneNumber,
        address: null,
        bio: null,
        created_at: Timestamp.fromDate(new Date()),
        updated_at: Timestamp.fromDate(new Date()),
        profile_picture: result.user.photoURL,
        online_status: "offline",
        social_media_links: null,
        date_of_birth: null,
        fcm_token: null,
        kyc_status: null,
        last_seen_at: null,
        location: null,
        user_type: "attendee",
      };
      const response = await setDoc(userDoc, userObject);
      console.log("RESPONSE: " + response);

      localStorage.setItem("isAttendee", JSON.stringify(true));

      // SENDING THE EMAIL VERIFICATION LINK
      sendEmailVerificationLink();
    }

    const querySnapshot = await getDoc(userDoc);

    var userData = querySnapshot.data();

    var isAttendee = false;
    var isOrganizer = false;
    var isAdmin = false;

    if (userData.user_type === "organizer") {
      isOrganizer = true;
    } else if (userData.user_type === "attendee") {
      isAttendee = true;
    } else if (userData.user_type === "admin") {
      isAdmin = true;
    } else {
      console.log("CANT GET THE USER TYPE");
    }

    // CACHING THE USER DATA
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("isAttendee", JSON.stringify(isAttendee));
    localStorage.setItem("isOrganizer", JSON.stringify(isOrganizer));
    localStorage.setItem("isAdmin", JSON.stringify(isAdmin));

    return userData;
  } catch (error) {
    console.log("SOMETHING WENT WRONG" + error);
    return null;
  }
};

// GET CURRENT USER DETAILS
export const getUserDetails = async () => {
  // return null if no user is logged in
  if (auth.currentUser === null) {
    return null;
  }

  const userId = auth.currentUser.uid;

  console.log("CURRENT USER ID: " + userId);

  try {
    // GET USER DETAILS FROM FIREBASE AUTH
    const userDoc = doc(db, "users", userId);
    const querySnapshot = await getDoc(userDoc);

    return querySnapshot.data();
  } catch (error) {
    console.log("SOMETHING WENT WRONG: " + error);
    return null;
  }
};

// GET CURRENT USER DETAILS
export async function getUserByEmail(email) {
  console.log("GETTING DETAILS FOR: " + email);

  try {
    // GET USER DETAILS FROM FIREBASE AUTH
    const userCollection = collection(db, "users");
    const q = query(userCollection, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    return querySnapshot.docs[0].data();
  } catch (error) {
    console.log("SOMETHING WENT WRONG: " + error);
    return null;
  }
}

// SEND USER EMAIL VERIFICATION LINK
export const sendEmailVerificationLink = async () => {
  return await sendEmailVerification(auth.currentUser);
};

// CHANGE USER PASSWORD
export const changeUserPassword = async (newPassword) => {
  reauthenticateWithRedirect();
  return await updatePassword(auth.currentUser, newPassword);
};

// RESET USER PASSWORD
export const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth.currentUser, email);
};

// DELETE USER ACCOUNT
export const deleteUserAccount = async () => {
  return await deleteUser(auth.currentUser);
};

// METHOD TO CHECK IF THE USER EMAIL IS VERIFIED
export const isEmailVerified = async () => {
  try {
    return auth.currentUser.emailVerified;
  } catch (error) {
    return null;
  }
};

// UPDATING THE ORGANIZER PROFILE
export async function updateOrganizerProfile({
  organizer_name,
  about,
  organizer_logo,
  cover_photo,
  country,
  website,
  email,
  phone,
  facebook,
  twitter,
  instagram,
  linkedin,
  youtubeVideoUrl,
  showVenuesMap,
  showFollowers,
  showReviews,
  categories,
  user_id,
}) {
  // CREATING THE ORGANIZER DATA OBJECT
  const organizerData = {
    organizer_name: organizer_name,
    email: email,
    categories: categories,
    about: about,
    organizer_logo: organizer_logo,
    cover_photo: cover_photo,
    country: country,
    website: website,
    phone: phone,
    facebook: facebook,
    twitter: twitter,
    instagram: instagram,
    linkedin: linkedin,
    youtubeVideoUrl: youtubeVideoUrl,
    showVenuesMap: showVenuesMap,
    showFollowers: showFollowers,
    showReviews: showReviews,
    updated_at: Timestamp.fromDate(new Date()),
  };

  // ADDING THE USER DATA TO FIRESTORE DATABASE
  const organizerDoc = doc(db, "organizers", user_id);
  try {
    const organizerResponse = await updateDoc(organizerDoc, organizerData);
    console.log("RESPONSE AFTER UPDATING ORGANIZER DATA: " + organizerResponse);
    return "success";
  } catch (error) {
    console.log("ERROR: " + error);
    return error;
  }
}
