import {
  addDoc,
  addDocl,
  Timestamp,
  collection,
  doc,
  getDocs,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import app from "../Firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { displayErrorToast, displaySuccessToast } from "./helpers.js";
import { toast } from "react-toastify";

// INITILIZE FIRESTORE
const db = getFirestore(app);
const storage = getStorage(app);

////////////////////////////////
// METHOD TO ADD A NEW BLOGPOST/
////////////////////////////////

export async function addBlogPost({
  category,
  name,
  description,
  tags,
  main_image,
  read_time,
}) {
  // CREATING A COLLECTION
  const blogsCollection = collection(db, "blogs");

  console.log("RECEIVED DESCRIPTION: " + description);

  // ADDING THE DATA TO FIRESTORE
  const data = {
    category: category,
    name: name,
    description: description,
    added_at: Timestamp.fromDate(new Date()),
    updated_at: Timestamp.fromDate(new Date()),
    main_image: main_image,
    tags: tags || [],
    read_time: read_time,
    views: "",
    status: "visible",
  };

  const responseFromFirestore = await addDoc(blogsCollection, data);
  const blog_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const blogDoc = doc(db, "blogs", blog_id);

  // UPDATING THE BLOG ID
  const response = await updateDoc(blogDoc, { id: blog_id });

  console.log("RESPONSE AFTER ADDING DOCUMENT: " + response);
}

//////////////////////////////////////////
// METHOD TO ADD A NEW BLOGPOST CATEGORY//
//////////////////////////////////////////
export async function addBlogPostCategory({ category }) {
  // CREATING A COLLECTION
  const blogPostCategoriesCollection = collection(db, "blogpost_categories");

  // ADDING THE DATA TO FIRESTORE
  const data = {
    id: "",
    category: category,
    added_at: Timestamp.fromDate(new Date()),
    status: "visible",
  };

  const responseFromFirestore = await addDoc(
    blogPostCategoriesCollection,
    data
  );
  const blogpost_category_id = responseFromFirestore.id;

  // CREATING A DOCUMENT
  const blogpostDoc = doc(db, "blogpost_categories", blogpost_category_id);

  // UPDATING THE BLOG ID
  const response = await updateDoc(blogpostDoc, { id: blogpost_category_id });

  console.log("RESPONSE AFTER ADDING THE BLOGPOST: " + response);
}

/////////////////////////////////////////////////////////////
// /////////METHOD TO GET BLOGPOST CATEGORIES/////////////////
/////////////////////////////////////
export async function getBlogpostCategories() {
  const categories = [];
  const categoriesDoc = collection(db, "blogpost_categories");
  const querySnapshot = await getDocs(categoriesDoc);
  querySnapshot.forEach((doc) => {
    categories.push({ id: doc.id, ...doc.data() });
  });
  return categories;
}

/////////////////////////////////////////////////////////////
// /////////METHOD TO GET A SPECIFIC BLOGPOST CATEGORY /////////////////
/////////////////////////////////////
export async function getSpecificBlogCategory(blog_category) {
  console.log("CATEGORY ID:" + blog_category);
  const querySnapshot = await getDoc(
    doc(db, "blogpost_categories", blog_category)
  );

  const blogCategory = querySnapshot.data();

  return blogCategory;
}

//delete blog category

//////////////////////////////////////////////////////
/////// METHOD TO GET ALL BLOG POSTS//////////////////
export async function getBlogs() {
  const blogsposts = [];
  const userDoc = collection(db, "blogs");
  const querySnapshot = await getDocs(userDoc);
  querySnapshot.forEach((doc) => {
    blogsposts.push({ id: doc.id, ...doc.data() });
  });
  return blogsposts;
}

////////////////////////////////////////
// METHOD TO UPLOAD BLOG POST IMAGE
////////////////////////////////////////
export const uploadBlogImage = async (imageFile) => {
  if (!imageFile) {
    return;
  }

  const storage = getStorage(app);
  const storageRef = ref(storage, "blogposts/images/" + imageFile.name);

  try {
    await uploadBytes(storageRef, imageFile);

    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image", error);
  }
};

//handle delete blog image Url
export const deleteBlogMainImage = async (imageUrl) => {
  const imageRef = ref(storage, imageUrl);
  try {
    await deleteObject(imageRef);
    console.log("blog Image deleted");
  } catch (error) {
    console.log("Error deleting the image", error);
    throw error;
  }
};

///////////////////////////////////////////////////////////////
////FUNCTION TO CALCULATE THE TIME SINCE THE BLOG WAS ADDED////
///////////////////////////////////////////////////////////////
export function getTimeSinceAdded(timestamp) {
  const now = new Date();
  const addedDate = new Date(timestamp);
  const diffInMs = now.getTime() - addedDate.getTime();
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
  const diffInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths === 1 ? "" : "s"} ago`;
  } else {
    return `${diffInYears} year${diffInYears === 1 ? "" : "s"} ago`;
  }
}

///////////////////////////////////////////////////////////////
////                GETTING BLOGS BY ID                 ///////
///////////////////////////////////////////////////////////////
export async function getBlogById(blogID) {
  console.log("THE BLOG ID IS ::", blogID);
  const docRef = doc(db, "blogs", blogID);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return { id: docSnap.id, ...data };
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }
}

// getting a specifice blog post
export const getSpecificBlog = async (blog_post_id) => {
  const blogDoc = doc(db, "blogs", blog_post_id);
  const querySnapshot = await getDoc(blogDoc);
  const blog = querySnapshot.data();
  console.log("blog:", blog);
  return blog;
};

//delete Blog Post
export const deleteBlogPost = async (blog_post_id) => {
  console.log("Going to delete blog with id:", blog_post_id);
  try {
    // Get blog details
    const blogDetails = await getSpecificBlog(blog_post_id);
    console.log("Specific blog retrieved:", blogDetails);

    // Delete main image from storage
    try {
      const storageRef = ref(storage, blogDetails?.main_image);
      await deleteObject(storageRef);
      console.log("Main event image deleted");
    } catch (error) {
      console.error("Error deleting blog image:", error);
      displayErrorToast("Blog image not deleted: " + error);
    }

    // Delete the blog document
    try {
      const docRef = doc(db, "blogs", blog_post_id);
      await deleteDoc(docRef);
      console.log("Blog deleted successfully");
    } catch (error) {
      console.error("Error deleting blog document:", error);
      displayErrorToast("Error while deleting blog");
    }
    return true;
  } catch (error) {
    console.error("Something went wrong when deleting the blog:", error);
    return false;
  }
};

//delete blog post category
export const deleteBlogPostCategory = async (blog_category_id) => {
  try {
    const categoryRef = doc(db, "blogpost_categories", blog_category_id);
    await deleteDoc(categoryRef);
    console.log("Blog category deleted");
    return true;
  } catch (error) {
    console.log("Error deleting a blog category:", error);
    return false;
  }
};

//a function to update blog category
export const updateBlogCategory = async (blog_category_id, data) => {
  try {
    const categoryRef = doc(db, "blogpost_categories", blog_category_id);
    await updateDoc(categoryRef, data);
    console.log("Blog category updated");
    return true;
  } catch (error) {
    console.log("Error updating a blog category:", error);
    return false;
  }
};

//getting visible blog bposts categories
export const getVisibleBlogCategories = async () => {
  const blogCategories = await getBlogpostCategories();
  const visibleBlogCategories = blogCategories.filter(
    (category) => category.status === "visible"
  );
  return visibleBlogCategories;
};
//getting visible bposts
export const getVisibleBlogs = async () => {
  const blogs = await getBlogs();
  const visibleBlogs = blogs.filter((blog) => blog.status === "visible");
  return visibleBlogs;
};

//getting featured blog categories
export const getFeaturedBlogCategories = async () => {
  const blogCategories = await getBlogpostCategories();
  const featuredBlogCategories = blogCategories.filter(
    (category) => category.featured === true
  );
  return featuredBlogCategories;
};

//updating bpost
export const updateBlogPost = async (blog_post_id, data) => {
  try {
    const blogDoc = doc(db, "blogs", blog_post_id);
    await updateDoc(blogDoc, data);
    console.log("Blog post updated");
    return true;
  } catch (error) {
    console.log("Error updating blog post:", error);
    return false;
  }
};

//toggle the status of the blog post from visible to invisible and from invisible to visible
export const toggleBlogPostStatus = async (blog_post_id) => {
  try {
    const blogDoc = doc(db, "blogs", blog_post_id);
    const blog = await getDoc(blogDoc);
    const blogData = blog.data();
    if (blogData.status === "visible") {
      blogData.status = "invisible";
    } else {
      blogData.status = "visible";
    }
    await updateDoc(blogDoc, blogData);
    console.log("Blog post status updated");
    displaySuccessToast("Blog post status updated");
    return true;
  } catch (error) {
    console.log("Error updating blog post status:", error);
    return false;
  }
};

//toggle the status of blog post categories from visible to invisible and from invisible to visible
export const toggleBlogCategoryStatus = async (blog_category_id) => {
  try {
    const categoryRef = doc(db, "blogpost_categories", blog_category_id);
    const category = await getDoc(categoryRef);
    const categoryData = category.data();
    if (categoryData.status === "visible") {
      categoryData.status = "invisible";
    } else {
      categoryData.status = "visible";
    }
    await updateDoc(categoryRef, categoryData);
    console.log("Blog category status updated");
    displaySuccessToast("Blog category status updated");
    return true;
  } catch (error) {
    console.log("Error updating blog category status:", error);
    return false;
  }
};

/////////////////////////////////////////////////////
/////////METHOD TO GET ALL BLOG TAGS/////////////////
export async function getAllTags() {
  //Calling the get blogs method
  const blogs = await getBlogs();
  const tags = blogs.flatMap((blog) => blog.tags);
  return [...new Set(tags)];
}

//geting tags of visible blogs
export async function getVisibleTags() {
  //Calling the get blogs method
  const blogs = await getVisibleBlogs();
  const tags = blogs.flatMap((blog) => blog.tags);
  return [...new Set(tags)];
}
//or
// export async function getVisibleTags() {
//   //Calling the get blogs method
//   const blogs = await getBlogs();
//   const tags = blogs.filter((blog) => blog.status === "visible");
//   return [...new Set(tags.flatMap((blog) => blog.tags))];
// }

///HELPER CENTER SECTION
// adding an article to database
export const addArticle = async (data) => {
  try {
    const articleCollection = collection(db, "articles");
    const responseFromFirestore = await addDoc(articleCollection, data);
    const article_id = responseFromFirestore.id;
    //creaitng a document
    const articleDoc = doc(db, "articles", article_id);
    //updating the document
    await updateDoc(articleDoc, { id: article_id });
    return true;
  } catch (error) {
    console.log("Error adding article:", error);
    return false;
  }
};

//getting articles from database
export async function getArticles() {
  try {
    const querySnapshot = await getDocs(collection(db, "articles"));
    const articles = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return articles;
  } catch (error) {
    console.log("Error getting articles:", error);
    return null;
  }
}

//delete article
export const deleteArticle = async (article_id) => {
  try {
    const docRef = doc(db, "articles", article_id);
    await deleteDoc(docRef);
    console.log("Article deleted successfully");
    return true;
  } catch (error) {
    console.error("Error deleting article:", error);
    return false;
  }
};

//toggle the status of the article from visible to invisible and from invisible to visible
export const toggleArticleStatus = async (article_id) => {
  try {
    const docRef = doc(db, "articles", article_id);
    const article = await getDoc(docRef);
    const articleData = article.data();
    if (articleData.status === "visible") {
      articleData.status = "invisible";
    } else {
      articleData.status = "visible";
    }
    await updateDoc(docRef, articleData);
    console.log("Article status updated");
    displaySuccessToast("Article status updated");
    return true;
  } catch (error) {
    console.log("Error updating article status:", error);
    return false;
  }
};

//toggle the featured of the article from true to false and from false to true
export const toggleFeaturedStatus = async (article_id) => {
  try {
    const docRef = doc(db, "articles", article_id);
    const article = await getDoc(docRef);
    const articleData = article.data();
    if (articleData.featured === true) {
      articleData.featured = false;
    } else {
      articleData.featured = true;
    }
    await updateDoc(docRef, articleData);
    console.log("Article featured status updated");
    displaySuccessToast("Article featured status updated");
    return true;
  } catch (error) {
    console.log("Error updating article featured status:", error);
    return false;
  }
};

//updating article
export const updateArticle = async (article_id, data) => {
  try {
    const docRef = doc(db, "articles", article_id);
    await updateDoc(docRef, data);
    console.log("Article updated successfully");
    return true;
  } catch (error) {
    console.log("Error updating article:", error);
    return false;
  }
};

//adding user category to database
export const addUserCategory = async (data) => {
  try {
    const categoryCollection = collection(db, "user_categories");
    const responseFromFirestore = await addDoc(categoryCollection, data);
    const category_id = responseFromFirestore.id;
    //creaitng a document
    const categoryDoc = doc(db, "user_categories", category_id);
    //updating the document
    await updateDoc(categoryDoc, { id: category_id });
    return true;
  } catch (error) {
    console.log("Error adding user category:", error);
    return false;
  }
};

//getting user categories from the database
export async function getUserCategories() {
  try {
    const querySnapshot = await getDocs(collection(db, "user_categories"));
    const categories = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return categories;
  } catch (error) {
    console.log("Error getting user categories:", error);
    return null;
  }
}

//delete user category
export const deleteUserCategory = async (user_category_id) => {
  try {
    const categoryRef = doc(db, "user_categories", user_category_id);
    await deleteDoc(categoryRef);
    console.log("User category deleted");
    return true;
  } catch (error) {
    console.log("Error deleting a user category:", error);
    return false;
  }
};

//updating user category
export const updateUserCategory = async (user_category_id, data) => {
  try {
    const categoryRef = doc(db, "user_categories", user_category_id);
    await updateDoc(categoryRef, data);
    console.log("User category updated");
    return true;
  } catch (error) {
    console.log("Error updating a user category:", error);
    return false;
  }
};
//toggle the status of the user category from visible to invisible and from invisible to visible
export const toggleUserCategoryStatus = async (user_category_id) => {
  try {
    const categoryRef = doc(db, "user_categories", user_category_id);
    const category = await getDoc(categoryRef);
    const categoryData = category.data();
    if (categoryData.status === "visible") {
      categoryData.status = "invisible";
    } else {
      categoryData.status = "visible";
    }
    await updateDoc(categoryRef, categoryData);
    console.log("User category status updated");
    displaySuccessToast("User category status updated");
    return true;
  } catch (error) {
    console.log("Error updating user category status:", error);
    return false;
  }
};

//PAGES SECTION

//adding page to database
export const addPage = async (data) => {
  try {
    const pageCollection = collection(db, "pages");
    const responseFromFirestore = await addDoc(pageCollection, data);
    const page_id = responseFromFirestore.id;
    //creaitng a document
    const pageDoc = doc(db, "pages", page_id);
    //updating the document
    await updateDoc(pageDoc, { id: page_id });
    return true;
  } catch (error) {
    console.log("Error adding page:", error);
    return false;
  }
};

// a function to get pages from database
export async function getPages() {
  try {
    const querySnapshot = await getDocs(collection(db, "pages"));
    const pages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return pages;
  } catch (error) {
    console.log("Error getting pages:", error);
    return null;
  }
}

//delete page
export const deletePage = async (page_id) => {
  try {
    const docRef = doc(db, "pages", page_id);
    await deleteDoc(docRef);
    console.log("Page deleted");
    return true;
  } catch (error) {
    console.log("Error deleting a page:", error);
    return false;
  }
};

//updating page
export const updatePage = async (page_id, data) => {
  try {
    const docRef = doc(db, "pages", page_id);
    await updateDoc(docRef, data);
    console.log("Page updated");
    return true;
  } catch (error) {
    console.log("Error updating a page:", error);
    return false;
  }
};

//get a page
export const getPage = async (page_id) => {
  try {
    const docRef = doc(db, "pages", page_id);
    const page = await getDoc(docRef);
    const pageData = page.data();
    return pageData;
  } catch (error) {
    console.log("Error getting a page:", error);
    return null;
  }
};
