import React, { useContext, useState, useEffect } from "react";
import Header from "./../../../../components/header/header";
import Footer from "./../../../../components/footer/footer";
import Sidenavbar from "../../components/sidenavbar";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumpOrg from "../../components/breadcrumporganizer";
import { myContext } from "../../../../user_context";
import { isMobile } from "react-device-detect";
import { useSettings } from "../../../../store/setting_store";

export default function Myvenues() {
  const { isOrganizer, user } = useContext(myContext);
  const layout_page = useSettings((state) => state.layout_page);
  const navigate = useNavigate();

  if (!isOrganizer) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />
        <BreadCrumpOrg title={"Scanner Settings"} />

        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"scanner_settings"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div class="shadow p-3 mb-5 bg-body rounded">
                  <div className=" mt-4 ml-4">
                    <p className="mb-2 text-black fw-semibold">
                      <i
                        className="fas fa-info-circle mr-1"
                        style={{
                          color: layout_page?.primary_color,
                        }}></i>
                      {""}
                      Show event date stats on the scanner app{" "}
                      <span className=" text-danger">*</span>
                    </p>
                    <h6 className="text-sm my-3 text-black-50">
                      The event date stats (sales and attendance) will be
                      visible on the scanner app
                    </h6>
                    <div className=" d-flex gap-3 mb-5 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          No
                        </label>
                      </div>
                    </div>
                    <div className="fw-bold">
                      <h6>
                        <i
                          className="fas fa-info-circle  mr-1"
                          style={{
                            color: layout_page?.primary_color,
                          }}></i>
                        {""}
                        Allow tap to check in on the scanner app {""}
                        <span className=" text-danger">*</span>
                      </h6>
                    </div>
                    <div>
                      <h6 className=" fw-bold text-sm text-black-50 my-3">
                        Besides the qr code scanning feature, the scanner
                        account will be able to check in the attendees using a
                        list and a button
                      </h6>
                    </div>
                    <div className=" d-flex gap-3 ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefaults"
                          id="flexRadioDefault3"
                          checked
                        />
                        <label class="form-check-label" for="flexRadioDefault3">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefaults"
                          id="flexRadioDefault4"
                        />
                        <label class="form-check-label" for="flexRadioDefault4">
                          No
                        </label>
                      </div>
                    </div>
                    <button
                      style={{ border: "none" }}
                      className="my-5 py-2 px-4 bg-danger text-white rounded-pill">
                      <h5 className="">Save</h5>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
