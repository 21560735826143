import { Modal, Button } from "react-bootstrap";
import { convertTimestampToDateTime } from "../../services/helpers";
import { useSettings } from "../../store/setting_store";

const AddToCalendarModal = ({
  eventDetails,
  eventDates,
  eventId,
  show,
  onHide,
}) => {
  const startDate = convertTimestampToDateTime(eventDates[0].start_date)
    .toISOString()
    .replace(/[-:.]/g, "");
  const endDate = convertTimestampToDateTime(eventDates[0].end_date)
    .toISOString()
    .replace(/[-:.]/g, "");

  const layout_page = useSettings((state) => state.layout_page);

  return (
    <Modal id="add-to-calendar-modal" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fas fa-calendar-plus" /> Add to calendar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="add-to-calendar">
        <div className="add-to-calendar" id={54766}>
          <label
            htmlFor="checkbox-for-54766"
            className="add-to-calendar-checkbox">
            + Add to my Calendar
          </label>
          <input
            name="add-to-calendar-checkbox"
            className="add-to-calendar-checkbox"
            id="checkbox-for-54766"
            type="checkbox"
          />

          {/* GOOGLE CALENDAR */}
          <a
            className="icon-google"
            target="_blank"
            href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
              eventDetails.name
            )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(
              eventDetails.description
            )}&location=${encodeURIComponent(
              eventDates[0]?.venue
            )}&sprop=&sprop=name:`}
            rel="noreferrer">
            Google Calendar
          </a>

          {/* YAHOO CALENDAR */}
          <a
            className="icon-yahoo"
            target="_blank"
            href={`http://calendar.yahoo.com/?v=60&view=d&type=20&title=${eventDetails.name}&st=${startDate}&dur=0000&desc=${eventDetails.description}&in_loc=${eventDates[0]?.venue}`}
            rel="noreferrer">
            Yahoo! Calendar
          </a>

          {/* ICAL CALENDAR */}
          <a
            className="icon-ical"
            target="_blank"
            href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://${
              window.location.hostname + "/event_details/" + eventId
            }%0ASUMMARY:${eventDetails.name}%0ADESCRIPTION:${
              eventDetails.description
            }%0ALOCATION:${eventDates[0]?.venue}%0AEND:VEVENT%0AEND:VCALENDAR`}
            rel="noreferrer">
            iCal Calendar
          </a>

          {/* OUTLOOK CALENDAR */}
          <a
            className="icon-outlook"
            target="_blank"
            href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://${
              window.location.hostname + "/event_details/" + eventId
            }%0ASUMMARY:${eventDetails.name}%0ADESCRIPTION:${
              eventDetails.description
            }%0ALOCATION:${eventDates[0]?.venue}%0AEND:VEVENT%0AEND:VCALENDAR`}
            rel="noreferrer">
            Outlook Calendar
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          style={{
            backgroundColor: layout_page?.primary_color,
          }}
          onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToCalendarModal;
