import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getVisibleEventsCategories } from "../../services/events";

function SubFooter() {
  const [eventCategorynames, setEventCategoryNames] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // FETCHING EVENT CATEGORIES
    const get_event_categories = async () => {
      const data = await getVisibleEventsCategories();

      // EXTRACTING EVENT CATEGORY NAMES
      const names = data.map((category) => category.name);
      setEventCategoryNames(names);
    };
    get_event_categories();

    // ROTATING WORDS
    const interval = setInterval(() => {
      setCurrentIndex(
        (currentIndex) => (currentIndex + 1) % eventCategorynames.length
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [eventCategorynames.length]);

  return (
    <section className="section-intro padding-y-lg">
      <div className="container">
        <div className="row">
          <div className="col-md-7 text-center">
            <article className="text-dark text-center text-lg-left mb-3">
              <h3>
                Are you ready to take your{" "}
                <b className="rotate-words">
                  {eventCategorynames[currentIndex]}
                </b>{" "}
                event to the next level ?
              </h3>
            </article>
          </div>
          <div className=" text-center text-lg-right col-md-5">
            <Link to="/add_event" className="btn btn-light">
              <i className="fas fa-calendar-plus" /> Create my event
            </Link>
            <Link to="/add_event_ideas" className="btn btn-dark ml-5">
              <i class="fas fa-lightbulb"></i> Submit Event Idea
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubFooter;
