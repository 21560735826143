import React from "react";
import BreadCrump from "../components/BreadCrump/bread_crump";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import { useSettings } from "../store/setting_store";
import { Link } from "react-router-dom";

export default function Contact() {
  const layout_page = useSettings((state) => state.layout_page);
  return (
    <div>
      <Header />

      {/* BREADCRUMP */}
      <BreadCrump title={"Contact"} />

      {/* CONTACT MAIN BODY */}
      <section className="section-content padding-y bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card box">
                <div className="card-body">
                  <h1 className="text-center mb-5">FAQ</h1>
                  <p className="text-center">
                    <Link to="" style={{ color: layout_page?.primary_color }}>
                      How to book tickets?
                    </Link>
                  </p>
                  <p className="text-center">
                    <Link to="" style={{ color: layout_page?.primary_color }}>
                      How to verify a purchased ticket?
                    </Link>
                  </p>
                  <p className="text-center">
                    <Link to="" style={{ color: layout_page?.primary_color }}>
                      How can i present my ticket at the event entrance?
                    </Link>
                  </p>
                  <p className="text-center">
                    <Link to="" style={{ color: layout_page?.primary_color }}>
                      How can i edit my organizer profile ?
                    </Link>
                  </p>
                  <p className="text-center">
                    <Link to="" style={{ color: layout_page?.primary_color }}>
                      Registration
                    </Link>
                  </p>
                  <h4 className="text-center mt-5">
                    <Link
                      to="/how_it_works"
                      style={{ color: layout_page?.primary_color }}
                    >
                      <i className="fas fa-life-ring" /> Help center
                    </Link>
                  </h4>
                  <hr className="mt-5 mb-5" />
                  <h1 className="text-center mb-5">Contact information</h1>
                  <div className="row">
                    <div className="col-12 col-lg-4 card border-0 card-body text-center shadow-none">
                      <figure className="itemside">
                        <div className="aside">
                          <span className="icon-wrap icon-md round bg-info">
                            <i className="fas fa-envelope-open-text white" />
                          </span>
                        </div>
                        <figcaption className="text-wrap align-self-center">
                          <h6 className="title">info@lyxaevents.com</h6>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-12 col-lg-4 card border-0 card-body text-center shadow-none">
                      <figure className="itemside">
                        <div className="aside">
                          <span className="icon-wrap icon-md round bg-info">
                            <i className="fas fa-phone white" />
                          </span>
                        </div>
                        <figcaption className="text-wrap align-self-center">
                          <h6 className="title">+256702059944 </h6>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-12 col-lg-4 card border-0 card-body text-center shadow-none">
                      <figure className="itemside">
                        <div className="aside">
                          <span className="icon-wrap icon-md round bg-info">
                            <i className="fas fa-fax white" />
                          </span>
                        </div>
                        <figcaption className="text-wrap align-self-center">
                          <h6 className="title">+256776176500</h6>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col card border-0 card-body text-center shadow-none">
                      <figure className="itemside">
                        <div className="aside">
                          <span className="icon-wrap icon-md round bg-info">
                            <i className="fas fa-map-marker white" />
                          </span>
                        </div>
                        <figcaption className="text-wrap align-self-center">
                          <h6 className="title">
                            Plot 1645, Bukoto-Ntinda Road, Kampala.
                          </h6>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  <hr className="mt-5 mb-5" />
                  <h1 className="text-center mb-5">Send us an email</h1>
                  <form
                    name="form"
                    method="post"
                    noValidate="novalidate"
                    className="col-12 col-lg-8 mx-auto"
                  >
                    <div id="form">
                      <div className="form-group">
                        <label htmlFor="form_name" className="required">
                          Name
                        </label>
                        <input
                          type="text"
                          id="form_name"
                          name="form[name]"
                          required="required"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="form_email" className="required">
                          Email
                        </label>
                        <input
                          type="text"
                          id="form_email"
                          name="form[email]"
                          required="required"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="form_subject" className="required">
                          Subject
                        </label>
                        <input
                          type="text"
                          id="form_subject"
                          name="form[subject]"
                          required="required"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="form_message" className="required">
                          Message
                        </label>
                        <textarea
                          id="form_message"
                          name="form[message]"
                          required="required"
                          rows={10}
                          className="form-control"
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="form_save"
                          name="form[save]"
                          className="btn text-white btn-block btn"
                          style={{
                            backgroundColor: layout_page?.primary_color,
                          }}
                        >
                          Send
                        </button>
                      </div>
                      <input
                        type="hidden"
                        id="form__token"
                        name="form[_token]"
                        defaultValue="zDi-oCnACV-QQNTG1gf5sh0J6XcZslJVcGhtSeBrHEM"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
