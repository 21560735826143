import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import BreadCrump from "../../components/BreadCrump/bread_crump";
import { Link, useNavigate } from "react-router-dom";

import { getTimeSinceAdded } from "../../services/blogs";
import { useBlogs } from "../../store/blog_store";
import CustomPagination from "../../components/pagination/Pagination";
import { useSettings } from "../../store/setting_store";

function Blogs() {
  //FETCHING BLOGPOSTs
  const getVisibleBlogs = useBlogs((state) => state.getVisibleBlogs);
  // const getTimeSinceAdded=useBlogs((state) => state.getTimeSinceAdded);
  const blog_page = useSettings((state) => state.blog_page);
  const layout_page = useSettings((state) => state.layout_page);
  const [blogposts, setBlogposts] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const blogs = await getVisibleBlogs();
      console.log("visible blogs:", blogs);
      setBlogposts(blogs);
    }
    fetchData();
  }, [blogposts.length]);

  //filtering logic
  const [blogFilter, setBlogFilter] = useState("");
  const filteredBlogs = blogposts.filter((blogpost) => {
    return (
      !blogFilter ||
      (blogpost.name &&
        blogpost.name.toLowerCase().includes(blogFilter.toLowerCase()))
    );
  });

  // GETTING NUMBER OF BLOGS
  const numBlogs = blogposts.length;

  //pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [blogpostsPerPage] = useState(blog_page?.blogs_per_page);
  //get current blogs
  const indexOfLastblogposts = currentPage * blogpostsPerPage;
  const indexOfFastblogposts = indexOfLastblogposts - blogpostsPerPage;
  const current_blogposts = filteredBlogs?.slice(
    indexOfFastblogposts,
    indexOfLastblogposts
  );

  return (
    <div>
      <Header currentPage={"blog"} />
      <BreadCrump title={"Blog"} />

      <section class="section-content padding-y bg-white">
        <div class="container">
          <div class="box shadow-none bg-gray mb-4">
            <div class="row">
              {/* DISPLAYING NUMBER OF BLOGS FOUND */}
              <div class="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0 d-flex justify-content-around">
                <span class="center-lg-y text-muted">
                  {numBlogs} post(s) found
                </span>
              </div>

              {/* SEARCHING FOR BLOGS */}
              <div class="col-sm-12 col-lg-6 offset-lg-3 text-center text-lg-right">
                <form>
                  <input
                    type="text"
                    class="form-control bg-white col-6 display-inline"
                    name="keyword"
                    placeholder="Search blog posts"
                    value={blogFilter}
                    onChange={(e) => setBlogFilter(e.target.value)}
                  />
                  <button
                    type="submit"
                    class="btn text-white display-inline"
                    style={{ backgroundColor: layout_page?.primary_color }}
                  >
                    <i class="fas fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div class="row">
            {/* DISPLAYING BLOG POSTS */}
            {current_blogposts?.map((blogpost, index) => (
              <div key={blogpost?.id} class="col-12 col-sm-6 col-lg-4">
                <div class="card mb-4 shadow-none blog-post-card">
                  {/* DISPLAYING THE BLOGPOST IMAGE */}
                  <Link to={"/blog_details/" + blogpost?.id}>
                    <img
                      class="card-img-top img-lazy-load b-loaded cursor-pointer"
                      src={blogpost?.main_image}
                      alt="5 Ways Event Managers Can Increase Event Registration"
                      height={350}
                    />
                  </Link>

                  <div class="card-body">
                    <p class="card-text">
                      <small class="text-muted mr-3">
                        <a class="text-dark">
                          <i class="fas fa-sitemap"></i>
                          &nbsp;{blogpost?.category?.label}
                        </a>
                      </small>
                      <small class="text-muted mr-3">
                        <i class="far fa-clock"></i>
                        &nbsp;{getTimeSinceAdded(blogpost?.added_at)}
                      </small>

                      {/* DISPLAYNG THE READTIME */}
                      <small class="text-muted">
                        <i class="fas fa-book-reader"></i>
                        &nbsp;{blogpost?.read_time} min read
                      </small>
                    </p>

                    {/* DISPLAYING THE TITLE */}
                    <h5 class="card-title cursor-pointer">
                      <Link class="text-dark">{blogpost?.name}</Link>
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* pagination */}
          {blogposts?.length > blog_page?.blogs_per_page && (
            <CustomPagination
              setCurrentPage={setCurrentPage}
              // totalPages={Math.round(blogposts?.length / blogpostsPerPage)}
              totalPages={Math.ceil(blogposts?.length / blogpostsPerPage)}
            />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Blogs;
