import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/searchable_dropdown/searchable_dropdown";
import FlexPaymentLoggedIn from "./../../components/flexPayment/FlexPaymentLoggedIn";
import { FaAngleRight } from "react-icons/fa";
import { Timestamp } from "firebase/firestore";
import {
  convertTimestampToDateTime,
  displayErrorToast,
  displaySuccessToast,
  formatDate,
  formatPhoneNumber,
  generateBookingId,
  sendEmail,
} from "../../services/helpers";
import Loader from "./../../components/loader/loader";
import { userStore } from "../../store/user_store";
import { useEvents } from "./../../store/event_store";
import { usePromoCodes } from "../../store/promocode_store";
import { ordersStore } from "../../store/orders_store";
import { useSettings } from "../../store/setting_store";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./../../components/eventDetail/EventDetail.css";
import AirtelMTN from "./../../components/flexPayment/optionpics/airtelMtn.webp";
import FlexiPay from "./../../components/flexPayment/optionpics/flexipay.png";
import { auth } from "../../Firebase";

export default function Checkout() {
  const user = userStore((state) => state.user);
  const isAttendee = userStore((state) => state.isAttendee);
  const isLoggedIn = userStore((state) => state.isLoggedIn);
  const getCartItems = userStore((state) => state.getCartItems);
  const getEvent = useEvents((state) => state.getEvent);
  const cartItems = userStore((state) => state.cartItems);
  const clearCart = userStore((state) => state.clearCart);
  const addUserToPromocodeBeneficiaries = usePromoCodes(
    (state) => state.addUserToPromocodeBeneficiaries
  );
  const getCountriesList = useEvents((state) => state.getCountriesLists);
  //adding order
  const addOrder = ordersStore((state) => state.addOrder);
  const layout_page = useSettings((state) => state.layout_page);

  // const [totalAmount, setTotalAmount] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState("");
  const [setCity] = useState("");
  const [setStreetAddress] = useState("");
  const [ticket_quantities, setticket_quantities] = useState([]);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatter = new Intl.NumberFormat("en-US");

  const location = useLocation();
  const totalAmount = location.state?.totalAmount;
  const promoDetails = location.state?.promoDetails;
  const gross = location.state?.gross;

  //handling validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9,9}$/, "valid phone number required")
      .test("valid-prefix", "MTN or Airtel phone number", (value) => {
        // Ensure phoneNumber is a string
        const phoneNumberString = String(value);

        // Check the prefix to determine payment type
        if (
          phoneNumberString.startsWith("75") ||
          phoneNumberString.startsWith("74") ||
          phoneNumberString.startsWith("70") ||
          phoneNumberString.startsWith("77") ||
          phoneNumberString.startsWith("78") ||
          phoneNumberString.startsWith("76")
        ) {
          return true;
        }
        return false;
      })
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: user?.firstname,
      last_name: user?.lastname,
      email: user?.email,
      phoneNumber: formatPhoneNumber(user?.phone_number),
    },
    validationSchema,
    onSubmit: handleOrder,
  });

  // GETTING THE LIST OF COUNTRIES
  const get_countries_list = async () => {
    const returnedCountries = await getCountriesList();
    const countries = [];

    for (var i = 0; i < returnedCountries?.length; i++) {
      countries.push({
        value: returnedCountries[i].countryCode,
        label: returnedCountries[i].name,
      });
    }
    setCountriesList(countries);
  };

  useEffect(() => {
    // FETCH THE CART ITEMS
    async function get_cart_items() {
      await getCartItems(user?.user_id);

      let numTickets = 0;
      let total = 0;
      const quantities = [];
      const eventsList = [];

      cartItems.map(async (cartItem) => {
        numTickets += parseInt(cartItem.quantity);
        total += parseInt(cartItem.quantity) * parseInt(cartItem.price ?? "0");

        quantities.push({
          event_id: cartItem.event_id,
          quantity: cartItem.quantity,
          ticket_id: cartItem.ticket_id,
        });

        const response = await getEvent(cartItem.event_id);
        eventsList.push(response);
      });

      // setTotalAmount(total);
      setticket_quantities(quantities);
      setEvents(eventsList);
    }

    if (user && isAttendee) {
      get_cart_items();
    }

    get_countries_list();
  }, []);

  //handling Orders
  async function handleOrder() {
    setIsLoading(true);

    // GENERATING THE TRANSACTION ID
    const orderTickets = [];
    const orderId = generateBookingId();

    // Iterate over the selected events
    for (let i = 0; i < events?.length; i++) {
      const event = events[i];

      // Iterate over the tickets for each event
      for (let j = 0; j < event?.event_dates[0]?.tickets.length; j++) {
        const ticket = event?.event_dates[0]?.tickets[j];
        console.log(`TICKET: ${j}`, ticket);

        if (ticket) {
          // Get the quantity from the cart items
          const cartItem = cartItems.find((item) => item.event_id === event.id);
          const quantity = parseInt(cartItem?.quantity);

          orderTickets.push({
            quantity: quantity,
            ticket_id: ticket?.id,
            event_id: event?.id,
          });
        }
      }
    }

    const orderData = {
      amount: 0,
      tickets: orderTickets,
      customer_email: values.email,
      customer_id: null,
      customer_name: values.first_name + " " + values.last_name,
      customer_number: `256${values.phoneNumber}`,
      order_id: orderId,
    };

    // ADDING THE ORDER TO THE DATABASE
    const orderResponse = await addOrder(orderData);

    if (orderResponse) {
      // CLEAR CART
      await clearCart(user?.user_id);

      // MARK PROMOCODE USED FOR THIS USER
      if (promoDetails) {
        orderTickets.forEach(async (ticket) => {
          // IF THE TICKET HAS A PROMOCODE
          if (promoDetails[ticket?.ticket_id]) {
            await addUserToPromocodeBeneficiaries(
              values.email,
              promoDetails[ticket?.ticket_id]?.id
            );
          } else {
            console.log("NO PROMOCODE FOR THIS TICKET");
          }
        });
      } else {
        console.log("THE PROMOCODE DETAILS ARE EMPTY: " + promoDetails);
      }

      // DISPLAYING THE SUCCESS TOAST
      displaySuccessToast("Order Placed Successfully!");

      setIsLoading(false);
      navigate("/success_screen", { state: { orderData } });

      // SENDING THE EMAIL
      var sendEmailResponse = await sendEmail({
        data: orderData,
        subject: `Lyxa Events ${events[0]?.name} Order Confirmation`,
        email: values.email,
      });

      if (sendEmailResponse) {
        displaySuccessToast("Email With Order details will be sent shortly!");
      } else {
        displayErrorToast(
          "Something Went Wrong Sending the email, Please save your tickets here."
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      displayErrorToast(
        "Something Went Wrong Placing Order, Please try again later."
      );
    }
  }

  // INITIALIZING USENAVIGATE
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/sign_in");
    return;
  }

  // IF IS LOADING
  if (isLoading) {
    return <Loader />;
  }

  // CHECKING IF THE USER IS AN ATTENDEE
  if (!isAttendee || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />

        {/* CHECKOUT BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">Checkout</h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <a href="/" className="dark">
                    <i className="fas fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/dashboard" className="dark">
                    Dashboard
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/cart" className="dark">
                    My cart
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Checkout
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/* CHECKOUT SECTION */}
        <section className="section-content bg-white padding-y">
          <div className="container">
            <div className="row">
              <main className="col-lg-8 order-1 order-lg-0 mt-4 mt-lg-0">
                <div className="card">
                  <div className="card-header">
                    <h6 className="b mb-0">Order summary</h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover shopping-cart-wrap">
                        <thead className="text-muted">
                          <tr>
                            <th style={{ width: "55%" }} scope="col">
                              Event / Ticket
                            </th>
                            <th
                              style={{ width: "15%" }}
                              scope="col"
                              width={100}
                            >
                              Price
                            </th>
                            <th
                              style={{ width: "15%" }}
                              scope="col"
                              width={100}
                            >
                              Quantity
                            </th>
                            <th
                              style={{ width: "15%" }}
                              scope="col"
                              className="text-right"
                              width={100}
                            >
                              Subtotal
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems?.map((item) => (
                            <tr>
                              <td width="55%">
                                <figure className="media h-100">
                                  <div className="img-wrap my-auto">
                                    <img
                                      src={item?.event_image}
                                      className="img-thumbnail img-sm img-lazy-load"
                                      alt="Event"
                                    />
                                  </div>
                                  <figcaption className="media-body my-auto ">
                                    <Link
                                      style={{
                                        color: layout_page?.primary_color,
                                      }}
                                      to={"/events/" + item?.event_id}
                                    >
                                      <h6 className="title mb-2">
                                        {item?.event_name}
                                      </h6>
                                    </Link>
                                    <dl className="dlist-inline small">
                                      <dt />
                                      <dd className="mr-0">
                                        {item?.ticket_name && (
                                          <p>{item.ticket_name} ticket</p>
                                        )}
                                        {item?.table_name && (
                                          <p>{item.table_name} table</p>
                                        )}
                                        <i
                                          className="fas fa-info-circle ml-2"
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                          data-toggle="tooltip"
                                          data-original-title="Get a front seat and a popcorn bag"
                                        />
                                      </dd>
                                    </dl>

                                    <dl className="dlist-inline small">
                                      <dt>When: </dt>&nbsp;
                                      <dd>
                                        {formatDate(
                                          convertTimestampToDateTime(
                                            item?.event_start_date
                                          )
                                        )}
                                      </dd>
                                    </dl>
                                    <dl className="dlist-inline small">
                                      <dt>Where: </dt> &nbsp;
                                      <dd>{item?.venue}</dd>
                                    </dl>
                                    <dl className="dlist-inline small">
                                      <dt>Organizer: </dt>&nbsp;
                                      <dd>
                                        <Link
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                          href={
                                            "/organizer_details/" +
                                            item?.organizer?.id
                                          }
                                          taget="_blank"
                                        >
                                          {item?.organizer?.organizer_name}
                                        </Link>
                                      </dd>
                                    </dl>
                                    {/* table labes if available */}

                                    {item?.is_table_ticket && (
                                      <dl className="dlist-inline small mr-2">
                                        <dt className="mr-2">
                                          Selected Tables:{" "}
                                        </dt>
                                        <dd>
                                          {item?.selectedTables.map(
                                            (table, index_label) => (
                                              <span key={index_label}>
                                                {table?.tableLabel}
                                                {index_label <
                                                  item.selectedTables.length -
                                                    1 && ", "}
                                              </span>
                                            )
                                          )}
                                        </dd>
                                      </dl>
                                    )}
                                  </figcaption>
                                </figure>
                              </td>
                              <td width="15%">
                                <div className="price-wrap">
                                  <span
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                    className="price-new"
                                  >
                                    UGX {formatter.format(item?.price)}
                                  </span>
                                </div>
                              </td>
                              <td width="15%">{item?.quantity}</td>
                              <td width="15%" className="text-right">
                                <div className="price-wrap">
                                  <span
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                    className="price"
                                  >
                                    UGX{" "}
                                    {formatter.format(
                                      parseInt(item?.quantity) *
                                        parseInt(item?.price ?? "0")
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {totalAmount === 0 ? (
                  <form name="checkout" onSubmit={handleSubmit}>
                    {/* BILLING INFORMATION */}
                    <div className="card mt-4">
                      <div className="card-header">
                        <h6 className="b mb-0">Billing information</h6>
                      </div>
                      <div className="card-body">
                        <div className="form-row">
                          {/* FIRST NAME */}
                          <div className="col form-group">
                            <label htmlFor="first_name" className="required">
                              First name
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              className={
                                errors.first_name && touched.first_name
                                  ? "input-error form-control"
                                  : "form-control"
                              }
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.first_name && touched.first_name && (
                              <p className="error">{errors.first_name}</p>
                            )}
                          </div>

                          {/* LAST NAME */}
                          <div className="col form-group">
                            <label
                              htmlFor="checkout_lastname"
                              className="required"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              className={
                                errors.last_name && touched.last_name
                                  ? "input-error form-control"
                                  : "form-control"
                              }
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.last_name && touched.last_name && (
                              <p className="error">{errors.last_name}</p>
                            )}
                          </div>
                        </div>

                        {/* USER EMAIL */}
                        <div className="form-row">
                          <div className="col form-group">
                            <label
                              htmlFor="checkout_email"
                              className="required"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className={
                                errors.email && touched.email
                                  ? "input-error form-control"
                                  : "form-control"
                              }
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                              <p className="error">{errors.email}</p>
                            )}
                          </div>

                          {/* PHONE NUMBER */}
                          <div className="col form-group">
                            <label htmlFor="phoneNumber" className="required">
                              Phone Number
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                  style={{ borderRadius: "5px" }}
                                >
                                  +256
                                </span>
                              </div>
                              <input
                                type="text"
                                name="phoneNumber"
                                className={
                                  errors.phoneNumber && touched.phoneNumber
                                    ? "input-error form-control"
                                    : "form-control"
                                }
                                placeholder="+2567XXXXXXXX"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>

                            {errors.phoneNumber && touched.phoneNumber && (
                              <p className="error">{errors.phoneNumber}</p>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          {/* COUNTRY */}
                          <div className="col form-group">
                            <label htmlFor="checkout_country">Country</label>

                            <SearchableDropdown
                              value={country}
                              options={countriesList}
                              onChange={(e) => setCountry(e)}
                              isMulti={false}
                              defaultValue={{ value: "256", label: "Uganda" }}
                              placeholder={"Select Country"}
                            />
                          </div>

                          {/* CITY */}
                          <div className="col form-group">
                            <label htmlFor="checkout_state">City</label>
                            <input
                              type="text"
                              id="city"
                              name="city"
                              placeholder="City"
                              className="form-control"
                              onChange={(e) => setCity(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col form-group">
                            <label htmlFor="street_address">
                              Street Address
                            </label>
                            <input
                              type="text"
                              name="street_address"
                              className="form-control"
                              onChange={(e) => setStreetAddress(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className=" text-uppercase d-flex align-items-center justify-content-center py-2 text-base btn-checkout my-3 w-100 border-0"
                      style={{ height: "40px" }}
                    >
                      Get Free Ticket
                      <FaAngleRight size={20} className={"ml-2"} />
                    </button>
                  </form>
                ) : (
                  <>
                    {/* IF TICKETS ARE TO BE PAYED */}

                    <FlexPaymentLoggedIn
                      amount={totalAmount}
                      events={events}
                      orderTickets={ticket_quantities}
                      promoDetails={promoDetails}
                      cartItems={cartItems}
                    />
                  </>
                )}
              </main>
              <aside className="col-lg-4 pt-3 pt-lg-0 order-0 order-lg-1">
                <div className="sticky-top sticky-sidebar">
                  {/* NUMBER OF TICKETS */}
                  <dl className="dlist-align">
                    <dt>Tickets</dt>
                    <dd className="text-right ml-5">
                      UGX {formatter.format(gross)}
                    </dd>
                  </dl>

                  {/* DISCOUNT */}
                  {totalAmount !== gross && (
                    <dl className="dlist-align">
                      <dt>Discount: </dt>
                      <dd className="text-right ml-5">
                        {" "}
                        UGX {formatter.format(gross - totalAmount)}
                      </dd>
                    </dl>
                  )}

                  {/* TOTAL */}
                  <dl className="dlist-align h4">
                    <dt>Total</dt>
                    <dd className="text-right ml-5">
                      <strong>UGX {formatter.format(totalAmount)}</strong>
                    </dd>
                  </dl>
                  <hr />

                  {/* SUPPORTED PAYMENT METHODS */}
                  <div className="row">
                    {/* AIRTEL MONEY LOGO */}
                    <div className="col">
                      <img
                        src={AirtelMTN}
                        style={{ height: "2.5rem" }}
                        className="width-auto img-fluid"
                        alt="airtel money"
                      />
                    </div>

                    {/* MTN MOMO LOGO */}
                    <div className="col">
                      <img
                        src={FlexiPay}
                        className="width-auto img-fluid"
                        style={{ height: "2.5rem" }}
                        alt="mtn momo"
                      />
                    </div>

                    {/* VISA LOGO */}
                    {/* <div className="col">
                      <img
                        src={visa}
                        className="width-auto img-fluid py-1"
                        style={{ height: "2.5rem" }}
                        alt="visa"
                      />
                    </div> */}

                    {/* MASATERCARD LOGO */}
                    {/* <div className="col">
                      <img
                        src={mastercard}
                        className="width-auto img-fluid"
                        style={{ height: "2.5rem" }}
                        alt="mastercard"
                      />
                    </div> */}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
