import { Link, useNavigate, useParams } from "react-router-dom";
import { ordersStore } from "../../store/orders_store";
import { userStore } from "../../store/user_store";
import Sidenavbar from "./components/sidenavbar";
import { isMobile } from "react-device-detect";
import { useEffect, useState, useRef } from "react";
import Loader from "../../components/loader/loader";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import CustomPagination from "../../components/pagination/Pagination";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import { FaFilePdf, FaUserCircle } from "react-icons/fa";
import PopingOutOrderDetailsOrganizer from "./PopingOutOrderDetailsOrganizer";
import { useSettings } from "../../store/setting_store";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPdf from "react-to-pdf";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { auth } from "../../Firebase";

export default function ManageOrganizerOrders() {
  const { id } = useParams();
  const pdfRef = useRef();

  const isOrganizer = userStore((state) => state.isOrganizer);
  const fetchOrders = ordersStore((state) => state.fetchOrders);
  //get all orders
  const getSpecificOrder = ordersStore(
    (state) => state.getOrdersOfSpecificEvent
  );

  const organizerDetails = ordersStore((state) => state.getOrganizerDetail);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [organizer, setOrganizer] = useState([]);

  const [isExportingPdf, setIsExportingPdf] = useState(true);

  // Truncate text function the date
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength);
    }
    return text;
  }

  //exporting to pdf
  const exportToPdf = () => {
    setIsExportingPdf(false);
    const input = pdfRef.current;

    // Capture the table element as a canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the PDF page to match the table
      const pdfWidth = 210; // A4 page width in mm
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("attendee_details.pdf");
    });
  };

  //fetching orders from the database
  useEffect(() => {
    const fetchDataOrders = async () => {
      await fetchOrders();
      const specificOrder = await getSpecificOrder(id);

      setOrders(specificOrder);

      //get OrganizerDetails
      const details = await organizerDetails(id);
      setOrganizer(details);
    };
    fetchDataOrders();
    //set a delay for 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [orders.length]);

  //SETTING FILTERS
  const [bookingIdFilter, setBookingIdFilter] = useState("");

  //filtering logic
  const filteredOrders = orders.filter((order) => {
    return (
      !bookingIdFilter ||
      (order.booking_id &&
        order.booking_id.toLowerCase().includes(bookingIdFilter.toLowerCase()))
    );
  });

  //sorting by registration desc and asc/ last_seen_at
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedOrders = filteredOrders.slice().sort((a, b) => {
    const direction = sortDirection === "desc" ? -1 : 1;

    if (sortCriteria === "created_at") {
      const dateA = a.created_at;
      const dateB = b.created_at;
      return (dateA - dateB) * direction;
    } else if (sortCriteria === "other_criteria") {
      return (a.other_criteria - b.other_criteria) * direction;
    } else {
      return 0;
    }
  });

  // PAGINATION STATUS
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(50);
  //get current orders
  const indexOfLastorders = currentPage * ordersPerPage;
  const indexOfFastorders = indexOfLastorders - ordersPerPage;
  const current_orders = sortedOrders?.slice(
    indexOfFastorders,
    indexOfLastorders
  );

  //getting only the tickets of that specific event
  const eventTickets = current_orders.map((order) =>
    order?.order_tickets?.filter((ticket) => ticket.event_id === id)
  );

  //displaying the loader
  if (isLoading) {
    return <Loader />;
  }

  if (!isOrganizer || !auth.currentUser?.emailVerified) {
    navigate("/access_denied");
    return null;
  } else {
    return (
      <div>
        <Header />

        {/* BREADCRUMP */}
        <section className="section-pagetop bg-gray">
          <div className="container clearfix">
            <h4 className="title-page dark b float-xl-left mb-0">Attendees</h4>
            <nav className="float-xl-right mt-2 mt-xl-0">
              <ol className="breadcrumb text-white">
                <li className="breadcrumb-item">
                  <a href="/en" className="dark">
                    <i className="fas fa-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/en/dashboard" className="dark">
                    Dashboard
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Attendees
                </li>
              </ol>
            </nav>
          </div>
        </section>

        {/* MANAGE ORDERS MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* SIDE BAR */}
              <aside className="col-lg-3 pt-3 pt-lg-0">
                {!isMobile && <Sidenavbar currentPage={"my_reports"} />}
              </aside>

              {/* MAIN CONTENT */}
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  {/* NUMBER OF RESULTS */}
                  <div className="row">
                    <div className="col-sm-12 col-lg-3 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {current_orders?.length} result(s) found
                      </span>
                    </div>

                    {/* SORT */}
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          value={`${sortCriteria}&direction=${sortDirection}`}
                          onChange={(e) => {
                            const [criteria, direction] =
                              e.target.value.split("&direction=");
                            setSortCriteria(criteria);
                            setSortDirection(direction);
                          }}
                        >
                          <option
                            value={`created_at&direction=desc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "desc"
                            }
                          >
                            Creation date (desc)
                          </option>

                          <option
                            value={`created_at&direction=asc`}
                            selected={
                              sortCriteria === "created_at" &&
                              sortDirection === "asc"
                            }
                          >
                            Creation date (asc)
                          </option>

                          {/* Add similar options for other criteria */}
                        </select>
                      </label>
                    </div>

                    {/* EXPORT BUTTONS */}
                    <div className="col-sm-12 col-lg-4 text-center text-lg-right">
                      {/* EXPORT ORDERS TO EXCEL BUTTON */}
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className=" btn text-white bg-orange fas fa-file-excel fa-lg"
                        table="table-to-xls"
                        filename="attendee_details"
                        sheet="orders"
                        buttonText=""
                        data-toggle="tooltip"
                        title="Export attendee details to an Excel file"
                      />

                      {/* EXPORT ORDERS TO PDF BUTTON */}
                      <button
                        onClick={exportToPdf}
                        className="btn text-white ml-1"
                        style={{
                          backgroundColor: layout_page?.primary_color,
                        }}
                        data-toggle="tooltip"
                        title="Export attendee details to a PDF file"
                      >
                        <FaFilePdf size={15} />
                      </button>
                    </div>

                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        {/* SDEARCH FILTERS */}
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="reference">Reference</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white"
                              id="reference"
                              name="reference"
                              value={bookingIdFilter}
                              onChange={(e) =>
                                setBookingIdFilter(e.target.value)
                              }
                            />
                          </div>

                          {/* filter by from date */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="datefrom">From date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="datefrom"
                              name="datefrom"
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="dateto">Until date</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 form-control bg-white datetimepicker"
                              id="dateto"
                              name="dateto"
                            />
                          </div>

                          <div className="col-12 col-sm-4 text-left mb-4">
                            <button
                              type="reset"
                              className="btn text-white"
                              style={{
                                backgroundColor: layout_page?.primary_color,
                              }}
                              data-toggle="tooltip"
                              title="Reset"
                            >
                              <i className="fas fa-search-minus" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* DATA AREA */}
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <div className="card">
                          {orders.length === 0 && (
                            <div className="alert alert-info alert-icon">
                              <i className="fas fa-exclamation-circle" /> No
                              orders for your event found
                            </div>
                          )}
                        </div>
                        {current_orders.length > 0 && (
                          <div className="table-responsive">
                            <div className="fw-bold d-flex flex-column mt-4 ml-4 gap-2">
                              <div className="d-flex align-items-center">
                                <span
                                  className="fw-normal"
                                  style={{ width: 120 }}
                                >
                                  Event Name:
                                </span>
                                <span
                                  className="fw-normal"
                                  style={{
                                    color: layout_page?.primary_color,
                                  }}
                                >
                                  {current_orders[0]?.event_name}
                                </span>
                              </div>

                              <div className="d-flex align-items-center">
                                <span
                                  className="fw-normal"
                                  style={{ width: 120 }}
                                >
                                  Event Organizer:
                                </span>
                                <span
                                  className="fw-normal"
                                  style={{
                                    color: layout_page?.primary_color,
                                  }}
                                >
                                  {organizer?.organizer_name}
                                </span>
                              </div>
                            </div>
                            <div id="pdf-target" ref={pdfRef}>
                              <table
                                id="table-to-xls"
                                className="table table-hover table-vcenter text-nowrap"
                              >
                                <thead>
                                  <tr>
                                    <th className="text-center">Order ID</th>
                                    <th>Ticket</th>
                                    <th>Customer Name</th>
                                    <th>Customer Email</th>
                                    <th>Customer Phone Number</th>
                                    <th>Order Date</th>
                                    {isExportingPdf && (
                                      <th className="text-center">
                                        <i className="fas fa-cog" />
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                {/* MAPPING OF THE ORDERS RECIEVED */}
                                <tbody>
                                  {current_orders.map((order, index) => (
                                    <tr key={order?.id}>
                                      {/* ORDER ID */}
                                      <td className="text-center text-muted text-sm">
                                        <Link
                                          style={{
                                            color: layout_page?.primary_color,
                                          }}
                                          to={
                                            "/ticket_details/" +
                                            order?.booking_id
                                          }
                                        >
                                          {order?.booking_id}
                                        </Link>
                                      </td>

                                      {/* ORDER DETAILS */}
                                      <td className="text-sm">
                                        <ul className="p-0">
                                          {/* Group the tickets by event */}
                                          {Object.values(
                                            eventTickets[index]?.reduce(
                                              (acc, ticket) => {
                                                const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                                if (!acc[key]) {
                                                  acc[key] = [];
                                                }
                                                acc[key].push(ticket);
                                                return acc;
                                              },
                                              {}
                                            )
                                          ).map((tickets, eventIndex) => (
                                            <li key={eventIndex}>
                                              {/* TICKET NAME AND COUNT */}
                                              {Object.values(
                                                tickets.reduce(
                                                  (acc, ticket) => {
                                                    const key = `${ticket.event_id}_${ticket.ticket_name}`;
                                                    if (!acc[key]) {
                                                      acc[key] = 0;
                                                    }
                                                    acc[key]++;
                                                    return acc;
                                                  },
                                                  {}
                                                )
                                              ).map((count, ticketIndex) => (
                                                <div key={ticketIndex}>
                                                  {ticketIndex > 0 && ", "}
                                                  {
                                                    tickets[ticketIndex]
                                                      ?.ticket_name
                                                  }{" "}
                                                  ({count}{" "}
                                                  {count > 1
                                                    ? "tickets"
                                                    : "ticket"}
                                                  )
                                                </div>
                                              ))}
                                            </li>
                                          ))}
                                        </ul>
                                      </td>

                                      {/* CUSTOMER NAME */}
                                      <td className="text-sm">
                                        <span className="ajax-loading d-flex align-items-center">
                                          <FaUserCircle
                                            size={30}
                                            color="gray"
                                          />
                                          <span className="ml-1">
                                            {order?.customer_name}
                                          </span>
                                        </span>
                                      </td>

                                      {/* customer email */}
                                      <td className=" text-sm">
                                        <span className=" ajax-loading">
                                          {order?.customer_email}
                                        </span>
                                      </td>

                                      {/* customer phone number */}
                                      <td className=" text-sm">
                                        <span className=" ajax-loading">
                                          {order?.customer_number}
                                        </span>
                                      </td>

                                      {/* ORDER DATE */}

                                      <td className="text-sm">
                                        {truncateText(
                                          formatDate(
                                            convertTimestampToDateTime(
                                              order?.created_at
                                            )
                                          ),
                                          17
                                        )}
                                      </td>

                                      {/* ACTIONS */}
                                      {isExportingPdf && (
                                        <td className=" text-center">
                                          <PopingOutOrderDetailsOrganizer
                                            order_id={order?.id}
                                            booking_id={order?.booking_id}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* PAGINATION */}
                    {sortedOrders?.length > 50 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          sortedOrders?.length / ordersPerPage
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
