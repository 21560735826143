import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import AdminSideNav from "./components/admin_side_nav";
import PopingOutReviews from "./PopingOutReviews";
import { FaStar } from "react-icons/fa";
import { userStore } from "../../store/user_store";
import { useReview } from "../../store/review_store";
import { convertTimestampToDateTime, formatDate } from "../../services/helpers";
import CustomPagination from "../../components/pagination/Pagination";
import Alert from "../attendee_dashboard/components/alert";
import { useSettings } from "../../store/setting_store";
import BreadcrumpAdmin from "./components/breadcrump_admin";

export default function ManageEventReviews() {
  //fetching all reviews
  const isAdmin = userStore((state) => state.isAdmin);
  const reviews = useReview((state) => state.reviews);
  const getEventReviews = useReview((state) => state.getEventReviews);
  const layout_page = useSettings((state) => state.layout_page);

  const navigate = useNavigate();
  const [event_reviews, setEventReviews] = useState([]);

  const { event_id } = useParams();

  //fetching reviews from the database
  useEffect(() => {
    const fetchingReviews = async () => {
      const response = await getEventReviews(event_id);
      console.log("THE LENGTH OF REVIEWS FOR THIS EVENT: ", response);
      setEventReviews(response);
    };
    fetchingReviews();
  }, [reviews.length]);

  //filtering the reviews basing on event name

  const [eventNameFilter, setEventNameFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [visibilityFilter, setVisibilityFilter] = useState("all");
  const [ratingFilter, setRatingFilter] = useState("all");

  //filtering logic
  const filteredReviews = reviews.filter((review) => {
    return (
      (!eventNameFilter ||
        (review.event_name &&
          review.event_name
            .toLowerCase()
            .includes(eventNameFilter.toLowerCase()))) &&
      (!keywordFilter ||
        (review.review_headline &&
          review.review_headline
            .toLowerCase()
            .includes(keywordFilter.toLowerCase()))) &&
      (visibilityFilter === "all" ||
        (visibilityFilter === "visible" && review.status === "visible") ||
        (visibilityFilter === "invisible" && review.status === "invisible")) &&
      (ratingFilter === "all" || review.rating.toString() === ratingFilter)
    );
  });

  //FILTER BY CREATION DATE AND POPULARITY
  const [sortCriteria, setSortCriteria] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  //function to update the sorting state when the user selects a different sorting option
  const handleSortChange = (value) => {
    const [criteria, direction] = value.split("-");
    setSortCriteria(criteria);
    setSortDirection(direction);
  };

  const sortedReviews = filteredReviews?.slice().sort((a, b) => {
    if (sortCriteria === "rating") {
      // Convert rating strings to numbers for sorting
      const ratingA = parseInt(a.rating, 10);
      const ratingB = parseInt(b.rating, 10);

      // Sort by rating
      return sortDirection === "desc" ? ratingB - ratingA : ratingA - ratingB;
    } else {
      // Sort by creation date
      return sortDirection === "desc"
        ? new Date(b.created_at) - new Date(a.created_at)
        : new Date(a.created_at) - new Date(b.created_at);
    }
  });

  //PAGINATION STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(20);
  //get current events
  const indexOfLastreviews = currentPage * reviewsPerPage;
  const indexOfFastreviews = indexOfLastreviews - reviewsPerPage;
  const current_reviews = sortedReviews?.slice(
    indexOfFastreviews,
    indexOfLastreviews
  );

  if (!isAdmin) {
    navigate("/access_denied");
  } else {
    return (
      <div>
        <Header />

        {/* ADMIN DASHBOARD BREADCRUMP */}
        <BreadcrumpAdmin title="Manage Reveiws" />

        {/* ADMIN DASHBOARD MAIN BODY */}
        <section className="section-content padding-y bg-white">
          <div className="container">
            <div className="row">
              {/* ADMIN DASHBOARD SIDE NAV */}
              <AdminSideNav currentPage={"manage_reviews"} />

              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="box shadow-none bg-gray mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5 text-center text-lg-left mb-3 mb-lg-0">
                      <span className="center-lg-y text-muted">
                        {event_reviews.length} reviews(s) found
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-5 text-center text-lg-right">
                      <label
                        htmlFor="sortable-select"
                        className="display-inline"
                      >
                        Sort by
                        <select
                          id="sortable-select"
                          className="form-control display-inline-block bg-white select2 ml-3"
                          data-placeholder="Select an option"
                          style={{ width: "50%" }}
                          onChange={(e) => handleSortChange(e.target.value)}
                        >
                          <option value="rating-desc">Rating (desc)</option>
                          <option value="rating-asc">Rating (asc)</option>
                          <option value="created_at-desc">
                            Review date (desc)
                          </option>
                          <option value="created_at-asc">
                            Review date (asc)
                          </option>
                        </select>
                      </label>
                    </div>
                    <div className="col-sm-12 col-lg-2 text-center text-lg-right">
                      <a
                        href="#search-filters"
                        className="btn text-white has-tooltip"
                        style={{ backgroundColor: layout_page?.primary_color }}
                        data-toggle="collapse"
                        title="Toggle display the search filters"
                        aria-expanded="false"
                        aria-controls="search-filters"
                      >
                        <i className="fab fa-searchengin fa-lg" />
                      </a>
                    </div>
                    <div
                      id="search-filters"
                      className="col-sm-12 col-lg-12 text-center mb-3 mb-lg-0 collapse show"
                    >
                      <hr />
                      <form>
                        <div className="row">
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="keyword">Keyword</label>
                            <input
                              id="keyword"
                              name="keyword"
                              type="text"
                              className="form-control bg-white"
                              defaultValue
                              placeholder="Search in reviews"
                              style={{ width: "auto" }}
                              value={keywordFilter}
                              onChange={(e) => setKeywordFilter(e.target.value)}
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <label htmlFor="event">Event</label>
                            <input
                              type="text"
                              className="mr-sm-2 mb-2 mb-sm-0 autocomplete bg-white form-control"
                              id="event"
                              name="event"
                              data-url-list="/en/api/get-events?published=all&elapsed=all"
                              data-minimum-input-length={0}
                              value={eventNameFilter}
                              onChange={(e) =>
                                setEventNameFilter(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="visible-filter-all"
                                type="radio"
                                name="all"
                                value="all"
                                className="custom-control-input"
                                checked={visibilityFilter === "all"}
                                onChange={() => setVisibilityFilter("all")}
                              />
                              <label
                                htmlFor="visible-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="visible-filter-yes"
                                type="radio"
                                name="visible"
                                value="visible"
                                className="custom-control-input"
                                checked={visibilityFilter === "visible"}
                                onChange={() => setVisibilityFilter("visible")}
                              />
                              <label
                                htmlFor="visible-filter-yes"
                                className="custom-control-label"
                              >
                                Visible only
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                id="invisible-filter-yes"
                                type="radio"
                                name="invisible"
                                value="invisible"
                                className="custom-control-input"
                                checked={visibilityFilter === "invisible"}
                                onChange={() =>
                                  setVisibilityFilter("invisible")
                                }
                              />
                              <label
                                htmlFor="invisible-filter-yes"
                                className="custom-control-label"
                              >
                                Hidden only
                              </label>
                            </div>
                          </div>

                          {/* filtering by stars */}
                          <div className="col-12 col-sm-4 text-left mb-4">
                            <div className="custom-control custom-checkbox">
                              <input
                                id="rating-filter-all"
                                type="radio"
                                name="rating"
                                value="all"
                                className="custom-control-input"
                                checked={ratingFilter === "all"}
                                onChange={() => setRatingFilter("all")}
                              />
                              <label
                                htmlFor="rating-filter-all"
                                className="custom-control-label"
                              >
                                All
                              </label>
                            </div>
                            {[5, 4, 3, 2, 1].map((star) => (
                              <div
                                key={star}
                                className="custom-control custom-checkbox"
                              >
                                <input
                                  id={`rating-filter-${star}`}
                                  type="radio"
                                  name="rating"
                                  value={star.toString()}
                                  className="custom-control-input"
                                  checked={ratingFilter === star.toString()}
                                  onChange={() =>
                                    setRatingFilter(star.toString())
                                  }
                                />
                                <label
                                  htmlFor={`rating-filter-${star}`}
                                  className="custom-control-label"
                                >
                                  {star} stars
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {event_reviews.length === 0 ? (
                  <>
                    <div className="col mt-4">
                      <Alert message={"No Reviews Found"} />
                    </div>
                  </>
                ) : (
                  <div className="card box">
                    <div className="card-body">
                      <div className="row mb-4">
                        <div>
                          {/* STARTING OF THE REVIEWS */}
                          {current_reviews.map((review, index) => (
                            <div
                              className="col border border-1 border-white mb-3 rounded shadow-sm"
                              key={review?.id}
                            >
                              <div className="user-review-wrapper mt-2 ">
                                <Link to="">
                                  <img
                                    src={review?.event_image}
                                    alt=""
                                    className="img-thumbnail img-100-100 mr-2"
                                  />
                                  <span
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  >
                                    {review?.event_name}
                                  </span>
                                </Link>

                                <div className=" mt-2">
                                  <span>
                                    {Array.from({ length: 5 }, (_, index) => (
                                      <FaStar
                                        size={15}
                                        key={index}
                                        color={
                                          index < review?.rating
                                            ? layout_page?.primary_color
                                            : "gray"
                                        }
                                      />
                                    ))}
                                  </span>

                                  <div
                                    className="label-rating ml-2"
                                    style={{
                                      color: layout_page?.primary_color,
                                    }}
                                  >
                                    {review?.rating} out of 5 stars
                                  </div>
                                </div>
                                <small className="text-muted mt-1">
                                  {formatDate(
                                    convertTimestampToDateTime(
                                      review?.created_at
                                    )
                                  )}
                                </small>

                                <h6 className="mt-2 font-weight-bold">
                                  {review?.review_headline}
                                </h6>
                                <p
                                  className="mt-2 text-sm readmore rmjs-1"
                                  data-collapsed-height={200}
                                  data-height-margin={20}
                                  style={{ height: "auto", maxHeight: "none" }}
                                >
                                  {review?.review_body}
                                </p>
                              </div>
                              <div className=" d-flex justify-content-between">
                                {review?.status === "visible" ? (
                                  <span className="badge badge-success mb-5">
                                    <i className="fas fa-eye fa-fw" /> Visible
                                  </span>
                                ) : (
                                  <span className="badge badge-warning mb-5">
                                    <i className="fas fa-eye-slash fa-fw" />
                                    Invisible
                                  </span>
                                )}
                                <PopingOutReviews
                                  review_id={review?.id}
                                  status={review?.status}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Pagination */}
                    {event_reviews?.length > 10 && (
                      <CustomPagination
                        setCurrentPage={setCurrentPage}
                        totalPages={Math.ceil(
                          event_reviews?.length / reviewsPerPage
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
