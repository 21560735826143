import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import { useEffect, useState } from "react";
import { ordersStore } from "../../../store/orders_store";

export default function CircleProgressBar({ event_id }) {
  const [percentage, setPercentage] = useState(0);

  //getting the ticket quantity in an order for a single event.
  const getOrderTicketsForEvent = ordersStore(
    (state) => state.getOrderTicketsForEvent
  );

  //getting scanned tickets in an order of a single event
  const getScannedTicketsForEvent = ordersStore(
    (state) => state.getScannedTicketsForEvent
  );

  useEffect(() => {
    async function fetchData() {
      //getting all the ticket in an order for an event
      const t = await getOrderTicketsForEvent(event_id);

      //getting scannedTickets in an order for an event
      const ticketsScanned = await getScannedTicketsForEvent(event_id);
      const ts = ticketsScanned.length;

      //calculate percentage
      const ticketsSold = parseInt(t);
      const scannedTicks = parseInt(ts);

      if (scannedTicks !== 0 && ticketsSold !== 0) {
        const soldPercentage = (scannedTicks / ticketsSold) * 100;
        setPercentage(soldPercentage.toFixed(1)); // format to one decimal place
      } else {
        setPercentage(0);
      }

      // const scannnedTickets = await getScannedTicketsForEvent(event_id);
      // console.log("the scanned tickets of this event is:", scannnedTickets);
    }
    fetchData();
  }, [event_id]);

  return (
    <Box
      sx={{ display: "flex", gap: 2, alignItems: "center", flexWrap: "wrap" }}>
      <CircularProgress
        size="md"
        thickness={2}
        color="neutral"
        sx={{ color: "gray" }}
        determinate
        value={percentage}>
        <div className="float-left">
          <strong>{percentage}%</strong>
        </div>
      </CircularProgress>
    </Box>
  );
}
